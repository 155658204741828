/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
import { EnumsService } from './../../../../services/enum.service';
import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { SurveyResultService } from '../survey-result/survey-result.service';
import { SurveyResultCalculationsService } from '../survey-result/survey-result-calculations.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { AuthService } from '../../../../services/auth.service';
import { EMPTY, catchError, forkJoin } from 'rxjs';
import { Chart } from 'chart.js';
import { CHART_COLORS } from '../../../../../../constants';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { QuestionCategoryPipe } from '../../../../pipes/question-category.pipe';

@Component({
  selector: 'app-nexleader-printable-result',
  standalone: true,
  imports: [CommonModule, FilterPipe, QuestionCategoryPipe],
  templateUrl: './printable-result.component.html',
})
export class NexleaderPrintableResultComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  routeParams: any;
  result: any;
  user: any;
  surveyType!: string;
  categories!: any[];
  openEndedQuestionCategory: any;
  openEndedQuestions!: any[];
  summaryOverviewQuestionCategory: any;

  possessivePrefix!: string;
  total!: number;
  ipsatStage: any;
  charts: Chart[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private surveyResultService: SurveyResultService,
    private surveyResultCalculationsService: SurveyResultCalculationsService,
    private enumsService: EnumsService,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;

    forkJoin({
      result: this.surveyResultService.getSurveyResult(
        this.routeParams['survey_result_id']
      ),
      user: this.userService.getUser(this.routeParams['user_id']),
      enums: this.enumsService.getEnums(),
    })
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe(({ result, user, enums }) => {
        this.result = result;

        this.user = user;
        this.surveyType = user.group.surveyType.identifier;

        this.categories = enums.AllQuestionCategories;
        this.openEndedQuestionCategory = enums.QuestionCategories.OPEN_ENDED;
        this.summaryOverviewQuestionCategory =
          enums.QuestionCategories.SUMMARY_OVERVIEW;

        const payload = this.authService.getTokenPayload();
        this.possessivePrefix =
          payload.user && this.user._id === payload.user._id
            ? 'Your'
            : this.user.firstName + "'s";

        this.surveyResultCalculationsService.interpretCategories(
          this.categories,
          this.result
        );

        // Handle categories for the prerequisite that has the name of spiritualGifts
        this.categories.forEach((category) => {
          if (
            category.prerequisite === 'spiritualGifts' &&
            this.surveyType === 'values_based'
          ) {
            category.name = 'Personal Values';
            category.prerequisite = 'PersonalValues';
          }
        });

        this.total =
          this.surveyResultCalculationsService.calculateTotalIpsatScore(
            this.result
          );

        this.ipsatStage = {
          name: this.surveyResultCalculationsService.calculateCurrentIpsatStage(
            this.total
          ).name,
          description: this.categories[6].range.name,
        };

        this.openEndedQuestions = this.result.questions.filter(
          (question: any) => {
            return question.questionCategory.name == 'Open-Ended Questions';
          }
        );

        this.drawChart();
      });
  }

  ngAfterViewChecked(): void {
    this.drawChart();
  }

  drawChart() {
    if (this.charts.length < 1) {
      const ctxs = [
        document.getElementById('doughnut-chart') as HTMLCanvasElement,
        document.getElementById('doughnut-chart2') as HTMLCanvasElement,
      ].filter((x) => !!x);

      ctxs.forEach((ctx) => {
        ctx.width = 200;
        ctx.height = ctx.width / 2;
        const chart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: this.surveyResultCalculationsService.doughnutLabels(
              this.surveyType
            ),
            datasets: [
              {
                label: 'Your IPSAT Score breakdown',
                data: this.surveyResultCalculationsService.calculateDoughnutChart(
                  this.categories,
                  this.total
                ),
                backgroundColor: CHART_COLORS,
              },
            ],
          },
          options: {
            animation: {
              duration: 10,
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            elements: {
              arc: {
                borderWidth: 0,
              },
            },
            responsive: false,
            maintainAspectRatio: false,
          },
        });

        this.charts.push(chart as Chart);
      });
    }
  }

  print(): void {
    window.print();
  }

  getCategoryValue(key: string) {
    const actualKey = Object.keys(this.result.prerequisites).find(
      (k) =>
        k.toLowerCase() === key ||
        (k.toLowerCase() === 'mbti' && key === 'personality')
    );
    return (
      actualKey && {
        key: actualKey,
        value: this.result.prerequisites[actualKey],
      }
    );
  }

  ngOnDestroy() {
    // Destroy all chart instances to clean up resources
    if (this.charts) {
      this.charts.forEach(chart => chart.destroy());
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderPrintableResultComponent',
//   downgradeComponent({
//     component: NexleaderPrintableResultComponent,
//   }) as angular.IDirectiveFactory
// );
