import { Pipe, PipeTransform } from '@angular/core';

// If value is !!
// then it should return true if the provided key value is truthy

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(list: any[], key: string | undefined, value: string): any[] {
    if (!list) return [];
    if (!value) return list;
    if (!key) {
      return list.filter((i) =>
        JSON.stringify(i).toLowerCase().includes(value.toLowerCase())
      );
    }
    const nestedKeys = key.split('.').filter((v) => !!v);
    return list.filter((i) => {
      let nestedValue = i;
      nestedKeys.forEach((nestedKey) => {
        nestedValue = nestedValue[nestedKey];
      });
      return (value === '!!' && nestedValue) || nestedValue === value;
    });
  }
}
