<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-sm-8" *ngIf="shouldShowPurchaseOption()">
        <div class="nexleader-ecourse-card">
          <div>
            <h4 class="text-primary-2">Purchase ECourse Content</h4>
            <p>Purchase the Ecourse content to receive the following...</p>
            <br />

            <div class="row flex align-items-center">
              <div class="col-md-6">
                <ul class="nexleader-section-one-list">
                  <li>
                    Gain confidence and clarity about how you can add value to
                    others and make your best contribution to a team
                  </li>
                  <li>
                    Prioritize where you need to grow and develop the
                    self-leadership skills to follow through
                  </li>
                  <li>
                    Reveal self-defeating behaviors that could sabotage
                    relationships or derail your influence
                  </li>
                  <li>
                    Take charge of your life and close the gap between where you
                    are now and who you aspire to become
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <div class="nexleader-ecourse-video-wrapper">
                  <app-nexleader-vimeo-video [vimeoId]="'442266854'" [hideDownloadLink]="true"
                    [fullWidth]="true"></app-nexleader-vimeo-video>
                </div>
              </div>
            </div>
            <br />
            <h5 class="mt-5" style="font-size: 12pt; font-weight: bold">
              Access Module 1 for Free! Purchase access to the full ECourse.
            </h5>
          </div>
        </div>
      </div>
      <div class="col-sm-4" *ngIf="shouldShowPurchaseOption()">
        <div class="nexleader-ecourse-card" style="min-height: 420px; height: 100%">
          <div>
            <h4 class="text-primary-2">Purchase</h4>

            <div class="row flex align-items-center">
              <div class="col-sm-12">
                <app-nexleader-purchase-ecourse-content-upgrade
                  [userId]="userId"></app-nexleader-purchase-ecourse-content-upgrade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" *ngFor="let module of ecourseModules">
        <div class="nexleader-ecourse-card" style="min-height: 240px; height: 100%"
          [ngClass]="{ 'opacity-70': !shouldEnableModule(module) }">
          <img [src]="'/assets/images/icons/' + module.icon + '.svg'" alt="{{ module.icon }}" />
          <div>
            <h4 class="text-primary-2">{{ module.name }}</h4>
            <p>{{ module.description }}</p>
            <br />
            <a class="font-semi-bold flex flex-vertical-center" [attr.href]="shouldEnableModule(module)
            ? '/assets/files/ecourse/' + module.identifier + '.pdf'
            : null" [ngClass]="{ 'cursor-not-allowed': !shouldEnableModule(module) }" target="_blank">
              <i class="fa fa-2x fa-file-pdf-o text-success"></i>
              <span class="m-l-sm">Download Note Guide</span>
            </a>
            <br />
            <a class="font-semi-bold flex flex-vertical-center" (click)="
                shouldEnableModule(module) && viewEcourseModuleVideo(module)
              " [ngClass]="{ 'cursor-not-allowed': !shouldEnableModule(module) }" target="_blank">
              <i class="fa fa-2x fa-file-video-o text-success"></i>
              <span class="m-l-sm">View Module Video</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="nexleader-ecourse-card flex-column" style="min-height: 240px; height: 100%"
          [ngClass]="{ 'opacity-70': !shouldEnableModule(moduleEight) }">
          <div class="flex">
            <img [src]="'/assets/images/icons/module8.svg'" alt="coaching session" />
            <div>
              <h4 class="text-primary-2">Module 8</h4>
              <p>
                Using Self-Awareness and Self-Leadership to Bring Your Best to
                School, Work, and Life
              </p>
              <br />
              <a class="font-semi-bold flex flex-vertical-center" [attr.href]="shouldEnableModule(moduleEight)
              ? '/assets/files/ecourse/module8.pdf'
              : null" target="_blank" [ngClass]="{
                  'cursor-not-allowed': !shouldEnableModule(moduleEight)
                }">
                <i class="fa fa-2x fa-file-pdf-o text-success"></i>
                <span class="m-l-sm">Download Note Guide</span>
              </a>
              <br />
              <a class="font-semi-bold flex flex-vertical-center" [attr.href]="hasCompletedCoachingSession ||
              user?.onboardingExperience?.identifier !== 'ecourse'
                ? '/assets/files/ecourse/self-leadership-action-plan.pdf'
                : null" target="_blank" [ngClass]="{
                  'cursor-not-allowed': !shouldEnableModule(moduleEight)
                }">
                <i class="fa fa-2x fa-file-pdf-o text-success"></i>
                <span class="m-l-sm">Download Self-Leadership Action Plan</span>
              </a>
              <br />
              <a class="font-semi-bold flex flex-vertical-center" (click)="
                  shouldEnableModule(moduleEight) &&
                    viewEcourseModuleVideo(moduleEight)
                " target="_blank" [ngClass]="{
                  'cursor-not-allowed': !shouldEnableModule(moduleEight)
                }">
                <i class="fa fa-2x fa-file-video-o text-success"></i>
                <span class="m-l-sm">View Module Video</span>
              </a>
            </div>
          </div>
          <p *ngIf="
              !hasCompletedCoachingSession &&
              user?.onboardingExperience?.identifier === 'ecourse'
            " class="m-t-sm">
            Module 8 will become available once you have completed a coaching
            session.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>