<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Top Nav -->
<div id="header" class="hideOnPrint" *ngIf="shouldShowNav">
  <div class="color-line"></div>
  <div id="logo" class="light-version">
    <img src="/assets/images/nav-logo.svg" alt="nav logo" height="24" />
  </div>
  <nav role="navigation">
    <div class="header-link hide-menu navClickButton" (click)="toggleShowMobileNav()">
      <i class="fa fa-bars"></i>
    </div>
    <div class="navbar-left">
      <h4 class="font-bold text-primary-2">{{ currentRouteName }}</h4>
    </div>
  </nav>
</div>

<!-- Left Sidebar Navigation -->
<aside id="menu" class="hideOnPrint" *ngIf="shouldShowNav">
  <div id="navigation">
    <div class="nav-header-contents">
      <h5 class="text-primary-2 font-semi-bold">{{ userName }}</h5>
      <span class="text-muted">{{ groupName }}</span>

      <br />
      <br />
      <div class="flex">
        <a [routerLink]="['/account']" class="text-muted font-semi-bold">
          <i class="fa fa-cog"></i> Settings
        </a>
        <div class="flex-grow"></div>
        <a (click)="logout()" class="text-muted font-semi-bold">
          <i class="fa fa-sign-out"></i> Logout
        </a>
      </div>
    </div>
    <ul class="nav" id="side-menu">
      <li *ngFor="let role of activeRoles">
        <a>
          <div class="flex cursor-pointer" (click)="toggleNavSection(role._id)">
            <span class="nav-label nav-label-top">{{ role.name }}</span>
            <div class="flex-grow"></div>
            <i class="fa fa-chevron-up text-muted" *ngIf="navSectionIsVisible(role._id)"></i>
            <i class="fa fa-chevron-down text-muted" *ngIf="!navSectionIsVisible(role._id)"></i>
          </div>
        </a>
        <ul class="nav nav-second-level" *ngIf="navSectionIsVisible(role._id)">
          <li *ngFor="
              let route of getActiveRoutesForRole(role) | orderBy : 'name'
            ">
            <a [routerLink]="[route.path]" [class.active]="isCurrentRoute(route)">
              <span class="nav-label"><i style="width: 15px"
                  class="fa fa-{{ route.icon }} text-center"></i>&nbsp;&nbsp;{{ route.name }}</span>
              <span class="pull-right label label-success pull-right" *ngIf="route.isNewRoute">NEW</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</aside>