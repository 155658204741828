/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { LicensingPartnerService } from '../../../licensing-partners/resources/licensing-partner.service';
import { NexleaderWrapperPanelComponent } from '../../../core/components/wrapper-panel/wrapper-panel.component';
import { NexleaderSessionsComponent } from '../sessions/sessions.component';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-licensing-partner-calendar',
  standalone: true,
  imports: [CommonModule, NexleaderWrapperPanelComponent, NexleaderSessionsComponent],
  templateUrl: './licensing-partner-calendar.component.html'
})
export class NexleaderLicensingPartnerCalendarComponent implements OnInit {
  routeParams: any;
  licensingPartner: any;

  constructor(
    private route: ActivatedRoute,
    private licensingPartnerService: LicensingPartnerService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadLicensingPartner();
  }

  private loadLicensingPartner(): void {
    this.licensingPartnerService.get(this.routeParams['licensing_partner_id']).pipe(
      tap((data) => {
        this.licensingPartner = data;
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderLicensingPartnerCalendarComponent',
//   downgradeComponent({
//     component: NexleaderLicensingPartnerCalendarComponent,
//   }) as angular.IDirectiveFactory
// );

