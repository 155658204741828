/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * ErrorHandler
 *
 * angular service
 *
 * The ErrorHandler angular
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr'; // Make sure to install the ngx-toastr library
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  error: any = null;

  types = {
    AUTH: { name: 'Auth' },
    TOAST: { name: 'Toast' },
  };

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService
  ) { }

  handleError(err: any, type?: any): void {
    console.error(err);
    if (err.error && err.error.message) err = err.error;

    if (
      (err.status === 403 && !type) ||
      (err.status === 401 && !type) ||
      (type && type.name === 'Auth')
    ) {
      this.error = err;
      // Assuming Auth.logout() has been converted to a method in your AuthService
      // and you've injected AuthService into this service.
      // Update this accordingly.
      this.authService.logout();

      // Assuming you have a route with the path '/login'
      this.router.navigate(['/login']);

    } else if (err.status === 422 || (type && type.name === 'Toast')) {
      this.toastr.error(
        err.message || 'Unknown error contacting the server.',
        'Error',
        {
          closeButton: true,
          timeOut: 5000,
          positionClass: 'toast-top-right',
        }
      );

      this.error = null;
    } else {
      this.error = err;
      // Assuming you have a route with the path '/error'
      this.router.navigate(['/error']);
    }
  }

  toast(err: any): void {
    this.handleError(err, this.types.TOAST);
  }
}
