<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Take the MyPassionProfile Assessment</h3>
</div>
<div class="modal-body">
  <p class="m-b-sm">
    <b>Important information about MyPassionProfile for IPSAT users:</b>
  </p>
  <ol>
    <li>
      Be sure to use the discount code IPSAT when creating your MyPassionProfile
      account to zero out the cost.
    </li>
    <li>
      MyPassionProfile does NOT force you to select three passions but the IPSAT
      requires THREE passions in order to continue to the next step. Be sure to
      select THREE issues/causes in the MyPassionProfile process or you will not
      be able to finish the IPSAT.
    </li>
    <li>
      If you can't find your passion in the pre-populated lists click on the
      link "What if I can't find my passion?" and you can create your own.
    </li>
  </ol>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
  <a
    class="btn btn-success"
    type="button"
    href="https://mypassionprofile.com/"
    target="_blank"
    >Continue to MyPassionProfile</a
  >
</div>
