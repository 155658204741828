<div class="row" style="height: 100%" *ngIf="currentStep === 'engagement'">
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center bg-white">
    <div class="nexleader-ecourse-content-wrapper">
      <h3 class="font-bold text-primary-2 text-center" style="line-height: 36px">
        How to Grow Your Self-Awareness and Lead Yourself Better
      </h3>
      <br />
      <div>
        <app-nexleader-ecourse-rali-video [index]="1"></app-nexleader-ecourse-rali-video>
      </div>
      <br />
      <div class="text-center">
        <a class="btn btn-success btn-lg" href="/assets/files/ecourse/module1.pdf" target="_blank">Download
          Module 1 Application Note Guide</a>
      </div>
    </div>
  </div>
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center">
    <div class="nexleader-ecourse-content-wrapper">
      <div class="text-center">
        <img src="/assets/images/icons/module1.svg" alt="module 1" class="m-b-lg" width="80" />
      </div>
      <div class="nexleader-ecourse-heading flex">
        <div class="font-bold nexleader-ecourse-heading-left">
          Module <span>1</span>
        </div>
        <h3 class="font-bold text-primary-2 m-l-md">
          The Art of Self Leadership and the Power of Self-Awareness
        </h3>
      </div>
      <br />
      <div class="p-v-50">
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-download text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">Download the Application Note Guide</h4>
          </div>
        </div>
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-play text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">Engage with the video segments</h4>
          </div>
        </div>
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-undo text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">
              Respond to the Action Steps for Module 1
            </h4>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-success btn-lg" (click)="nextStep()">
            {{ mapContinueButtonTextForStep() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div style="height: 100%"> -->
<div style="height: 100%" *ngIf="currentStep === 'included_items'">
  <div class="nexleader-ecourse-content-wrapper">
    <h3 class="font-bold text-center text-primary-2">
      Here's what's included in your purchase:
    </h3>
    <br />
    <div class="row flex flex-wrap">
      <div class="col-md-3 col-xs-12" *ngFor="let module of availableModules">
        <div class="nexleader-ecourse-card" (click)="viewModuleInformation(module.identifier)" style="cursor: pointer">
          <img [src]="'/assets/images/icons/' + module.icon + '.svg'" alt="{{ module.icon }}" />
          <div>
            <h4 class="text-primary-2">{{ module.name }}</h4>
            <p>{{ module.description }}</p>
            <br />
            <a>Learn more</a>
          </div>
        </div>
      </div>
    </div>
    <div class="nexleader-ecourse-purchase-card text-center">
      <h3 class="text-white">
        Evergreen access to the 8 Modules, 5 Asessments, lifetime IPSAT account,
        and 2 one-on-one coaching sessions for $795.
      </h3>
      <p>
        We offer a money-back guarantee. If you follow our proven process and
        complete the course but don't believe it delivered the promised value,
        we'll issue a completed refund.
      </p>
    </div>
    <div class="flex flex-wrap">
      <button class="btn btn-default btn-lg" (click)="previousStep()">
        Previous Step
      </button>
      <div class="flex-grow"></div>
      <button class="btn btn-success btn-lg" (click)="nextStep()">
        {{ mapContinueButtonTextForStep() }}
      </button>
    </div>
  </div>
</div>
<div style="height: 100%" *ngIf="currentStep === 'purchase'">
  <app-nexleader-if-no-stripe>
    <div class="row">
      <div class="col-md-10 col-md-offset-1 col-xs-12">
        <div class="hpanel warning">
          <div class="color-line color-line-warning"></div>
          <div class="panel-body">
            <h3 class="mt-">Unable to Contact Stripe</h3>
            <p>
              Your computer is not able to talk to our payment provider, Stripe.
              Please try disabling ad blockers or other browser extensions that
              interfere with network traffic. Stripe allows us to securely
              process your order, and you cannot complete your purchase without
              Stripe.
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-nexleader-if-no-stripe>
  <br />
  <div class="row nexleader-ecourse-content-wrapper">
    <div class="col-sm-8 col-sm-offset-2 col-xs-12">
      <div class="text-center">
        <img [src]="'/assets/images/icons/module1.svg'" alt="module 1" class="m-b-lg" width="80" />
      </div>
      <h3 class="font-bold text-center text-primary-2 m-b-lg">
        How to Grow Your Self-Awareness and Lead Yourself Better
      </h3>
      <div class="hpanel">
        <div class="panel-body">
          <h4 class="m-b-md">Review & Checkout</h4>
          <hr class="m-t-md m-b-md" />
          <ul class="m-b-md">
            <li>8 Modules with video lessons</li>
            <li>Application Note Guide</li>
            <li>
              5 Assessment/Resources (personality, strengths, skills, values,
              passions)
            </li>
            <li>Lifetime Identity Profile Self-Awareness Tool Account</li>
            <li>2 Virtual Coaching Sessions</li>
          </ul>
          <div *ngIf="!pricingConfig.discountCodeName">
            <div class="form-group">
              <label class="control-label">Discount Code</label>
              <input class="form-control" type="text" [(ngModel)]="candidateDiscountCodeName" />
            </div>
            <button class="btn btn-default" (click)="validateCandidateDiscountCode()">
              Validate
            </button>
          </div>
          <div *ngIf="pricingConfig.discountCodeName">
            <p>
              Discount code
              <i>{{ pricingConfig.discountCodeName }}</i> successfully applied.
              <a (click)="clearDiscountName()">Undo</a>.
            </p>
          </div>
          <hr class="m-t-md m-b-md" />
          <div class="flex flex-vertical-center">
            <div>
              <p>Your total is...</p>
            </div>
            <div class="flex-grow"></div>
            <h4 class="font-bold m-t-none m-b-none">
              <app-nexleader-currency *ngIf="!loading && pricing" [currencyQuantity]="pricing.total.currencyQuantity"
                [currencyCode]="pricing.total.currencyCode"></app-nexleader-currency>
              <span *ngIf="loading">Loading Price...</span>
            </h4>
          </div>
        </div>
        <div class="panel-footer flex flex-vertical-center">
          <!-- [attr.disabled]="currentStep.index < 1 ? true : null" -->
          <button class="btn btn-default" (click)="previousStep()">
            Previous Step
          </button>
          <div class="flex-grow"></div>
          <button class="btn btn-success" (click)="checkout()" [attr.disabled]="!isReadyToAdvance() ? true : null">
            <i class="fa fa-shopping-cart"></i>
            Checkout
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->