/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { SuccessService } from './../../services/success.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModelDebounceChangeDirective } from '../../directives/ng-model-debounce.directive';
import { FormsModule } from '@angular/forms';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { PasswordResetService } from '../../services/password-reset.service';

@Component({
  selector: 'app-nexleader-password-reset',
  templateUrl: './password-reset.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, NgModelDebounceChangeDirective],
})
export class NexleaderPasswordResetComponent implements OnInit {
  passwordReset: any = {};
  message: string | undefined;
  groups: any;

  constructor(
    private router: Router,
    private passwordResetService: PasswordResetService,
    private errorHandler: ErrorHandlerService,
    private successService: SuccessService
  ) { }

  ngOnInit(): void {
    if (this.errorHandler.error) {
      this.message = this.errorHandler.error.message;
    }
  }

  trackByGroupId(index: number, group: any): number {
    return group._id;
  }

  update() {
    const req = { noAction: true, ...this.passwordReset };
    this.passwordResetService
      .save(req)
      .pipe(
        tap((response) => {
          this.groups = null;
          delete this.passwordReset.group;
        }),
        catchError((error: any) => {
          if (error.status === 449) {
            this.groups = error.error.disambiguation;
          } else {
            this.groups = null;
          }
          return of(null);
        })
      )
      .subscribe();
  }

  sendPasswordReset() {
    this.passwordResetService
      .save(this.passwordReset)
      .pipe(
        tap((_) => {
          this.successService.handle({ message: 'Password Reset Link Sent.' });
          this.router.navigate(['/']);
        }),
        catchError((error: any) => {
          if (error.status === 449) {
            this.groups = error.error.disambiguation;
          } else {
            this.errorHandler.handleError(
              error,
              this.errorHandler.types.TOAST
            );
          }
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderPasswordResetComponent',
//   downgradeComponent({
//     component: NexleaderPasswordResetComponent,
//   }) as angular.IDirectiveFactory
// );
