/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/*global Chart*/

/**
 * nexleaderViewCoach
 *
 * angular (view) component
 *
 * Renders all information for a coach that is displayed on the "Your Coaching" page. This includes their participants,
 *  NPS, evaluation results, and other items pertaining to an individual coach.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderQuickViewNotSelectedComponent } from '../../components/quick-view-not-selected/quick-view-not-selected.component';
import { NexleaderQuickViewComponent } from '../../components/quick-view/quick-view.component';
import { NexleaderUserCardComponent } from '../../components/user-card/user-card.component';
import { UserService } from '../../../../services/user.service';
import { DashboardService } from '../../../analytics/resources/dashboard.service';
import { EvaluationResultService } from '../../../../services/evaluation-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { NetPromoterService } from '../../../../services/net-promoter.service';
import { GlobalNpsService } from '../../../../services/global-nps.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderNetPromoterBoxComponent } from '../../components/net-promoter-box/net-promoter-box.component';
import { FormsModule } from '@angular/forms';
import { NexleaderStatisticComponent } from '../../components/statistic/statistic.component';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';
import { NgChartsModule } from 'ng2-charts';
import { Chart } from 'chart.js';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-coach',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderQuickViewNotSelectedComponent,
    NexleaderQuickViewComponent,
    NexleaderUserCardComponent,
    NexleaderNetPromoterBoxComponent,
    NexleaderStatisticComponent,
    OrderByPipe,
    FormsModule,
    NgChartsModule,
    RouterLink
  ],
  templateUrl: './coach.component.html',
})
export class NexleaderCoachComponent implements OnInit, OnDestroy {
  routeParams: any;
  toggleGlobalNPS: boolean = false;
  selectedUser: any;

  // Configure the initial state of the filter that shows the evaluation results
  evaluationResultsFilter = 'lastName';
  coach: any;
  evaluationResults: any[] = [];
  stats: any;
  coachParticipants: any[] = [];
  netPromoter: any;
  globalNPS: any;
  private ctx!: HTMLCanvasElement;
  private chartInstance: Chart | undefined;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dashboardService: DashboardService,
    private evaluationResultService: EvaluationResultService,
    private globalNPSService: GlobalNpsService,
    private errorHandler: ErrorHandlerService,
    private netPromoterService: NetPromoterService
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;
    this.ctx = document.getElementById('netPromoterChart') as HTMLCanvasElement;
    const coachId = this.routeParams['user_id'];

    this.userService
      .getUser(coachId)
      .pipe(
        tap((coach) => {
          this.coach = coach;
          this.loadGlobalNPS();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.evaluationResultService
      .coachQuery(coachId)
      .pipe(
        tap((evaluationResults) => {
          this.evaluationResults = evaluationResults.filter(
            (result) => result.coach._id === coachId
          );

          if (this.evaluationResults.length > 0) {
            // Calculates the net promoter score for the coach based off of the results
            this.netPromoterService
              .calculateNetPromoterScore(this.evaluationResults)
              .then((netPromoter) => {
                this.netPromoter = netPromoter;
              });

            if (this.ctx) {
              this.chartInstance = new Chart(this.ctx, {
                type: 'bar',
                data: {
                  labels: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                  ],
                  datasets: [
                    {
                      label: 'Net Promoter Scores',
                      borderWidth: 1,
                      data: this.netPromoterService.formatNetPromoterDataForBarChart(
                        this.evaluationResults
                      ),
                      backgroundColor: 'rgba(76,186,114, 0.2)',
                      borderColor: '#24d37b',
                    },
                  ],
                },
                options: {
                  animation: {
                    duration: 0,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                },
              });
            }
          }
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.dashboardService
      .queryCoach(coachId)
      .pipe(
        tap((stats) => {
          this.stats = stats;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.userService
      .queryParticipants(coachId)
      .pipe(
        tap((coachParticipants) => {
          this.coachParticipants = coachParticipants.map((user) => {
            user._name = user.firstName + ' ' + user.lastName;
            user._coach =
              user.coach && user.coach.firstName && user.coach.lastName
                ? user.coach.firstName + ' ' + user.coach.lastName
                : 'N/A';
            return user;
          });
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  loadGlobalNPS() {
    // Query the global NPS for the coach
    this.globalNPSService
      .get(this.coach.email)
      .pipe(
        tap((globalNPS) => {
          this.globalNPS = globalNPS;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  // Configure the filters for the coach
  filterAlphabetically() {
    this.evaluationResultsFilter = 'lastName';
  }

  filterByCreation() {
    this.evaluationResultsFilter = '';
  }

  ngOnDestroy() {
    // Destroy the chart instance to clean up resources
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCoachComponent',
//   downgradeComponent({
//     component: NexleaderCoachComponent,
//   }) as angular.IDirectiveFactory
// );
