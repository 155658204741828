/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
/* global ArrayBuffer */
/* global Uint8Array */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  constructor(private http: HttpClient) { }

  uploadBase64Url(base64Urlblob: string, body: any): Observable<any> {
    const split = base64Urlblob.split(',');
    const contentType = split[0].split(':')[1].split(';')[0];
    return this.uploadBase64(split[1], body, { type: contentType });
  }

  uploadBase64(base64blob: string, body: any, options: any): Observable<any> {
    const binaryImg = atob(base64blob);
    const length = binaryImg.length;
    const ua = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      ua[i] = binaryImg.charCodeAt(i);
    }
    const blob = new Blob([ua], options);
    return this.uploadBlob(blob, body);
  }

  uploadBlob(blob: Blob, body: any): Observable<any> {
    // If the photo is not a course content photo, add the necessary user/group checks
    if (body.imageType && body.imageType !== 'course-content-photo') {
      if (!body.user && !body.group) {
        throw new Error('body.user or body.group is required for blob upload');
      }
      body.user = body.user && body.user._id ? body.user._id : body.user;
      body.group = body.group && body.group._id ? body.group._id : body.group;
      if (typeof body.user !== 'string' && typeof body.group !== 'string') {
        throw new Error(
          'body.user or body.group must be a string or have a string _id'
        );
      }
      if (typeof body.filename !== 'string') {
        throw new Error('body.filename must be a string');
      }
    }

    const formData = new FormData();
    Object.keys(body).forEach((key) => {
      let val = body[key];
      // Check if the value is undefined, if so, skip the key
      if (val !== undefined) {
        if (typeof val !== 'string') {
          val = JSON.stringify(val);
        }
        formData.append(key, val);
      }
    });
    formData.append('file', blob);
    return this.http.post(`${API_BASE}blobs`, formData, {
      withCredentials: true,
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class BlobResourceService {
  constructor(private http: HttpClient) { }

  getBlob(): Observable<any> {
    return this.http.get(`${API_BASE}blobs`, {
      withCredentials: true,
    });
  }

  delete(blobId: string): Observable<any> {
    return this.http.delete(`${API_BASE}blobs/${blobId}`, {
      withCredentials: true,
    });
  }

  queryUser(userId: string, groupId?: string): Observable<any> {
    let params = new HttpParams();
    if (groupId) {
      params = params.append('group_id', groupId);
    }
    return this.http.get(`${API_BASE}blobs/users/${userId}/blobs`, {
      withCredentials: true,
      params,
    });
  }

  queryCoach(coachId: string, userId: string): Observable<any> {
    return this.http.get(
      `${API_BASE}blobs/coaches/${coachId}/users/${userId}/blobs`
    );
  }

  queryCoachUser(coachUserId: string, userId: string): Observable<any> {
    return this.http.get(
      `${API_BASE}blobs/coachUsers/${coachUserId}/users/${userId}/blobs`
    );
  }

  queryOnboardingPhoto(groupId: string): Observable<any> {
    return this.http.get(`${API_BASE}blobs/groups/${groupId}/onboardingPhoto`);
  }

  queryCourseContent(): Observable<any> {
    return this.http.get(`${API_BASE}blobs/courseContent/blobs`, {
      withCredentials: true,
    });
  }

  deleteUser(userId: string, blobId: string): Observable<any> {
    return this.http.delete(`${API_BASE}blobs/users/${userId}/blobs/${blobId}`);
  }
}
