/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderTeamReportCost
 *
 * angular component
 *
 * It is the responsibility of this component to calculate and display the cost of a team report in various cases.
 */
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderCurrencyComponent } from '../../../store/components/currency/currency.component';
import { LicensingPartnerService } from '../../../licensing-partners/resources/licensing-partner.service';
import { GroupService } from '../../../../services/group.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-team-report-cost',
  standalone: true,
  imports: [CommonModule, NexleaderCurrencyComponent],
  templateUrl: './team-report-cost.component.html',
})
export class NexleaderTeamReportCostComponent implements OnInit {
  private _groupId: string = '';

  /**
   * nexleaderDataGroupId
   *
   * string two-way binding
   *
   * This is the group id of the group to calculate price for
   *  We do not edit this value; we watch it
   */
  @Input() set groupId(value: string) {
    if (!value || typeof value !== 'string') {
      throw new Error(
        'Cannot instantiate nexleaderTeamReportCost without nexleaderDataGroupId binding'
      );
    }
    this._groupId = value;
    this.loadData();
  }

  get groupId() {
    return this._groupId;
  }

  /**
   * nexleaderDataCostType
   *
   * string binding
   *
   * This string value matches up with the LicensingPartner pricing subsection and identifies which cost
   *  this component should display.
   * This cost type can be one of three values:
   *  - perUser
   *  - min
   *  - max
   */
  @Input() costType!: string;

  licensingPartner: any;
  group: any;

  constructor(
    private licensingPartnerService: LicensingPartnerService,
    private groupService: GroupService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  /**
   * $onInit
   *
   * angular event binding
   *
   * Called after angular bindings load; validates them then loads data we need
   */
  ngOnInit(): void {
    if (typeof this.groupId === 'undefined')
      throw new Error(
        'Cannot instantiate nexleaderTeamReportCost without nexleaderDataGroupId binding'
      );
    if (typeof this.costType !== 'string')
      throw new Error(
        'Cannot instantiate nexleaderTeamReportCost without nexleaderDataCostType binding'
      );

    if (['perUser', 'min', 'max'].indexOf(this.costType) < 0)
      throw new Error(
        "Cannot instantiate nexleaderTeamReportCost with a cost type that is not one of the following: 'perUser', 'min', 'max'"
      );
  }

  private loadData(): void {
    if (this.groupId) {
      this.licensingPartnerService
        .getGroup(this.groupId)
        .pipe(
          tap((licensingPartner) => {
            this.licensingPartner = licensingPartner;
          }),
          catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        )
        .subscribe();

      this.groupService
        .getLite(this.groupId)
        .pipe(
          tap((group) => {
            this.group = group;
          }),
          catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.licensingPartner = null;
      this.group = null;
    }
  }

  /**
   * isLoading()
   *
   * function
   *
   * @returns {boolean} true if this component should NOT be rendered because data is still loading
   */
  isLoading(): boolean {
    return !(this.licensingPartner && this.group);
  }

  /**
   * getCurrencyQuantity()
   *
   * function
   *
   * returns the number of currency units to display (100 for $100 USD; 10,000 for 10,000 YEN)
   *  returns null if licensingPartner has not yet loaded
   */
  getCurrencyQuantity(): number {
    if (!this.licensingPartner || !this.group) {
      return NaN;
    }

    let teamReportPriceObject;

    if (this.group.surveyType.identifier === 'faith_based') {
      teamReportPriceObject =
        this.licensingPartner.pricing.teamReport.faithBased;
    } else if (this.group.surveyType.identifier === 'values_based') {
      teamReportPriceObject =
        this.licensingPartner.pricing.teamReport.valuesBased;
    } else {
      throw new Error(
        'unknown survey type identifier: ' + this.group.surveyType.identifier
      );
    }

    // this.costType is one of these: 'perUser', 'min', 'max'
    // So are the fields of teamReportPriceObject
    return teamReportPriceObject[this.costType];
  }

  /**
   * getCurrencyCode()
   *
   * function
   *
   * returns the ISO 4217 currency code for the purpose of rendering currency
   *  returns null if licensingPartner has not yet loaded
   */
  getCurrencyCode(): string {
    if (!this.licensingPartner) return '';
    return this.licensingPartner.currencyCode;
  }
}
