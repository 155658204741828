/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/*global Chart*/
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderNetPromoterBoxComponent } from '../../../core/components/net-promoter-box/net-promoter-box.component';
import { NexleaderStatisticComponent } from '../../../core/components/statistic/statistic.component';
import { CoachUserService } from '../../../../services/coach-user.service';
import { Chart } from 'chart.js';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { EvaluationResultService } from '../../../../services/evaluation-result.service';
import { NetPromoterService } from '../../../../services/net-promoter.service';
import { DashboardService } from '../../../analytics/resources/dashboard.service';
import { FormsModule } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { NexleaderParticipantsTableComponent } from '../../../core/components/participants-table/participants-table.component';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-multigroup-coach',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NexleaderNetPromoterBoxComponent,
    NexleaderStatisticComponent,
    FormsModule,
    NexleaderParticipantsTableComponent,
  ],
  templateUrl: './multigroup-coach.component.html',
})
export class NexleaderMultigroupCoachComponent implements OnInit, OnDestroy {
  routeParams: any;

  netPromoter: any;
  participants: any;
  globalNPS: any;
  coachUser: any;
  nps!: number;
  unableToCalculateNps: boolean = false;
  toggleGlobalNPS: boolean = false;
  evaluationResults: any[] = [];
  stats: any;
  private chart!: Chart;

  constructor(
    private route: ActivatedRoute,
    private coachUserService: CoachUserService,
    private dashboardService: DashboardService,
    private evaluationResultService: EvaluationResultService,
    private netPromoterService: NetPromoterService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    this.coachUserService
      .getCoachUser(this.routeParams['coach_user_id'])
      .pipe(
        tap((coachUser) => {
          this.coachUser = coachUser;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.coachUserService
      .getNPS(this.routeParams['coach_user_id'])
      .pipe(
        tap((nps) => {
          this.nps = nps;
        }),
        catchError((error) => {
          // Error probably means we couldn't calculate NPS yet because we don't have any evals
          this.nps = 0;
          this.unableToCalculateNps = true;
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.evaluationResultService
      .queryCoachUser(this.routeParams['coach_user_id'])
      .pipe(
        tap((a) => {
          this.evaluationResults = a.filter(
            (b) => b.coach._id === this.routeParams['user_id']
          );
          this.generateBarChart();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.dashboardService
      .queryCoachUser(this.routeParams['coach_user_id'])
      .pipe(
        tap((a) => {
          this.stats = a;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  generateBarChart() {
    if (this.evaluationResults.length > 0) {
      setTimeout(() => {
        const ctx = document.getElementById(
          'netPromoterChart'
        ) as HTMLCanvasElement;
        if (ctx) {
          this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
              datasets: [
                {
                  label: 'Net Promoter Scores',
                  borderWidth: 1,
                  data: this.netPromoterService.formatNetPromoterDataForBarChart(
                    this.evaluationResults
                  ),
                  backgroundColor: 'rgba(76,186,114, .2)',
                  borderColor: '#24d37b',
                },
              ],
            },
            options: {
              plugins: {
                legend: {
                  display: false,
                },
              },
            },
          });
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
