import { EnumsService } from './../../../../services/enum.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, of, tap } from 'rxjs';
import { NexleaderViewUsersWithAttributeModalComponent } from '../../../core/components/modals/view-users-with-attribute-modal/view-users-with-attribute-modal.component';
import { NexleaderAboutTeamReportDomainsModalComponent } from '../modals/about-team-report-domains-modal/about-team-report-domains-modal.component';

@Component({
  selector: 'app-nexleader-team-report-domains',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './team-report-domains.component.html',
})
export class NexleaderTeamReportDomainsComponent implements OnInit {
  /**
   * domainsOfPassionateEngagement
   *
   * Domains of Passionate engagement data to display
   */
  @Input() domainsOfPassionateEngagement: any;
  domainsOfPassion: any[] = [];
  constructor(
    private enumsService: EnumsService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          // Loop through the enum of domains of passionate engagement
          this.domainsOfPassion = Object.values(
            enums.DomainsOfPassionateEngagement
          ).map((domain: any) => {
            // Loop through each passion
            domain.passions = this.domainsOfPassionateEngagement.passions.map(
              (passion: any) => {
                return {
                  isRepresented:
                    passion.domainsOfPassionateEngagement[domain.name]
                      .isRepresented,
                  users:
                    passion.domainsOfPassionateEngagement[domain.name].users,
                  name: passion.passionName,
                };
              }
            );
            // We only want to show the list of passions if there are users
            domain.hasUsers = domain.passions.some(
              (p: any) => p.users.length > 0
            );
            return domain;
          });
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * viewTeamReportDomainsInformation
   *
   * Allows a user to learn more about the team report domains of passionate engagement through a modal
   */
  viewTeamReportDomainsInformation() {
    const modalRef = this.modalService.show(
      NexleaderAboutTeamReportDomainsModalComponent,
      {
        initialState: {
          users:
            this.domainsOfPassionateEngagement.usersWithPassionProfileReports,
        },
      }
    );
  }

  /**
   * viewUsers
   *
   * Allows a user to click on a passion and view the users who have that passion for the domain
   *
   * @param {Object} passion - A passion property to use inside of the modal
   */
  viewUsers(passion: any) {
    this.modalService.show(NexleaderViewUsersWithAttributeModalComponent, {
      initialState: {
        // Map the title and users from the passed-in parameters
        title: passion.name + ' - Domains of Passionate Engagement',
        users: passion.users,
      },
    });
  }
}
