/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderNav
 *
 * angular component
 *
 * The nav component is responsible for loading data and maintaining state for the left sidebar
 *  in the app. It displays all nav routes available to the user grouped by the role they belong
 *  to.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { RoleService } from '../../../../services/role.service';
import { NavService } from '../../../../services/nav.service';
import { catchError, of, tap } from 'rxjs';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';

@Component({
  selector: 'app-nexleader-nav',
  standalone: true,
  imports: [CommonModule, OrderByPipe, RouterLink],
  templateUrl: './nav.component.html'
})
export class NexleaderNavComponent implements OnInit {
  roles: any[] = [];
  routes: any[] = [];
  user: any;
  coachUser: any;
  visibleNavSections: string[] = [];

  /**
   * hasLoaded
   *
   * @type {boolean}
   *
   * False until all data necessary to render the nav has loaded
   */
  hasLoaded = false;

  constructor(
    private authService: AuthService,
    private roleService: RoleService,
    private errorHandler: ErrorHandlerService,
    private navService: NavService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loadResources();
    this.setupAuthenticationListener();
  }

  //Load resources (including the role enum)
  loadResources(): void {
    this.roleService.query().pipe(
      tap((roles) => {
        this.roles = roles;

        //Team Report Role
        // The team report role isn't an actual auth role, but it is a
        // section in the nav with its own rules for displaying and
        // hiding
        this.roles.push({
          name: 'Team',
          _id: 'team',
          index: 1
        });

        //Multigroup Coach Role
        this.roles.push({
          name: 'Multigroup Coach',
          _id: 'multigroup_coach',
          index: 39
        });

        // By default, set up an array of the role IDs to show which are visible
        this.visibleNavSections = this.roles.map((role) => role._id);

        this.hasLoaded = true;
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();

    //Extract a subset of each $$route object from the Angular router in
    // order to dynamically build the nav
    // we use a little bit of unsupported Angular here to make our lives
    // a lot easier (we access the current $$route)
    this.routes = this.router.config.map((route) => {
      return {
        path: route.path,
        name: route.data?.['name'],
        hiddenFromNav: route.data?.['hiddenFromNav'],
        roles: route.data?.['roles'],
        icon: route.data?.['icon'],
        isNewRoute: route.data?.['isNewRoute']
      };
    });
  }

  //Listen for an authentication event from the authentication service
  // so that we always have up to date
  setupAuthenticationListener(): void {
    this.authService.authentication.subscribe((params: any) => {
      if (params?.user) {
        this.user = params.user;
        this.coachUser = null;
      } else if (params?.coach_user) {
        this.coachUser = params.coach_user;
        this.user = null;
      } else {
        this.user = null;
        this.coachUser = null;
      }
    });
  }

  /**
   * getCurrentRoute()
   *
   * @returns {*} the currently selected route from the $route service
   */
  get currentRoute() {
    return this.route.firstChild?.snapshot;
  }

  /**
   * getCurrentRoutePath()
   *
   * @returns {*} the currently selected route path from the $route service
   */
  get currentRoutePath(): string | null {
    return this.route.firstChild?.snapshot.routeConfig?.path ?? null;
  }

  /**
   * getCurrentRouteName()
   *
   * @returns {*} the currently selected route name from the $route service
   */
  get currentRouteName(): string {
    const currentRouteData = this.currentRoute?.data;
    return currentRouteData ? currentRouteData['name'] : null;
  }

  /**
   * getActiveRoleIds()
   *
   * Returns the current active role ids based on the currently
   *  authenticated user from the auth service.
   */
  get activeRoleIds(): string[] {
    const payload = this.authService.getTokenPayload();
    if (!payload) return [];

    const roles: string[] = [];

    //If a user is a consolidated multigroup coach user,
    // it is automatically assinged the role multigroup_coach
    // it also gets the role group admin if it has a primary_user,
    // which they all _should_ have.
    if (payload.is_coach_user) {
      roles.push('multigroup_coach');

      if (payload.primary_user) {
        roles.push('groupadmin', 'participant');
      }
    } else {
      // Filter out the directsignupunpaid role, as there aren't any routes to show for this
      roles.push(
        ...payload.roles.filter((role: string) => role !== 'directsignupunpaid')
      );

      //Team Report Role
      // The team report role isn't an actual auth role, but it is a
      // section in the nav with its own rules for displaying and
      // hiding
      // If the user is marked as a team leader or the user is a group admin,
      // display the team report section
      // todo for now, team report only works for spirituality-based groups
      if (
        this.user &&
        (this.user.isTeamLeader || payload.roles.indexOf('groupadmin') >= 0)
      ) {
        roles.push('team');
      }
    }

    return roles;
  }

  /**
   * getActiveRoles()
   *
   * Calls getActiveRoleIds() and adds the additional
   *  role data from querying the roles from the backend
   *
   * Upgrades from ids to full objects
   *
   * Returns null if roles have not loaded yet
   */
  get activeRoles(): any[] | null {
    if (!this.roles) return null;
    return this.activeRoleIds
      .map((roleId: string) =>
        this.roles.find((role: any) => role._id === roleId)
      )
      .filter((role: any) => !!role)
      .sort((a, b) => a.index - b.index);
  }

  /**
   * getUserName()
   *
   * returns the name of the currently authenticated
   * user in firstName lastName format
   *
   * Don't call this function if user or coachUser isn't present
   *  You will get null
   */
  get userName(): string | null {
    if (this.user) return `${this.user.firstName} ${this.user.lastName}`;
    if (this.coachUser) return `${this.coachUser.firstName} ${this.coachUser.lastName}`;
    return null;
  }

  /**
   * getGroupName()
   *
   * returns the name of the group the currently authenticated
   * user belongs to
   *
   * Don't call this function if user or coachUser isn't present
   *  You will get null
   *
   * Returns 'Multigroup Coach' for multigroup coaches
   */
  get groupName(): string | null {
    if (this.user && this.user.group) return this.user.group.name;
    if (this.coachUser) return 'Multigroup Coach';
    return null;
  }

  /**
   * getActiveRoutesForRole()
   *
   * returns all route objects from $ctrl.routes that should be displayed under the role section
   *  for instance, the group admin section includes "Users"
   *
   * does not return routes that are not "displayable"
   *
   * to be displayable, a route must have a roles array and a name
   * it must also not explicitly hide itself from the nav via the property hiddenFromNav
   *
   * @param role
   */
  getActiveRoutesForRole(role: any): any[] {
    if (!role) return [];
    return this.routes.filter(
      (route) =>
        route.name &&
        route.roles &&
        !route.hiddenFromNav &&
        route.roles.indexOf(role.name) >= 0
    );
  }

  /**
   * navSectionIsVisible()
   * 
   * Determines if the current section is visible. Just a check of whether the role exists in the visible
   *  nav sections.
   * 
   * @param {string} role - A role name to check.
   * 
   * @returns A boolean of whether the role is included in visibility.
   */
  navSectionIsVisible(role: string): boolean {
    return this.visibleNavSections.includes(role);
  }

  /**
   * toggleNavSection()
   * 
   * Toggles the visibility of a given left nav section.
   * 
   * @param {string} role - The name of the role to toggle visibility for.
   */
  toggleNavSection(role: string): void {
    if (this.navSectionIsVisible(role)) {
      this.visibleNavSections = this.visibleNavSections.filter((val) => val !== role);
    } else {
      this.visibleNavSections.push(role);
    }
  }

  /**
   * isCurrentRoute()
   *
   * returns true if the route object from $ctrl.routes represents the
   *  route that is currently selected
   */
  isCurrentRoute(route: any): boolean {
    return this.currentRoutePath === route.path;
  }

  /**
   * shouldShowNav()
   *
   * returns true if we should be showing the navbar at the top
   */
  get shouldShowNav(): boolean {
    const routeData = this.currentRoute?.data ?? {};
    return this.hasLoaded && !routeData['hideNav'];
  }

  //---Mobile nav code---
  //The mobile nav is collapsible via Javascript.
  //The mobile nav vs. the normal nav is controlled
  // via media query css.
  //This code controls the show/hide state of the mobile
  // nav

  shouldShowMobileNav(): boolean {
    return this.navService.showMobileNav;
  }

  toggleShowMobileNav(): void {
    this.navService.showMobileNav = !this.navService.showMobileNav;
    if (this.navService.showMobileNav) {
      // add class show-sidebar from body tag
      document.body.classList.add('show-sidebar');
    } else {
      // remove class show-sidebar from body tag
      document.body.classList.remove('show-sidebar');
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderNavComponent',
//   downgradeComponent({
//     component: NexleaderNavComponent,
//   }) as angular.IDirectiveFactory
// );

