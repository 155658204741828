import { OnInit } from '@angular/core';
/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
import { EnumsService } from './../../../../services/enum.service';
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { SuccessService } from './../../../../services/success.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlobService } from '../../../../services/blob.service';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NexleaderFilePickerComponent } from '../file-picker/file-picker.component';

@Component({
  selector: 'app-nexleader-file-upload',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderFilePickerComponent],
  templateUrl: './file-upload.component.html',
})
export class NexleaderFileUploadComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  @Input() fileOwner: any;
  @Input() prerequisite: any;
  @Output() onUploadComplete = new EventEmitter<any>();
  @Output() onUploadFail = new EventEmitter<any>();
  file: any = {};
  prerequisiteAlreadySelected: boolean = false;
  data: any;
  uploading: boolean = false;
  prerequisites: any;

  constructor(
    private blobService: BlobService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService,
    private enumsService: EnumsService
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
    this.prerequisiteAlreadySelected = !!this.prerequisite;

    if (!this.prerequisiteAlreadySelected) {
      this.prerequisite = null;
    }

    if (!this.onUploadComplete.observed) {
      this.onUploadComplete.pipe(takeUntil(this._destroy$)).subscribe(() => {
        this.successService.handle({
          message: 'Finished Uploading ' + this.file.name,
        });
      });
    }

    if (!this.onUploadFail.observed) {
      this.onUploadFail.pipe(takeUntil(this._destroy$)).subscribe((e) => {
        this.errorHandlerService.handleError({
          message: 'Failed to Upload ' + this.file.name,
          detail: e,
        });
      });
    }

    if (!this.fileOwner) {
      console.warn('File Upload will not work properly without a file owner.');
    }

    this.load();
  }

  trackByIndex(index: number, group: any) {
    return group.index;
  }

  load(): void {
    this.enumsService
      .getEnums()
      .pipe(
        tap((a) => {
          this.prerequisites = a.AllPrerequisites;
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }

  onSelect(file: any): void {
    this.file = file;
  }

  onLoad(data: any): void {
    this.data = data;
  }

  upload(): void {
    this.uploading = true;

    this.blobService
      .uploadBase64Url(this.data, {
        user: this.fileOwner,
        filename: this.file.name,
        prerequisite: this.prerequisite,
      })
      .pipe(
        tap((_) => {
          this.onUploadComplete.emit(this.file);
          this.uploading = false;
          this.file = {};
          this.data = null;
        }),
        catchError((error) => {
          this.uploading = false;
          this.file = {};
          this.data = null;
          this.onUploadFail.emit(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
