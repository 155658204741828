<div class="flex flex-vertical-center flex-horizontal-center hideOnPrint w-100 h-100" style="min-height: 100vh">
  <button class="btn btn-lg btn-primary" (click)="print()">
    <i class="fa fa-print"></i>&nbsp;&nbsp;Print your Team Report
  </button>
</div>
<div class="nexleader-team-report-header font-bold" *ngIf="teamReport">
  {{ teamReport.teamName }} - {{ teamReport.group.name }} - Team Report
</div>
<!-- First Page: Title Page -->
<div class="centeredFirstPage" *ngIf="teamReport">
  <div class="centeredFirstPageInside">
    <img src="/assets/images/logo.svg" alt="ipsat icon" width="200px" />
    <br />
    <br />
    <h1>Identity Profile <br />Self-Awareness Tool (IPSAT)</h1>
    <br />
    <br />
    <h3 class="font-bold">discover your identity</h3>
    <br />
    <br />
    <h3 class="font-bold">Team Report</h3>
    <br />
    <br />
    <p>
      <b>Group:</b>
      {{ teamReport.group.name }}
    </p>
    <p>
      <b>Team:</b>
      {{ teamReport.teamName }}
    </p>
    <p>
      <b>Created:</b>
      {{ teamReport.createdAt | date }}
    </p>
  </div>
</div>
<div class="page-break"></div>
<div *ngIf="teamReport">
  <div class="nexleader-team-report-page-num"></div>
  <b>Team Members</b>
  <br />
  <div class="row m-b-md">
    <div class="col-xs-3" *ngFor="let teamUser of teamReport.teamUsers">
      <h5>{{ teamUser.firstName }} {{ teamUser.lastName }}</h5>
    </div>
  </div>
  <p class="m-b-sm"><b>IPSAT Team Score</b></p>
  <p>
    The maximum score for the Identity Profile Self-Awareness Tool (IPSAT) is
    100. There are five categories based on the components of your identity
    profile: personality, strengths, skills,
    <span *ngIf="surveyTypeIdentifier === 'values_based'">personal values</span>
    <span *ngIf="surveyTypeIdentifier === 'faith_based'">spiritual gifts</span>,
    and passions. Each sub-category has a maximum score of 20.
    <br />
    <br />
    The team score is the average of the individual IPSAT scores for the members
    of your team.
  </p>
  <br />
  <div class="text-center m-b-sm">
    <h4 class="text-center">
      Your team is at the
      <span class="font-bold">{{ teamReport.ipsat.stage.name }}</span>
    </h4>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <app-nexleader-chart-ipsat-doughnut [model]="teamReport.ipsat"
        [isPrinting]="true"></app-nexleader-chart-ipsat-doughnut>
    </div>
    <div class="col-xs-6">
      <p>{{ teamReport.ipsat.stage.body }}</p>
    </div>
  </div>
  <br />
  <br />
  <div class="m-b-md">
    <div class="flex flex-vertical-center pull-left m-b-sm">
      <img [src]="'/assets/images/icons/personality.svg'" alt="personality icon" class="m-r-sm img-xs" />
      <b>Personality</b>
    </div>
    <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.personality"
      [category]="'personality'"></app-nexleader-team-report-progress-bar>
  </div>
  <div class="m-b-md">
    <div class="flex flex-vertical-center pull-left m-b-sm">
      <img [src]="'/assets/images/icons/strengths.svg'" alt="strengths icon" class="m-r-sm img-xs" />
      <b>Strengths</b>
    </div>
    <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.strengths"
      [category]="'strengths'"></app-nexleader-team-report-progress-bar>
  </div>
  <div class="m-b-md">
    <div class="flex flex-vertical-center pull-left m-b-sm">
      <img [src]="'/assets/images/icons/skills.svg'" alt="skills icon" class="m-r-sm img-xs" />
      <b>Skills</b>
      <div class="flex-grow"></div>
    </div>
    <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.skills"
      [category]="'skills'"></app-nexleader-team-report-progress-bar>
  </div>
  <div class="m-b-md">
    <div class="flex flex-vertical-center pull-left m-b-sm">
      <img [src]="'/assets/images/icons/spiritualgifts.svg'" alt="spiritual gifts icon" class="m-r-sm img-xs" />
      <b>
        <span *ngIf="surveyTypeIdentifier === 'values_based'">Personal Values</span>
        <span *ngIf="surveyTypeIdentifier === 'faith_based'">Spiritual Gifts</span>
      </b>
    </div>
    <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.spiritualGifts"
      [category]="'spiritualgifts'"></app-nexleader-team-report-progress-bar>
  </div>
  <div class="m-b-md">
    <div class="flex flex-vertical-center pull-left m-b-sm">
      <img [src]="'/assets/images/icons/passions.svg'" alt="passions icon" class="m-r-sm img-xs" />
      <b>Passions</b>
    </div>
    <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.passions"
      [category]="'passions'"></app-nexleader-team-report-progress-bar>
  </div>
</div>
<div class="page-break"></div>
<div *ngIf="teamReport">
  <div class="nexleader-team-report-page-num"></div>
  <div class="row">
    <div class="col-xs-8">
      <div class="flex flex-vertical-center m-b-sm">
        <img src="/assets/images/icons/personality.svg" alt="Personality" class="img-md m-r-md" />
        <h3 class="font-bold m-b-sm text-primary-2">Team Personality</h3>
      </div>
      <p>
        The IPSAT team score reflects the collective level of self-awareness
        with regard to how well your team members understand their personality,
        how it relates to the rest of their identity, and how their preferences
        could work against them if overused.
      </p>
    </div>
    <div class="col-xs-4">
      <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.personality"
        [category]="'personality'"></app-nexleader-team-report-progress-bar>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-xs-6">
      <p class="m-b-sm">
        <b>MBTI Team Type - {{ teamReport.personality.teamMbti.name }}</b>
      </p>
      <p>
        The MBTI team type is calculated by counting the number of team members
        with each preference. The information below shows the MBTI types
        represented by your team.
      </p>
    </div>
    <div class="col-xs-6">
      <app-nexleader-chart-mbti-table [teamMbti]="teamReport.personality.teamMbti"
        [model]="teamReport.personality.mbtiTypeAggregation"></app-nexleader-chart-mbti-table>
    </div>
  </div>
  <br />
  <br />
  <app-nexleader-chart-mbti-letter-aggregation
    [model]="teamReport.personality.mbtiTraitAggregation"></app-nexleader-chart-mbti-letter-aggregation>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-discussion-questions [questions]="teamReport.personality.questions"
    [header]="'Personality'"></app-nexleader-team-report-discussion-questions>
</div>
<div class="page-break"></div>
<div *ngIf="teamReport">
  <div class="nexleader-team-report-page-num"></div>
  <div class="row">
    <div class="col-xs-8">
      <div class="flex flex-vertical-center m-b-sm">
        <img src="/assets/images/icons/strengths.svg" alt="Strengths" class="img-md m-r-md" />
        <h3 class="font-bold m-b-sm text-primary-2">Team Strengths</h3>
      </div>
      <p>
        The IPSAT team score reflects the collective level of self-awareness
        with regard to how well your team members understand their strengths,
        the extent to which they are being developed, how they relate to the
        rest of their identity, and how their strengths could work against them
        if overused.
      </p>
    </div>
    <div class="col-xs-4">
      <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.strengths"
        [category]="'strengths'"></app-nexleader-team-report-progress-bar>
    </div>
  </div>
  <br />
  <p class="m-b-sm">
    <b>Team Profile of Clifton Strengths Assessment across the four domains</b>
  </p>
  <app-nexleader-chart-strengths-represented *ngIf="teamReport.strengths.representedStrengthCategories"
    [model]="teamReport.strengths.representedStrengthCategories"></app-nexleader-chart-strengths-represented>
  <p class="m-b-sm"><b>Strengths Not Represented on Your Team</b></p>
  <app-nexleader-chart-strengths-unrepresented *ngIf="teamReport.strengths.unrepresentedStrengthCategories"
    [model]="teamReport.strengths.unrepresentedStrengthCategories"></app-nexleader-chart-strengths-unrepresented>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-discussion-questions [questions]="teamReport.strengths.questions"
    [header]="'Strengths'"></app-nexleader-team-report-discussion-questions>
</div>
<div class="page-break"></div>
<div *ngIf="teamReport">
  <div class="nexleader-team-report-page-num"></div>
  <div class="row">
    <div class="col-xs-8">
      <div class="flex flex-vertical-center m-b-sm">
        <img src="/assets/images/icons/skills.svg" alt="Skills" class="img-md m-r-md" />
        <h3 class="font-bold m-b-sm text-primary-2">Team Skills</h3>
      </div>
      <p>
        The IPSAT team score reflects the collective level of self-awareness
        with regard to how well your team members understand the skills required
        to succeed in various roles, the extent to which they are being
        developed, how skills can synergize with the rest of their identity, and
        how skills can work against them if overused.
      </p>
    </div>
    <div class="col-xs-4">
      <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.skills"
        [category]="'skills'"></app-nexleader-team-report-progress-bar>
    </div>
  </div>
  <br />
  <p class="m-b-sm"><b>Ideal vs Actual Team Skill Profile</b></p>
  <app-nexleader-chart-skill-comparison [model]="teamReport.skills.levels"></app-nexleader-chart-skill-comparison>
  <br />
  <p class="m-b-sm"><b>Five highest rated, self-assessed skills</b></p>
  <app-nexleader-chart-skills [model]="teamReport.skills.levels.top"></app-nexleader-chart-skills>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-discussion-questions [questions]="teamReport.skills.questions"
    [header]="'Skills'"></app-nexleader-team-report-discussion-questions>
</div>
<div class="page-break"></div>
<div *ngIf="teamReport && surveyTypeIdentifier === 'faith_based'">
  <div class="nexleader-team-report-page-num"></div>
  <div class="row">
    <div class="col-xs-8">
      <div class="flex flex-vertical-center m-b-sm">
        <img src="/assets/images/icons/spiritualgifts.svg" alt="Spiritual Gifts" class="img-md m-r-md" />
        <h3 class="font-bold m-b-sm text-primary-2">Team Spiritual Gifts</h3>
      </div>
      <p>
        The IPSAT team score reflects the collective level of self-awareness
        with regard to how consistently your team members use their spiritual
        gifts, the extent to which they are being developed, how gifts can
        synergize with the rest of their identity, and how they can work against
        them if overused.
      </p>
    </div>
    <div class="col-xs-4">
      <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.spiritualGifts"
        [category]="'spiritualgifts'"></app-nexleader-team-report-progress-bar>
    </div>
  </div>
  <br />
  <p class="m-b-sm"><b>Venn Diagram of Your Team's Spiritual Gifts</b></p>
  <app-nexleader-chart-spiritual-gifts-represented *ngIf="teamReport.spiritualGifts.representedSpiritualGiftCategories"
    [model]="teamReport.spiritualGifts.representedSpiritualGiftCategories"></app-nexleader-chart-spiritual-gifts-represented>
  <p class="m-b-sm"><b>Spiritual Gifts Not Represented on Your Team</b></p>
  <app-nexleader-chart-spiritual-gifts-unrepresented
    *ngIf="teamReport.spiritualGifts.unrepresentedSpiritualGiftCategories"
    [model]="teamReport.spiritualGifts.unrepresentedSpiritualGiftCategories"></app-nexleader-chart-spiritual-gifts-unrepresented>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-discussion-questions [questions]="teamReport.spiritualGifts.questions"
    [header]="'Spiritual Gifts'"></app-nexleader-team-report-discussion-questions>
</div>
<div *ngIf="teamReport && surveyTypeIdentifier === 'values_based'">
  <div class="nexleader-team-report-page-num"></div>
  <div class="row">
    <div class="col-xs-8">
      <div class="flex flex-vertical-center m-b-md">
        <img src="/assets/images/icons/spiritualgifts.svg" alt="Personal Values" class="img-md m-r-md" />
        <h3 class="font-bold m-b-sm text-primary-2">Team Personal Values</h3>
      </div>
      <p>
        The IPSAT team report reflects the collective level of self-awareness
        with regard to how well your team members understand and live by their
        values, when to compromise if values are in tension with each other, and
        their awareness of situations where they are vulnerable to act in ways
        that are inconsistent with their values.
      </p>
    </div>
    <div class="col-xs-4">
      <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.spiritualGifts"
        [category]="'spiritualgifts'"></app-nexleader-team-report-progress-bar>
    </div>
  </div>
  <br />
  <p class="m-b-sm">
    <b>Team Profile of Personal Values Across 4 Life Domains</b>
  </p>
  <h4 class="font-bold m-b-md text-primary"></h4>
  <app-nexleader-chart-personal-values-represented *ngIf="teamReport.personalValues" [model]="teamReport.personalValues"
    [hideValueSetSelect]="true" [defaultSelection]="'topThreeValues'"></app-nexleader-chart-personal-values-represented>
  <div class="page-break"></div>
  <p class="m-b-sm"><b>Personal Values Spider Graph</b></p>
  <app-nexleader-chart-personal-values-spider *ngIf="teamReport.personalValues" [model]="teamReport.personalValues"
    [hideValueSetSelect]="true" [defaultSelection]="'topThreeValues'"></app-nexleader-chart-personal-values-spider>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-discussion-questions
    [questions]="teamReport.personalValues.questions"></app-nexleader-team-report-discussion-questions>
</div>
<div class="page-break"></div>
<div *ngIf="teamReport">
  <div class="nexleader-team-report-page-num"></div>
  <div class="row">
    <div class="col-xs-8">
      <div class="flex flex-vertical-center m-b-sm">
        <img src="/assets/images/icons/passions.svg" alt="Passions" class="img-md m-r-md" />
        <h3 class="font-bold m-b-sm text-primary-2">Team Passions</h3>
      </div>
      <p>
        The IPSAT team score reflects the collective level of self-awareness
        with regard to how well your team members understand how passions
        emerge, how to develop them, how effectively they are using their
        giftedness to advance important causes, and how passion can work against
        them if overused.
      </p>
    </div>
    <div class="col-xs-4">
      <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.passions"
        [category]="'passions'"></app-nexleader-team-report-progress-bar>
    </div>
  </div>
  <br />
  <div class="row flex flex-vertical-center">
    <div class="col-xs-8">
      <img class="img-full" src="/assets/images/passion-pyramid.svg" alt="passion pyramid" />
    </div>
    <div class="col-xs-4">
      <p class="m-b-sm"><b>The Passion Pyramid</b></p>
      <p>
        Passion can be defined as a self-directed motivation to learn more about
        something, engage in activities related to it, and influence others for
        it, even when sacrifice is required. The passion pyramid depicts the
        four levels of passion based on the learn, engage, influence and
        sacrifice model.
      </p>
    </div>
  </div>
  <br />
  <br />
  <app-nexleader-chart-passions-represented
    [model]="teamReport.passions.representedPassionLevels"></app-nexleader-chart-passions-represented>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-domains [domainsOfPassionateEngagement]="teamReport.domainsOfPassionateEngagement">
  </app-nexleader-team-report-domains>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-discussion-questions [questions]="teamReport.passions.questions"
    [header]="'Passions'"></app-nexleader-team-report-discussion-questions>
</div>
<div class="page-break"></div>
<ng-container *ngIf="teamReport">
  <div *ngFor="let statement of teamReport.openEndedQuestions; let last = last">
    <div class="nexleader-team-report-page-num"></div>
    <p class="m-b-sm">
      <b>{{ statement.name }} Statements for your team</b>
    </p>
    <p>{{ statement.description }}</p>
    <br />
    <br />
    <p [ngClass]="{ 'm-b-sm': !last }" *ngFor="let response of statement.responses">
      {{ response.answer.name }}
      <br />
      <i>{{ response.user.firstName }} {{ response.user.lastName }}</i>
    </p>
    <div class="page-break" *ngIf="!last"></div>
  </div>
</ng-container>

<div class="page-break" *ngIf="teamReport">
  <div class="nexleader-team-report-page-num"></div>
  <app-nexleader-team-report-discussion-questions [questions]="teamReport.nextStepQuestions"
    [header]="'Next Steps'"></app-nexleader-team-report-discussion-questions>
</div>