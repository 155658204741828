/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderLicensingPartners
 *
 * view
 *
 * displays a table of LicensingPartners that exist in the app
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderQuickViewComponent } from '../../../core/components/quick-view/quick-view.component';
import { NexleaderQuickViewNotSelectedComponent } from '../../../core/components/quick-view-not-selected/quick-view-not-selected.component';
import { LicensingPartnerService } from '../../resources/licensing-partner.service';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderNewLicensingPartnerModalComponent } from '../../components/modals/new-licensing-partner-modal/new-licensing-partner-modal.component';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderLicensingPartnerCardComponent } from '../../components/licensing-partner-card/licensing-partner-card.component';

@Component({
  selector: 'app-nexleader-licensing-partners',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderQuickViewComponent,
    NexleaderQuickViewNotSelectedComponent,
    NexleaderLicensingPartnerCardComponent,
  ],
  templateUrl: './licensing-partners.component.html',
})
export class NexleaderLicensingPartnersComponent implements OnInit {
  licensingPartners: any[] = [];
  selectedPartner: any;
  constructor(
    private licensingPartnerService: LicensingPartnerService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: BsModalService,
    private successService: SuccessService
  ) { }

  /**
   * ngOnInit()
   *
   * function
   *
   * called by Angular when bindings are done
   *  validate bindings then load data
   */
  ngOnInit(): void {
    this.load();
  }

  /**
   * load()
   *
   * validates group_id then updates the data we use
   *  called $onInit and when a new group is created (or one is deleted)
   */
  load(): void {
    // Load the partners that we're going to display
    this.licensingPartnerService
      .queryIndex()
      .pipe(
        tap((data) => {
          this.licensingPartners = data;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * openNewLicensingPartnerModal()
   *
   * function
   *
   * opens the new licensing partner modal view using Angular Material or another modal library
   */
  openNewLicensingPartnerModal(): void {
    const modalRef = this.modalService.show(
      NexleaderNewLicensingPartnerModalComponent
    );

    // load data and show success message
    modalRef.content?.onSave.subscribe((value) => {
      modalRef.hide();
      this.load();
      this.successService.handle();
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderLicensingPartnersComponent',
//   downgradeComponent({
//     component: NexleaderLicensingPartnersComponent,
//   }) as angular.IDirectiveFactory
// );
