/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderGroupCard
 *
 * component
 *
 * displays a card representing a single  group
 *  used in the CoachGroups view
 *
 * may mutate of the state of groups via two-way binding
 *  an edit button will make edits; archive button will archive
 *
 * changes are saved automatically via the resource service
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupService } from '../../../../services/group.service';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-group-card',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './group-card.component.html',
})
export class NexleaderGroupCardComponent implements OnInit {
  @Input() set groupId(value: string) {
    this.loadGroup(value);
  }

  group: {
    coachCount: number;
    _id: string;
    createdAt: string;
    updatedAt: string;
    customOnboarding: any;
    ecourse: any;
    inviteCount: number;
    userCount: number;
    name: string;
    isLegacy: boolean;
    isDirectPurchase: boolean;
    licensingPartner: any;
    surveyType: any;
    tier: any;
    remainingLicenses: number;
    maxUsers: number;
    teamReportCount: number;
    sessionCount: number;
    smallGroupCount: number;
    participantCount: number;
    preferredPreassessments: any;
  } = {
    coachCount: NaN,
    _id: '',
    createdAt: '',
    updatedAt: '',
    customOnboarding: {},
    ecourse: {},
    inviteCount: NaN,
    userCount: NaN,
    name: '',
    isLegacy: false,
    isDirectPurchase: false,
    licensingPartner: {},
    surveyType: {},
    tier: {},
    remainingLicenses: NaN,
    maxUsers: NaN,
    teamReportCount: NaN,
    sessionCount: NaN,
    smallGroupCount: NaN,
    participantCount: NaN,
    preferredPreassessments: {},
  };
  groupAdmins: any[] = [];

  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {}

  /**
   * loadGroup()
   *
   * function: modifies internal component state
   *
   * validate  group id then load the  group information used in the card
   * separate from $onInit because we call this function again if the $watched groupid changes
   *
   * called by watch on $ctrl.groupId
   */
  loadGroup(groupId: string): void {
    if (typeof groupId !== 'string') {
      throw new Error('loadGroup() requires a valid string groupId parameter.');
    }

    forkJoin([
      this.groupService.get(groupId),
      this.userService.queryGroupRoleIndex(groupId, 'groupadmin'), // Queries all the group admins for the specific group
    ])
      .pipe(
        tap(([group, admins]) => {
          this.group = group;
          this.groupAdmins = admins;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
