<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<label class="control-label">Onboarding Image</label>
<p>
  By adding an onboarding image, you can further personalize your participants
  IPSAT experience and tailor it to your group or organization.
  <br />
  <i
    >For the best results, make sure to choose a square photo, otherwise the
    aspect ratio may be skewed.</i
  >
</p>
<br />
<div *ngIf="existingPhotos.length < 1">
  <app-nexleader-file-picker
    (onSelect)="onSelect($event)"
    (onLoad)="onLoad($event.uri)"
    [accept]="'image/png, image/jpeg'"
  ></app-nexleader-file-picker>
  <span *ngIf="file?.name"
    >Selected Image: <i>{{ file.name }}</i></span
  >
  <span *ngIf="!file?.name">No image selected.</span>
  <button
    class="btn btn-primary pull-right"
    [attr.disabled]="!(data && file?.name) ? true : null"
    (click)="upload()"
  >
    <i class="fa fa-upload"></i>&nbsp;&nbsp; Upload Image
  </button>
</div>
<div *ngIf="existingPhotos.length > 0">
  <h5 class="text-primary-2 m-b-sm">Current Group Photo</h5>
  <div *ngFor="let photo of existingPhotos">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <!-- TODO test image -->
        <img
          [src]="photo.path"
          alt="group photo"
          class="img-full"
          [ngClass]="{
            'img-circle': group.customOnboarding.shouldRoundGroupPhoto
          }"
        />
      </div>
      <div class="col-sm-6 col-xs-12">
        <p class="font-semi-bold m-b-sm">
          Uploaded on {{ photo.file.uploadDate | date }}
        </p>
        <div class="form-group m-b-md">
          <div class="checkbox">
            <input
              type="checkbox"
              [(ngModel)]="group.customOnboarding.shouldRoundGroupPhoto"
              (ngModelChange)="saveGroup()"
              id="shouldRoundGroupPhoto"
            />
            <label for="shouldRoundGroupPhoto" class="control-label"
              >Round Group Photo</label
            >
            <br />
            <span class="small-text">
              If you are uploading a profile photo that is square, we recommend
              that you check this as it will give your group photo a more
              personalized look.
            </span>
          </div>
        </div>
        <button class="btn btn-default" (click)="deletePhoto(photo)">
          Delete Photo
        </button>
      </div>
    </div>
  </div>
</div>
