/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
/* global angular */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-nexleader-wistia-video',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wistia-video.component.html',
})
export class NexleaderWistiaVideoComponent implements OnInit {
  /**
   * wistiaId
   *
   * string
   *
   * A string representing the ID of the Wistia video we want to embed.
   */
  @Input() wistiaId: string | undefined;
  videoUrl: SafeResourceUrl | undefined;

  /**
   * WISTIA_PLAYER_URL_PREFIX
   *
   * @type {string} the prefix of the URL preprended to the video id before handing it off to the wistia embed code
   */
  private readonly WISTIA_PLAYER_URL_PREFIX = '//fast.wistia.net/embed/iframe/';

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // Ensure we have the YouTube ID
    if (!this.wistiaId) {
      throw new Error(
        'nexleaderDataWistiaId is required for nexleaderComponentWistiaVideo'
      );
    }

    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.WISTIA_PLAYER_URL_PREFIX + this.wistiaId
    );
  }
}
