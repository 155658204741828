import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user.service';
import { EMPTY, catchError } from 'rxjs';
import { SkillsInventoryResultService } from '../../../../services/skills-inventory-result.service';
import { CommonModule } from '@angular/common';
import { NexleaderSkillsInventoryBarComponent } from '../skills-inventory-bar/skills-inventory-bar.component';
import { NexleaderSkillsInventorySkillChartComponent } from '../skills-inventory-skill-chart/skills-inventory-skill-chart.component';

@Component({
  selector: 'app-nexleader-skills-inventory-result',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NexleaderSkillsInventoryBarComponent,
    NexleaderSkillsInventorySkillChartComponent,
  ],
  templateUrl: './skills-inventory-result.component.html',
})
export class NexleaderSkillsInventoryResultComponent implements OnInit {
  @Input() userId: string | null = null;
  @Input() skillsInventoryResultId: string | null = null;

  user: any;
  scoredSkillsInventoryResult: any; // Replace with the actual type
  possessivePrefix: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private success: SuccessService,
    private error: ErrorHandlerService,
    private auth: AuthService,
    private userService: UserService,
    private skillsInventoryResultService: SkillsInventoryResultService
  ) { }

  ngOnInit(): void {
    if (!this.userId) {
      console.error(
        'userId required to render SkillsInventoryResult; needed in bindings'
      );
      return;
    }

    if (!this.skillsInventoryResultId) {
      console.error(
        'skillsInventoryResultId required to render SkillsInventoryResult; needed in bindings'
      );
      return;
    }

    this.userService
      .getUser(this.userId)
      .pipe(
        catchError((error) => {
          this.error.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((user) => {
        this.user = user;
        this.possessivePrefix =
          this.user._id === this.auth.getTokenPayload().user
            ? 'Your'
            : `${this.user.firstName}'s`;
      });

    this.skillsInventoryResultService
      .getScore(this.skillsInventoryResultId)
      .pipe(
        catchError((error) => {
          this.error.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((scoredSkillsInventoryResult) => {
        this.scoredSkillsInventoryResult = scoredSkillsInventoryResult;
      });
  }
}
