/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderSkillsInventoryResultComponent } from '../../components/skills-inventory-result/skills-inventory-result.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-skills-inventory-result-view-full-screen',
  standalone: true,
  imports: [CommonModule, NexleaderSkillsInventoryResultComponent],
  templateUrl: './skills-inventory-result-view-full-screen.component.html'
})
export class NexleaderSkillsInventoryResultViewFullScreenComponent implements OnInit {
  routeParams: any;
  userId!: string;
  skillsInventoryResultId!: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;

    if (!this.routeParams['user_id']) {
      console.error('user_id required to render SkillsInventoryResult; needed in route parameters');
      return;
    }

    if (!this.routeParams['skills_inventory_result_id']) {
      console.error('skills_inventory_result_id required to render SkillsInventoryResult; needed in route parameters');
      return;
    }

    this.userId = this.routeParams['user_id'];
    this.skillsInventoryResultId = this.routeParams['skills_inventory_result_id'];
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSkillsInventoryResultViewFullScreenComponent',
//   downgradeComponent({
//     component: NexleaderSkillsInventoryResultViewFullScreenComponent,
//   }) as angular.IDirectiveFactory
// );

