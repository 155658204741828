/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderUserCard
 *
 * component
 *
 * displays a card representing a single user
 *  used in the CoachUsers view
 *
 * may mutate of the state of users via two-way binding
 *  an edit button will make edits; archive button will archive
 *
 * changes are saved automatically via the resource service
 */
import { tap, catchError, of } from 'rxjs';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderEditUserModalComponent } from '../modals/edit-user-modal/edit-user-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-user-card',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './user-card.component.html',
})
export class NexleaderUserCardComponent {
  private _userId: string = '';

  /**
   * UserId
   *
   * string binding
   *
   * the id of the  user we're rending a card for
   */
  @Input() set userId(value: string) {
    if (!value || typeof value !== 'string') {
      throw new Error('nexleaderUserCard requires a string param userId');
    }

    this._userId = value;

    // If there is not a current viewing role for the user card, assume they are a coach (limited access)
    this.currentViewingRole = this.currentViewingRole || 'coach';
    this.load();
  }

  get userId() {
    return this._userId;
  }

  /**
   * currentViewingRole
   *
   * string binding
   *
   * The current role of the user that is viewing this user card.
   */
  @Input() currentViewingRole!: string;

  /**
   * onChange
   *
   * expression binding: event handler
   *
   * called after saving a change to the  user
   */
  @Output() onChange = new EventEmitter<any>();

  user: any;
  enums: any;

  constructor(
    private userService: UserService,
    private enumsService: EnumsService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: BsModalService,
    private successHandlerService: SuccessService
  ) { }

  /**
   * load()
   *
   * function: modifies internal component state
   *
   * validate  user id then load the  user information used in the card
   * separate from $onInit because we call this function again if the $watched userid changes
   *
   * called by watch on $ctrl.userId
   */
  load(): void {
    if (!this.userId || typeof this.userId !== 'string') {
      throw new Error('load() requires a string param userId');
    }

    this.userService
      .getUser(this.userId)
      .pipe(
        tap((user) => {
          this.user = user;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.enums = enums;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  deleteUser(): void {
    if (this.user.completedIpsat) {
      this.userService
        .moveToLegacy(this.user._id)
        .pipe(
          tap(() => {
            this.successHandlerService.handle({
              message: 'Successfully moved user to Legacy Group.',
            });
            this.load();
            this.onChange.emit();
          }),
          catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.userService
        .delete(this.user._id)
        .pipe(
          tap(() => {
            this.successHandlerService.handle({
              message: 'Successfully deleted user.',
            });
            this.onChange.emit();
          }),
          catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    }
  }

  open(id: string): void {
    const modalRef = this.modalService.show(NexleaderEditUserModalComponent, {
      initialState: {
        id: id,
        currentViewingRole: this.currentViewingRole,
      },
    });

    modalRef.content?.close.subscribe(() => {
      modalRef.hide();
      this.onChange.emit();
      this.load();
      this.successHandlerService.handle();
    });
  }

  // saveUser(user: any): void {
  //   this.userService
  //     .saveGroup(user.group._id, user._id, user)
  //     .pipe(
  //       tap(() => {
  //         this.successHandlerService.handle();
  //         this.load(this.userId);
  //       }),
  //       catchError((error) => {
  //         this.errorHandlerService.handleError(error);
  //         return of(null);
  //       })
  //     )
  //     .subscribe();
  // }

  sendEvaluationEmail(user: any): void {
    this.userService
      .sendCoachEvaluationEmail(user._id)
      .pipe(
        tap(() => {
          this.successHandlerService.handle({ message: 'Email sent' });
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
