<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div
  id="nexleader-test-onboarding-take-ipsat"
  class="flex flex-column-md h-100-auto justify-content-center overflow-y-scroll nexleader-onboarding-take-ipsat bg-white"
>
  <div
    class="col-sm-6 m-t-a-md m-b-a-md"
    *ngIf="canViewFaithBasedTakeIpsatVideo()"
  >
    <div class="measure">
      <h4 class="text-primary-2 font-semi-bold m-b-sm">
        Before you continue with the Self Awareness and Self Authoring steps of
        the IPSAT
      </h4>
      <p>
        Please watch this video tutorial before continuing the IPSAT process.
        Feedback from users confirms engaging this content greatly enhances the
        quality of the IPSAT experience.
      </p>
      <br />
      <div class="nexleader-onboarding-video-container">
        <app-nexleader-vimeo-video
          [vimeoId]="'357854721'"
        ></app-nexleader-vimeo-video>
      </div>
      <br />
      <h5 class="text-primary-2 font-semi-bold m-b-sm">
        Identity Overview Statement Worksheet
      </h5>
      <p>
        <a
          href="/assets/files/IPSAT_Identity_Overview_Worksheet_Faith.pdf"
          target="_blank"
          >Download</a
        >
        this worksheet, referenced in the video tutorial, for step-by-step
        instructions on how to write your Identity Overview Statement.
      </p>
      <br />
      <h5 class="text-primary-2 font-semi-bold m-b-sm">
        Review the Results of your Pre-IPSAT assessments
      </h5>
      <p>
        Click <a (click)="openReviewPrerequisitesModal()">here</a>
        to access the results you entered from the five Pre-IPSAT assessments.
      </p>
    </div>
  </div>
  <div
    class="col-sm-6 m-t-a-md m-b-a-md"
    *ngIf="canViewValuesBasedTakeIpsatVideo()"
  >
    <div class="measure">
      <h4 class="text-primary-2 font-semi-bold m-b-sm">
        Before you take the IPSAT
      </h4>
      <p>
        Please watch this video tutorial before continuing the IPSAT process.
        Feedback from users confirms engaging this content greatly enhances the
        quality of the IPSAT experience.
      </p>
      <br />
      <div class="nexleader-onboarding-video-container">
        <app-nexleader-vimeo-video
          [vimeoId]="'357856509'"
        ></app-nexleader-vimeo-video>
      </div>
      <br />
      <h5 class="text-primary-2 font-semi-bold m-b-sm">
        Identity Overview Statement Worksheet
      </h5>
      <p>
        <a
          href="/files/IPSAT_Identity_Overview_Worksheet_Values.pdf"
          target="_blank"
          >Download</a
        >
        this worksheet, referenced in the video tutorial, for step-by-step
        instructions on how to write your Identity Overview Statement.
      </p>
      <br />
      <h5 class="text-primary-2 font-semi-bold m-b-sm">
        Review the Results of your Pre-IPSAT assessments
      </h5>
      <p>
        Click <a (click)="openReviewPrerequisitesModal()">here</a>
        to access the results you entered from the five Pre-IPSAT assessments.
      </p>
    </div>
  </div>
  <div class="col-sm-6 m-t-a-md m-b-a-md">
    <div class="measure m-t-lg m-b-lg">
      <div class="text-center m-b-lg">
        <h1 class="text-success"><i class="fa fa-check fa-2x"></i></h1>
        <h2 *ngIf="!userIsInSmallGroup">
          Thanks for completing the Identity Profile Exercise portion of the
          onboarding process.
        </h2>
        <h2 *ngIf="userIsInSmallGroup">You Have Completed Modules 1-6!</h2>
      </div>
      <div class="hpanel hbggreen m-b-md" *ngIf="userIsInSmallGroup">
        <div class="panel-body">
          <p>
            After completing the IPSAT you will exit the onboarding process and
            be directed to your dashboard where you can view your report. Please
            print your report and bring a copy with you to Module 7 for the
            final meeting of your small group.
          </p>
        </div>
      </div>
      <p class="m-b-md">
        <b
          >Estimated time to complete the Self Awareness and Self Authoring
          steps: 30-45 minutes</b
        >
      </p>
      <div class="m-b-md">
        If you don’t have time to engage the final 2 steps now, you can logout
        and return later. You will be redirected to this page on your next
        login.
      </div>
      <p class="m-b-sm">
        <b>Tips for the final 2 steps:</b>
      </p>
      <ul class="m-b-md">
        <li>
          If you exit before submitting your responses, the information will NOT
          be saved!
        </li>
        <li>
          We strongly encourage you to watch the video tutorial and engage the
          Identity Overview Statement Worksheet before taking the next step.
        </li>
        <li>
          Your coach will help you reflect on and edit your self-authored
          statements.
        </li>
      </ul>
      <hr />
      <p class="m-b-sm"><b>Disclaimer</b></p>
      <p>
        I understand my coach is not functioning in the role of a professional
        counselor, psychologist, or therapist, and I am fully responsible for
        the choices/actions that emerge from my coaching session(s).
      </p>
      <ng-container *ngIf="user">
        <div class="row">
          <div
            class="col-sm-6 m-t-md"
            *ngIf="!user.prerequisites.disclaimerAcknowledged"
          >
            <button
              class="btn btn-block btn-primary"
              (click)="setDisclaimerAcknowledged(true)"
            >
              I Agree
            </button>
          </div>
          <div
            class="col-sm-6 m-t-md"
            *ngIf="user.prerequisites.disclaimerAcknowledged"
          >
            <button
              class="btn btn-block btn-default"
              (click)="setDisclaimerAcknowledged(false)"
            >
              Undo
            </button>
          </div>
          <div class="col-sm-6 m-t-md">
            <a
              class="btn btn-block btn-success"
              [attr.disabled]="!canProceed() ? true : null"
              [routerLink]="['/users', user._id, 'surveys', survey._id]"
            >
              <i class="fa fa-diamond"></i>&nbsp;&nbsp;Take the IPSAT
            </a>
          </div>
        </div>
        <div *ngIf="!valid">
          <hr />
          <span class="red"
            >Your prerequisites are invalid or may be outdated. Please take a
            moment to review them before attempting to take the IPSAT.</span
          >
        </div>
        <div *ngIf="!user.prerequisites.disclaimerAcknowledged">
          <hr />
          <span class="red"
            >You cannot take the IPSAT until you acknowledge the
            disclaimer.</span
          >
        </div>
      </ng-container>
      <div class="text-center" *ngIf="showGoBack">
        <hr />
        Not ready to continue? <a (click)="goBack()">Go Back</a>
      </div>
    </div>
  </div>
</div>
