/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderTeamReportProgressBar
 *
 * angular component
 *
 * This is a basic bootstrap progress bar intended for use in the upper right hand corner of each section of the team
 *  report.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-team-report-progress-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './team-report-progress-bar.component.html',
})
export class NexleaderTeamReportProgressBarComponent implements OnInit {
  @Input() max!: number;
  @Input() value!: number;
  @Input() category: string = '';

  roundedValue!: number;
  percentage!: number;

  ngOnInit(): void {
    if (isNaN(this.value)) {
      throw new Error(
        "Numeric property 'value' is required for NexleaderTeamReportProgressBarComponent"
      );
    }

    if (isNaN(this.max)) {
      throw new Error(
        "Numeric property 'max' is required for NexleaderTeamReportProgressBarComponent"
      );
    }

    if (!this.category || typeof this.category !== 'string') {
      throw new Error(
        "String property 'category' is required for NexleaderTeamReportProgressBarComponent"
      );
    }

    // Sometimes the value can be a decimal number, as division does not always return an integer
    this.roundedValue = Math.round(this.value);

    this.percentage = (this.value / this.max) * 100;
  }
}
