<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div
  class="color-line"
  [ngClass]="{ 'color-line-danger': !hasEnoughLicenses }"
></div>
<div class="modal-header">
  <h3 class="modal-title">Invite a New User</h3>
</div>
<div class="modal-body" *ngIf="hasEnoughLicenses">
  <div class="row">
    <div class="col-md-12 m-b-md">
      <p>Inviting a user sends an email and link allowing them to login.</p>
    </div>
    <div class="col-md-12 m-b-md" *ngIf="group && shouldShowGroupInformation()">
      <p><b>Group:</b> {{ group.name }}</p>
      <p><b>Licensing Partner:</b> {{ group.licensingPartner.name }}</p>
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">First Name</label>
      <input
        type="text"
        placeholder="John"
        class="form-control"
        [(ngModel)]="invite.firstName"
      />
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Last Name</label>
      <input
        type="text"
        placeholder="Smith"
        class="form-control"
        [(ngModel)]="invite.lastName"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="control-label">Email</label>
    <input
      type="email"
      placeholder="john.smith@gmail.com"
      class="form-control"
      [(ngModel)]="invite.email"
    />
  </div>
  <app-nexleader-input-role [model]="invite.roles"></app-nexleader-input-role>
  <div class="form-group">
    <a (click)="enableSmallGroup()" *ngIf="!placeUserInSmallGroup"
      >Assign User to a Small Group</a
    >
    <a (click)="disableSmallGroup()" *ngIf="placeUserInSmallGroup"
      >Assign User Directly to a Coach</a
    >
  </div>
  <div class="form-group" *ngIf="placeUserInSmallGroup">
    <label class="control-label">Small Group</label>
    <select class="form-control" [(ngModel)]="invite.smallGroup">
      <option [ngValue]="null">-- Select a small group --</option>
      <option
        *ngFor="let smallGroup of smallGroups; trackBy: trackById"
        [ngValue]="smallGroup"
      >
        {{ smallGroup.name }}
      </option>
    </select>
    <div class="m-t-sm">
      <p *ngIf="invite.smallGroup">
        <b>Coach:</b> {{ invite.smallGroup.smallGroupLeader.firstName }}
        {{ invite.smallGroup.smallGroupLeader.lastName }}
      </p>
      <p *ngIf="!invite.smallGroup">
        Coach will be assigned to the leader of the small group you select.
      </p>
    </div>
  </div>
  <div class="form-group" *ngIf="!placeUserInSmallGroup">
    <label class="control-label">Coach</label>
    <select class="form-control" [(ngModel)]="invite.coach">
      <option [ngValue]="null">-- Select a coach --</option>
      <option
        *ngFor="let coach of coaches; trackBy: trackById"
        [ngValue]="coach._id"
      >
        {{ coach.firstName + " " + coach.lastName }}
      </option>
    </select>
  </div>
</div>
<div class="modal-footer" *ngIf="hasEnoughLicenses">
  <button class="btn btn-success" type="button" (click)="saveInvite(invite)">
    Invite User
  </button>
</div>
<div class="modal-body" *ngIf="!hasEnoughLicenses">
  <p class="text-center">
    <i class="fa fa-5x fa-shopping-cart text-danger"></i>
    <br />
    <br />
    You've run out of licenses! Contact our support team to purchase more.
    <br /><br />
    <a
      href="mailto:angeline.patrick@nexleader.com?subject=Interested in purchasing more licenses&body=I'm interested in purchasing more licenses, please contact me at:"
      >angeline.patrick&#64;nexleader.com</a
    >
  </p>
</div>
