/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderAddCoachUserModal
 *
 * angular component
 *
 * Allows a user to add a coach user as a multi group coach to their group. This permission is given to the licensing
 *  partner as they are able to add coach users within their groups to other groups.
 */
import { SuccessService } from './../../../../services/success.service';
import { CoachUserService } from './../../../../services/coach-user.service';
import { GroupService } from './../../../../services/group.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-add-coach-user-modal',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './add-coach-user-modal.component.html',
})
export class NexleaderAddCoachUserModalComponent implements OnInit {
  /**
   * licensingPartnerId
   *
   * string
   *
   * An ID of string of the licensing partner in which the group belongs to.
   */
  licensingPartnerId: string = '';
  routeParams: any;

  /**
   * close()
   *
   * function
   *
   * Helper function to close the modal.
   */
  @Output() close = new EventEmitter<any>();

  hasEnoughLicenses: boolean = true;
  selectedCoachUser: any = null;
  search = { firstName: '', lastName: '' };
  maxCoachUserSelect: number = 15; //Show at most 15 users in the dropdown. Make them filter if there are more.
  group: any;
  coachUsers: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private successService: SuccessService,
    private errorHandler: ErrorHandlerService,
    private coachUserService: CoachUserService
  ) { }

  // Validate input
  ngOnInit(): void {
    if (
      !this.licensingPartnerId ||
      typeof this.licensingPartnerId !== 'string'
    ) {
      throw new Error(
        'nexleaderAddCoachUserModal param licensingPartnerId must be a string'
      );
    }

    const groupId = this.routeParams['group_id'];

    this.groupService
      .get(groupId)
      .pipe(
        tap((group) => {
          this.group = group;
          this.hasEnoughLicenses = this.group.remainingLicenses > 0;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.coachUserService
      .getAllCoachUser()
      .pipe(
        tap((coachUsers) => {
          this.coachUsers = coachUsers;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  trackById(index: number, item: any) {
    return item._id;
  }

  addCoachUser(coachUser: any): void {
    if (this.filterCoachUsers().length === 1) {
      coachUser = this.filterCoachUsers()[0];
    }

    if (!coachUser) {
      console.error(
        'CoachUser must be provided to add a coachUser to a group in modal directive',
        coachUser
      );
      return;
    }

    this.coachUserService
      .addToGroup(coachUser._id, this.group._id)
      .pipe(
        tap(() => {
          this.successService.handle();
          this.close.emit();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  filterCoachUsers(): any[] {
    if (!this.coachUsers) {
      return [];
    }

    return this.coachUsers.filter(
      (coachUser) =>
        (this.search.firstName === '' ||
          coachUser.firstName
            .toLowerCase()
            .includes(this.search.firstName.toLowerCase())) &&
        (this.search.lastName === '' ||
          coachUser.lastName
            .toLowerCase()
            .includes(this.search.lastName.toLowerCase()))
    );
  }

  submitDisabled(): boolean {
    return !this.selectedCoachUser && this.filterCoachUsers().length !== 1;
  }
}
