<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="container p-v-50-sm">
  <div class="row">
    <div class="col-md-3 col-xs-12 hide-mobile">
      <app-nexleader-component-pvi-assessment-selected-values
        *ngIf="topTwelveValues"
        [selectedValues]="topTwelveValues"
        [selectedValue]="selectedValue"
        [shouldNotShowDomain]="true"
        [shouldNotShowRemove]="true"
      ></app-nexleader-component-pvi-assessment-selected-values>
    </div>
    <div class="col-md-9 col-xs-12">
      <p class="m-b-md h4">
        Rank the 12 personal values you have selected. In this force ranking
        exercise, just think about the value, not the domain. To what extent
        does this value impact your daily decisions, actions, or interaction
        with others?
      </p>
      <div class="hpanel m-b-md">
        <div class="panel-body">
          <h4 class="text-primary-2 m-b-md h4">
            Use the slider to show where the value
            <span class="text-success">{{ selectedValue.name }}</span> fits on
            the continuum below…
          </h4>
          <div>
            <app-nexleader-input-range
              id="nexleader-test-pvi-rate-range-selector"
              [model]="selectedValue.rating"
              [rangeMin]="0"
              [rangeMax]="10"
              [rangeStep]="0.001"
              (realOnChange)="selectedValue.rating = $event"
            ></app-nexleader-input-range>
          </div>
          <div class="flex flex-space-between m-t-lg">
            <p class="font-semi-bold">
              sometimes<span class="hide-mobile-inline">/should</span>
            </p>
            <p class="font-semi-bold">
              mostly<span class="hide-mobile-inline">/ought</span>
            </p>
            <p class="font-semi-bold">
              always<span class="hide-mobile-inline">/must</span>
            </p>
          </div>
        </div>
      </div>
      <div class="flex">
        <button
          class="btn btn-default"
          id="nexleader-test-pvi-rate-range-previous"
          (click)="moveToPreviousValue()"
          [class.is-hidden]="!canMoveToPreviousValue()"
        >
          Previous Value
        </button>
        <div class="flex-grow"></div>
        <button
          class="btn btn-default"
          id="nexleader-test-pvi-rate-range-next"
          (click)="moveToNextValue()"
          [class.is-hidden]="!canMoveToNextValue()"
        >
          Next Value
        </button>
      </div>
    </div>
  </div>
</div>
