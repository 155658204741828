/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/*global StripeCheckout*/
import { Injectable } from '@angular/core';
import { StripeKeyService } from '../features/store/resources/stripe-key.service';
import { ProductService } from '../features/store/resources/product.service';
import { Observable, catchError, of, shareReplay, take, tap } from 'rxjs';
declare var Stripe: any;
@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  // Load the key when the service loads
  private stripe: any;
  private _key$: Observable<string | null>;

  constructor(
    private productService: ProductService,
    private stripeKeyService: StripeKeyService
  ) {
    // Load the key when the service loads
    this._key$ = this.stripeKeyService
      .get()
      .pipe(
        tap((result) => {
          this.stripe = Stripe(result.stripeKey);
        }),
        catchError((error) => {
          return of(null);
        })
      ).pipe(shareReplay());
  }

  createStripeElements(currency: string, amount: number) {
    return new Promise((resolve, reject) => {
      this._key$.pipe(take(1)).subscribe(key => {
        if (!key) {
          reject({
            message:
              'No stripe key loaded in StripePayment service; Either a call was made to payment before ' +
              ' the key could load, or no key was loaded in the backend config file. Check config.js.',
            status: 0,
          });
          return;
        }
        resolve(
          this.stripe.elements({
            mode: 'payment',
            currency,
            amount,
          })
        );
      })
    });
  }

  createStripeToken(cardElement: any) {
    return new Promise((resolve, reject) => {
      this._key$.pipe(take(1)).subscribe(key => {
        if (!key) {
          reject({
            message:
              'No stripe key loaded in StripePayment service; Either a call was made to payment before ' +
              ' the key could load, or no key was loaded in the backend config file. Check config.js.',
            status: 0,
          });
          return;
        }
        this.stripe.createToken(cardElement).then((response: any) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response.token);
          }
        });
      })

    });
  }

  /**
   * StripePayment.handleTeamReport
   *
   * Handle the stripe payment popup for purchasing a team report
   *
   * If calculatedPriceInUSCents, we don't do a stripe checkout. We just callback with null.
   *  No payment token is required if the user doesn't have to pay.
   *
   * ex:
   * StripePayment.handleTeamReport(3, 5000, function (err, token) {})
   *
   * @param {number} userCount - The number of users the team admin is purchasing for the team report
   * @param {number} price - The calculated price of the entire team report, calculated using US cents
   * @param {Function} callback - A return callback
   */
  handleTeamReport(userCount: number, price: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (price <= 0) {
        resolve(null);
        return;
      }
      window.addEventListener('popstate', () => {
        resolve(null);
      });
      // Make sure the key is loaded or throw an error.
      this._key$.pipe(take(1)).subscribe(key => {
        if (!key) {
          reject({
            message:
              'No stripe key loaded in StripePayment service; Either a call was made to payment before ' +
              ' the key could load, or no key was loaded in the backend config file. Check config.js.',
            status: 0,
          });
          return;
        }

        //Configure the StripeCheckout handler
        const handler = (window as any).StripeCheckout.configure({
          key: key,
          locale: 'auto',
          token: (token: any) => {
            resolve(token);
          },
          closed: () => {
            reject({ isCloseEvent: true });
          },
        });
        //Open the StripeCheckout handler
        handler.open({
          name: 'IPSAT Team Report',
          description: `nexleader IPSAT TeamReport for ${userCount} users`,
        });
      })
    });
  }

  handlePayment(
    token: any,
    id: string,
    amount: number,
    user: any
  ): Observable<any> {
    return this.productService.buyProduct(user.group._id, {
      stripeToken: token,
      product_id: id,
      quantity: amount,
      user: user,
      group_id: user.group._id,
    });
  }
}
