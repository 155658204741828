/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SessionService } from '../session/session.service';
import { Observable, catchError, combineLatest, of, take, tap } from 'rxjs';
import { GroupService } from '../../../../services/group.service';
import { UserService } from '../../../../services/user.service';
import { NexleaderCalendarComponent } from '../../../core/components/calendar/calendar.component';
import { NexleaderSessionComponent } from '../session/session.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderNewSessionModalComponent } from '../../components/modals/new-session-modal/new-session-modal.component';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nexleader-sessions',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderCalendarComponent,
    NexleaderSessionComponent,
  ],
  templateUrl: './sessions.component.html',
})
export class NexleaderSessionsComponent implements OnInit {
  @Input() coach: any;
  @Input() coachUser: any;
  @Input() group: any;
  @Input() licensingPartner: any;
  @Input() isSuperAdmin: boolean = false;

  routeParams: any;
  selectableUsers: any[] = [];
  selectableGroups: any[] = [];
  showGroupSelectionDialog = false;
  showUserSelectionDialogWithName: string | null = null;
  selectedUser: any;
  selectedGroup: any;
  selectedGroupId: string = '';
  events: any[] = [];
  filteredEvents: any[] = [];
  currentEvent: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private groupService: GroupService,
    private userService: UserService,
    private modalService: BsModalService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  closeModal() {
    this.modalService.hide();
    this.load();
  }

  trackById(index: number, item: any): number {
    return item._id;
  }

  load(): void {
    const search = this.route.snapshot.queryParams;
    // const search = this.routerHelper.fetchQueryParams();
    // const search =  this.route.snapshot.queryParams;
    const observables: Observable<any>[] = [];

    // If user is super admin, load all the sessions
    if (this.isSuperAdmin) {
      observables.push(this.sessionService.queryIndex());
      this.showGroupSelectionDialog = true;

      observables.push(
        this.groupService.queryIndex().pipe(
          tap((group) => {
            this.selectableGroups = group;
          })
        )
      );
    } else {
      //If user is not super admin, query by coach or participant
      // coach takes precedence over coachUser. This is important when users are selected
      if (this.coach) {
        observables.push(this.sessionService.queryCoach(this.coach._id));
      }

      if (this.coachUser) {
        observables.push(
          this.sessionService.queryCoachUser(this.coachUser._id)
        );

        //Show a user selection dialog labeled as group and with group names
        // to select the coach object for a given group
        this.showUserSelectionDialogWithName = 'Group';
        observables.push(
          this.userService.queryCoachUser(this.coachUser._id).pipe(
            tap((coachUser) => {
              //Remove the users the coachUser has archived. We don't want to see them in this view.
              this.selectableUsers = coachUser.filter(
                (user) => !user.archivedByCoachUser
              );
              this.selectableUsers.forEach(
                (user) => (user.display_name = user.group.name)
              );
            })
          )
        );
      }

      if (this.group) {
        this.selectedGroup = this.group;
        observables.push(this.sessionService.queryGroup(this.group._id));
      }

      if (this.licensingPartner) {
        this.showGroupSelectionDialog = true;
        observables.push(
          this.sessionService.queryLicensingPartner(this.licensingPartner._id)
        );
        observables.push(
          this.groupService
            .queryLicensingPartnerIndex(this.licensingPartner._id)
            .pipe(
              tap((group) => {
                this.selectableGroups = group;
              })
            )
        );
      }
    }

    if (observables.length < 1) {
      console.warn(
        "No query object selected for sessions. You can query by coach, consolidated coachUser, or participant. You can also get them all if you're the super admin"
      );
    }

    // Set selected user to null while we load (and if there's no selection from $route.snapshot.queryParams)
    if (this.showUserSelectionDialogWithName) {
      this.selectedUser = null;
    }
    if (this.showGroupSelectionDialog) {
      this.selectedGroup = null;
    }

    combineLatest(observables)
      .pipe(
        tap((results) => {
          this.events = results[0].filter((session: any) => {
            if (
              !(
                session &&
                session.participant &&
                session.coach &&
                session.coach.lastName &&
                session.coach.firstName &&
                session.participant.firstName &&
                session.participant.lastName &&
                session.start &&
                session.end
              )
            ) {
              console.warn('Malformed session in sessions directive', session);
              return false;
            }
            session.title = `${session.coach.firstName} ${session.coach.lastName} coaches ${session.participant.firstName} ${session.participant.lastName}`;
            return true;
          });

          if (this.showUserSelectionDialogWithName && search['selectedUser']) {
            this.selectableUsers.forEach((user) => {
              if (user._id === search['selectedUser']) {
                this.selectedUser = user;
              }
            });
          }

          if (this.showGroupSelectionDialog && search['selectedGroup']) {
            this.selectedGroup = this.selectableGroups.find(
              (group) => group._id === search['selectedGroup']
            );
          }

          this.updateFilteredEvents();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  getFilteredEvents(): any[] {
    if (!(this.selectedUser || this.selectedGroup)) return this.events;
    if (this.selectedUser && this.selectedGroup) {
      console.error('Invalid filter criteria.');
      return [];
    }
    if (this.selectedUser) {
      return this.events.filter(
        (session) => session.coach._id === this.selectedUser._id
      );
    } else {
      return this.events.filter(
        (session) => session.coach.group._id === this.selectedGroup._id
      );
    }
  }

  updateFilteredEvents(): void {
    this.filteredEvents = this.getFilteredEvents();
    this.group = this.selectedGroup; // Do this so that newSession gets a copy of our group
  }

  onSelectedUserChange(): void {
    this.router.navigate([], {
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: { selectedUser: this.selectedUser?._id ?? '' }
    });
    this.updateFilteredEvents();
  }

  onSelectedGroupChange(): void {
    this.router.navigate([], {
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: { selectedGroup: this.selectedGroup?._id ?? '' }
    });
    this.updateFilteredEvents();
  }

  disableNewSession(): boolean {
    return !(this.group || this.selectedUser || this.coach);
  }

  newSession(): void {
    const modalRef = this.modalService.show(NexleaderNewSessionModalComponent, {
      initialState: {
        group: this.group,
        coach: this.selectedUser,
        routeParams: this.routeParams,
      },
    });
    modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.load();
    });
  }
}
