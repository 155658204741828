import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../../environments/environment';
import { EnumsService } from '../../../../../services/enum.service';
import { tap } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-onboarding-module-intro',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './onboarding-module-intro.component.html'
})
export class NexleaderOnboardingModuleIntroComponent implements OnInit {
  /**
   * moduleIdentifier
   *
   * An identifier we can use to reference all modules and then select a specific one from it
   */
  @Input() moduleIdentifier!: string;

  /**
   * completionCallback
   *
   * A callback function used to redirect or change a page after the user changes the completionBind
   */
  @Output() completionCallback = new EventEmitter<void>();
  completionBind: any;
  allOnboardingSteps: any;
  selectedModuleContext: any;
  userIsOnIpsatStage: boolean = false;

  constructor(private enumsService: EnumsService) { }

  ngOnInit() {
    this.enumsService.getEnums().pipe(
      tap((a) => {
        this.allOnboardingSteps = a.ModuleContexts;
        this.selectedModuleContext = this.allOnboardingSteps[this.moduleIdentifier.toUpperCase()];

        // We need a way to reference if the user is on the final stage, as this shows custom copy
        this.userIsOnIpsatStage = this.moduleIdentifier === a.ModuleContexts.IPSAT.identifier;
      })
    ).subscribe();
  }
}
