/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-coach-eval-thank-you',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './coach-eval-thank-you.component.html'
})
export class NexleaderCoachEvalThankYouComponent {

}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCoachEvalThankYouComponent',
//   downgradeComponent({
//     component: NexleaderCoachEvalThankYouComponent,
//   }) as angular.IDirectiveFactory
// );
