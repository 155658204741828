<div class="panel-body">
  <div class="pull-right">
    <p class="text-success font-bold">{{ question.questionIndex }}</p>
  </div>
  <p>Rank yourself for...</p>
  <h3 class="font-bold m-b-md">{{ question.name }}</h3>
  <div class="nexleader-ipsat-skills-inventory-question-wrapper">
    <div class="nexleader-ipsat-skills-inventory-slider m-t-md">
      <a
        *ngFor="let option of question.options; let first = first"
        class="nexleader-ipsat-skills-inventory-option-step"
        [class.active]="
          question.selectedOption?.identifier === option.identifier
        "
        (click)="selectQuestionOption(option)"
      >
        <span
          class="nexleader-ipsat-skills-inventory-question-section"
          *ngIf="!first"
        ></span>
        <div class="nexleader-ipsat-skills-inventory-question-circle"></div>
        <p
          class="m-b-xs nexleader-ipsat-skills-inventory-circle-sm"
          [class.active]="
            question.selectedOption?.identifier === option.identifier
          "
        >
          {{ option.name }}
        </p>
        <small class="font-bold text-muted" *ngIf="option.additionalText">{{
          option.additionalText
        }}</small>
      </a>
    </div>
  </div>
  <div *ngIf="question.selectedOption?.name > 7">
    <div class="m-t-md m-b-md">
      <hr />
    </div>
    <p>
      Since you ranked yourself a<span
        *ngIf="question.selectedOption?.name === '8'"
        >n</span
      >
      <b>{{ question.selectedOption?.name }}</b
      >, please use the text box to give an example of how/where you have been
      able to use this skill in the last six months that helps explain why you
      chose this high rating.
    </p>
    <br />
    <input
      class="form-control"
      placeholder="Use this place for your example... (limited to 25 words)"
      [(ngModel)]="question.additionalResponse"
      nexleaderMaximumWordsValidation
      [maxWords]="25"
    />
  </div>
</div>
