<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<app-nexleader-wrapper-panel>
  <app-nexleader-sessions
    *ngIf="group"
    [group]="group"
  ></app-nexleader-sessions>
</app-nexleader-wrapper-panel>
