import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: true,
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], key: string, reverse: boolean = false): any[] {
    const list = [...array];
    const nestedKeyArray = key.split('.').filter((v) => !!v);
    if (!Array.isArray(array)) {
      return array;
    }

    list.sort((a: any, b: any) => {
      let aValue = a;
      let bValue = b;

      nestedKeyArray.forEach((nestedKey) => {
        aValue = aValue[nestedKey];
        bValue = bValue[nestedKey];
      });

      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (aValue < bValue) {
        return reverse ? 1 : -1;
      } else if (aValue > bValue) {
        return reverse ? -1 : 1;
      } else {
        return 0;
      }
    });

    return list;
  }
}
