export const CHART_COLORS = [
  '#24d37b',
  '#f55f63',
  '#524fdb',
  '#2e7ef0',
  '#fcd14c',
  '#eee',
];
export const PVI_BAR_CHART_COLORS = [
  '#208278',
  '#8e3551',
  '#ff9239',
  '#1dcfdd',
];
export const PVI_BAR_CHART_COLORS_TRANSPARENT = [
  'rgba(32,130,120,.2)',
  'rgba(142,53,81,.2)',
  'rgba(255,146,57,.2)',
  'rgba(29,207,221,.2)',
];
