<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Taking the Clifton Strengths Assessment</h3>
</div>
<div class="modal-body">
  <p>
    Your purchase of this online course includes the fees for all pre-IPSAT
    assessments, including Clifton Strengths (formerly Strengthsfinder 2.0). If
    you have already completed Clifton Strengths, you don’t need to do it again.
    <br /><br />
    <b>Clifton Strengths Access Code: {{ cliftonStrengthsAccessCode }}</b>
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
  <a
    class="btn btn-success"
    [href]="cliftonStrengthsAssessmentUrl"
    target="_blank"
    >Take Clifton Strengths</a
  >
</div>
