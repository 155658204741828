import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-redirect-component',
    template: ''
})
export class RedirectComponent {
    constructor(private route: ActivatedRoute, private router: Router) {
        const redirectTo = route.snapshot.data['redirect'];
        router.navigateByUrl(redirectTo, { replaceUrl: true });
    }
}
