/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../services/enum.service';
import { tap } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-prerequisites-review-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './prerequisites-review-modal.component.html'
})
export class NexleaderPrerequisitesReviewModalComponent implements OnInit {

  /**
   * prerequisites
   *
   * array of prerequisites
   *
   * An array of a user's prerequisites.
   */
  prerequisites!: { key: any };

  /**
   * surveyType
   *
   * string
   *
   * A string that determines which type of survey (IPSAT result) the user has.
   */
  surveyType!: string;
  categories: any;

  constructor(private enumsService: EnumsService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.enumsService.getEnums().pipe(
      tap((a) => {
        this.categories = a.AllQuestionCategories;
      })
    ).subscribe();
  }

  /**
   * close
   *
   * function
   *
   * A binding to close the modal
   */
  close() {
    this.bsModalService.hide();
  }
}
