<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Create a new Small Group</h3>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12 m-b-md">
      <p>
        Small groups organize users within your group and provide coaches the
        ability to control onboarding for these users.
      </p>
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Name</label>
      <input
        type="text"
        placeholder="John"
        class="form-control"
        [(ngModel)]="model.name"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="control-label">Coach</label>
    <select class="form-control" [(ngModel)]="model.smallGroupLeader">
      <option value="undefined">-- Select a coach --</option>
      <option
        *ngFor="let coach of coaches; trackBy: trackByCoachId"
        [value]="coach._id"
      >
        {{ coach.firstName + " " + coach.lastName }}
      </option>
    </select>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="save()">
    Create Small Group
  </button>
</div>
