<!-- Copyright (C) nexleader - All Rights Reserved -->
<!-- Unauthorized copying of this file, via any medium is strictly prohibited -->
<!-- Proprietary and confidential -->
<!-- Written for nexleader <myipsat.com>, 2016-2018 -->

<div class="login-container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center m-b-md">
        <div class="text-center m-b-md">
          <img src="/assets/images/logo.svg" width="125" height="100" />
        </div>
      </div>
      <div class="hpanel">
        <div class="panel-body" *ngIf="!warning && invite">
          <p class="text-danger text-center">{{ message }}</p>
          <h3 class="text-center">Welcome, {{ invite.firstName }}</h3>
          <p class="text-center">
            Finish creating your account by setting your password below.
          </p>
          <br />
          <form>
            <div class="form-group">
              <label class="control-label">Password</label>
              <input type="password" placeholder="******" class="form-control" [(ngModel)]="invite.password"
                name="password" />
            </div>
            <div class="form-group">
              <label class="control-label">Confirm your password</label>
              <input type="password" placeholder="******" class="form-control" [(ngModel)]="invite.confirmPassword"
                name="confirmPassword" />
            </div>
            <button class="btn btn-success btn-block" [attr.disabled]="
                !invite.password ||
                invite.password !== invite.confirmPassword ||
                invite.password === ''
                  ? true
                  : null
              " (click)="signup()">
              Create your account
            </button>
          </form>
        </div>
        <div class="panel-body" *ngIf="warning">
          <p>
            It looks like you've already created your account and this invite
            has now expired. <br /><br /><a [routerLink]="'/login'">Login Here</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <strong>IPSAT</strong> <br />
      2017 Copyright nexleader
    </div>
  </div>
</div>