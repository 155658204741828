/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { EnumsService } from '../../../../services/enum.service';
import { forkJoin, tap, catchError, of } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { EvaluationService } from '../../../../services/evaluation.service';
import { SurveyService } from '../../../../services/survey.service';

@Component({
  selector: 'app-nexleader-edits',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './edits.component.html',
})
export class NexleaderEditsComponent implements OnInit {
  routeParams: any;
  enums: any;

  surveys: any[] = [];
  evaluations: any[] = [];
  surveysAndEvaluations: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private enumsService: EnumsService,
    private surveyService: SurveyService,
    private evaluationService: EvaluationService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    forkJoin([
      this.enumsService.getEnums(),
      this.surveyService.query(),
      this.evaluationService.query(),
    ])
      .pipe(
        tap(([enumsResult, surveysResult, evaluationsResult]) => {
          this.enums = enumsResult;
          this.surveys = surveysResult.map((survey: any) => ({
            ...survey,
            type: 'survey',
          }));
          this.evaluations = evaluationsResult.map((evaluation: any) => ({
            ...evaluation,
            type: 'evaluation',
          }));
          this.surveysAndEvaluations = [...this.surveys, ...this.evaluations];
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  newSurvey(): void {
    // this.surveyService
    //   .save({
    //     name: 'Untitled',
    //     description: 'Untitled',
    //   })
    //   .pipe(
    //     tap((_) => {
    //       this.load();
    //     }),
    //     catchError((error) => {
    //       this.errorHandler.handleError(error);
    //       return of(null);
    //     })
    //   );
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEditsComponent',
//   downgradeComponent({
//     component: NexleaderEditsComponent,
//   }) as angular.IDirectiveFactory
// );
