<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="pull-left">
  <h4 class="small-text font-semi-bold text-primary-2" *ngIf="category.prerequisite !== 'spiritualGifts'">
    {{ category.name }}
  </h4>
  <h4 class="small-text font-semi-bold text-primary-2" *ngIf="category.prerequisite === 'spiritualGifts'">
    <span *ngIf="surveyType === 'values_based'">Personal Values</span>
    <span *ngIf="surveyType === 'faith_based'">Spiritual Gifts</span>
  </h4>
</div>
<div class="stats-icon pull-right">
  <img src="/assets/images/icons/{{ category.className }}.svg" alt="icon" />
</div>
<div class="m-t-xl">
  <h3 class="m-b-xs">{{ category.value }}/{{ category.maxValue }}</h3>
  <div class="progress m-t-xs full progress-small">
    <div [ngStyle]="{ width: (100 * category.value) / category.maxValue + '%' }" aria-valuemax="100" aria-valuemin="0"
      aria-valuenow="55" role="progressbar" class="progress-bar progress-bar-{{ category.className }}">
      <span class="sr-only">35% Complete (success)</span>
    </div>
  </div>
</div>