/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { UserService } from './../../../../services/user.service';
import { SuccessService } from './../../../../services/success.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderFileUploadComponent } from '../../components/file-upload/file-upload.component';
import { NexleaderFilesComponent } from '../../components/files/files.component';
import { BlobResourceService } from '../../../../services/blob.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-files-view',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderFileUploadComponent,
    NexleaderFilesComponent,
  ],
  templateUrl: './files-view.component.html',
})
export class NexleaderFilesViewComponent implements OnInit {
  routeParams: any;
  user_id!: string;
  blobs: any[] = [];
  user: any;
  isLoggedInAsUser = false;

  constructor(
    private blobService: BlobResourceService, // Update the service
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.user_id = this.routeParams['user_id'];
    this.load();
  }

  load(): void {
    combineLatest([
      this.blobService.queryUser(this.user_id),
      this.userService.getUser(this.user_id),
    ])
      .pipe(
        tap(([blob, user]) => {
          this.blobs = blob;
          this.user = user;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  onFinishedUpload(file: any): void {
    this.successService.handle({ message: 'Uploaded ' + file.name + '.' });
    this.load();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderFilesViewComponent',
//   downgradeComponent({
//     component: NexleaderFilesViewComponent,
//   }) as angular.IDirectiveFactory
// );
