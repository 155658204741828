<div class="nexleader-cookies-acknowledgement">
    <div class="row">
        <div class="col-md-8 col-md-offset-2 col-xs-12">
            <div class="hpanel hgreen" *ngIf="!hasAcknowledgedCookiesAgreement">
                <div class="panel-body" style="box-shadow: 0 10px 20px rgba(0,0,0,.05)">
                    <div class="flex flex-vertical-center flex-horizontal-center">
                        <p class="m-r-md m-b-none">
                            nexleader IPSAT uses cookies. We use cookies in order to enable functionality necessary for
                            all features on our website.
                        </p>
                        <button class="btn btn-success" (click)="acknowledgeCookiesAgreement()">Continue <i
                                class="fa fa-check"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>