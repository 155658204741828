/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetService } from '../../services/password-reset.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-forgot-password',
  templateUrl: './forgot-password.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class NexleaderForgotPasswordComponent implements OnInit {
  routeParams: any;
  message: string | undefined;
  password: string = '';
  confirmPassword: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passwordResetService: PasswordResetService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.passwordResetService
      .get(this.routeParams['password_reset_id'])
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  resetPassword(): void {
    this.passwordResetService
      .save({
        _id: this.routeParams['password_reset_id'],
        password: this.password,
      })
      .pipe(
        tap((_) => {
          this.router.navigate(['/']);
        }),
        catchError((error) => {
          this.message =
            'There was an error with resetting your password. Double-check that you have the correct credentials.';
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderForgotPasswordComponent',
//   downgradeComponent({
//     component: NexleaderForgotPasswordComponent,
//   }) as angular.IDirectiveFactory
// );
