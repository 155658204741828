import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderCopyLinkComponent } from '../../../../core/components/copy-link/copy-link.component';

@Component({
  selector: 'app-nexleader-shareable-team-report-modal',
  standalone: true,
  imports: [CommonModule, NexleaderCopyLinkComponent],
  templateUrl: './shareable-team-report-modal.component.html',
})
export class NexleaderShareableTeamReportModalComponent {
  shareableLink!: string;

  constructor(private bsModalService: BsModalService) { }

  close(): void {
    this.bsModalService.hide();
  }
}
