<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="container p-v-50-sm">
  <div class="row nexleader-onboarding-reverse-row">
    <div class="col-md-3 col-xs-12">
      <app-nexleader-component-pvi-assessment-selected-values [selectedValues]="topTwelveValues"
        (onRemoveValue)="removeValue($event)"></app-nexleader-component-pvi-assessment-selected-values>
    </div>
    <div class="col-md-9 col-xs-12">
      <div class="hide-mobile">
        <p class="m-b-md">Click on the icon to navigate between domains.</p>
        <div class="flex flex-space-between m-b-md">
          <div class="flex flex-vertical-center cursor-pointer"
            id="nexleader-test-pvi-select-domain-{{ domain.identifier }}" *ngFor="let domain of allDomains"
            [attr.disabled]="!canSelectValues() ? true : null" (click)="selectDomain(domain)">
            <img *ngIf="domain.identifier === selectedDomain?.identifier"
              src="/assets/images/pvi_icons/{{ domain.identifier }}.svg" alt="pvi icon" class="img-md" />
            <img *ngIf="domain.identifier !== selectedDomain?.identifier" src="/assets/images/pvi_icons/{{
                domain.identifier
              }}-gray.svg" alt="pvi icon" class="img-md" />
            <h4 class="font-semi-bold m-l-sm" [class.text-primary-2]="
                domain.identifier === selectedDomain.identifier
              ">
              {{ domain.name }}
            </h4>
          </div>
        </div>
      </div>
      <div class="show-mobile m-t-md">
        <div class="form-group">
          <label class="control-label">Select Domain</label>
          <select class="form-control" [(ngModel)]="selectedDomain">
            <option *ngFor="let domain of allDomains" [ngValue]="domain">
              {{ domain.name }}
            </option>
          </select>
        </div>
      </div>
      <p>
        Choose values that come to mind when you reflect on the
        <b>domain of {{ selectedDomain?.name.toLowerCase() }}</b>: {{ selectedDomain?.description }}. <br /><br />Click
        on the values
        below to select them. They will then populate in the list
        <span class="show-mobile-inline">below. Click the above dropdown to change domains</span><span
          class="hide-mobile-inline">on the left</span>.
      </p>
      <div class="hpanel m-t-md m-b-md">
        <div class="panel-body">
          <app-nexleader-component-pvi-assessment-value-select
            (onSelectValue)="addValueToSelectedDomain($event)"></app-nexleader-component-pvi-assessment-value-select>
          <hr />
          <h4 class="text-primary-2 font-semi-bold m-b-sm">Create a Value</h4>
          <p class="m-b-md">
            If you are unable to find a value in the list above, create your own
            value using the input below.
          </p>
          <div class="flex flex-column-md">
            <input type="text" name="value" class="form-control m-r-sm m-b-md-sm" [(ngModel)]="valueToCreate"
              placeholder="Type your value here..." />
            <button class="btn btn-default" (click)="createCustomValue()">
              Create Value
            </button>
          </div>
        </div>
      </div>
      <div class="hide-mobile">
        <div class="flex">
          <button class="btn btn-default" *ngIf="selectedDomain?.index !== 0" (click)="previousDomain()">
            Previous Domain
          </button>
          <div class="flex-grow"></div>
          <button class="btn btn-default" *ngIf="selectedDomain?.index !== (allDomains?.length || 0) - 1"
            (click)="nextDomain()">
            Next Domain
          </button>
        </div>
      </div>
    </div>
  </div>
</div>