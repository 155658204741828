import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EvaluationService } from '../services/evaluation.service';

@Injectable({
    providedIn: 'root'
})
export class GroupUserCoachEvaluationRedirectResolver implements Resolve<string> {
    constructor(
        private evaluationService: EvaluationService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        const groupId = route.params['group_id'];
        const userId = route.params['user_id'];
        const coachId = route.params['coach_id'];

        return new Observable<string>((observer) => {
            this.evaluationService.query().subscribe((evaluations) => {
                if (evaluations && evaluations.length > 0) {
                    const evaluationId = evaluations[0]._id;
                    const redirectUrl = `/groups/${groupId}/users/${userId}/coaches/${coachId}/evaluations/${evaluationId}`;
                    observer.next(redirectUrl);
                    observer.complete();
                } else {
                    // Handle if evaluations are not found
                    observer.error('Evaluations not found');
                }
            });
        });
    }
}
