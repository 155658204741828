<div class="form-group">
  <label class="control-label">Course Content Name</label>
  <input
    type="text"
    class="form-control"
    [(ngModel)]="courseContent.contentName"
  />
</div>
<div class="form-group">
  <label class="control-label">Course Content Description</label>
  <textarea
    rows="4"
    class="form-control"
    [(ngModel)]="courseContent.contentDescription"
  ></textarea>
</div>
<div class="form-group">
  <label class="control-label">Course Objectives</label>
  <textarea
    rows="4"
    class="form-control"
    [(ngModel)]="courseContent.courseObjectives"
  ></textarea>
</div>
<div class="form-group">
  <label class="control-label">Course URL</label>
  <input
    type="text"
    class="form-control"
    [(ngModel)]="courseContent.contentUrl"
  />
</div>
<div class="form-group">
  <label class="control-label">Course Price</label>
  <input
    type="text"
    class="form-control"
    [(ngModel)]="courseContent.coursePrice"
  />
</div>
<div class="form-group">
  <label class="control-label"
    >Tags (separated by commas, non-case sensitive)</label
  >
  <input
    type="text"
    class="form-control"
    [(ngModel)]="courseContent.internalTags"
  />
</div>
