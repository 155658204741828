/* global angular */
/**
 * nexleaderViewGroupSessionsList
 *
 * angular component
 *
 * A view used to display ALL sessions for the entire application within a table (through a component)
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderSessionsTableComponent } from '../../components/sessions-table/sessions-table.component';
import { GroupService } from '../../../../services/group.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-group-sessions-list',
  standalone: true,
  imports: [CommonModule, NexleaderSessionsTableComponent],
  templateUrl: './group-sessions-list.component.html',
})
export class NexleaderGroupSessionsListComponent implements OnInit {
  routeParams: any;
  groupId: string = '';
  group: any;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    // Used to correctly configure the group's session list
    this.groupId = this.routeParams['group_id'];
    this.load();
  }

  load(): void {
    this.groupService
      .getLite(this.groupId)
      .pipe(
        tap((group) => {
          this.group = group;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupSessionsListComponent',
//   downgradeComponent({
//     component: NexleaderGroupSessionsListComponent,
//   }) as angular.IDirectiveFactory
// );
