<!-- Copyright (C) nexleader - All Rights Reserved -->
<!-- Unauthorized copying of this file, via any medium is strictly prohibited -->
<!-- Proprietary and confidential -->
<!-- Written for nexleader <myipsat.com>, 2016-2018 -->
<app-nexleader-help-utility></app-nexleader-help-utility>

<div *ngIf="user" style="height: 100%">
  <app-nexleader-ecourse-module-one *ngIf="step.identifier === ECOURSE_ONBOARDING_STEPS.MODULE_ONE.identifier"
    [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-module-one>
  <app-nexleader-ecourse-module-two *ngIf="step.identifier === ECOURSE_ONBOARDING_STEPS.MODULE_TWO.identifier"
    [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-module-two>
  <app-nexleader-ecourse-personal-info *ngIf="
      step.identifier === ECOURSE_ONBOARDING_STEPS.PERSONAL_INFO.identifier
    " [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-personal-info>
  <app-nexleader-ecourse-module-three *ngIf="step.identifier === ECOURSE_ONBOARDING_STEPS.MODULE_THREE.identifier"
    [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-module-three>
  <app-nexleader-ecourse-module-four *ngIf="step.identifier === ECOURSE_ONBOARDING_STEPS.MODULE_FOUR.identifier"
    [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-module-four>
  <app-nexleader-ecourse-module-five *ngIf="step.identifier === ECOURSE_ONBOARDING_STEPS.MODULE_FIVE.identifier"
    [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-module-five>
  <app-nexleader-ecourse-module-six *ngIf="step.identifier === ECOURSE_ONBOARDING_STEPS.MODULE_SIX.identifier"
    [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-module-six>
  <app-nexleader-ecourse-module-seven *ngIf="step.identifier === ECOURSE_ONBOARDING_STEPS.MODULE_SEVEN.identifier"
    [user]="user" (handleNextStep)="nextStep()"></app-nexleader-ecourse-module-seven>
</div>