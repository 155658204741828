/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
import { CommonModule } from '@angular/common';
import { Component, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-nexleader-skills-inventory-skill-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skills-inventory-skill-chart.component.html',
})
export class NexleaderSkillsInventorySkillChartComponent
  implements AfterViewInit, OnDestroy {
  @Input() skills!: { name: string; value: number }[];

  // Used by printable view to lock width and height
  @Input() width: number | null = null;
  @Input() height: number | null = null;
  @Input() disableResponsive: boolean = false;
  private chart!: Chart;

  constructor(private elementRef: ElementRef) { }

  drawChart(): void {
    const ctx: HTMLCanvasElement =
      this.elementRef.nativeElement.querySelector('canvas');

    if (!Array.isArray(this.skills)) {
      console.error('Skills array must be provided to draw the skill chart.');
      return;
    }

    // Set the chart height as 50 px per skill (or preconfigured)
    ctx.height = this.height || 37 * this.skills.length;
    // Set the custom width or leave it as what it was
    ctx.width = this.width || ctx.width;

    const data = this.skills.map((skill) => skill.value);
    const labels = this.skills.map((skill) => skill.name);

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Score',
            data: data,
            backgroundColor: 'rgba(76,186,114,0.2)',
            borderColor: 'rgba(76,186,114,1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: this.disableResponsive !== true,
        scales: {
          x: {
            position: 'bottom',
            type: 'linear',
            min: 1,
            max: 10,
            ticks: {
              stepSize: 1,
              maxRotation: 0,
              autoSkip: false,
            },
            grid: {
              display: true,
            },
            display: true,
          },
          x2: {
            position: 'top',
            type: 'linear',
            min: 1,
            max: 10,
            ticks: {
              callback: (value) => {
                switch (value) {
                  case 1:
                    return 'Non-Existent';
                  case 3:
                    return 'Below Average';
                  case 5:
                    return 'Average';
                  case 7:
                    return 'Functionally Competent';
                  case 10:
                    return 'World Class';
                  default:
                    return '';
                }
              },
              stepSize: 1,
              maxRotation: 0,
              autoSkip: false,
            },
            grid: {
              drawOnChartArea: false,
            },
            display: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            //Manually select the xAlign because Chart.js wasn't doing a good job.
            // left align until it goes off the screen, which is only at a val of 10
            xAlign: this.disableResponsive ? 'center' : 'left',
          },
        },
      },
    });
  }

  ngAfterViewInit(): void {
    this.drawChart();
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
