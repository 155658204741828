/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderNewLicensingPartnerModal
 *
 * directive
 *
 * this directive contains the GUI and logic to submit a POST request to
 *  create a new LicensingPartner
 *
 * (LicensingPartner contain Groups)
 *
 * See LicensingPartner.model
 */
import { tap, catchError, of } from 'rxjs';
import { LicensingPartnerService } from './../../../resources/licensing-partner.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderInputLicensingPartnerDataComponent } from '../../input-licensing-partner-data/input-licensing-partner-data.component';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
// import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-new-licensing-partner-modal',
  standalone: true,
  imports: [CommonModule, NexleaderInputLicensingPartnerDataComponent],
  templateUrl: './new-licensing-partner-modal.component.html',
})
export class NexleaderNewLicensingPartnerModalComponent {
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  model: {
    name: string;
    region: string;
    currencyCode: string;
    pricing: {
      ipsatLicense: {
        organizationalFaithBased: number;
        organizationalValuesBased: number;
        independentContractorFaithBased: number;
        independentContractorValuesBased: number;
      };
      teamReport: {
        faithBased: {
          perUser: number;
          min: number;
          max: number;
        };
        valuesBased: {
          perUser: number;
          min: number;
          max: number;
        };
      };
      ipsatDirectPurchase: {
        faithBasedPlusCoachingSession: number;
        valuesBasedPlusCoachingSession: number;
        cliftonStrengthsAssessment: number;
      };
      ipsatEcourseDirectPurchase: {
        valuesBasedBundle: number;
      };
      ecourseContentUpgrade: number;
    };
    cliftonStrengthsCode: string;
  } = {
      name: '',
      region: '',
      currencyCode: '',
      pricing: {
        ipsatLicense: {
          organizationalFaithBased: NaN,
          organizationalValuesBased: NaN,
          independentContractorFaithBased: NaN,
          independentContractorValuesBased: NaN,
        },
        teamReport: {
          faithBased: {
            perUser: NaN,
            min: NaN,
            max: NaN,
          },
          valuesBased: {
            perUser: NaN,
            min: NaN,
            max: NaN,
          },
        },
        ipsatDirectPurchase: {
          faithBasedPlusCoachingSession: NaN,
          valuesBasedPlusCoachingSession: NaN,
          cliftonStrengthsAssessment: NaN,
        },
        ipsatEcourseDirectPurchase: {
          valuesBasedBundle: NaN,
        },
        ecourseContentUpgrade: NaN,
      },
      cliftonStrengthsCode: '',
    };

  constructor(
    private errorHandler: ErrorHandlerService,
    // private bsModalService: BsModalService,
    private licensingPartnerService: LicensingPartnerService
  ) { }

  /**
   * save()
   *
   * function
   *
   * calls the save() function to make the REST POST call to save the new LicensingPartner object
   *  then closes the modal
   */
  save(): void {
    this.licensingPartnerService
      .save(this.model)
      .pipe(
        tap(() => {
          // hide modal and callback for success
          // this.bsModalService.hide();
          this.onSave.emit();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error?.error);
          return of(null);
        })
      )
      .subscribe();
  }
}
