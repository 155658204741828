/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentStepFive
 *
 * component
 *
 * This component is responsible for completing the fifth step of the PVI. It asks the user to select three of the
 *  previously chosen seven values as their top three values.
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-step-five',
  standalone: true,
  imports: [CommonModule, OrderByPipe],
  templateUrl: './pvi-assessment-step-five.component.html',
})
export class NexleaderComponentPviAssessmentStepFiveComponent
  implements OnInit
{
  /**
   * topTwelveValues
   *
   * array
   *
   * An array of the top twelve values that the user has selected. This array is filled within this component
   *  and utilized in the parent component.
   */
  @Input() topTwelveValues!: any[];

  ngOnInit(): void {
    if (!Array.isArray(this.topTwelveValues)) {
      throw new Error(
        'Input "topTwelveValues" must be an array for NexleaderComponentPviAssessmentStepFiveComponent.'
      );
    }
  }

  /**
   * toggleValueSelection()
   *
   * function
   *
   * Allows a user to select a value to be used in the top three values array.
   *
   * @param {string} name - A string used to identify the index of the value in the topTwelveValues
   */
  toggleValueSelection(name: string) {
    // We can't have more than three values in the array
    // This is an interesting if statement. We want to only return with nothing if there are three values
    //already and they are trying to add a new one
    this.topTwelveValues.forEach((val) => {
      if (val.name === name) {
        if (this.getValuesInTopThree().length === 3 && !val.isInTopThree)
          return;
        val.isInTopThree = !val.isInTopThree;
        // If there is currently a topThreeIndex for the number, return with it being set to 0
        if (val.topThreeIndex) {
          val.topThreeIndex = 0;
        } else {
          // The index is equal to the number of values in the filtered array
          val.topThreeIndex = this.topTwelveValues.filter(function (v) {
            return v.isInTopThree;
          }).length;
        }
      }
    });
  }

  /**
   * getValuesInTopThree()
   *
   * function
   *
   * @returns all values in the top three and sorts them based on their index.
   */
  getValuesInTopThree() {
    return (
      this.topTwelveValues
        // Filter just those that are in the top three
        .filter((v) => {
          return v.isInTopThree;
        })
        // Order by their topThreeIndex
        .sort((a, b) => {
          return a.topThreeIndex - b.topThreeIndex;
        })
    );
  }

  /**
   * getValuesInTopSeven()
   *
   * function
   *
   * @returns all values in the top seven
   */
  getValuesInTopSeven() {
    return this.topTwelveValues.filter((v) => {
      return v.isInTopSeven;
    });
  }

  /**
   * shouldDisableSelectionFor()
   *
   * function
   *
   * Returns true if a value is not currently selected and we have reached the cap of selectable items
   *
   * @param value Value we are wondering about
   * @returns {boolean} true if the value selection should be disabled for the value provided
   */
  shouldDisableSelectionFor(value: any) {
    return !value.isInTopThree && this.getValuesInTopThree().length >= 3;
  }
}
