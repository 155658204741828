/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderLicensingPartnerCard
 *
 * component
 *
 * displays a card representing a single licensing partner
 *  used in the LicensingPartners view
 *
 * may mutate of the state of groups via two-way binding
 *  an edit button will make edits; archive button will archive
 *
 * changes are saved automatically via the resource service
 */
import { combineLatest, tap, catchError, of } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { GroupService } from '../../../../services/group.service';
import { LicensingPartnerService } from '../../resources/licensing-partner.service';
import { NexleaderEditLicensingPartnerModalComponent } from '../modals/edit-licensing-partner-modal/edit-licensing-partner-modal.component';

@Component({
  selector: 'app-nexleader-licensing-partner-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './licensing-partner-card.component.html',
})
export class NexleaderLicensingPartnerCardComponent {
  _licensingPartnerId: string = '';

  /**
   * licensingPartnerId
   *
   * string binding
   *
   * the id of the licensing partner we're rending a card for
   */
  @Input() set licensingPartnerId(value: string) {
    if (typeof value !== 'string') {
      throw new Error(
        'nexleaderLicensingPartnerCard required string param licensingPartnerId'
      );
    }
    this._licensingPartnerId = value;
    this.loadLicensingPartnerCard(value);
  }
  get licensingPartnerId() {
    return this._licensingPartnerId;
  }

  /**
   * onChange
   *
   * expression binding: event handler
   *
   * called after saving a change to the licensing partner
   */
  @Output() onChange = new EventEmitter<any>();

  licensingPartner: {
    name: string;
    region: string;
    createdAt: string;
  } = {
      name: '',
      region: '',
      createdAt: '',
    };
  groups: any[] = [];
  licensingPartnerLicenseCount: number = NaN;

  constructor(
    private modalService: BsModalService,
    private licensingPartnerService: LicensingPartnerService,
    private groupService: GroupService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  /**
   * load()
   *
   * function: modifies internal component state
   *
   * validate licensing partner id then load the licensing partner information used in the card
   * separate from $onInit because we call this function again if the $watched smallgroupid changes
   *
   * called by watch on $ctrl.licensingPartnerId
   */
  loadLicensingPartnerCard(licensingPartnerId: string): void {
    if (typeof licensingPartnerId !== 'string') {
      throw new Error('load() requires a string parameter licensingPartnerId');
    }

    combineLatest([
      this.licensingPartnerService.get(licensingPartnerId),
      this.groupService.queryLicensingPartnerIndex(licensingPartnerId),
    ])
      .pipe(
        tap(([licensingPartnerResponse, groupsResponse]) => {
          this.licensingPartner = licensingPartnerResponse;
          this.groups = groupsResponse;
          this.licensingPartnerLicenseCount = this.groups.reduce(
            (count, group) => count + group.maxUsers,
            0
          );
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * editLicensingPartnerCard
   *
   * Allows a coach to edit a licensing partner in which they are a part of.
   */
  editLicensingPartner(): void {
    const modalRef = this.modalService.show(
      NexleaderEditLicensingPartnerModalComponent,
      {
        initialState: { licensingPartnerId: this.licensingPartnerId },
      }
    );

    modalRef.content?.onCancel.subscribe(() => {
      modalRef.hide();
    });

    modalRef.content?.onSave.subscribe((value) => {
      modalRef.hide();
      this.loadLicensingPartnerCard(this.licensingPartnerId);
      this.onChange.emit();
    });
  }
}
