/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationService } from '../../../../services/evaluation.service';
import { EvaluationResultService } from '../../../../services/evaluation-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-evaluation',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './evaluation.component.html'
})
export class NexleaderEvaluationComponent implements OnInit {
  routeParams: any;
  evaluation: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private evaluationService: EvaluationService,
    private evaluationResultService: EvaluationResultService,
    private errorHandlerService: ErrorHandlerService,
    private successHandler: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.evaluationService
      .getEvaluation(this.routeParams['evaluation_id'])
      .pipe(
        tap((evaluation) => {
          this.evaluation = evaluation;
          this.evaluation.questions.forEach((q: any) => {
            q.answer = {};
            // Default string fields to an empty string
            if (!(q.answers && q.answers[0])) {
              q.answer.name = '';
            }
          });
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  setAnswer(question: any, index: number): void {
    question.answer.value = index;
  }

  invalid(): string | boolean {
    if (!this.evaluation) return false;

    let reason: string | boolean = false;

    this.evaluation.questions.forEach((q: any) => {
      if (!q.answer) return reason = 'You have not answered all required questions.';
      if (!(q.answer.value || q.answer.name || q.answer.name === '')) return reason = 'You have not answered all required questions.';
      return false;
    });
    return reason;
  }

  submitEvaluation(evaluation: any): void {
    evaluation.questions.forEach((q: any) => {
      if (q.answer.name === '') {
        q.answer.name = 'No response';
      } else if (!q.answer.name) {
        q.answer.name = q.answer.value.toString();
      }
    });

    const { coach_id, user_id, group_id } = this.routeParams;
    evaluation.coach = coach_id;
    evaluation.user = user_id;
    evaluation.group = group_id;

    // Remove all properties related to the previous model we copied over
    delete evaluation._id;
    delete evaluation.createdAt;
    delete evaluation.updatedAt;

    this.evaluationResultService.save(evaluation).pipe(
      tap((_) => {
        this.successHandler.handle({ message: 'Evaluation saved. Thank you for your input.' });
        this.router.navigate(['/coachEvalThankYou']);
      }),
      catchError(error => {
        this.errorHandlerService.handleError(error.error,
          this.errorHandlerService.types.TOAST);
        return of(null);
      })
    ).subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEvaluationComponent',
//   downgradeComponent({
//     component: NexleaderEvaluationComponent,
//   }) as angular.IDirectiveFactory
// );
