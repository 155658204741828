<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row" *ngIf="coach">
      <div class="col-md-6">
        <div class="hpanel">
          <div class="panel-heading">Information</div>
          <div class="panel-body">
            <p class="m-b-sm">
              <b>{{ coach.firstName }} {{ coach.lastName }}</b>
            </p>
            <p>
              <span>{{ user?.group?.name }}</span>
            </p>
            <p class="m-b-md" *ngIf="coach.description">
              {{ coach.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="hpanel">
          <div class="panel-heading">Coaching Sessions</div>
          <div class="panel-body">
            <p *ngIf="sessions.length === 0">
              You do not have any coach sessions scheduled.
            </p>
            <div class="table-responsive" *ngIf="sessions.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th>Start</th>
                    <th>End</th>
                    <th>Best Number to Call</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let session of sessions | orderBy : '-start'">
                    <td>{{ session.start | date : "medium" }}</td>
                    <td>{{ session.end | date : "medium" }}</td>
                    <td>{{ session.bestNumberToCall | telephone }}</td>
                    <td>
                      <a
                        [href]="
                          apiUrl + 'sessions/' + session._id + '/export/ics'
                        "
                        *ngIf="session._id"
                      >
                        Download as Calendar File (.ics)
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!coach">You currently do not have a coach assigned to you.</div>
  </div>
</div>
