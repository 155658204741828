/* global angular */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderMultigroupCoachComponent } from '../../components/multigroup-coach/multigroup-coach.component';
// import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-multigroup-coaching-dashboard',
  standalone: true,
  imports: [CommonModule, NexleaderMultigroupCoachComponent],
  templateUrl: './multigroup-coaching-dashboard.component.html',
})
export class NexleaderMultigroupCoachingDashboardComponent implements OnInit {
  // routeParams: any;
  // constructor() { }

  ngOnInit(): void {
    // this.routeParams = this.route.snapshot.params;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderMultigroupCoachingDashboardComponent',
//   downgradeComponent({
//     component: NexleaderMultigroupCoachingDashboardComponent,
//   }) as angular.IDirectiveFactory
// );
