/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderNewTeamReportDiscountModal
 *
 * angular component - new modal
 *
 * modal form to create a new team report discount object
 *
 * Steve (or any super admin) has the ability to create discount codes for the TeamReport feature. These discounts
 *  can either reduce the total price by a percentage or set the team report to a flat fee. This enum represents
 *  the type of discount that a given discount code represents.
 *
 * See TeamReport.logic -> calculatePrice()
 * See TeamReport.route -> POST /purchase
 * See TeamReportDiscount.model
 *
 * Called by nexleaderTeamReportDiscounts component (see that component)
 */

import { TeamReportDiscountService } from './../../../resources/team-report-discount.service';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderInputEnumComponent } from '../../../../core/components/input-enum/input-enum.component';
import { FormsModule } from '@angular/forms';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SuccessService } from '../../../../../services/success.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-new-team-report-discount-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderInputEnumComponent],
  templateUrl: './new-team-report-discount-modal.component.html',
})
export class NexleaderNewTeamReportDiscountModalComponent {
  /**
   * discountAmountRaw
   *
   * number
   *
   * this is the raw discount amount that the user typed into the text box
   *  depending on if it's flat fee or a percentage discount, we apply a different
   *  multiplier before saving to the backend. Thus, we store it in the controller and
   *  don't apply the multiplier until a user clicks save
   *
   * @type {null}
   */
  discountAmountRaw: number | null = null;

  /**
   * teamReportDiscount
   *
   * model object
   *
   * the whole point of this controller is to build a teamReportDiscount object
   *
   * this is object we're building on
   *
   * @type {{discountType: null}}
   */
  teamReportDiscount: {
    discountType: any;
    discountAmountBps: number | null;
    name: string;
  } = {
      discountType: null, // Initialize this to null so that the InputEnum component can bind to it
      discountAmountBps: null,
      name: '',
    };

  constructor(
    private teamReportDiscountService: TeamReportDiscountService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService,
    private modalService: BsModalService
  ) { }

  /**
   * save()
   *
   * called when clicking "submit" (or some button like that)
   *
   * converts percentage or dollar amount to basis points
   * saves the object and closes the modal
   */
  save(): void {
    // Validate and show errors as toasts
    if (!this.teamReportDiscount.discountType)
      return this.errorHandlerService.toast({
        message:
          'Please select a discount type in order to save your discount code.',
      });
    if (typeof this.discountAmountRaw !== 'number')
      return this.errorHandlerService.toast({
        message:
          'Please enter a numeric discount amount in order to save your discount code.',
      });

    // Compute the discount amount in bps
    switch (this.teamReportDiscount.discountType.identifier) {
      case 'flat_fee':
      case 'flat_discount':
        this.teamReportDiscount.discountAmountBps =
          this.discountAmountRaw * 10000;
        break;
      case 'percentage':
        this.teamReportDiscount.discountAmountBps =
          this.discountAmountRaw * 100;
        break;
      default:
        return this.errorHandlerService.toast({
          message:
            'Please select a known discount type in order to save your discount code',
        });
    }

    this.teamReportDiscountService
      .save(this.teamReportDiscount)
      .pipe(
        tap(() => {
          this.successService.handle({
            message: 'Successfully created TeamReport discount code.',
          });
          this.modalService.hide();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * getDiscountAmountSymbol()
   *
   * discountAmount is treated differently based on discount type
   *  it can represent dollars or a percentage (see TeamReportDiscount.model docs)
   *  this function just returns the appropriate symbol
   */
  getDiscountAmountSymbol(): string | null {
    if (!this.teamReportDiscount.discountType) return null;
    switch (this.teamReportDiscount.discountType.identifier) {
      case 'flat_fee':
      case 'flat_discount':
        return '$';
      case 'percentage':
        return '%';
      default:
        return null;
    }
  }
}
