<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<b>Upload Reports {{ isOptional ? "(optional)" : "" }}</b>
<br />
<div class="m-t-sm">
  <app-nexleader-files
    [files]="files"
    [fileOwner]="user"
    (onFileDelete)="load.emit()"
    [editable]="true"
    [columnClass]="'col-lg-6 col-xs-12'"
  ></app-nexleader-files>
  <app-nexleader-file-upload
    *ngIf="prerequisite"
    [fileOwner]="user"
    [prerequisite]="prerequisite"
    (onUploadComplete)="onFinishedUpload($event)"
  ></app-nexleader-file-upload>
</div>
