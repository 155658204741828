/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderNewSmallGroupModal
 *
 * directive
 *
 * this directive contains the GUI and logic to submit a POST request to
 *  create a new SmallGroup within a given Group
 *
 * requires external binding to specify which Group to create the SmallGroup in
 *
 * (Groups contain SmallGroups via a ref from SmallGroup to Group)
 *
 * See SmallGroup.model
 */
import { ErrorHandlerService } from './../../../../../services/error-handler.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallGroupService } from '../../../resources/small-group.service';
import { UserService } from '../../../../../services/user.service';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-new-small-group-modal',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './new-small-group-modal.component.html',
})
export class NexleaderNewSmallGroupModalComponent implements OnInit {
  groupId: string = '';
  @Output() onSave = new EventEmitter<any>();

  coaches: any[] = [];

  //Create a basic model object to bind to
  // this is also how we pass the group_id to the new small group
  model: any = {
    group: this.groupId,
  };

  constructor(
    private smallGroupService: SmallGroupService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    // Validate that somehow we got a groupId string
    if (!this.groupId) {
      throw new Error(
        'groupId is required by NexleaderNewSmallGroupModalComponent'
      );
    }

    // Load a list of coaches that we might pick to be our small group leader
    this.userService
      .queryGroupRoleIndex(this.groupId, 'coach')
      .pipe(
        tap((users) => {
          this.coaches = users;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.model.group = this.groupId;
  }

  trackByCoachId(index: number, coach: any) {
    return coach._id;
  }

  /**
   * save()
   *
   * function
   *
   * calls the save() function to make the REST POST call to save the new smallGroup object
   *  then closes the modal
   */
  save() {
    this.smallGroupService
      .save(this.model)
      .pipe(
        tap((_) => {
          this.onSave.emit();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error?.error);
          return of(null);
        })
      )
      .subscribe();
  }
}
