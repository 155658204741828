import { TeamReportDiscountService } from './../../resources/team-report-discount.service';
/*global angular*/

/**
 * nexleaderTeamReportDiscounts
 *
 * VIEW
 *
 * Displays a table of all existing TeamReportDiscounts
 *
 * Provides the ability to create and delete team report discounts. Creation is provided through a link
 *  that opens the NewTeamReportDiscountModel.
 *
 * Update ability is not available; these objects are small enough to make the end user (Steve/Angeline) delete
 *  old ones and create new ones.
 */

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, of, take, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderNewTeamReportDiscountModalComponent } from '../../components/modals/new-team-report-discount-modal/new-team-report-discount-modal.component';

@Component({
  selector: 'app-nexleader-team-report-discounts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './team-report-discounts.component.html',
})
export class NexleaderTeamReportDiscountsComponent implements OnInit {
  teamReportDiscounts: any[] = [];

  constructor(
    private modalService: BsModalService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService,
    private teamReportDiscountService: TeamReportDiscountService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  /**
   * load()
   *
   * load function for the controller
   *
   * Loads the $ctrl.teamReportDiscounts array that we display in the table view
   */
  load(): void {
    this.teamReportDiscountService
      .getAll()
      .pipe(
        tap((a) => {
          this.teamReportDiscounts = a;
          this.teamReportDiscounts.forEach((teamReportDiscount) => {
            if (!teamReportDiscount.discountType) {
              console.warn(
                'malformed teamReportDiscount in TeamReportDiscounts component; no discountType',
                teamReportDiscount
              );
              return;
            }
            switch (teamReportDiscount.discountType.identifier) {
              case 'flat_fee':
              case 'flat_discount':
                teamReportDiscount._discountAmountString =
                  '$' +
                  (teamReportDiscount.discountAmountBps / 10000).toFixed(2);
                break;
              case 'percentage':
                teamReportDiscount._discountAmountString =
                  (teamReportDiscount.discountAmountBps / 100).toFixed(2) + '%';
                break;
              default:
                teamReportDiscount._discountAmountString = 'UNKNOWN TYPE';
                console.warn(
                  'TeamReportDiscounts: unknown teamReportDiscount.discountType.identifier; add a line to the switch statement to render it'
                );
            }
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * openCreateTeamReportDiscount()
   *
   * Presents a modal to the user that allows him/her to create a new team report discount object.
   *  Successfully creating a TeamReport discount results in a call to innerScope.close() that reloads
   *  this view so that we can see the new object in the table.
   */
  openCreateTeamReportDiscount(): void {
    const modalRef = this.modalService.show(
      NexleaderNewTeamReportDiscountModalComponent
    );
    modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.load();
    });
  }

  /**
   * remove()
   *
   * Sends a DELETE request for a given teamReportDiscount object (using the _id property)
   *
   * @param teamReportDiscount
   */
  remove(teamReportDiscount: any): void {
    this.teamReportDiscountService
      .remove(teamReportDiscount._id)
      .pipe(
        tap((_) => {
          this.successService.handle({
            message: 'Successfully deleted team report discount code.',
          });
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderTeamReportDiscountsComponent',
//   downgradeComponent({
//     component: NexleaderTeamReportDiscountsComponent,
//   }) as angular.IDirectiveFactory
// );
