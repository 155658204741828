/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentAcknowledgeCookies
 * 
 * component
 * 
 * Since we use cookies on the nexleader site, we have to make sure we provide people with a notice
 *  that we're using those cookies. This allows us to display a banner across the top that gives the
 *  user context on why we're using cookies.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-cookies-acknowledge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cookies-acknowledge.component.html'
})
export class NexleaderCookiesAcknowledgeComponent implements OnInit {

  hasAcknowledgedCookiesAgreement: boolean = false;
  ACKNOWLEDGED_COOKIES_AGREEMENT_COOKIE: string = 'nexleaderIpsatAcknowledgedCookies';

  // When we load, we want to determine if the user has acknowledged the cookies agreement
  ngOnInit(): void {
    // When we load, we want to determine if the user has acknowledged the cookies agreement
    const cookieValue = this.getCookie(this.ACKNOWLEDGED_COOKIES_AGREEMENT_COOKIE);
    this.hasAcknowledgedCookiesAgreement = cookieValue === 'true';
  }

  private getCookie(name: string): string | undefined {
    const cookieMatch = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return cookieMatch ? cookieMatch[2] : undefined;
  }

  // Once we load, we want to put the cookies agreement
  acknowledgeCookiesAgreement(): void {
    this.hasAcknowledgedCookiesAgreement = true;
    document.cookie = `${this.ACKNOWLEDGED_COOKIES_AGREEMENT_COOKIE}=${true}`;
  }

}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCookiesAcknowledgeComponent',
//   downgradeComponent({
//     component: NexleaderCookiesAcknowledgeComponent,
//   }) as angular.IDirectiveFactory
// );
