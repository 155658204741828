<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper" *ngIf="group">
  <div class="content animate-panel">
    <div class="hpanel">
      <div class="panel-heading">Basic Information</div>
      <div class="panel-body">
        <div class="row m-b-lg">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Group Configuration</h5>
          </div>
          <div class="col-md-9 col-xs-12">
            <div class="form-group">
              <label class="control-label">Group Name</label>
              <input
                type="text"
                placeholder="Forge"
                class="form-control"
                [(ngModel)]="group.name"
              />
            </div>
            <p *ngIf="isSuperAdmin">
              <b>Note:</b> A group's survey type is not editable once the group
              has been created.
            </p>
          </div>
        </div>
        <div class="row" *ngIf="isSuperAdmin">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Super Admin Settings</h5>
          </div>
          <div class="col-md-9 col-xs-12">
            <p class="text-muted small m-b-sm">
              You can only see these settings since you're a super admin.
            </p>
            <div class="form-group">
              <label class="control-label">Group licenses</label>
              <input
                type="number"
                placeholder="1000"
                class="form-control"
                [(ngModel)]="group.maxUsers"
              />
            </div>
            <div class="form-group">
              <label class="control-label">Group type</label>
              <select
                [(ngModel)]="group.tier"
                class="form-control"
                [compareWith]="compareEnums"
              >
                <option
                  *ngFor="
                    let tier of groupTiers | keyvalue;
                    trackBy: trackByGroupName
                  "
                  [ngValue]="tier.value"
                >
                  {{ tier.value.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 col-md-offset-3 col-xs-12">
            <button class="btn btn-success" (click)="saveGroup()">
              Save Group
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="hpanel" *ngIf="isSuperAdmin">
      <div class="panel-heading">Assessment Configuration</div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Configure Group Assessments</h5>
            <p>
              Assessment configuration is used to edit the preassessment
              requirements that a user must complete in order for them to take
              an IPSAT.
            </p>
          </div>
          <div class="col-md-9 col-xs-12">
            <div class="form-group">
              <label class="control-label">MBTI Assessment</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="group.preferredPreassessments.mbti"
              />
            </div>
            <div class="form-group">
              <label class="control-label">Strengths Assessment</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="group.preferredPreassessments.strengths"
              />
            </div>
            <div
              class="form-group"
              *ngIf="group.surveyType.identifier === 'faith_based'"
            >
              <label class="control-label">Spiritual Gifts Assessment</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="group.preferredPreassessments.spiritualGifts"
              />
            </div>
            <div class="form-group">
              <label class="control-label">Passions Assessment</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="group.preferredPreassessments.passions"
              />
            </div>
            <button class="btn btn-success" (click)="saveGroup()">
              Save Group
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="hpanel" *ngIf="groupAdmins.length > 0">
      <div class="panel-heading">Group Admins</div>
      <div class="panel-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <th>First Name</th>
              <th>Last Name</th>
              <th><i class="fa fa-envelope-o"></i>&nbsp;&nbsp;Email</th>
              <th><i class="fa fa-calendar"></i>&nbsp;&nbsp;Created</th>
              <th><i class="fa fa-user"></i>&nbsp;&nbsp;User Link</th>
              <th><i class="fa fa-trash-o"></i>&nbsp;&nbsp;Delete</th>
            </thead>
            <tbody>
              <tr *ngFor="let user of groupAdmins">
                <td>{{ user.firstName }}</td>
                <td>{{ user.lastName }}</td>
                <td>
                  <a [href]="'mailto:' + user.email">{{ user.email }}</a>
                </td>
                <td>{{ user.createdAt | date }}</td>
                <td>
                  <a [routerLink]="['/users', user._id, 'surveyResults']">Link</a>
                </td>
                <td>
                  <button class="btn btn-danger" (click)="deleteUser(user)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="hpanel">
      <div class="panel-heading">Custom Onboarding</div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Onboarding Section</h5>
            <p>
              You can add a custom description and upload a profile photo or
              group logo to be displayed during the onboarding process of your
              participants.
            </p>
          </div>
          <div class="col-md-9 col-xs-12">
            <div class="flex flex-vertical-center">
              <p class="m-b-none">
                The ability to add a custom onboarding for this group is
                currently
                <b>{{
                  group.customOnboarding.enabledForGroup
                    ? "enabled"
                    : "disabled"
                }}</b
                >.
              </p>
              <div class="flex-grow"></div>
              <button class="btn btn-default" (click)="toggleOnboarding()">
                {{
                  group.customOnboarding.enabledForGroup ? "Disable" : "Enable"
                }}
              </button>
            </div>
          </div>
          <div
            class="col-md-9 col-xs-12"
            *ngIf="group.customOnboarding.enabledForGroup"
          >
            <hr />
            <div class="form-group m-b-sm">
              <label class="control-label">Onboarding Heading</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="group.customOnboarding.heading"
              />
            </div>
            <div class="form-group m-b-sm">
              <label class="control-label">Onboarding Description</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="group.customOnboarding.description"
                rows="4"
              ></textarea>
            </div>
            <div class="pull-right m-b-sm">
              <button
                class="btn btn-default"
                (click)="previewCustomOnboarding()"
                style="margin-right: 3px"
              >
                Preview
              </button>
              <button class="btn btn-success" (click)="saveGroup()">
                Save
              </button>
            </div>
            <div class="clearfix"></div>
            <hr />
            <app-nexleader-group-photo-upload
              *ngIf="group && group._id"
              [groupId]="group._id"
              (handleOnChange)="load()"
            ></app-nexleader-group-photo-upload>
            <div *ngIf="group.customOnboarding.videoEnabled">
              <hr />
              <label class="control-label">Onboarding Video</label>
              <p>
                You are able to embed a custom video into the onboarding for
                your participants to view before they complete the IPSAT.
              </p>
              <br />
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <label class="control-label">Video Provider</label>
                  <app-nexleader-input-enum
                    [enumName]="'VideoProviders'"
                    [model]="group.customOnboarding.videoProvider"
                    (realOnChange)="
                      group.customOnboarding.videoProvider = $event; saveGroup()
                    "
                    >/</app-nexleader-input-enum
                  >
                </div>
                <div
                  class="col-sm-6 col-xs-12"
                  *ngIf="
                    group.customOnboarding.videoProvider &&
                    group.customOnboarding.videoProvider !== 'null'
                  "
                >
                  <label class="control-label"
                    >{{ group.customOnboarding.videoProvider.name }} Video
                    ID</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="group.customOnboarding.videoUrl"
                    [ngModelDebounce]="1000"
                    (ngModelDebounceChange)="saveGroup()"
                  />
                  <!-- <p class="m-t-xs">We've partnered with Vimeo to allow you to upload a custom
                                        onboarding video. Learn more on how to embed it <a href ng-click="$ctrl.openLearnAboutVideoModal()">here</a>.</p> -->
                </div>
              </div>
            </div>
            <div *ngIf="isSuperAdmin" class="m-t-md">
              <hr class="m-b-sm" />
              <p class="text-muted small">
                You can only see these settings since you're a super admin.
              </p>
              <br />
              <p>
                Toggle ability for the group to upload a Vimeo video. Since
                adding a Vimeo video to the onboarding is a big addition for the
                onboarding, we allow you to enable/disable this functionality
                for groups.
              </p>
              <br />
              <div class="flex flex-vertical-center">
                <p class="m-b-none">
                  The ability to upload an onboarding video for this group is
                  currently
                  <b>{{
                    group.customOnboarding.videoEnabled ? "enabled" : "disabled"
                  }}</b
                  >.
                </p>
                <div class="flex-grow"></div>
                <button
                  class="btn btn-default"
                  (click)="toggleOnboardingVideoUpload()"
                >
                  {{
                    group.customOnboarding.videoEnabled ? "Disable" : "Enable"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hpanel" *ngIf="isSuperAdmin">
      <div class="panel-heading">Direct Purchase and ECourse Settings</div>
      <div class="panel-body">
        <div class="row m-b-md">
          <div class="col-md-9 col-md-offset-3 col-xs-12">
            <p class="text-muted small">
              You can only see these settings since you're a super admin.
            </p>
          </div>
        </div>
        <app-nexleader-group-direct-purchase-settings
          *ngIf="group"
          [group]="group"
        ></app-nexleader-group-direct-purchase-settings>
        <div class="row">
          <div class="col-md-9 col-md-offset-3 col-xs-12">
            <button class="btn btn-success" (click)="saveGroup()">
              Save Group
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="hpanel" *ngIf="isSuperAdmin">
      <div class="panel-heading">Dangerous Options</div>
      <div class="panel-body">
        <div class="row m-b-md">
          <div class="col-md-9 col-md-offset-3 col-xs-12">
            <p class="text-muted small">
              You can only see these settings since you're a super admin.
            </p>
          </div>
        </div>
        <div class="row m-b-lg">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Legacy Group</h5>
          </div>
          <div class="col-md-9 col-xs-12">
            <p>
              Making this group the legacy group will set it to receive all
              users that have been removed from a group but still remain a
              member of the IPSAT.
            </p>
            <button class="btn btn-primary m-t-sm" (click)="makeLegacy()">
              Make Legacy Group
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Delete Group</h5>
          </div>
          <div class="col-md-9 col-xs-12">
            <p>
              This will delete the group and all associated users, email
              customizations, evaluations, survey results, and other objects.
            </p>
            <button
              class="btn btn-danger m-t-sm"
              (click)="deleteGroup()"
              id="nexleader-test-delete-group-btn"
            >
              Delete Group
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
