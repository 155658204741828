<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<!-- Print button (hidden on print) -->
<div class="flex flex-vertical-center flex-horizontal-center hideOnPrint w-100 h-100vh">
  <button class="btn btn-lg btn-primary" (click)="print()">
    <i class="fa fa-print"></i>&nbsp;&nbsp;Print your Skills Inventory Result
  </button>
</div>

<ng-container *ngIf="scoredSkillsInventoryResult">
  <!-- First Page: Title Page -->
  <div class="centeredFirstPage">
    <div class="centeredFirstPageInside">
      <img src="/assets/images/icons/skills.svg" alt="Skills Inventory icon" width="200px" />
      <br />
      <br />
      <h1>The Skills <br />Inventory Report</h1>
      <br />
      <br />
      <p>
        <b>Prepared for:</b>
        {{ scoredSkillsInventoryResult.user.firstName }}
        {{ scoredSkillsInventoryResult.user.lastName }}
      </p>
      <p>
        <b>Completed:</b> {{ scoredSkillsInventoryResult.createdAt | date }}
      </p>
    </div>
  </div>
  <div class="page-break"></div>

  <!-- Body 1 (Multiple pages): Skill Aggregation -->
  <h2 class="font-bold m-b-md">Your Skills Inventory Result</h2>
  <div *ngFor="let skillGroup of scoredSkillsInventoryResult.skillGroups">
    <p class="m-b-md">
      Based on your responses to the Skills Inventory, you are
      <b>{{ skillGroup.name }}</b> in the following areas:
    </p>
    <app-nexleader-skills-inventory-bar [val]="skillGroup.display_value" [width]="750" [height]="80"
      [disableResponsive]="true"></app-nexleader-skills-inventory-bar>
    <br />
    <p *ngIf="skillGroup.skills.length < 1">
      You did not identify any skills as {{ skillGroup.name }}.
    </p>
    <ul *ngIf="skillGroup.skills.length > 0" class="nexleader-skills-inventory-skill-list">
      <li *ngFor="let skill of skillGroup.skills">
        <span>{{ skill.name }} ({{ skill.selectedOption.weight }})</span>
        <br />
        <i>{{ skill.additionalResponse }}</i>
      </li>
    </ul>
    <hr />
  </div>

  <!-- Body 2 (Multiple pages): Questions for Reflection -->
  <h4 class="font-bold">Questions for Reflection</h4>
  <div>
    <p class="m-b-md">
      To what extent would your friends, peers, and colleagues validate your
      perspective as it relates to your most well developed skills? Who could
      you ask about this to test your self-awareness for blind spots that might
      cause you to overestimate your capacity?
    </p>
    <p class="m-b-md">
      What methods have been most effective in helping you cultivate these
      skills? How could you repurpose this learning methodology to develop other
      skills?
    </p>
    <p>
      How could these skills synergize with other aspects of your identity
      profile?
    </p>
  </div>
  <hr />

  <!-- Body 3: (Multiple Pages): Ideal Role -->
  <h4 class="font-bold">Ideal Role</h4>
  <div class="m-b-md">
    <p>
      The skills you believe you will need to be effective in the role to which
      you aspire most in the future are:
    </p>
  </div>
  <div class="m-b-md">
    <b>Future Ideal Role</b>
    <br />
    <p>{{ scoredSkillsInventoryResult.textResponses.futureIdealRole }}</p>
  </div>
  <div class="m-b-md">
    <b>Skills Needed</b>
    <br />
    <p>{{ scoredSkillsInventoryResult.textResponses.skillsNeeded }}</p>
  </div>
  <div class="m-b-md">
    <b>Under-Developed Skills</b>
    <br />
    <p>{{ scoredSkillsInventoryResult.textResponses.underdevelopedSkills }}</p>
  </div>
  <div>
    <b>Under-Developed Skills Explanation</b>
    <br />
    <p>
      {{
      scoredSkillsInventoryResult.textResponses
      .underdevelopedSkillsExplanation || "No explanation"
      }}
    </p>
  </div>

  <!-- Last Page: Skills Overview-->
  <div class="page-break"></div>
  <h4 class="font-bold m-b-md">Skills Inventory Overview</h4>
  <app-nexleader-skills-inventory-skill-chart *ngIf="scoredSkillsInventoryResult.skills"
    [skills]="scoredSkillsInventoryResult.skills" [width]="750" [height]="930"
    [disableResponsive]="true"></app-nexleader-skills-inventory-skill-chart>
</ng-container>