<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">{{ modalSection.title }}</h3>
</div>
<div class="modal-body">
  <p>
    {{ modalSection.description }}
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
