<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="hpanel">
          <div class="panel-body">
            <div class="flex flex-vertical-center">
              <img src="/assets/images/icons/general_resources.svg" alt="general resources" class="img-md" />
              <div class="m-l-sm">
                <h5 class="text-primary-2">Small Group Resources</h5>
                <p>
                  Learn more about small groups, how they work, and view
                  resources for each module. Each module has separate handouts
                  and resources. View them
                  <a [routerLink]="'/smallGroupResources'">here</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hpanel" *ngIf="getActiveSmallGroups().length < 1">
      <div class="panel-body">
        No small groups have been configured for you.
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12" *ngFor="let smallGroup of getActiveSmallGroups()">
        <app-nexleader-small-group-card [smallGroupId]="smallGroup._id"
          (onChange)="onSmallGroupChange()"></app-nexleader-small-group-card>
      </div>
    </div>
    <app-nexleader-archived-small-groups-table *ngIf="smallGroups"
      [smallGroups]="smallGroups"></app-nexleader-archived-small-groups-table>
  </div>
</div>