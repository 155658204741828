/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-statistic',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './statistic.component.html',
})
export class NexleaderStatisticComponent {
  @Input() name: any;
  @Input() value: any;
  @Input() text: any;
  @Input() icon: any;
  @Input() link: any;
  @Input() description: any;
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderStatisticComponent',
//   downgradeComponent({
//     component: NexleaderStatisticComponent,
//   }) as angular.IDirectiveFactory
// );
