/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderSessionComponent } from '../../../views/session/session.component';

@Component({
  selector: 'app-nexleader-edit-session-modal',
  standalone: true,
  imports: [CommonModule, NexleaderSessionComponent],
  templateUrl: './edit-session-modal.component.html',
})
export class NexleaderEditSessionModalComponent {
  @Input() session: any;

  constructor(private bsModalService: BsModalService) { }

  close() {
    this.bsModalService.hide();
  }
}
