/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamReportService } from '../team-reports/team-report.service';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../../services/auth.service';
import { SuccessService } from '../../../../services/success.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { NexleaderTeamReportCostComponent } from '../../components/team-report-cost/team-report-cost.component';
import { NexleaderShareableTeamReportModalComponent } from '../../components/modals/shareable-team-report-modal/shareable-team-report-modal.component';
import { NexleaderChartIpsatDoughnutComponent } from '../../components/chart-ipsat-doughnut/chart-ipsat-doughnut.component';
import { NexleaderTeamReportProgressBarComponent } from '../../components/team-report-progress-bar/team-report-progress-bar.component';
import { NexleaderChartMbtiTableComponent } from '../../components/chart-mbti-table/chart-mbti-table.component';
import { NexleaderChartMbtiLetterAggregationComponent } from '../../components/chart-mbti-letter-aggregation/chart-mbti-letter-aggregation.component';
import { NexleaderTeamReportDiscussionQuestionsComponent } from '../../components/team-report-discussion-questions/team-report-discussion-questions.component';
import { NexleaderChartStrengthsRepresentedComponent } from '../../components/chart-strengths-represented/chart-strengths-represented.component';
import { NexleaderChartStrengthsUnrepresentedComponent } from '../../components/chart-strengths-unrepresented/chart-strengths-unrepresented.component';
import { NexleaderChartSkillComparisonComponent } from '../../components/chart-skill-comparison/chart-skill-comparison.component';
import { NexleaderChartSkillsComponent } from '../../components/chart-skills/chart-skills.component';
import { NexleaderChartSpiritualGiftsRepresentedComponent } from '../../components/chart-spiritual-gifts-represented/chart-spiritual-gifts-represented.component';
import { NexleaderChartSpiritualGiftsUnrepresentedComponent } from '../../components/chart-spiritual-gifts-unrepresented/chart-spiritual-gifts-unrepresented.component';
import { NexleaderChartPassionsRepresentedComponent } from '../../components/chart-passions-represented/chart-passions-represented.component';
import { NexleaderTeamReportDomainsComponent } from '../../components/team-report-domains/team-report-domains.component';
import { NexleaderChartPersonalValuesRepresentedComponent } from '../../components/chart-personal-values-represented/chart-personal-values-represented.component';
import { NexleaderChartPersonalValuesSpiderComponent } from '../../components/chart-personal-values-spider/chart-personal-values-spider.component';
import { NexleaderAddTeamMemberModalComponent } from '../../components/modals/add-team-member-modal/add-team-member-modal.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-team-report',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    NexleaderTeamReportCostComponent,
    NexleaderChartIpsatDoughnutComponent,
    NexleaderTeamReportProgressBarComponent,
    NexleaderChartMbtiTableComponent,
    NexleaderChartMbtiLetterAggregationComponent,
    NexleaderTeamReportDiscussionQuestionsComponent,
    NexleaderChartStrengthsRepresentedComponent,
    NexleaderChartStrengthsUnrepresentedComponent,
    NexleaderChartSkillComparisonComponent,
    NexleaderChartSkillsComponent,
    NexleaderChartSpiritualGiftsRepresentedComponent,
    NexleaderChartSpiritualGiftsUnrepresentedComponent,
    NexleaderChartPassionsRepresentedComponent,
    NexleaderTeamReportDomainsComponent,
    NexleaderChartPersonalValuesRepresentedComponent,
    NexleaderChartPersonalValuesSpiderComponent,
  ],
  templateUrl: './team-report.component.html',
})
export class NexleaderTeamReportComponent implements OnInit {
  routeParams: any;

  // We need group_id & team_report_id from the route in order to correctly redirect
  group_id: string = '';
  team_report_id: string = '';

  // A relative URL we can use to redirect the user to in case they are not logged in
  relativeUrl: string = '';
  teamReport: any;
  isAllowedToEditTeamReport: boolean = false;
  error: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private teamReportService: TeamReportService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private authService: AuthService,
    private modalService: BsModalService,
    private successService: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.group_id = this.routeParams['group_id'];
    this.team_report_id = this.routeParams['team_report_id'];
    this.relativeUrl = `/groups/${this.group_id}/teamReports/${this.team_report_id}`;

    combineLatest([
      this.teamReportService.score(this.team_report_id, this.group_id),
      this.teamReportService.canEdit(this.team_report_id, this.group_id),
    ])
      .pipe(
        tap(([teamReport, canEditResponse]) => {
          this.teamReport = teamReport;
          this.isAllowedToEditTeamReport = canEditResponse.canViewTeamReport;
        }),
        catchError((error) => {
          this.error = error;
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * shareTeamReport
   *
   * Opens a share team report modal that allows users to share their team report.
   */
  shareTeamReport(): void {
    const modalRef = this.modalService.show(
      NexleaderShareableTeamReportModalComponent,
      {
        initialState: {
          shareableLink: `#/groups/${this.group_id}/teamReports/${this.team_report_id}`,
        },
      }
    );
  }

  /**
   * updateTeamReport
   *
   * Updates a team report to have the correct new permissions for a user viewing it.
   */
  markUserAsAbleToViewTeamReport(user: any): void {
    this.teamReportService
      .updateUserPermissions(this.team_report_id, this.group_id, {
        user: user._id,
        group: this.group_id,
      })
      .pipe(
        tap(() => {
          this.successService.handle();
        }),
        catchError((error) => {
          this.error = error;
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * addTeamMember
   *
   * Adds a new member to the team report.
   *
   * This function opens a modal that handles the logic for adding a team member.
   */
  addTeamMember(): void {
    const modalRef = this.modalService.show(
      NexleaderAddTeamMemberModalComponent,
      {
        initialState: {
          groupId: this.group_id,
          teamReportId: this.team_report_id,
          currentUsers: this.teamReport.teamUsers.map((u: any) => u._id),
        },
      }
    );
    modalRef.content?.close.subscribe(() => {
      modalRef.hide();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([this.relativeUrl]);
      });
    });
  }

  /**
   * removeTeamMember
   *
   * Removes a team member from a team report.
   */
  removeTeamMember(user: any): void {
    this.teamReportService
      .removeUserFromTeamReport(this.team_report_id, this.group_id, {
        user: user._id,
        group: this.group_id,
      })
      .pipe(
        tap(() => {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([this.relativeUrl]);
          });
        }),
        catchError((error) => {
          this.error = error;
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * canRemoveTeamMember
   *
   * Simple logic operation that lets users know if they can remove additional members from a team report.
   *
   * @returns {boolean}
   */
  canRemoveTeamMember(): boolean {
    return this.teamReport?.teamUsers?.length > 1;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderTeamReportComponent',
//   downgradeComponent({
//     component: NexleaderTeamReportComponent,
//   }) as angular.IDirectiveFactory
// );
