<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div>
  <div class="flex flex-column-md">
    <input
      type="text"
      class="form-control"
      placeholder="Search for a value"
      [(ngModel)]="searchValue"
      (ngModelChange)="searchAllValues()"
    />
    <div class="flex-grow w-xxl-sm m-b-sm"></div>
    <select
      class="form-control"
      id="nexleader-test-pvi-select-page-count"
      [(ngModel)]="limit"
      (ngModelChange)="onLimitChange()"
    >
      <option value="20">Show 20</option>
      <option value="999999">Show All</option>
    </select>
  </div>
  <hr />
  <div class="hide-mobile">
    <div class="row flex flex-wrap">
      <div
        class="col-sm-3 m-b-md"
        *ngFor="
          let value of getSelectedValues() | filter : undefined : searchValue;
          let index = index
        "
        (click)="selectValue(value)"
      >
        <i class="fa fa-plus text-muted pull-right m-t-sm m-r-sm"></i>
        <div
          id="nexleader-test-pvi-select-value-{{ index }}"
          class="p-sm bg-light font-semi-bold cursor-pointer border-radius-sm no-select"
        >
          <h5 class="word-wrap">{{ value.name }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="show-mobile">
    <table class="table">
      <thead>
        <tr>
          <th>Value</th>
          <th>Selected</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let value of getSelectedValues() | filter : undefined : searchValue
          "
          (click)="selectValue(value)"
        >
          <td>{{ value.name }}</td>
          <td>
            <i class="fa fa-check text-success" *ngIf="value.selected"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="limit === 20" class="flex flex-vertical-center">
    <button
      class="btn btn-default"
      (click)="previousSetOfValues()"
      *ngIf="pageIndex > 0"
    >
      Previous
    </button>
    <div class="flex-grow"></div>
    {{ pageIndex + 1 }}/5
    <div class="flex-grow"></div>
    <button
      class="btn btn-default"
      id="nexleader-test-pvi-select-next-page"
      (click)="nextSetOfValues()"
      *ngIf="pageIndex < 4"
    >
      Next
    </button>
  </div>
</div>
