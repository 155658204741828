<div *ngIf="videoUrl">
  <div class="embed-container">
    <iframe
      [src]="videoUrl"
      width="540"
      height="303"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</div>
