/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../services/enum.service';
import { EvaluationService } from '../../../../services/evaluation.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NexleaderEditQuestionsComponent } from '../edit-questions/edit-questions.component';

@Component({
  selector: 'app-nexleader-edit-evaluation',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderEditQuestionsComponent],
  templateUrl: './edit-evaluation.component.html',
})
export class NexleaderEditEvaluationComponent implements OnInit {
  routeParams: any;
  enums: any;
  evaluation: {
    createdAt: string;
    updatedAt: string;
    _id: string;
    description: string;
    interpretation: [];
    name: string;
    questions: any[];
  } = {
      createdAt: '',
      updatedAt: '',
      _id: '',
      description: '',
      interpretation: [],
      name: '',
      questions: [],
    };

  constructor(
    private enumsService: EnumsService,
    private evaluationService: EvaluationService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    forkJoin([
      this.enumsService.getEnums(),
      this.evaluationService.getEvaluation(this.routeParams['evaluation_id']),
    ])
      .pipe(
        tap(([enums, evaluation]) => {
          this.enums = enums;
          this.evaluation = evaluation;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  newQuestion(): void {
    // Jank workaround, needs fixing
    this.evaluation.questions.push({
      name: '',
      answers: Array.from({ length: 6 }, (_, i) => ({
        name: i + 1,
        summary: '',
      })),
    });
  }

  saveEvaluation(): void {
    this.evaluationService
      .save(this.evaluation)
      .pipe(
        tap(() => {
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  deleteEvaluation(): void {
    this.evaluationService
      .delete(this.evaluation._id)
      .pipe(
        tap(() => {
          this.router.navigate(['/edits']);
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEditEvaluationComponent',
//   downgradeComponent({
//     component: NexleaderEditEvaluationComponent,
//   }) as angular.IDirectiveFactory
// );
