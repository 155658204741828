/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewAdminCourseContent
 *
 * angular component
 */
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseContentService } from '../../resources/course-content.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { BlobResourceService } from '../../../../services/blob.service';
import { NexleaderCourseContentInputComponent } from '../../components/course-content-input/course-content-input.component';
import { NexleaderCourseContentCardComponent } from '../../components/course-content-card/course-content-card.component';

@Component({
  selector: 'app-nexleader-course-content-admin-view',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderCourseContentInputComponent,
    NexleaderCourseContentCardComponent,
  ],
  templateUrl: './course-content-admin-view.component.html',
})
export class NexleaderCourseContentAdminViewComponent implements OnInit {
  allCourseContent: any[] = [];
  blobs: any[] = [];
  mappedCourseContent: any[] = [];

  /**
   * $ctrl.newCourseContentInformation
   *
   * Placeholder object for new course content.
   */
  newCourseContentInformation = {
    contentName: '',
    contentDescription: '',
    courseObjectives: '',
    contentUrl: '',
    tags: '',
  };

  constructor(
    private courseContentService: CourseContentService,
    private successService: SuccessService,
    private blobResourceService: BlobResourceService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  /**
   * $ctrl.load()
   *
   * Loads all IPSAT U associated content.
   *
   * Called when the initial page loads.
   */
  load(): void {
    forkJoin([
      this.courseContentService.getCourseContent(),
      this.blobResourceService.getBlob(),
    ])
      .pipe(
        tap(([courseContent, blobs]) => {
          this.allCourseContent = courseContent;
          this.blobs = blobs;

          this.mappedCourseContent = this.allCourseContent.map((content) => {
            const blob = this.blobs?.find(
              (b) =>
                b.file &&
                b.file.metadata &&
                b.file.metadata.courseContentId === content._id
            );
            return { ...content, blob: blob };
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  hasValidFormInput(courseContent: any): boolean {
    return (
      courseContent &&
      courseContent.contentName &&
      courseContent.contentName.length > 0 &&
      courseContent.contentDescription &&
      courseContent.contentDescription.length > 0 &&
      courseContent.courseObjectives &&
      courseContent.courseObjectives.length > 0 &&
      courseContent.contentUrl &&
      courseContent.contentUrl.length > 0 &&
      courseContent.contentUrl.match(
        new RegExp(
          /ht{2}ps?:\/{2}(w{3}\.)?[#%+-.0-:=@-Z_a-z~]{1,256}\.[\d()A-Za-z]{1,6}\b([#%&()+-:=?-Z_a-z~]*)/
        )
      ) &&
      courseContent.coursePrice &&
      courseContent.coursePrice.length > 0 &&
      courseContent.internalTags &&
      courseContent.internalTags.length > 0
    );
  }

  saveCourseContent(courseContent: any): void {
    if (!this.hasValidFormInput(courseContent)) return;

    const mappedCourseTags = courseContent.internalTags
      .split(',')
      .map((tag: string) => tag.trim().toLowerCase());

    if (courseContent._id) {
      this.courseContentService
        .saveCourseContent(
          {
            ...courseContent,
            tags: mappedCourseTags,
          },
          courseContent._id
        )
        .pipe(
          tap(() => {
            this.successService.handle({
              message: 'Successfully saved IPSAT content piece.',
            });
            this.load();
          }),
          catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.courseContentService
        .saveCourseContent({ ...courseContent, tags: mappedCourseTags })
        .pipe(
          tap(() => {
            this.newCourseContentInformation = {
              contentName: '',
              contentDescription: '',
              courseObjectives: '',
              contentUrl: '',
              tags: '',
            };
            this.successService.handle({
              message: 'Successfully added IPSAT content piece.',
            });
            this.load();
          }),
          catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    }
  }

  removeCourseContent(courseContent: any): void {
    this.courseContentService
      .removeCourseContent(courseContent._id)
      .pipe(
        tap((_) => {
          this.successService.handle({
            message: 'Successfully removed IPSAT content piece.',
          });
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCourseContentAdminViewComponent',
//   downgradeComponent({
//     component: NexleaderCourseContentAdminViewComponent,
//   }) as angular.IDirectiveFactory
// );
