/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/**
 * nexleaderViewSurveyResult
 *
 * view
 *
 * This view is one of the most fundamental views for the entire IPSAT project. It displays data for a user about a
 *  user's survey result score based off of the IPSAT they recently took. It displays all of the data from an IPSAT
 *  result and also allows a user to edit their open-ended IPSAT questions.
 */
import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SurveyResultService } from './survey-result.service';
import { UserService } from '../../../../services/user.service';
import { SurveyResultCalculationsService } from './survey-result-calculations.service';
import { EnumsService } from '../../../../services/enum.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { AuthService } from '../../../../services/auth.service';
import { EMPTY, catchError, forkJoin } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { Chart } from 'chart.js';
import { CHART_COLORS } from '../../../../../../constants';
import { NexleaderShareableIpsatModalComponent } from '../../components/modals/shareable-ipsat-modal/shareable-ipsat-modal.component';
import { QuestionCategoryPipe } from '../../../../pipes/question-category.pipe';
import { environment } from '../../../../../environments/environment';
import { NexleaderCategoryScoreComponent } from '../../components/category-score/category-score.component';
import { NexleaderResultMoreModalComponent } from '../../components/modals/result-more-modal/result-more-modal.component';
import { NexleaderMaximumWordsValidationDirective } from '../../../../directives/max-words.directive';
import { NexleaderTabsComponent } from '../../components/tabs/tabs.component';

@Component({
  selector: 'app-nexleader-survey-result',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    NexleaderShareableIpsatModalComponent,
    QuestionCategoryPipe,
    NexleaderCategoryScoreComponent,
    NexleaderResultMoreModalComponent,
    NexleaderMaximumWordsValidationDirective,
    NexleaderTabsComponent,
  ],
  templateUrl: './survey-result.component.html',
})
export class NexleaderSurveyResultComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  routeParams: any;
  result: any;
  user: any;
  surveyType!: string;
  possessivePrefix!: string;
  userIsInSmallGroup!: boolean;
  ipsatQuestionCategories: any; // Adjust the type accordingly
  openEndedQuestionCategory: any; // Adjust the type accordingly
  summaryOverviewQuestionCategory: any; // Adjust the type accordingly
  ipsatTotal!: number;
  ipsatStage: any; // Adjust the type accordingly
  openEndedQuestions!: any[]; // Adjust the type accordingly
  editingOpenEndedStatements!: boolean;
  private chart: any;
  public doughnutStyle: { [key: string]: string } = {};

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private surveyResultService: SurveyResultService,
    private userService: UserService,
    private surveyResultCalculationsService: SurveyResultCalculationsService,
    private enumsService: EnumsService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService
  ) { }

  ngAfterViewChecked(): void {
    this.drawChart();
  }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    // Declare the style to show elements as when printing
    this.doughnutStyle = {
      'max-width': '315px',
      'margin-left': 'auto',
      'margin-right': 'auto',
    };

    forkJoin({
      result: this.surveyResultService.getSurveyResult(
        this.routeParams['survey_result_id']
      ),
      user: this.userService.getUser(this.routeParams['user_id']),
      enums: this.enumsService.getEnums(),
    })
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe(({ result, user, enums }) => {
        // Fetch the survey result for the given user
        this.result = result;
        this.user = user;
        this.surveyType = user.group.surveyType.identifier;

        // Variable used in the view to show/hide certain buttons if the user is in a small group
        this.userIsInSmallGroup = !!this.user.smallGroup;
        this.ipsatQuestionCategories = enums.AllQuestionCategories;
        this.openEndedQuestionCategory = enums.QuestionCategories.OPEN_ENDED;
        this.summaryOverviewQuestionCategory =
          enums.QuestionCategories.SUMMARY_OVERVIEW;

        /**
         * possessivePrefix
         *
         * This prefix is the way we display the ownership of the IPSAT score.
         *
         * @type {string}
         */
        this.possessivePrefix =
          this.user._id === this.authService.getTokenPayload().user
            ? 'Your'
            : this.user.firstName + "'s";

        this.surveyResultCalculationsService.interpretCategories(
          this.ipsatQuestionCategories,
          this.result
        );

        // We calculate the total of the the entire result
        this.ipsatTotal =
          this.surveyResultCalculationsService.calculateTotalIpsatScore(
            this.result
          );
        // And the current stage the user is at
        // This stage is defined in the SurveyResultCalculations service and serves as a verbal way to show
        //  where a user currently is in terms of understanding their IPSAT score.
        this.ipsatStage = {
          name: this.surveyResultCalculationsService.calculateCurrentIpsatStage(
            this.ipsatTotal
          ).name,
          description: this.ipsatQuestionCategories[6].range.name,
        };

        // Open-ended questions are those that have a category of "Open-Ended Questions"
        this.openEndedQuestions = this.result.questions.filter(
          (question: any) => {
            return question.questionCategory.name === 'Open-Ended Questions';
          }
        );

        this.drawChart();
      });
  }

  drawChart() {
    if (!this.chart) {
      // This $scope.$watch is necessary as we need to be sure the doughnut-chart element is currently visible
      // We also need to know that the doughnut-chart element is available to use
      const ctx = document.getElementById(
        'doughnut-chart'
      ) as HTMLCanvasElement;
      if (ctx) {
        // Configuration for how we show the doughnut chart on the survey result
        this.chart = new Chart(ctx, {
          type: 'doughnut',
          // Configuration for the data of the survey result calculation
          data: {
            labels: this.surveyResultCalculationsService.doughnutLabels(
              this.surveyType
            ),
            datasets: [
              {
                label: 'Your IPSAT Score breakdown',
                data: this.surveyResultCalculationsService.calculateDoughnutChart(
                  this.ipsatQuestionCategories,
                  this.ipsatTotal
                ),
                backgroundColor: CHART_COLORS,
              },
            ],
            // options: {
            //   segmentShowStroke: false,
            // },
          },
          // We don't show the legend and there is no border on the doughnut chart
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            elements: {
              arc: {
                borderWidth: 0,
              },
            },
          },
        });
      }
    }
  }

  /**
   * learnMoreAboutResult()
   *
   * Function
   *
   * Shows more information about a single question category in the form of a modal.
   *
   * @param questionCategory - A specific category that the user requests to learn more about.
   */
  learnMoreAboutResult(questionCategory: any) {
    this.modalService.show(NexleaderResultMoreModalComponent, {
      initialState: {
        questionCategory: questionCategory,
        result: this.result,
        surveyType: this.surveyType,
        questions: this.result.questions.filter(function (a: any) {
          return a.questionCategory.index === questionCategory.index;
        }),
      },
    });
  }

  /**
   * shareIPSAT()
   *
   * Function
   *
   * Opens a modal that has links and buttons for a user to share their IPSAT score with others.
   */
  shareIPSAT() {
    const modalRef = this.modalService.show(
      NexleaderShareableIpsatModalComponent,
      {
        initialState: {
          shareableID: this.result._id,
        },
      }
    );

    modalRef.content?.close.subscribe(() => {
      modalRef.hide();
    });
  }

  /**
   * toggleEditableOpenEndedStatements()
   *
   * Helper Function
   *
   * Toggles on and off the ability to edit the open-ended statements.
   */
  toggleEditableOpenEndedStatements() {
    this.editingOpenEndedStatements = !this.editingOpenEndedStatements;
  }

  /**
   * saveIdentityStatements()
   *
   * Function
   *
   * Saves the identity statements by saving the version of the survey result the user has edited.
   */
  saveIdentityStatements() {
    this.surveyResultService
      .save(this.result, this.result._id)
      .subscribe(() => {
        this.successService.handle();
        this.editingOpenEndedStatements = false;
      });
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSurveyResultComponent',
//   downgradeComponent({
//     component: NexleaderSurveyResultComponent,
//   }) as angular.IDirectiveFactory
// );
