import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NetPromoterService {
  calculateNetPromoterScore(
    evaluationResults: any[]
  ): Promise<{ score: number; class: string }> {
    return new Promise((resolve, reject) => {
      // Verify that evaluationResults exists
      if (!evaluationResults) {
        reject('evaluationResults is required');
      }

      // Total number of respondents
      const totalRespondents = evaluationResults.length;

      // Total number of promoters
      const promoters = evaluationResults.filter(
        (result) => result.questions[0].answer.value >= 9
      ).length;

      // Total number of detractors
      const detractors = evaluationResults.filter(
        (result) => result.questions[0].answer.value <= 6
      ).length;

      // Calculates the percentage of promoters
      const percentPromoters = promoters / totalRespondents;

      // Calculates the percentage of detractors
      const percentDetractors = detractors / totalRespondents;

      // Calculates the net promoter score
      const netPromoterScore = percentPromoters - percentDetractors;

      // Round score to nearest integer
      const netPromoterScoreRounded = Math.round(netPromoterScore * 100);

      // Calculate the color for the box
      this.correspondingColor(netPromoterScoreRounded)
        .then((color) => {
          resolve({
            score: netPromoterScoreRounded,
            class: color,
          });
        })
        .catch((err) => reject(err));
    });
  }

  correspondingColor(score: number): Promise<string> {
    return new Promise((resolve, reject) => {
      // If the type of the score is not a number, reject immediately
      if (typeof score !== 'number') {
        reject({ message: 'Invalid score to process color', status: 500 });
      }

      if (score < 30) {
        // Return a danger background if score is less than 33
        resolve('hbgred');
      } else if (score >= 30 && score < 50) {
        // Return a warning background if the score is greater or equal to 33 but less than 80
        resolve('hbgyellow');
      } else if (score >= 50) {
        // Return a success background if the score is greater or equal to 80
        resolve('hbggreen');
      } else {
        // Reject if the score is not processable
        reject('Not processable');
      }
    });
  }

  formatNetPromoterDataForBarChart(evaluationResults: any[]): number[] {
    const finalArray: number[] = Array.from({ length: 11 }, () => 0);

    // Update finalArray based on the evaluation result objects
    evaluationResults.forEach((result) => {
      finalArray[result.questions[0].answer.value]++;
    });

    return finalArray;
  }
}
