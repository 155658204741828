/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
/**
 * nexleaderOnboardingSpiritualGifts
 *
 * The view component for the onboarding spiritual gifts section of the onboarding preassessments.
 * This view contains information and fields that pertain to this part of the onboarding.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderYesNoButtonsComponent } from '../../../../../core/components/yes-no-buttons/yes-no-buttons.component';
import { NexleaderProfileWizardUploadFileComponent } from '../../../../../core/components/profile-wizard-upload-file/profile-wizard-upload-file.component';
import { BlobResourceService, BlobService } from '../../../../../../services/blob.service';
import { EnumsService } from '../../../../../../services/enum.service';
import { tap } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-onboarding-spiritual-gifts',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderYesNoButtonsComponent, NexleaderProfileWizardUploadFileComponent],
  templateUrl: './onboarding-spiritual-gifts.component.html'
})
export class NexleaderOnboardingSpiritualGiftsComponent implements OnInit {
  /**
   * save
   *
   * Allows data that is edited on this onboarding personality view to be saved.
   */
  @Output() save = new EventEmitter();

  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process
   */
  @Input() user: any;

  // An array of three false values to be used to define if the user is editing their spiritual gifts manually
  // Pretty jank way of solving the issue, but we cannot assign it to the user.prerequisites object, as that is just an array of strings
  // We need something that stores the state of whether or not the user is editing their spiritual gifts with custom fields
  editingSpiritualGiftsManually = [false, false, false];
  prerequisiteLink!: string;
  allSpiritualGifts: any[] = [];
  prerequisite: any;
  files: any[] = [];

  constructor(private enumsService: EnumsService, private blobService: BlobResourceService) { }

  ngOnInit() {
    // We need a spiritual gifts assessment link
    if (!this.user.group) throw new Error('user.group is required for component nexleaderOnboardingSpiritualGifts.');
    if (!this.user.group.preferredPreassessments) throw new Error('user.group.preferredPreassessments is required for component nexleaderOnboardingSpiritualGifts.');
    if (!this.user.group.preferredPreassessments.spiritualGifts) throw new Error('user.group.preferredPreassessments.spiritualGifts is required for component nexleaderOnboardingSpiritualGifts.');

    // set the link to the spiritual gifts' assessment link
    this.prerequisiteLink = this.user.group.preferredPreassessments.spiritualGifts;
    // Load all the files for the user
    this.loadFiles();

    this.enumsService.getEnums().subscribe((a) => {
      this.allSpiritualGifts = a.AllDefaultSpiritualGifts;
      this.prerequisite = a.Prerequisites.SPIRITUAL_GIFTS;
    });

    for (let i = this.user.prerequisites.spiritualGifts.length; i < 3; i++) {
      this.user.prerequisites.spiritualGifts.push({ name: '' });
    }
  }

  trackByName(index: number, option: any): number {
    return option.name;
  }

  compareGifts(o1: any, o2: any) {
    if (o1?.name == o2?.name) return true;
    else return false;
  }

  /**
   * editSpiritualGiftManually
   *
   * Allows a user to edit a single spiritual gift manually
   *
   * @param {Number} index - A number that defines the index of where to update which spiritual gift
   */
  editSpiritualGiftManually(index: number) {
    // Set the value to the opposite of the current value
    this.editingSpiritualGiftsManually[index] = !this.editingSpiritualGiftsManually[index];
  }

  /**
   * loadFiles
   *
   * Allows us to query for files for a specific user based off of data passed into the component
   */
  loadFiles(): void {
    this.blobService.queryUser(this.user._id).pipe(
      tap((response) => {
        this.files = response;
      })
    ).subscribe();
  }
}
