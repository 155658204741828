/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderLicensingPartnerStripeConnectInvite
 *
 * component
 *
 * The responsibility of this component is to display a button that says "Invite to Stripe Connect" that calls the
 *  sendStripeConnectAccountInvite route.
 *
 * If a Licensing Partner already has a stripe account connection, then the button should be replaced
 *  by text that provides some information about the stripe connection.
 */
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LicensingPartnerService } from '../../resources/licensing-partner.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-licensing-partner-stripe-connect-invite',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './licensing-partner-stripe-connect-invite.component.html',
})
export class NexleaderLicensingPartnerStripeConnectInviteComponent {
  private _licensingPartnerId: string = '';

  /**
   * licensingPartnerId
   *
   * string binding
   *
   * the id of the licensing partner we're rending a card for
   */
  @Input() set licensingPartnerId(value: string) {
    if (typeof value !== 'string') {
      throw new Error(
        'LicensingPartnerStripeConnectInviteButton required string param licensingPartnerId; got: ' +
          value
      );
    }
    this._licensingPartnerId = value;
    this.loadLicensingPartner();
  }

  get licensingPartnerId() {
    return this._licensingPartnerId;
  }

  /**
   * loading
   * @type {boolean} true if this component is waiting on data from an async request
   */
  loading: boolean = false;

  /**
   * invite
   *
   * object
   *
   * This is the main data model object for the invite form. Stripe requires a few parameters
   *  that will be stored on here.
   *
   * @type {{}}
   */
  invite: any = {
    stripeCountryCode: null,
  };
  licensingPartner: any;

  constructor(
    private licensingPartnerService: LicensingPartnerService,
    private errorHandler: ErrorHandlerService,
    private successHandler: SuccessService
  ) {}

  /**
   * load()
   *
   * function: modifies internal component state
   *
   * validate licensing partner id then load the licensing partner information used in the card
   * separate from $onInit because we call this function again if the $watched smallgroupid changes
   *
   * called by watch on $ctrl.licensingPartnerId
   */
  loadLicensingPartner(): void {
    if (typeof this.licensingPartnerId !== 'string') {
      throw new Error('load() required string param licensingPartnerId');
    }

    this.loading = true;

    this.licensingPartnerService
      .get(this.licensingPartnerId)
      .pipe(
        tap((response) => {
          this.licensingPartner = response;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe(() => {
        this.loading = false;
      });
  }

  /**
   * sendStripeConnectAccountInvite()
   *
   * function
   *
   * should only be called if the licensing partner does not yet have a stripe connect account
   *  otherwise, the result will be a 422 error
   */
  sendStripeConnectAccountInvite(): void {
    this.loading = true;

    this.licensingPartnerService
      .sendStripeConnectAccountInvite(this.licensingPartnerId, this.invite)
      .pipe(
        tap(() => {
          this.loadLicensingPartner();
          this.successHandler.handle({
            message: 'Successfully sent Stripe Connect invite.',
          });
        }),
        catchError((error) => {
          this.loadLicensingPartner();
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * licensingPartnerIsInvited()
   *
   * function; depends on internal state
   *
   * returns true if the licensing partner in the controller has already been invited to Stripe Connect
   *  true if we have an account id on file
   */
  licensingPartnerIsInvited(): boolean {
    return (
      this.licensingPartner &&
      this.licensingPartner.stripe &&
      this.licensingPartner.stripe.stripeConnectAccountId
    );
  }
}
