<!-- header/title -->
<div class="m-b-md text-center" *ngIf="onboardingExperience?.identifier !== 'ecourse'">
  <h3 id="nexleader-test-pre-ipsat-checklist">
    Your Pre-IPSAT Assessment Checklist
  </h3>
  <p>
    Once you've completed the checklist, you'll be able to take the IPSAT. This
    checklist saves as you go, allowing you to logout and log back in to finish
    at your convenience.
  </p>
</div>

<div class="m-b-md text-center" *ngIf="onboardingExperience?.identifier === 'ecourse'">
  <h3 id="nexleader-test-pre-ipsat-checklist">
    Your pre-IPSAT e-course checklist
  </h3>
  <p>
    You will complete a Pre-IPSAT assessment/resource after Modules 2-6 and the
    IPSAT after Module 7. All your pre-IPSAT results are saved after you enter
    them so you can logout and back into the e-course to pick up where you left
    off based on your schedule.
    <br />
    <b>You will have evergreen access to all 8 Modules on your ecourse dashboard
      after you complete Module 7.</b>
  </p>
</div>

<!-- The additional profile/personal info module item displayed to all users -->
<div class="hpanel nexleader-onboarding-preassessments-selector-hpanel" style="width: 100%"
  (click)="selectPersonalInfo()" *ngIf="onboardingExperience?.identifier !== 'ecourse'">
  <div class="panel-body">
    <div class="flex flex-vertical-center">
      <img src="/assets/images/icons/profile.svg" class="nexleader-onboarding-preassessments-icon" />
      <p class="nexleader-onboarding-preassessments-selector-title m-l-sm">
        <b class="text-primary-2">Profile</b><br />Tell us about yourself
      </p>
      <div class="flex-grow"></div>
      <span class="fa nexleader-onboarding-preassessments-selector-icon fa-check-circle"></span>
    </div>
  </div>
</div>

<!-- The additional IPSAT module item displayed only to ecourse users -->
<div
  class="hpanel nexleader-onboarding-preassessments-selector-hpanel nexleader-onboarding-preassessments-ecourse-panel"
  *ngIf="onboardingExperience?.identifier === 'ecourse'">
  <div class="panel-body">
    <div class="flex flex-vertical-center">
      <img src="/assets/images/icons/module1.svg" class="nexleader-onboarding-preassessments-icon" />
      <p class="nexleader-onboarding-preassessments-selector-title m-l-sm">
        <b class="text-primary-2">Module 1</b><br />The Art of Self-Leadership
        and the Power of Self-Awareness
      </p>
      <div class="flex-grow"></div>
      <span class="fa nexleader-onboarding-preassessments-selector-icon fa-check-circle"></span>
    </div>
  </div>
</div>

<!-- The preassessment sidebar module items shown to all users -->
<div class="hpanel nexleader-onboarding-preassessments-selector-hpanel" style="width: 100%"
  *ngFor="let prerequisite of getPrerequisites()" [ngClass]="{
    'nexleader-onboarding-preassessments-selector-hpanel-active':
      isPrereqSelected(prerequisite),
    'nexleader-onboarding-preassessments-selector-hpanel-disabled':
      !canSelectPrereq(prerequisite),
    'nexleader-onboarding-preassessments-ecourse-panel':
      onboardingExperience?.identifier === 'ecourse'
  }" (click)="selectPrereq(prerequisite)">
  <div class="panel-body text-left">
    <div class="flex flex-vertical-center">
      <img [src]="getIconPathForPrereq(prerequisite)" class="nexleader-onboarding-preassessments-icon" />
      <p class="nexleader-onboarding-preassessments-selector-title m-l-sm">
        <b class="text-primary-2">{{ getNameForPrereq(prerequisite)!.name }}</b><br />{{
        getNameForPrereq(prerequisite)!.desc }}
      </p>
      <div class="flex-grow"></div>
      <span class="nexleader-onboarding-preassessments-selector-icon fa" [ngClass]="{
          'fa-check-circle': isPrereqComplete(prerequisite),
          'fa-circle-o': !isPrereqComplete(prerequisite)
        }"></span>
    </div>
  </div>
</div>

<!-- The additional IPSAT module item displayed only to small group users -->
<div class="hpanel nexleader-onboarding-preassessments-selector-hpanel" style="width: 100%" *ngIf="isUserInSmallGroup()"
  [ngClass]="{
    'nexleader-onboarding-preassessments-selector-hpanel-active':
      isPrereqSelected(undefined),
    'nexleader-onboarding-preassessments-selector-hpanel-disabled':
      !canSelectIpsat()
  }">
  <div class="panel-body">
    <div class="flex flex-vertical-center">
      <img src="/assets/images/icons/ipsat.svg" class="nexleader-onboarding-preassessments-icon" />
      <p class="nexleader-onboarding-preassessments-selector-title m-l-sm">
        <b class="text-primary-2">Module 6</b><br />The IPSAT
      </p>
      <div class="flex-grow"></div>
      <span class="fa nexleader-onboarding-preassessments-selector-icon fa-circle-o"></span>
    </div>
  </div>
</div>

<!-- The additional IPSAT module item displayed only to ecourse users -->
<div *ngIf="onboardingExperience?.identifier === 'ecourse'">
  <div
    class="hpanel nexleader-onboarding-preassessments-selector-hpanel nexleader-onboarding-preassessments-ecourse-panel"
    style="width: 100%">
    <div class="panel-body">
      <div class="flex flex-vertical-center">
        <img src="/assets/images/icons/module7.svg" class="nexleader-onboarding-preassessments-icon" />
        <p class="nexleader-onboarding-preassessments-selector-title m-l-sm">
          <b class="text-primary-2">Module 7</b><br />Discovering your Identity
          Profile with IPSAT
        </p>
        <div class="flex-grow"></div>
        <span class="fa nexleader-onboarding-preassessments-selector-icon fa-circle-o"></span>
      </div>
    </div>
  </div>

  <div
    class="hpanel nexleader-onboarding-preassessments-selector-hpanel nexleader-onboarding-preassessments-ecourse-panel">
    <div class="panel-body">
      <div class="flex flex-vertical-center">
        <img src="/assets/images/icons/module8.svg" class="nexleader-onboarding-preassessments-icon" />
        <p class="nexleader-onboarding-preassessments-selector-title m-l-sm">
          <b class="text-primary-2">Module 8</b><br />Using Self-Awareness and
          Self-Leadership to Bring Your Best to School, Work, and Life
        </p>
        <div class="flex-grow"></div>
        <span class="fa nexleader-onboarding-preassessments-selector-icon fa-circle-o"></span>
      </div>
    </div>
  </div>
</div>