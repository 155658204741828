/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/**
 * Created by jakebillings on 7/31/16.
 */
/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CoachUserService {
  constructor(private http: HttpClient) { }

  getCoachUser(coach_user_id: string): Observable<any> {
    return this.http.get(`${API_BASE}coachUsers/${coach_user_id}`, {
      withCredentials: true,
    });
  }

  getAllCoachUser(): Observable<any> {
    return this.http.get(`${API_BASE}coachUsers`, {
      withCredentials: true,
    });
  }

  queryMyCoachAccounts(): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}coachUsers/myCoachAccounts`, {
      withCredentials: true,
    });
  }

  queryMyGroupAdminAccounts(): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}coachUsers/myGroupAdminAccounts`, {
      withCredentials: true,
    });
  }

  upgradeMyCoachAccount(coachUser: any): Observable<any> {
    return this.http.post(
      `${API_BASE}coachUsers/upgradeMyCoachAccount`,
      coachUser,
      { withCredentials: true, responseType: 'text', }
    );
  }

  addToGroup(coach_user_id: string, group_id: string): Observable<any> {
    return this.http.post(
      `${API_BASE}coachUsers/${coach_user_id}/addToGroup`,
      {
        group_id,
      },
      { withCredentials: true }
    );
  }

  getNPS(coach_user_id: string): Observable<any> {
    return this.http.get(`${API_BASE}coachUsers/${coach_user_id}/nps`, {
      withCredentials: true,
    });
  }

  queryLicensingPartner(licensing_partner_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}licensingPartners/${licensing_partner_id}/coachUsers`
    );
  }
}
