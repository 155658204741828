<!-- Copyright (C) nexleader - All Rights Reserved -->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Create a New Direct Discount Code</h3>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12 m-b-md">
      <p>
        Customers will be able to use this discount code to purchase the IPSAT
        for a cheaper price. This discount will only apply to customers who
        select a version of the IPSAT that matches these settings exactly.
      </p>
    </div>
    <div class="form-group col-md-12">
      <label class="control-label">Discount Code</label>
      <input
        type="text"
        placeholder="IPSAT2017"
        class="form-control"
        [(ngModel)]="directDiscount.name"
      />
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Discount Product</label>
      <app-nexleader-input-enum
        [enumName]="'DirectDiscountProducts'"
        [model]="directDiscount.directDiscountProduct"
        (realOnChange)="directDiscount.directDiscountProduct = $event"
      ></app-nexleader-input-enum>
      <p class="m-t-sm" *ngIf="directDiscount.directDiscountProduct">
        {{ directDiscount.directDiscountProduct.description }}
      </p>
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Discount Type</label>
      <app-nexleader-input-enum
        [enumName]="'TeamReportDiscountTypes'"
        [model]="directDiscount.discountType"
        (realOnChange)="directDiscount.discountType = $event"
      ></app-nexleader-input-enum>
      <p class="m-t-sm" *ngIf="directDiscount.discountType">
        {{ directDiscount.discountType.description }}
      </p>
    </div>
    <div class="form-group col-md-6" *ngIf="directDiscount.discountType">
      <label class="control-label" *ngIf="!isFlatFeeAndDirectPurchase()"
        >Discount Amount ({{ getDiscountAmountSymbol() }})</label
      >
      <label class="control-label" *ngIf="isFlatFeeAndDirectPurchase()"
        >New Price ({{ getDiscountAmountSymbol() }})</label
      >
      <input
        type="number"
        placeholder="30"
        class="form-control"
        [(ngModel)]="discountAmountRaw"
      />
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6" *ngIf="isFlatFeeAndDirectPurchase()">
      <label class="control-label">Onboarding Experience</label>
      <app-nexleader-input-enum
        [enumName]="'OnboardingExperiences'"
        [model]="directDiscount.onboardingExperience"
        (realOnChange)="directDiscount.onboardingExperience = $event"
      ></app-nexleader-input-enum>
    </div>
    <div
      class="form-group col-md-6"
      *ngIf="
        isFlatFeeAndDirectPurchase() &&
        directDiscount.onboardingExperience &&
        directDiscount.onboardingExperience.identifier !== 'ecourse'
      "
    >
      <label class="control-label">Survey Type</label>
      <app-nexleader-input-enum
        [enumName]="'SurveyTypes'"
        [model]="directDiscount.surveyType"
        (realOnChange)="directDiscount.surveyType = $event"
      ></app-nexleader-input-enum>
    </div>
    <div
      class="col-md-12"
      *ngIf="
        isFlatFeeAndDirectPurchase() &&
        directDiscount.onboardingExperience &&
        directDiscount.onboardingExperience.identifier !== 'ecourse'
      "
    >
      <div class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="directDiscount.cliftonStrengthsAssessment"
          id="new-direct-discount-clifton-strengths-assessment"
        />
        <label
          for="new-direct-discount-clifton-strengths-assessment"
          class="control-label"
          >Includes Clifton Strengths Assessment</label
        >
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="save()">
    Create Discount
  </button>
</div>
