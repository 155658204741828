/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderSmallGroupResources
 *
 * A view that displays information about small groups and provides resources for leaders of small groups.
 * It contains information, links to PDFs, and links to videos to help small group leaders do their role well.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { EnumsService } from '../../../../services/enum.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderSmallGroupVideoModalComponent } from '../../components/modals/small-group-video-modal/small-group-video-modal.component';

@Component({
  selector: 'app-nexleader-small-group-resources',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './small-group-resources.component.html'
})
export class NexleaderSmallGroupResourcesComponent implements OnInit {
  smallGroupResources: any[] = [];
  hideNewInvite = false;

  constructor(private modalService: BsModalService, private enumsService: EnumsService) { }

  ngOnInit(): void {
    this.loadSmallGroupResources();
  }

  loadSmallGroupResources(): void {
    this.enumsService.getEnums().subscribe((data: any) => {
      this.smallGroupResources = data.AllSmallGroupResources;
    });
  }

  /**
   * viewSmallGroupVideo()
   *
   * function: modal opener
   *
   * opens the "view small group video" modal
   *
   * @param videoIdentifier the internal identifier of the small group video to use
   */
  viewSmallGroupVideo(videoIdentifier: string): void {
    if (typeof videoIdentifier !== 'string') {
      throw new Error('viewSmallGroupVideo() requires string param viewSmallGroupVideo');
    }
    const modalRef = this.modalService.show(NexleaderSmallGroupVideoModalComponent, {
      initialState: {
        videoIdentifier: videoIdentifier
      }
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSmallGroupResourcesComponent',
//   downgradeComponent({
//     component: NexleaderSmallGroupResourcesComponent,
//   }) as angular.IDirectiveFactory
// );

