<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-4">
        <div class="hpanel">
          <div class="panel-body">
            <h4 class="text-primary-2 m-b-md">Add an IPSAT U Content Piece</h4>
            <app-nexleader-course-content-input
              [courseContent]="newCourseContentInformation"
            ></app-nexleader-course-content-input>
            <div class="float-right">
              <button
                class="btn btn-success"
                [attr.disabled]="
                  !hasValidFormInput(newCourseContentInformation) ? true : null
                "
                (click)="saveCourseContent(newCourseContentInformation)"
              >
                Add Content
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div
            class="col-md-6"
            *ngFor="let courseContent of mappedCourseContent"
          >
            <app-nexleader-course-content-card
              [courseContent]="courseContent"
              [canEditCourseContent]="true"
              (handleSaveCourseContent)="saveCourseContent(courseContent)"
              (handleRemoveCourseContent)="removeCourseContent(courseContent)"
              (onUploadFile)="load()"
            ></app-nexleader-course-content-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
