<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div id="nexleader-wrapper">
  <div class="content animate-panel">
      <div class="row">
          <div class="col-lg-4">
              <div class="row">
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Total Users'"
                          [value]="stats.totalUsers"
                          [icon]="'users'"></app-nexleader-statistic>
                  </div>
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Total Groups'"
                          [value]="stats.totalGroups"
                          [icon]="'sitemap'"></app-nexleader-statistic>
                  </div>
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Total Sessions'"
                          [value]="stats.totalSessions"
                          [icon]="'calendar'"></app-nexleader-statistic>
                  </div>
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Total Charges'"
                          [value]="stats.totalCharges"
                          [icon]="'credit-card'"></app-nexleader-statistic>
                  </div>
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Global NPS'"
                          [value]="stats.globalNps || 'N/A'"
                          [icon]="'globe'"></app-nexleader-statistic>
                  </div>
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Total Coaches'"
                          [value]="stats.totalCoaches"
                          [icon]="'briefcase'"></app-nexleader-statistic>
                  </div>
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Total Organizational Coaches'"
                          [value]="stats.totalOrganizationalCoaches"
                          [icon]="'briefcase'"></app-nexleader-statistic>
                  </div>
                  <div class="col-sm-6">
                      <app-nexleader-statistic
                          [name]="'Total Independent Contractor Coaches'"
                          [value]="stats.totalIndependentContractorCoaches"
                          [icon]="'briefcase'"></app-nexleader-statistic>
                  </div>
              </div>
          </div>
          <div class="col-lg-8">
              <div class="hpanel">
                  <div class="panel-body">
                      <app-nexleader-dashboard-statistics-chart></app-nexleader-dashboard-statistics-chart>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
