/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderVimeoVideo
 *
 * angular component
 *
 * This component displays a vimeo video. It accepts and id and is responsible for making sure that video with that id
 *  displays with proper play/pause controls.
 *
 * This component is currently stored in the SmallGroup feature; if it is at some point in time re-used for another,
 *  it should be moved to the "core" feature.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-nexleader-vimeo-video',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vimeo-video.component.html',
})
export class NexleaderVimeoVideoComponent implements OnInit {
  /**
   * vimeoId
   *
   * string
   *
   * A string containing the vimeo id of the vimeo video to be displayed
   *
   * For instance, if the player is located at "https://player.vimeo.com/video/273137006", then the
   * id is "273137006"
   */
  @Input() vimeoId: string = '';

  /**
   * vimeoDownloadId
   *
   * string
   *
   * A string containing the vimeo download id of the vimeo video to be downloaded
   *
   * For instance, if the download link is located at "https://vimeo.com/273219277/13a098e255", then the
   * id is "273219277/13a098e255"
   */
  @Input() vimeoDownloadId: string = '';

  /**
   * hideDownloadLink
   *
   * boolean
   *
   * A boolean determining if we allow or disallow a user from downloading the video
   */
  @Input() hideDownloadLink: boolean = false;

  /**
   * fullWidth
   *
   * boolean
   *
   * A boolean determining if we want to stretch the video container to the full width.
   */
  @Input() fullWidth: boolean = false;

  videoUrl: SafeUrl | undefined;
  downloadUrl: string = '';

  /**
   * VIMEO_PLAYER_URL_PREFIX
   * @type {string} the prefix of the URL preprended to the video id before handing it off to the vimeo embed code
   */
  private readonly VIMEO_PLAYER_URL_PREFIX = 'https://player.vimeo.com/video/';

  /**
   * VIMEO_DOWNLOAD_URL_PREFIX
   * @type {string} the prefix the is prepended to video id before adding it to a href
   */
  private readonly VIMEO_DOWNLOAD_URL_PREFIX = 'https://vimeo.com/';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    // We need a video identifier to correct assign a video url.
    if (!this.vimeoId) {
      throw new Error(
        'nexleaderVimeoVideo: the string param vimeoId is required'
      );
    }
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.VIMEO_PLAYER_URL_PREFIX + this.vimeoId
    );
    this.downloadUrl =
      this.VIMEO_DOWNLOAD_URL_PREFIX + (this.vimeoDownloadId || this.vimeoId);
  }
}
