<div class="row">
  <div class="col-md-12">
    <app-nexleader-yes-no-buttons
      [(model)]="user.prerequisites.completedMbti"
      (onChange)="save.emit()"
    ></app-nexleader-yes-no-buttons>
  </div>

  <div class="col-md-12" *ngIf="user.prerequisites.completedMbti === false">
    <hr />
    <b>Estimated time to complete: 15-20 minutes</b>
    <p class="m-t-md m-b-md">
      Since you haven’t taken MBTI® or don't remember your results, click on the
      "Take the MBTI®" button below. The assessment will open in a new tab in
      your browser. Once you have completed the assessment, return to this page
      and click "I Completed the MBTI® Assessment."
    </p>
    <a class="btn btn-default" [href]="prerequisiteLink" target="_blank"
      >Take the MBTI Assessment</a
    >
    <br />
    <a
      class="btn btn-success btn-block m-t-md"
      (click)="user.prerequisites.completedMbti = true"
      >I Completed the MBTI® Assessment</a
    >
  </div>

  <div class="col-md-12" *ngIf="user.prerequisites.completedMbti">
    <p class="m-t-md m-b-lg">
      Great, since you've completed the Myers Briggs Type Indicator (MBTI®), use
      the pull-down menu to enter your profile. If you have your results, feel
      free to take a screenshot and use the file uploader tool to save it within
      the IPSAT. If you have trouble uploading the file or don't have files,
      just move on to the next step.
    </p>
    <div class="col-xl-6">
      <div class="form-group">
        <b>My MBTI® Profile</b>
        <select
          class="form-control m-t-sm"
          [(ngModel)]="user.prerequisites.mbti"
          [compareWith]="compareMBTI"
        >
          <option [ngValue]="user.prerequisites.mbti === null ? null : undefined">-- Select a Myers Briggs --</option>
          <option
            *ngFor="let option of mbtis; trackBy: trackByOptionIndex"
            [ngValue]="option"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-xl-6">
      <app-nexleader-profile-wizard-upload-file
        *ngIf="user && files && prerequisite"
        [user]="user"
        [prerequisite]="prerequisite"
        [allFiles]="files"
        (load)="loadFiles()"
        [isOptional]="true"
      ></app-nexleader-profile-wizard-upload-file>
    </div>
  </div>
</div>
