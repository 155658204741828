<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<p *ngIf="loading">Loading</p>
<p *ngIf="!loading && !canShowLink()">
  Sorry, a direct purchase referral link is not available for this user.
</p>
<p *ngIf="!loading && canShowLink()">
  Standard Link for <b>{{ user.firstName }} {{ user.lastName }}</b> in
  <b>{{ user.group.name }}</b>
</p>
<p *ngIf="!loading && canShowLink()">
  <a [href]="getStandardLink()">{{ getStandardLink() }}</a>
</p>
<p *ngIf="!loading && canShowLink()">
  ECourse Link for <b>{{ user.firstName }} {{ user.lastName }}</b> in
  <b>{{ user.group.name }}</b>
</p>
<p *ngIf="!loading && canShowLink()">
  <a [href]="getECourseLink()">{{ getECourseLink() }}</a>
</p>
<br />
<i
  >Please note that users who sign up and pay for the IPSAT this pay will have
  the coach and group listed above.</i
>
