<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-body">
            <h2 class="mt-0">Coaches in {{ group?.name }}</h2>
            <p>
              Welcome to the coach view. Here, you can view your coaches and
              their Net Promoter Scores.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" *ngIf="activeCoaches.length < 1">
        <div class="hpanel">
          <div class="panel-body">
            <p>No coaches to show.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngFor="let user of activeCoaches">
        <div class="hpanel contact-panel hgreen">
          <div class="panel-body">
            <h4>{{ user.firstName }} {{ user.lastName }}</h4>
            <div class="text-muted font-bold m-t-sm">
              <a [href]="'mailto:' + user.email">{{ user.email }}</a>
            </div>
            <h2 class="font-bold" *ngIf="user.evaluationResults.length > 0">
              {{ user.netPromoter?.score }}
            </h2>
            <div class="small">
              <i *ngIf="user.evaluationResults.length > 0"
                >Net-Promoter Score</i
              >
              <i *ngIf="user.evaluationResults.length === 0"
                ><br />Coach has not received enough feedback to calculate a Net
                Promoter score.</i
              >
            </div>
          </div>
          <div class="panel-footer">
            <a [routerLink]="['/coaches', user._id, 'admin']">
              <i class="fa fa-eye"></i>&nbsp;&nbsp;View coach
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-heading">Your Archived Coaches</div>
          <div class="panel-body">
            <table
              class="table archieve-coach-table"
              *ngIf="archivedCoaches.length > 0"
            >
              <thead>
                <th><i class="fa fa-user"></i>&nbsp;&nbsp;Name</th>
                <th><i class="fa fa-dashboard"></i>&nbsp;&nbsp;View Coach</th>
              </thead>
              <tr *ngFor="let user of archivedCoaches">
                <td>{{ user.firstName }} {{ user.lastName }}</td>
                <td>
                  <a [routerLink]="['/coaches', user._id, 'admin']">
                    <i class="fa fa-eye"></i>&nbsp;&nbsp;View Coach
                  </a>
                </td>
              </tr>
            </table>
            <p *ngIf="archivedCoaches.length < 1">
              No archived groups to show.
            </p>
            <p class="m-t-md">
              Archived coaches cannot be assigned new participants and cannot
              schedule new sessions. Coaches in multiple groups archive
              themselves from a group when they complete their work.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
