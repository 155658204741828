/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderCoaches
 *
 * angular component (view)
 *
 * Shows all coaches, both those that are currently active and those that have been archived for a selected group.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { GroupService } from '../../../../services/group.service';
import { NetPromoterService } from '../../../../services/net-promoter.service';
import { EvaluationResultService } from '../../../../services/evaluation-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-coaches',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './coaches.component.html',
})
export class NexleaderCoachesComponent implements OnInit {
  routeParams: any;
  group: any;
  unfilteredCoaches: any[] = [];
  activeCoaches: any[] = [];
  archivedCoaches: any[] = [];
  evaluationResults: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private groupService: GroupService,
    private netPromoterService: NetPromoterService,
    private evaluationResultService: EvaluationResultService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    const group_id = this.routeParams['group_id'];

    combineLatest([
      this.groupService.get(group_id),
      this.userService.queryGroup(group_id),
      this.evaluationResultService.queryGroup(group_id),
    ])
      .pipe(
        tap(([group, users, evaluationResults]) => {
          this.group = group;
          this.evaluationResults = evaluationResults;

          this.unfilteredCoaches = users.filter(
            (user) => user.roles.indexOf('coach') >= 0
          );
          this.activeCoaches = this.unfilteredCoaches.filter(
            (user) => !user.archivedByCoachUser
          );
          this.archivedCoaches = this.unfilteredCoaches.filter(
            (user) => user.archivedByCoachUser
          );

          this.activeCoaches.forEach((coach) => {
            coach.evaluationResults = this.evaluationResults.filter(
              (result) => coach._id === result.coach?._id
            );

            this.netPromoterService
              .calculateNetPromoterScore(coach.evaluationResults)
              .then((np) => {
                coach.netPromoter = np;
              });
          });
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCoachesComponent',
//   downgradeComponent({
//     component: NexleaderCoachesComponent,
//   }) as angular.IDirectiveFactory
// );
