<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="row">
  <div class="{{ columnClass }}" *ngFor="let blob of files">
    <div
      class="hpanel"
      [ngClass]="panelClassForFilePrerequisite(blob.file.metadata.prerequisite)"
    >
      <a [href]="blob.path">
        <div class="panel-body file-body">
          <i
            class="fa {{ iconForContentType(blob.file.contentType) }} m-b-xs"
          ></i>
          <br />
          <span>{{ blob.file.filename }}</span>
        </div>
      </a>
      <div class="panel-footer">
        <a [href]="blob.path">{{
          blob.file.metadata.prerequisite.description
        }}</a>
        <a
          (click)="deleteFile(blob)"
          *ngIf="editable"
          class="pull-right text-danger"
        >
          <i class="fa fa-trash"></i>
        </a>
      </div>
    </div>
  </div>
</div>
