<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-4" *ngFor="let user of filteredUsers()">
        <div
          class="hpanel contact-panel"
          [ngClass]="{ hgreen: isUserPrimary(user) }"
        >
          <div class="panel-body">
            <h4>{{ user.group.name }}</h4>
            <div class="text-muted font-bold m-t-sm">
              <span *ngIf="isUserPrimary(user)">Your </span
              ><span>{{ user.group.tier.name }} Group</span>
            </div>
          </div>
          <div class="panel-footer">
            <a [routerLink]="['/coaches', user._id, 'admin']"
              ><i class="fa fa-eye"></i>&nbsp;&nbsp;View Dashboard for Group</a
            >
            <a (click)="archive(user)" class="pull-right text-red"
              ><i class="fa fa-archive"></i>&nbsp;&nbsp;Archive this Group</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-heading">Your Archived Groups</div>
          <div class="panel-body">
            <table
              class="table archived-groups-table"
              *ngIf="archivedUsers().length > 0"
            >
              <thead>
                <th><i class="fa fa-user"></i>&nbsp;&nbsp;Name</th>
                <th><i class="fa fa-users"></i>&nbsp;&nbsp;Type</th>
                <th><i class="fa fa-undo"></i>&nbsp;&nbsp;Undo</th>
                <th><i class="fa fa-dashboard"></i>&nbsp;&nbsp;Dashboard</th>
              </thead>
              <tr *ngFor="let user of archivedUsers()">
                <td>{{ user.group.name }}</td>
                <td>{{ user.group.tier.name }}</td>
                <td>
                  <a (click)="unarchive(user)">
                    <i class="fa fa-undo"></i>&nbsp;&nbsp;Unarchive
                  </a>
                </td>
                <td>
                  <a [routerLink]="['/coaches', user._id, 'admin']"
                    ><i class="fa fa-eye"></i>&nbsp;&nbsp;View Dashboard</a
                  >
                </td>
              </tr>
            </table>
            <p *ngIf="archivedUsers().length < 1">
              No archived groups to show.
            </p>
            <p class="m-t-md">
              Archived groups are hidden from your coaching calendar. Group
              admins are not allowed to assign new participants for you to coach
              in archived groups.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
