<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<app-nexleader-help-utility></app-nexleader-help-utility>
<app-nexleader-onboarding-video
  [class.hidden]="!shouldShowVideo()"
  (boundNext)="load()"
></app-nexleader-onboarding-video>
<div class="container p-v-50" *ngIf="!shouldShowVideo()">
  <app-nexleader-if-no-stripe>
    <div class="row">
      <div class="col-md-10 col-md-offset-1 col-xs-12">
        <div class="hpanel warning">
          <div class="color-line color-line-warning"></div>
          <div class="panel-body">
            <h3 class="mt-">Unable to Contact Stripe</h3>
            <p>
              Your computer is not able to talk to our payment provider, Stripe.
              Please try disabling ad blockers or other browser extensions that
              interfere with network traffic. Stripe allows us to securely
              process your order, and you cannot complete your purchase without
              Stripe.
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-nexleader-if-no-stripe>
  <div class="row">
    <div class="col-sm-8 col-sm-offset-2 col-xs-12">
      <div class="text-center m-b-lg">
        <h1>IPSAT Checkout</h1>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <p class="m-b-sm">
            <span class="font-bold">IPSAT Version:</span>
            {{ getSurveyTypeName() }}
          </p>
          <p class="font-bold m-b-xs">Extras</p>
          <p *ngIf="pricingConfig">
            <label for="nexleader-direct-clifton-strengths-check">
              <input
                type="checkbox"
                id="nexleader-direct-clifton-strengths-check"
                [(ngModel)]="pricingConfig.cliftonStrengthsAssessment"
                (change)="handlePricingConfigChange()"
              />
              Include Clifton Strengths Assessment
            </label>
          </p>
          <p class="font-bold m-b-xs">Items</p>
          <ul class="m-b-sm" *ngIf="pricing">
            <li
              *ngFor="let lineItem of pricing.lineItems"
              [ngStyle]="{
                'font-style':
                  lineItem.price.currencyQuantity < 0 ? 'italic' : 'normal'
              }"
            >
              {{ lineItem.name }} (
              <app-nexleader-currency
                [currencyCode]="lineItem.price.currencyCode"
                [currencyQuantity]="lineItem.price.currencyQuantity"
              >
              </app-nexleader-currency>
              )
            </li>
          </ul>
          <hr />
          <!-- Discount code interface -->
          <div *ngIf="!pricingConfig?.discountCodeName">
            <div class="form-group">
              <label class="control-label">Discount Code</label>
              <input
                class="form-control"
                type="text"
                id="nexleader-e2e-direct-purchase-candidate-discount-code-name"
                [(ngModel)]="candidateDiscountCodeName"
              />
            </div>
            <button
              class="btn btn-default"
              id="nexleader-e2e-direct-purchase-candidate-discount-code-verify"
              [attr.disabled]="!candidateDiscountCodeName ? true : null"
              (click)="validateCandidateDiscountCode()"
            >
              Validate
            </button>
          </div>
          <div *ngIf="pricingConfig?.discountCodeName">
            <p>
              Discount code
              <i>{{ pricingConfig.discountCodeName }}</i> successfully applied.
              <a (click)="clearDiscountName()">Undo</a>.
            </p>
          </div>
          <hr />
          <div class="flex flex-column-md">
            <div>
              <p>Your total is...</p>
            </div>
            <div class="flex-grow"></div>
            <h4
              class="font-bold m-t-none m-b-none"
              id="nexleader-test-team-report-price"
            >
              <app-nexleader-currency
                *ngIf="pricing"
                [currencyQuantity]="pricing.total.currencyQuantity"
                [currencyCode]="pricing.total.currencyCode"
              >
              </app-nexleader-currency>
              <span *ngIf="!pricing">Loading Price...</span>
            </h4>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button
      class="btn btn-lg btn-success"
      (click)="checkout()"
      [attr.disabled]="!isReadyToAdvance() ? true : null"
      id="nexleader-e2e-direct-purchase-checkout"
    >
      <i class="fa fa-shopping-cart"></i>
      Checkout
    </button>
  </div>
</div>
