/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderGroupSmallGroupsView
 *
 * component
 *
 * like nexleader-invite component
 * displays a table of smallgroups that exist in a given group
 *
 * $routeParams
 *  - group_id
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupService } from '../../../../services/group.service';
import { SmallGroupService } from '../../resources/small-group.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderQuickViewComponent } from '../../../core/components/quick-view/quick-view.component';
import { NexleaderQuickViewNotSelectedComponent } from '../../../core/components/quick-view-not-selected/quick-view-not-selected.component';
import { NexleaderSmallGroupCardComponent } from '../../components/small-group-card/small-group-card.component';
import { catchError, of, tap } from 'rxjs';
import { NexleaderNewSmallGroupModalComponent } from '../../components/modals/new-small-group-modal/new-small-group-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-group-small-groups-view',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderQuickViewComponent,
    NexleaderQuickViewNotSelectedComponent,
    NexleaderSmallGroupCardComponent,
  ],
  templateUrl: './group-small-groups-view.component.html',
})
export class NexleaderGroupSmallGroupsViewComponent implements OnInit {
  routeParams: any;
  group: any;
  smallGroups: any[] = [];
  selectedGroup: any;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private smallGroupService: SmallGroupService,
    private modalService: BsModalService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  /**
   * $onInit()
   *
   * function
   *
   * called by angular when bindings are done
   *  validate bindings then load data
   */
  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  /**
   * load()
   *
   * function
   *
   * loads data for the view
   *  called by $onInit
   *  called when the NewSmallGroupModal closes (and saves)
   */
  load(): void {
    const groupId = this.routeParams['group_id'];

    if (!groupId) {
      throw new Error(
        'nexleaderGroupSmallGroupsView requires a group_id route parameter'
      );
    }

    this.groupService
      .getLite(groupId)
      .pipe(
        tap((group) => {
          this.group = group;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    //Query the index version to improve performance of this list view;
    // don't change this to queryGroup if you want more information in the righthand side
    // of the quick view; use the _id to query the smallGroup yourself
    this.smallGroupService
      .queryGroupIndex(groupId)
      .pipe(
        tap((smallGroups) => {
          this.smallGroups = smallGroups.map((smallGroup) => {
            return {
              ...smallGroup,
              _smallGroupLeaderName:
                smallGroup.smallGroupLeader.firstName +
                ' ' +
                smallGroup.smallGroupLeader.lastName,
            };
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * openNewSmallGroupModal()
   *
   * function
   *
   * opens the new small group modal component using uib modal
   */
  openNewSmallGroupModal(): void {
    const modalRef = this.modalService.show(
      NexleaderNewSmallGroupModalComponent,
      {
        initialState: { groupId: this.routeParams['group_id'] },
      }
    );

    modalRef.content?.onSave.subscribe(() => {
      modalRef.hide();
      this.successService.handle();
      this.load();
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupSmallGroupsViewComponent',
//   downgradeComponent({
//     component: NexleaderGroupSmallGroupsViewComponent,
//   }) as angular.IDirectiveFactory
// );
