/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { CoachUserService } from './../../../../services/coach-user.service';
import { AuthService } from './../../../../services/auth.service';
import { EnumsService } from './../../../../services/enum.service';
import { EmailService } from './../../../../services/email.service';
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { UserService } from './../../../../services/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderEditUserModalComponent } from '../modals/edit-user-modal/edit-user-modal.component';
import { SuccessService } from '../../../../services/success.service';
import { FormsModule } from '@angular/forms';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { TelephonePipe } from '../../../../pipes/telephone.pipe';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';
import { CapitalizePipe } from '../../../../pipes/capitalize.pipe';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { indexOfPropertyValue } from './../../../../utils/array-utils';

@Component({
  selector: 'app-nexleader-participants-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgModelDebounceChangeDirective,
    FilterPipe,
    TelephonePipe,
    OrderByPipe,
    CapitalizePipe,
    RouterLink
  ],
  templateUrl: './participants-table.component.html',
})
export class NexleaderParticipantsTableComponent implements OnInit {
  @Input() isCoach: boolean = false;
  @Input() disableCoachEdit!: string;
  @Input() isMultipleGroup: boolean = false;
  indexOfPropertyValue = indexOfPropertyValue;

  routeParams: any;
  currentUser: any;
  limit = 10;
  searchText = '';
  filter = 'lastName';
  users: any[] = [];
  coaches: any[] = [];
  enums: any;
  showFrozen = false;
  showLimitWarning = false;
  searchReloadNeeded = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private userService: UserService,
    private emailService: EmailService,
    private coachUserService: CoachUserService,
    private authService: AuthService,
    private enumsService: EnumsService,
    private errorHandlerService: ErrorHandlerService,
    private successHandlerService: SuccessService
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    let userObservable: Observable<any>;

    if (this.routeParams['group_id']) {
      userObservable = this.userService
        .queryGroup(this.routeParams['group_id'])
        .pipe(
          tap((a) => {
            this.users = a;
            this.coaches = this.users.filter(
              (b) =>
                !b.archivedByCoachUser &&
                indexOfPropertyValue(b.roles, 'name', 'Coach') >= 0
            );
          })
        );
    } else if (this.routeParams['licensing_partner_id']) {
      userObservable = this.userService
        .queryLicensingPartner(this.routeParams['licensing_partner_id'])
        .pipe(
          tap((a) => {
            this.users = a;
            this.coaches = this.users.filter(
              (b) =>
                !b.archivedByCoachUser &&
                indexOfPropertyValue(b.roles, 'name', 'Coach') >= 0
            );
          })
        );
    } else if (this.routeParams['user_id'] && this.isCoach) {
      userObservable = this.userService
        .queryParticipants(this.routeParams['user_id'])
        .pipe(
          tap((a) => {
            this.users = a;
            this.coaches = this.users.filter(
              (b) =>
                !b.archivedByCoachUser &&
                indexOfPropertyValue(b.roles, 'name', 'Coach') >= 0
            );
          })
        );
    } else if (this.routeParams['user_id']) {
      userObservable = this.userService
        .queryParticipants(this.routeParams['user_id'])
        .pipe(
          tap((a) => {
            this.users = a;
            this.coaches = this.users.filter(
              (b) =>
                !b.archivedByCoachUser &&
                indexOfPropertyValue(b.roles, 'name', 'Coach') >= 0
            );
          })
        );
    } else if (this.routeParams['coach_user_id']) {
      userObservable = this.userService
        .queryCoachUserParticipants(this.routeParams['coach_user_id'])
        .pipe(
          tap((a) => {
            this.users = a;
            console.log(this.users)
            this.coaches = this.users.filter(
              (b) =>
                !b.archivedByCoachUser &&
                indexOfPropertyValue(b.roles, 'name', 'Coach') >= 0
            );
          })
        );
      this.isMultipleGroup = true;
    } else {
      userObservable = this.userService.queryIndex({
        search: this.searchText,
        limit: this.limit
      }).pipe(
        tap((a) => {
          this.users = a;
          this.coaches = this.users.filter(
            (b) => b.roles.indexOf('Coach') >= 0
          );
          this.showLimitWarning = this.users.length >= this.limit;
        })
      );
      this.searchReloadNeeded = true;
    }

    //If the user is logged out, cry.
    const payload = this.authService.getTokenPayload();
    if (payload) {
      if (payload.user) {
        this.userService
          .getUser(payload.user)
          .pipe(
            tap((user) => {
              if (indexOfPropertyValue(user.roles, '_id', 'admin') >= 0) {
                this.showFrozen = true;
              }
            })
          )
          .subscribe();
      } else if (payload.coach_user) {
        this.coachUserService
          .getCoachUser(payload.coach_user)
          .pipe(tap(() => { }))
          .subscribe();
      } else {
        console.warn(
          'Malformed payload; user _id or coach_user _id required',
          payload
        );
      }
    } else {
      console.warn('Must be logged in to view a participant table.');
      this.router.navigate(['/']); // Navigate to home or login page
    }

    forkJoin([this.enumsService.getEnums(), userObservable])
      .pipe(
        tap(([enums, users]) => {
          this.enums = enums;

          this.users = users.map((user: any) => {
            user.fullName = `${user.firstName} ${user.lastName}`;
            return user;
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  trackById(index: number, item: any) {
    return item._id;
  }

  reloadForSearch() {
    if (this.searchReloadNeeded) {
      this.load();
    }
  }

  open(id: string) {
    const modalRef = this.modalService.show(NexleaderEditUserModalComponent, {
      initialState: {
        id: id,
      },
    });

    modalRef.content?.close.subscribe(() => {
      modalRef.hide();
      this.load();
      this.successHandlerService.handle();
    });
  }

  saveUser(user: any) {
    this.userService
      .saveGroup(user._id, user.group._id, user)
      .pipe(
        tap(() => {
          this.successHandlerService.handle();
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  sendEvaluationEmail(user: any) {
    this.userService
      .sendCoachEvaluationEmail(user._id)
      .pipe(
        tap(() => {
          this.successHandlerService.handle({ message: 'Email sent' });
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  sendEmail(user: any, emailType: string) {
    this.emailService
      .sendEmail({ user: user._id, group: user.group._id, emailType, view: {} })
      .pipe(
        tap(() => {
          this.successHandlerService.handle({ message: 'Email Sent.' });
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  deleteUser(user: any) {
    if (user.completedIpsat) {
      this.userService
        .moveToLegacy(user._id)
        .pipe(
          tap(() => {
            this.load();
          })
        )
        .subscribe();
    } else {
      this.userService
        .delete(user._id)
        .pipe(
          tap(() => {
            this.load();
          })
        )
        .subscribe();
    }
    this.load();
  }

  filterAlphabetically() {
    this.filter = 'lastName';
  }

  filterByCreation() {
    this.filter = '';
  }
}
