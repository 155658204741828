<div>
  <p class="m-t-md m-b-md">
    <b>Estimated time to complete: 20 minutes</b>
  </p>
  <p class="m-b-md">
    Clicking the "Take your Personal Values Inventory" button below will open a
    new tab in your browser with the Personal Values Inventory.
  </p>
  <a class="btn btn-default" [routerLink]="'/takePVI'"
    >Take the Personal Values Inventory</a
  >
  <br />
  <p class="m-t-md m-b-lg">
    Upon completing the PVI, your top three values will automatically be
    populated in the list below. Your Personal Values Inventory Report will be
    available on your IPSAT Dashboard after you complete the onboarding.
  </p>
  <div *ngIf="pviResults.length > 0">
    <hr />
    <div class="pull-right">
      <a
        [routerLink]="['/users', user._id, 'pvi', pviResultId, 'fullscreen']"
        target="_blank"
        >View Your Personal Values Inventory Result</a
      >
    </div>
    <p class="m-b-xs">
      <b>Your Top Three Personal Values</b>
    </p>
    <ul>
      <li *ngFor="let value of topThreeValues">
        {{ value.name }}
      </li>
    </ul>
  </div>
</div>
