<app-nexleader-onboarding-module-intro
  *ngIf="shouldShowSmallGroupModuleScreen()"
  [moduleIdentifier]="getCurrentSmallGroupModule()?.identifier"
  (completionCallback)="completeModuleAndAdvance()"
>
</app-nexleader-onboarding-module-intro>

<div
  *ngIf="!shouldShowSmallGroupModuleScreen()"
  class="nexleader-onboarding-h100"
>
  <div class="show-mobile">
    <div class="hpanel m-b-none">
      <div class="panel-body">
        <div *ngFor="let prerequisite of Prerequisites | keyvalue">
          <ng-container
            *ngIf="selectedPrerequisite.index === prerequisite.value.index"
          >
            <div class="row m-t-md">
              <div class="col-xs-2">
                <div
                  class="nexleader-onboarding-arrow"
                  (click)="selectPreviousPrereq()"
                  *ngIf="
                    prerequisite.value.index !== Prerequisites['MBTI'].index
                  "
                >
                  <i class="fa fa-chevron-left"></i>
                </div>
              </div>
              <div class="col-xs-8 text-center">
                <img
                  [src]="getIconPathForPrereq(prerequisite.value)"
                  class="img-md text-center m-b-sm"
                />
                <h5
                  [ngClass]="{
                    'text-success': isPrereqComplete(prerequisite.value)
                  }"
                >
                  {{ prerequisite.value.name }}:
                  {{ prerequisite.value.description }}
                  <i
                    *ngIf="isPrereqComplete(prerequisite.value)"
                    class="fa fa-check"
                  ></i>
                </h5>
              </div>
              <div class="col-xs-2">
                <div
                  class="nexleader-onboarding-arrow"
                  (click)="selectNextPrereq()"
                  *ngIf="
                    prerequisite.value.index !== Prerequisites['PASSIONS'].index
                  "
                >
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="nexleader-onboarding-h100 container-fluid" *ngIf="user">
    <div
      class="row nexleader-onboarding-h100 nexleader-onboarding-overlow-x-hidden"
    >
      <div
        class="col-md-6 bg-white nexleader-onboarding-padded-container nexleader-onboarding-half-layout-side"
      >
        <div class="nexleader-onboarding-preassessments-padding">
          <div class="text-center">
            <img
              [src]="getIconPathForPrereq(selectedPrerequisite)"
              style="width: 70px"
              [alt]="selectedPrerequisite.name"
            />
            <div class="text-center">
              <h3
                class="m-t-md"
                *ngIf="
                  selectedPrerequisite.index !==
                  Prerequisites['SPIRITUAL_GIFTS'].index
                "
              >
                <!-- getSelectedPrerequisiteDescription() replaces "Leadership Skills Inventory" with "Skills Inventory" (see function docs) -->
                {{ selectedPrerequisite.name }}:
                {{ getSelectedPrerequisiteDescription() }}
              </h3>
              <h3
                class="m-t-md"
                *ngIf="
                  selectedPrerequisite.index ===
                  Prerequisites['SPIRITUAL_GIFTS'].index
                "
              >
                <span *ngIf="group.surveyType.identifier === 'faith_based'"
                  >Spiritual Gifts: Spiritual Gifts Assessment</span
                >
                <span *ngIf="group.surveyType.identifier === 'values_based'"
                  >Personal Values: Personal Values Inventory</span
                >
              </h3>
              <p class="m-t-md">
                <span
                  *ngIf="
                    selectedPrerequisite.index === Prerequisites['MBTI'].index
                  "
                  >Your first pre-assessment is the Myers Briggs Type Indicator
                  (MBTI®).</span
                >
                <span
                  *ngIf="
                    selectedPrerequisite.index ===
                    Prerequisites['STRENGTHS'].index
                  "
                  >Your second pre-assessment is the Clifton Strengths
                  Assessment from Gallup.</span
                >
                <span
                  *ngIf="
                    selectedPrerequisite.index === Prerequisites['SKILLS'].index
                  "
                  >Your third pre-assessment is the Skills Inventory.</span
                >
                <span
                  *ngIf="
                    selectedPrerequisite.index ===
                      Prerequisites['SPIRITUAL_GIFTS'].index &&
                    group.surveyType.identifier === 'faith_based'
                  "
                  >Your fourth pre-assessment is a spiritual gifts test. There's
                  no prescribed assessment for spiritual gifts — we leave the
                  choice up to you and your organization. Have you taken a
                  spiritual gifts assessment before and do you remember your
                  results?</span
                >
                <span
                  *ngIf="
                    selectedPrerequisite.index ===
                      Prerequisites['SPIRITUAL_GIFTS'].index &&
                    group.surveyType.identifier === 'values_based'
                  "
                  >Your fourth pre-assessment is the Personal Values
                  Inventory.</span
                >
                <span
                  *ngIf="
                    selectedPrerequisite.index ===
                    Prerequisites['PASSIONS'].index
                  "
                  >Your final pre-assessment is the MyPassionProfile.</span
                >
                <span
                  *ngIf="
                    selectedPrerequisite.index !==
                      Prerequisites['SKILLS'].index &&
                    selectedPrerequisite.index !==
                      Prerequisites['SPIRITUAL_GIFTS'].index
                  "
                >
                  Have you taken this assessment before and do you remember your
                  results?</span
                >
              </p>
            </div>
          </div>

          <!-- Personality -->
          <app-nexleader-onboarding-personality
            *ngIf="selectedPrerequisite.index === Prerequisites['MBTI'].index"
            (save)="save()"
            [user]="user"
          >
          </app-nexleader-onboarding-personality>

          <!-- Strengths -->
          <app-nexleader-onboarding-strengths
            *ngIf="
              selectedPrerequisite.index === Prerequisites['STRENGTHS'].index
            "
            (save)="save()"
            [user]="user"
          >
          </app-nexleader-onboarding-strengths>

          <!-- Skills -->
          <app-nexleader-onboarding-skills
            *ngIf="selectedPrerequisite.index === Prerequisites['SKILLS'].index"
            (save)="save()"
            [user]="user"
            [scoredSkillsInventory]="scoredSkillsInventory"
          >
          </app-nexleader-onboarding-skills>

          <!-- Spiritual Gifts/Values based IPSAT -->
          <app-nexleader-onboarding-spiritual-gifts
            *ngIf="
              selectedPrerequisite.index ===
                Prerequisites['SPIRITUAL_GIFTS'].index &&
              group.surveyType.identifier === 'faith_based'
            "
            (save)="save()"
            [user]="user"
          >
          </app-nexleader-onboarding-spiritual-gifts>

          <app-nexleader-onboarding-personal-values-inventory
            *ngIf="
              selectedPrerequisite.index ===
                Prerequisites['SPIRITUAL_GIFTS'].index &&
              group.surveyType.identifier === 'values_based'
            "
            (save)="save()"
            [user]="user"
          >
          </app-nexleader-onboarding-personal-values-inventory>

          <!-- Passions -->
          <app-nexleader-onboarding-passions
            *ngIf="
              selectedPrerequisite.index === Prerequisites['PASSIONS'].index
            "
            (save)="save()"
            [user]="user"
          >
          </app-nexleader-onboarding-passions>

          <!-- Next Button -->
          <div class="form-group m-t-xl" *ngIf="shouldShowNextPrereqButton()">
            <button
              class="btn btn-success nexleader-onboarding-full-button"
              id="nexleader-test-onboarding-next-btn"
              (click)="onNextPrereqButtonClicked()"
            >
              <span *ngIf="!shouldDisplayTakeIpsatInsteadOfNext()">Next</span>
              <span *ngIf="shouldDisplayTakeIpsatInsteadOfNext()">
                <span *ngIf="!isUserInSmallGroup()"
                  ><i class="fa fa-diamond"></i>&nbsp;&nbsp;Take IPSAT</span
                >
                <span *ngIf="isUserInSmallGroup()">Go to Module 6</span>
              </span>
            </button>
          </div>
        </div>
      </div>

      <!-- Selector -->
      <div
        class="col-md-6 nexleader-onboarding-padded-container nexleader-onboarding-half-layout-side hide-mobile"
      >
        <div class="nexleader-onboarding-preassessments-padding">
          <div
            class="hpanel m-b-md"
            *ngIf="shouldDisplayTakeIpsatInsteadOfNext()"
          >
            <div class="panel-body">
              <div class="flex flex-vertical-center">
                <div class="m-r-md">
                  You have completed all of the required prerequisites and now
                  are able to take the IPSAT.
                </div>
                <div class="flex-grow"></div>
                <button
                  class="btn btn-success btn-lg"
                  (click)="onNextPrereqButtonClicked()"
                >
                  <span *ngIf="!isUserInSmallGroup()"
                    ><i class="fa fa-diamond"></i>&nbsp;&nbsp;Take IPSAT</span
                  >
                  <span *ngIf="isUserInSmallGroup()">Go to Module 6</span>
                </button>
              </div>
            </div>
          </div>

          <app-nexleader-pre-ipsat-checklist
            *ngIf="selectedPrerequisite"
            (onSelectPersonalInfo)="viewPersonalInfoStep()"
            (onSelectPrereq)="save()"
            [user]="user"
            [(selectedPrerequisite)]="selectedPrerequisite"
          >
          </app-nexleader-pre-ipsat-checklist>
        </div>
      </div>
    </div>
  </div>
</div>
