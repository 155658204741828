/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderComponentCourseContentCard
 *
 * angular component
 *
 * The course content card is a shared card component between the admin and participant view for IPSAT U.
 */
import { tap, catchError, of } from 'rxjs';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlobResourceService } from '../../../../services/blob.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderCourseContentInputComponent } from '../course-content-input/course-content-input.component';
import { NexleaderCourseContentFileUploadComponent } from '../course-content-file-upload/course-content-file-upload.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-nexleader-course-content-card',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderCourseContentInputComponent,
    NexleaderCourseContentFileUploadComponent,
  ],
  templateUrl: './course-content-card.component.html',
})
export class NexleaderCourseContentCardComponent implements OnInit {
  /**
   * courseContent
   *
   * Object
   *
   * An object representing course content to be rendered on the individual card.
   */
  @Input() courseContent: any;

  /**
   * canEditCourseContent
   *
   * Object
   *
   * Determines if the user can edit the course content. Reserved only for super admins configuring the content.
   */
  @Input() canEditCourseContent: any;

  /**
   * handleSaveCourseContent
   *
   * Object
   *
   * A binding that handles saving the course content.
   */
  @Output() handleSaveCourseContent = new EventEmitter<any>();

  /**
   * handleCourseContentRemove
   *
   * Object
   *
   * A binding that handles removing the course content.
   */
  @Output() handleRemoveCourseContent = new EventEmitter<any>();

  /**
   * onUploadFile
   *
   * Object
   *
   * A binding that handles removing the course content.
   */
  @Output() onUploadFile = new EventEmitter<any>();

  urlRegex =
    /ht{2}ps?:\/{2}(w{3}\.)?[#%+-.0-:=@-Z_a-z~]{1,256}\.[\d()A-Za-z]{1,6}\b([#%&()+-:=?-Z_a-z~]*)/;
  editingCourseContent = false;

  constructor(
    private blobResourceService: BlobResourceService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    // validate inputs
    if (!this.courseContent || typeof this.courseContent !== 'object') {
      throw new Error(
        'Input nexleaderDataCourseContent of type object is required for NexleaderCourseContentCardComponent.'
      );
    }
  }

  /**
   * Toggles whether the course content card is being edited.
   */
  editCourseContent() {
    this.editingCourseContent = !this.editingCourseContent;
  }

  /**
   * Determines if the form is currently valid in its current state.
   *
   * @param {*} courseContent
   * @returns
   */
  hasValidFormInput(courseContent: any) {
    return (
      courseContent &&
      courseContent.contentName &&
      courseContent.contentName.length > 0 &&
      courseContent.contentDescription &&
      courseContent.contentDescription.length > 0 &&
      courseContent.courseObjectives &&
      courseContent.courseObjectives.length > 0 &&
      courseContent.contentUrl &&
      courseContent.contentUrl.length > 0 &&
      courseContent.contentUrl.match(this.urlRegex) &&
      courseContent.coursePrice &&
      courseContent.coursePrice.length > 0 &&
      courseContent.internalTags &&
      courseContent.internalTags.length > 0
    );
  }

  /**
   * Handles removing an image.
   */
  handleRemoveImage() {
    this.blobResourceService
      .delete(this.courseContent.blob._id)
      .pipe(
        tap((_) => {
          this.successService.handle({ message: 'File deleted.' });
          this.onUploadFile.emit();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
