import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {

  constructor(private httpClient: HttpClient) {}

  get(): Observable<any> {
    return this.httpClient.get<any>(`${API_BASE}integrations`, {
      withCredentials: true
    });
  }
}
