import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { CommonModule } from '@angular/common';
import { NexleaderCalendarComponent } from '../../../core/components/calendar/calendar.component';

@Component({
  selector: 'app-nexleader-participant-small-group-sessions',
  standalone: true,
  imports: [CommonModule, NexleaderCalendarComponent],
  templateUrl: './participant-small-group-sessions.component.html',
})
export class NexleaderParticipantSmallGroupSessionsComponent implements OnInit {
  routeParams: any;
  smallGroupSessions!: any[];
  currentEvent: any;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    const userId = this.routeParams['user_id'];
    this.userService.queryModuleSessions(userId).subscribe((data: any) => {
      this.smallGroupSessions = data;
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderParticipantSmallGroupSessionsComponent',
//   downgradeComponent({
//     component: NexleaderParticipantSmallGroupSessionsComponent,
//   }) as angular.IDirectiveFactory
// );
