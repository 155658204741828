import { ActivatedRouteSnapshot, CanDeactivate, GuardResult, MaybeAsync, RouterStateSnapshot } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EnforceNoBackGuard implements CanDeactivate<any> {
    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): MaybeAsync<GuardResult> {
        if (component.canDeactivate) {
            return component.canDeactivate();
        }
        return false;
    }

}