/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private http: HttpClient) {}

  get(sessionId: string): Observable<any> {
    return this.http.get(`${API_BASE}sessions/${sessionId}`, {
      withCredentials: true,
    });
  }

  save(sessionId: string | undefined, session: any): Observable<any> {
    const url = `${API_BASE}sessions` + (sessionId ? `/${sessionId}` : '');
    let params = new HttpParams();
    if (session._id) {
      params = params.append('survey_id', session._id);
    }
    return this.http.post(url, session, {
      withCredentials: true,
      params,
    });
  }

  remove(sessionId: string): Observable<any> {
    const params = new HttpParams({
      fromObject: { survey_id: sessionId },
    });
    return this.http.delete(`${API_BASE}sessions/${sessionId}`, {
      withCredentials: true,
      params,
    });
  }

  queryCoach(coachId: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}sessions/coaches/${coachId}`, {
      withCredentials: true,
    });
  }

  queryIndex(params?: any): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}sessions/index`, {
      params,
      withCredentials: true,
    });
  }

  queryCoachUser(coachUserId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}coachUsers/${coachUserId}/sessions`,
      {
        withCredentials: true,
      }
    );
  }

  queryParticipant(userId: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}sessions/participants/${userId}`, {
      withCredentials: true,
    });
  }

  queryGroup(groupId: string, params?: any): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}groups/${groupId}/sessions`, {
      params,
      withCredentials: true,
    });
  }

  completeSession(sessionId: string, payload: any): Observable<any> {
    return this.http.post(
      `${API_BASE}sessions/${sessionId}/complete`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  queryLicensingPartner(
    licensingPartnerId: string,
    params?: any
  ): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}licensingPartners/${licensingPartnerId}/sessions`,
      {
        params,
        withCredentials: true,
      }
    );
  }
}
