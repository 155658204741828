<div class="row nexleader-quick-view-overflow" style="height: 100%">
  <div
    class="col-md-6 col-12 nexleader-quick-view-overflow-col"
    style="height: 100%; overflow-y: scroll"
    id="nexleader-quickview-table"
  >
    <div class="hpanel">
      <div class="panel-heading">
        {{ label || "Quick View" }}
        <div
          class="panel-tools"
          (click)="createNewElement()"
          [class.is-hidden]="overrideDisplayNewElement"
          [class.disabled]="isNewElementPresent()"
        >
          <a
            ><i class="fa fa-plus"></i>&nbsp;&nbsp;
            {{ overrideNewElementText || "Create" }}</a
          >
        </div>
      </div>
      <div class="panel-body" *ngIf="elements.length < 1">
        <p>Nothing has been created yet.</p>
      </div>
      <div class="panel-body" *ngIf="elements.length > 0">
        <div class="input-group">
          <div class="input-group-addon">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Search"
            [(ngModel)]="searchText"
          />
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th *ngFor="let header of tableHeaders">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let element of elements | filter : undefined : searchText
                "
                (click)="selectElement(element)"
                [ngClass]="{
                  'nexleader-quick-view-table-active':
                    selectedElement === element
                }"
                [attr.id]="
                  selectedElement === element
                    ? 'nexleader-quick-view-table-active'
                    : ''
                "
                class="cursor-pointer"
              >
                <td *ngFor="let column of tableColumns">
                  <span *ngIf="element[column]?.name">{{
                    element[column].name
                  }}</span>
                  <span *ngIf="element[column] === true">
                    <i class="fa fa-check text-success"></i>
                  </span>
                  <span *ngIf="isString(element[column])">{{
                    element[column]
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-12 nexleader-quick-view-overflow-col">
    <div style="height: 40px"></div>
    <ng-content></ng-content>
  </div>
</div>
