<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <ng-container *ngIf="personalValuesInventoryResults.length < 1">
      <p>No Personal Values Inventory results yet.</p>
    </ng-container>
    <div class="row equal-6" *ngIf="personalValuesInventoryResults.length > 0">
      <div
        class="col-md-6 col-xs-12 equal-child"
        *ngFor="let pviResult of personalValuesInventoryResults"
      >
        <div class="hpanel">
          <div class="panel-body">
            <h4 class="text-primary-2 m-b-sm">
              Personal Values Inventory Result
            </h4>
            <b>Top Twelve Values:</b>
            <span
              *ngFor="let value of pviResult.topTwelveValues; let last = last"
            >
              {{ value.name }}<span *ngIf="!last">, </span>
            </span>
          </div>
          <div class="panel-footer flex flex-vertical-center">
            <span class="text-secondary font-semi-bold"
              >Completed on {{ pviResult.createdAt | date }}</span
            >
            <div class="flex-grow"></div>
            <a
              class="btn btn-default"
              [routerLink]="['/users', pviResult.user._id, 'pvi', pviResult._id]"
            >
              View result
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
