<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Use ng-if at the top of the file, so we don't have to use it on everything that binds to the report -->
<div *ngIf="teamReport" class="container m-t-lg m-b-lg">
  <div class="row">
    <div class="col-md-10 col-md-offset-1 col-xs-12">
      <!-- Header: Prepared for Etc.. -->
      <div class="flex flex-vertical-center m-b-md">
        <div>
          <a [routerLink]="['/']">Back</a>
          <h2 class="font-bold text-primary-2" id="nexleader-test-team-report-title">
            Team Report prepared for {{ teamReport.teamName }}
          </h2>
          <div class="text-muted font-bold">
            Created On: {{ teamReport.createdAt | date }}
          </div>
        </div>
        <div class="flex-grow"></div>
        <a class="m-l-md pull-right font-bold text-muted" [routerLink]="[
            '/groups',
            group_id,
            'teamReports',
            team_report_id,
            'printable'
          ]">
          <i class="fa fa-print"></i>&nbsp;&nbsp;Print Your Team Report
        </a>
        <a class="m-l-md pull-right font-bold text-muted" (click)="shareTeamReport()">
          <i class="fa fa-share"></i>&nbsp;&nbsp;Share Your Team Report
        </a>
      </div>

      <!-- Team Members List -->
      <div class="hpanel nexleader-teamreport-panel">
        <div class="panel-body">
          <h4 class="text-primary">Team Members</h4>
          <hr class="m-t-md m-b-md" />
          <div class="table-responsive m-b-md">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th *ngIf="isAllowedToEditTeamReport">
                    Can View This Team Report
                  </th>
                  <th *ngIf="isAllowedToEditTeamReport">
                    Remove From This Team Report
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let teamUser of teamReport.teamUsers"
                  id="nexleader-test-team-report-user-row-{{ teamUser._id }}">
                  <td>{{ teamUser.firstName }} {{ teamUser.lastName }}</td>
                  <td>{{ teamUser.email }}</td>
                  <td *ngIf="isAllowedToEditTeamReport">
                    <input type="checkbox" id="nexleader-test-team-report-allowed-to-view-checkbox-{{
                        teamUser._id
                      }}" [(ngModel)]="teamUser.isAllowedToViewTeamReport"
                      (change)="markUserAsAbleToViewTeamReport(teamUser)" />
                  </td>
                  <td *ngIf="isAllowedToEditTeamReport">
                    <button class="btn btn-default" id="nexleader-test-team-report-remove-member-btn-{{
                        teamUser._id
                      }}" (click)="removeTeamMember(teamUser)" *ngIf="canRemoveTeamMember()">
                      Remove
                    </button>
                    <i class="text-muted" *ngIf="!canRemoveTeamMember()">
                      You must have at least one team member on each team
                      report.
                    </i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" *ngIf="isAllowedToEditTeamReport">
            <div class="col-xs-12">
              <div class="pull-left">
                <h5 class="text-primary-2">Add a New Team Member</h5>
                <p class="text-muted">
                  Adding a new team member to this team report will cost
                  <app-nexleader-team-report-cost id="nexleader-test-team-report-add-member-price-message"
                    [groupId]="group_id" [costType]="'perUser'"></app-nexleader-team-report-cost>/team member.
                </p>
              </div>
              <div class="pull-right">
                <button class="btn btn-success" id="nexleader-test-team-report-add-member-btn"
                  (click)="addTeamMember()">
                  Add Team Member
                </button>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Team IPSAT -->
      <div class="hpanel nexleader-teamreport-panel">
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <div class="flex flex-vertical-center m-b-md">
                <img src="/assets/images/logo.svg" alt="Logo" class="img-md m-r-md" />
                <h3 class="font-bold m-b-sm text-primary-2">
                  Team IPSAT Results
                </h3>
              </div>
              <app-nexleader-chart-ipsat-doughnut [model]="teamReport.ipsat"></app-nexleader-chart-ipsat-doughnut>
            </div>
            <div class="col-sm-6 col-xs-12">
              <h4 class="text-primary">
                Your team is at the
                <span class="font-bold">{{ teamReport.ipsat.stage.name }}</span>
              </h4>
              <p>{{ teamReport.ipsat.stage.body }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Personality: Team MBTI -->
      <div class="m-t-lg">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-8 col-xs-12">
                <div class="flex flex-vertical-center m-b-md">
                  <img src="/assets/images/icons/personality.svg" alt="Personality" class="img-md m-r-md" />
                  <h3 class="font-bold m-b-sm text-primary-2">
                    Team Personality
                  </h3>
                </div>
                <p>
                  The IPSAT team score reflects the collective level of
                  self-awareness with regard to how well your team members
                  understand their personality, how it relates to the rest of
                  their identity, and how their preferences could work against
                  them if overused.
                </p>
              </div>
              <div class="col-sm-4 col-xs-12">
                <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.personality"
                  [category]="'personality'"></app-nexleader-team-report-progress-bar>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <div class="row">
              <div class="col-xs-12 m-b-md">
                <h4 class="font-bold m-b-sm text-primary">
                  MBTI&reg; Team Type -
                  {{ teamReport.personality.teamMbti.name }}
                </h4>
                <p>
                  The MBTI&reg; team type is calculated by counting the number
                  of team members with each preference. The information below
                  shows the MBTI&reg; types represented by your team.
                </p>
              </div>
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-md-4 col-xs-12 m-b-md">
                    <app-nexleader-chart-mbti-table [teamMbti]="teamReport.personality.teamMbti"
                      [model]="teamReport.personality.mbtiTypeAggregation"></app-nexleader-chart-mbti-table>
                  </div>
                  <div class="col-md-8 col-xs-12">
                    <app-nexleader-chart-mbti-letter-aggregation
                      [model]="teamReport.personality.mbtiTraitAggregation"></app-nexleader-chart-mbti-letter-aggregation>
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-discussion-questions
              [questions]="teamReport.personality.questions"></app-nexleader-team-report-discussion-questions>
          </div>
        </div>
      </div>

      <!-- Strengths: Team StrengthsFinder -->
      <div class="m-t-lg">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-8 col-xs-12">
                <div class="flex flex-vertical-center m-b-md">
                  <img src="/assets/images/icons/strengths.svg" alt="Strengths" class="img-md m-r-md" />
                  <h3 class="font-bold m-b-sm text-primary-2">
                    Team Strengths
                  </h3>
                </div>
                <p>
                  The IPSAT team score reflects the collective level of
                  self-awareness with regard to how well your team members
                  understand their strengths, the extent to which they are being
                  developed, how they relate to the rest of their identity, and
                  how their strengths could work against them if overused.
                </p>
              </div>
              <div class="col-sm-4 col-xs-12">
                <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.strengths"
                  [category]="'strengths'"></app-nexleader-team-report-progress-bar>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <h4 class="text-primary">
              Team Profile of Clifton Strengths Assessment across the four
              domains
            </h4>
            <app-nexleader-chart-strengths-represented *ngIf="teamReport.strengths.representedStrengthCategories"
              [model]="teamReport.strengths.representedStrengthCategories"></app-nexleader-chart-strengths-represented>
            <hr class="m-t-md m-b-md" />
            <h4 class="text-primary">Strengths Not Represented on Your Team</h4>
            <app-nexleader-chart-strengths-unrepresented *ngIf="teamReport.strengths.unrepresentedStrengthCategories"
              [model]="teamReport.strengths.unrepresentedStrengthCategories"></app-nexleader-chart-strengths-unrepresented>
            <br />
            <p>
              Each of the CliftonStrengths&reg; sorts into one of four domains
              as listed above. Click
              <a href="https://www.gallupstrengthscenter.com/Home/en-US/CliftonStrengths-Themes-Domains"
                target="_blank">here</a>
              for more information.
            </p>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-discussion-questions
              [questions]="teamReport.strengths.questions"></app-nexleader-team-report-discussion-questions>
          </div>
        </div>
      </div>

      <!-- Skills: Team SkillsInventory -->
      <div class="m-t-lg">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-8 col-xs-12">
                <div class="flex flex-vertical-center m-b-md">
                  <img src="/assets/images/icons/skills.svg" alt="Skills" class="img-md m-r-md" />
                  <h3 class="font-bold m-b-sm text-primary-2">Team Skills</h3>
                </div>
                <p>
                  The IPSAT team score reflects the collective level of
                  self-awareness with regard to how well your team members
                  understand the skills required to succeed in various roles,
                  the extent to which they are being developed, how skills can
                  synergize with the rest of their identity, and how skills can
                  work against them if overused.
                </p>
              </div>
              <div class="col-sm-4 col-xs-12">
                <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.skills"
                  [category]="'skills'"></app-nexleader-team-report-progress-bar>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <div>
              <h4 class="font-bold text-primary m-b-md">
                Ideal vs Self-assessed Team Skill Profile
              </h4>
              <app-nexleader-chart-skill-comparison
                [model]="teamReport.skills.levels"></app-nexleader-chart-skill-comparison>
            </div>
            <hr class="m-t-md m-b-md" />
            <div>
              <h4 class="font-bold text-primary m-b-md">
                Five highest rated, self-assessed skills
              </h4>
              <app-nexleader-chart-skills [model]="teamReport.skills.levels.top"></app-nexleader-chart-skills>
            </div>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-discussion-questions
              [questions]="teamReport.skills.questions"></app-nexleader-team-report-discussion-questions>
          </div>
        </div>
      </div>

      <!-- Spiritual Gifts -->
      <div class="m-t-lg" *ngIf="teamReport.surveyType.identifier === 'faith_based'">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-8 col-xs-12">
                <div class="flex flex-vertical-center m-b-md">
                  <img src="/assets/images/icons/spiritualgifts.svg" alt="Spiritual Gifts" class="img-md m-r-md" />
                  <h3 class="font-bold m-b-sm text-primary-2">
                    Team Spiritual Gifts
                  </h3>
                </div>
                <p>
                  The IPSAT team score reflects the collective level of
                  self-awareness with regard to how consistently your team
                  members use their spiritual gifts, the extent to which they
                  are being developed, how gifts can synergize with the rest of
                  their identity, and how they can work against them if
                  overused.
                </p>
              </div>
              <div class="col-sm-4 col-xs-12">
                <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.spiritualGifts"
                  [category]="'spiritualgifts'"></app-nexleader-team-report-progress-bar>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <h4 class="font-bold m-b-md text-primary">
              Venn Diagram of Your Team's Spiritual Gifts
            </h4>
            <app-nexleader-chart-spiritual-gifts-represented *ngIf="
                teamReport.spiritualGifts.representedSpiritualGiftCategories
              " [model]="
                teamReport.spiritualGifts.representedSpiritualGiftCategories
              "></app-nexleader-chart-spiritual-gifts-represented>
            <hr class="m-t-md m-b-md" />
            <h4 class="font-bold m-b-md text-primary">
              Spiritual Gifts Not Represented on Your Team
            </h4>
            <app-nexleader-chart-spiritual-gifts-unrepresented *ngIf="
                teamReport.spiritualGifts.unrepresentedSpiritualGiftCategories
              " [model]="
                teamReport.spiritualGifts.unrepresentedSpiritualGiftCategories
              "></app-nexleader-chart-spiritual-gifts-unrepresented>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-discussion-questions
              [questions]="teamReport.spiritualGifts.questions"></app-nexleader-team-report-discussion-questions>
          </div>
        </div>
      </div>

      <!-- Personal Values -->
      <div class="m-t-lg" *ngIf="teamReport.surveyType.identifier === 'values_based'">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-8 col-xs-12">
                <div class="flex flex-vertical-center m-b-md">
                  <img src="/assets/images/icons/spiritualgifts.svg" alt="Personal Values" class="img-md m-r-md" />
                  <h3 class="font-bold m-b-sm text-primary-2">
                    Team Personal Values
                  </h3>
                </div>
                <p>
                  The IPSAT team report reflects the collective level of
                  self-awareness with regard to how well your team members
                  understand and live by their values, when to compromise if
                  values are in tension with each other, and their awareness of
                  situations where they are vulnerable to act in ways that are
                  inconsistent with their values.
                </p>
              </div>
              <div class="col-sm-4 col-xs-12">
                <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.spiritualGifts"
                  [category]="'spiritualgifts'"></app-nexleader-team-report-progress-bar>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <h4 class="font-bold m-b-md text-primary">
              Team Profile of Personal Values Across 4 Life Domains
            </h4>
            <app-nexleader-chart-personal-values-represented *ngIf="teamReport.personalValues"
              [model]="teamReport.personalValues"></app-nexleader-chart-personal-values-represented>
            <hr class="m-t-md m-b-md" />
            <h4 class="font-bold m-b-md text-primary">
              Personal Values Spider Graph
            </h4>
            <app-nexleader-chart-personal-values-spider *ngIf="teamReport.personalValues"
              [model]="teamReport.personalValues"></app-nexleader-chart-personal-values-spider>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-discussion-questions
              [questions]="teamReport.personalValues.questions"></app-nexleader-team-report-discussion-questions>
          </div>
        </div>
      </div>

      <!-- Passions: Team MyPassionProfile -->
      <div class="m-t-lg">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-8 col-xs-12">
                <div class="flex flex-vertical-center m-b-md">
                  <img src="/assets/images/icons/passions.svg" alt="Passions" class="img-md m-r-md" />
                  <h3 class="font-bold m-b-sm text-primary-2">Team Passions</h3>
                </div>
                <p>
                  The IPSAT team score reflects the collective level of
                  self-awareness with regard to how well your team members
                  understand how passions emerge, how to develop them, how
                  effectively they are using their giftedness to advance
                  important causes, and how passion can work against them if
                  overused.
                </p>
              </div>
              <div class="col-sm-4 col-xs-12">
                <app-nexleader-team-report-progress-bar [max]="20" [value]="teamReport.ipsat.passions"
                  [category]="'passions'"></app-nexleader-team-report-progress-bar>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <div class="row flex flex-vertical-center flex-column-md">
              <div class="col-md-8 col-xs-12 text-center">
                <img style="width: 100%; max-width: 400px" src="/assets/images/passion-pyramid.svg"
                  alt="passion pyramid" />
              </div>
              <div class="col-md-4 col-xs-12">
                <p>
                  Passion can be defined as a self-directed motivation to learn
                  more about something, engage in activities related to it, and
                  influence others for it, even when sacrifice is required. The
                  passion pyramid depicts the four levels of passion based on
                  the learn, engage, influence and sacrifice model.
                </p>
              </div>
            </div>
            <hr class="m-t-md m-b-md" />
            <h4 class="font-bold m-b-md text-primary">
              Passions Represented on Your Team
            </h4>
            <app-nexleader-chart-passions-represented
              [model]="teamReport.passions.representedPassionLevels"></app-nexleader-chart-passions-represented>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-domains [domainsOfPassionateEngagement]="
                teamReport.domainsOfPassionateEngagement
              "></app-nexleader-team-report-domains>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-discussion-questions
              [questions]="teamReport.passions.questions"></app-nexleader-team-report-discussion-questions>
          </div>
        </div>
      </div>

      <!-- Open-Ended Statements -->
      <div class="m-t-lg" *ngFor="let statement of teamReport.openEndedQuestions; let last = last">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <h3 class="font-bold text-primary-2">
              {{ statement.name }} Statements for Your Team
            </h3>
            <p>{{ statement.description }}</p>
            <hr class="m-t-md m-b-md" />
            <p class="font-bold m-b-sm text-primary-2">
              The following are statements for your team:
            </p>
            <p *ngFor="let response of statement.responses" [ngClass]="{ 'm-b-md': !last }">
              {{ response.answer.name }}
              <br />
              <i>{{ response.user.firstName }} {{ response.user.lastName }}</i>
            </p>
          </div>
        </div>
      </div>

      <!-- Next Step Questions -->
      <div class="m-t-lg">
        <div class="hpanel nexleader-teamreport-panel">
          <div class="panel-body">
            <h3 class="font-bold text-primary-2">Next Steps</h3>
            <hr class="m-t-md m-b-md" />
            <app-nexleader-team-report-discussion-questions [questions]="teamReport.nextStepQuestions"
              [hideHeader]="true"></app-nexleader-team-report-discussion-questions>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="error" class="row m-t-lg m-b-lg">
  <div class="col-sm-4 col-sm-offset-4 col-xs-12">
    <div class="m-b-md flex flex-horizontal-center flex-vertical-center">
      <img src="/assets/images/logo.svg" width="50" class="m-r-sm" />
      <h3 class="font-bold text-primary-2">IPSAT</h3>
    </div>
    <div class="hpanel">
      <div class="panel-body">
        <h4 class="text-primary-2" id="nexleader-test-team-report-not-allowed-to-view">
          Important information about viewing this team report:
        </h4>
        <p>
          The online version of an IPSAT Team Report is only visible to IPSAT
          users who are part of the same group. You don't have to be on the
          team, but your account does need to be part of the same group. If you
          don't have an IPSAT account or are not part of this group, ask the
          person who sent you this link for a PDF version of the report.
          <br /><br />
          If you have an IPSAT account and are part of this group click
          <a [routerLink]="['/login']" [queryParams]="{ next: relativeUrl }">here</a>
          to login and view the Team Report. <br /><br />
          <a [routerLink]="['/']">Head home</a>
        </p>
      </div>
    </div>
  </div>
</div>