<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-body">
            <app-nexleader-sessions-table></app-nexleader-sessions-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
