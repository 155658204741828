<div class="hpanel">
  <div class="panel-body">
    <div class="flex flex-vertical-center">
      <h4 class="text-primary-2 m-t-none m-b-none">
        {{ invite.firstName }} {{ invite.lastName }}
      </h4>
      <div class="flex-grow"></div>
    </div>
    <table class="table m-t-md">
      <tbody>
        <tr>
          <td><b>Email</b></td>
          <td>
            <a href="mailto:{{ invite.email }}">{{ invite.email }}</a>
          </td>
        </tr>
        <tr>
          <td><b>Roles</b></td>
          <td>
            <ul>
              <li *ngFor="let role of invite.roles">{{ role }}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td><b>Coach</b></td>
          <td>
            {{ invite.coach?.firstName || "No " }}
            {{ invite.coach?.lastName || "coach" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="panel-footer flex flex-vertical-center">
    <div class="font-semi-bold">Created On {{ invite.createdAt | date }}</div>
    <div class="flex-grow"></div>
    <div>
      <a
        style="margin-right: 3px"
        class="btn btn-default"
        [routerLink]="['/invites', invite._id]"
        >Link</a
      >
      <button
        style="margin-right: 3px"
        class="btn btn-default"
        (click)="resendInvite()"
      >
        Resend
      </button>
      <button
        style="margin-right: 3px"
        class="btn btn-danger"
        (click)="deleteInvite()"
      >
        Delete
      </button>
    </div>
  </div>
</div>
