/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * InputLicensingPartnerData
 *
 * angular component
 *
 * dynamic selector dropdown for coaches
 *
 * This component is responsible for the stateless inputs associated with creating and editing licensing partners.
 *  If you're looking for a drop down of licensing partners, see InputLicensingPartner.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-input-licensing-partner-data',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-licensing-partner-data.component.html',
})
export class NexleaderInputLicensingPartnerDataComponent implements OnInit {
  /**
   * model
   *
   * two-way object binding
   *
   * basically ng-model
   *
   * This is a two-way binding to the licensing partner object we are acting as an input for
   */
  @Input() model: any;

  /**
   * $onInit()
   *
   * function: angular event handler
   *
   * called after binding
   */
  ngOnInit() {
    if (this.model === undefined) {
      throw new Error(
        'Cannot initialize NexleaderInputLicensingPartnerDataComponent. Binding "model" is required.'
      );
    }
  }
}
