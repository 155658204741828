/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { EnumsService } from '../../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SkippedVideoService } from '../../../../../services/skipped-video.service';
import { SuccessService } from '../../../../../services/success.service';
import { UserService } from '../../../../../services/user.service';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { NexleaderVimeoVideoComponent } from '../../../../small-groups/components/vimeo-video/vimeo-video.component';

@Component({
  selector: 'app-nexleader-onboarding-video',
  standalone: true,
  imports: [CommonModule, NexleaderVimeoVideoComponent],
  templateUrl: './onboarding-video.component.html'
})
export class NexleaderOnboardingVideoComponent implements OnInit {
  @Output() boundNext = new EventEmitter();

  routeParams: any;
  onboardingStep: any;
  nextOnboardingStep: any;
  user: any;
  surveyTypeIdentifier!: string;

  constructor(
    private route: ActivatedRoute,
    private enumsService: EnumsService,
    private userService: UserService,
    private skippedVideoService: SkippedVideoService,
    private errorHandler: ErrorHandlerService,
    private successHandler: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    forkJoin([this.enumsService.getEnums(),
    this.userService.getUser(this.routeParams['user_id'])
    ]).pipe(
      tap(([enums, user]) => {
        this.onboardingStep = enums.OnboardingSteps.VIDEO;
        this.nextOnboardingStep = enums.OnboardingSteps.PREASSESSMENTS;
        this.user = user;
        this.surveyTypeIdentifier = user.group.surveyType.identifier;
        if (this.user.watchedVideo) {
          this.next();
        }
      }),
      catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  save(): Observable<any> {
    return this.userService.saveUser(this.user).pipe(
      tap((_) => {
        this.successHandler.handle()
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null)
      })
    )
  }

  next(): void {
    // Only update their step if they're on the video step
    if (this.user.onboardingStep.index === this.onboardingStep.index) {
      this.user.onboardingStep = this.nextOnboardingStep;
    }
    this.save().pipe(
      tap((_) => {
      })
    ).subscribe(() => {
      this.boundNext.emit();
    })
  }

  setWatchedVideoAndNext(watchedVideo: boolean): void {
    this.user.watchedVideo = watchedVideo;
    // If the user hasn't watched the video, we need to skip it to make sure they don't see it again
    if (!watchedVideo) this.skippedVideoService.hasSkippedVideo = true;
    this.next();
  }

  isUserInSmallGroup(): boolean {
    if (!this.user) return false;
    return !!this.user.smallGroup;
  }
}
