/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class DirectDiscountService {
  constructor(private http: HttpClient) {}

  remove(directDiscountId: string): Observable<any> {
    return this.http.delete(`${API_BASE}directDiscounts/${directDiscountId}`, {
      withCredentials: true,
    });
  }

  save(directDiscount: any): Observable<any> {
    return this.http.post(`${API_BASE}directDiscounts`, directDiscount, {
      withCredentials: true,
    });
  }

  queryLicensingPartner(licensingPartnerId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}licensingPartners/${licensingPartnerId}/directDiscounts`,
      {
        withCredentials: true,
      }
    );
  }
}
