import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { CoachUserService } from '../../../../services/coach-user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-multigroup-coaching-groups',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './multigroup-coaching-groups.component.html',
})
export class NexleaderMultigroupCoachingGroupsComponent implements OnInit {
  routeParams: any;
  coachUser: any;
  users: any[] = [];

  constructor(
    private userService: UserService,
    private coachUserService: CoachUserService,
    private errorHandler: ErrorHandlerService,
    private successHandler: SuccessService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    this.coachUserService
      .getCoachUser(this.routeParams['coach_user_id'])
      .pipe(
        tap((a) => {
          this.coachUser = a;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.userService
      .queryCoachUser(this.routeParams['coach_user_id'])
      .pipe(
        tap((a) => {
          this.users = a;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  isUserPrimary(user: any): boolean {
    const primaryId =
      this.coachUser.primaryGroupAdminUser?._id ||
      this.coachUser.primaryGroupAdminUser;
    return user._id === primaryId;
  }

  archive(user: any): void {
    user.archivedByCoachUser = true;
    this.userService
      .saveUser(user)
      .pipe(
        tap((_) => {
          this.successHandler.handle();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  unarchive(user: any): void {
    user.archivedByCoachUser = false;
    this.userService
      .saveUser(user)
      .pipe(
        tap(() => {
          this.successHandler.handle();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * Returns the users that have NOT been archived by the colsolidated coach user
   * These will display as cards
   */
  filteredUsers(): any[] {
    return this.users
      ? this.users.filter((user) => !user.archivedByCoachUser)
      : [];
  }

  /**
   * Returns the users that HAVE been archived by the consolidated coach user
   * These will display as a table
   */
  archivedUsers(): any[] {
    return this.users
      ? this.users.filter((user) => user.archivedByCoachUser)
      : [];
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderMultigroupCoachingGroupsComponent',
//   downgradeComponent({
//     component: NexleaderMultigroupCoachingGroupsComponent,
//   }) as angular.IDirectiveFactory
// );
