import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupService } from '../../../../services/group.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { TeamReportService } from './team-report.service';
import { Observable, catchError, combineLatest, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NexleaderTeamReportCostComponent } from '../../components/team-report-cost/team-report-cost.component';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-team-reports',
  standalone: true,
  imports: [CommonModule, RouterLink, FormsModule, NexleaderTeamReportCostComponent],
  templateUrl: './team-reports.component.html',
})
export class NexleaderTeamReportsComponent implements OnInit {
  routeParams: any;

  //Store the group_id on the controller so that the view button can
  // use it
  group_id: string | null = null;
  teamReports: any[] = [];
  groups: any[] = [];
  group: any | null = null;
  viewingMoreInformation = false;

  constructor(
    private route: ActivatedRoute,
    private teamReportService: TeamReportService,
    private groupService: GroupService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.group_id = this.routeParams['group_id'];
    this.load();
  }

  load(): void {
    const observables: Observable<any>[] = [];

    if (this.group_id) {
      observables.push(
        this.teamReportService.queryGroupIndex(this.group_id).pipe(
          tap((a) => {
            this.teamReports = a;
          })
        )
      );
      observables.push(
        this.groupService.getLite(this.group_id).pipe(
          tap((a) => {
            this.group = a;
          }),
          catchError((error) => {
            this.errorHandler.handleError(error);
            return of(null);
          })
        )
      );
    } else {
      observables.push(
        this.groupService
          .queryCoachUserIndex(this.routeParams['coach_user_id'])
          .pipe(
            tap((a) => {
              this.groups = a;
            })
          )
      );
    }

    if (this.routeParams['coach_user_id']) {
      observables.push(
        this.teamReportService
          .queryCoachUserIndex(this.routeParams['coach_user_id'])
          .pipe(
            tap((a) => {
              this.teamReports = a;
            })
          )
      );
    }

    combineLatest(observables)
      .pipe(
        tap((_) => { }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  viewMoreInformation(): void {
    this.viewingMoreInformation = !this.viewingMoreInformation;
  }

  shouldShowGroupDropdown(): boolean {
    return !!this.groups.length;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderTeamReportsComponent',
//   downgradeComponent({
//     component: NexleaderTeamReportsComponent,
//   }) as angular.IDirectiveFactory
// );
