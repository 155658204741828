/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-nexleader-frozen',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './frozen.component.html'
})
export class NexleaderFrozenComponent {

  constructor(public authService: AuthService) { }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderFrozenComponent',
//   downgradeComponent({
//     component: NexleaderFrozenComponent,
//   }) as angular.IDirectiveFactory
// );
