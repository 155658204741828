/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderInputRole
 *
 * angular component
 *
 * This component is responsible for providing UI input for one user to select roles for another user.
 * This component is responsible for loading and presenting the user with a list of roles that are editable at the level
 * of authentication that they have, presenting a UI for them to select these roles.
 */

import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RoleService } from '../../../../services/role.service';
import { AuthService } from '../../../../services/auth.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-input-role',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-role.component.html',
})
export class NexleaderInputRoleComponent implements OnInit {
  @Input() model: string[] = [];
  selectedRoles: string[] = [];

  payloadRoles: string[] = [];
  roles: any[] = [];

  constructor(
    private roleService: RoleService,
    private authService: AuthService,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    if (this.model === undefined) {
      throw new Error('InputRole requires a non-undefined model to bind to');
    }

    const payload = this.authService.getTokenPayload();
    if (!payload) {
      throw new Error(
        'InputRole requires an authenticated user from Auth to use; sorry, the logic depends on it.'
      );
    }

    this.payloadRoles = payload.roles;

    // If the token exists but has no roles, it's a consolidated multigroup coach user.
    // Assume it has a group admin role.
    if (!Array.isArray(this.payloadRoles)) {
      this.payloadRoles = ['groupadmin'];
    }

    this.roleService
      .query()
      .pipe(
        tap((roles) => {
          this.roles = roles;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  onRoleChange(roleId: string) {
    const index = this.model.findIndex((i) => i === roleId);
    if (index === -1) {
      // add role
      this.model.push(roleId);
    } else {
      // remove role
      this.model.splice(index, 1);
    }
  }

  /**
   * arraysDoShareElements()
   *
   * @param a array one
   * @param b array two
   * @returns {boolean} true if a and b have one or more common elements
   */
  arraysDoShareElements(a: any[], b: any[]): boolean {
    if (!Array.isArray(a)) {
      throw new TypeError('a must be an array, got: ' + a);
    }

    if (!Array.isArray(b)) {
      throw new TypeError('b must be an array, got: ' + b);
    }

    return b.some((v) => a.indexOf(v) >= 0);
  }

  /**
   * getAvailableRoles()
   *
   * @returns {*} An array of role objects that the currently authenticated user is allowed to edit
   */
  getAvailableRoles(): any[] {
    if (!this.roles) {
      return [];
    }

    return this.roles.filter(
      (role) =>
        role.showInGUI &&
        this.arraysDoShareElements(role.editableBy, this.payloadRoles)
    );
  }
}
