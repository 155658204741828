<div class="row">
  <div class="clearfix"></div>
  <div
    class="col-md-3 col-sm-6 col-xs-12 col-3-print m-b-md"
    *ngFor="let passionLevel of renderedModel"
  >
    <p class="hideNonPrint m-b-sm">
      <b>{{ passionLevel.name }} Passions:</b>
    </p>
    <h5 class="m-b-sm text-primary hideOnPrint">
      {{ passionLevel.name }} Passions:
    </h5>
    <div
      *ngFor="let passion of passionLevel.passions; let last = last"
      [ngClass]="{ 'm-b-sm': !last }"
    >
      <p class="cursor-pointer" (click)="viewUsers(passion)">
        {{ passion.name }} ({{ passion.users.length }})
      </p>
    </div>
    <p *ngIf="passionLevel.passions.length < 1">
      No passions available for this level.
    </p>
  </div>
</div>
