/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { tap, catchError, of } from 'rxjs';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderInputLicensingPartnerDataComponent } from '../../input-licensing-partner-data/input-licensing-partner-data.component';
import { LicensingPartnerService } from '../../../resources/licensing-partner.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SuccessService } from '../../../../../services/success.service';
import { NexleaderLicensingPartnerStripeConnectInviteComponent } from '../../licensing-partner-stripe-connect-invite/licensing-partner-stripe-connect-invite.component';

@Component({
  selector: 'app-nexleader-edit-licensing-partner-modal',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderInputLicensingPartnerDataComponent,
    NexleaderLicensingPartnerStripeConnectInviteComponent,
  ],
  templateUrl: './edit-licensing-partner-modal.component.html',
})
export class NexleaderEditLicensingPartnerModalComponent implements OnInit {
  /**
   * licensingPartnerId
   *
   * A licensing partner that will be edited inside of the modal.
   *
   * ex: {SMALL GROUP DATA}
   */
  licensingPartnerId: string = '';

  /**
   * save
   *
   * A function to use to save the updated small group.
   *
   * wrapped by save(), which does some processing on data (see $ctrl.save())
   *
   * ex: someFn()
   */
  @Output() onSave = new EventEmitter<any>();

  /**
   * cancel
   *
   * A cancel callback function to use to cancel the editing of a small group.
   *
   * ex: someFn()
   */
  @Output() onCancel = new EventEmitter<any>();

  licensingPartner: any;

  constructor(
    private licensingPartnerService: LicensingPartnerService,
    private errorHandler: ErrorHandlerService,
    private successService: SuccessService // private bsModalService: BsModalService
  ) {}

  /**
   * $onInit()
   *
   * function: angular event handler
   *  called after bindings load
   *
   * validate that the bindings loaded and are the correct type
   */
  ngOnInit(): void {
    if (!this.licensingPartnerId) {
      throw new Error(
        'NexleaderEditLicensingPartnerModalComponent requires licensingPartnerId property'
      );
    }

    this.loadLicensingPartner();
  }

  loadLicensingPartner(): void {
    this.licensingPartnerService
      .get(this.licensingPartnerId)
      .pipe(
        tap((response) => {
          this.licensingPartner = response;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * save()
   *
   * maps meeting data for saving then calls realSave()
   */
  save(): void {
    this.licensingPartnerService
      .save(this.licensingPartner, this.licensingPartnerId)
      .pipe(
        tap(() => {
          this.successService.handle();
          this.onSave.emit();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  cancel() {
    this.onCancel.emit();
  }
}
