/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-ecourse-information-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ecourse-information-modal.component.html'
})
export class NexleaderEcourseInformationModalComponent implements OnInit {

  /**
   * ecourseModule
   * 
   * string
   * 
   * A string representing the current ecourse module the user is on.
   */
  ecourseModule!: string;
  moduleTitle!: string;

  moduleTitles: any = {
    'module1': 'Module 1: The Art of Self-Leadership and the Power of Self-Awareness',
    'module2': 'Module 2: Exploring Your Personality',
    'module3': 'Module 3: Discovering Your Strengths',
    'module4': 'Module 4: Developing Your Skills',
    'module5': 'Module 5: Refining Your Personal Values',
    'module6': 'Module 6: Uncovering Your Passions',
    'module7': 'Module 7: Discovering your Identity Profile with IPSAT',
    'module8': 'Module 8: Using Self-Awareness and Self-Leadership to Bring Your Best to School, Work, and Life',
  };

  constructor(private bsModalService: BsModalService) { }

  ngOnInit(): void {
    this.moduleTitle = this.moduleTitles[this.ecourseModule];
  }

  /**
   * close
   *
   * A close callback function to use
   *
   * ex: someFn()
   */
  close() {
    this.bsModalService.hide();
  }
}
