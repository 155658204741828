<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Scrub text</h3>
</div>
<div class="modal-body">
  <p>
    Do to an issue with the way word saves text, it is necessary to scrub the
    text before placing it inside of an email template.
  </p>
  <br />
  <div class="form-group">
    <label class="control-label">Text to format</label>
    <textarea
      class="form-control"
      [(ngModel)]="text"
      cols="30"
      rows="10"
    ></textarea>
  </div>
  <button class="btn btn-success" (click)="formatText()">Format Text</button>
  <br />
  <br />
  <div class="form-group">
    <label class="control-label">Formatted Text</label>
    <textarea
      class="form-control"
      [(ngModel)]="formattedText"
      cols="30"
      rows="10"
      [disabled]="true"
    ></textarea>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>
