/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderPrintableTeamReport
 *
 * angular component
 *
 * Handles the logic (for printing) and the UI for generating a printable version of the team report.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { NexleaderChartIpsatDoughnutComponent } from '../../components/chart-ipsat-doughnut/chart-ipsat-doughnut.component';
import { NexleaderChartMbtiLetterAggregationComponent } from '../../components/chart-mbti-letter-aggregation/chart-mbti-letter-aggregation.component';
import { NexleaderChartMbtiTableComponent } from '../../components/chart-mbti-table/chart-mbti-table.component';
import { NexleaderChartPassionsRepresentedComponent } from '../../components/chart-passions-represented/chart-passions-represented.component';
import { NexleaderChartPersonalValuesRepresentedComponent } from '../../components/chart-personal-values-represented/chart-personal-values-represented.component';
import { NexleaderChartPersonalValuesSpiderComponent } from '../../components/chart-personal-values-spider/chart-personal-values-spider.component';
import { NexleaderChartSkillComparisonComponent } from '../../components/chart-skill-comparison/chart-skill-comparison.component';
import { NexleaderChartSkillsComponent } from '../../components/chart-skills/chart-skills.component';
import { NexleaderChartSpiritualGiftsRepresentedComponent } from '../../components/chart-spiritual-gifts-represented/chart-spiritual-gifts-represented.component';
import { NexleaderChartSpiritualGiftsUnrepresentedComponent } from '../../components/chart-spiritual-gifts-unrepresented/chart-spiritual-gifts-unrepresented.component';
import { NexleaderChartStrengthsRepresentedComponent } from '../../components/chart-strengths-represented/chart-strengths-represented.component';
import { NexleaderChartStrengthsUnrepresentedComponent } from '../../components/chart-strengths-unrepresented/chart-strengths-unrepresented.component';
import { NexleaderTeamReportCostComponent } from '../../components/team-report-cost/team-report-cost.component';
import { NexleaderTeamReportDiscussionQuestionsComponent } from '../../components/team-report-discussion-questions/team-report-discussion-questions.component';
import { NexleaderTeamReportDomainsComponent } from '../../components/team-report-domains/team-report-domains.component';
import { NexleaderTeamReportProgressBarComponent } from '../../components/team-report-progress-bar/team-report-progress-bar.component';
import { TeamReportService } from '../team-reports/team-report.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-printable-team-report',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderTeamReportCostComponent,
    NexleaderChartIpsatDoughnutComponent,
    NexleaderTeamReportProgressBarComponent,
    NexleaderChartMbtiTableComponent,
    NexleaderChartMbtiLetterAggregationComponent,
    NexleaderTeamReportDiscussionQuestionsComponent,
    NexleaderChartStrengthsRepresentedComponent,
    NexleaderChartStrengthsUnrepresentedComponent,
    NexleaderChartSkillComparisonComponent,
    NexleaderChartSkillsComponent,
    NexleaderChartSpiritualGiftsRepresentedComponent,
    NexleaderChartSpiritualGiftsUnrepresentedComponent,
    NexleaderChartPassionsRepresentedComponent,
    NexleaderTeamReportDomainsComponent,
    NexleaderChartPersonalValuesRepresentedComponent,
    NexleaderChartPersonalValuesSpiderComponent,
  ],
  templateUrl: './printable-team-report.component.html',
})
export class NexleaderPrintableTeamReportComponent implements OnInit {
  routeParams: any;
  teamReport: any;
  surveyTypeIdentifier: string = '';

  constructor(
    private route: ActivatedRoute,
    private teamReportService: TeamReportService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    this.teamReportService
      .score(this.routeParams['team_report_id'], this.routeParams['group_id'])
      .pipe(
        tap((result) => {
          this.teamReport = result;
          // Useful for conditional UI logic on how we show the spiritual gifts vs values based team report
          this.surveyTypeIdentifier = result.surveyType.identifier;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  print(): void {
    window.print();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderPrintableTeamReportComponent',
//   downgradeComponent({
//     component: NexleaderPrintableTeamReportComponent,
//   }) as angular.IDirectiveFactory
// );
