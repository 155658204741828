<div class="hpanel" *ngIf="user">
  <div class="panel-body">
    <div class="flex flex-vertical-center">
      <h4 class="text-primary-2 m-t-none m-b-none">
        {{ user.firstName }} {{ user.lastName }}
      </h4>
      <div class="flex-grow"></div>
    </div>
    <table class="table m-t-md">
      <tbody>
        <tr>
          <td><b>Email</b></td>
          <td>
            <a href="mailto:{{ user.email }}">{{ user.email }}</a>
          </td>
        </tr>
        <tr>
          <td><b>Preferred Contact Info</b></td>
          <td>
            <span *ngIf="user.preferredContactType?.name">
              {{ user.preferredContactType.name }}:
            </span>
            {{ user.preferredContactAddress || "Email" }}
          </td>
        </tr>
        <tr *ngIf="currentViewingRole !== 'coach'">
          <td><b>Roles</b></td>
          <td>
            <ul>
              <li
                *ngFor="let role of user.roles"
                style="text-transform: capitalize"
              >
                {{ role }}
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="currentViewingRole === 'admin'">
          <td><b>Licensing Partner</b></td>
          <td>{{ user.licensingPartner?.name }}</td>
        </tr>
        <tr *ngIf="currentViewingRole === 'admin'">
          <td><b>IPSAT Experience</b></td>
          <td>{{ user.group?.surveyType?.name }}</td>
        </tr>
        <tr *ngIf="currentViewingRole === 'admin'">
          <td><b>Group</b></td>
          <td>
            {{ user.group?.name }}
          </td>
        </tr>
        <tr>
          <td><b>Coach Evaluation</b></td>
          <td>
            <a
              (click)="sendEvaluationEmail(user)"
              *ngIf="user.coach && user.evaluationResults?.length === 0"
            >
              <i class="fa fa-paper-plane-o"></i>&nbsp;&nbsp;
              {{ user.coachEvalSent ? "Resend" : "Send" }}
              Email
            </a>
            <span *ngIf="!user.coach">No Coach</span>
            <a
              [routerLink]="[
                '/evaluationResults',
                user.evaluationResults[0]?._id
              ]"
              *ngIf="user.evaluationResults.length > 0"
              >View Results</a
            >
          </td>
        </tr>
        <tr>
          <td><b>Survey Results</b></td>
          <td>
            <a [routerLink]="['/users', user._id, 'surveyResults']"
              ><i class="fa fa-eye"></i>&nbsp;&nbsp;View Results</a
            >
          </td>
        </tr>
        <tr>
          <td><b>Skills Inventory Results</b></td>
          <td>
            <a [routerLink]="['/users', user._id, 'skillsInventoryResults']"
              ><i class="fa fa-shield"></i>&nbsp;&nbsp;View Results</a
            >
          </td>
        </tr>
        <tr>
          <td><b>Personal Values Inventory Results</b></td>
          <td>
            <a [routerLink]="['/users', user._id, 'pviResults']"
              ><i class="fa fa-bolt"></i>&nbsp;&nbsp;View Results</a
            >
          </td>
        </tr>
        <tr>
          <td><b>My Passion Profile Results</b></td>
          <td>
            <a [routerLink]="['/users', user._id, 'mppResults']"
              ><i class="fa fa-puzzle-piece"></i>&nbsp;&nbsp;View Results</a
            >
          </td>
        </tr>
        <tr>
          <td><b>View Files</b></td>
          <td>
            <a [routerLink]="['/users', user._id, 'files']"
              ><i class="fa fa-file-archive-o"></i>&nbsp;&nbsp;View Files</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="panel-footer flex flex-vertical-center">
    <div class="font-semi-bold">Created On {{ user.createdAt | date }}</div>
    <div class="flex-grow"></div>
    <div *ngIf="currentViewingRole !== 'coach'">
      <a
        style="margin-right: 3px"
        id="nexleader-test-user-edit"
        class="btn btn-success"
        (click)="open(user._id)"
      >
        Edit
      </a>
      <button
        [attr.disabled]="
          user.roles?.indexOf('coach') > -1 || user.completedIpsat ? true : null
        "
        class="btn btn-danger"
        (click)="deleteUser()"
      >
        Delete
      </button>
    </div>
  </div>
</div>
