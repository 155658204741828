/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, ErrorHandler, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../services/enum.service';
import { SuccessService } from '../../../../../services/success.service';
import { UserService } from '../../../../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, of, tap } from 'rxjs';
import { NexleaderViewEcourseVideoModalComponent } from '../../../../ecourse/components/view-ecourse-video-modal/view-ecourse-video-modal.component';
import { NexleaderEcourseRaliVideoComponent } from '../../../../ecourse/components/ecourse-rali-video/ecourse-rali-video.component';
import { environment } from '../../../../../../environments/environment';
import { NexleaderOnboardingPersonalityComponent } from '../../onboarding/onboarding-preassessments/onboarding-personality/onboarding-personality.component';
import { NexleaderPreIpsatChecklistComponent } from '../../onboarding/onboarding-preassessments/pre-ipsat-checklist/pre-ipsat-checklist.component';

@Component({
  selector: 'app-nexleader-ecourse-module-two',
  standalone: true,
  imports: [CommonModule, NexleaderEcourseRaliVideoComponent, NexleaderPreIpsatChecklistComponent, NexleaderOnboardingPersonalityComponent],
  templateUrl: './ecourse-module-two.component.html'
})
export class NexleaderEcourseModuleTwoComponent implements OnInit {
  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process.
   */
  @Input() user: any;

  /**
   * handleNextStep
   *
   * Allows data that is edited on this ecourse module to be saved.
   */
  @Output() handleNextStep = new EventEmitter<void>();

  /**
   * moduleSteps
   * 
   * Controls the steps the user can move between for the ecourse module.
   */
  moduleSteps = ['engagement', 'assessment'];

  /**
   * currentStep
   * 
   * Determines the current step the user is on in the ecourse module.
   */
  currentStep = this.moduleSteps[0];
  currentPrerequisite: any;

  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private successService: SuccessService,
    private errorHandler: ErrorHandler,
    private enumsService: EnumsService
  ) { }

  ngOnInit() {
    this.load();

    // Automatically move the user to the engagement step if they've already completed the MBTI
    if (this.user?.prerequisites?.completedMbti) {
      this.nextStep();
    }
  }

  /**
   * load()
   * 
   * Loads all required enums for the module.
   */
  load() {
    this.enumsService.getEnums().pipe(
      tap((enums) => {
        this.currentPrerequisite = enums.Prerequisites.MBTI;
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  /**
   * nextStep()
   * 
   * Handles moving the user to the next step (WITHIN THE MODULE).
   *  Calls the handleNextStep() function if the user is on the last step.
   *  ie: moving from learning_assessment to engagement
   */
  nextStep() {
    const currentStepIndex = this.moduleSteps.indexOf(this.currentStep);
    if (currentStepIndex < this.moduleSteps.length - 1) {
      this.currentStep = this.moduleSteps[currentStepIndex + 1];
    } else {
      this.handleNextStep.emit();
    }
  }

  /**
   * mapContinueButtonTextForStep()
   * 
   * Maps text for the continue buttons given the current step of the module the user
   *  is on.
   */
  mapContinueButtonTextForStep() {
    switch (this.currentStep) {
      case 'engagement':
        return 'Continue to 16 Personalities Assessment';
      default:
        return null;
    }
  }

  /**
   * save()
   * 
   * Saves a user. To be called when an MBTI option is selected.
   */
  save(): void {
    this.userService
      .saveUser(this.user)
      .pipe(
        tap((_) => {
          this.successService.handle();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * canProceedToNextStep()
   * 
   * Determines if the user is able to continue to the next module of the ecourse.
   */
  canProceedToNextStep() {
    return this.user
      && this.user.prerequisites
      && this.user.prerequisites.completedMbti
      && this.user.prerequisites.mbti
      && this.user.prerequisites.mbti.name;
  }

  /**
   * reviewModuleOneVideo()
   * 
   * Opens a vimeo video modal that allows the user to rewatch the module 1 video.
   */
  reviewModuleOneVideo() {
    const modalRef = this.modalService.show(NexleaderViewEcourseVideoModalComponent, {
      initialState: {
        title: 'Module 1',
        index: 1
      }
    });

    modalRef.content?.onClose.subscribe(() => {
      modalRef.hide();
    });
  }
}
