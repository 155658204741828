<div class="row">
  <div class="col-md-12">
    <app-nexleader-yes-no-buttons
      id="nexleader-test-yes-no-strengths"
      [(model)]="user.prerequisites.completedStrengths"
      (onChange)="save.emit()"
    ></app-nexleader-yes-no-buttons>
  </div>

  <div
    *ngIf="user.hasPurchasedCliftonStrengthsAssessment"
    class="col-md-12 text-center"
  >
    <hr />
    <h4>Your Clifton Strengths Discount Code</h4>
    <p>
      When you purchased the IPSAT, you also purchased a license to the Clifton
      Strengths Assessment.<br />To access your license, use the following code:
    </p>
    <h5 class="m-t-md" *ngIf="cliftonStrengthsCode">
      {{ cliftonStrengthsCode }}
    </h5>
    <p class="m-t-md" *ngIf="!cliftonStrengthsCode">
      Please contact
      <a href="mailto:Angeline.Patrick@nexleader.com"
        >Angeline.Patrick&#64;nexleader.com</a
      >
      for your code.
    </p>
    <hr *ngIf="user.prerequisites.completedStrengths" />
  </div>

  <div
    class="col-md-12"
    *ngIf="user.prerequisites.completedStrengths === false"
  >
    <hr />
    <b>Estimated time to complete: 15-20 minutes</b>
    <p
      class="m-t-md m-b-md"
      *ngIf="user.onboardingExperience.identifier !== 'ecourse'"
    >
      Since you haven’t taken Clifton Strengths Assessment, click on the "Take
      the Clifton Strengths Assessment" button below to begin the assessment.
      The page will open in a new tab on your browser.
      <br /><br />
      The cost of the Clifton Strengths Assessment assessment is $19.99. Because
      over 18 million people have taken this assessment, we've chosen not to
      include this fee in the cost of the IPSAT.
      <br /><br />
      Once you have completed the assessment, click "I Completed the Clifton
      Strengths Assessment" and save your results.
    </p>
    <p
      class="m-t-md m-b-md"
      *ngIf="user.onboardingExperience.identifier === 'ecourse'"
    >
      Your purchase of this online course includes the fees for all pre-IPSAT
      assessments, including Clifton Strengths (formerly Strengthsfinder 2.0).
      If you have already completed Clifton Strengths, you don’t need to do it
      again.
      <br /><br />
      <b>Clifton Strengths Access Code: {{ cliftonStrengthsCode }}</b>
    </p>
    <a
      class="btn btn-default"
      (click)="onTakeCliftonStrengthsClick()"
      target="_blank"
      >Take Clifton Strengths Assessment</a
    >
    <a
      class="btn btn-success btn-block m-t-md"
      (click)="user.prerequisites.completedStrengths = true"
      >I Completed the Clifton Strengths Assessment</a
    >
  </div>

  <div class="col-md-12" *ngIf="user.prerequisites.completedStrengths">
    <p class="m-t-md m-b-lg">
      Since you've taken the Clifton Strengths Assessment from Gallup, enter
      your top five strengths below. If you have your report, you can use the
      file uploader tool to save it within the IPSAT. If you don't have your
      report, or have problems uploading a file, just move on to the next step.
    </p>
    <div class="col-xl-6">
      <b>Your Strengths</b>
      <div
        class="form-group"
        *ngFor="let i of [0, 1, 2, 3, 4]; let index = index"
      >
        <select
          type="text"
          class="form-control m-t-sm"
          [(ngModel)]="user.prerequisites.strengths[index]"
          placeholder="Strength"
          [compareWith]="compareStregths"
        >
          <option
            *ngFor="let strength of allStrengths; trackBy: trackByName"
            [ngValue]="strength"
          >
            {{ strength.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-xl-6">
      <app-nexleader-profile-wizard-upload-file
        *ngIf="user && files && prerequisite"
        [user]="user"
        [prerequisite]="prerequisite"
        [allFiles]="files"
        (load)="loadFiles()"
        [isOptional]="true"
      ></app-nexleader-profile-wizard-upload-file>
    </div>
  </div>
</div>
