/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class RaliService {
  constructor(private http: HttpClient) {}

  get(referrerUrl: string): Observable<any> {
    let params = new HttpParams();
    if (referrerUrl) {
      params = params.append('referrerUrl', referrerUrl);
    }
    return this.http.get(`${API_BASE}rali/token`, {
      params,
      withCredentials: true,
    });
  }

  checkAccess(): Observable<any> {
    return this.http.get(`${API_BASE}rali/checkAccess`, {
      withCredentials: true,
    });
  }
}
