/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SuccessService {
  constructor(private toastr: ToastrService) {}

  handle(e?: { message: string }): void {
    this.toastr.success(e?.message || 'Saved.', '', {
      closeButton: false,
      timeOut: 750,
      extendedTimeOut: 1000,
      progressBar: false,
      positionClass: 'toast-top-right',
      tapToDismiss: true,
      newestOnTop: false,
    });
  }
}
