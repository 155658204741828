<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <label class="control-label">Select an email to edit</label>
      <select class="form-control" [(ngModel)]="selectedEmailType">
        <option
          *ngFor="let emailType of emailTypes | filter : 'scope' : emailScope"
          [ngValue]="emailType"
        >
          {{ emailType.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-9">
    <div *ngIf="selectedEmailType">
      <div
        *ngFor="
          let email of emails | filter : 'type.index' : selectedEmailType.index;
          let index = index
        "
        class="m-b-md"
      >
        <button class="btn btn-primary" (click)="addWordText()">
          Scrub Text
        </button>
        <button
          *ngIf="index > 0"
          (click)="deleteEmail(email)"
          class="btn btn-danger"
        >
          Delete
        </button>
        <br />
        <br />
        <div class="form-group">
          <label class="control-label">Email Subject</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="email.subject"
            [ngModelDebounce]="1000"
            (ngModelDebounceChange)="saveEmail(email)"
          />
        </div>
        <angular-editor
          [(ngModel)]="email.body"
          (ngModelChange)="emailTextUpdated(email)"
          [config]="config"
          [ngModelDebounce]="1000"
          (ngModelDebounceChange)="saveEmail(email)"
        ></angular-editor>
      </div>
    </div>
  </div>
</div>
