<h4 class="font-bold m-b-md text-primary hideOnPrint">
  Four Domains of Passionate Engagement
</h4>
<p class="font-bold hideNonPrint m-b-md">
  Four Domains of Passionate Engagement
</p>
<div class="row">
  <div
    class="col-6-print col-sm-6 col-xs-12 nexleader-team-report-domain-section"
    *ngFor="let domain of domainsOfPassion"
  >
    <div class="hpanel" [ngClass]="{ hyellow: domain.hasUsers }">
      <div class="panel-body">
        <h4 class="font-bold text-primary-2 m-b-sm">{{ domain.name }}</h4>
        <div class="row">
          <div class="col-xs-12" *ngIf="!domain.hasUsers">
            No passions represented for this domain.
          </div>
          <ng-container *ngFor="let passion of domain.passions">
            <div
              class="col-sm-6 col-xs-12 m-b-xs"
              *ngIf="passion.users.length > 0"
            >
              <span class="cursor-pointer" (click)="viewUsers(passion)"
                >{{ passion.name }} ({{ passion.users.length }})
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<a (click)="viewTeamReportDomainsInformation()" class="hideOnPrint"
  >Learn more about the four domains of engagement.</a
>
