import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  getUser(user_id: string): Observable<any> {
    return this.http.get(`${API_BASE}users/${user_id}`, {
      withCredentials: true,
    });
  }

  saveUser(user: any): Observable<any> {
    return this.http.post(`${API_BASE}users/${user._id}`, user, {
      withCredentials: true,
    });
  }

  delete(user_id: string): Observable<any> {
    return this.http.delete(`${API_BASE}users/${user_id}`, {
      withCredentials: true,
    });
  }

  queryIndex(queryParams?: { [key: string]: any }): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}users/index`, {
      withCredentials: true,
      params: queryParams
    });
  }

  queryGroup(group_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}groups/${group_id}/users`, {
      withCredentials: true,
    });
  }

  queryGroupRole(group_id: string, role_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}groups/${group_id}/users/${role_id}`,
      {
        withCredentials: true,
      }
    );
  }

  queryGroupRoleIndex(group_id: string, role_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}groups/${group_id}/users/${role_id}/index`,
      {
        withCredentials: true,
      }
    );
  }

  queryGroupCanLeadTeamReportIndex(group_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}groups/${group_id}/users/canLeadTeamReport/index`,
      {
        withCredentials: true,
      }
    );
  }

  queryGroupEligibleForTeamReport(group_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}groups/${group_id}/users/teamReportEligibility`,
      {
        withCredentials: true,
      }
    );
  }

  queryCoachUser(coach_user_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}coachUsers/${coach_user_id}/users`,
      {
        withCredentials: true,
      }
    );
  }

  saveGroup(group_id: string, user_id: string, user: any): Observable<any> {
    return this.http.post(
      `${API_BASE}groups/${group_id}/users/${user_id}`,
      user,
      {
        withCredentials: true,
      }
    );
  }

  queryParticipants(user_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}users/${user_id}/participants`, {
      withCredentials: true,
    });
  }

  queryLicensingPartner(licensing_partner_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}licensingPartners/${licensing_partner_id}/users`,
      {
        withCredentials: true,
      }
    );
  }

  queryLicensingPartnerIndex(licensing_partner_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}licensingPartners/${licensing_partner_id}/users/index`,
      {
        withCredentials: true,
      }
    );
  }

  queryCoachUserParticipants(coach_user_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}coachUsers/${coach_user_id}/participants`,
      {
        withCredentials: true,
      }
    );
  }

  queryFiles(user_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}users/${user_id}/files`, {
      withCredentials: true,
    });
  }

  moveToLegacy(user_id: string): Observable<any> {
    return this.http.post(
      `${API_BASE}users/${user_id}/moveToLegacy`,
      {},
      {
        withCredentials: true,
      }
    );
  }

  exportToCSV(): Observable<any> {
    return this.http.get(`${API_BASE}users/exportToCSV`);
  }

  markAsTeamLeader(user_id: string): Observable<any> {
    return this.http.post(
      `${API_BASE}users/${user_id}/markAsTeamLeader`,
      {},
      {
        withCredentials: true,
      }
    );
  }

  queryTeamReport(user_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}users/${user_id}/teamReports`, {
      withCredentials: true,
    });
  }

  querySmallGroups(user_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}users/${user_id}/smallGroups`, {
      withCredentials: true,
    });
  }

  querySmallGroupsIndex(user_id: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}users/${user_id}/smallGroups/index`,
      {
        withCredentials: true,
      }
    );
  }

  queryModuleSessions(user_id: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}users/${user_id}/moduleSessions`, {
      withCredentials: true,
    });
  }

  queryCoach(user_id: string): Observable<any> {
    return this.http.get<any>(`${API_BASE}users/${user_id}/coach`, {
      withCredentials: true,
    });
  }

  sendCoachEvaluationEmail(user_id: string): Observable<any> {
    return this.http.post(
      `${API_BASE}users/${user_id}/sendCoachEvaluationEmail`,
      {},
      {
        withCredentials: true,
        responseType: 'text',
      }
    );
  }
}
