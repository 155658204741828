<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="hpanel">
          <div class="panel-body">
            <h4>Get Started with Team Reports</h4>
            <hr class="m-t-md m-b-md" />
            <p class="m-b-md">
              The IPSAT helps individuals answer the question,
              <b>"Who am I?"</b> The IPSAT Team Report helps groups answer the
              question, <b>"Who are we?"</b> <br /><br />High performing teams
              understand how each individual best contributes to the whole and
              how the collective identity of the team best contributes to the
              mission.
            </p>
            <p class="font-bold m-b-sm">
              Move your team members from "me" to "we" with an IPSAT Team Report
              in four simple steps:
            </p>
            <ol class="m-b-sm">
              <li class="m-b-xs">Give your team a name.</li>
              <li class="m-b-xs">Select the IPSAT users on your team.*</li>
              <li class="m-b-xs">
                Create an Ideal Skill Set Profile. <br />One aspect of the Team
                Report compares the self-assessed skills of your team members
                against an "ideal skill set profile" you create that identifies
                up to 10 skills you believe your team needs to thrive. It is
                recommended that you seek input from your team, using the list
                of skills from the IPSAT skills inventory, before creating the
                Ideal Skill Set Profile.
              </li>
              <li
                class="m-b-xs"
                id="nexleader-test-team-report-purchase-cost-message"
              >
                Purchase your Team Report.
                <span *ngIf="group_id">
                  The cost of a team report for groups with whom we have signed
                  a licensing agreement is
                  <app-nexleader-team-report-cost
                    [groupId]="group_id"
                    [costType]="'perUser'"
                  ></app-nexleader-team-report-cost>
                  per team member, with a minimum of
                  <app-nexleader-team-report-cost
                    [groupId]="group_id"
                    [costType]="'min'"
                  ></app-nexleader-team-report-cost>
                  and a maximum of
                  <app-nexleader-team-report-cost
                    [groupId]="group_id"
                    [costType]="'max'"
                  ></app-nexleader-team-report-cost>
                  .
                </span>
                <span *ngIf="!group_id">
                  The cost of a team report is determined by the group you
                  select.
                </span>
              </li>
            </ol>
            <p class="small text-muted m-b-md">
              *To be eligible for inclusion on a Team Report, users MUST have
              completed the IPSAT and their coaching session.
            </p>
            <a (click)="viewMoreInformation()"
              >View
              {{ viewingMoreInformation ? "less" : "more" }} information</a
            >
            <div *ngIf="viewingMoreInformation" class="m-t-md">
              <p class="font-bold m-b-sm">
                Your IPSAT Team Report will include:
              </p>
              <ul class="m-b-md">
                <li class="m-b-xs">
                  Team IPSAT score and stage of identity self-awareness
                </li>
                <li class="m-b-xs">Team MBTI profile</li>
                <li class="m-b-xs">
                  Team Strengths as distributed across four domains as
                  identified by Gallup: Executing, Influencing, Strategic
                  Thinking, and Relationship Building
                </li>
                <li class="m-b-xs">
                  Team Skills - contrasting your "ideal skill set profile" with
                  self-assessed skills of team members
                </li>
                <li class="m-b-xs">
                  Team Spiritual Gifts profile as reflected by the corporate
                  functions of spiritual gifts - Word, Love and Power
                </li>
                <li class="m-b-xs">
                  Team Passion profile - including the preferred domain of
                  engagement for team members with shared passions (Service
                  Domain, Justice Domain, Discovery Domain, Advocacy Domain)
                </li>
                <li class="m-b-xs">Questions for Discussion and Application</li>
                <li class="m-b-xs">
                  Collated identity statements from individual IPSAT reports
                  (overview statements, best contribution statements,
                  developmental priority statements, potential derailer
                  statements, vulnerable settings statements)
                </li>
              </ul>
            </div>
            <div class="m-t-md text-center" *ngIf="!shouldShowGroupDropdown()">
              <a
                style="margin-right: 3px"
                [routerLink]="['/groups', group_id, 'teamReports', 'create']"
                [attr.disabled]="!group_id ? true : null"
                class="btn btn-success"
                >Create a new Team Report</a
              >
              <a
                style="margin-right: 3px"
                [href]="'/assets/files/IPSAT_Sample_Team_Report.pdf'"
                class="btn btn-default"
                >View Sample</a
              >
              <a
                [href]="
                  '/assets/files/IPSAT_Team_Report_Getting_Started.pdf'
                "
                class="btn btn-default"
                >Team Report Checklist</a
              >
            </div>
            <div class="row" *ngIf="shouldShowGroupDropdown()">
              <div class="col-md-6">
                <!-- (ngModelChange)="onChange()" -->
                <select class="form-control" [(ngModel)]="group_id">
                  <option value="undefined">-- Select --</option>
                  <option *ngFor="let option of groups" [value]="option._id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 text-center">
                <a
                  style="margin-right: 3px"
                  [routerLink]="['/groups', group_id, 'teamReports', 'create']"
                  [attr.disabled]="!group_id ? true : null"
                  class="btn btn-success"
                  >Create a new Team Report</a
                >
              </div>
              <div class="col-md-6 text-center">
                <a
                  style="margin-right: 3px"
                  [href]="'/assets/files/IPSAT_Sample_Team_Report.pdf'"
                  class="btn btn-default"
                  >View Sample</a
                >
              </div>
              <div class="col-md-6 text-center">
                <a
                  [href]="
                    '/assets/files/IPSAT_Team_Report_Getting_Started.pdf'
                  "
                  class="btn btn-default"
                  >Team Report Checklist</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div *ngFor="let teamReport of teamReports">
          <div class="hpanel">
            <div class="panel-body">
              <div class="flex flex-vertical-center">
                <i class="fa fa-2x fa-flag m-r-md"></i>
                <h4 class="nexleader-test-team-report-list-view-name">
                  {{ teamReport.teamName }}
                </h4>
              </div>
              <div class="m-t-md m-b-md">
                <hr />
              </div>
              <div>
                <p class="m-b-sm">
                  <span class="font-bold text-primary-2">Team Leader:</span>
                  {{ teamReport.teamLeader.firstName }}
                  {{ teamReport.teamLeader.lastName }}
                </p>
                <p class="font-bold text-primary-2">Team Members</p>
                <ul>
                  <li *ngFor="let user of teamReport.teamUsers">
                    {{ user.firstName }} {{ user.lastName }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="panel-footer flex flex-vertical-center">
              <i class="fa fa-calendar"></i>&nbsp;&nbsp;Created
              {{ teamReport.createdAt | date }}
              <div class="flex-grow"></div>
              <div class="pull-right">
                <a
                  [routerLink]="['/groups', teamReport.group._id, 'teamReports', teamReport._id]"
                  class="btn btn-info btn-sm"
                  id="nexleader-test-team-report-list-view-report-btn-{{
                    teamReport._id
                  }}"
                >
                  <i class="fa fa-eye"></i>&nbsp;&nbsp;View report
                </a>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
