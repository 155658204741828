<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="hpanel">
      <div class="panel-heading">
        <h3>Tasks</h3>
      </div>
      <div class="panel-body">
        <p>
          <b>Do not touch. Development only.</b>
        </p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Task</th>
              <th>Recurrence</th>
              <th>Run</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let task of tasks">
              <td>{{ task.name }}</td>
              <td>{{ task.recurrenceDescription }}</td>
              <td>
                <button
                  class="btn btn-default"
                  [attr.disabled]="!task.enabled ? true : null"
                  (click)="runTask(task)"
                >
                  Run
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
