/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderChartPersonalValuesSpider
 *
 * angular component
 *
 * This component is responsible for displaying a spider chart showing how all of the values for the team fall across
 *  all four of the domains. It renders a radar chart using chart.js to display this.
 */

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart } from 'chart.js';
import { PVI_BAR_CHART_COLORS } from '../../../../../../constants';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-chart-personal-values-spider',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './chart-personal-values-spider.component.html',
})
export class NexleaderChartPersonalValuesSpiderComponent implements OnInit, OnDestroy {
  /**
   * model - representedPersonalValues
   *
   */
  @Input() model: any;

  /**
   * hideValueSetSelect
   *
   * boolean
   *
   * Determines whether or not we give the ability for the user to change the value set (ie "topTwelveValues"
   *  vs "topSevenValues"). Useful for printing when we want to hide this UI.
   */
  @Input() hideValueSetSelect: boolean = false;

  /**
   * defaultSelection
   *
   * string
   *
   * Determines what the default selection is for the the graph.
   */
  @Input() defaultSelection: string = '';

  private chart!: Chart;
  private labels: string[] = [];
  selectedDataSetName: string = '';
  selectedDataSet: any[] = [];
  private ctx!: HTMLCanvasElement;

  constructor(private elementRef: ElementRef) { }

  /**
   * $onInit()
   *
   * angular event handler
   *
   * Runs when the component inits.
   */
  ngOnInit() {
    //Validate that there's a model on here
    if (!this.model || typeof this.model !== 'object') {
      throw new Error('Object model is required.');
    }

    // The labels for the chart are just the names of the domains
    // Use the topTwelveValues because this will have all the domains inside
    this.labels = this.model.topTwelveValues.map((domain: any) => domain.name);

    // Initialize the currently selected dataset to be the top twelve values
    this.selectedDataSetName = this.defaultSelection || 'topTwelveValues';

    this.selectDataSet(this.selectedDataSetName);
  }

  /**
   * drawSpiderChart()
   *
   * function
   *
   * Draws the spider chart represented for each domain chart. This utilizes the $ctrl.selectedDataSet to
   *  correctly draw the chart so we are drawing it with the proper values.
   */
  drawSpiderChart() {
    // We want to order the graph in a clockwise way starting with others at the top and ending with vocation on the left
    // We define this array so we can have a basepoint in which we use to then sort our array
    const orderedArrayOfDomains = ['Others', 'Avocation', 'Self', 'Vocation'];

    // Get our canvas element from the html template
    const wrapper = this.elementRef.nativeElement.querySelector(
      '.nexleader-chart-personal-values-spider'
    );
    wrapper.style.width = '100%';
    this.ctx = document.getElementById(
      'personal-value-spider-graph'
    ) as HTMLCanvasElement;

    // Slight hack, but allows us to update the values in the chart without having to entirely rerender the page
    // This allows us to destroy the chart and then we are able to redraw it when we initialize it below
    if (this.chart) {
      this.chart.destroy();
    }

    const data = this.selectedDataSet
      .map((domain: any) => {
        // If there are no values for the domain, return a 0 to not show the chart
        if (domain.values.length < 1) return 1;
        // If there is only one value for the domain, return immediately
        if (domain.values.length === 1) return domain.values[0].rating;
        return domain.values.reduce((a: any, b: any) =>
          // if this is the first element, there will be an a.rating, otherwise it won't exist
          a.rating ? a.rating + b.rating : a + b.rating
        );
      })
      .map((val: any) => Math.round(val));

    const orderedData = [data[1], data[3], data[0], data[2]];

    this.chart = new Chart(this.ctx, {
      type: 'radar',
      data: {
        labels: orderedArrayOfDomains,
        datasets: [
          {
            data: orderedData,
            backgroundColor: 'rgba(57,132,234,.2)',
            borderColor: 'rgb(57,132,234)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          r: {
            pointLabels: {
              color: '#208278',
              // color: PVI_BAR_CHART_COLORS, // no option to pass array
              font: {
                size: 12,
              },
            },
            min: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  /**
   * selectDataSet()
   *
   * function
   *
   * Selects a data set of the personal values to view.
   *
   * @param {string} dataset - A dataset selection either topTwelveValues, topSevenValues, or topThreeValues
   */
  selectDataSet(dataset: string) {
    this.selectedDataSet = this.model[dataset];
    this.drawSpiderChart();
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
