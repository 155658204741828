import { Routes } from '@angular/router';
import { NexleaderLoginComponent } from './auth/login/login.component';
import { NexleaderCoachUserSignupComponent } from './features/multigroup-coaching/views/coach-user-signup/coach-user-signup.component';
import { DefaultRedirectResolver } from './resolvers/default-redirect-resolver';
import { RedirectComponent } from './redirect.component';
import { NexleaderGroupsComponent } from './features/core/views/groups/groups.component';
import { NexleaderMultigroupCoachingDashboardComponent } from './features/multigroup-coaching/views/multigroup-coaching-dashboard/multigroup-coaching-dashboard.component';
import { NexleaderMultigroupCoachCalendarComponent } from './features/multigroup-coaching/views/multigroup-coach-calendar/multigroup-coach-calendar.component';
import { NexleaderMultigroupCoachingGroupsComponent } from './features/multigroup-coaching/views/multigroup-coaching-groups/multigroup-coaching-groups.component';
import { NexleaderFrozenComponent } from './features/core/views/frozen/frozen.component';
import { NexleaderDirectSignupComponent } from './features/direct/views/direct-signup/direct-signup.component';
import { NexleaderDirectLandingComponent } from './features/direct/views/direct-landing/direct-landing.component';
import { inject } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NexleaderEcourseLandingPricingComponent } from './features/ecourse/view/ecourse-landing-pricing/ecourse-landing-pricing.component';
import { NexleaderOnboardingComponent } from './features/onboarding/views/onboarding/onboarding.component';
import { NexleaderEcourseOnboardingComponent } from './features/onboarding/views/ecourse-onboarding/ecourse-onboarding.component';
import { NexleaderPasswordResetComponent } from './auth/password-reset/password-reset.component';
import { NexleaderForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { NexleaderBugComponent } from './features/static-pages/views/bug/bug.component';
import { NexleaderDashboardComponent } from './features/analytics/views/dashboard/dashboard.component';
import { NexleaderEditsComponent } from './features/core/views/edits/edits.component';
import { NexleaderEditSurveyComponent } from './features/core/views/edit-survey/edit-survey.component';
import { NexleaderEditEvaluationComponent } from './features/core/views/edit-evaluation/edit-evaluation.component';
import { NexleaderCourseContentAdminViewComponent } from './features/course-content/views/course-content-admin-view/course-content-admin-view.component';
import { NexleaderCourseContentParticipantViewComponent } from './features/course-content/views/course-content-participant-view/course-content-participant-view.component';
import { NexleaderOnboardingTakeIpsatComponent } from './features/onboarding/views/onboarding-take-ipsat/onboarding-take-ipsat.component';
import { NexleaderTakeSkillsInventoryComponent } from './features/skills-inventory/views/take-skills-inventory/take-skills-inventory.component';
import { NexleaderSkillsInventoryComponent } from './features/skills-inventory/views/skills-inventory/skills-inventory.component';
import { NexleaderSurveyComponent } from './features/core/views/survey/survey.component';
import { NexleaderEvaluationComponent } from './features/core/views/evaluation/evaluation.component';
import { NexleaderEvaluationResultComponent } from './features/core/views/evaluation-result/evaluation-result.component';
import { NexleaderCoachEvalThankYouComponent } from './features/core/views/coach-eval-thank-you/coach-eval-thank-you.component';
import { NexleaderPrereqsComponent } from './features/core/views/prereqs/prereqs.component';
import { NexleaderNoCoachComponent } from './features/core/views/no-coach/no-coach.component';
import { NexleaderMyCoachComponent } from './features/core/views/my-coach/my-coach.component';
import { NexleaderParticipantSmallGroupSessionsComponent } from './features/small-groups/views/participant-small-group-sessions/participant-small-group-sessions.component';
import { NexleaderFilesViewComponent } from './features/core/views/files-view/files-view.component';
import { NexleaderWrapperPanelComponent } from './features/core/components/wrapper-panel/wrapper-panel.component';
import { NexleaderAboutComponent } from './features/static-pages/views/about/about.component';
import { NexleaderOnboardingVideoWrapperComponent } from './features/onboarding/views/onboarding/onboarding-video-wrapper/onboarding-video-wrapper.component';
import { NexleaderCoachComponent } from './features/core/views/coach/coach.component';
import { NexleaderCoachCalendarComponent } from './features/sessions/views/coach-calendar/coach-calendar.component';
import { NexleaderCoachSmallGroupsComponent } from './features/small-groups/views/coach-small-groups/coach-small-groups.component';
import { NexleaderSmallGroupResourcesComponent } from './features/small-groups/views/small-group-resources/small-group-resources.component';
import { NexleaderSurveyResultsComponent } from './features/core/views/survey-results/survey-results.component';
import { NexleaderSkillsInventoryResultsComponent } from './features/skills-inventory/views/skills-inventory-results/skills-inventory-results.component';
import { NexleaderSurveyResultComponent } from './features/core/views/survey-result/survey-result.component';
import { NexleaderSkillsInventoryResultViewComponent } from './features/skills-inventory/views/skills-inventory-result-view/skills-inventory-result-view.component';
import { NexleaderSkillsInventoryResultViewFullScreenComponent } from './features/skills-inventory/views/skills-inventory-result-view-full-screen/skills-inventory-result-view-full-screen.component';
import { NexleaderPrintableSkillsInventoryResultComponent } from './features/core/views/printable-skills-inventory-result/printable-skills-inventory-result.component';
import { NexleaderPrintableResultComponent } from './features/core/views/printable-result/printable-result.component';
import { NexleaderShareableSurveyResultComponent } from './features/core/views/shareable-survey-result/shareable-survey-result.component';
import { NexleaderPrintableShareableSurveyResultComponent } from './features/core/views/printable-shareable-survey-result/printable-shareable-survey-result.component';
import { NexleaderUsersComponent } from './features/core/views/users/users.component';
import { NexleaderLicensingPartnerGroupsComponent } from './features/licensing-partners/views/licensing-partner-groups/licensing-partner-groups.component';
import { NexleaderDirectDiscountsComponent } from './features/direct/views/direct-discounts/direct-discounts.component';
import { NexleaderLicensingPartnerUsersComponent } from './features/licensing-partners/views/licensing-partner-users/licensing-partner-users.component';
import { NexleaderLicensingPartnerCalendarComponent } from './features/sessions/views/licensing-partner-calendar/licensing-partner-calendar.component';
import { NexleaderLicensingPartnerSessionListComponent } from './features/sessions/views/licensing-partner-session-list/licensing-partner-session-list.component';
import { NexleaderAdminCalendarComponent } from './features/sessions/views/admin-calendar/admin-calendar.component';
import { NexleaderAdminSessionListComponent } from './features/sessions/views/admin-session-list/admin-session-list.component';
import { NexleaderGroupSessionsListComponent } from './features/sessions/views/group-sessions-list/group-sessions-list.component';
import { NexleaderEditApplicationEmailsComponent } from './features/core/views/edit-application-emails/edit-application-emails.component';
import { NexleaderGroupComponent } from './features/core/views/group/group.component';
import { NexleaderGroupSettingsComponent } from './features/core/views/group-settings/group-settings.component';
import { NexleaderGroupSmallGroupsViewComponent } from './features/small-groups/views/group-small-groups-view/group-small-groups-view.component';
import { NexleaderTeamReportDiscountsComponent } from './features/team-report/views/team-report-discounts/team-report-discounts.component';
import { NexleaderTeamReportsComponent } from './features/team-report/views/team-reports/team-reports.component';
import { NexleaderCreateTeamReportComponent } from './features/team-report/views/create-team-report/create-team-report.component';
import { NexleaderTeamReportComponent } from './features/team-report/views/team-report/team-report.component';
import { NexleaderTeamReportCreationConfirmationComponent } from './features/team-report/views/team-report-creation-confirmation/team-report-creation-confirmation.component';
import { NexleaderPrintableTeamReportComponent } from './features/team-report/views/printable-team-report/printable-team-report.component';
import { NexleaderCoachesComponent } from './features/core/views/coaches/coaches.component';
import { NexleaderGroupUsersComponent } from './features/core/views/group-users/group-users.component';
import { NexleaderGroupInvitesComponent } from './features/core/views/group-invites/group-invites.component';
import { NexleaderStoreViewComponent } from './features/store/views/store-view/store-view.component';
import { NexleaderGroupCalendarComponent } from './features/sessions/views/group-calendar/group-calendar.component';
import { NexleaderInviteComponent } from './features/core/views/invite/invite.component';
import { NexleaderErrorComponent } from './features/core/views/error/error.component';
import { NexleaderTasksComponent } from './features/core/views/tasks/tasks.component';
import { NexleaderAccountComponent } from './features/core/views/account/account.component';
import { NexleaderIntegrationsComponent } from './features/integrations/views/integrations/integrations.component';
import { NexleaderLicensingPartnersComponent } from './features/licensing-partners/views/licensing-partners/licensing-partners.component';
import { NexleaderUserTeamReportsComponent } from './features/team-report/views/user-team-reports/user-team-reports.component';
import { NexleaderViewTakePVIComponent } from './features/personal-values-inventory/views/take-pvi/take-pvi.component';
import { NexleaderViewPviAssessmentComponent } from './features/personal-values-inventory/views/pvi-assessment/pvi-assessment.component';
import { NexleaderViewPviAssessmentResultComponent } from './features/personal-values-inventory/views/pvi-assessment-result/pvi-assessment-result.component';
import { NexleaderPviAssessmentResultFullScreenComponent } from './features/personal-values-inventory/views/pvi-assessment-result-full-screen/pvi-assessment-result-full-screen.component';
import { NexleaderViewPviAssessmentResultPrintableComponent } from './features/personal-values-inventory/views/pvi-assessment-result-printable/pvi-assessment-result-printable.component';
import { NexleaderViewMppResultsComponent } from './features/my-passion-profile/views/mpp-results/mpp-results.component';
import { NexleaderMppResultComponent } from './features/my-passion-profile/views/mpp-result/mpp-result.component';
import { NexleaderMppResultPrintableComponent } from './features/my-passion-profile/views/mpp-result-printable/mpp-result-printable.component';
import { NexleaderViewPviAssessmentResultsComponent } from './features/personal-values-inventory/views/pvi-assessment-results/pvi-assessment-results.component';
import { NexleaderEcourseContentComponent } from './features/core/views/ecourse-content/ecourse-content.component';
import { GroupUserCoachEvaluationRedirectResolver } from './resolvers/group-user-coach-evaluation-redirectc-resolver';
import { NexleaderMultigroupCoachListComponent } from './features/multigroup-coaching/views/multigroup-coach-list/multigroup-coach-list.component';
import { NexleaderEcourseLandingComponent } from './features/ecourse/view/ecourse-landing/ecourse-landing.component';
import { NexleaderRaliFrameComponent } from './features/core/components/rali-frame/rali-frame.component';
import { EnforceNoBackGuard } from './guards/enforce-no-back.guard';

export const routes: Routes = [
    {
        path: '',
        resolve: {
            redirect: DefaultRedirectResolver
        },
        pathMatch: 'full',
        component: RedirectComponent
    },
    {
        path: 'login',
        pathMatch: 'full',
        component: NexleaderLoginComponent,
        data: { hideNav: true } // Optionally, you can add metadata to the route
    },
    {
        path: 'coachUserOnboarding',
        pathMatch: 'full',
        component: NexleaderCoachUserSignupComponent,
        data: { hideNav: true } // Optionally, you can add metadata to the route
    },
    {
        path: 'multigroupCoaching/:coach_user_id/dashboard',
        pathMatch: 'full',
        component: NexleaderMultigroupCoachingDashboardComponent,
        data: {
            name: 'Dashboard',
            hiddenFromNav: true
        }
    },
    {
        path: 'multigroupCoaching/:coach_user_id/calendar',
        pathMatch: 'full',
        component: NexleaderMultigroupCoachCalendarComponent,
        data: {
            name: 'Your Calendar',
            hiddenFromNav: true
        }
    },
    {
        path: 'multigroupCoaching/:coach_user_id/groups',
        pathMatch: 'full',
        component: NexleaderMultigroupCoachingGroupsComponent,
        data: {
            name: 'Your Groups',
            hiddenFromNav: true
        }
    },
    {
        path: 'frozen',
        pathMatch: 'full',
        component: NexleaderFrozenComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'direct/signup',
        pathMatch: 'full',
        component: NexleaderDirectSignupComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'direct/landing/:user_id',
        pathMatch: 'full',
        component: NexleaderDirectLandingComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'direct/landing',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/direct/landing/' + payload.user;
            }
            return '/';
        }
    },
    {
        path: 'direct/ecourse',
        pathMatch: 'full',
        component: NexleaderEcourseLandingComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'direct/ecourse/pricing',
        pathMatch: 'full',
        component: NexleaderEcourseLandingPricingComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'users/:user_id/onboarding',
        pathMatch: 'full',
        component: NexleaderOnboardingComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'onboarding',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + payload.user + '/onboarding';
            }

            return '/';
        }
    },
    {
        path: 'users/:user_id/ecourse-onboarding',
        pathMatch: 'full',
        component: NexleaderEcourseOnboardingComponent,
        data: {
            hideNav: true
        }
    },

    {
        path: 'ecourse/onboarding',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + payload.user + '/ecourse-onboarding';
            }

            return '/';
        }
    },
    {
        path: 'passwordReset',
        pathMatch: 'full',
        component: NexleaderPasswordResetComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'passwordReset/:password_reset_id',
        pathMatch: 'full',
        component: NexleaderForgotPasswordComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'bug',
        pathMatch: 'full',
        component: NexleaderBugComponent
    },
    {
        path: 'adminDashboard',
        pathMatch: 'full',
        component: NexleaderDashboardComponent,
        data: {
            name: 'Admin Dashboard',
            icon: 'server',
            roles: ['Admin']
        }
    },
    {
        path: 'edits',
        pathMatch: 'full',
        component: NexleaderEditsComponent,
        data: {
            name: 'Edit Surveys',
            icon: 'pencil',
            roles: ['Admin']
        }
    },
    {
        path: 'edits/survey/:survey_id',
        pathMatch: 'full',
        component: NexleaderEditSurveyComponent,
        data: {
            roles: ['Admin']
        }
    },
    {
        path: 'edits/evaluation/:evaluation_id',
        pathMatch: 'full',
        component: NexleaderEditEvaluationComponent,
        data: {
            roles: ['Admin']
        }
    },
    {
        path: 'adminIpsatU',
        pathMatch: 'full',
        component: NexleaderCourseContentAdminViewComponent,
        data: {
            name: 'IPSAT U Admin',
            icon: 'graduation-cap',
            roles: ['Admin']
        }
    },
    {
        path: 'ipsatU',
        pathMatch: 'full',
        component: NexleaderCourseContentParticipantViewComponent,
        data: {
            name: 'IPSAT U',
            icon: 'graduation-cap',
            roles: ['Participant'],
            isNewRoute: true
        }
    },
    {
        path: 'users/:user_id/takeIpsat',
        pathMatch: 'full',
        component: NexleaderOnboardingTakeIpsatComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'takeSkillsInventory',
        pathMatch: 'full',
        data: {
            name: 'Skills Inventory',
            icon: 'shield',
            roles: ['Participant']
        },
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + (payload.user || payload.primary_user) + '/takeSkillsInventory';
            }

            return '/';
        }
    },
    {
        path: 'users/:user_id/takeSkillsInventory',
        pathMatch: 'full',
        component: NexleaderTakeSkillsInventoryComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'users/:user_id/skillsInventory',
        pathMatch: 'full',
        component: NexleaderSkillsInventoryComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'users/:user_id/surveys/:survey_id',
        pathMatch: 'full',
        component: NexleaderSurveyComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'groups/:group_id/users/:user_id/coaches/:coach_id/evaluations',
        pathMatch: 'full',
        resolve: {
            redirect: GroupUserCoachEvaluationRedirectResolver
        },
        component: RedirectComponent // Assuming this is the component for evaluation
    },
    {
        path: 'groups/:group_id/users/:user_id/coaches/:coach_id/evaluations/:evaluation_id',
        pathMatch: 'full',
        component: NexleaderEvaluationComponent,
        data: { hideNav: true }
    },
    {
        path: 'evaluationResults/:evaluation_result_id',
        pathMatch: 'full',
        component: NexleaderEvaluationResultComponent
    },
    {
        path: 'coachEvalThankYou',
        pathMatch: 'full',
        component: NexleaderCoachEvalThankYouComponent,
        data: { hideNav: true }
    },
    {
        path: 'editPrereqs',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                var user_id = (payload.user || payload.primary_user);
                var group_id = (payload.group || payload.primary_group);
                return '/groups/' + group_id + '/users/' + user_id + '/prereqs';
            }
            return '/';
        },
        data: {
            name: 'My Pre-IPSAT',
            icon: 'pencil',
            roles: ['Participant']
        }
    },
    {
        path: 'surveyResults',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                var user_id = (payload.user || payload.primary_user);
                return '/users/' + user_id + '/surveyResults';
            }
            return '/';
        },
        data: {
            name: 'My IPSAT Results',
            icon: 'files-o',
            roles: ['Participant']
        }
    },
    {
        path: 'skillsInventoryResults',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                var user_id = (payload.user || payload.primary_user);
                return '/users/' + user_id + '/skillsInventoryResults';
            }
            return '/';
        },
        data: {
            name: 'My Skills Report',
            icon: 'files-o',
            roles: ['Participant']
        }
    },
    {
        path: 'groups/:group_id/users/:user_id/prereqs',
        pathMatch: 'full',
        component: NexleaderPrereqsComponent,
        data: {
            name: 'My Pre-IPSAT Assessments',
            hiddenFromNav: true
        }
    },
    {
        path: 'mycoach',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload && payload.user) {
                return '/users/' + (payload.user || payload.primary_user) + '/mycoach';
            }
            return '/coaches/nocoach';
        },
        data: {
            name: 'My Coach',
            icon: 'briefcase',
            roles: ['Participant']
        }
    },
    {
        path: 'takeIpsat',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + (payload.user || payload.primary_user) + '/takeIpsat';
            }
            return '/';
        },
        data: {
            name: 'Take IPSAT',
            icon: 'diamond',
            roles: ['Participant']
        }
    },
    {
        path: 'coaches/nocoach',
        pathMatch: 'full',
        component: NexleaderNoCoachComponent
    },
    {
        path: 'users/:user_id/mycoach',
        pathMatch: 'full',
        component: NexleaderMyCoachComponent,
        data: {
            roles: ['Participant'],
            name: 'My Coach',
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/smallGroups',
        pathMatch: 'full',
        component: NexleaderParticipantSmallGroupSessionsComponent,
        data: {
            roles: ['Participant'],
            name: 'My Small Group',
            hiddenFromNav: true
        }
    },
    {
        path: 'participant/smallGroups',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload && payload.user) {
                return '/users/' + (payload.user || payload.primary_user) + '/smallGroups';
            }
            return '/';
        },
        data: {
            name: 'My Small Group',
            icon: 'users',
            roles: ['Participant']
        }
    },
    {
        path: 'files',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + (payload.user || payload.primary_user) + '/files';
            }
            return '/';
        },
        data: {
            roles: ['Participant']
        }
    },
    {
        path: 'users/:user_id/files',
        pathMatch: 'full',
        component: NexleaderFilesViewComponent
    },
    {
        path: 'coach',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/coaches/' + payload.user + '/admin';
            }
            return '/';
        },
        data: {
            name: 'Your Coaching',
            icon: 'user',
            roles: ['Coach']
        }
    },
    {
        path: 'calendar',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/coaches/' + payload.user + '/calendar';
            }
            return '/';
        },
        data: {
            name: 'Your Calendar',
            icon: 'calendar',
            roles: ['Coach']
        }
    },
    {
        path: 'coaches/:coach_id/sessions/:session_id',
        pathMatch: 'full',
        component: NexleaderWrapperPanelComponent
    },
    {
        path: 'about',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                var user_id = (payload.user || payload.primary_user);
                var group_id = (payload.group || payload.primary_group);
                return '/groups/' + group_id + '/users/' + user_id + '/about';
            }
            return '/';
        },
        data: {
            name: 'About',
            icon: 'question-circle',
            roles: ['Participant']
        }
    },
    {
        path: 'groups/:group_id/users/:user_id/about',
        pathMatch: 'full',
        component: NexleaderAboutComponent,
        data: {
            roles: ['Participant'],
            name: 'About the IPSAT',
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/video',
        pathMatch: 'full',
        component: NexleaderOnboardingVideoWrapperComponent,
        data: {
            roles: ['Participant'],
            hideNav: true
        }
    },
    {
        path: 'coaches/:user_id/admin',
        pathMatch: 'full',
        component: NexleaderCoachComponent,
        data: {
            roles: ['Group Admin', 'Coach'],
            name: 'Coaching Dashboard',
            hiddenFromNav: true
        }
    },
    {
        path: 'coaches/:coach_id/calendar',
        pathMatch: 'full',
        component: NexleaderCoachCalendarComponent,
        data: {
            name: 'Your Calendar',
            hiddenFromNav: true
            // reloadOnSearch: false,
        }
    },
    {
        path: 'smallGroups',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/coaches/' + (payload.user || payload.primary_user) + '/smallGroups';
            }
            return '/';
        },
        data: {
            name: 'Your Small Groups',
            icon: 'folder',
            roles: ['Coach', 'Multigroup Coach']
        }
    },
    {
        path: 'coaches/:user_id/smallGroups',
        pathMatch: 'full',
        component: NexleaderCoachSmallGroupsComponent,
        data: {
            name: 'Your Small Groups',
            hiddenFromNav: true
        }
    },
    {
        path: 'smallGroupResources',
        pathMatch: 'full',
        component: NexleaderSmallGroupResourcesComponent,
        data: {
            name: 'Small Group Resources',
            icon: 'file',
            roles: ['Coach'],
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/surveyResults',
        pathMatch: 'full',
        component: NexleaderSurveyResultsComponent,
        data: {
            name: 'IPSAT Results',
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/skillsInventoryResults',
        pathMatch: 'full',
        component: NexleaderSkillsInventoryResultsComponent,
        data: {
            name: 'Skills Inventory Results',
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/surveyResults/:survey_result_id',
        pathMatch: 'full',
        component: NexleaderSurveyResultComponent,
        data: {
            name: 'IPSAT Result',
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/skillsInventoryResults/:skills_inventory_result_id',
        pathMatch: 'full',
        component: NexleaderSkillsInventoryResultViewComponent,
        data: {
            name: 'Skills Inventory Result',
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/skillsInventoryResults/:skills_inventory_result_id/fullscreen',
        pathMatch: 'full',
        component: NexleaderSkillsInventoryResultViewFullScreenComponent,
        data: {
            name: 'Skills Inventory Result Fullscreen',
            hiddenFromNav: true,
            hideNav: true
        }
    },
    {
        path: 'users/:user_id/skillsInventoryResults/:skills_inventory_result_id/printable',
        pathMatch: 'full',
        component: NexleaderPrintableSkillsInventoryResultComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'users/:user_id/surveyResults/:survey_result_id/printable',
        pathMatch: 'full',
        component: NexleaderPrintableResultComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 's/:survey_result_id',
        pathMatch: 'full',
        component: NexleaderShareableSurveyResultComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 's/:survey_result_id/printable',
        pathMatch: 'full',
        component: NexleaderPrintableShareableSurveyResultComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'groups',
        pathMatch: 'full',
        component: NexleaderGroupsComponent, // Specify the component to be displayed
        data: {
            name: 'Groups', // Optional metadata for the route
            icon: 'sitemap',
            roles: ['Admin']
        }
    },
    {
        path: 'admin/users',
        pathMatch: 'full',
        component: NexleaderUsersComponent,
        data: {
            name: 'Users',
            icon: 'sitemap',
            roles: ['Admin']
        }
    },
    {
        path: 'licensingPartners/:licensing_partner_id/groups',
        pathMatch: 'full',
        component: NexleaderLicensingPartnerGroupsComponent,
        data: {
            name: 'Groups'
        }
    },
    {
        path: 'licensingPartners/:licensing_partner_id/directDiscounts',
        pathMatch: 'full',
        component: NexleaderDirectDiscountsComponent,
        data: {
            name: 'Direct Discounts'
        }
    },
    {
        path: 'licensingPartners/:licensing_partner_id/users',
        pathMatch: 'full',
        component: NexleaderLicensingPartnerUsersComponent,
        data: {
            name: 'Users'
        }
    },
    {
        path: 'licensingPartners/:licensing_partner_id/calendar',
        pathMatch: 'full',
        component: NexleaderLicensingPartnerCalendarComponent,
        data: {
            name: 'Calendar'
        }
    },
    {
        path: 'licensingPartners/:licensing_partner_id/sessionsTable',
        pathMatch: 'full',
        component: NexleaderLicensingPartnerSessionListComponent,
        data: {
            name: 'Sessions List'
        }
    },
    {
        path: 'licensingPartners/groups',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.licensing_partner) {
                    return '/licensingPartners/' + payload.licensing_partner + '/groups';
                } else if (payload.primary_group) {
                    return '/licensingPartners/' + payload.primary_licensing_partner + '/groups';
                } else {
                    console.warn('Improper access attempt to /licensingPartner/groups. Token has no licensing_partner or primary_licensing_partner.');
                }
            }
            return '/';
        },
        data: {
            name: 'Groups',
            icon: 'sitemap',
            roles: ['Partner Admin']
        }
    },
    {
        path: 'licensingPartners/directDiscounts',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.licensing_partner) {
                    return '/licensingPartners/' + payload.licensing_partner + '/directDiscounts';
                } else if (payload.primary_group) {
                    return '/licensingPartners/' + payload.primary_licensing_partner + '/directDiscounts';
                } else {
                    console.warn('Improper access attempt to /licensingPartner/directDiscounts. Token has no licensing_partner or primary_licensing_partner.');
                }
            }
            return '/';
        },
        data: {
            name: 'Direct Discounts',
            icon: 'flag',
            roles: ['Partner Admin', 'Admin']
        }
    },
    {
        path: 'licensingPartners/users',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.licensing_partner) {
                    return '/licensingPartners/' + payload.licensing_partner + '/users';
                } else if (payload.primary_group) {
                    return '/licensingPartners/' + payload.primary_licensing_partner + '/users';
                } else {
                    console.warn('Improper access attempt to /licensingPartner/users. Token has no licensing_partner or primary_licensing_partner.');
                }
            }
            return '/';
        },
        data: {
            name: 'Users',
            icon: 'users',
            roles: ['Partner Admin']
        }
    },
    {
        path: 'licensingPartners/calendar',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.licensing_partner) {
                    return '/licensingPartners/' + payload.licensing_partner + '/calendar';
                } else if (payload.primary_group) {
                    return '/licensingPartners/' + payload.primary_licensing_partner + '/calendar';
                } else {
                    console.warn('Improper access attempt to /licensingPartner/calendar. Token has no licensing_partner or primary_licensing_partner.');
                }
            }
            return '/';
        },
        data: {
            name: 'Calendar',
            icon: 'calendar',
            roles: ['Partner Admin']
        }
    },
    {
        path: 'licensingPartners/sessionsTable',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.licensing_partner) {
                    return '/licensingPartners/' + payload.licensing_partner + '/sessionsTable';
                } else if (payload.primary_group) {
                    return '/licensingPartners/' + payload.primary_licensing_partner + '/sessionsTable';
                } else {
                    console.warn('Improper access attempt to /licensingPartner/sessionsTable. Token has no licensing_partner or primary_licensing_partner.');
                }
            }
            return '/';
        },
        data: {
            name: 'Sessions List',
            icon: 'calendar',
            roles: ['Partner Admin']
        }
    },
    {
        path: 'sessions',
        pathMatch: 'full',
        component: NexleaderAdminCalendarComponent,
        data: {
            name: 'Admin Calendar',
            icon: 'calendar',
            roles: ['Admin']
        }
    },
    {
        path: 'sessionsTable',
        pathMatch: 'full',
        component: NexleaderAdminSessionListComponent,
        data: {
            name: 'Admin Session List',
            icon: 'calendar',
            roles: ['Admin']
        }
    },
    {
        path: 'group',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group;
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group;
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Dashboard',
            icon: 'tachometer',
            roles: ['Group Admin']
        }
    },
    {
        path: 'groupcalendar',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/calendar';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/calendar';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Calendar',
            icon: 'calendar',
            roles: ['Group Admin']
        }
    },
    {
        path: 'coaches',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/coaches';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/coaches';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Coaches',
            icon: 'briefcase',
            roles: ['Group Admin']
        }
    },
    {
        path: 'users',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/users';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/users';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Users',
            icon: 'users',
            roles: ['Group Admin']
        }
    },
    {
        path: 'invites',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/invites';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/invites';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Invites',
            icon: 'users',
            roles: ['Group Admin']
        }
    },
    {
        path: 'group/sessionsList',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/sessionsList';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/sessionsList';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Session List',
            icon: 'calendar',
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/:group_id/sessionsList',
        pathMatch: 'full',
        component: NexleaderGroupSessionsListComponent,
        data: {
            name: 'Group Session List',
            hiddenFromNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'store',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group && payload.user) {
                    return '/groups/' + payload.group + '/users/' + payload.user + '/store';
                } else if (payload.primary_group && payload.primary_user) {
                    return '/groups/' + payload.primary_group + '/users/' + payload.primary_user + '/store';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group or no primary_user. (or group and user)');
                }
            }
            return '/';
        },
        data: {
            name: 'Store',
            icon: 'shopping-cart',
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/:group_id/store',
        pathMatch: 'full',
        redirectTo: ({ params }) => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.user) {
                    return '/groups/' + params['group_id'] + '/users/' + payload.user + '/store';
                } else if (payload.primary_user) {
                    return '/groups/' + params['group_id'] + '/users/' + payload.primary_user + '/store';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group or no primary_user. (or group and user)');
                }
            }
            return '/';
        },
    },
    {
        path: 'group/smallGroups',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group && payload.user) {
                    return '/groups/' + payload.group + '/smallGroups';
                } else if (payload.primary_group && payload.primary_user) {
                    return '/groups/' + payload.primary_group + '/smallGroups';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group or no primary_user. (or group and user)');
                }
            }
            return '/';
        },
        data: {
            name: 'Small Groups',
            icon: 'sitemap',
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/settings',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/settings';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/settings';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Group Settings',
            icon: 'cog',
            roles: ['Group Admin']
        }
    },
    {
        path: 'group/teamReports',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/teamReports';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/teamReports';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'Team Reports',
            icon: 'flag',
            roles: ['Team']
        }
    },
    {
        path: 'coachUser/teamReports',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.coach_user) {
                    return '/coachUsers/' + payload.coach_user + '/teamReports';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no coach_user.');
                }
            }
            return '/';
        },
        data: {
            name: 'Team Reports',
            icon: 'flag',
            roles: ['Multigroup Coach']
        }
    },
    {
        path: 'group/teamReports/create',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.group) {
                    return '/groups/' + payload.group + '/teamReports/create';
                } else if (payload.primary_group) {
                    return '/groups/' + payload.primary_group + '/teamReports/create';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_group.');
                }
            }
            return '/';
        },
        data: {
            name: 'New Team Report',
            hiddenFromNav: true,
            icon: 'flag',
            roles: ['Group Admin']
        }
    },
    {
        path: 'settings',
        pathMatch: 'full',
        component: NexleaderEditApplicationEmailsComponent,
        data: {
            name: 'App Settings',
            icon: 'cog',
            roles: ['Admin']
        }
    },
    {
        path: 'groups/:group_id',
        pathMatch: 'full',
        component: NexleaderGroupComponent,
        data: {
            name: 'Group Dashboard',
            hiddenFromNav: true
        }
    },
    {
        path: 'groups/:group_id/settings',
        pathMatch: 'full',
        component: NexleaderGroupSettingsComponent,
        data: {
            name: 'Group Settings',
            hiddenFromNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/:group_id/smallGroups',
        pathMatch: 'full',
        component: NexleaderGroupSmallGroupsViewComponent,
        data: {
            name: 'Small Groups',
            hiddenFromNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'teamReportDiscounts',
        pathMatch: 'full',
        component: NexleaderTeamReportDiscountsComponent,
        data: {
            name: 'Discount Codes',
            icon: 'flag',
            roles: ['Admin']
        }
    },
    {
        path: 'groups/:group_id/teamReports',
        pathMatch: 'full',
        component: NexleaderTeamReportsComponent,
        data: {
            name: 'Team Reports',
            hiddenFromNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'coachUsers/:coach_user_id/teamReports',
        pathMatch: 'full',
        component: NexleaderTeamReportsComponent,
        data: {
            name: 'Team Reports',
            hiddenFromNav: true,
            roles: ['Multigroup Coach']
        }
    },
    {
        path: 'groups/:group_id/teamReports/create',
        pathMatch: 'full',
        component: NexleaderCreateTeamReportComponent,
        data: {
            name: 'New Team Report',
            hideNav: true,
            hiddenFromNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/:group_id/teamReports/:team_report_id',
        pathMatch: 'full',
        component: NexleaderTeamReportComponent,
        data: {
            name: 'Team Report',
            hiddenFromNav: true,
            hideNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/:group_id/teamReports/:team_report_id/confirm',
        pathMatch: 'full',
        component: NexleaderTeamReportCreationConfirmationComponent,
        data: {
            name: 'Team Report Creation Confirmation',
            hiddenFromNav: true,
            hideNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/:group_id/teamReports/:team_report_id/printable',
        pathMatch: 'full',
        component: NexleaderPrintableTeamReportComponent,
        data: {
            hiddenFromNav: true,
            hideNav: true,
            roles: ['Group Admin']
        }
    },
    {
        path: 'groups/:group_id/coaches',
        pathMatch: 'full',
        component: NexleaderCoachesComponent,
        data: {
            name: 'Group Coaches',
            hiddenFromNav: true
        }
    },
    {
        path: 'groups/:group_id/users',
        pathMatch: 'full',
        component: NexleaderGroupUsersComponent,
        data: {
            name: 'Group Users',
            hiddenFromNav: true
        }
    },
    {
        path: 'groups/:group_id/invites',
        pathMatch: 'full',
        component: NexleaderGroupInvitesComponent,
        data: {
            name: 'Group Invites',
            hiddenFromNav: true
        }
    },
    {
        path: 'groups/:group_id/users/:user_id/store',
        pathMatch: 'full',
        component: NexleaderStoreViewComponent,
        data: {
            name: 'Group Store',
            hiddenFromNav: true
        }
    },
    {
        path: 'groups/:group_id/calendar',
        pathMatch: 'full',
        component: NexleaderGroupCalendarComponent,
        data: {
            name: 'Group Calendar',
            hiddenFromNav: true
        }
    },
    {
        path: 'invites/:invite_id',
        pathMatch: 'full',
        component: NexleaderInviteComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'error',
        pathMatch: 'full',
        component: NexleaderErrorComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'tasks',
        pathMatch: 'full',
        component: NexleaderTasksComponent,
        data: {
            roles: ['Admin']
        }
    },
    {
        path: 'account',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/account/' + (payload.user || payload.primary_user);
            }
            return '/';
        }
    },
    {
        path: 'account/:user_id',
        pathMatch: 'full',
        component: NexleaderAccountComponent,
        data: {
            roles: ['Admin', 'Coach', 'Participant'],
            name: 'Account',
            hiddenFromNav: true
        }
    },
    {
        path: 'multigroupCoaching',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload && payload.coach_user) {
                return '/multigroupCoaching/' + payload.coach_user + '/dashboard';
            }
            return '/';
        },
        data: {
            name: 'Your Dashboard',
            icon: 'dashboard',
            roles: ['Multigroup Coach']
        }
    },
    {
        path: 'multigroupCoaching/calendar',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload && payload.coach_user) {
                return '/multigroupCoaching/' + payload.coach_user + '/calendar';
            }
            return '/';
        },
        data: {
            name: 'Your Calendar',
            icon: 'calendar',
            roles: ['Multigroup Coach']
        }
    },
    {
        path: 'multigroupCoaching/groups',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload && payload.coach_user) {
                return '/multigroupCoaching/' + payload.coach_user + '/groups';
            }
            return '/';
        },
        data: {
            name: 'Your Groups',
            icon: 'users',
            roles: ['Multigroup Coach']
        }
    },
    {
        path: 'integrations',
        pathMatch: 'full',
        component: NexleaderIntegrationsComponent,
        data: {
            name: 'Integrations',
            icon: 'briefcase',
            roles: ['Admin'],
        }
    },
    {
        path: 'multigroupCoaches',
        pathMatch: 'full',
        component: NexleaderMultigroupCoachListComponent,
        data: {
            name: 'Multigroup',
            icon: 'users',
            roles: ['Admin']
        }
    },
    {
        path: 'licensingPartners',
        pathMatch: 'full',
        component: NexleaderLicensingPartnersComponent,
        data: {
            name: 'Licensing Partners',
            icon: 'globe',
            roles: ['Admin']
        }
    },
    {
        path: 'userTeamReports',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                if (payload.user) {
                    return '/users/' + payload.user + '/teamReports';
                } else if (payload.primary_group) {
                    return '/users/' + payload.primary_user + '/teamReports';
                } else {
                    console.warn('Improper access attempt to /groups. Token has no group or primary_user.');
                }
            }
            return '/';
        },
        data: {
            name: 'Team Reports',
            icon: 'flag',
            roles: ['Participant']
        }
    },
    {
        path: 'users/:user_id/teamReports',
        pathMatch: 'full',
        component: NexleaderUserTeamReportsComponent,
        data: {
            hiddenFromNav: true,
            roles: ['Participant'],
            name: 'Your Team Reports'
        }
    },
    {
        path: 'users/:user_id/takePVI',
        pathMatch: 'full',
        component: NexleaderViewTakePVIComponent,
        data: {
            hideNav: true
        }
    },
    {
        path: 'takePVI',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + (payload.user || payload.primary_user) + '/takePVI';
            }
            return '/';
        },
        data: {
            roles: ['Participant'],
            icon: 'bolt',
            name: 'Take PVI'
        }
    },
    {
        path: 'users/:user_id/pviAssessment',
        pathMatch: 'full',
        canDeactivate: [EnforceNoBackGuard],
        component: NexleaderViewPviAssessmentComponent,
        data: {
            roles: ['Participant'],
            hideNav: true,
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/pvi/:pvi_result_id',
        pathMatch: 'full',
        component: NexleaderViewPviAssessmentResultComponent,
        data: {
            roles: ['Participant'],
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/pvi/:pvi_result_id/fullscreen',
        pathMatch: 'full',
        component: NexleaderPviAssessmentResultFullScreenComponent,
        data: {
            roles: ['Participant'],
            hiddenFromNav: true,
            hideNav: true
        }
    },
    {
        path: 'users/:user_id/pvi/:pvi_result_id/printable',
        pathMatch: 'full',
        component: NexleaderViewPviAssessmentResultPrintableComponent,
        data: {
            roles: ['Participant'],
            hiddenFromNav: true,
            hideNav: true
        }
    },
    {
        path: 'pviResults',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + (payload.user || payload.primary_user) + '/pviResults';
            }
            return '/';
        },
        data: {
            roles: ['Participant'],
            icon: 'files-o',
            name: 'My PVI Results'
        }
    },
    {
        path: 'users/:user_id/mppResults',
        pathMatch: 'full',
        component: NexleaderViewMppResultsComponent,
        data: {
            roles: ['Participant'],
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/mppResults/:passion_profile_result_id',
        pathMatch: 'full',
        component: NexleaderMppResultComponent,
        data: {
            roles: ['Participant'],
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/mppResults/:passion_profile_result_id/printable',
        pathMatch: 'full',
        component: NexleaderMppResultPrintableComponent,
        data: {
            roles: ['Participant'],
            hiddenFromNav: true,
            hideNav: true
        }
    },
    {
        path: 'mppResults',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + (payload.user || payload.primary_user) + '/mppResults';
            }
            return '/';
        },
        data: {
            roles: ['Participant'],
            icon: 'files-o',
            name: 'My Passion Profile Results'
        }
    },
    {
        path: 'users/:user_id/pviResults',
        pathMatch: 'full',
        component: NexleaderViewPviAssessmentResultsComponent,
        data: {
            roles: ['Participant'],
            hiddenFromNav: true
        }
    },
    {
        path: 'users/:user_id/ecourseContent',
        pathMatch: 'full',
        component: NexleaderEcourseContentComponent,
        data: {
            name: 'Ecourse Module Content'
        }
    },
    {
        path: 'ecourseContent',
        pathMatch: 'full',
        redirectTo: () => {
            const auth = inject(AuthService);
            const payload = auth.getTokenPayload();
            if (payload) {
                return '/users/' + (payload.user || payload.primary_user) + '/ecourseContent';
            }
            return '/';
        },
        data: {
            roles: ['Participant'],
            icon: 'files-o',
            name: 'E-Course'
        }
    },
    // TODO remove this once we have the ECourse implemented
    // {
    //     path: 'raliTest',
    //     pathMatch: 'full',
    //     component: NexleaderRaliFrameComponent,
    //  }
];