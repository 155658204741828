import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { NexleaderCookiesAcknowledgeComponent } from './features/core/components/cookies-acknowledge/cookies-acknowledge.component';
import { NexleaderNavComponent } from './features/core/components/nav/nav.component';
import { WindowService } from './services/window.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule, RouterOutlet,
        NexleaderCookiesAcknowledgeComponent,
        NexleaderNavComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    title = 'ng17';
    constructor(
        private windowService: WindowService,
        private renderer: Renderer2
    ) { }

    ngOnInit() {
        this.windowService.setBodySmall();

        const splash = this.renderer.selectRootElement('.splash');
        if (splash) {
            splash.style.display = 'none';
        }
    }
}