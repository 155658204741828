/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * Enums
 *
 * angular service: $resource
 *
 * Enums are served statically be the backend and cached by this service.
 *  If enums change on the backend, changes will not be reflected until page refresh.
 *  However, enums should never change.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  private _enums: Observable<any>;

  constructor(private httpClient: HttpClient) {
    this._enums = this.httpClient.get(`${API_BASE}enums`).pipe(shareReplay());
  }

  getEnums(): Observable<any> {
    return this._enums;
  }
}
