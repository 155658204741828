<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<!-- Replace this with your actual HTML structure -->
<div class="container p-v-50-sm">
  <div class="row">
    <div class="col-md-3 col-xs-12 hide-mobile">
      <app-nexleader-component-pvi-assessment-selected-values
        *ngIf="topTwelveValues"
        [selectedValues]="topTwelveValues"
        [selectedValue]="selectedValue"
        (onValueSelect)="selectValue($event.value)"
      ></app-nexleader-component-pvi-assessment-selected-values>
    </div>
    <div class="col-md-9 col-xs-12">
      <div class="hpanel m-b-md">
        <div class="panel-body">
          <p class="m-b-md">
            Write a 25 word or less description of this value that would help
            you explain it to others. You don’t have to use complete sentences.
          </p>
          <div class="m-b-md">
            <h4 class="text-primary-2 m-b-sm">{{ selectedValue?.name }}</h4>
            <small
              class="text-secondary font-bold m-r-sm"
              *ngFor="let domain of selectedValue?.personalValueDomains"
            >
              {{ domain.name }}
            </small>
          </div>
          <textarea
            type="text"
            id="nexleader-test-pvi-text-desc"
            class="form-control"
            [(ngModel)]="selectedValue.description"
            placeholder="Write your 25 word or less description here"
            nexleaderMaximumWordsValidation
            [maxWords]="25"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div class="flex">
        <button
          class="btn btn-default"
          id="nexleader-test-pvi-text-desc-previous"
          (click)="moveToPreviousValue()"
          [class.is-hidden]="!canMoveToPreviousValue()"
        >
          Previous Value
        </button>
        <div class="flex-grow"></div>
        <button
          class="btn btn-default"
          id="nexleader-test-pvi-text-desc-next"
          (click)="moveToNextValue()"
          [class.is-hidden]="!canMoveToNextValue()"
        >
          Next Value
        </button>
      </div>
    </div>
  </div>
</div>
