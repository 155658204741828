/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { InviteService } from '../../../../services/invite.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-invite',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink],
  templateUrl: './invite.component.html'
})
export class NexleaderInviteComponent implements OnInit {
  routeParams: any;
  invite: any;
  warning: boolean | undefined;
  message: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private inviteService: InviteService,
    private errorHandler: ErrorHandlerService,
    private successHandler: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadInvite();
  }

  loadInvite(): void {
    this.inviteService.get(this.routeParams['invite_id']).pipe(
      tap((invite) => {
        this.invite = invite;
        this.warning = this.invite.completed;
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null)
      })
    ).subscribe();
  }

  signup(): void {
    if (this.invite.password.length > 4) {
      this.inviteService.signup(this.routeParams['invite_id'], this.invite).pipe(
        tap(() => {
          this.successHandler.handle({ message: 'Successfully created your account.' });
          setTimeout(() => {
            this.authService.auth(this.invite).pipe(
              tap((_) => {
                this.router.navigate(['/']);
              }), catchError((error) => {
                this.errorHandler.handleError(error.error,
                  this.errorHandler.types.AUTH);
                return of(null);
              })
            ).subscribe();
          }, 1100);

        }), catchError((error) => {
          this.errorHandler.handleError(error.error,
            this.errorHandler.types.TOAST);
          return of(null);
        })
      ).subscribe();
    } else {
      this.message = 'Please enter at least 5 characters.';
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderInviteComponent',
//   downgradeComponent({
//     component: NexleaderInviteComponent,
//   }) as angular.IDirectiveFactory
// );
