/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-net-promoter-box',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './net-promoter-box.component.html',
})
export class NexleaderNetPromoterBoxComponent {
  @Input() score!: number;
  @Input() class: string = '';
}
