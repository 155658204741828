<div class="row m-b-md">
  <div class="col-sm-4 col-xs-12 m-b-sm">
    <label class="control-label">Data Set</label>
    <select
      class="form-control"
      [(ngModel)]="queryParams.queryIdentifier"
      (ngModelChange)="onQueryParamsChange()"
    >
      <option
        *ngFor="
          let model of models | keyvalue : onCompare;
          trackBy: trackByIdentifier
        "
        [value]="model.value.identifier"
      >
        {{ model.value.name }}
      </option>
    </select>
  </div>
  <div class="col-sm-4 col-xs-12 m-b-sm">
    <label class="control-label">Chart Type</label>
    <select
      class="form-control"
      [(ngModel)]="queryParams.runningTotal"
      (ngModelChange)="onQueryParamsChange()"
    >
      <option
        *ngFor="let runningTotalOption of runningTotalOptions"
        [value]="runningTotalOption.value"
      >
        {{ runningTotalOption.name }}
      </option>
    </select>
  </div>
  <div class="col-sm-4 col-xs-12 m-b-sm">
    <label class="control-label">Aggregation</label>
    <select
      class="form-control"
      [(ngModel)]="queryParams.aggregationDuration"
      (ngModelChange)="onQueryParamsChange()"
    >
      <option
        *ngFor="let duration of aggregationDurations"
        [value]="duration.value"
      >
        {{ duration.name }}
      </option>
    </select>
  </div>

  <div class="col-sm-6 col-xs-12 m-b-sm">
    <label class="control-label">Start</label>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        required
        name="start"
        #start="bsDatepicker"
        [bsConfig]="{
          showTodayButton: true,
          showClearButton: true,
          dateInputFormat: 'MM/DD/YYYY',
          clearPosition: 'right',
          todayPosition: 'left',
          adaptivePosition: true
        }"
        bsDatepicker
        [(ngModel)]="queryParams.start"
        (ngModelChange)="onQueryParamsChange()"
      />
      <span class="input-group-btn">
        <button
          class="btn btn-outline-secondary"
          type="button"
          (click)="start.toggle()"
        >
          <i class="fa fa-calendar"></i>
        </button>
      </span>
    </div>
  </div>

  <div class="col-sm-6 col-xs-12 m-b-sm">
    <label class="control-label">End</label>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        required
        name="end"
        #end="bsDatepicker"
        [bsConfig]="{
          showTodayButton: true,
          showClearButton: true,
          dateInputFormat: 'MM/DD/YYYY',
          clearPosition: 'right',
          todayPosition: 'left',
          adaptivePosition: true
        }"
        bsDatepicker
        [(ngModel)]="queryParams.end"
        (ngModelChange)="onQueryParamsChange()"
      />
      <span class="input-group-btn">
        <button
          class="btn btn-outline-secondary"
          type="button"
          (click)="end.toggle()"
        >
          <i class="fa fa-calendar"></i>
        </button>
      </span>
    </div>
  </div>
</div>
<hr />

<div
  class="flex flex-vertical-center flex-horizontal-center hideOnPrint w-100 h-100 position-relative"
>
  <span class="position-absolute" *ngIf="loadState">{{ loadState }}...</span>
  <canvas [class.chart-d-none]="loadState" id="canvas"></canvas>
</div>
