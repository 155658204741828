/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderNewDirectDiscountModal
 *
 * angular component - new modal
 *
 * modal form to create a new team report discount object
 *
 * Steve (or any super admin) has the ability to create discount codes for the Direct Purchase feature. These discounts
 *  can either reduce the total price by a percentage or set the team report to a flat fee. This enum represents
 *  the type of discount that a given discount code represents.
 *
 * Discounts can apply to different types of transactions (see DirectDiscountProduct.enum). For example:
 *  - Direct Purchase (IPSAT direct purchase OR ECourse purchase)
 *  - ECourse Content Upgrade (non-ECourse users buying access to ECourse materials)
 *
 * See Direct.logic -> calculatePricing()
 * See Direct.route -> POST /purchase
 * See DirectDiscount.model
 *
 * Called by nexleaderDirectDiscounts component (see that component)
 */

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../services/enum.service';
import { DirectDiscountService } from '../../../resources/direct-discount.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SuccessService } from '../../../../../services/success.service';
import { FormsModule } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderInputEnumComponent } from '../../../../core/components/input-enum/input-enum.component';

@Component({
  selector: 'app-nexleader-new-direct-discount-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderInputEnumComponent],
  templateUrl: './new-direct-discount-modal.component.html',
})
export class NexleaderNewDirectDiscountModalComponent implements OnInit {
  licensingPartnerId: string = '';

  /**
   * discountAmountRaw
   *
   * number
   *
   * this is the raw discount amount that the user typed into the text box
   *  depending on if it's flat fee or a percentage discount, we apply a different
   *  multiplier before saving to the backend. Thus, we store it in the controller and
   *  don't apply the multiplier until a user clicks save
   *
   * @type {null}
   */
  discountAmountRaw: number | null = null;

  /**
   * directDiscount
   *
   * model object
   *
   * the whole point of this controller is to build a directDiscount object
   *
   * this is object we're building on
   *
   * @type {{discountType: null}}
   */
  directDiscount: {
    licensingPartner: string;
    discountType: any;
    directDiscountProduct: any;
    onboardingExperience: any;
    surveyType: any;
    cliftonStrengthsAssessment: boolean;
    discountAmountBps: number | null;
    name: string;
  } = {
      licensingPartner: this.licensingPartnerId,
      discountType: null, //init this to null so that the InputEnum component can bind to it
      directDiscountProduct: null, //init this to null so that the InputEnum component can bind to it
      onboardingExperience: null, //init this to null so that the InputEnum component can bind to it
      surveyType: null, //init this to null so that the InputEnum component can bind to it
      cliftonStrengthsAssessment: false,
      discountAmountBps: null,
      name: '',
    };

  SurveyTypes: any;

  constructor(
    private enumsService: EnumsService,
    private directDiscountService: DirectDiscountService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    if (typeof this.licensingPartnerId !== 'string') {
      throw new Error(
        'NewDirectDiscountModal requires string licensingPartnerId binding.'
      );
    }

    this.directDiscount.licensingPartner = this.licensingPartnerId;

    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.SurveyTypes = enums.SurveyTypes;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * isFlatFeeAndDirectPurchase()
   *
   * @returns {boolean} true if we should show the additional fields required for flat fee direct purchase
   *  discount codes
   */
  isFlatFeeAndDirectPurchase(): boolean {
    if (
      !this.directDiscount.directDiscountProduct ||
      !this.directDiscount.discountType
    ) {
      return false;
    }
    return (
      this.directDiscount.discountType.identifier === 'flat_fee' &&
      this.directDiscount.directDiscountProduct.identifier === 'direct_purchase'
    );
  }

  /**
   * save()
   *
   * called when clicking "submit" (or some button like that)
   *
   * converts percentage or dollar amount to basis points
   * saves the object and closes the modal
   */
  save(): void {
    //Validate and show errors as toasts
    if (!this.directDiscount.discountType) {
      return this.errorHandlerService.handleError(
        {
          message:
            'Please select a discount type in order to save your discount code.',
        },
        this.errorHandlerService.types.TOAST
      );
    }
    if (typeof this.discountAmountRaw !== 'number') {
      return this.errorHandlerService.handleError(
        {
          message:
            'Please enter a numeric discount amount in order to save your discount code.',
        },
        this.errorHandlerService.types.TOAST
      );
    }

    //Compute the discount amount in bps
    switch (this.directDiscount.discountType.identifier) {
      case 'flat_fee':
      case 'flat_discount':
        this.directDiscount.discountAmountBps = this.discountAmountRaw * 10000;
        break;
      case 'percentage':
        this.directDiscount.discountAmountBps = this.discountAmountRaw * 100;
        break;
      default:
        return this.errorHandlerService.handleError(
          {
            error: {
              message:
                'Please select a known discount type in order to save your discount code',
            },
          },
          this.errorHandlerService.types.TOAST
        );
    }

    // Since there's no bundle w/ Ecourse + Clifton, ensure that the boolean is always false
    //  for ecourse codes
    if (
      this.directDiscount.onboardingExperience &&
      this.directDiscount.onboardingExperience.identifier === 'ecourse'
    ) {
      this.directDiscount.cliftonStrengthsAssessment = true;
    }

    // Since there's no Faith-based Ecourse, ensure that we always choose values_based
    if (
      this.directDiscount.onboardingExperience &&
      this.directDiscount.onboardingExperience.identifier === 'ecourse'
    ) {
      this.directDiscount.surveyType = this.SurveyTypes.VALUES_BASED;
    }

    this.directDiscountService
      .save(this.directDiscount)
      .pipe(
        tap((_) => {
          this.successService.handle({
            message: 'Successfully created Direct discount code.',
          });
          this.modalService.hide();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * getDiscountAmountSymbol()
   *
   * discountAmount is treated differently based on discount type
   *  it can represent dollars or a percentage (see DirectDiscount.model docs)
   *  this function just returns the appropriate symbol
   */
  getDiscountAmountSymbol(): string | null {
    if (!this.directDiscount.discountType) {
      return null;
    }
    switch (this.directDiscount.discountType.identifier) {
      case 'flat_fee':
      case 'flat_discount':
        return '$';
      case 'percentage':
        return '%';
      default:
        return null;
    }
  }
}
