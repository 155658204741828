/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/**
 * Created by jakebillings on 7/31/16.
 */
/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TeamReportService {
  constructor(private http: HttpClient) { }

  get(teamReportId: string, groupId?: string): Observable<any> {
    let params = new HttpParams();
    if (groupId) {
      params = params.append('group_id', groupId);
    }
    return this.http.get(`${API_BASE}teamReports/${teamReportId}`, {
      withCredentials: true,
      params,
    });
  }

  score(teamReportId: string, groupId: string): Observable<any> {
    let params = new HttpParams();
    if (groupId) {
      params = params.append('group_id', groupId);
    }
    return this.http.get(`${API_BASE}teamReports/${teamReportId}/score`, {
      withCredentials: true,
      params,
    });
  }

  calculatePrice(payload: any): Observable<any> {
    return this.http.post(`${API_BASE}teamReports/calculatePrice`, payload, {
      withCredentials: true,
    });
  }

  calculateAddUserPrice(payload: any): Observable<any> {
    return this.http.post(
      `${API_BASE}teamReports/calculateAddUserPrice`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  purchase(payload: any): Observable<any> {
    return this.http.post(`${API_BASE}teamReports/purchase`, payload, {
      withCredentials: true,
    });
  }

  queryGroupIndex(groupId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}groups/${groupId}/teamReports/index`,
      {
        withCredentials: true,
      }
    );
  }

  queryCoachUserIndex(coachUserId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}coachUsers/${coachUserId}/teamReports/index`,
      {
        withCredentials: true,
      }
    );
  }

  updateUserPermissions(
    teamReportId: string,
    groupId: string,
    payload: any
  ): Observable<any> {
    let params = new HttpParams();
    if (groupId) {
      params = params.append('group_id', groupId);
    }
    return this.http.post(
      `${API_BASE}teamReports/${teamReportId}/updateUserPermissions`,
      payload,
      {
        withCredentials: true,
        params,
      }
    );
  }

  removeUserFromTeamReport(
    teamReportId: string,
    groupId: string,
    payload: any
  ): Observable<any> {
    let params = new HttpParams();
    if (groupId) {
      params = params.append('group_id', groupId);
    }
    return this.http.post(
      `${API_BASE}teamReports/${teamReportId}/removeUserFromTeamReport`,
      payload,
      {
        withCredentials: true,
        params,
      }
    );
  }

  addUsersToTeamReport(teamReportId: string, payload: any): Observable<any> {
    return this.http.post(
      `${API_BASE}teamReports/${teamReportId}/addUsersToTeamReport`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  canEdit(teamReportId: string, groupId: string): Observable<any> {
    let params = new HttpParams();
    if (groupId) {
      params = params.append('group_id', groupId);
    }
    return this.http.get(`${API_BASE}teamReports/${teamReportId}/canEdit`, {
      withCredentials: true,
      params,
    });
  }
}
