<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-body">
            <h2 class="mt-0">Sessions in {{ group?.name }}</h2>
            <p>
              Welcome to the session view. Here, you can view all sessions in
              {{ group?.name }} as a list. It also supports searching and
              sorting.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-body">
            <app-nexleader-sessions-table *ngIf="groupId" [groupId]="groupId">
            </app-nexleader-sessions-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
