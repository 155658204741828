<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="modal-header">
  <h3 class="modal-title">Create a new group</h3>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="control-label">Group name</label>
    <input
      type="text"
      placeholder="Forge"
      class="form-control"
      [(ngModel)]="group.name"
    />
  </div>
  <div class="form-group">
    <label class="control-label">Group licenses</label>
    <input
      type="number"
      placeholder="1000"
      class="form-control"
      [(ngModel)]="group.maxUsers"
    />
  </div>
  <div class="form-group">
    <label class="control-label">Group type</label>
    <select [(ngModel)]="group.tier" class="form-control">
      <option
        *ngFor="let tier of groupTiers | keyvalue; trackBy: trackByName"
        [ngValue]="tier.value"
      >
        {{ tier.value.name }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <label class="control-label">IPSAT Experience</label>
    <select [(ngModel)]="group.surveyType" class="form-control">
      <option
        *ngFor="let surveyType of surveyTypes | keyvalue; trackBy: trackByName"
        [ngValue]="surveyType.value"
      >
        {{ surveyType.value.name }}
      </option>
    </select>
  </div>
  <app-nexleader-input-licensing-partner
    [model]="group.licensingPartner"
    (realOnChange)="group.licensingPartner = $event"
  ></app-nexleader-input-licensing-partner>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="saveGroup(group)">
    Save Group
  </button>
</div>
