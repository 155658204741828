/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * InputLicensingPartner
 *
 * angular component
 *
 * dynamic selector dropdown for licensing partners
 *
 * This component is responsible for loading a list of licensing partners, displaying a dropdown, and
 *  letting the user select one.
 *
 * If a user is logged in as a partner admin (not a super admin), they are restricted to selecting
 *  only their licensing partner. This component is responsible for the frontend side of this
 *  behavior. We disable the dropdown and select the logged in partner.
 *
 * If you're looking for a form to enter the data associated with a licensing partner for add/edit operations,
 *  See InputLicensingPartnerData.
 */
import { tap, catchError, of } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { LicensingPartnerService } from '../../../licensing-partners/resources/licensing-partner.service';

@Component({
  selector: 'app-nexleader-input-licensing-partner',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-licensing-partner.component.html',
})
export class NexleaderInputLicensingPartnerComponent implements OnInit {
  /**
   * model
   *
   * two-way object binding
   *
   * basically ng-model
   *
   * selecting an option in the dropdown should mutate this state to the selected option
   * mutating this state from an outside controller should update the dropdown
   * model should not be null in $onLoad (after binding)
   */
  @Input() model: any;
  @Output() realOnChange: EventEmitter<any> = new EventEmitter<any>();
  options: any[] = [];
  disabled: boolean = false;
  defaultLicensingPartner: any = null;

  constructor(
    private auth: AuthService,
    private licensingPartnerService: LicensingPartnerService,
    private errorHandler: ErrorHandlerService
  ) { }

  /**
   * $onInit()
   *
   * function: angular event handler
   *
   * called after binding
   *
   * validates bindings then
   * loads the enum options
   */
  ngOnInit(): void {
    if (this.model === undefined) {
      throw new Error(
        "cannot initialize an InputLicensingPartner component with an undefined (null is actually fine) $ctrl.model; you wouldn't actually be binding to anything; try adding an ng-if with the same model property (or adding a model property if you didn't)."
      );
    }

    const payload = this.auth.getTokenPayload();

    if (!payload) {
      throw new Error(
        'cannot use licensing partner input if you are not authenticated; we have logic that depends on auth and your write query would 403 anyway'
      );
    }

    if (!payload.roles) {
      throw new Error(
        'cannot use licensing partner input as a consolidated coach user (roles required in payload) you are not authenticated; we have logic that depends on auth and your write query would 403 anyway'
      );
    }

    if (typeof payload.licensing_partner !== 'string') {
      throw new Error(
        'malformed auth payload licensing_partner is required; we have logic that depends on auth and your write query would 403 anyway'
      );
    }

    const isSuperAdmin = payload.roles.indexOf('admin') >= 0;

    if (isSuperAdmin) {
      this.disabled = false;

      this.licensingPartnerService
        .queryIndex()
        .pipe(
          tap((partners) => {
            this.options = partners;
            this.options.forEach((partner) => {
              partner._displayName = `${partner.name} (${partner.region})`;
              if (partner.isDefaultLicensingPartner) {
                this.defaultLicensingPartner = partner;
              }
            });
            this.model = this.defaultLicensingPartner;
          }),
          catchError((error) => {
            this.errorHandler.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.disabled = true;

      this.licensingPartnerService
        .getLite(payload.licensing_partner)
        .pipe(
          tap((currentPartner) => {
            this.options = [currentPartner];
            currentPartner._displayName = `${currentPartner.name} (${currentPartner.region})`;
            this.model = currentPartner._id;
          }),
          catchError((error) => {
            this.errorHandler.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    }
  }

  onChange(): void {
    this.realOnChange.emit(this.model);
  }

  shouldDisableDropdown(): boolean {
    return this.disabled;
  }
}
