/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderTimezoneSelect
 *
 * directive
 *
 * timezone-selector
 *
 * provides a dropdown where users can select a timezone
 *  defaults to best guess of local timezone
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import moment from 'moment';

@Component({
  selector: 'app-nexleader-timezone-select',
  standalone: true,
  imports: [CommonModule, FormsModule, FilterPipe],
  templateUrl: './timezone-select.component.html',
})
export class NexleaderTimezoneSelectComponent implements OnInit {
  @Input() model: any;
  @Output() realOnChange: EventEmitter<any> = new EventEmitter<any>();

  regions: string[] = [];
  timezones: any[] = [];
  region: any;

  constructor() { }

  ngOnInit() {
    const names: string[] = [];

    moment.tz.names().forEach((tz: string) => {
      if (names.indexOf(tz) >= 0) return;
      names.push(tz);
      const split: string[] = tz.split('/');

      if (this.regions.indexOf(split[0]) < 0) this.regions.push(split[0]);

      this.timezones.push({
        region: split[0],
        name: split.slice(1, split.length).join('/').split('_').join(' '),
        timezone: tz,
      });
    });

    if (!this.model || moment.tz.names().indexOf(this.model) < 0) {
      this.model = moment.tz.guess();
      this.onChange();
    }
    this.region = this.model.split('/')[0];
  }

  /**
   * onChange()
   *
   * wraps realOnChange() in a $timeout
   *
   * see docs for realOnChange in bindings
   */
  onChange() {
    this.realOnChange.emit(this.model);
  }
}
