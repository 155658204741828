<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div id="nexleader-wrapper">
  <div class="content">
    <app-nexleader-quick-view
      [model]="licensingPartners"
      [tableHeaders]="['Name', 'Region']"
      [tableColumns]="['name', 'region']"
      [label]="'Licensing Partners'"
      (overrideOnNewElement)="openNewLicensingPartnerModal()"
      (onElementSelect)="selectedPartner = $event"
    >
      <ng-container *ngIf="selectedPartner?._id; else notSelected">
        <app-nexleader-licensing-partner-card
          [licensingPartnerId]="selectedPartner._id"
          (onChange)="load()"
        ></app-nexleader-licensing-partner-card>
      </ng-container>
      <ng-template #notSelected>
        <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
      </ng-template>
    </app-nexleader-quick-view>
  </div>
</div>
