<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Create a New Discount Code</h3>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12 m-b-md">
      <p>
        Team leaders will be able to use this discount code to purchase the team
        report for a cheaper price.
      </p>
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Discount Code</label>
      <input
        type="text"
        placeholder="IPSAT2017"
        class="form-control"
        [(ngModel)]="teamReportDiscount.name"
      />
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Discount Type</label>
      <app-nexleader-input-enum
        [enumName]="'TeamReportDiscountTypes'"
        [model]="teamReportDiscount.discountType"
        (realOnChange)="teamReportDiscount.discountType = $event"
      ></app-nexleader-input-enum>
    </div>
    <div class="form-group col-md-6" *ngIf="teamReportDiscount.discountType">
      <label class="control-label"
        >Discount Amount ({{ getDiscountAmountSymbol() }})</label
      >
      <input
        type="number"
        placeholder="30"
        class="form-control"
        [(ngModel)]="discountAmountRaw"
      />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="save()">
    Create Discount
  </button>
</div>
