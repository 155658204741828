/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderOnboardingPersonalInfo
 *
 * angular component: onboarding step
 *
 * This component follows the onboarding step component pattern. This means it has a few common function names for
 *  validation of next buttons and a "nexleader-next" function binding for the nexleaderOnboarding component to call.
 *
 * This component is responsible for collecting and saving basic personal information for our users upon signup.
 *  It is responsible for validating this information (and not allowing users to move on until it is valid and
 *  saved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderCustomOnboardingCardComponent } from '../../../components/custom-onboarding-card/custom-onboarding-card.component';
import { NexleaderInputEnumComponent } from '../../../../core/components/input-enum/input-enum.component';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EnumsService } from '../../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SuccessService } from '../../../../../services/success.service';
import { UserService } from '../../../../../services/user.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-onboarding-personal-info',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderCustomOnboardingCardComponent, NexleaderInputEnumComponent],
  templateUrl: './onboarding-personal-info.component.html'
})
export class NexleaderOnboardingPersonalInfoComponent implements OnInit {
  routeParams: any;
  @Output() boundNext = new EventEmitter();
  user: any;
  groupId!: string;
  onboardingStep: any;
  nextOnboardingStep: any;

  constructor(
    private enumsService: EnumsService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService,
    private route: ActivatedRoute
  ) { }

  /**
   * $onInit()
   *
   * angular event handler function
   *
   * called when the component binding is complete
   *
   * loads the user we're editing
   * loads any additional information necessary for running the view
   */
  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    forkJoin([
      this.enumsService.getEnums(),
      this.userService.getUser(this.routeParams['user_id'])
    ]).pipe(
      tap(([enums, user]) => {
        this.onboardingStep = enums.OnboardingSteps.PERSONAL_INFO;
        this.nextOnboardingStep = enums.OnboardingSteps.VIDEO;
        this.user = user;
        this.groupId = this.user.group._id;

        // If enum fields are undefined, set them to null so that input-enum can bind
        if (!this.user.gender) this.user.gender = null;
        if (!this.user.preferredContactType) this.user.preferredContactType = null;
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null)
      })
    ).subscribe();
  }

  isReadyToAdvance(): boolean {
    if (!this.user) return false;
    if (typeof this.user.firstName !== 'string') return false;
    if (!this.user.lastName) return false;
    if (!this.user.birthYear) return false;
    if (!this.user.gender) return false;
    if (typeof this.user.gender.name !== 'string') return false;
    if (typeof this.user.email !== 'string') return false;
    if (!this.user.group) return false;
    if (typeof this.user.group.name !== 'string') return false;
    if (!this.user.preferredContactType || this.user.preferredContactType === 'null') return false;
    if (typeof this.user.preferredContactAddress !== 'string') return false;

    // COPA Compliance: We don't want users signing up if they aren't 13
    if (this.user.birthYear > new Date().getFullYear() - 13) return false;

    return true;
  }

  save(): Observable<any> {
    if (!this.isReadyToAdvance()) return of(null);
    // Set this to true, as they have now completed their profile
    this.user.completedProfile = true;
    return this.userService.saveUser(this.user).pipe(
      tap(() => {
        this.successService.handle()
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null)
      })
    )
  }

  next(): void {
    if (!this.isReadyToAdvance()) return;
    this.user.onboardingStep = this.nextOnboardingStep;
    this.save().pipe(
      tap(() => {
        this.boundNext.emit();
      })
    ).subscribe();
  }

  getBirthYears(): number[] {
    const years = [];
    const currentYear = new Date().getFullYear();
    // The oldest person was 122 years old
    // The youngest allowed person is 13 years old
    for (let i = 13; i <= 122; i++) {
      years.push(currentYear - i);
    }
    // Start with the current year instead of the last year
    return years;
  }
}
