/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SmallGroupService {
  constructor(private http: HttpClient) {}

  getSmallGroup(smallGroupId: string): Observable<any> {
    return this.http.get(`${API_BASE}smallGroups/${smallGroupId}`, {
      withCredentials: true,
    });
  }

  save(smallGroup: any): Observable<any> {
    const url =
      `${API_BASE}smallGroups` + (smallGroup._id ? `/${smallGroup._id}` : '');
    return this.http.post(url, smallGroup, {
      withCredentials: true,
    });
  }

  getUsersAndInvites(smallGroupId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}smallGroups/${smallGroupId}/usersAndInvites`,
      {
        withCredentials: true,
      }
    );
  }

  queryGroup(groupId: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}groups/${groupId}/smallGroups`);
  }

  queryGroupIndex(groupId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}groups/${groupId}/smallGroups/index`,
      {
        withCredentials: true,
      }
    );
  }

  sendInitialMeetingEmail(smallGroupId: string): Observable<any> {
    return this.http.post(
      `${API_BASE}smallGroups/${smallGroupId}/sendInitialMeetingEmail`,
      {},
      {
        withCredentials: true,
        responseType: 'text',
      }
    );
  }
}
