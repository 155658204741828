<input
  type="range"
  class="nexleader-range-slider"
  [(ngModel)]="model"
  [min]="rangeMin"
  [max]="rangeMax"
  [step]="rangeStep"
  (ngModelChange)="onChange()"
  [disabled]="disabled"
/>
