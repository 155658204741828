/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderDirectSignupView
 *
 * angular component: view
 *
 * Added May 2020
 *
 * This view lets new users sign up for the IPSAT directly without talking to a human being. Not that this creates an
 *  account with the "directsignupunpaid". Users with this role are directed through a process to purchase the IPSAT
 *  before they can take teh assessment.
 *
 * Users can sign up for faith-based or values-based directly. They will be placed in respective groups if this is the case.
 * Users can also sign up for a specific coach in a specific group. If this is the case, they will be placed in a group
 *  with that coach.
 * Users can also sign up for a specific group without knowing a coach. If this is the case, they will be placed in the
 *  group and assigned a coach randomly from coaches with automatic assignment enabled.
 *
 * This view handles signups from lead generation funnels for both ECourse and Standard IPSAT experiences.
 *  See OnboardingExperience.enum
 *
 * $location.search() paramters control this behavior. See $onInit.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { DirectService } from '../../resources/direct.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderHelpUtilityComponent } from '../../../core/components/help-utility/help-utility.component';
import { NexleaderCustomOnboardingCardComponent } from '../../../onboarding/components/custom-onboarding-card/custom-onboarding-card.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-direct-signup',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderHelpUtilityComponent, NexleaderCustomOnboardingCardComponent],
  templateUrl: './direct-signup.component.html'
})
export class NexleaderDirectSignupComponent implements OnInit {
  queryParams: any;

  /**
   * transactionIsInProgress
   *
   * this flag is set to true if the user has clicked submit and we have not
   *  yet received a response from the server; we don't want them to be able to
   *  click submit again
   *
   * @type {boolean}
   */
  transactionIsInProgress = false;
  isEcourse = false;

  /**
   * surveyType
   *
   * stores the SurveyType enum value for the (FAITH_BASED or VALUES_BASED)
   *
   * populated and validated after loading enums in $onInit either from $location.search().survey_type_identifier
   *  or from $location.search().referring_coach_id
   *
   * @type {*}
   */
  surveyType: any = null;

  /**
   * referringCoach
   *
   * a user entering direct purchase may have been referred by a coach - if that's the case, this
   *  object will be loaded and populated in $onInit using the $location.search().referring_coach_id
   *  parameter
   *
   * @type {*}
   */
  referringCoach: any = null;

  /**
   * form
   *
   * stores the form elements (firstName, lastName, email, password)
   *
   * @type {{}}
   */
  form: any = {};
  groupId!: string;
  allSurveyTypes: any[] = [];
  allOnboardingExperiences: any[] = [];
  onboardingExperience: any;
  referringGroup: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private enumsService: EnumsService,
    private directService: DirectService,
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private successService: SuccessService
  ) { }

  /**
   * $onInit()
   *
   * angular event handler function
   *
   * called when the component binding is complete
   *
   * loads the user we're editing
   * loads any additional information necessary for running the view
   */
  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
    const surveyTypeIdentifier = this.queryParams['survey_type_identifier'];
    const coachId = this.queryParams['coach_id'];
    const groupId = this.queryParams['group_id'];
    const onboardingExperienceIdentifier = this.queryParams['onboarding_experience_identifier'];

    // Validate survey_type_identifier and coach_id
    // This ole' big block of if statements validates both
    // We can have either a referring coach id or a survey_type_identifier. This is enforced as an
    //  exclusive or since a referring coach id implies a survey_type_identifier.
    if (surveyTypeIdentifier && !coachId && !groupId) {
      // Fetch survey type details and validate
      this.enumsService.getEnums().pipe(
        tap((a) => {
          // Fetch enums to use as filtering
          this.allSurveyTypes = a.AllSurveyTypes;

          // Throw an error if the selected survey type is invalid
          if (this.allSurveyTypes.map(surveyType => surveyType.identifier).indexOf(surveyTypeIdentifier) < 0) {
            this.errorHandler.handleError({
              message: 'Sorry, that is an invalid direct purchase URL - invalid survey_type_identifier - please contact support',
              status: -1
            });
          }

          // Store the survey type object on $ctrl
          this.surveyType = this.allSurveyTypes.find(surveyType => surveyType.identifier === surveyTypeIdentifier);
        }), catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      ).subscribe();

    } else if (coachId && !surveyTypeIdentifier && !groupId) {
      //coach_id is present - load details via that endpoint

      // Fetch coach
      this.directService.getCoach(coachId).pipe(
        tap((coach) => {
          this.referringCoach = coach;
          this.surveyType = coach.surveyType;

          this.groupId = coach.group;
        }), catchError((error) => {
          this.errorHandler.handleError({
            message: 'Sorry, that is an invalid direct purchase URL - invalid coach_id - please contact support',
            status: -1
          });
          return of(null);
        })
      ).subscribe();

    } else if (groupId && !surveyTypeIdentifier && !coachId) {
      // Fetch group details and validate
      this.groupId = groupId;
      this.directService.getGroup(groupId).pipe(
        tap((group) => {
          this.referringGroup = group;
          this.surveyType = group.surveyType;
        }), catchError((error) => {
          this.errorHandler.handleError({
            message: 'Sorry, that is an invalid direct purchase URL - invalid group_id - please contact support',
            status: -1
          });
          return of(null);
        })
      ).subscribe();
    } else {
      this.errorHandler.handleError({
        message: 'Sorry, that is an invalid direct purchase URL - please contact support - ' +
          'nexleaderDirectPurchaseView requires EITHER a string survey_type_identifier OR a string coach_id as a query string parameter',
        status: -1
      });
    }

    //Verify onboarding_experience_identifier
    if (onboardingExperienceIdentifier) {
      this.enumsService.getEnums().pipe(
        tap((enums) => {
          // Fetch enums to use as filtering
          this.allOnboardingExperiences = enums.AllOnboardingExperiences;

          // Throw an error if the selected survey type is invalid
          if (this.allOnboardingExperiences.map(experience => experience.identifier).indexOf(onboardingExperienceIdentifier) < 0) {
            this.errorHandler.handleError({
              message: 'Sorry, that is an invalid direct purchase URL - invalid onboarding_experience_identifier - please contact support',
              status: -1
            });
          }

          // Store the survey type object on $ctrl
          this.onboardingExperience = this.allOnboardingExperiences.find(experience => experience.identifier === onboardingExperienceIdentifier);
        }), catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      ).subscribe();
    } else {
      this.errorHandler.handleError({
        message: 'Sorry, that is an invalid direct purchase URL - please contact support - ' +
          'nexleaderDirectPurchaseView requires a string onboarding_experience_identifier',
        status: -1
      });
    }

    // Verify that we're not letting someone sign up for a faith-based ecourse since that doesn't exist
    //  we let a case slip through here where they sign up using a coach_id that is in a faith_based
    //  group.
    //  that's okay because the backend checks that properly and will give a nice error toast
    if (surveyTypeIdentifier && surveyTypeIdentifier !== 'values_based' && onboardingExperienceIdentifier === 'ecourse') {
      this.errorHandler.handleError({
        message: 'Sorry, that is an invalid direct purchase URL - please contact support - ' +
          'The ecourse is currently only available as an ecourse.',
        status: -1
      });
    }
  }

  /**
   * isReadyToAdvance()
   *
   * perform simple validation on the form data and make sure surveyType has loaded (and is valid)
   *
   * Note: this is no guarantee we won't get a 422 - e.g. the email could be valid but a duplicate
   *
   * @returns {boolean} true if we're ready to submit to the backend
   */
  isReadyToAdvance(): boolean {
    if (this.transactionIsInProgress) return false;
    if (!this.surveyType) return false;
    if (!this.form.firstName) return false;
    if (!this.form.lastName) return false;
    if (!this.form.email) return false;
    if (!this.form.password) return false;
    return true;
  }

  /**
   * signup()
   *
   * function
   *
   * this is the main form submission
   *
   * @returns {boolean} false if we cancelled due to frontend validation
   */
  signup() {
    // Abort if the form doesn't meet frontend validation requirements
    //  Those requirements are pretty weak, so there's no point bothering the backend
    //  if they fail
    if (!this.isReadyToAdvance()) return;

    // Build the request body
    const directPurchaseRequestBody: any = {
      firstName: this.form.firstName,
      lastName: this.form.lastName,
      email: this.form.email,
      password: this.form.password,
      onboardingExperienceIdentifier: this.onboardingExperience.identifier
    };

    // Order matters here since we load surveyType from groupId but don't want to pass it if we
    //  have a groupId or referringCoach
    if (this.referringCoach) {
      directPurchaseRequestBody['coachId'] = this.referringCoach._id;
    } else if (this.groupId) {
      directPurchaseRequestBody['groupId'] = this.groupId;
    } else {
      directPurchaseRequestBody['surveyTypeIdentifier'] = this.surveyType.identifier;
    }

    // Send the DirectPurchase post request
    //  On Success, perform the next step
    //  On Error, show an error toast
    this.directService.signup(directPurchaseRequestBody).pipe(
      tap(() => {
        // Display a success toast
        this.successService.handle({ message: 'Successfully purchased ' + this.surveyType.name + ' IPSAT.' });
        // We successfully performed a direct purchase of the IPSAT. The user's account is ready to
        //  login to. We have their email and password, so let's just log them in for convenience.
        this.authService.auth({ email: this.form.email, password: this.form.password }).pipe(
          tap((_) => {
            // We have now successfully created and logged into a brand new IPSAT account. Redirect to /
            //  and the existing logic should take care of the rest.
            this.router.navigate(['/']);
          }), catchError((error) => {
            this.errorHandler.handleError(error);
            return of(null);
          })
        ).subscribe();
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderDirectSignupComponent',
//   downgradeComponent({
//     component: NexleaderDirectSignupComponent,
//   }) as angular.IDirectiveFactory
// );

