/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentEcourseRaliVideo
 *
 * angular component
 *
 * For the IPSAT ECourse feature, nexleader is partnering with the Rali online learning tool. As a result,
 *  We will embed Rali "videos" (they're more than just videos) in iFrames in the ECourse Onboarding process
 *  of the IPSAT. To generate the URLs for these iFrame embeds, we must generate and sign JWTs with some
 *  user data.
 *
 * This component wraps the nexleaderComponentRaliFrame component to handle loading to correct version of a video
 *  based on a users group. This is key since each group has a distinct set of Rali ids for each module video.
 *
 * It should show up looking like a video.
 *
 * See RaliFrame.component
 * See Group.model (ecourse subsection)
 * See Rali.route
 * See Rali.logic
 * See https://help.rali.io/docs/embedding-onq/embedding-video-player/#iframe-attributes
 */
import { AuthService } from './../../../../services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupService } from '../../../../services/group.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderRaliFrameComponent } from '../../../core/components/rali-frame/rali-frame.component';

@Component({
  selector: 'app-nexleader-ecourse-rali-video',
  standalone: true,
  imports: [CommonModule, NexleaderRaliFrameComponent],
  templateUrl: './ecourse-rali-video.component.html',
})
export class NexleaderEcourseRaliVideoComponent implements OnInit {
  /**
   * index
   *
   * number; integer; [1, 8] inclusive
   *
   * An integer representing which module video we're trying to load
   */
  @Input() index!: number;
  group: any;

  constructor(
    private authService: AuthService,
    private groupService: GroupService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    // Ensure we have the Rali ID
    if (typeof this.index !== 'number') {
      throw new Error(
        'numeric nexleaderDataIndex is required for nexleaderComponentRaliFrame; See binding documentation. Type: ' +
        typeof this.index
      );
    }

    if (this.index < 1 || this.index > 8) {
      throw new Error(
        'nexleaderDataIndex expected a module index in the inclusive range [1, 8]; See documentation.'
      );
    }

    const tokenPayload = this.authService.getTokenPayload();

    if (!tokenPayload) {
      return this.errorHandler.handleError({
        message: 'Sorry, you must be logged in to view this resource',
        status: 403,
      });
    }

    this.groupService
      .getForEcourse(tokenPayload.group)
      .pipe(
        tap((result) => {
          this.group = result;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  getRaliId(): any {
    if (!this.group) {
      return;
    }

    switch (this.index) {
      case 1:
        return this.group.ecourse.moduleOneRaliId;
      case 2:
        return this.group.ecourse.moduleTwoRaliId;
      case 3:
        return this.group.ecourse.moduleThreeRaliId;
      case 4:
        return this.group.ecourse.moduleFourRaliId;
      case 5:
        return this.group.ecourse.moduleFiveRaliId;
      case 6:
        return this.group.ecourse.moduleSixRaliId;
      case 7:
        return this.group.ecourse.moduleSevenRaliId;
      case 8:
        return this.group.ecourse.moduleEightRaliId;
    }
  }
}
