/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderSmallGroupCard
 *
 * component
 *
 * displays a card representing a single small group
 *  used in the CoachSmallGroups view
 *
 * may mutate of the state of groups via two-way binding
 *  an edit button will make edits; archive button will archive
 *
 * changes are saved automatically via the resource service
 */
import { NexleaderEditSmallGroupModalComponent } from './../modals/edit-small-group-modal/edit-small-group-modal.component';
import { SuccessService } from './../../../../services/success.service';
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { EnumsService } from './../../../../services/enum.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallGroupService } from '../../resources/small-group.service';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';
import { catchError, of, tap } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-small-group-card',
  standalone: true,
  imports: [CommonModule, OrderByPipe],
  templateUrl: './small-group-card.component.html',
})
export class NexleaderSmallGroupCardComponent implements OnInit {
  private _smallGroupId: string = '';

  /**
   * smallGroupId
   *
   * string binding
   *
   * the id of the small group we're rending a card for
   */
  @Input() set smallGroupId(value: string) {
    if (typeof value !== 'string') {
      throw new Error(
        'nexleaderSmallGroupCard required string param smallGroupId'
      );
    }
    this._smallGroupId = value;
    this.loadSmallGroup(this.smallGroupId);
  }

  get smallGroupId() {
    return this._smallGroupId;
  }

  /**
   * onChange
   *
   * expression binding: event handler
   *
   * called after saving a change to the small group
   */
  @Output() onChange = new EventEmitter<any>();

  AllSmallGroupMeetingTypes: any;
  smallGroup: any;
  usersAndInvites: any[] = [];
  smallGroupMeetings: any[] = [];

  constructor(
    private smallGroupService: SmallGroupService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: BsModalService,
    private enumsService: EnumsService,
    private successService: SuccessService
  ) { }

  /**
   * $onInit()
   *
   * function: angular event handler
   *  called after bindings load
   *
   * validate that the bindings loaded and are the correct type
   */
  ngOnInit() {
    this.loadEnums();
  }

  private loadEnums() {
    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.AllSmallGroupMeetingTypes = enums.AllSmallGroupMeetingTypes;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe(() => {
        this.loadSmallGroup(this.smallGroupId);
      });
  }

  /**
   * _getSmallGroupMeetings()
   *
   * private function
   *
   * "merges" the enum and the small group meeting data to create a complete table
   * this is not an issue for complete small group objects; this function exists
   * for small groups for which meetings are not scheduled
   *
   * we return the complete list of small group meeting types from the enums
   *  for each meeting type that has been scheduled and stored on $ctrl.smallGroup,
   *  we populate the date for which this is schedule
   *
   * called by a deep watch on $ctrl.smallGroup.smallGroupMeetings
   */
  private _getSmallGroupMeetings(): any[] {
    if (!this.AllSmallGroupMeetingTypes || !this.smallGroup) {
      return [];
    }

    return this.AllSmallGroupMeetingTypes.sort((a: any) => a.index).map(
      (smallGroupMeetingType: any) => {
        const match = this.smallGroup.smallGroupMeetings.filter(
          (smallGroupMeeting: any) =>
            smallGroupMeeting.smallGroupMeetingType.identifier ===
            smallGroupMeetingType.identifier
        )[0];

        return {
          smallGroupMeetingType: smallGroupMeetingType,
          start: match ? match.start : null,
          timezone: match ? match.timezone : null,
          location: match ? match.location : null,
        };
      }
    );
  }

  /**
   * loadSmallGroup()
   *
   * function: modifies internal component state
   *
   * validate small group id then load the small group information used in the card
   * separate from $onInit because we call this function again if the $watched smallgroupid changes
   *
   * called by watch on $ctrl.smallGroupId
   */
  loadSmallGroup(smallGroupId: string) {
    if (!smallGroupId) {
      throw new Error('loadSmallGroup() requires a smallGroupId parameter');
    }

    this.smallGroupService
      .getSmallGroup(smallGroupId)
      .pipe(
        tap((smallGroup) => {
          this.smallGroup = smallGroup;
          this.smallGroupMeetings = this._getSmallGroupMeetings();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.smallGroupService
      .getUsersAndInvites(smallGroupId)
      .pipe(
        tap((usersAndInvites) => {
          this.usersAndInvites = usersAndInvites;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * setArchivedAndSave()
   *
   * function
   *
   * since groups are only active for a fixed period of time, we allow coaches to archive groups.
   *  archived groups show up as only a table row under "archived groups" as opposed to a full
   *  card in the view CoachSmallGroups (Your Small Groups) (this view)
   *
   * this function sets the property to the param and calls save() via the resource API
   *
   * @param smallGroup mutable copy of the smallGroup we are editing; must have _id and must be the copy from the $ctrl.smallGroups array so that the view updates
   * @param archived true if we are archiving a group false if we are unarchiving a group
   */
  setArchivedAndSave(smallGroup: any, archived: boolean) {
    smallGroup.archived = archived;
    this.smallGroupService
      .save(smallGroup)
      .pipe(
        tap((_) => {
          this.successService.handle();
          this.onChange.emit();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * editSmallGroup
   *
   * Allows a coach to edit a small group in which they are a part of.
   */
  editSmallGroup() {
    const modalRef = this.modalService.show(
      NexleaderEditSmallGroupModalComponent,
      {
        initialState: {
          smallGroupId: this.smallGroupId,
        },
      }
    );

    modalRef.content?.onCancel.subscribe(() => {
      modalRef.hide();
    });

    modalRef.content?.onSave.subscribe((value) => {
      modalRef.hide();
      this.loadSmallGroup(this.smallGroupId);
      this.onChange.emit();
    });
  }
}
