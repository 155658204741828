<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">{{ title }}</h3>
</div>
<div class="modal-body">
  <p class="font-bold m-b-sm">Users who have this:</p>
  <ul class="m-b-sm">
    <li *ngFor="let user of users">
      {{ user.firstName }} {{ user.lastName }}
      <span *ngIf="user.additionalAttributeData"
        >- {{ user.additionalAttributeData }}</span
      >
      <span *ngIf="user.isTop">*</span>
    </li>
  </ul>
  <p>{{ additionalClarificationCopy }}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
