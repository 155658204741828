/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class EcourseContentUpgradeService {
  constructor(private httpClient: HttpClient) {}

  purchase(requestBody: any): Observable<any> {
    return this.httpClient.post(
      `${API_BASE}ecourseContentUpgrade/purchase`,
      requestBody,
      {
        withCredentials: true,
        responseType: 'text',
      }
    );
  }

  getPricing(config: any): Observable<any> {
    return this.httpClient.get(`${API_BASE}ecourseContentUpgrade/pricing`, {
      params: config,
      withCredentials: true,
    });
  }
}
