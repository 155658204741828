/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartSkillComparison
 *
 * Displays a skills comparison chart in TeamReport
 *
 * The skills comparison chart is a component of the team report that compares a teams actual
 *  skill set with its ideal skill set.
 *
 * This component can easily be generalized for an skill comparison application.
 */
import { Component, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-nexleader-chart-skill-comparison',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-skill-comparison.component.html',
})
export class NexleaderChartSkillComparisonComponent implements OnInit, OnDestroy {
  /**
   * model
   *
   * object in the following form:
   * {
   *   actual: [{
   *     name: '' //Skill name
   *     value: 5 //Skill level
   *     index: 0 //Skill index
   *     }
   *   ...more...
   *   ],
   *   ideal: [{
   *     name: '' //Skill name
   *     value: 5 //Skill level
   *     index: 0 //Skill index
   *     }
   *   ...more...
   *   ]
   * }
   *
   * Skills in skill array must match
   */
  @Input() model: any;
  disableResponsive: boolean = false;
  private chart!: Chart;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    if (!this.model) {
      throw new Error(
        'model is required for NexleaderChartSkillComparisonComponent (see component docs; maybe add an *ngIf if it has to load)'
      );
    }
    if (
      !Array.isArray(this.model.ideal) ||
      !Array.isArray(this.model.actual) ||
      this.model.ideal.length !== this.model.actual.length
    ) {
      throw new Error(
        "Failed sanity check; both ideal and actual should hold all skills; thus their lengths should be the same; they're not."
      );
    }

    const wrapper = this.elementRef.nativeElement.querySelector(
      '.nexleader-chart-skill-comparison-wrapper'
    );
    const canvas = document.getElementById(
      'horizontal-bar'
    ) as HTMLCanvasElement;

    //Fetch the labels from the names of the ideal skills
    // we could also fetch from the actual skills
    // we should probably validate that they're the same
    // but, this is probably good enough.
    const labels = this.model.ideal.map((skill: { name: any }) => skill.name);

    //Map the ideal and actual data sets to arrays of numbers for consumption by ChartJS
    const idealData = this.model.ideal.map((skill: { value: any }) => {
      if (typeof skill.value !== 'number') {
        throw new Error(
          'skill.value should be a number; malformed data passed to ChartSkillComparison; see docs'
        );
      }
      return skill.value;
    });

    const actualData = this.model.actual.map((skill: { value: number }) => {
      if (typeof skill.value !== 'number') {
        throw new Error(
          'skill.value should be a number; malformed data passed to ChartSkillComparison; see docs'
        );
      }
      // If the skill value is equal to zero, we don't want to show it as zero, so we make it 0.5
      return skill.value === 0 ? 0.5 : skill.value;
    });

    //Set the proper height for the wrapper div so that the chart renders at the correct size
    // the height should make the bars look about the right size
    // In my experience it's best to size by pixels per data item, so we will set the total size to
    // some constant times the number of data items.
    // labels.length is used to find the number of data items.
    const PIXELS_PER_DATA_ITEM = 25;
    wrapper.style.height = PIXELS_PER_DATA_ITEM * labels.length + 200 + 'px';

    //Call Chart.js to render the chart
    this.chart = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Ideal',
            data: idealData,
            backgroundColor: 'rgba(80,100,118,0.4)',
            borderColor: 'rgb(80,100,118)',
            borderWidth: 1,
          },
          {
            label: 'Self-assessed',
            data: actualData,
            backgroundColor: 'rgba(60,151,211,0.4)',
            borderColor: 'rgb(60,151,211)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: !this.disableResponsive,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        scales: {
          y: {
            // display: false,
          },
          x: {
            position: 'bottom',
            type: 'linear',
            min: 0,
            max: 10,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              autoSkip: false,
            },
            grid: {
              display: true,
            },
            display: true,
          },
          x2: {
            position: 'top',
            type: 'linear',
            min: 1,
            max: 10,
            ticks: {
              callback: function (value: any) {
                switch (value) {
                  case 1:
                    return 'Non-Existent';
                  case 3:
                    return 'Below Average';
                  case 5:
                    return 'Average';
                  case 7:
                    return 'Functionally Competent';
                  case 10:
                    return 'World Class';
                  default:
                    return '';
                }
              },
              maxRotation: 0,
              minRotation: 0,
              autoSkip: false,
            },
            grid: {
              drawOnChartArea: false,
            },
            display: true,
          },
        },
      },
    });
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
