<div class="container-fluid h-100">
    <div class="h-100-subtract row nexleader-onboarding-reverse-row overflow-y-hidden">
        <div class="col-md-6 bg-white h-100-subtract flex overflow-y-scroll">
            <div class="p-v-50 nexleader-onboarding-preassessments-padding m-t-a m-b-a">
                <div>
                    <h2 *ngIf="!isUserInSmallGroup()" id="nexleader-test-what-is-the-ipsat">
                        What is the IPSAT?
                    </h2>
                    <h2 *ngIf="isUserInSmallGroup()">
                        Introducing IPSAT for Small Groups
                    </h2>
                    <div *ngIf="surveyTypeIdentifier==='faith_based'">
                        <p class="m-t-md" *ngIf="!isUserInSmallGroup()">
                            This 8-minute video will help you understand what to
                            expect from this process. Our research shows that
                            individuals who watch this video save time and get
                            more benefits out of the experience.
                        </p>
                        <p class="m-t-md" *ngIf="isUserInSmallGroup()">
                            This 7-minute video will help you understand what to
                            expect from this process. Our research shows that
                            individuals who watch this video save time and get
                            more benefits out of the experience.
                        </p>
                    </div>
                    <div *ngIf="surveyTypeIdentifier==='values_based'">
                        <p class="m-t-md">
                            This 10-minute video will help you understand what
                            to expect from this process. Our research shows that
                            individuals who watch this video save time and get
                            more benefits out of the experience.
                        </p>
                    </div>
                </div>

                <div class="m-t-lg">
                    <div class="nexleader-onboarding-video-container">
                        <app-nexleader-vimeo-video *ngIf="surveyTypeIdentifier === 'faith_based'"
                            [vimeoId]="'360904957'">
                        </app-nexleader-vimeo-video>
                        <app-nexleader-vimeo-video *ngIf="surveyTypeIdentifier === 'values_based'"
                            [vimeoId]="'360905830'">
                        </app-nexleader-vimeo-video>
                    </div>
                </div>

                <div class="form-group m-t-xl">
                    <button class="btn btn-success nexleader-onboarding-full-button"
                        (click)="setWatchedVideoAndNext(true)" id="nexleader-e2e-target-finish-onboarding-video">
                        Finished
                    </button>
                </div>

                <div class="form-group text-center m-t-md">
                    <a (click)="setWatchedVideoAndNext(false)">
                        I'll watch this later...
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-6 nexleader-onboarding-gray h-100-subtract flex overflow-y-scroll">
            <div class="nexleader-onboarding-preassessments-padding p-v-50 m-t-a m-b-a">
                <p class="m-b-xl" *ngIf="!isUserInSmallGroup()">
                    The IPSAT process below is designed to help you discover
                    your "identity profile" and quantify how well you understand
                    it.
                    <a href="http://myipsat.com" target="_blank">Read More</a>
                </p>
                <h2 class="m-b-xl" *ngIf="isUserInSmallGroup()">
                    The IPSAT small group process:
                </h2>
                <div class="m-b-lg">
                    <div class="flex flex-vertical-center">
                        <span class="nexleader-number-highlight nexleader-number-highlight-active">
                            1
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 class="font-semi-bold">
                            <span *ngIf="!isUserInSmallGroup()">
                                Pre-IPSAT Assessments
                            </span>
                            <span *ngIf="isUserInSmallGroup()">
                                Small Group Meetings
                            </span>
                        </h3>
                    </div>
                    <p class="m-t-md" *ngIf="!isUserInSmallGroup()">
                        The Identity Profile Exercise will guide you through
                        entering the results from five, widely accepted
                        assessments and we’ll create a snapshot of your Identity
                        Profile.
                    </p>
                    <p class="m-t-md" *ngIf="isUserInSmallGroup()">
                        Attend your IPSAT small group meeting to engage video
                        content with discussion questions for Modules 1-7.
                    </p>
                </div>
                <div class="m-b-lg" *ngIf="isUserInSmallGroup()">
                    <div class="flex flex-vertical-center">
                        <span class="nexleader-number-highlight nexleader-number-highlight-active">
                            2
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 class="font-semi-bold">
                            Pre-IPSAT Assessments
                        </h3>
                    </div>
                    <p class="m-t-md">
                        The Self Awareness Exercise and Self Authoring Exercise
                        complete the IPSAT process and help you quantify how
                        well you understand your Identity Profile.
                    </p>
                </div>
                <div class="m-b-lg">
                    <div class="flex flex-vertical-center">
                        <span class="nexleader-number-highlight nexleader-number-highlight-active">
                            <span *ngIf="!isUserInSmallGroup()">2</span>
                            <span *ngIf="isUserInSmallGroup()">3</span>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 class="font-semi-bold">
                            Take the IPSAT
                        </h3>
                    </div>
                    <p class="m-t-md" *ngIf="!isUserInSmallGroup()">
                        The Self Awareness Exercise and Self Authoring Exercise
                        complete the IPSAT process and help you quantify how
                        well you understand your Identity Profile.
                    </p>
                    <p class="m-t-md" *ngIf="isUserInSmallGroup()">
                        After Module 6 you will take the IPSAT and bring your
                        results with you for Module 7 at the final meeting of
                        your group.
                    </p>
                </div>
                <div class="m-b-lg">
                    <div class="flex flex-vertical-center">
                        <span class="nexleader-number-highlight nexleader-number-highlight-active">
                            <span *ngIf="!isUserInSmallGroup()">3</span>
                            <span *ngIf="isUserInSmallGroup()">4</span>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h3 class="font-semi-bold">
                            Learn and Grow
                        </h3>
                    </div>
                    <p class="m-t-md">
                        Through a coach-supported process you’ll discover more
                        of who you are so you can optimize and unleash your
                        potential.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>