/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentStepTwo
 *
 * component
 *
 * This component is responsible for completing step two of the PVI. Step two consists of selecting various ratings for
 *  each previously selected value.
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NexleaderComponentPviAssessmentSelectedValuesComponent } from '../pvi-assessment-selected-values/pvi-assessment-selected-values.component';
import { NexleaderInputRangeComponent } from '../../../core/components/input-range/input-range.component';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-step-two',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderComponentPviAssessmentSelectedValuesComponent,
    NexleaderInputRangeComponent,
  ],
  templateUrl: './pvi-assessment-step-two.component.html',
})
export class NexleaderComponentPviAssessmentStepTwoComponent implements OnInit {
  /**
   * topTwelveValues
   *
   * array
   *
   * An array of the top twelve values that the user has selected. This array is filled within this component
   *  and utilized in the parent component.
   */
  @Input() topTwelveValues!: any[];
  selectedValue: any;

  ngOnInit(): void {
    this.validateInputParams();
    this.initializeSelectedValue();
  }

  validateInputParams(): void {
    if (!Array.isArray(this.topTwelveValues)) {
      throw new Error('Input param `topTwelveValues` must be an array.');
    }
  }

  initializeSelectedValue(): void {
    if (!this.topTwelveValues || this.topTwelveValues.length === 0) {
      throw new Error('Input param `topTwelveValues` cannot be null or empty.');
    }

    // Select the first element in the topTwelveValues array
    this.selectedValue = this.topTwelveValues[0];
    // Reset the rating, so we can always start at 0
    this.selectedValue.rating = 0;
  }

  /**
   * moveToPreviousValue()
   *
   * function
   *
   * Moves the user to the previous value than the one they selected
   */
  moveToPreviousValue(): void {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );

    // If they are currently on the first value, they can't move backwards
    if (currentValueIndex === 0) return;

    this.selectedValue = this.topTwelveValues[currentValueIndex - 1];
  }

  /**
   * moveToNextValue()
   *
   * function
   *
   * Moves the user to the next value than the one they selected
   */
  moveToNextValue(): void {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );

    // If they are currently on the last value, they can't move forwards
    if (currentValueIndex === this.topTwelveValues.length - 1) return;

    this.selectedValue = this.topTwelveValues[currentValueIndex + 1];

    // Reset the rating, so we can always start at 0
    if (!this.selectedValue.rating) {
      this.selectedValue.rating = 0;
    }
  }

  /**
   * canMoveToPreviousValue()
   *
   * helper function
   *
   * Determines whether or not the user can move to the previous value.
   *
   * @return {boolean}
   */
  canMoveToPreviousValue(): boolean {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );
    return currentValueIndex !== 0;
  }

  /**
   * canMoveToNextValue()
   *
   * helper function
   *
   * Determines whether or not the user can move to the next value.
   *
   * @return {boolean}
   */
  canMoveToNextValue(): boolean {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );
    return currentValueIndex !== this.topTwelveValues.length - 1;
  }
}
