/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../services/enum.service';
import { UserService } from '../../../../services/user.service';
import { GroupService } from '../../../../services/group.service';
import { BlobResourceService } from '../../../../services/blob.service';
import { SuccessService } from '../../../../services/success.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import { PersonalValuesInventoryResultService } from '../../../../services/personal-values-inventory-result.service';
import { NexleaderProfileWizardUploadFileComponent } from '../profile-wizard-upload-file/profile-wizard-upload-file.component';
import { NexleaderInputPassionsComponent } from '../input-passions/input-passions.component';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-profile-wizard',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgModelDebounceChangeDirective,
    NexleaderProfileWizardUploadFileComponent,
    NexleaderInputPassionsComponent,
    RouterLink
  ],
  templateUrl: './profile-wizard.component.html',
})
export class NexleaderProfileWizardComponent implements OnInit {
  routeParams: any;

  categories = [
    { name: 'Personality', desc: 'Myers Briggs' },
    { name: 'Strengths', desc: 'Clifton Strengths' },
    { name: 'Skills', desc: 'Skills Inventory' },
    { name: 'Spiritual Gifts', desc: 'Spiritual Gifts Assessment' },
    { name: 'Passions', desc: 'MyPassionProfile.com' },
  ];

  model = {
    strengths: [
      { name: '' },
      { name: '' },
      { name: '' },
      { name: '' },
      { name: '' },
    ],
    skills: [{ name: '' }, { name: '' }, { name: '' }],
    spiritualGifts: [{ name: '' }, { name: '' }, { name: '' }],
    passions: [
      { name: '', level: '' },
      { name: '', level: '' },
      { name: '', level: '' },
    ],
  };

  user: any;
  files: Blob[] = [];
  mbtis: any;
  levels: any;
  prerequisites: any;
  allStrengths: any;
  allSpiritualGifts: any;
  surveyTypeIdentifier: any;
  pviResults: any[] = [];
  pviResultId: string = '';
  topThreeValues: any[] = [];

  showEdit = true;
  currentCategory: any;

  constructor(
    private route: ActivatedRoute,
    private enumsService: EnumsService,
    private userSvc: UserService,
    private groupSvc: GroupService,
    private blobSvc: BlobResourceService,
    private errorHandlerService: ErrorHandlerService,
    private pviResultSvc: PersonalValuesInventoryResultService,
    private successHandler: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    combineLatest([
      this.groupSvc.getLite(
        this.routeParams['group_id'],
        this.routeParams['user_id']
      ),
      this.userSvc.getUser(this.routeParams['user_id']),
      this.blobSvc.queryUser(
        this.routeParams['user_id'],
        this.routeParams['group_id']
      ),
      this.enumsService.getEnums(),
    ])
      .pipe(
        tap(([group, user, files, enums]) => {
          this.surveyTypeIdentifier = group.surveyType.identifier;
          this.user = user;
          if (typeof this.user.prerequisites === 'undefined') {
            this.user.prerequisites = this.model;
          }
          if (this.user.group.surveyType.identifier === 'values_based') {
            this.loadPersonalValuesInventoryResults();
          }

          this.files = files;

          this.mbtis = enums.AllMBTI;
          this.levels = enums.AllLevels;
          this.prerequisites = enums.Prerequisites;
          this.allStrengths = enums.AllDefaultStrengths;
          this.allSpiritualGifts = enums.AllDefaultSpiritualGifts;

          this.user.prerequisites.completedPassions = true;
          this.user.prerequisites.completedSpiritualGifts = true;
          this.user.prerequisites.completedSkills = true;
          this.user.prerequisites.completedStrengths = true;
          this.user.prerequisites.completedMbti = true;
          this.saveUser();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  trackByIndex(index: number, group: any): number {
    return index;
  }

  trackByName(index: number, group: any): string {
    return group.name;
  }

  compareName(o1: any, o2: any) {
    if (o1?.name === o2?.name) return true;
    else return false;
  }

  saveUser(): void {
    this.userSvc
      .saveUser(this.user)
      .pipe(
        tap((_) => {
          this.successHandler.handle();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  changeCategory(c: any): void {
    this.currentCategory = c;
  }

  shouldShowValuesBasedCopy(): boolean {
    return (
      this.user && this.user.group.surveyType.identifier === 'values_based'
    );
  }

  shouldShowSpiritualityBasedCopy(): boolean {
    return this.user && this.user.group.surveyType.identifier === 'faith_based';
  }

  /**
   * loadPersonalValuesInventoryResults()
   *
   * function
   *
   * Loads all PVI results for an individual user based off of their user_id.
   */
  loadPersonalValuesInventoryResults() {
    this.pviResultSvc
      .queryUser(this.user._id)
      .pipe(
        tap((pviResults) => {
          this.pviResults = pviResults;
          // There must be at least one PVI result
          if (this.pviResults.length > 0) {
            this.pviResultId = this.pviResults[0]._id;
            // We want to get this off the first result
            this.topThreeValues = this.pviResults[0].topTwelveValues
              // Filter those in the top three
              .filter((a: any) => a.isInTopThree)
              // Sort by the top three index so we can ensure that the data is in the correct order
              .sort((a: any, b: any) => a.topThreeIndex - b.topThreeIndex);
            // We still assign this to spiritualGifts to ensure consistency in our data model
            this.user.prerequisites.spiritualGifts = this.topThreeValues;
          }
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
