/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderPreIpsatChecklist
 *
 * angular component: input-like state manipulator
 *
 * Visually, this component is the sidebar of preassessments third step of the onboarding.
 *
 * This component is responsible for rendering a list of $ctrl.user.prerequisites as a sidebar.
 * This component displays the list of $ctrl.user.prerequisites in the third step of the onboarding. It also displays
 *  the "Personal Info" step as an option in case the user wishes to go back. Clicks to this
 *  are passed to the onSelectPersonalInfo expression binding. We also display the IPSAT as
 *  a step if the user is part of a small group.
 *
 * A strong case could be made that this component should not be separate from the nexleaderOnboardingPreassessments
 *  component since the states overlap. These components remain separate due to the size of the main preassessment
 *  component. Separating the sidebar makes it easier to manage the state and keep the sidebar testable.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-pre-ipsat-checklist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pre-ipsat-checklist.component.html'
})
export class NexleaderPreIpsatChecklistComponent implements OnInit {
  /**
   * onSelectPersonalInfo
   *
   * expression binding
   *
   * Allows the user to go back to the previous step where they enter information for their account.
   */
  @Output() onSelectPersonalInfo = new EventEmitter();

  /**
   * onSelectPrereq
   *
   * expression binding
   *
   * Allows the parent component to listen for "select prereq" events.
   */
  @Output() onSelectPrereq = new EventEmitter();

  /**
   * selectedPrerequisite
   *
   * two-way data binding
   *
   * Inside this component, we highlight the prereq that matches this value as selected in
   *  the list.
   * We update this value when a new prereq is selected.
   */
  @Input() selectedPrerequisite: any;
  @Output() selectedPrerequisiteChange = new EventEmitter();

  /**
   * user
   *
   * two-way data binding
   *
   * Inside this component, we use the user value to render the prereq checklist and its
   *  checkmarks
   * This component does not directly modify this state.
   */
  @Input() user: any;

  /**
   * onboardingExperience
   * 
   * two-way data binding
   * 
   * We need to determine whether we want to show the content for the ecouse or the
   *  regular IPSAT.
   */
  @Input() onboardingExperience: any;

  AllPrerequisites: any[] = [];

  constructor(private enumsService: EnumsService, private errorHandler: ErrorHandlerService) { }

  /**
   * $onInit()
   *
   * angular event handler function
   *
   * called when the component binding is complete
   *
   * validates bindings
   */
  ngOnInit() {
    if (!this.user) throw new Error('Component PreIPSATChecklist requires two-way object binding user');
    if (!this.user.prerequisites) throw new Error('Component PreIPSATChecklist requires two-way object binding user.prerequisites');
    if (this.selectedPrerequisite === undefined) throw new Error('Component PreIPSATChecklist requires two-way object binding selectedPrerequisite');

    this.enumsService.getEnums().pipe(
      tap((data) => {
        this.AllPrerequisites = data.AllPrerequisites;
        if (this.selectedPrerequisite === null) {
          this.selectedPrerequisite = this.AllPrerequisites[0];
          this.selectedPrerequisiteChange.emit(this.selectedPrerequisite);
        }
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  /**
   * selectPersonalInfo()
   *
   * function
   *
   * called when the personal info prereq is selected
   */
  selectPersonalInfo() {
    this.onSelectPersonalInfo.emit();
  }

  /**
   * isPrereqSelected()
   *
   * pure function
   *
   * tests whether or not a prerequisite is the currently selected prerequisite
   *  used by the view to highlight the selected prerequisite
   *
   * @param prereq the prereq to test
   * @returns {boolean} true if the index matches the selected prereq's index
   */
  isPrereqSelected(prereq: any): boolean {
    //If it's falsy, it's definetely not selected
    if (!prereq) return false;
    return this.selectedPrerequisite.index === prereq.index;
  }

  /**
   * isUserInSmallGroup()
   *
   * pure function
   *
   * called by UI logic and canSelectPrereq logic to determine if the user is in a small gorup
   *
   * @returns boolean true if the user is in a small group; false if not
   */
  isUserInSmallGroup(): boolean {
    return !!this.user.smallGroup;
  }

  /**
   * getIconPathForPrereq()
   *
   * pure function
   *
   * @returns string the path of the svg icon for a specific prerequisite
   */
  getIconPathForPrereq(prerequisite: any): string {
    return `/assets/images/icons/${prerequisite.name.toLowerCase().replace(' ', '')}.svg`;
  }

  /**
   * isPrereqComplete()
   *
   * pure function
   *
   * @returns boolean true if a prerequisite is complete and should show a checkmark
   */
  isPrereqComplete(prereq: any): boolean {
    //If it's falsy, it can't be done
    if (!prereq) return false;
    //Can't select anything until the user loads
    if (!this.user || !this.user.prerequisites) return false;

    const nonemptyStringObj = (strO: any) => {
      if (!strO) return false;
      return typeof strO.name === 'string' && strO.name.trim() !== '';
    };

    switch (prereq.identifier) {
      case 'mbti':
        return this.user.prerequisites.completedMbti && this.user.prerequisites.mbti && this.user.prerequisites.mbti.name;
      case 'strengths':
        return this.user.prerequisites.completedStrengths && this.user.prerequisites.strengths && this.user.prerequisites.strengths.length === 5 && this.user.prerequisites.strengths.every(nonemptyStringObj);
      case 'skills':
        return this.user.prerequisites.skills && this.user.prerequisites.skills.length === 3 && this.user.prerequisites.skills.every(nonemptyStringObj);
      case 'spiritual_gifts':
        return this.user.prerequisites.completedSpiritualGifts && this.user.prerequisites.spiritualGifts && this.user.prerequisites.spiritualGifts.length === 3 && this.user.prerequisites.spiritualGifts.every(nonemptyStringObj);
      case 'passions':
        return this.user.prerequisites.completedPassions && this.user.prerequisites.passions && this.user.prerequisites.passions.length === 3 && this.user.prerequisites.passions.every(nonemptyStringObj) && this.user.prerequisites.passions.every((passion: any) => passion.level && passion.level.name);
      default:
        throw new Error(`Unknown prereq identifier; cannot determine completeness; '${prereq.identifier}'`);
    }
  }

  /**
   * getPrerequisites()
   *
   * pure function
   *
   * returns the prerequisite objects to be iterated over by the
   *  side bar to produce all module elements
   *
   * @returns {*} the prereqs object off of the user model
   */
  getPrerequisites() {
    return this.AllPrerequisites;
  }

  /**
   * canSelectPrereq()
   *
   * pure function
   *
   * returns true if the user is allowed to select a prereq in this UI
   *
   * If the user is not in a small group he/she is allowed to select any prereq at anytime in any order.
   * For small group users we only allow them to move between prereqs if they have met two criteria:
   * 1. the prereq is already complete on the user model
   * 2. the prereq is next in line to be completed by the user
   *
   * @param prereq - A prerequisite we are using to validate whether or not the user can move.
   *
   * @returns boolean A boolean that defines whether or not the user can select this prereq.
   */
  canSelectPrereq(prereq: any): boolean {
    //If it's falsy, you can't select it
    if (!prereq) return false;
    //Can't select anything until the user loads
    if (!this.user || !this.user.prerequisites) return false;
    //If the user isn't in a small group, they can select whatever they want
    if (!this.isUserInSmallGroup()) return true;
    // If the user is in an ecourse, they cannot select another prereq
    if (this.onboardingExperience.identifier === 'ecourse') return false;

    //If the user is in a small group, they can only select prereqs in order
    // notice in this switch statement that all of the known cases fall through
    // and that the prereqs are in reverse order
    // e.g. if skills is to be selected, strengths and mbti must be completed first
    switch (prereq.identifier) {
      case 'passions':
        if (!this.isPrereqComplete({ identifier: 'spiritual_gifts' })) return false;
        if (!this.user.smallGroupStep.hasCompletedModule5) return false;
        break;
      //falls through
      case 'spiritual_gifts':
        if (!this.isPrereqComplete({ identifier: 'skills' })) return false;
        if (!this.user.smallGroupStep.hasCompletedModule4) return false;
        break;
      //falls through
      case 'skills':
        if (!this.isPrereqComplete({ identifier: 'strengths' })) return false;
        if (!this.user.smallGroupStep.hasCompletedModule3) return false;
        break;
      //falls through
      case 'strengths':
        if (!this.isPrereqComplete({ identifier: 'mbti' })) return false;
        if (!this.user.smallGroupStep.hasCompletedModule2) return false;
        break;
      //falls through
      case 'mbti':
        if (!this.user.smallGroupStep.hasCompletedModule1) return false;
        return true;
      default:
        throw new Error(`Could not process prereq. Unrecognized prereq identifier; '${prereq.identifier}'`);
    }
    return false;
  }

  /**
   * canSelectIpsat()
   *
   * the ipsat module only shows up for small groups
   *
   * @returns {boolean} true if the ipsat module can be selected
   */
  canSelectIpsat(): boolean {
    //This is a shortcut. If we can select passions, then
    // we know everything before passions is marked complete.
    //Then, we check if passions is complete. If it is,
    // we can select the IPSAT module
    if (!this.canSelectPrereq({ identifier: 'passions' })) return false;
    if (!this.user.smallGroupStep.hasCompletedModule6) return false;
    return this.isPrereqComplete({ identifier: 'passions' });
  }

  /**
   * selectPrereq()
   *
   * function
   *
   * called when a selectable prereq (that is not the fake personal info prereq) is clicked
   *
   * @param prereq the prereq object to select
   * @returns boolean true if the prereq was selected; false if it was not selectable
   */
  selectPrereq(prereq: any): boolean {
    if (!this.canSelectPrereq(prereq)) return false;
    this.selectedPrerequisite = prereq;
    this.selectedPrerequisiteChange.emit(this.selectedPrerequisite);
    this.onSelectPrereq.emit();
    return true;
  }

  /**
   * getNameForPrereq()
   *
   * function (depends on internal state)
   *
   * returns the section name for the provided prereq considering
   *  1. Small Group vs. Non Small Group
   *  2. Spiritual Gifts vs. Values-Based
   *
   * returns null if the group hasn't loaded
   */
  getNameForPrereq(prereq: any) {
    if (!this.user) return null;

    if (this.onboardingExperience && this.onboardingExperience.identifier === 'ecourse') {
      switch (prereq.identifier) {
        case 'mbti':
          return { name: 'Module 2', desc: 'Exploring your Personality' };
        case 'strengths':
          return { name: 'Module 3', desc: 'Discovering Your Strengths' };
        case 'skills':
          return { name: 'Module 4', desc: 'Developing Your Skills' };
        case 'spiritual_gifts':
          return { name: 'Module 5', desc: 'Refining Your Personal Values' };
        case 'passions':
          return { name: 'Module 6', desc: 'Uncovering Your Passions' };
      }
    }

    let name = prereq.name;
    let desc = prereq.description;

    //If the user's group is in values-based-mode, swap gifts for values
    if (name === 'Spiritual Gifts' && this.user.group.surveyType.identifier === 'values_based') {
      name = 'Values';
      desc = 'Personal Values Inventory';
    }

    // We want to show "Skills Inventory" rather than "Leadership Skills Inventory"
    // We don't want to mess with the crusty enum, so this is the easiest way to fix that
    if (desc === 'Leadership Skills Inventory') {
      desc = 'Skills Inventory';
    }

    //If the user is in a small group, call it a module
    if (this.isUserInSmallGroup()) {
      return { name: `Module ${prereq.index + 1} ${name}`, desc: '' };
    }

    //Return the standard name
    return { name: name, desc: desc };
  }
}
