/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { AuthService } from './../../../../services/auth.service';
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { EnumsService } from './../../../../services/enum.service';
import { UserService } from './../../../../services/user.service';
import { SurveyResultService } from './../survey-result/survey-result.service';
import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';
import { NexleaderVimeoVideoComponent } from '../../../small-groups/components/vimeo-video/vimeo-video.component';
import { SurveyResultCalculationsService } from '../survey-result/survey-result-calculations.service';
import { Subscription, catchError, forkJoin, of, tap } from 'rxjs';
import { NexleaderSmallGroupVideoComponent } from '../../../small-groups/components/small-group-video/small-group-video.component';
import { environment } from '../../../../../environments/environment';
import { Chart } from 'chart.js';
import { CHART_COLORS } from '../../../../../../constants';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-survey-results',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NexleaderVimeoVideoComponent,
    NexleaderSmallGroupVideoComponent,
  ],
  templateUrl: './survey-results.component.html',
})
export class NexleaderSurveyResultsComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  routeParams: any;
  ipsatResults: any[] = [];
  user: { [key: string]: any[] } = {};
  categories: any[] = [];
  possessivePrefix: string | undefined;
  surveyType!: string;
  canvasSubscription!: Subscription;
  @ViewChildren('canvas') canvas!: QueryList<HTMLCanvasElement>;
  private chartInstances: Chart[] = [];
  public doughnutStyle: { [key: string]: string } = {};

  constructor(
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private authService: AuthService,
    private enumsService: EnumsService,
    private surveyResultService: SurveyResultService,
    private surveyResultCalculationsService: SurveyResultCalculationsService
  ) { }

  public onCompare(
    _left: KeyValue<any, any>,
    _right: KeyValue<any, any>
  ): number {
    return 1;
  }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    const userID = this.routeParams['user_id'];

    // Declare the style to show elements as when printing
    this.doughnutStyle = {
      'max-width': '290px',
      'margin-left': 'auto',
      'margin-right': 'auto',
    };

    forkJoin([
      this.surveyResultService.queryUser(userID),
      this.userService.getUser(userID),
      this.enumsService.getEnums(),
    ])
      .pipe(
        tap(([ipsatResults, user, enums]) => {
          this.ipsatResults = ipsatResults;

          this.user = user;
          this.surveyType = user.group.surveyType.identifier;

          this.categories = enums.AllQuestionCategories;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe(() => {
        this.possessivePrefix =
          this.user['_id'] === this.authService.getTokenPayload().user
            ? 'Your'
            : `${this.user['firstName']}'s`;

        // this.calculations = SurveyResultCalculations;

        this.ipsatResults.forEach((result) => {
          result.total =
            this.surveyResultCalculationsService.calculateTotalIpsatScore(
              result
            );
          result.categories = this.categories;

          result.categories.forEach((category: any) => {
            category.value = 0;
            result.questions.forEach((question: any) => {
              if (question.questionCategory.index === category.index) {
                category.value += question.answer.value;
              }
            });
          });

          result.chartData =
            this.surveyResultCalculationsService.calculateDoughnutChart(
              result.categories,
              result.total
            );
        });
      });
  }

  ngAfterViewChecked(): void {
    if (this.canvas && !this.canvasSubscription) {
      this.canvasSubscription = this.canvas.changes.subscribe((change) => {
        this.ipsatResults.forEach((result) => {
          const ctx = document.getElementById(
            `doughnut${result._id}`
          ) as HTMLCanvasElement | null;

          if (ctx) {
            const chartInstance = new Chart(ctx, {
              type: 'doughnut',
              data: {
                labels: this.surveyResultCalculationsService.doughnutLabels(
                  this.surveyType
                ),
                datasets: [
                  {
                    label: 'Your IPSAT Score breakdown',
                    data: result.chartData,
                    backgroundColor: CHART_COLORS,
                  },
                ],
              },
              options: {
                elements: {
                  arc: {
                    borderWidth: 0,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              },
            });

            // Store the chart instance
            this.chartInstances.push(chartInstance as Chart);
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from canvas changes subscription
    if (this.canvasSubscription) {
      this.canvasSubscription.unsubscribe();
    }

    // Destroy each chart instance
    this.chartInstances.forEach(chart => chart.destroy());
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSurveyResultsComponent',
//   downgradeComponent({
//     component: NexleaderSurveyResultsComponent,
//   }) as angular.IDirectiveFactory
// );
