<div class="text-center m-b-md">
  <small>Score Breakdown</small>
  <br />
  <br />
  <div [ngStyle]="doughnutStyle">
    <canvas id="doughnut-chart"></canvas>
  </div>
</div>
<div class="text-center">
  <h1 class="font-extra-bold" id="nexleader-test-team-report-ipsat-score">
    {{ model.score }}
  </h1>
  <small>IPSAT score out of 100</small>
</div>
