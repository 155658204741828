import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-help-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './help-modal.component.html'
})
export class NexleaderHelpModalComponent {
  constructor(private bsModalService: BsModalService) { }

  close() {
    this.bsModalService.hide();
  }

}
