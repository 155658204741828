/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderViewMppResults
 *
 * view
 *
 * This view contains a basic list that displays all of the MPP results.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassionProfileResultService } from '../../../../services/passion-profile-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { EMPTY, catchError, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-view-mpp-results',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './mpp-results.component.html',
})
export class NexleaderViewMppResultsComponent implements OnInit {
  routeParams: any;
  myPassionProfileResults: any[] = [];

  constructor(
    private passionProfileResultService: PassionProfileResultService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadMyPassionProfileResults();
  }

  private loadMyPassionProfileResults(): void {
    const userId = this.routeParams.user_id;

    // Load the my passion profile results
    this.passionProfileResultService
      .queryUser(userId)
      .pipe(
        tap((results) => {
          this.myPassionProfileResults = results;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderViewMppResultsComponent',
//   downgradeComponent({
//     component: NexleaderViewMppResultsComponent,
//   }) as angular.IDirectiveFactory
// );
