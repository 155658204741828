<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-lg-12">
        <div class="hpanel">
          <div class="panel-heading">
            <h3 *ngIf="isLoggedInAsUser">Your Files</h3>
            <h3 *ngIf="!isLoggedInAsUser">{{ user?.firstName }}'s Files</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-5" *ngIf="isLoggedInAsUser">
        <div class="hpanel">
          <div class="panel-body">
            <app-nexleader-file-upload
              (onUploadComplete)="onFinishedUpload($event)"
              [fileOwner]="user_id"
            ></app-nexleader-file-upload>
          </div>
        </div>
        <div class="hpanel">
          <div class="panel-body">
            <b>What goes in here?</b>
            <p class="m-t-sm">
              You can upload a PDF, Word Document, etc.
              <br /><br />Your coach will be able to view the Pre-IPSAT files
              loaded here. <br /><br />We suggest the max upload size of 20MB.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <p *ngIf="blobs.length === 0">No files have been uploaded yet.</p>
        <app-nexleader-files
          [files]="blobs"
          (onFileDelete)="load()"
          [fileOwner]="user_id"
          [editable]="isLoggedInAsUser"
        ></app-nexleader-files>
      </div>
    </div>
  </div>
</div>
