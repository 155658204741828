<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-body">
            <h2 class="mt-0">Sessions in {{ licensingPartner?.name }}</h2>
            <p>
              Welcome the session view. Here, you can view all sessions in
              {{ licensingPartner?.name }} as a list. It also supports searching
              and sorting.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-body">
            <app-nexleader-sessions-table
              *ngIf="licensingPartnerId"
              [licensingPartnerId]="licensingPartnerId"
            ></app-nexleader-sessions-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
