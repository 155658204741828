/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  getEmail(emailId: string | undefined): Observable<any> {
    const url = `${API_BASE}emails` + (emailId ? `/${emailId}` : '');
    return this.http.get(url, { withCredentials: true });
  }

  save(email: any): Observable<any> {
    return this.http.post(`${API_BASE}emails/${email._id}`, email, {
      withCredentials: true,
    });
  }

  remove(emailId: string): Observable<any> {
    return this.http.delete(`${API_BASE}emails/${emailId}`, {
      withCredentials: true,
    });
  }

  queryGroup(groupId: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}groups/${groupId}/emails`, {
      withCredentials: true,
    });
  }

  sendEmail(emailData: any): Observable<any> {
    return this.http.post(`${API_BASE}emails/send`, emailData);
  }
}
