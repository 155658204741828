<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="container p-v-50-sm">
  <div class="row">
    <div class="col-sm-8 col-sm-offset-2 col-xs-12">
      <div class="hpanel hblue">
        <div class="panel-body">
          <h4 class="text-primary-2 m-b-sm">
            Important Information about Taking the Personal Values Inventory
          </h4>
          <div *ngIf="currentStep(1)" id="nexleader-test-pvi-intro-1">
            It is easier to identify personal values, what is important to you,
            when reflecting on your engagement with specific life domains. The
            Personal Values Inventory (PVI) will guide you through a process of
            identifying values by reflecting on them in four life domains:
            <br /><br />
            <div class="row">
              <div class="col-sm-6 col-xs-12 m-b-md">
                <div class="flex flex-vertical-center m-b-sm">
                  <img src="/assets/images/pvi_icons/self.svg" alt="pvi self icon" class="img-md" />
                  <h5 class="text-primary-2 m-l-sm">Self</h5>
                </div>
                <p>
                  Values reflecting what is important in how you view yourself,
                  and how you want to be perceived by others
                </p>
              </div>
              <div class="col-sm-6 col-xs-12 m-b-md">
                <div class="flex flex-vertical-center m-b-sm">
                  <img src="/assets/images/pvi_icons/others.svg" alt="pvi others icon" class="img-md" />
                  <h5 class="text-primary-2 m-l-sm">Others</h5>
                </div>
                <p>
                  Values reflecting what is important in how you view others and
                  your relationship/interaction with them
                </p>
              </div>
              <div class="col-sm-6 col-xs-12 m-b-md">
                <div class="flex flex-vertical-center m-b-sm">
                  <img src="/assets/images/pvi_icons/vocation.svg" alt="pvi vocation icon" class="img-md" />
                  <h5 class="text-primary-2 m-l-sm">Vocation</h5>
                </div>
                <p>
                  Values reflecting what is important for you in building a
                  career and finding meaning in work
                </p>
              </div>
              <div class="col-sm-6 col-xs-12">
                <div class="flex flex-vertical-center m-b-sm">
                  <img src="/assets/images/pvi_icons/avocation.svg" alt="pvi avocation icon" class="img-md" />
                  <h5 class="text-primary-2 m-l-sm">Avocation</h5>
                </div>
                <p>
                  Values reflecting what is important for you in having fun and
                  finding meaning in leisure
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="currentStep(2)" id="nexleader-test-pvi-intro-2">
            <ol>
              <li class="m-b-sm">
                In this exercise you will select up to 12 personal values by
                reviewing the same list as you reflect on each of the 4 life
                domains.
                <b>Please note, you will not be selecting 12 values per domain,
                  but 12 in total.</b>
                If you select the same value in more than one domain, it will
                only count once against the total of 12. There is no minimum
                number of values per domain, only a maximum of 12 total.
              </li>
              <li class="m-b-sm">
                If you select 12 before you are finished with each domain you
                will have to remove a value before you can add another. If you
                can’t find a value use the search option. If you want to include
                a value you can’t find from the pre-populated list, use the text
                box at the bottom of the page to create a value.
              </li>
              <li>
                Don’t overthink your choices. Start with top of mind responses.
                You will refine and force rank your list later in this process.
              </li>
            </ol>
          </div>
          <div class="flex flex-vertical-center m-t-md">
            <button class="btn btn-default" id="nexleader-test-pvi-intro-back" (click)="previousStep()"
              [attr.disabled]="!canMoveToPreviousStep() ? true : null">
              Back
            </button>
            <div class="flex-grow"></div>
            <span class="text-muted font-semi-bold">Page {{ selectedStep }}/{{ maxStep }}</span>
            <div class="flex-grow"></div>
            <button class="btn btn-default" (click)="nextStep()" id="nexleader-test-pvi-intro-next"
              [attr.disabled]="!canMoveToNextStep() ? true : null" [class.is-hidden]="selectedStep === maxStep">
              Next
            </button>
            <button class="btn btn-success" id="nexleader-test-pvi-intro-start-btn" *ngIf="selectedStep === maxStep"
              (click)="advanceToAssessment.emit()">
              Start Assessment
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>