/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, ErrorHandler, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderHelpUtilityComponent } from '../../../core/components/help-utility/help-utility.component';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { EnumsService } from '../../../../services/enum.service';
import { UserService } from '../../../../services/user.service';
import { NexleaderEcourseModuleOneComponent } from './ecourse-module-one/ecourse-module-one.component';
import { NexleaderEcourseModuleTwoComponent } from './ecourse-module-two/ecourse-module-two.component';
import { NexleaderEcoursePersonalInfoComponent } from './ecourse-personal-info/ecourse-personal-info.component';
import { NexleaderEcourseModuleThreeComponent } from './ecourse-module-three/ecourse-module-three.component';
import { NexleaderEcourseModuleFourComponent } from './ecourse-module-four/ecourse-module-four.component';
import { NexleaderEcourseModuleFiveComponent } from './ecourse-module-five/ecourse-module-five.component';
import { NexleaderEcourseModuleSixComponent } from './ecourse-module-six/ecourse-module-six.component';
import { NexleaderEcourseModuleSevenComponent } from './ecourse-module-seven/ecourse-module-seven.component';

@Component({
  selector: 'app-nexleader-ecourse-onboarding',
  standalone: true,
  imports: [CommonModule, NexleaderHelpUtilityComponent, NexleaderEcourseModuleOneComponent, NexleaderEcourseModuleTwoComponent,
    NexleaderEcoursePersonalInfoComponent, NexleaderEcourseModuleThreeComponent, NexleaderEcourseModuleFourComponent,
    NexleaderEcourseModuleFiveComponent, NexleaderEcourseModuleSixComponent, NexleaderEcourseModuleSevenComponent
  ],
  templateUrl: './ecourse-onboarding.component.html'
})
export class NexleaderEcourseOnboardingComponent implements OnInit {
  routeParams: any;
  user: any;
  ECOURSE_ONBOARDING_STEPS: any;
  ONBOARDING_EXPERIENCES: any;
  ALL_ECOURSE_ONBOARDING_STEPS: any;
  step: any;
  stepIndex: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private enumsService: EnumsService,
    private errorHandler: ErrorHandler
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load() {
    forkJoin([
      this.enumsService.getEnums(),
      this.userService.getUser(this.routeParams['user_id'])
    ]).pipe(
      tap(([enums, user]) => {
        this.ECOURSE_ONBOARDING_STEPS = enums.EcourseOnboardingSteps;
        this.ONBOARDING_EXPERIENCES = enums.OnboardingExperiences;
        this.ALL_ECOURSE_ONBOARDING_STEPS = enums.AllEcourseOnboardingSteps;

        this.user = user;

        this.step = this.user.ecourseOnboardingStep;
        this.stepIndex = this.ALL_ECOURSE_ONBOARDING_STEPS.findIndex((step: any) => step.identifier === this.step.identifier);

        // If the user is not an ecourse user, we want to push them out of the onboarding
        if (this.user.onboardingExperience.identifier !== this.ONBOARDING_EXPERIENCES.ECOURSE.identifier) {
          this.router.navigate(['/']);
        }

        // If the user doesn't already have an empty prerequisites object, create it
        if (!this.user.prerequisites) {
          this.user.prerequisites = {};
        }

        this.handleUserChange();

      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  nextStep() {
    const hasNextStep = this.stepIndex < this.ALL_ECOURSE_ONBOARDING_STEPS.length - 1;
    if (hasNextStep) {
      this.user.ecourseOnboardingStep = this.ALL_ECOURSE_ONBOARDING_STEPS[this.stepIndex + 1];
      this.step = this.user.ecourseOnboardingStep;
      this.handleUserChange();
    }

    // We want to watch the user state to be sure to update the step if it changes
    this.userService
      .saveUser(this.user)
      .pipe(
        tap((_) => {
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  handleUserChange() {
    if (this.user && this.user.ecourseOnboardingStep && this.ALL_ECOURSE_ONBOARDING_STEPS) {
      this.step = this.user.ecourseOnboardingStep;
      this.stepIndex = this.ALL_ECOURSE_ONBOARDING_STEPS.findIndex((step: any) => step.identifier === this.step.identifier);
      this.userService.saveUser(this.user).pipe(
        tap((_) => {
        }), catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      ).subscribe();
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEcourseOnboardingComponent',
//   downgradeComponent({
//     component: NexleaderEcourseOnboardingComponent,
//   }) as angular.IDirectiveFactory
// );

