<div class="flex flex-vertical-center flex-horizontal-center hideOnPrint w-100 h-100vh">
  <button class="btn btn-lg btn-primary" (click)="printResult()">
    <i class="fa fa-print"></i>&nbsp;&nbsp;Print your Personal Values Inventory
    Result
  </button>
</div>
<div class="content animate-panel nexleader-pvi-assessment-result-print" *ngIf="pviAssessmentResultScore">
  <div class="h-100 w-100 flex flex-vertical-center flex-horizontal-center text-center">
    <div>
      <img src="/assets/images/icons/spiritualgifts.svg" alt="Personal Values Inventory icon" class="img-sm" />
      <br />
      <br />
      <h1>Personal Values Inventory Report</h1>
      <br />
      <br />
      <p>
        <b>Prepared for:</b>
        {{ pviAssessmentResultScore.user.firstName }}
        {{ pviAssessmentResultScore.user.lastName }}
      </p>
      <p><b>Completed:</b> {{ pviAssessmentResultScore.createdAt | date }}</p>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="m-b-md p-b-sm flex flex-vertical-center border-bottom">
    <img src="/assets/images/icons/spiritualgifts.svg" alt="PVI logo" height="24px" />
    <div class="flex-grow"></div>
    <h5 class="font-semi-bold">Personal Values Inventory</h5>
  </div>
  <div class="nexleader-team-report-page-num"></div>
  <div class="m-b-lg">
    <h4 class="text-primary-2 font-semi-bold m-b-sm">Introduction</h4>
    <p>
      Your life is impacted by your values, even if you can’t explain what they
      are. Personal values reveal what you believe is important in life. Since
      your intelligence and strengths are value neutral, the only way to ensure
      you contribute to the common good is to define clear boundaries in the
      form of personal values. <br /><br />Values form the river banks for our
      decisions and actions. Sometimes we choose and act in ways that are
      inconsistent with our values, producing negative emotions that erode the
      river banks of our lives. You can only evaluate your values, to determine
      if changes are in order, after you have made them explicit.
      <br /><br />Sometimes when identifying personal values, we discover a gap
      between aspirational values (ideal) and operational values (actual).
      Articulating personal values enables us to live more intentionally and
      increase the alignment between ideal and actual.
    </p>
  </div>
  <div class="m-b-lg">
    <h4 class="text-primary-2 font-semi-bold m-b-sm">Four Life Domains</h4>
    <p class="m-b-lg">
      Identifying values, even when we hold them deeply, can be difficult. The
      process of turning implicit values into explicit values can be helped by
      thinking about what is important to you in specific environments,
      relationships, or activities. We call these life domains. <br /><br />
      The Personal Values Inventory guided you through a process of reflecting
      on what is important to you in the following four life domains:
    </p>
    <div class="row">
      <div class="col-xs-6 m-b-md">
        <img src="/assets/images/pvi_icons/self.svg" alt="pvi self icon" class="img-md m-b-sm" />
        <h5 class="text-primary-2 font-bold">Self</h5>
        <p>
          Values reflecting what is important in how you view yourself, and how
          you want to be perceived by others
        </p>
      </div>
      <div class="col-xs-6 m-b-md">
        <img src="/assets/images/pvi_icons/others.svg" alt="pvi others icon" class="img-md m-b-sm" />
        <h5 class="text-primary-2 font-bold">Others</h5>
        <p>
          Values reflecting what is important in how you view others and your
          relationship/interaction with them
        </p>
      </div>
      <div class="col-xs-6">
        <img src="/assets/images/pvi_icons/vocation.svg" alt="pvi vocation icon" class="img-md m-b-sm" />
        <h5 class="text-primary-2 font-bold">Vocation</h5>
        <p>
          Values reflecting what is important for you in building a career and
          finding meaning in work
        </p>
      </div>
      <div class="col-xs-6">
        <img src="/assets/images/pvi_icons/avocation.svg" alt="pvi avocation icon" class="img-md m-b-sm" />
        <h5 class="text-primary-2 font-bold">Avocation</h5>
        <p>
          Values reflecting what is important for you in having fun and finding
          meaning in leisure
        </p>
      </div>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="nexleader-team-report-page-num"></div>
  <h3 class="text-primary-2 font-semi-bold m-b-md">
    Your Personal Values: Summary Overview
  </h3>
  <div class="m-b-lg row equal-6">
    <div class="col-xs-6 equal-child" *ngFor="
        let value of pviAssessmentResultScore.topTwelveValues;
        let index = index
      " style="page-break-inside: avoid">
      <div *ngIf="index !== 0 && index !== 1 && (index + 1) % 4 === 2">
        <div class="nexleader-team-report-page-num"></div>
        <div class="clearfix"></div>
      </div>
      <div class="h-17px" *ngIf="!(index !== 0 && index !== 1 && (index + 1) % 4 === 2)"></div>
      <div class="hpanel m-b-md">
        <div class="panel-body">
          <div class="pull-right">
            <span class="label label-default" *ngIf="value.isInTopSeven">Top Seven</span>
            <span class="label label-success" style="margin-left: 4px" *ngIf="value.isInTopThree">#{{
              value.topThreeIndex }}</span>
          </div>
          <h4 class="text-primary-2 font-semi-bold">
            {{ value.name }}
          </h4>
          <p class="m-b-sm">
            This value surfaced when I reflected through the life domain of:
          </p>
          <div class="flex flex-vertical-center flex-horizontal-center m-b-md">
            <div class="m-l-sm" *ngFor="let domain of allPersonalValueDomains">
              <img *ngIf="value.arrayOfDomains.indexOf(domain.identifier) !== -1"
                src="/assets/images/pvi_icons/{{ domain.identifier }}.svg" alt="pvi icon" class="img-md" />
              <img *ngIf="value.arrayOfDomains.indexOf(domain.identifier) === -1" src="/assets/images/pvi_icons/{{
                  domain.identifier
                }}-gray.svg" alt="pvi icon" class="img-md" />
            </div>
          </div>
          <p>
            <b>My Description:</b>
            {{
            value.description ||
            "No
            description provided."
            }}
          </p>
          <hr />
          <p class="m-b-lg">
            I have rated this value on the sometimes, mostly, always continuum
            as follows:
          </p>
          <div>
            <div class="progress full progress-small">
              <div [style]="{ width: 100 * (value.rating / 10) + '%' }" role="progressbar"
                class="progress-bar progress-bar-personality">
                <span class="sr-only">35% Complete (success)</span>
              </div>
            </div>
          </div>
          <div class="flex flex-space-between m-t-lg">
            <p class="font-semi-bold small-text">sometimes</p>
            <p class="font-semi-bold small-text">mostly</p>
            <p class="font-semi-bold small-text">always</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>