/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-copy-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './copy-link.component.html',
})
export class NexleaderCopyLinkComponent implements OnInit {
  @Input() link!: string;
  @Input() relativeLink!: string;

  constructor() { }

  ngOnInit() {
    if (this.link && this.relativeLink) {
      console.warn(
        "nexleaderCopyLink can't have both link and relative link. Pick one.",
        this.link,
        this.relativeLink
      );
    }

    if (!this.link) {
      if (!this.relativeLink) {
        console.warn(
          'nexleaderCopyLink requires a link or a relative link. Pick one.',
          this.link,
          this.relativeLink
        );
      }
      this.link = location.href.split('#')[0] + this.relativeLink;
    }
  }
}
