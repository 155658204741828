/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { CoachUserService } from './../../../../services/coach-user.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderWrapperPanelComponent } from '../../../core/components/wrapper-panel/wrapper-panel.component';
import { NexleaderSessionsComponent } from '../../../sessions/views/sessions/sessions.component';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-multigroup-coach-calendar',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderWrapperPanelComponent,
    NexleaderSessionsComponent,
  ],
  templateUrl: './multigroup-coach-calendar.component.html',
})
export class NexleaderMultigroupCoachCalendarComponent implements OnInit {
  routeParams: any;
  coachUser: any;

  constructor(
    private coachUserService: CoachUserService,
    private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.coachUserService
      .getCoachUser(this.routeParams['coach_user_id'])
      .pipe(
        tap((a) => {
          this.coachUser = a;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderMultigroupCoachCalendarComponent',
//   downgradeComponent({
//     component: NexleaderMultigroupCoachCalendarComponent,
//   }) as angular.IDirectiveFactory
// );
