/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';
import { DashboardService } from '../../resources/dashboard.service';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { Chart } from 'chart.js';
import { FormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-nexleader-dashboard-statistics-chart',
  standalone: true,
  imports: [CommonModule, FormsModule, NgChartsModule, BsDatepickerModule],
  templateUrl: './dashboard-statistics-chart.component.html',
})
export class NexleaderDashboardStatisticsChartComponent implements OnInit, OnDestroy {
  models: { [key: string]: any } = {};
  //Load the canvas element from the html template
  // $element provides use with only our elements, so we're pretty safe just
  // taking the first div element.
  private ctx!: HTMLCanvasElement;

  //These are the different time periods for which chart data can be aggregated
  // The value is in seconds, which is what the endpoint accepts
  // The name is human-readable
  aggregationDurations: any[] = [
    {
      name: 'Hour',
      value: 3600,
    },
    {
      name: 'Day',
      value: 86400,
    },
    {
      name: 'Week',
      value: 604800,
    },
    {
      name: 'Month',
      value: 2592000,
    },
    {
      name: 'Year',
      value: 31536000,
    },
  ];

  //True/False with different names; The running total option could be confusing to end-users.
  // In the UI, true corresponds to "Total" and false corresponds to "Change"
  // The option is called Chart Type
  runningTotalOptions: any[] = [
    {
      name: 'Total',
      value: true,
    },
    {
      name: 'Change',
      value: false,
    },
  ];

  //Query params to send to /dashboard/documentsCreateByTime when load() is called
  // The values below are the default values for the chart
  // They are updated by UI components.
  // The loadAndDraw() function should be called upon every debounced change to these values
  // This is accomplished with ng-change
  // See $ctrl.onQueryParamsChange()
  currentDate = new Date();

  queryParams = {
    start: new Date(new Date().getTime() - 32140800000), //One year in the past
    end: new Date(), //Now
    queryIdentifier: 'groups', //groups
    aggregationDuration: 2592000, //Aggregate by month
    runningTotal: true, //Keep a running total (Total)
  };

  //False if loaded; string explanation of what's going on if not
  loadState: string = 'Loading Data';

  data: any;
  labels: any;
  private chart!: Chart;
  constructor(
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.ctx = document.getElementById('canvas') as HTMLCanvasElement;
    this.preload();
    this.loadAndDrawChart();
  }

  trackByIdentifier(_: number, model: any): number {
    return model.key;
  }

  public onCompare(
    _left: KeyValue<any, any>,
    _right: KeyValue<any, any>
  ): number {
    return 1;
  }

  //Used once on the first load of the controller to load static data that does not
  // change when the chart updates
  // For instance, we need to load the allowedModels to populate the list of allowed models
  // Is not called by and should not be called by load() and draw()
  // Does not and should not call load() or draw()
  preload() {
    this.dashboardService
      .queryAllowedModels()
      .pipe(
        tap((allowedModels) => {
          this.models = allowedModels;
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  //Load function (called by load and draw)
  // There's probably no reason to call this function directly
  load() {
    this.loadState = 'Loading Data';

    //Null this so that a chart doesn't show if there's an error
    this.data = null;
    this.labels = null;

    return this.dashboardService
      .getDocumentsCreatedByTime({
        ...this.queryParams,
        start: this.queryParams.start.toISOString(),
        end: this.queryParams.end.toISOString(),
      })
      .pipe(
        tap((result) => {
          this.data = result.data;
          this.labels = result.labels;
          this.loadState = 'Data Loaded';
        }),
        catchError((error: any) => {
          this.loadState = 'Error Loading Data';
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  //Draw function (called by load and draw)
  // There's probably no reason to call this function directly
  drawChart() {
    //If there's no data, warn the console.
    if (!this.data) {
      this.loadState = 'Error: No Data';
      console.warn("Can't draw chart without data; load it first", this.data);
      return;
    }

    //Update the load state in case drawing takes a while.
    this.loadState = 'Drawing Chart';

    if (!this.chart) {
      //Actually configure and draw the chart
      this.chart = new Chart(this.ctx, {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.models[this.queryParams.queryIdentifier].name,
              data: this.data,
              backgroundColor: 'rgba(76,186,114,0.2)',
              borderColor: 'rgba(76,186,114,1)',
              borderWidth: 1,
              pointBackgroundColor: 'rgba(130,210,164,0.2)',
              pointBorderColor: '#82d2a4',
              pointHoverBorderColor: 'rgba(148,159,177,0.8)',
              fill: 'origin',
              tension: 0.4,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          animation: {
            onComplete: () => {
              this.loadState = '';
            },
          },
          responsive: true,
        },
      });
    } else {
      this.chart.data.datasets[0].data = this.data;
      this.chart.data.datasets[0].label =
        this.models[this.queryParams.queryIdentifier].name;
      this.chart.data.labels = this.labels;
      this.chart.update();
    }
  }

  //Loads and draws the chart
  loadAndDrawChart() {
    this.load().add(() => {
      this.drawChart();
    });
  }

  //When the query params change (ng-change), loadAndDraw the chart.
  onQueryParamsChange() {
    if (!this.queryParams.start) {
      console.warn('No start for onQueryParamsChange');
      return;
    }

    if (!this.queryParams.end) {
      console.warn('No end for onQueryParamsChange');
      return;
    }

    this.loadAndDrawChart();
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderDashboardStatisticsChartComponent',
//   downgradeComponent({
//     component: NexleaderDashboardStatisticsChartComponent,
//   }) as angular.IDirectiveFactory
// );
