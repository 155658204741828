/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * InputCoach
 *
 * angular component
 *
 * dynamic selector dropdown for coaches
 *
 * This component is responsible for loading a list of coaches, displaying a dropdown, and
 *  letting the user select one.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-input-coach',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-coach.component.html',
})
export class NexleaderInputCoachComponent implements OnInit {
  /**
   * model
   *
   * two-way object binding
   *
   * basically ng-model
   *
   * selecting an option in the dropdown should mutate this state to the selected option
   * mutating this state from an outside controller should update the dropdown
   * model should not be null in ngOnInit (after binding)
   */
  @Input() model: any;

  /**
   * groupId
   *
   * string binding
   *
   * A string of the ID of the group used to fetch all users within the group.
   */
  @Input() groupId: string = '';
  @Output() realOnChange: EventEmitter<any> = new EventEmitter<any>();
  coaches: any[] = [];

  constructor(
    private userService: UserService,
    private errorHandler: ErrorHandlerService
  ) {}

  /**
   * ngOnInit()
   *
   * function: Angular lifecycle hook
   *
   * called after binding
   */
  ngOnInit(): void {
    // if (this.model === undefined) {
    //   throw new Error('cannot initialize InputCoach. binding this.model is required');
    // }
    if (typeof this.groupId !== 'string') {
      throw new Error(
        'cannot initialize InputCoach. string this.groupId is required'
      );
    }

    // Query all users included in the group
    this.userService
      .queryGroup(this.groupId)
      .pipe(
        tap((users) => {
          this.coaches = users.filter(
            (user) => user.roles.indexOf('coach') >= 0
          );
          this.coaches.forEach((coach) => {
            coach._name = `${coach.firstName} ${coach.lastName}`;
          });

          // change model to undefined if coaches list don't have selected coach
          const selectedCoachIndex = this.coaches.findIndex(
            (c) => c._id === this.model
          );
          if (selectedCoachIndex === -1) {
            this.model = undefined;
          }
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  trackByCoachId(index: number, coach: any) {
    return coach._id;
  }

  onChange(event: any): void {
    this.realOnChange.emit(this.model);
  }
}
