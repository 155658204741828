/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
/* global angular */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-nexleader-youtube-video',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './youtube-video.component.html',
})
export class NexleaderYoutubeVideoComponent implements OnInit {
  /**
   * youtubeId
   *
   * string
   *
   * A string representing the ID of the YouTube video we want to embed.
   */
  @Input() youtubeId: string | undefined;
  videoUrl: SafeResourceUrl | undefined;

  /**
   * YOUTUBE_PLAYER_URL_PREFIX
   *
   * @type {string} the prefix of the URL preprended to the video id before handing it off to the youtube embed code
   */
  private readonly YOUTUBE_PLAYER_URL_PREFIX = 'https://www.youtube.com/embed/';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    // Ensure we have the YouTube ID
    if (!this.youtubeId) {
      throw new Error(
        'nexleaderDataYoutubeId is required for NexleaderYoutubeVideoComponent'
      );
    }

    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.YOUTUBE_PLAYER_URL_PREFIX + this.youtubeId
    );
  }
}
