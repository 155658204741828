<div class="nexleader-chart-strengths-represented-wrapper">
  <canvas id="strength-bar-chart"></canvas>
</div>
<div class="hideOnPrint">
  <a (click)="viewStrengthsInformation()">
    Learn more about the four domains of strength.
  </a>
  <br />
  <br />
</div>
<div class="row">
  <div
    class="col-md-3 col-sm-6 col-xs-12 m-b-md col-3-print text-center"
    *ngFor="let category of model"
  >
    <h5 class="m-b-sm text-strengths-{{ category.identifier }} font-bold">
      {{ category.name }}
    </h5>
    <p *ngIf="category.strengths.length < 1">No strengths for this category.</p>
    <div *ngFor="let strength of category.strengths" class="m-b-xs">
      <p class="cursor-pointer" (click)="viewUsers(strength)">
        {{ strength.name }} ({{ strength.value }})
      </p>
    </div>
  </div>
</div>
