import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NexleaderMaximumWordsValidationDirective } from '../../../../directives/max-words.directive';

@Component({
  selector: 'app-nexleader-skills-inventory-question',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderMaximumWordsValidationDirective,
  ],
  templateUrl: './question.component.html',
})
export class NexleaderSkillsInventoryQuestionComponent {
  @Input() question: any;

  selectQuestionOption(option: any): void {
    this.question.selectedOption = { ...option };
  }
}
