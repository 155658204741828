<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="container p-v-50" *ngIf="teamReport">
  <div class="row">
    <div class="col-md-6 col-xs-12 col-md-offset-3">
      <div class="hpanel">
        <div class="panel-body">
          <h4 class="text-primary-2">Team Report Creation Confirmation</h4>
          <hr class="m-t-md m-b-md" />
          <p class="m-b-md">
            Congratulations! Your team report was just created successfully.
            Below is a quick summary of your new team report.
          </p>
          <h5 class="font-bold text-primary-2 m-b-xs">Users</h5>
          <p class="m-b-sm">
            Users selected to be represented in the team report.
          </p>
          <ul class="m-b-md">
            <li *ngFor="let user of teamReport.teamUsers">
              <span
                id="{{ 'nexleader-test-team-report-confirm-name-' + user._id }}"
                >{{ user.firstName }} {{ user.lastName }}</span
              >
            </li>
          </ul>
          <h5 class="font-bold text-primary-2 m-b-xs">Selected Skills</h5>
          <p class="m-b-sm">
            Skills selected to be compared in the team report.
          </p>
          <ul class="m-b-md">
            <li *ngFor="let skill of teamReport.teamTargetSkills">
              {{ skill.name }} - {{ skill.goalLevel }}
            </li>
          </ul>
          <h5 class="font-bold text-primary-2 m-b-xs">Payment</h5>
          <p id="nexleader-test-team-report-payment-string">
            You paid
            <app-nexleader-currency
              *ngIf="teamReport.price"
              [currencyQuantity]="teamReport.price.currencyQuantity"
              [currencyCode]="teamReport.price.currencyCode"
            ></app-nexleader-currency>
            for creating a team report with
            {{ teamReport.teamUsers.length }} user(s).
          </p>
        </div>
        <div class="panel-footer">
          <div class="flex flex-vertical-center">
            <div class="pull-left font-bold text-muted">
              <i class="fa fa-calendar"></i>&nbsp;&nbsp;Created on
              {{ teamReport.createdAt | date }}
            </div>
            <div class="flex-grow"></div>
            <div class="pull-right">
              <a
                class="btn btn-success"
                id="nexleader-test-team-report-view-report-btn"
                [routerLink]="['/groups', teamReport.group, 'teamReports', teamReport._id]"
              >
                <i class="fa fa-eye"></i>&nbsp;&nbsp;View Report
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
