/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { CoachUserService } from './coach-user.service';
import { SkippedVideoService } from './skipped-video.service';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  private authenticationSubject = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private userService: UserService,
    private coachUserService: CoachUserService,
    private skippedVideoService: SkippedVideoService,
  ) {
    this.initializeAuth();
  }

  getTokenPayload() {
    const token = this.tokenService.getToken();
    if (!token) return null;
    return this.jwtHelper.decodeToken(token);
  }

  logout() {
    this.tokenService.removeToken();
    this.skippedVideoService.hasSkippedVideo = false;

    this._sendAuthEvent();
  }

  get authentication(): Observable<any> {
    return this.authenticationSubject.asObservable();
  }

  private _sendAuthEvent() {
    const payload = this.getTokenPayload();
    if (payload) {
      if (payload.user) {
        this.userService.getUser(payload.user).subscribe(
          (user) => {
            // Assuming $rootScope.$broadcast is replaced with Angular event handling
            // this._rootScope.$broadcast('Authentication', { user });
            // this.rootScope.broadcastEvent('Authentication', { user });
            this.authenticationSubject.next({ user });
          },
          (error) => console.error(error)
        );
      } else if (payload.coach_user) {
        this.coachUserService.getCoachUser(payload.coach_user).subscribe(
          (coachUser) => {
            // Assuming $rootScope.$broadcast is replaced with Angular event handling
            // this._rootScope.$broadcast('Authentication', {
            //   coach_user: coachUser,
            // });
            // this.rootScope.broadcastEvent('Authentication', {
            //   coach_user: coachUser,
            // });
            this.authenticationSubject.next({ coach_user: coachUser });
          },
          (error) => console.error(error)
        );
      } else {
        console.warn(
          'Malformed token in _sendAuthEvent(); should have user or coach_user data',
          payload
        );
      }
    } else {
      // Assuming $rootScope.$broadcast is replaced with Angular event handling
      // this._rootScope.$broadcast('Authentication', {
      //   user: null,
      //   coach_user: null,
      // });
      // this.rootScope.broadcastEvent('Authentication', {
      //   user: null,
      //   coach_user: null,
      // });
      this.authenticationSubject.next({ user: null, coach_user: null });
    }
  }

  initializeAuth() {
    const payload = this.getTokenPayload();
    if (payload) {
      if (payload.user) {
        this.userService.getUser(payload.user).subscribe(
          () => this._sendAuthEvent(),
          () => this.logout()
        );
      } else if (payload.coach_user) {
        this.coachUserService.getCoachUser(payload.coach_user).subscribe(
          () => this._sendAuthEvent(),
          () => this.logout()
        );
      } else {
        console.warn('Bad payload; no user data', payload);
      }
    } else {
      this._sendAuthEvent();
    }
  }

  authEndpoint(credentials: any): Observable<any> {
    return this.http.post(API_BASE + 'auth', credentials, {
      responseType: 'text',
    });
  }

  refreshEndpoint(): Observable<any> {
    return this.http.post(API_BASE + 'auth/refresh', {}, { withCredentials: true, responseType: 'text' });
  }

  authDisambiguation(credentials: any): Observable<any> {
    return this.http.post(API_BASE + 'auth/disambiguation', credentials);
  }

  auth(credentials: any): Observable<null> {
    this.tokenService.removeToken();
    return this.authEndpoint(credentials).pipe(
      switchMap((res) => {
        const data = res;
        this.tokenService.setToken(data);
        this._sendAuthEvent();
        return of(null);
      })
    );
  }

  refresh(): Observable<null> {
    return this.refreshEndpoint().pipe(
      switchMap((res) => {
        this.tokenService.removeToken();
        this.tokenService.setToken(res);
        this._sendAuthEvent();
        return of(null);
      })
    );
  }
}
