/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/**
 * Created by jakebillings on 10/4/16.
 */
/* global angular */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderFilesComponent } from '../files/files.component';
import { NexleaderFileUploadComponent } from '../file-upload/file-upload.component';

@Component({
  selector: 'app-nexleader-profile-wizard-upload-file',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderFilesComponent,
    NexleaderFileUploadComponent,
  ],
  templateUrl: './profile-wizard-upload-file.component.html',
})
export class NexleaderProfileWizardUploadFileComponent implements OnChanges {
  @Input() prerequisite: any;
  @Input() user: any;
  @Input() allFiles: any[] = [];
  @Input() isOptional: boolean = false;

  @Output() load: EventEmitter<void> = new EventEmitter<void>();

  files: any[] = [];

  constructor(private successService: SuccessService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allFiles']) {
      this.files = this.allFiles.filter(
        (a) => a.file.metadata.prerequisite.index === this.prerequisite.index
      );
    }
  }

  onFinishedUpload(file: any): void {
    this.successService.handle({ message: 'Uploaded ' + file.name + '.' });
    this.load.emit();
  }
}
