<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Learn How to Upload a Custom Video</h3>
</div>
<div class="modal-body">
  <p>
    When viewing a video on Vimeo, copy the ID from the URL. The ID is a series
    of numbers that you can find in the URL following the
    <i>vimeo.com</i> address.
    <br />
    <br />
    <b>An example:</b> If a the URL for a Vimeo video is
    <b>https://vimeo.com/12345</b>, the video ID will be <b>12345</b>.
    <br />
    <br />
    <b>Note:</b> In order to embed the video properly, ensure that the video
    access settings are set so that anyone with the link can view the video.
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
