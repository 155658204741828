<div *ngIf="videoUrl">
  <div [ngClass]="{ 'embed-container': fullWidth }">
    <iframe
      [src]="videoUrl"
      width="540"
      height="303"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
  <a [href]="downloadUrl" *ngIf="!hideDownloadLink">Download this Video</a>
</div>
