<div class="row" style="height: 100%" *ngIf="currentStep === 'engagement'">
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center bg-white">
    <div class="nexleader-ecourse-content-wrapper">
      <h3 class="font-bold text-primary-2 text-center" style="line-height: 36px">
        How to Grow Your Self-Awareness and Lead Yourself Better
      </h3>
      <br />
      <div>
        <app-nexleader-ecourse-rali-video [index]="4"></app-nexleader-ecourse-rali-video>
      </div>
      <br />
      <div class="text-center">
        <a class="btn btn-success btn-lg" href="/assets/files/ecourse/module4.pdf" target="_blank">Download
          Module 4 Application Note Guide</a>
      </div>
    </div>
  </div>
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center">
    <div class="nexleader-ecourse-content-wrapper">
      <div class="text-center">
        <img [src]="'/assets/images/icons/skills.svg'" alt="skills" class="m-b-lg" width="80" />
      </div>
      <div class="nexleader-ecourse-heading flex">
        <div class="font-bold nexleader-ecourse-heading-left">
          Module<span>4</span>
        </div>
        <h3 class="font-bold text-primary-2 m-l-md">Developing Your Skills</h3>
      </div>
      <br />
      <div class="p-v-50">
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-download text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">Download the Application Note Guide</h4>
          </div>
        </div>
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-play text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">Engage with the video segments</h4>
          </div>
        </div>
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-undo text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">
              Reflect & Respond to the Action Steps
            </h4>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-success btn-lg" (click)="nextStep()">
            {{ mapContinueButtonTextForStep() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" style="height: 100%" *ngIf="currentStep === 'assessment'">
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center bg-white">
    <div class="nexleader-ecourse-content-wrapper">
      <div class="nexleader-onboarding-preassessments-padding">
        <div class="text-center">
          <img [src]="'/assets/images/icons/skills.svg'" style="width: 70px" alt="personality" />
          <div class="text-center">
            <h3 class="m-t-md">Skills: Skills Inventory</h3>
            <p class="m-t-md">
              Your second pre-assessment is the Skills Inventory.
            </p>
          </div>
        </div>
      </div>
      <app-nexleader-onboarding-skills (save)="save()" [user]="user" [scoredSkillsInventory]="scoredSkillsInventory">
      </app-nexleader-onboarding-skills>
      <br />
      <div class="text-center">
        <button class="btn btn-success nexleader-onboarding-full-button" *ngIf="canProceedToNextStep()"
          (click)="nextStep()">
          Continue
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center">
    <div class="nexleader-ecourse-content-wrapper">
      <app-nexleader-pre-ipsat-checklist *ngIf="currentPrerequisite" [user]="user"
        [selectedPrerequisite]="currentPrerequisite" [onboardingExperience]="user.onboardingExperience">
      </app-nexleader-pre-ipsat-checklist>
    </div>
  </div>
</div>