/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderWrapperPanelComponent } from '../../../core/components/wrapper-panel/wrapper-panel.component';
import { NexleaderSessionsComponent } from '../sessions/sessions.component';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-coach-calendar',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderWrapperPanelComponent,
    NexleaderSessionsComponent,
  ],
  templateUrl: './coach-calendar.component.html',
})
export class NexleaderCoachCalendarComponent implements OnInit {
  routeParams: any;

  coach: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadCoach();
  }

  private loadCoach(): void {
    this.userService
      .getUser(this.routeParams['coach_id'])
      .pipe(
        tap((coach) => {
          this.coach = coach;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCoachCalendarComponent',
//   downgradeComponent({
//     component: NexleaderCoachCalendarComponent,
//   }) as angular.IDirectiveFactory
// );
