/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderTakePVI
 *
 * angular component: "take assessment" component
 *
 * This component is a "Take Assessment" component meaning it is similar to "Take Skills Inventory" and "Take IPSAT".
 * It is responsible for providing validation of prerequesites for and providing introductory copy for Personal
 *  Values Assessment. Then, it launches the PVI assessment
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nexleader-view-take-pvi',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './take-pvi.component.html',
})
export class NexleaderViewTakePVIComponent implements OnInit {
  routeParams: any;
  userId!: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.userId = this.routeParams['user_id'];

    if (!this.userId) {
      throw new Error(
        'userId is required for nexleaderTakePVI component: ' + this.userId
      );
    }
  }

  go(): void {
    this.router.navigate(['/users', this.userId, 'pviAssessment']);
  }

  goBack(): void {
    this.router.navigate(['/']);
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderViewTakePVIComponent',
//   downgradeComponent({
//     component: NexleaderViewTakePVIComponent,
//   }) as angular.IDirectiveFactory
// );
