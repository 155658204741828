import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StripePaymentService } from '../../../../services/stripe-payment.service';

@Component({
  selector: 'app-nexleader-stripe-element-modal',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './stripe-element-modal.component.html',
})
export class NexleaderStripeElementModalComponent implements OnInit {
  title: string = '';
  description: string = '';
  currencyCode: string = 'usd';
  amount!: number;
  email: string = '';
  isEmailValid: boolean = true;
  isEmailEditable = true;
  cardElement: any;

  @Output() tokenGenerated = new EventEmitter();

  constructor(private stripePaymentService: StripePaymentService) { }

  ngOnInit(): void {
    if (this.amount <= 0) {
      setTimeout(() => {
        this.tokenGenerated.emit(null);
      });
      return;
    }
    this.stripePaymentService
      .createStripeElements(this.currencyCode, this.amount)
      .then((elements: any) => {
        if (this.isEmailEditable) {
          const linkAuthenticationElement =
            elements.create('linkAuthentication');
          linkAuthenticationElement.mount('#link-authentication-element');
          linkAuthenticationElement.on('change', (event: any) => {
            this.isEmailValid = event.complete ? true : false;
            this.email = event.value.email;
          });
        }

        this.cardElement = elements.create('card');
        this.cardElement.mount('#stripe-elements');
      });
  }

  pay() {
    if (!this.email || !this.isEmailValid) {
      return;
    }

    this.stripePaymentService.createStripeToken(this.cardElement).then(
      (response: any) => {
        const token = { ...response, email: this.email };
        this.tokenGenerated.emit(token);
      },
      (error) => {
        // Error generating token
      }
    );
  }
}
