/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderSession
 *
 * angular component
 *
 * This component is responsible for handling inputs of a session. This component is used both for creating a new
 *  session, but also for updating an existing session.
 */
import { catchError, of, tap } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionService } from './session.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { UserService } from '../../../../services/user.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TelephonePipe } from '../../../../pipes/telephone.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NexleaderTimezoneSelectComponent } from '../../../core/components/timezone-select/timezone-select.component';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

const newSessionObject = {
  timezone: '',
};

@Component({
  selector: 'app-nexleader-session',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TelephonePipe,
    BsDatepickerModule,
    FormsModule,
    TimepickerModule,
    NexleaderTimezoneSelectComponent,
  ],
  templateUrl: './session.component.html',
})
export class NexleaderSessionComponent implements OnInit {
  /**
   * session_id
   *
   * string
   *
   * A string representing an ID of an existing session that can be used to query the session.
   */
  @Input() session_id!: string;

  /**
   * coach_id
   *
   * string
   *
   * A string representing an ID of the preselected coach to be creating a session for.
   *
   * Used by coaches.
   */
  @Input() coach_id!: string;

  /**
   * group_id
   *
   * A string representing an ID of the preselected group to be creating a session for.
   *
   * Used by super admins.
   */
  @Input() group_id!: string;
  routeParams: any;

  /**
   * onSave()
   *
   * angular function binding
   *
   * An angular function that is called after the session is saved. This is like a callback. Can be useful for
   *  closing a modal.
   */
  @Output() onSave = new EventEmitter();

  session: any;
  coaches!: any[];
  participants!: any[];
  groupName!: string;
  noCoachesForGroup!: boolean;
  timelocked!: boolean;

  // Configure default UI states for the view
  start!: Date;
  startOpen = false;
  end!: Date;
  endOpen = false;

  get calendarDownloadUrl() {
    return `${environment.apiUrl}sessions/${this.session._id}/export/ics`;
  }

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private sessionService: SessionService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  ngOnInit(): void {
    // session component is always opned through modal which is placed at the root of DOM
    // so we are using params from route.firstChild
    this.routeParams = this.route.firstChild?.snapshot.params;
    // const queryParams = this.routerHelper.fetchQueryParams();
    // Configure defaults if none exist
    this.session_id =
      this.session_id || this.routeParams?.session_id || 'new';
    this.coach_id = this.coach_id || this.routeParams?.coach_id;

    this.loadGroupOrSession();
    this.updateStartTime();
  }

  loadGroupOrSession(): void {
    // const queryParams = this.routerHelper.fetchQueryParams();
    if (this.session_id === 'new') {
      this.timelocked = true;
      this.session = { ...newSessionObject };

      if (this.coach_id) {
        this.loadCoach();
      } else {
        this.loadGroup(this.group_id || this.routeParams?.group_id);
      }
    } else {
      this.loadSession(this.session_id);
    }
  }

  loadCoach(): void {
    this.userService
      .getUser(this.coach_id)
      .pipe(
        tap((coach) => {
          this.session = { coach, timezone: this.session.timezone};
          this.groupName = coach.group.name;
          this.loadParticipants(this.session.coach);
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  // Load coaches for a given group this is called if a coach is not already selected via attribute
  // Called by loadSession
  loadGroup(group_id: string): void {
    if (!group_id) {
      throw new Error(
        'param group_id is required to load the proper group for component nexleaderSession'
      );
    }

    // Query all users for the group
    this.userService
      .queryGroup(group_id)
      .pipe(
        tap((users: any[]) => {
          // Select those that are coaches
          this.coaches = users
            .filter(
              (coach) =>
                !coach.archivedByCoachUser && coach.roles.includes('coach')
            )
            .map((coach) => ({
              ...coach,
              _fullName: `${coach.firstName} ${coach.lastName}`,
            }));

          // If there are no coaches for the group, we need some flag to determine this
          // This is useful so hide the UI so no one is confused by the UI not having any coaches
          if (this.coaches.length < 1) {
            this.noCoachesForGroup = true;
          }

          this.groupName = this.coaches[0]?.group?.name || '';
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  //Load participants for a given coach
  //Called when the coach changes or when a pre-selected coach loads
  loadParticipants(coach: any): void {
    if (!coach) {
      throw new Error(
        'param coach is required to load the proper users for a coach for component nexleaderSession'
      );
    }

    // Query all participants for the coach
    this.userService
      .queryParticipants(coach._id || coach)
      .pipe(
        tap((participants: any[]) => {
          this.participants = participants;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  //Load session if we have an id, create base object if it's a new session, or throw an error if it's
  // an invalid value
  loadSession(session_id: string): void {
    if (session_id === 'new') {
      // By default we don't want them to be able to change the length of the session, so we lock it
      this.timelocked = true;
      // If there is a coach selected, we want to query information about that coach to properly render
      if (this.coach_id) {
        this.userService.getUser(this.coach_id).subscribe((coach) => {
          this.session = {
            coach: coach,
            timezone: '',
          };
          this.groupName = coach.group.name;
          this.loadParticipants(this.session.coach);
        });
        return;
      } else {
        // const queryParams = this.routerHelper.fetchQueryParams();
        // If there is no coach, we want to query the group instead
        this.session = {
          timezone: '',
        };
        return this.loadGroup(this.group_id || this.routeParams?.group_id);
      }
    }

    // We want to query information about the session if there currently is an ID for it
    if (session_id) {
      this.sessionService
        .get(session_id)
        .pipe(
          tap((session) => {
            this.groupName = session?.coach?.group?.name || '';
            this.session = session;
            this.start = this.fromUTC(
              this.session.start,
              this.session.timezone
            );
            this.end = this.fromUTC(this.session.end, this.session.timezone);
            this.timelocked =
              this.end.getTime() - this.start.getTime() === 75 * 60 * 1000;
          }),
          catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        )
        .subscribe();
      return;
    }

    return console.error(
      "Session directive requires session_id to be an _id or 'new'",
      session_id
    );
  }

  /**
   * save()
   *
   * function
   *
   * Saves a current session and then calls the onSave function.
   */
  save(): void {
    if (!this.session) {
      throw new Error('session is required to save a session');
    }

    this.sessionService
      .save(this.session._id, this.session)
      .pipe(
        tap(() => {
          this.successService.handle();
          this.onSave.emit();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * remove()
   *
   * function
   *
   * Deletes a current existing session.
   */
  remove(): void {
    if (!this.session) {
      throw new Error('$ctrl.session is required to remove a session');
    }

    this.sessionService
      .remove(this.session._id)
      .pipe(
        tap(() => {
          this.successService.handle({ message: 'Successfully Deleted.' });
          this.onSave.emit();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * complete()
   *
   * function
   *
   * Marks a session as complete in the database.
   */
  complete(): void {
    this.sessionService
      .completeSession(this.session._id, {})
      .pipe(
        tap(() => {
          this.successService.handle({
            message: 'Session marked as completed.',
          });
          this.onSave.emit();
        }),
        catchError((error) => {
          this.errorHandlerService.toast(error);
          // this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * fromUTC()
   *
   * helper function
   *
   * @param {string} dateString - A string that represents of the date to convert to current timezone.
   * @param {string} timezone - A string that represents the timezone to calculate the date for the date string.
   *
   * @returns {Date}
   */
  fromUTC(dateString: string, timezone: string): Date {
    const mstart = moment(dateString).tz(timezone);

    return new Date(
      mstart.year(),
      mstart.month(),
      mstart.date(),
      mstart.hour(),
      mstart.minute(),
      mstart.second()
    );
  }

  /**
   * toISO()
   *
   * helper function
   *
   * Converts a date into an ISO string. Useful when saving the date to the database.
   *
   * @param {Date} date - A date to be used to convert to a string.
   * @param {string} timezone - A string representing a timezone that is used for the conversion.
   */
  toISO(date: Date, timezone: string): string {
    return moment
      .tz(
        [
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
        ],
        timezone
      )
      .toISOString();
  }

  /**
   * updateStartTime()
   *
   * function
   *
   * Converts the start time of the session to the proper format. Useful when saving the data.
   */
  updateStartTime(): void {
    if (!this.start) {
      return;
    }

    if (!(this.session && this.session.timezone)) {
      return;
    }

    this.session.start = this.toISO(this.start, this.session.timezone);
    this.updateEndTime();
  }

  //Update the end time if the start time changes and there is not a previous end time
  updateEndTime(): void {
    if (!this.start) {
      return;
    }

    if (this.timelocked) {
      const expectedEndTime = new Date(this.start.getTime() + 75 * 60 * 1000);
      if (!this.end || expectedEndTime.getTime() !== this.end.getTime()) {
        this.end = expectedEndTime;
      }
    }

    if (this.end) {
      this.session.end = this.toISO(this.end, this.session.timezone);
    }
  }

  onIsSecondECourseSessionUpdated(): void {
    if (this.session.isSecondECourseSession) {
      this.timelocked = false;

      if (this.start) {
        this.end = new Date(this.start.getTime() + 60 * 60 * 1000);
      }
    }
  }

  timezoneChanged(updatedTimezone: string) {
    this.session.timezone = updatedTimezone;
    this.updateStartTime();
  }
}
