<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">
    About the Domains of Passionate Engagement Section
  </h3>
</div>
<div class="modal-body">
  <p>
    People typically engage with a passion from one of four domains.
    <br /><br />The Service domain focuses on meeting a practical need, and
    raises the question, <b>"What can I do?"</b> <br /><br />The Justice domain
    focuses on righting a wrong, and raises the question,
    <b>"How can this be?"</b> <br /><br />The Discovery domain focuses on
    solving a problem, and raises the question, <b>"Why is this happening?"</b>
    <br /><br />The Advocacy domain focuses on promoting a cause, and raises the
    question, <b>"Who else can we get to help?"</b>
  </p>
  <hr />
  <p>
    The following users have completed the latest version of MyPassionProfile
    and imported their data to the IPSAT:
  </p>
  <br />
  <ul>
    <li *ngFor="let user of users">{{ user.firstName }} {{ user.lastName }}</li>
  </ul>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
