/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartSpiritualGiftsRepresented
 *
 * Displays a chart representation of the 'represented SpiritualGifts' of a team in the TeamReport
 */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderAboutSpiritualGiftsModalComponent } from '../../../core/components/modals/about-spiritual-gifts-modal/about-spiritual-gifts-modal.component';
import { NexleaderViewUsersWithAttributeModalComponent } from '../../../core/components/modals/view-users-with-attribute-modal/view-users-with-attribute-modal.component';
import * as am5 from '@amcharts/amcharts5';
import * as am5venn from '@amcharts/amcharts5/venn';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-nexleader-chart-spiritual-gifts-represented',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-spiritual-gifts-represented.component.html',
})
export class NexleaderChartSpiritualGiftsRepresentedComponent
  implements OnInit, AfterViewInit {
  @ViewChild('venn') vennContainer!: ElementRef;
  private root!: am5.Root;

  /**
   * model
   *
   * array in the following form:
   * [{
   *    name: 'Executing',
   *    description: 'making things happen',
   *    SpiritualGifts: [
   *        {
   *            name: 'SpiritualGift name',
   *            value: 2 //how many people have it
   *        },
   *        {
   *            name: 'SpiritualGift name',
   *            value: 2 //how many people have it
   *        },
   *        {
   *            name: 'SpiritualGift name',
   *            value: 2 //how many people have it
   *        }
   *    ],
   *    value: 3 //how many SpiritualGifts there are
   *  }
   *  ...more...
   *  ]
   */
  @Input() model: any[] = [];
  categoriesWithoutIntersects: any[] = [];

  constructor(private modalService: BsModalService) { }

  ngAfterViewInit() {
    // Create root
    var root = am5.Root.new('venn');

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create venn series
    let chart = root.container.children.push(
      am5venn.Venn.new(root, {
        categoryField: 'name',
        valueField: 'size',
        intersectionsField: 'sets',
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 40,
        paddingRight: 40,
        fillField: 'color',
      })
    );

    chart.labels.template.setAll({
      fontSize: 16,
      fontWeight: '700',
    });

    // Set data
    chart.data.setAll(this.model);

    chart.slices.template.setAll({
      fillOpacity: 0,
      strokeWidth: 5,
    });
  }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit() {
    //Validate that there's a model on here
    if (!this.model) {
      throw new Error(
        'Model is required for nexleaderChartSpiritualGiftsRepresented'
      );
    }

    this.model.reverse().forEach((m) => {
      m.color = m.label && this.chartColorForCategoryLabel(m.label);
    });

    //The Venn Diagram really only works if all the circle show up, so we cheat
    // any category that has zero representation is given a value of 1, so it shows
    // up.
    this.categoriesWithoutIntersects = this.model.filter(
      (category) => category.sets.length === 1
    );
    // Define the desired order
    const order = ["Word Gifts", "Love Gifts", "Power Gifts"];
    // Sort the array based on the specified order
    this.categoriesWithoutIntersects.sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
  }

  //Returns the chart color for a given category label
  // used by d3 to pick colors for each circle in the venn diagram
  chartColorForCategoryLabel(label: string): any {
    switch (label) {
      case 'Word Gifts':
        return am5.color(0xda5554);
      case 'Love Gifts':
        return am5.color(0x2e7ef0);
      case 'Power Gifts':
        return am5.color(0xa09f1d);
      default:
        throw new Error(
          'Invalid chart label; no known valid color scheme for it'
        );
    }
  }

  /**
   * viewSpiritualGiftsInformation
   *
   * Allows a user to learn more about the spiritual gifts section through a modal
   */
  viewSpiritualGiftsInformation() {
    this.modalService.show(NexleaderAboutSpiritualGiftsModalComponent);
  }

  /**
   * viewUsers
   *
   * Allows a user to click on a spiritual gift and view the users who have that spiritual gift
   *
   * @param {Object} spiritualGift - A spiritual gift property to use inside of the modal
   */
  viewUsers(spiritualGift: any) {
    const modalRef = this.modalService.show(
      NexleaderViewUsersWithAttributeModalComponent,
      {
        initialState: {
          // Map the title and users from the passed-in parameters
          title: spiritualGift.name + ' - Spiritual Gifts',
          users: spiritualGift.users,
          additionalClarificationCopy:
            'An asterisk denotes this spiritual gift is the most dominant for this user.',
        },
      }
    );
  }
}
