import { HttpInterceptorFn } from '@angular/common/http';


export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem('nexleaderIpsatToken');
  // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MzI3OTgxNzAsImV4cCI6MTczMjg4NDU3MCwiaXNfY29hY2hfdXNlciI6ZmFsc2UsInVzZXIiOiI1ODA0ZGRjMTllOGM5MWZmNTRlNjQ0ZjMiLCJncm91cCI6IjU3Y2I5ZmEzMDEzMWM2NTAyYzk2YjFkNyIsInJvbGVzIjpbImNvYWNoIiwicGFydGljaXBhbnQiLCJncm91cGFkbWluIl0sImxpY2Vuc2luZ19wYXJ0bmVyIjoiNWI4ZGRhYTIzM2Y5ZTAyNTkzZjE3MGI4In0.Xn4AUxwrVlLOlZaVBVPNaHYmY7aPx2C212EVx1s8qXU';

  const modifiedReq = token
    ? req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })
    : req;

  return next(modifiedReq);
};
