<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div
  class="form-group"
  *ngFor="
    let question of questions
      | filter : 'questionCategory.name' : category?.name;
    let i = index
  "
>
  <h5 class="control-label">Question {{ i + 1 }}</h5>
  <br />
  <textarea
    type="text"
    class="form-control"
    [(ngModel)]="question.name"
  ></textarea>
  <br />
  <a
    (click)="showAdvanced[i] = !showAdvanced[i]"
    *ngIf="!eval"
    class="text-success"
    >Advanced Settings</a
  >
  <br />
  <div *ngIf="showAdvanced[i]">
    <div *ngFor="let answer of question.answers">
      <br />
      <h5>Summary Text for a Response of {{ answer.name }}</h5>
      <br />
      <app-nexleader-edit-ranges
        [ranges]="answer.ranges"
      ></app-nexleader-edit-ranges>
    </div>
  </div>
  <hr />
</div>
