<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<!-- Assuming that 'nexleader-file-picker' is a valid Angular component in your Angular application -->

<app-nexleader-file-picker
  (onSelect)="onSelect($event)"
  (onLoad)="onLoad($event.uri)"
  [accept]="'image/*'"
></app-nexleader-file-picker>

<span *ngIf="file.name"
  >Selected File: <i>{{ file.name }}</i></span
>
<span *ngIf="!file.name">No file selected.</span>

<button
  class="btn btn-primary pull-right"
  [attr.disabled]="!(data && file.name) ? true : null"
  (click)="upload()"
>
  <i class="fa fa-upload"></i>&nbsp;&nbsp; Upload
</button>
