/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { FormsModule } from '@angular/forms';
import { NexleaderEditRangesComponent } from '../edit-ranges/edit-ranges.component';

@Component({
  selector: 'app-nexleader-edit-questions',
  standalone: true,
  imports: [
    CommonModule,
    FilterPipe,
    FormsModule,
    NexleaderEditRangesComponent,
  ],
  templateUrl: './edit-questions.component.html',
})
export class NexleaderEditQuestionsComponent {
  @Input() questions: any;
  @Input() category: any;
  @Input() eval: boolean = false;

  showAdvanced: any = {};
}
