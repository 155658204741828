<p>Purchase the Ecourse content to receive the following...</p>
<br />
<ul class="m-b-md">
  <li>8 Modules with video lessons</li>
  <li>Application Note Guide</li>
</ul>
<p>
  <b>Note: </b> There are no coaching sessions included with this version of the
  ECourse. The references to coaching and assessments are for users who enter
  the IPSAT through the ECourse platform.
</p>
<hr />
<!-- Discount code interface -->
<div *ngIf="!pricingConfig?.discount_code_name">
  <div class="form-group">
    <label class="control-label">Discount Code</label>
    <input
      class="form-control"
      type="text"
      id="nexleader-e2e-direct-purchase-candidate-discount-code-name"
      [(ngModel)]="candidateDiscountCodeName"
    />
  </div>
  <button
    class="btn btn-default"
    id="nexleader-e2e-direct-purchase-candidate-discount-code-verify"
    [attr.disabled]="!candidateDiscountCodeName ? true : null"
    (click)="validateCandidateDiscountCode()"
  >
    Validate
  </button>
</div>
<div *ngIf="pricingConfig?.discount_code_name">
  <p>
    Discount code <i>{{ pricingConfig.discount_code_name }}</i> successfully
    applied. <a (click)="clearDiscountName()">Undo</a>.
  </p>
</div>
<hr />
<div class="flex flex-vertical-center">
  <div>
    <p>Your total is...</p>
  </div>
  <div class="flex-grow"></div>
  <h4 class="font-bold m-t-none m-b-none">
    <app-nexleader-currency
      *ngIf="!loading && pricing"
      [currencyQuantity]="pricing.total.currencyQuantity"
      [currencyCode]="pricing.total.currencyCode"
    ></app-nexleader-currency>
    <span *ngIf="loading">Loading Price...</span>
  </h4>
</div>
<hr />
<div class="flex flex-horizontal-center flex-vertical-center">
  <button
    class="btn btn-success"
    (click)="checkout()"
    [attr.disabled]="!isReadyToAdvance() ? true : null"
  >
    <i class="fa fa-shopping-cart"></i>
    Checkout
  </button>
</div>
