<div class="row">
  <div class="col-md-12">
    <div *ngIf="!scoredSkillsInventory">
      <p class="m-t-md m-b-md">
        Since the skills inventory was created for the IPSAT, you will need to
        take this assessment. Click on the button below to take the skills
        inventory and a new tab will open in your browser. Once you have
        completed the inventory, enter <b>your top three skills</b>. Your full
        skills inventory report will be saved for later viewing.
      </p>
      <p class="m-b-md">
        <b>Estimated time to complete: 20 minutes</b>
      </p>
      <a class="btn btn-default" [routerLink]="'/takeSkillsInventory'">Take the Skills Inventory</a>
      <hr />
    </div>

    <div *ngIf="scoredSkillsInventory">
      <p class="m-t-md m-b-md">
        Since you have now completed the Skills Inventory, your top skills are
        listed below. Select the three top skills you want to include in your
        IPSAT report. Note, you are selecting skills you rated high, not skills
        you want to develop.
      </p>
      <div class="pull-right">
        <a [routerLink]="['/users', user._id, 'skillsInventoryResults', scoredSkillsInventory._id, 'fullscreen']"
          target="_blank">View Your Skills Inventory Result</a>
      </div>

      <div *ngIf="!showAllSkills">
        <p class="m-b-sm">
          <b>Your Top Skills</b>
          <a (click)="showAllSkills = true"> Show All My Skills</a>
        </p>
        <ul>
          <li *ngFor="let skill of scoredSkillsInventory.topSkills">
            {{ skill.name }} ({{ skill.value }})
          </li>
        </ul>
      </div>

      <div *ngIf="showAllSkills">
        <p class="m-b-sm">
          <b>Your Skills</b>
          <a (click)="showAllSkills = false"> Show Only My Top Skills</a>
        </p>
        <ul>
          <li *ngFor="let skill of scoredSkillsInventory.skills">
            {{ skill.name }} ({{ skill.value }})
          </li>
        </ul>
      </div>
    </div>

    <div class="col-xl-6">
      <b>Your Skills</b>
      <ng-container *ngIf="scoredSkillsInventory else disableInput">
        <div class="form-group" *ngFor="let i of [0, 1, 2]; let index = index">
          <input type="text" class="form-control m-t-sm" [(ngModel)]="user.prerequisites.skills[index].name"
            placeholder="Skill" />
        </div>
      </ng-container>

      <ng-template #disableInput>
        <div class="form-group" *ngFor="let i of [0, 1, 2]; let index = index">
          <input type="text" class="form-control m-t-sm" disabled placeholder="Skill" />
        </div>
      </ng-template>
    </div>
  </div>
</div>