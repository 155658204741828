<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="modal-header">
  <h3 class="modal-title">Create a new Licensing Partner</h3>
</div>
<div class="modal-body">
  <app-nexleader-input-licensing-partner-data
    [model]="model"
  ></app-nexleader-input-licensing-partner-data>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="save()">
    Create Licensing Partner
  </button>
</div>
