/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartPersonalValuesRepresented
 *
 * Displays a chart representation of the "represented personal values" of a team in the TeamReport
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Chart } from 'chart.js';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderViewUsersWithAttributeModalComponent } from '../../../core/components/modals/view-users-with-attribute-modal/view-users-with-attribute-modal.component';

@Component({
  selector: 'app-nexleader-chart-personal-values-represented',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './chart-personal-values-represented.component.html',
})
export class NexleaderChartPersonalValuesRepresentedComponent implements OnInit, OnDestroy {
  /**
   * model - representedPersonalValues
   *
   */
  @Input() model: any;

  /**
   * hideValueSetSelect
   *
   * boolean
   *
   * Determines whether or not we give the ability for the user to change the value set (ie "topTwelveValues"
   *  vs "topSevenValues"). Useful for printing when we want to hide this UI.
   */
  @Input() hideValueSetSelect: boolean = false;

  /**
   * defaultSelection
   *
   * string
   *
   * Determines what the default selection is for the the graph.
   */
  @Input() defaultSelection: string = '';
  selectedDataSetName: string = '';
  selectedDataSet: any[] = [];
  labels: any;
  chart: any;
  private ctx!: HTMLCanvasElement;
  constructor(private modalService: BsModalService) { }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit() {
    //Validate that there's a model on here
    if (!this.model || typeof this.model !== 'object') {
      throw new Error(
        'object model is required for nexleaderChartPersonalValuesRepresented'
      );
    }

    // The labels for the chart are just the names of the domains
    // Use the topTwelveValues because this will have all the domains inside
    this.labels = this.model.topTwelveValues.map((domain: any) => domain.name);

    // Initialize the currently selected dataset to be the top twelve values
    this.selectedDataSetName = this.defaultSelection || 'topTwelveValues';

    this.selectDataSet(this.selectedDataSetName);
  }

  /**
   * drawValuesRepresentedChart()
   *
   * function
   *
   * Draws the values represented for each domain chart. This utilizes the $ctrl.selectedDataSet to correctly
   *  draw the chart so we are drawing it with the proper values.
   */
  drawValuesRepresentedChart() {
    // The data of the chart is the total of all the values for each domain
    // The height of each chart is the number of values for each domain
    const data = this.selectedDataSet.map((domain) => domain.values.length);

    // Used to know what the maximum value is and then create a chart that is 1 higher than that element
    const maxValueNumber = Math.max(...data);
    this.ctx = document.getElementById('canvas') as HTMLCanvasElement;

    // Slight hack, but allows us to update the values in the chart without having to entirely rerender the page
    // This allows us to destroy the chart and then we are able to redraw it when we initialize it below
    if (this.chart) {
      this.chart.destroy();
    }

    //Call Chart.js to render the chart
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [
          {
            data,
            backgroundColor: [
              'rgba(43,130,120,0.4)',
              'rgba(140,55,81,0.4)',
              'rgba(251,146,77,0.4)',
              'rgba(59,207,219,0.4)',
            ],
            borderColor: [
              'rgb(43,130,120)',
              'rgb(140,55,81)',
              'rgb(251,146,77)',
              'rgb(59,207,219)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        scales: {
          y: {
            position: 'left',
            type: 'linear',
            min: 0,
            // Make the max number slightly taller
            // This way we don't rely on a fixed number increase, but add to it based on the maxValue
            max: Math.round(maxValueNumber * 1.1),
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
            grid: {
              display: true,
            },
            display: true,
          },
          x: {
            display: false,
          },
        },
      },
    });
  }

  /**
   * viewUsers
   *
   * Allows a user to click on a personal value and view the users who have that value
   *
   * @param {Object} personalValue - A personal value property to use inside of the modal
   */
  viewUsers(personalValue: any) {
    this.modalService.show(NexleaderViewUsersWithAttributeModalComponent, {
      initialState: {
        // Map the title and users from the passed-in parameters
        title: personalValue.name + ' - Personal Values',
        users: personalValue.users,
        additionalClarificationCopy:
          'An asterisk denotes this is a top personal value for this user.',
      },
    });
  }

  /**
   * selectDataSet()
   *
   * function
   *
   * Selects a data set of the personal values to view.
   *
   * @param {string} dataset - A dataset selection either topTwelveValues, topSevenValues, or topThreeValues
   */
  selectDataSet(dataset: any) {
    this.selectedDataSet = this.model[dataset];

    this.drawValuesRepresentedChart();
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
