import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SkillsInventoryService } from '../../../../services/skills-inventory.service';
import { SkillsInventoryResultService } from '../../../../services/skills-inventory-result.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { UserService } from '../../../../services/user.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NexleaderSkillsInventoryQuestionComponent } from '../../components/question/question.component';

@Component({
  selector: 'app-nexleader-skills-inventory',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderSkillsInventoryQuestionComponent,
  ],
  templateUrl: './skills-inventory.component.html',
})
export class NexleaderSkillsInventoryComponent implements OnInit {
  routeParams: any;
  userId!: string;
  skillsInventory: any;
  user: any;
  selectedQuestion: any;
  suggestedWeakSkills!: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private skillsInventoryService: SkillsInventoryService,
    private skillsInventoryResultService: SkillsInventoryResultService,
    private userService: UserService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.userId = this.routeParams['user_id'];

    // Validate that we have a user to assign this assessment to
    if (typeof this.userId !== 'string') {
      throw new Error('A user_id is required to start a SkillsInventory');
    }

    // Load the questions for the SkillsInventory from the backend.
    // The skillsinventory object serves as a skeleton for the results object.
    // We will add to it in order to complete the assessment.
    // Also, initialize a few properties once the skillsInventory loads

    forkJoin({
      skillsInventory: this.skillsInventoryService.get(),
      user: this.userService.getUser(this.userId),
    })
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe(({ skillsInventory, user }) => {
        this.skillsInventory = skillsInventory;
        this.user = user;

        // The SkillsInventoryResult object will need a user_id to save, so assign it the one from
        // routeParams here. The controller will have already failed if there's no user_id.
        this.skillsInventory.user = this.user._id;

        // We need to create this empty object to bind properly
        this.skillsInventory.textResponses = {};

        // Select the first question since we're starting the assessment.
        this.selectedQuestion = this.skillsInventory.questions[0];
      });
  }

  //Goto the next or the previous question
  nextQuestion(): void {
    this.selectedQuestion =
      this.skillsInventory.questions[this.selectedQuestion.questionIndex + 1];

    // See documentation for this function. It's here to update the model at the appropriate time.
    this.suggestWeakSkills();
  }

  previousQuestion(): void {
    this.selectedQuestion =
      this.skillsInventory.questions[this.selectedQuestion.questionIndex - 1];
  }

  //Submit the the SkillsInventoryResult object we've built.
  // If there's an error, handle it.
  // If it succeeds, show a success toast and navigate to the result view.
  submit(): void {
    this.skillsInventoryResultService
      .save(this.skillsInventory)
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((skillsInventoryResult: any) => {
        this.successService.handle();

        // In the (probably rare) case that a user is already done onboarding when they click submit,
        // go straight to the result.
        // Otherwise, go back to the onboarding.
        if (this.user.onboardingStep.identifier === 'done') {
          this.router.navigate([
            '/users',
            this.user._id,
            'skillsInventoryResults',
            skillsInventoryResult._id,
          ]);
        } else {
          this.router.navigate(['/onboarding']);
        }
      });
  }

  //invalid() returns true if the submit button should be disabled because the assessment is not valid
  // maxQuestion is an optional parameter used by the next button to validate ONLY MULTIPLE CHOICE QUESTIONS
  // up to a given index. Text responses are not validated if there is a maxQuestion parameter.
  // Returns string instructions as a truthy value if the assessment is not complete.
  invalid(maxQuestion?: any): string | boolean {
    // Check basic loading/logistic stuff
    if (!this.skillsInventory) return 'SkillsInventory has not loaded.'; // We're still loading, so we can't submit
    if (typeof this.skillsInventory.user !== 'string')
      return 'Contact support.'; // This should not be the case. The controller should crash first.

    //Check that all questions that have options have their options selected
    var questionsInvalid = false;
    this.skillsInventory.questions.forEach((question: any, index: number) => {
      if (index > maxQuestion) return;
      if (question.options && !question.selectedOption) {
        questionsInvalid = true;
        return;
      }
    });
    if (questionsInvalid)
      return 'Please complete all multiple choice questions.';

    if (questionsInvalid)
      return 'Please complete all multiple choice questions.';

    // Don't check text responses if there's a maxQuestion parameter
    if (!isNaN(maxQuestion)) {
      return false;
    }

    if (!this.skillsInventory.textResponses) {
      return 'Please complete the text responses associated with this assessment.';
    }

    // Validate text responses for string type and nonempty
    if (
      typeof this.skillsInventory.textResponses.futureIdealRole !== 'string' ||
      this.skillsInventory.textResponses.futureIdealRole.trim() === ''
    ) {
      return 'Please enter your ideal future role.';
    }
    if (
      typeof this.skillsInventory.textResponses.skillsNeeded !== 'string' ||
      this.skillsInventory.textResponses.skillsNeeded.trim() === ''
    ) {
      return 'Please enter the skills you need for your future role.';
    }
    if (
      typeof this.skillsInventory.textResponses.underdevelopedSkills !==
      'string' ||
      this.skillsInventory.textResponses.underdevelopedSkills.trim() === ''
    ) {
      return 'Please enter your underdeveloped skills.';
    }
    if (
      typeof this.skillsInventory.textResponses
        .underdevelopedSkillsExplanation !== 'string' ||
      this.skillsInventory.textResponses.underdevelopedSkillsExplanation.trim() ===
      ''
    ) {
      return 'Please explain your underdeveloped skills.';
    }

    // Everything's valid, so return false.
    return false;
  }

  //suggestWeakSkills() returns a list of skill objects for which the user answered a value
  // of 6 or less. This is used by the weaknesses screen to provide a list of skill suggestions.
  // See under-developed skills
  //
  //We can't use this directly in an ng-repeat because it doesn't recognize the map as static
  // Instead, we must precompute and store the model.
  //We could do this with a $scope.$watch on the skillsInventory model, but I choose to do it on
  // every call to nextQuestion().
  //This is sufficient as no multiple choice shares a page with the weak skills suggestions
  // and the only way to navigate pages is with nextQuestion() and previousQuestion()
  suggestWeakSkills(): void {
    this.suggestedWeakSkills = this.skillsInventory.questions
      .filter((question: any) => {
        if (!question.selectedOption) return false;

        return question.selectedOption.weight < 6;
      })
      .map((question: any) => ({
        name: question.name,
        value: question.selectedOption.weight,
      }));
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSkillsInventoryComponent',
//   downgradeComponent({
//     component: NexleaderSkillsInventoryComponent,
//   }) as angular.IDirectiveFactory
// );
