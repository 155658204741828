<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Edit Licensing Partner</h3>
</div>
<div class="modal-body">
  <app-nexleader-input-licensing-partner-data
    *ngIf="licensingPartner"
    [model]="licensingPartner"
  ></app-nexleader-input-licensing-partner-data>
  <hr />
  <app-nexleader-licensing-partner-stripe-connect-invite
    *ngIf="licensingPartner && !licensingPartner.isDefaultLicensingPartner"
    [licensingPartnerId]="licensingPartner._id"
  ></app-nexleader-licensing-partner-stripe-connect-invite>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="cancel()">
    Cancel
  </button>
  <button class="btn btn-success" type="button" (click)="save()">Save</button>
</div>
