<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">{{ getModalTitle() }}</h3>
</div>
<div class="modal-body">
  <app-nexleader-small-group-video
    [videoIdentifier]="videoIdentifier"
  ></app-nexleader-small-group-video>
</div>
<div class="modal-footer">
  <div class="pull-right">
    <button class="btn btn-default" type="button" (click)="close()">
      Close
    </button>
  </div>
  <div class="clearfix"></div>
</div>
