/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskService } from './tasks.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-tasks',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tasks.component.html'
})
export class NexleaderTasksComponent implements OnInit {
  tasks: any[] = [];

  constructor(
    private taskService: TaskService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.taskService.getTasks().pipe(
      tap((tasks) => {
        this.tasks = tasks;
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  runTask(task: any): void {
    this.taskService.runTask(task).pipe(
      tap(() => {
        this.load(); // Reload tasks after running task
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderTasksComponent',
//   downgradeComponent({
//     component: NexleaderTasksComponent,
//   }) as angular.IDirectiveFactory
// );
