<div class="hpanel">
  <div class="panel-body">
    <div class="flex flex-vertical-center">
      <h4 class="text-primary-2 m-t-none m-b-none">{{ group.name }}</h4>
      <div class="flex-grow"></div>
      <span class="label label-default" *ngIf="group.isLegacy"
        >Legacy Group</span
      >
      <span class="label label-default" *ngIf="group.isDirectPurchase"
        >Direct Purchase</span
      >
    </div>
    <hr class="m-t-md m-b-md" />
    <p class="m-b-sm">
      <b>Licensing Partner: </b> {{ group.licensingPartner.name }}
    </p>
    <p class="m-b-sm">
      <b>Licenses: </b> {{ group.remainingLicenses }}/{{ group.maxUsers }}
    </p>
    <p class="m-b-sm"><b>IPSAT Experience: </b> {{ group.surveyType.name }}</p>
    <p class="m-b-sm">
      <b>Group Admins: </b>
      <span *ngIf="groupAdmins.length < 1">None</span>
      <ng-container *ngIf="groupAdmins.length > 0">
        <span *ngFor="let user of groupAdmins; let last = last">
          {{ user.firstName }} {{ user.lastName }} (<a
            href="mailto:{{ user.email }}"
            >{{ user.email }}</a
          >)
          <span *ngIf="!last">, </span>
        </span>
      </ng-container>
    </p>
  </div>
  <div class="panel-footer flex flex-vertical-center">
    <div class="font-semi-bold">Created On {{ group.createdAt | date }}</div>
    <div class="flex-grow"></div>
    <div>
      <a
        class="btn btn-success"
        style="margin-right: 3px"
        [routerLink]="['/groups', group._id]"
        >Manage</a
      >
      <a
        id="nexleader-test-group-edit"
        class="btn btn-success"
        [routerLink]="['/groups', group._id, 'settings']"
      >
        Settings
      </a>
    </div>
  </div>
</div>
