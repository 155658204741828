<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div
  class="nexleaderSurveyQuestionBox"
  [class.nexleaderSurveyPersonality]="
    question.questionCategory.name === 'Personality'
  "
  [class.nexleaderSurveyStrengths]="
    question.questionCategory.name === 'Strengths'
  "
  [class.nexleaderSurveySkills]="question.questionCategory.name === 'Skills'"
  [class.nexleaderSurveySpiritualGifts]="
    question.questionCategory.name === 'Spiritual Gifts'
  "
  [class.nexleaderSurveyPassions]="
    question.questionCategory.name === 'Passions'
  "
  [class.nexleaderSurveyText]="
    question.questionCategory.name === 'Open-Ended Questions' ||
    question.questionCategory.name === 'Summary Overview'
  "
  id="survey{{ index }}"
>
  <h4 *ngIf="question.title">
    {{
      question.questionCategory.name === "Summary Overview"
        ? "Identity Overview Statement"
        : question.title
    }}
  </h4>
  <h1>{{ question.name }}</h1>
  <br />
  <br />
  <div
    *ngIf="question.questionType.name === 'Radio Range'"
    class="question-box"
  >
    <div class="nexleaderSurveyBtnOuter">
      <div
        *ngFor="let questionAnswer of question.answers"
        class="nexleaderSurveyBtnContainer"
      >
        <input
          type="button"
          (click)="selectAnswer(questionAnswer)"
          [id]="question._id + '-option'"
          name="selector"
          class="nexleaderSurveyBtn"
          [class.nexleaderSurveyBtnActive]="questionAnswer === question.answer"
          [value]="questionAnswer.name"
          required
        />
      </div>
    </div>
    <div class="nexleaderSurveyTypes">
      <span class="pull-left">strongly disagree</span>
      <span class="pull-right">strongly agree</span>
    </div>
  </div>
  <form
    *ngIf="question.questionType.name === 'Input Field'"
    class="question-box"
  >
    <textarea
      name="{{ question.title }}"
      #textInput="ngModel"
      [(ngModel)]="question.answer.name"
      class="nexleaderSurveyInput"
      placeholder="Your response..."
      nexleaderMaximumWordsValidation
      [maxWords]="maxWordCount"
      rows="4"
      required
    >
    </textarea>
    <span
      class="nexleaderWordCount"
      [class.nexleaderWordCountInvalid]="textInput.invalid"
    >
      {{ wordCount }}/{{ maxWordCount }}
    </span>
    <br />
    <div class="nexleaderSurveyNextQuestion">
      <h1 (click)="scrollTo(index + 1)"><i class="fa fa-angle-down"></i></h1>
    </div>
  </form>
</div>
