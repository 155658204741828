<div class="hpanel">
  <div class="panel-body">
    <h4 class="text-primary-2">{{ licensingPartner.name }}</h4>
    <hr />
    <p><b>Region:</b> {{ licensingPartner.region }}</p>
    <p><b>Licenses:</b> {{ licensingPartnerLicenseCount | number }}</p>
    <br />
    <h5 class="m-b-sm text-primary-2">Groups ({{ groups.length }})</h5>
    <div class="table-responsive" *ngIf="groups.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Tier</th>
            <th>Licenses</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let group of groups">
            <td>{{ group.name }}</td>
            <td>{{ group.tier.name }}</td>
            <td>{{ group.maxUsers | number }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p *ngIf="groups.length < 1" class="m-b-md">
      This licencing partner does not yet own any users. Licencing partners can
      add users by logging in and creating them.
    </p>
  </div>
  <div class="panel-footer flex flex-vertical-center">
    <div class="font-semi-bold">
      Created On {{ licensingPartner.createdAt | date }}
    </div>
    <div class="flex-grow"></div>
    <div>
      <button class="btn btn-success" (click)="editLicensingPartner()">
        Edit
      </button>
    </div>
  </div>
</div>
