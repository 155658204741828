import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderCopyLinkComponent } from '../../copy-link/copy-link.component';

@Component({
  selector: 'app-nexleader-shareable-ipsat-modal',
  standalone: true,
  imports: [CommonModule, NexleaderCopyLinkComponent],
  templateUrl: './shareable-ipsat-modal.component.html',
})
export class NexleaderShareableIpsatModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() shareableID!: string;

  url!: string;
  text!: string;
  combined!: string;

  constructor() { }

  ngOnInit() {
    if (!this.shareableID)
      console.warn('A shareable IPSAT ID is required to create the URL');

    this.url = encodeURIComponent(
      location.href.split('#')[0] + '#/s/' + this.shareableID
    );
    this.text =
      'Here are the results of my unique Identity Profile from myIPSAT.com';

    this.combined = this.text + ': ' + this.url;
  }
}
