<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="hpanel stats">
  <div class="panel-body">
    <div class="stats-title pull-left">
      <h4>{{ name }}</h4>
    </div>
    <div class="stats-icon pull-right">
      <i class="fa fa-{{ icon }} fa-2x"></i>
    </div>
    <div class="m-t-xl">
      <h1 class="text-success">{{ value }}</h1>
      <p>{{ description }}</p>
    </div>
  </div>
  <div class="panel-footer" *ngIf="text">
    <a [href]="link">{{ text }}</a>
  </div>
</div>