<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">{{ title }}</h3>
</div>
<div class="modal-body">
  <ng-container *ngIf="index">
    <app-nexleader-ecourse-rali-video
      [index]="index"
    ></app-nexleader-ecourse-rali-video>
  </ng-container>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="onClose.emit()">
    Close
  </button>
</div>
