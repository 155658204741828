/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { EMPTY, catchError, of, switchMap, take, tap } from 'rxjs';
import { StripePaymentService } from '../../../../services/stripe-payment.service';
import { FormsModule } from '@angular/forms';
import { NexleaderCurrencyComponent } from '../currency/currency.component';
import { NexleaderStripeElementModalComponent } from '../stripe-element-modal/stripe-element-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-product',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderCurrencyComponent],
  templateUrl: './product.component.html',
})
export class NexleaderProductComponent implements OnInit {
  @Input() key: any;
  @Input() val: any;
  @Input() icon: string = '';
  routeParams: any;
  user: any;
  amount: number = 1;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private modalService: BsModalService,
    private errorHandlerService: ErrorHandlerService,
    private stripePaymentService: StripePaymentService,
    private successService: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.getUserData();
  }

  getUserData() {
    this.userService
      .getUser(this.routeParams['user_id'])
      .pipe(
        tap((user) => {
          this.user = user;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  purchaseLicenses(key: any, product: any) {
    const modalRef = this.modalService.show(
      NexleaderStripeElementModalComponent,
      {
        initialState: {
          title: product.name,
          description: `Purchasing ${this.amount} IPSAT license(s).`,
          currencyCode: product.price.currencyCode?.toLowerCase(),
          amount: Math.trunc(product.price.currencyQuantity * this.amount * 100),
        },
      }
    );

    modalRef.content?.tokenGenerated
      .pipe(take(1))
      .pipe(
        tap(() => modalRef.hide()),
        switchMap((token) => {
          return this.stripePaymentService
            .handlePayment(token, product._id, this.amount, this.user)
            .pipe(
              catchError((error) => {
                this.errorHandlerService.handleError(error);
                return EMPTY;
              })
            );
        })
      )
      .subscribe(() => {
        this.successService.handle({ message: 'Successfully charged.' });
      });
  }

  changeVal(a: number) {
    if (this.amount + a === 0) return;
    this.amount += a;
  }
}
