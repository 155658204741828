/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentChartPviSpider
 *
 * component
 *
 * This component is responsible for handling data passed through the model and rendering properly in the form of a
 *  spider chart.
 */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Chart } from 'chart.js';
import { PVI_BAR_CHART_COLORS } from '../../../../../../constants';

@Component({
  selector: 'app-nexleader-component-chart-pvi-spider',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-pvi-spider.component.html',
})
export class NexleaderComponentChartPviSpiderComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /**
   * model
   *
   * array
   *
   * The data must be in the correct form for the chart to render properly
   */
  @Input() model: number[] = []; // Adjust the type if necessary

  /**
   * title
   *
   * string
   *
   * A string that is the time of the radar chart
   */
  @Input() title: string = '';
  private chart!: Chart;

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    //Get our canvas element from the html template
    const wrapper = this.elementRef.nativeElement.querySelector(
      '.nexleader-chart-pvi-spider'
    );
    const canvas = this.elementRef.nativeElement.querySelector('canvas');

    // We want to order the graph in a clockwise way starting with others at the top and ending with vocation on the left
    // We define this array so we can have a basepoint in which we use to then sort our array
    const orderedArrayOfDomains = ['Others', 'Avocation', 'Self', 'Vocation'];

    wrapper.style.width = '100%';

    this.chart = new Chart(canvas, {
      type: 'radar',
      data: {
        labels: orderedArrayOfDomains,
        datasets: [
          {
            label: this.title,
            data: this.model,
            backgroundColor: 'rgba(36,211,123,.2)',
            borderColor: 'rgb(36,211,123)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          r: {
            beginAtZero: true,
            ticks: {
              stepSize: 5,
            },
            pointLabels: {
              color: PVI_BAR_CHART_COLORS[1],
              font: {
                size: 12,
              },
            },
          },
        },
      },
    });
  }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit(): void {
    this.validateInputs();
  }

  private validateInputs(): void {
    if (!Array.isArray(this.model)) {
      throw new Error(
        'Array model is required for nexleaderComponentChartPviSpider'
      );
    }
    if (typeof this.title !== 'string') {
      throw new Error(
        'String title is required for nexleaderComponentChartPviSpider'
      );
    }
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
