<div class="nexleader-chart-personal-values-wrapper">
  <canvas id="canvas"></canvas>
</div>
<div class="flex m-t-md m-b-md" *ngIf="!hideValueSetSelect">
  Current Value Set
  <div class="flex-grow"></div>
  <label class="control-label m-r-sm">
    <input type="radio" [(ngModel)]="selectedDataSetName" value="topTwelveValues"
      (click)="selectDataSet('topTwelveValues')" />
    &nbsp;&nbsp;Top Twelve
  </label>
  <label class="control-label m-r-sm">
    <input type="radio" [(ngModel)]="selectedDataSetName" value="topSevenValues"
      (click)="selectDataSet('topSevenValues')" />
    &nbsp;&nbsp;Top Seven
  </label>
  <label class="control-label">
    <input type="radio" [(ngModel)]="selectedDataSetName" value="topThreeValues"
      (click)="selectDataSet('topThreeValues')" />
    &nbsp;&nbsp;Top Three
  </label>
</div>
<div class="row">
  <div class="col-md-3 col-sm-6 col-xs-12 m-b-md col-3-print text-center" *ngFor="let valueDomain of selectedDataSet">
    <div class="flex flex-vertical-center m-b-sm text-center justify-content-center">
      <img [src]="
          '/assets/images/pvi_icons/' + valueDomain.identifier + '.svg'
        " alt="pvi self icon" class="img-md" />
      <h5 class="m-l-sm font-bold nexleader-chart-personal-values-{{
          valueDomain.identifier
        }}">
        {{ valueDomain.name }}
      </h5>
    </div>
    <p *ngIf="valueDomain.values.length < 1">No values for this category.</p>
    <div *ngFor="let value of valueDomain.values" class="m-b-xs">
      <p class="cursor-pointer" (click)="viewUsers(value)">
        {{ value.name }} ({{ value.users.length }})
      </p>
    </div>
  </div>
</div>