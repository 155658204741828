<div class="form-group">
  <label class="control-label">Licensing Partner</label>
  <select
    class="form-control nexleader-test-input-licensing-partner"
    [(ngModel)]="model"
    (ngModelChange)="onChange()"
    [disabled]="shouldDisableDropdown()"
  >
    <option [ngValue]="null" selected>-- Select Licensing Partner --</option>
    <option *ngFor="let option of options" [value]="option._id">
      {{ option._displayName }}
    </option>
  </select>
</div>
