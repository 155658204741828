/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderQuickViewNotSelectedComponent } from '../../components/quick-view-not-selected/quick-view-not-selected.component';
import { NexleaderQuickViewComponent } from '../../components/quick-view/quick-view.component';
import { environment } from '../../../../../environments/environment';
import { NexleaderUserCardComponent } from '../../components/user-card/user-card.component';

@Component({
  selector: 'app-nexleader-users',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderQuickViewNotSelectedComponent,
    NexleaderQuickViewComponent,
    NexleaderUserCardComponent,
  ],
  templateUrl: './users.component.html',
})
export class NexleaderUsersComponent implements OnInit {
  searchText: string = '';
  users: any[] = [];
  selectedUser: any;

  constructor(
    private userService: UserService,
    private successService: SuccessService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.userService
      .queryIndex()
      .pipe(
        tap((response) => {
          this.users = response;
          this.users.forEach((user) => {
            user._name = user.firstName + ' ' + user.lastName;
            if (user.coach) {
              user._coach = user.coach.firstName + ' ' + user.coach.lastName;
            } else {
              user._coach = 'N/A';
            }
            if (user.group) user._group = user.group.name;
            if (user.licensingPartner)
              user._licensingPartner = user.licensingPartner.name;
          });
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  // open(): void {
  //   const modalRef = this.modalService.open(NexleaderNewUserModalComponent); // Replace with the actual component name
  //   modalRef.componentInstance.nexleaderClose.subscribe(() => {
  //     modalRef.close();
  //     this.load();
  //     this.successService.handle();
  //   });
  // }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderUsersComponent',
//   downgradeComponent({
//     component: NexleaderUsersComponent,
//   }) as angular.IDirectiveFactory
// );
