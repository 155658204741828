/*global angular*/
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SkillsInventoryResultService } from '../../../../services/skills-inventory-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { EMPTY, catchError } from 'rxjs';
import { NexleaderSkillsInventorySkillChartComponent } from '../../../skills-inventory/components/skills-inventory-skill-chart/skills-inventory-skill-chart.component';
import { NexleaderSkillsInventoryBarComponent } from '../../../skills-inventory/components/skills-inventory-bar/skills-inventory-bar.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-printable-skills-inventory-result',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderSkillsInventoryBarComponent,
    NexleaderSkillsInventorySkillChartComponent,
  ],
  templateUrl: './printable-skills-inventory-result.component.html',
})
export class NexleaderPrintableSkillsInventoryResultComponent
  implements OnInit {
  routeParams: any;
  scoredSkillsInventoryResult: any;

  constructor(
    private route: ActivatedRoute,
    private skillsInventoryResultService: SkillsInventoryResultService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    const skillsInventoryResultId =
      this.routeParams['skills_inventory_result_id'];

    this.skillsInventoryResultService
      .getScore(skillsInventoryResultId)
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((scoredSkillsInventoryResult) => {
        this.scoredSkillsInventoryResult = scoredSkillsInventoryResult;
      });
  }

  print() {
    window.print();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderPrintableSkillsInventoryResultComponent',
//   downgradeComponent({
//     component: NexleaderPrintableSkillsInventoryResultComponent,
//   }) as angular.IDirectiveFactory
// );
