import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class PasswordResetService {
  private url = `${API_BASE}passwordReset`;

  constructor(private httpClient: HttpClient) {}

  get(passwordResetId: string) {
    return this.httpClient.get(`${this.url}/${passwordResetId}`);
  }

  save(passwordReset: any) {
    if (passwordReset._id) {
      return this.httpClient.post(
        `${this.url}/${passwordReset._id}`,
        passwordReset,
        {
          responseType: 'text',
        }
      );
    } else {
      return this.httpClient.post(this.url, passwordReset);
    }
  }

  remove() {
    return this.httpClient.delete(this.url);
  }
}
