/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderOnboardingStrengths
 *
 * The view component for the onboarding strengths section of the onboarding preassessments.
 * This view contains information and fields that pertain to this part of the onboarding.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BlobResourceService } from '../../../../../../services/blob.service';
import { EnumsService } from '../../../../../../services/enum.service';
import { LicensingPartnerService } from '../../../../../licensing-partners/resources/licensing-partner.service';
import { tap } from 'rxjs';
import { NexleaderYesNoButtonsComponent } from '../../../../../core/components/yes-no-buttons/yes-no-buttons.component';
import { FormsModule } from '@angular/forms';
import { NexleaderProfileWizardUploadFileComponent } from '../../../../../core/components/profile-wizard-upload-file/profile-wizard-upload-file.component';
import { NexleaderCliftonStrengthsModalComponent } from '../../../../../core/components/modals/clifton-strengths-modal/clifton-strengths-modal.component';

@Component({
  selector: 'app-nexleader-onboarding-strengths',
  standalone: true,
  imports: [CommonModule, NexleaderYesNoButtonsComponent, FormsModule, NexleaderProfileWizardUploadFileComponent],
  templateUrl: './onboarding-strengths.component.html'
})
export class NexleaderOnboardingStrengthsComponent implements OnInit {
  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process
   */
  @Input() user: any;

  /**
   * save
   *
   * Allows data that is edited on this onboarding strengths view to be saved.
   */
  @Output() save = new EventEmitter();

  /**
   * cliftonStrengthsRedeemUrl
   * 
   * string
   * 
   * A url used to redirect the user to if they have already purchased the CliftonStrengths and have a code that can be redeemed.
   */
  cliftonStrengthsRedeemUrl: string = 'https://my.gallup.com/_Home/RedeemAccessCode';
  cliftonStrengthsCode!: string;
  prerequisiteLink!: string;
  allStrengths: any[] = [];
  prerequisite: any;
  files: any[] = [];

  constructor(
    private licensingPartnerService: LicensingPartnerService,
    private blobService: BlobResourceService,
    private enumsService: EnumsService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    // We need a strengths assessment link
    if (!this.user.group) {
      throw new Error('user.group is required for component nexleaderOnboardingStrengths.');
    }
    if (!this.user.group.preferredPreassessments) {
      throw new Error('user.group.preferredPreassessments is required for component nexleaderOnboardingStrengths.');
    }
    if (!this.user.group.preferredPreassessments.strengths) {
      throw new Error('user.group.preferredPreassessments.strength is required for component nexleaderOnboardingStrengths.');
    }

    if (!this.user.prerequisites.strengths) {
      this.user.prerequisites.strengths = [];
    }

    // set the link to the strengths' assessment link
    this.prerequisiteLink = this.user.group.preferredPreassessments.strengths;

    // Load all the files for the user
    this.loadFiles();
    this.loadEnums();

    if (this.user.hasPurchasedCliftonStrengthsAssessment || this.user.onboardingExperience.identifier === 'ecourse') {
      this.loadCliftonStrengthsCode();
    }
  }

  trackByName(index: number, option: any): number {
    return option.name;
  }

  compareStregths(o1: any, o2: any) {
    if (o1?.name == o2?.name) return true;
    else return false;
  }

  /**
   * loadCliftonStrengthsCode()
   *
   * Loads the cliftonStrengthsCode for a licensing partner (if applicable).
   */
  loadCliftonStrengthsCode(): void {
    this.licensingPartnerService.getCliftonStrengthsAssessmentCode(this.user.licensingPartner._id).pipe(
      tap((response) => {
        this.cliftonStrengthsCode = response.cliftonStrengthsCode;
      })
    ).subscribe();
  }

  /**
   * loadFiles
   *
   * Allows us to query for files for a specific user based off of data passed into the component
   */
  loadFiles(): void {
    this.blobService.queryUser(this.user._id).pipe(
      tap((response) => {
        this.files = response;
      })
    ).subscribe();
  }

  loadEnums(): void {
    this.enumsService.getEnums().pipe(
      tap((response) => {
        this.allStrengths = response.AllDefaultStrengths;
        this.prerequisite = response.Prerequisites.STRENGTHS;
      })
    ).subscribe();
  }

  /**
   * openCliftonStrengthsModal()
   *
   * Opens the modal to show the user the clifton strengths assessment.
   */
  openCliftonStrengthsModal(): void {
    const modalRef = this.modalService.show(NexleaderCliftonStrengthsModalComponent, {
      initialState: {
        cliftonStrengthsAssessmentUrl: this.cliftonStrengthsRedeemUrl,
        cliftonStrengthsAccessCode: this.cliftonStrengthsCode
      }
    });
  }

  onTakeCliftonStrengthsClick(): void {
    if (this.user.hasPurchasedCliftonStrengthsAssessment || this.user.onboardingExperience.identifier === 'ecourse') {
      this.openCliftonStrengthsModal();
    } else {
      window.open(this.prerequisiteLink);
    }
  }
}
