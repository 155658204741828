/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyResultService } from '../survey-result/survey-result.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { QuestionCategoryPipe } from '../../../../pipes/question-category.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-printable-shareable-survey-result',
  standalone: true,
  imports: [CommonModule, QuestionCategoryPipe],
  templateUrl: './printable-shareable-survey-result.component.html'
})
export class NexleaderPrintableShareableSurveyResultComponent implements OnInit {
  routeParams: any;
  surveyResult: any;
  user: any;
  coach: any;
  surveyTypeIdentifier!: string;
  categories: any;
  summaryOverviewQuestionCategory: any;

  constructor(
    private route: ActivatedRoute,
    private surveyResultService: SurveyResultService,
    private enumsService: EnumsService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    forkJoin([
      this.surveyResultService.getShareableVersion(this.routeParams['survey_result_id']),
      this.enumsService.getEnums()
    ]).pipe(
      tap(([surveyResultData, enumsData]) => {
        this.surveyResult = surveyResultData.surveyResult;
        this.user = surveyResultData.user;
        this.coach = surveyResultData.coach;
        this.surveyTypeIdentifier = surveyResultData.user.group.surveyType.identifier;
        this.categories = enumsData.AllQuestionCategories;
        this.summaryOverviewQuestionCategory = enumsData.QuestionCategories.SUMMARY_OVERVIEW;
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  print(): void {
    window.print();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderPrintableShareableSurveyResultComponent',
//   downgradeComponent({
//     component: NexleaderPrintableShareableSurveyResultComponent,
//   }) as angular.IDirectiveFactory
// );
