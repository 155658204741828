/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderViewPviAssessmentStepOne
 *
 * component
 *
 * This component is responsible for completing step one of the PVI. Step one consists of the collection of a user's
 *  top twelve values and their respective domains of values engagement.
 * This component delegates rendering of selected values to a subcomponent.
 * This component delegates selecting an individual value to a subcomponent.
 * This component is responsible for managing and displaying the currently selected domain of value engagement.
 * This component is responsible for managing the data model and validated for selected values.
 */
import { Component, Input, OnInit } from '@angular/core';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { ConfirmActionService } from '../../../../services/confirm-action.service';
import { CommonModule } from '@angular/common';
import { NexleaderComponentPviAssessmentSelectedValuesComponent } from '../pvi-assessment-selected-values/pvi-assessment-selected-values.component';
import { NexleaderComponentPviAssessmentValueSelectComponent } from '../pvi-assessment-value-select/pvi-assessment-value-select.component';
import { FormsModule } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-step-one',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderComponentPviAssessmentSelectedValuesComponent,
    NexleaderComponentPviAssessmentValueSelectComponent,
  ],
  templateUrl: './pvi-assessment-step-one.component.html',
})
export class NexleaderComponentPviAssessmentStepOneComponent implements OnInit {
  /**
   * topTwelveValues
   *
   * array
   *
   * An array of the top twelve values that the user has selected. This array is filled within this component
   *  and utilized in the parent component.
   */
  @Input() topTwelveValues!: any[]; // Adjust the type based on the actual structure of your data

  allDomains!: any[];
  selectedDomain: any;

  // Configure the value to create string that is later populated using the createCustomValue() function
  valueToCreate = '';

  constructor(
    private enumsService: EnumsService,
    private errorHandler: ErrorHandlerService,
    private successService: SuccessService,
    private confirmActionService: ConfirmActionService
  ) { }

  ngOnInit() {
    if (!Array.isArray(this.topTwelveValues)) {
      throw new Error(
        'nexleaderComponentPviAssessmentStepOne expected input param `topTwelveValues` as an array'
      );
    }

    this.enumsService.getEnums().subscribe((enums) => {
      // Configure all domains in which the user can select values for
      this.allDomains = enums.AllPersonalValueDomains;
      this.selectDomain(this.allDomains[0]);
    });
  }

  /**
   * hasViewedAllDomains()
   *
   * function
   *
   * Determines if the user has viewed all the domains. Useful to know if we need to show a modal afterwards.
   *
   * @returns {boolean} if the user has viewed all domains.
   *
   * @private
   */
  private _hasViewedAllDomains(): boolean {
    return this.allDomains.every((domain) => domain.hasBeenViewed);
  }

  /**
   * canSelectValues()
   *
   * @returns {boolean} true if the value selector isn't full yet
   */
  canSelectValues(): boolean {
    return this.topTwelveValues.length < 12;
  }

  /**
   * addValueToSelectedDomain()
   *
   * function
   *
   * this is a helper function that calls addValueDomainPairToDataModel() because our child component that
   *  selects this data does not care what domain we currently have selected.
   *
   *  @param {Object} value - A value object to use when marking a value as in the top 12.
   */
  addValueToSelectedDomain({ value }: any): void {
    this.addValueDomainPairToDataModel(value, this.selectedDomain);
  }

  /**
   * addValueDomainPairToDataModel()
   *
   * function
   *
   * Adds a value given a provided domain to the object that users save.
   *
   * @param {Object} value - An object representing the value the user wishes to add to the data model.
   * @param {Object} domain - The current domain that the user has selected.
   */
  addValueDomainPairToDataModel(value: any, domain: any): void {
    const currentIndexOfValue = this.indexOfValueInSelectedValues(value);

    if (currentIndexOfValue > -1) {
      // If the domain already exists inside of the domains array, we want to return
      const indexOfCurrentDomain = this.topTwelveValues[
        currentIndexOfValue
      ].personalValueDomains
        .map((d: any) => d.name)
        .indexOf(domain.name);

      // Return immediately so we do not have a duplicate domain
      if (indexOfCurrentDomain > -1) {
        this.errorHandler.toast({
          message: 'Unable to add value. Value already exists.',
        });
        return;
      }

      this.successService.handle({
        message: 'Added value "' + value.name + '."',
      });
      // Push the selected value into the array of personalValueDomains that the item
      this.topTwelveValues[currentIndexOfValue].personalValueDomains.push(
        domain
      );
    } else {
      if (this.topTwelveValues.length === 12) {
        this.errorHandler.toast({
          message: 'There are currently twelve values selected.',
        });
        return;
      }

      this.successService.handle({
        message: 'Added value "' + value.name + '."',
      });
      // If the current index does not exist, just push it into the array as a new element
      this.topTwelveValues.push({
        name: value.name,
        personalValueDomains: [domain],
      });
    }

    // If the length of the values selected is equal to eleven, this means they are adding their final value
    if (this.topTwelveValues.length === 12) {
      // If the user has viewed all the domains
      // Generate the correct description for the modal based on whether the user has viewed all domains
      const description = this._hasViewedAllDomains()
        ? 'You have selected the maximum number of personal values (12) and reflected on each of the four life domains. If you are satisfied with your choices, please continue to the next step.'
        : 'You have selected the maximum number of personal values (12) but you have not reflected on each of the four life domains. You do not have to include values for each domain, but we want to make sure you have the opportunity to reflect on them before moving to the next step.\n' +
        'Please advance to the next domain and reflect on possible values. If you see a value you want to include, you will need to remove one of the existing values before adding a new value. Remember, you can select the same value in more than one domain and it only counts as one against the total of 12.';

      // Open the alert modal with the configured description
      this.confirmActionService.handleAlert('Alert', description).subscribe();
    }
  }

  /**
   * selectDomain()
   *
   * function
   *
   * Selects a domain for the user to then select values for.
   *
   * @param domain
   */
  selectDomain(domain: any): void {
    // Used to determine if the domain has been viewed so we can show a modal accordingly
    domain.hasBeenViewed = true;
    this.selectedDomain = domain;
  }

  /**
   * indexOfValueInSelectedValues()
   *
   * function
   *
   * Finds the index of a chosen value in the selected values array. This is useful when we're trying to
   *  figure out if we want to push the value into the array or just add the selected domain to its domains.
   *
   * @param {Object} value - A value to find within the array.
   * @returns {number} - the index
   */
  indexOfValueInSelectedValues(value: any): number {
    return this.topTwelveValues.map((v) => v.name).indexOf(value.name);
  }

  /**
   * nextDomain()
   *
   * function
   *
   * Selects the next domain for the user to select values for.
   */
  nextDomain(): void {
    const currentDomainIndex = this.allDomains
      .map((domain) => domain.identifier)
      .indexOf(this.selectedDomain.identifier);

    // We do not want them to advance if they are on the last domain
    if (currentDomainIndex === this.allDomains.length - 1) {
      return;
    }

    this.selectDomain(this.allDomains[currentDomainIndex + 1]);
  }

  /**
   * previousDomain()
   *
   * function
   *
   * Selects the previous domain for the user to select values for.
   */
  previousDomain(): void {
    const currentDomainIndex = this.allDomains
      .map((domain) => domain.identifier)
      .indexOf(this.selectedDomain.identifier);

    // We do not want them to move back if they are on the first domain
    if (currentDomainIndex === 0) {
      return;
    }

    this.selectDomain(this.allDomains[currentDomainIndex - 1]);
  }

  /**
   * removeValue()
   *
   * function
   *
   * Removes a value from the $ctrl.topTwelveValues array.
   *
   * @param {Object} value - An object that represents the value to remove
   */
  removeValue({ value }: any): void {
    this.topTwelveValues = this.topTwelveValues.filter(
      (v) => v.name !== value.name
    );
  }

  /**
   * createCustomValue()
   *
   * function
   *
   * Creates a value that is custom, meaning that it is not in the predetermined list of values.
   */
  createCustomValue(): void {
    // Don't want to add empty values
    if (this.valueToCreate.trim().length === 0) {
      return;
    }

    // Use the existing function to add the value to the current domain that is selected
    this.addValueToSelectedDomain({
      value: { name: this.valueToCreate },
    });

    // Reset the form field
    this.valueToCreate = '';
  }
}
