<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Your Prerequisites</h3>
</div>
<div class="modal-body">
  <div *ngFor="let category of categories">
    <div *ngFor="let key of prerequisites | keyvalue">
      <div *ngIf="key.key === category.prerequisite" class="m-b-md">
        <h5
          *ngIf="category.prerequisiteName !== 'Top 3'"
          class="text-primary-2 font-semi-bold m-b-sm"
        >
          {{ category.prerequisiteName }}
        </h5>
        <h5
          *ngIf="category.name === 'Skills'"
          class="text-primary-2 font-semi-bold m-b-sm"
        >
          Skills Inventory (Top 3)
        </h5>
        <h5
          *ngIf="category.name === 'Passions'"
          class="text-primary-2 font-semi-bold m-b-sm"
        >
          MyPassionsProfile.com (Top 3)
        </h5>
        <h5
          *ngIf="
            category.name === 'Spiritual Gifts' && surveyType === 'values_based'
          "
          class="text-primary-2 font-semi-bold m-b-sm"
        >
          Personal Values (Top 3)
        </h5>
        <h5
          *ngIf="
            category.name === 'Spiritual Gifts' && surveyType === 'faith_based'
          "
          class="text-primary-2 font-semi-bold m-b-sm"
        >
          Spiritual Gifts (Top 3)
        </h5>
        <p *ngIf="key.value.name">
          {{ key.value.name }}
        </p>
        <p *ngIf="key.value[0]">
          <span *ngFor="let v of key.value; let last = last">
            <span class="word-wrap">
              {{ v.name }}
              <span *ngIf="v.level"> ({{ v.level.name }})</span>
              <span *ngIf="!last">, </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
