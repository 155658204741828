import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  constructor(private httpClient: HttpClient) {}

  query(): Observable<any> {
    return this.httpClient.get(`${API_BASE}evaluations`, {
      withCredentials: true,
    });
  }

  getEvaluation(evaluationId: string): Observable<any> {
    return this.httpClient.get(`${API_BASE}evaluations/${evaluationId}`, {
      withCredentials: true,
    });
  }

  save(evaluation: any): Observable<any> {
    return this.httpClient.post(
      `${API_BASE}evaluations/${evaluation._id}`,
      evaluation,
      {
        withCredentials: true,
      }
    );
  }

  delete(evaluationId: string): Observable<any> {
    return this.httpClient.delete(`${API_BASE}evaluations/${evaluationId}`, {
      withCredentials: true,
    });
  }
}
