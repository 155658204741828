<table class="nexleader-chart-mbti-table">
  <tr *ngFor="let row of tableData">
    <td
      class="nexleader-chart-mbti-table-cell"
      *ngFor="let mbtiCountObject of row"
      [ngClass]="{
        'nexleader-chart-mbti-selected': mbtiCountObject.name === teamMbti.name
      }"
    >
      <span class="nexleader-chart-mbti-table-name text-muted">{{
        mbtiCountObject.name
      }}</span>
      <br />
      <span class="nexleader-chart-mbti-table-value text-success">{{
        mbtiCountObject.value
      }}</span>
    </td>
  </tr>
</table>
