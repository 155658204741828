import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderHelpModalComponent } from '../../../../core/components/modals/help-modal/help-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../services/auth.service';
import { NexleaderOnboardingVideoComponent } from '../onboarding-video/onboarding-video.component';

@Component({
  selector: 'app-nexleader-onboarding-video-wrapper',
  standalone: true,
  imports: [CommonModule, NexleaderOnboardingVideoComponent],
  templateUrl: './onboarding-video-wrapper.component.html'
})
export class NexleaderOnboardingVideoWrapperComponent implements OnInit {
  routeParams: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
  }

  boundNext(): void {
    this.router.navigate(['/']);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  openHelpModal(): void {
    this.modalService.show(NexleaderHelpModalComponent);
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderOnboardingVideoWrapperComponent',
//   downgradeComponent({
//     component: NexleaderOnboardingVideoWrapperComponent,
//   }) as angular.IDirectiveFactory
// );