<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="container p-v-50-sm">
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <h4 class="text-primary-2 font-semi-bold m-b-sm">Top Seven Values</h4>
      <p class="m-b-md">
        Prioritize your list with your three most important values at the top of
        the list. Click on the values below to select them. They will then
        populate in the list on the right.
      </p>
      <div class="hpanel">
        <div class="panel-body">
          <div
            *ngFor="
              let value of getValuesInTopSeven() | orderBy : 'rating' : true;
              let index = index;
              let last = last
            "
          >
            <div class="flex flex-vertical-center">
              <h4 class="font-semi-bold text-success m-r-sm">
                {{ index + 1 }}
              </h4>
              <div>
                <h5 class="text-primary-2">{{ value.name }}</h5>
                <p>
                  <small
                    class="text-secondary font-bold m-r-sm"
                    *ngFor="let domain of value.personalValueDomains"
                    >{{ domain.name }}
                  </small>
                </p>
              </div>
              <div class="flex-grow"></div>
              <button
                class="btn btn-sm btn-success"
                id="nexleader-test-pvi-step-five-select-{{ index }}"
                [attr.disabled]="shouldDisableSelectionFor(value) ? true : null"
                (click)="toggleValueSelection(value.name)"
              >
                {{ value.isInTopThree ? "Unselect" : "Select" }}
              </button>
            </div>
            <hr *ngIf="!last" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <h4 class="text-primary-2 font-semi-bold m-b-md">Top Three Values</h4>
      <p *ngIf="getValuesInTopThree().length < 1">
        No values have been selected yet. Select one to the left.
      </p>
      <div class="row">
        <div
          class="col-sm-6 col-xs-12"
          *ngFor="let value of getValuesInTopThree(); let index = index"
        >
          <div class="hpanel">
            <div class="panel-body">
              <div class="flex flex-vertical-center">
                <div>
                  <h5 class="text-primary-2 font-semi-bold">
                    {{ value.name }}
                  </h5>
                  <small
                    class="text-secondary font-bold m-r-sm"
                    *ngFor="let domain of value.personalValueDomains"
                    >{{ domain.name }}
                  </small>
                </div>
                <div class="flex-grow"></div>
                <span
                  class="nexleader-number-highlight nexleader-number-highlight-active m-r-sm"
                >
                  {{ index + 1 }}
                </span>
                <i
                  class="fa fa-minus text-muted cursor-pointer"
                  id="nexleader-test-pvi-step-five-deselect-{{ index }}"
                  (click)="toggleValueSelection(value.name)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
