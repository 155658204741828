import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly tokenCookie = 'nexleaderIpsatToken';

  getToken(): string | null {
    const cookies = document.cookie.split('; ');
    const mappedCookies: { [key: string]: string } = {};
    cookies.forEach((cookie) => {
      const [key, value] = cookie.split('=');
      mappedCookies[key] = value;
    });
    return mappedCookies[this.tokenCookie] || null;
  }

  removeToken(): void {
    // You may want to add more logic for cookie removal (e.g., expiration time)
    document.cookie = `${this.tokenCookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  }

  setToken(token: string): void {
    // You may want to add more logic for setting cookies (e.g., expiration time)
    document.cookie = `${this.tokenCookie}=${token}; path=/;`;
  }
}
