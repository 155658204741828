/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartSpiritualGiftsUnrepresented
 *
 * Displays a chart representation of the "unrepresented SpiritualGifts" of a team in the TeamReport
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-chart-spiritual-gifts-unrepresented',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-spiritual-gifts-unrepresented.component.html',
})
export class NexleaderChartSpiritualGiftsUnrepresentedComponent
  implements OnInit
{
  /**
   * model
   *
   * array in the following form:
   * [{
   *       name: 'Set A',
   *       sets: ['A'],
   *       description: 'Category description',
   *       spiritualGifts: [
   *           {
   *               name: 'Gift name name',
   *               value: 2 //how many people have it
   *           }
   *       ],
   *       size: 3
   *   }
   *  ...more...
   *  ]
   */
  @Input() model: any[] = [];
  categoriesWithoutIntersects: any[] = [];

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit() {
    //Validate that there's a model on here
    if (!this.model) {
      throw new Error(
        'model is required for nexleaderChartSpiritualGiftsRepresented (see component docs; maybe add an ng-if if it has to load)'
      );
    }

    this.categoriesWithoutIntersects = this.model.filter(
      (category) => category.sets.length === 1
    );
  }
}
