/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardService } from '../../resources/dashboard.service';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { NexleaderStatisticComponent } from '../../../core/components/statistic/statistic.component';
import { NexleaderDashboardStatisticsChartComponent } from '../../components/dashboard-statistics-chart/dashboard-statistics-chart.component';

@Component({
  selector: 'app-nexleader-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderStatisticComponent,
    NexleaderDashboardStatisticsChartComponent,
  ],
  templateUrl: './dashboard.component.html',
})
export class NexleaderDashboardComponent implements OnInit {
  stats = {
    totalGroups: undefined,
    totalUsers: undefined,
    totalOrganizationalCoaches: undefined,
    totalIndependentContractorCoaches: undefined,
    totalCoaches: undefined,
    totalSessions: undefined,
    totalCharges: undefined,
    globalNps: undefined,
  };

  constructor(
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  private load(): void {
    this.dashboardService
      .queryAdmin()
      .pipe(
        tap((data) => {
          this.stats = data;
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderDashboardComponent',
//   downgradeComponent({
//     component: NexleaderDashboardComponent,
//   }) as angular.IDirectiveFactory
// );
