<!-- TODO NOW TEST THIS COMPONENT -->
<div class="flex flex-vertical-center flex-horizontal-center hideOnPrint w-100 h-100">
    <button class="btn btn-lg btn-primary" (click)="print()">
        <i class="fa fa-print"></i>&nbsp;&nbsp;Print My Passion Profile Result
    </button>
</div>
<div class="hideNonPrint">
    <div class="mpp-assessment-result-container">
        <div class="mpp-container-fluid mpp-full-width-sm">
            <div class="paper-dark">
                <div class="pull-right hide-print">
                    <a class="btn btn-primary"
                        [routerLink]="['/users', user._id, 'mppResults', myPassionProfileResult._id, 'printable']">
                        <i class="fa fa-print"></i>&nbsp;&nbsp;Print
                    </a>
                </div>
                <div class="mpp-container-fluid mpp-assessment-dark-header">
                    <div class="m-b-lg">
                        <h1 class="m-b-sm">My Passion Profile Report</h1>
                        <p class="font-bold sub-text">
                            Prepared for: {{ user.firstName }} {{ user.lastName }}
                            <br />
                            Date: {{ assessmentResult.createdAt | date }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="paper">
                <div class="mpp-report-contents">
                    <div class="mpp-container-fluid">
                        <h2 class="m-b-md">Table of Contents</h2>
                        <div class="row">
                            <div class="col-sm-8 col-xs-12 m-b-md">
                                <h5>Two Streams of Passion</h5>
                                <p class="sub-text">Get a brief overview of your report.</p>
                            </div>
                            <div class="col-sm-8 col-xs-12 m-b-md"
                                *ngFor="let passion of assessmentResult.passions; let index = index">
                                <h5>{{ passion.name }}</h5>
                                <ol style="list-style-position: inside;">
                                    <li>Level</li>
                                    <li>Narrative Overview</li>
                                    <li>Domains of Passion Engagement</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="page-break"></div>
                </div>
            </div>

            <div class="paper-dark" id="two-streams">
                <div class="mpp-container-fluid">
                    <h2>Two Streams of Passion</h2>
                </div>
            </div>
            <div class="paper">
                <div class="mpp-container-fluid">
                    <p>
                        There are two streams of passion potential in all of us.
                        Both streams flow from self-directed sources of
                        motivation. If you are truly passionate about something,
                        you won't need others to drive you to pursue it. The
                        first stream is your interest-based passions, things you
                        do for fun, they bring you pleasure. The second stream
                        is your issue-based passions, activities you find
                        fulfilling, they give a sense of purpose. The
                        MyPassionProfile.com report is focused entirely on your
                        issue-based passions.
                        <br /><br />
                        Issue-based passions often develop at the intersection
                        of experience and empathy. If you talk to someone who is
                        passionate about homelessness, the environment, sex
                        trafficking or AIDS orphans, you will almost always
                        discover, somewhere in his or her journey, one or more
                        meaningful experiences that unearthed a heightened level
                        of empathy, the headwaters for a stream of issue-based
                        passion.
                        <br /><br />
                        Passion is evidenced by self-directed initiative to
                        learn more about, engage in and influence others toward
                        interests and issues even when sacrifice is required.
                        This progression of self-directed learning, engaging,
                        influencing and sacrificing is the passion pyramid.
                    </p>
                    <div class="text-center">
                        <img src="/assets/images/passion-pyramid.svg" alt="Passion Pyramid"
                            class="w100 mpp-passion-pyramid" />
                    </div>
                    <p>
                        Genuine passion produces a self-directed commitment to
                        learn more about, participate in, and influence others
                        toward interests or issues even when it requires
                        sacrifice or suffering.
                    </p>
                </div>
                <div class="page-break"></div>
            </div>

            <div *ngFor="let passion of assessmentResult.passions; let index = index" class="mpp-passion-container">
                <div class="paper-dark" id="passion-level-{{ index }}">
                    <div class="mpp-container-fluid">
                        <h2 class="m-b-sm">{{ passion.name }}</h2>
                        <p class="sub-text">Taken with {{ passion.assessment.name }}.</p>
                    </div>
                </div>
                <div class="paper">
                    <div class="mpp-container-fluid">
                        <div class="m-t-md">
                            <h3 class="m-b-md">
                                <span *ngIf="passion.passionLevel>0">
                                    You scored {{ passion.passionScore }} out of 100, which translates to a Level
                                    {{ passion.passionLevel }} Passion
                                </span>
                                <span *ngIf="passion.passionLevel<1">
                                    Your responses to the MyPassionProfile
                                    suggest this issue has not risen to the
                                    level of passion in your life.
                                </span>
                            </h3>
                            <div class="row">
                                <div class="col-xs-12 m-b-md" *ngFor="let copy of passion.levelCopy">
                                    {{ copy.text }}
                                </div>
                                <div class="col-xs-12 m-b-md" *ngFor="let copy of passion.warnings">
                                    <div class="card card-sm">
                                        <p class="f6 sub-text">
                                            {{ copy.text }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="passion-narrative-{{ index }}">
                            <h3 class="m-b-sm">Narrative Overview for {{ passion.name }}</h3>
                            <div class="m-b-md"
                                *ngFor="let group of passion.narrativeOverviewAggregatedByTitle; let first = first">
                                <div *ngFor="let copy of group" class="m-t-sm">
                                    <h5 *ngIf="first" class="m-b-sm">
                                        {{ copy.title }}
                                    </h5>
                                    <p>{{ copy.text }}</p>
                                </div>
                            </div>
                        </div>
                        <div id="passion-domains-{{ index }}" class="mpp-assessment-domains-of-passion">
                            <h3 class="m-b-sm">Domains of Passionate Engagement</h3>
                            <div *ngIf="passion.noWinningDomainOfPassion" class="m-b-md">
                                <p>
                                    Based on your results, a specific domain of
                                    passion could not be determined. Domains
                                    where you exhibit strength are highlighted.
                                </p>
                            </div>
                            <div *ngFor="let domainCopy of passion.domainCopy">
                                <p class="m-b-md">{{ domainCopy.text }}</p>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-sm-6 col-xs-12 m-b-md mpp-horizontal-on-print"
                                        *ngFor="let domain of passion.domainsOfPassionateEngagement">
                                        <div class="card">
                                            <h4>{{ domain.name }} <span *ngIf="domain.isHighestDomain">*</span></h4>
                                            <p class="sub-text">{{ domain.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p class="m-b-md">
                                    * denotes the domain is the highest scoring domain for the passion.
                                    <br /><br />
                                    Information in the MyPassionProfile.com
                                    Report is based on Who Is My Neighbor? by
                                    Steve Moore, published by NavPress. Used by
                                    permission, all rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paper hide-print" style="box-shadow: none;">
                <div class="mpp-container-fluid">
                    <hr />
                    <div class="pull-left sub-text f6">
                        Created on {{ assessmentResult.createdAt | date }}
                    </div>
                    <div class="pull-right">
                        <a class="btn btn-primary"
                            [routerLink]="['/users', user._id, 'mppResults', myPassionProfileResult._id, 'printable']">
                            <i class="fa fa-print"></i>&nbsp;&nbsp;Print
                        </a>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</div>