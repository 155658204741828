import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[nexleaderNoClick]',
  standalone: true,
})
export class NexleaderNoClickDirective {
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopPropagation();
  }
}
