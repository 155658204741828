<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<app-nexleader-wrapper-panel>
  <div class="row">
    <div class="col-xs-12">
      <div class="form-group" *ngIf="coachUsers.length > 0 || searchText !== ''">
        <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Search for a user" />
      </div>
    </div>
  </div>
  <p *ngIf="coachUsers.length < 1" style="margin-top: 10px">
    No coach users to show.
  </p>
  <div class="table-responsive" *ngIf="coachUsers.length > 0" style="margin-bottom: 0">
    <table class="table">
      <thead>
        <th>Name</th>
        <th>Email</th>
      </thead>
      <tbody>
        <tr *ngFor="let coachUser of coachUsers | filter : undefined : searchText">
          <td>{{ coachUser.firstName }} {{ coachUser.lastName }}</td>
          <td>{{ coachUser.email }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</app-nexleader-wrapper-panel>