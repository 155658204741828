/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private http: HttpClient) {}

  save(group: any): Observable<any> {
    const url = `${API_BASE}groups` + (group._id ? `/${group._id}` : '');
    return this.http.post(url, group, {
      withCredentials: true,
    });
  }

  get(groupId: string): Observable<any> {
    return this.http.get(`${API_BASE}groups/${groupId}`, {
      withCredentials: true,
    });
  }

  remove(groupId: string): Observable<any> {
    return this.http.delete(`${API_BASE}groups/${groupId}`, {
      withCredentials: true,
    });
  }

  queryIndex(): Observable<any> {
    return this.http.get(`${API_BASE}groups/index`, { withCredentials: true });
  }

  makeLegacy(group: any): Observable<any> {
    return this.http.post(`${API_BASE}groups/${group._id}/makeLegacy`, group, {
      withCredentials: true,
    });
  }

  getLite(groupId: string, userId?: string): Observable<any> {
    let params = new HttpParams();
    if (userId) {
      params = params.append('user_id', userId);
    }
    return this.http.get(`${API_BASE}groups/${groupId}/lite`, {
      withCredentials: true,
      params,
    });
  }

  getForEcourse(groupId: string): Observable<any> {
    return this.http.get(`${API_BASE}groups/${groupId}/ecourse`, {
      withCredentials: true,
    });
  }

  queryLicensingPartner(licensingPartnerId: string): Observable<any> {
    return this.http.get(
      `${API_BASE}licensingPartners/${licensingPartnerId}/groups`
    );
  }

  queryLicensingPartnerIndex(licensingPartnerId: string): Observable<any> {
    return this.http.get(
      `${API_BASE}licensingPartners/${licensingPartnerId}/groups/index`,
      {
        withCredentials: true,
      }
    );
  }

  queryCoachUserIndex(coachUserId: string): Observable<any> {
    return this.http.get(`${API_BASE}coachUsers/${coachUserId}/groups/index`, {
      withCredentials: true,
    });
  }

  queryCustomOnboarding(groupId: string): Observable<any> {
    return this.http.get(`${API_BASE}groups/${groupId}/customOnboarding`);
  }
}
