/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */ /* global angular */

/**
 * nexleaderViewUsersWithAttributeModal
 *
 * A modal that is used to show users who have a certain attribute (MBTI, strength, etc)
 */
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-view-users-with-attribute-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './view-users-with-attribute-modal.component.html',
})
export class NexleaderViewUsersWithAttributeModalComponent {
  /**
   * users
   *
   * An array of users that have the certain attribute to repeat over
   */
  @Input() users: any[] = [];

  /**
   * title
   *
   * A title that renders in the modal's header
   *
   * ex: 'Woo - Strengths'
   */
  @Input() title: string = '';

  /**
   * additionalClarificationCopy
   *
   * Additional copy that allows us to show more personalized data in the modal
   *
   * ex: An asterisk denotes this spiritual gift is the most dominant for this user.
   */
  @Input() additionalClarificationCopy: string = '';

  constructor(private bsModalService: BsModalService) { }

  /**
   * close
   *
   * A close callback function to use after the user successfully saves
   *
   * ex: someFn()
   */
  close(): void {
    this.bsModalService.hide();
  }
}
