<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div
        class="col-lg-4 col-md-6"
        *ngFor="let survey of surveysAndEvaluations"
      >
        <div class="hpanel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12 text-center">
                <h1 class="font-extra-bold">
                  {{ survey.surveyType?.name }} {{ survey.name }}
                </h1>
                <br />
                <p>{{ survey.description }}</p>
              </div>
            </div>
          </div>

          <div class="panel-footer">
            <span class="pull-right">
              <a [routerLink]="['/edits', survey.type, survey._id]">
                <i class="fa fa-pencil"></i> Edit
              </a>
            </span>
            <i class="fa fa-calendar"></i> Updated on
            {{ survey.updatedAt | date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
