/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderEcourseLandingModalComponent } from '../../../core/components/modals/ecourse-landing-modal/ecourse-landing-modal.component';

@Component({
  selector: 'app-nexleader-ecourse-landing-pricing',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './ecourse-landing-pricing.component.html'
})
export class NexleaderEcourseLandingPricingComponent implements OnInit {
  queryParams: any;
  coachId!: string;
  groupId!: string;
  hasCoachOrGroupId: boolean = false;

  constructor(private modalService: BsModalService, private route: ActivatedRoute) { }

  /**
   * $onInit()
   *
   * angular event handler function
   *
   * called when the component binding is complete
   */
  ngOnInit(): void {
    this.queryParams = this.route.snapshot.queryParams;

    this.coachId = this.queryParams['coach_id'];
    this.groupId = this.queryParams['group_id'];
    this.hasCoachOrGroupId = !!this.coachId || !!this.groupId;
    if (this.coachId && this.groupId) {
      throw new Error('Cannot have coach and group_id. group_id will take precedent');
    }
  }

  viewAdditionalInformationModal(identifier: string): void {
    const modalRef = this.modalService.show(NexleaderEcourseLandingModalComponent, {
      initialState: { identifier: identifier },
    });

  }

  getDirectSignupLink(): string {
    if (this.groupId) {
      return `/#/direct/signup?onboarding_experience_identifier=ecourse&group_id=${this.groupId}`;
    } else if (this.coachId) {
      return `/#/direct/signup?onboarding_experience_identifier=ecourse&coach_id=${this.coachId}`;
    } else {
      return '/#/direct/signup?onboarding_experience_identifier=ecourse&survey_type_identifier=values_based';
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEcourseLandingPricingComponent',
//   downgradeComponent({
//     component: NexleaderEcourseLandingPricingComponent,
//   }) as angular.IDirectiveFactory
// );
