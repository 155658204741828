/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartSkill
 *
 * Displays a skills  chart in TeamReport; this component IS NOT USED IN SKILLSINVENTORY.
 *
 * The skills  chart is a component of the team report that displays the top skills in a team
 *
 * This component can easily be generalized for an skill  application.
 */
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Chart } from 'chart.js';
import { NexleaderViewUsersWithAttributeModalComponent } from '../../../core/components/modals/view-users-with-attribute-modal/view-users-with-attribute-modal.component';

@Component({
  selector: 'app-nexleader-chart-skills',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-skills.component.html',
})
export class NexleaderChartSkillsComponent implements OnInit, OnDestroy {
  /**
   * model
   *
   * array in the following form:
   * [{
   *     name: '' //Skill name
   *     value: 5 //Skill level
   *     index: 0 //Skill index
   *     }
   *   ...more...
   *  ]
   *
   * Skills in skill array must match
   */
  @Input() model: any[] = [];
  private chart!: Chart;

  constructor(
    private elementRef: ElementRef,
    private modalService: BsModalService
  ) { }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit(): void {
    // Validate the model and other necessary conditions
    if (!Array.isArray(this.model)) {
      throw new Error(
        'Array model is required for NexleaderChartSkills (see component docs; maybe add an ngIf if it has to load)'
      );
    }

    //Get our canvas element from the html template
    const wrapper = this.elementRef.nativeElement.querySelector(
      '.nexleader-chart-skills-wrapper'
    );

    const canvas = this.elementRef.nativeElement.querySelector('canvas');

    //Fetch the labels from the names of the skills in the model
    const labels = this.model.map((skill) => skill.name);

    //Map the ideal and actual data sets to arrays of numbers for consumption by ChartJS
    const data = this.model.map((skill) => {
      if (typeof skill.value !== 'number') {
        throw new Error(
          'skill.value should be a number; malformed data passed to ChartSkill; see docs'
        );
      }
      return skill.value;
    });

    //Set the proper height for the wrapper div so that the chart renders at the correct size
    // the height should make the bars look about the right size
    // In my experience it's best to size by pixels per data item, so we will set the total size to
    // some constant times the number of data items.
    // labels.length is used to find the number of data items.
    const PIXELS_PER_DATA_ITEM = 40;
    wrapper.style.height = PIXELS_PER_DATA_ITEM * labels.length + 'px';

    //Call Chart.js to render the chart
    this.chart = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Data',
            data: data,
            backgroundColor: 'rgba(60,151,211,0.4)',
            borderColor: 'rgb(60,151,211)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },

        scales: {
          y: {
            // display: false,
          },
          x: {
            position: 'bottom',
            type: 'linear',
            min: 0,
            max: 10,
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              autoSkip: false,
            },
            grid: {
              display: true,
            },
            display: true,
          },
          x2: {
            position: 'top',
            type: 'linear',
            min: 1,
            max: 10,
            ticks: {
              callback: function (value) {
                switch (value) {
                  case 1:
                    return 'Non-Existent';
                  case 3:
                    return 'Below Average';
                  case 5:
                    return 'Average';
                  case 7:
                    return 'Functionally Competent';
                  case 10:
                    return 'World Class';
                  default:
                    return '';
                }
              },
              maxRotation: 0,
              minRotation: 0,
              autoSkip: false,
            },
            grid: {
              drawOnChartArea: false,
            },
            display: true,
          },
        },
      },
    });
  }

  /**
   * viewUsers
   *
   * Allows a user to click on a skill and view the users who have that skill
   *
   * @param {Object} skill - A skill property to use inside of the modal
   */
  viewUsers(skill: any): void {
    const modalRef = this.modalService.show(
      NexleaderViewUsersWithAttributeModalComponent,
      {
        initialState: {
          // Map the title and users from the passed-in parameters
          title: skill.name + ' - Skills',
          users: skill.users,
          additionalClarificationCopy:
            'An asterisk denotes this is a top skill for this user.',
        },
      }
    );
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
