<div class="nexleader-chart-skills-wrapper">
  <canvas></canvas>
</div>
<br />
<div class="row">
  <div class="col-md-3 col-sm-6 col-xs-12 m-b-xs" *ngFor="let skill of model">
    <p class="cursor-pointer" (click)="viewUsers(skill)">
      {{ skill.name }} ({{ skill.count }})
    </p>
  </div>
</div>
