<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Edit Small Group</h3>
</div>
<div class="modal-body" *ngIf="smallGroup">
  <div class="form-group">
    <label class="control-label">Name</label>
    <input
      type="text"
      placeholder="John"
      class="form-control"
      [(ngModel)]="smallGroup.name"
    />
  </div>
  <div class="form-group">
    <label class="control-label">Phone</label>
    <input
      type="text"
      placeholder="123-123-1234"
      class="form-control"
      [(ngModel)]="smallGroup.smallGroupLeaderPhone"
    />
  </div>
  <div class="form-group">
    <label class="control-label">Module Session Length</label>
    <app-nexleader-input-enum
      *ngIf="smallGroup.moduleMeetingLength"
      [model]="smallGroup.moduleMeetingLength"
      [enumName]="'ModuleSessionLengths'"
      (realOnChange)="smallGroup.moduleMeetingLength = $event"
    ></app-nexleader-input-enum>
  </div>
  <h5 class="text-primary-2 font-semi-bold">Meetings</h5>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Start Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let meeting of smallGroupMeetings">
          <td>{{ meeting.smallGroupMeetingType.name }}</td>
          <td>
            <div class="form-group">
              <label class="control-label">Meeting Location</label>
              <input
                type="text"
                placeholder="Coffee Shop"
                class="form-control"
                [(ngModel)]="meeting.location"
              />
            </div>
            <app-nexleader-input-datetime
              [modelDate]="meeting.start"
              [modelTimezone]="meeting.timezone"
              (realOnChange)="
                meeting.start = $event.date; meeting.timezone = $event.timezone
              "
            ></app-nexleader-input-datetime>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="cancel()">
    Cancel
  </button>
  <button
    class="btn btn-default"
    type="button"
    [attr.disabled]="!smallGroupMeetingsAreValid() ? true : null"
    (click)="sendSmallGroupMeetingEmails()"
  >
    Send Small Group Meeting Email
  </button>
  <button class="btn btn-success" type="button" (click)="save()">Save</button>
</div>
