/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentStepFour
 *
 * component
 *
 * This component is responsible for completing the fourth step of the PVI. It asks the user to select their top seven
 *  values based off of the chosen twelve they had previously selected.
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-step-four',
  standalone: true,
  imports: [CommonModule, OrderByPipe],
  templateUrl: './pvi-assessment-step-four.component.html',
})
export class NexleaderComponentPviAssessmentStepFourComponent
  implements OnInit
{
  /**
   * topTwelveValues
   *
   * array
   *
   * An array of the top twelve values that the user has selected. This array is filled within this component
   *  and utilized in the parent component.
   */
  @Input() topTwelveValues!: any[];

  ngOnInit(): void {
    if (!Array.isArray(this.topTwelveValues)) {
      throw new Error(
        'Input "topTwelveValues" must be an array for NexleaderComponentPviAssessmentStepFourComponent.'
      );
    }
  }

  /**
   * toggleValueSelection()
   *
   * function
   *
   * Allows a user to select a value to be used in the top seven values array.
   *
   * @param {string} name - A string used to identify the index of the value in the topTwelveValues
   */
  toggleValueSelection(name: string) {
    // We can't have more than seven values in the array
    // This is an interesting if statement. We want to only return with nothing if there are seven values
    //already and they are trying to add a new one
    this.topTwelveValues.forEach((val) => {
      if (val.name === name) {
        if (this.getValuesInTopSeven().length === 7 && !val.isInTopSeven)
          return;
        val.isInTopSeven = !val.isInTopSeven;
      }
    });
  }

  /**
   * getValuesInTopSeven()
   *
   * function
   *
   * Updates the topSevenValues array to make sure we only have values in the array that have been selected.
   */
  getValuesInTopSeven() {
    return this.topTwelveValues.filter(function (value) {
      return value.isInTopSeven;
    });
  }

  /**
   * shouldDisableSelectionFor()
   *
   * function
   *
   * Returns true if a value is not currently selected and we have reached the cap of selectable items
   *
   * @param value The value object we are wondering about
   * @returns {boolean} true if the value selection should be disabled for the value provided
   */
  shouldDisableSelectionFor(value: any) {
    return !value.isInTopSeven && this.getValuesInTopSeven().length >= 7;
  }
}
