/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-clifton-strengths-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './clifton-strengths-modal.component.html'
})
export class NexleaderCliftonStrengthsModalComponent {
  /**
   * cliftonStrengthsAccessCode
   * 
   * A string representing the access code for the clifton strengths assessment.
   */
  @Input() cliftonStrengthsAccessCode!: string;

  /**
   * cliftonStrengthsAssessmentUrl
   * 
   * A string representing the url for accessing the clifton strengths assessment.
   */
  @Input() cliftonStrengthsAssessmentUrl!: string;

  constructor(public bsModalService: BsModalService) { }

  /**
   * close
   *
   * A close callback function to use
   *
   * ex: someFn()
   */
  close(): void {
    this.bsModalService.hide();
  }
}
