<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<ng-container *ngIf="scoredSkillsInventoryResult">
  <div class="flex flex-vertical-center">
    <h2 class="font-bold">{{ possessivePrefix }} Skills Inventory Result</h2>
    <div class="flex-grow"></div>
    <div class="panel-tools">
      <a
        class="font-semi-bold"
        [routerLink]="['/users', scoredSkillsInventoryResult.user._id, 'skillsInventoryResults', scoredSkillsInventoryResult._id, 'printable']"
      >
        <i class="fa fa-print"></i>&nbsp;&nbsp;Print
        {{ possessivePrefix }} Result
      </a>
    </div>
  </div>

  <div class="row">
    <div
      class="col-md-12 col-xs-12"
      *ngFor="let skillGroup of scoredSkillsInventoryResult.skillGroups"
    >
      <div class="hpanel">
        <div class="panel-heading">{{ skillGroup.name }}</div>
        <div class="panel-body">
          <app-nexleader-skills-inventory-bar
            [val]="skillGroup.display_value"
          ></app-nexleader-skills-inventory-bar>
          <br />
          <p *ngIf="skillGroup.skills.length < 1">
            You did not identify any skills as <b>{{ skillGroup.name }}</b
            >.
          </p>
          <p class="m-b-md" *ngIf="skillGroup.skills.length > 0">
            Based on your responses to the Skills Inventory, you are
            <b>{{ skillGroup.name }}</b> in the following areas:
          </p>
          <ul
            *ngIf="skillGroup.skills.length > 0"
            class="nexleader-skills-inventory-skill-list"
          >
            <li *ngFor="let skill of skillGroup.skills">
              <span>{{ skill.name }} ({{ skill.selectedOption.weight }})</span>
              <br />
              <i>{{ skill.additionalResponse }}</i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="hpanel m-b-md">
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <h4 class="font-bold">Questions for Reflection</h4>
              <p class="m-b-md">
                To what extent would your friends, peers, and colleagues
                validate your perspective as it relates to your most well
                developed skills? Who could you ask about this to test your
                self-awareness for blind spots that might cause you to
                overestimate your capacity?
              </p>
              <p class="m-b-md">
                What methods have been most effective in helping you cultivate
                these skills? How could you repurpose this learning methodology
                to develop other skills?
              </p>
              <p>
                How could these skills synergize with other aspects of your
                identity profile?
              </p>
            </div>
            <div class="col-sm-6 col-xs-12 border-left">
              <h4 class="font-bold">Ideal Role</h4>
              <div class="m-b-md">
                <b>Future Ideal Role</b>
                <br />
                <p>
                  {{
                    scoredSkillsInventoryResult.textResponses.futureIdealRole
                  }}
                </p>
              </div>
              <div class="m-b-md">
                <b>Skills Needed</b>
                <br />
                <p>
                  {{ scoredSkillsInventoryResult.textResponses.skillsNeeded }}
                </p>
              </div>
              <div class="m-b-md">
                <b>Under-Developed Skills</b>
                <br />
                <p>
                  {{
                    scoredSkillsInventoryResult.textResponses
                      .underdevelopedSkills
                  }}
                </p>
              </div>
              <div>
                <b>Under-Developed Skills Explanation</b>
                <br />
                <p>
                  {{
                    scoredSkillsInventoryResult.textResponses
                      .underdevelopedSkillsExplanation || "No explanation"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="hpanel m-b-md">
        <div class="panel-heading">Your Skills</div>
        <div class="panel-body">
          <app-nexleader-skills-inventory-skill-chart
            *ngIf="scoredSkillsInventoryResult.skills"
            [skills]="scoredSkillsInventoryResult.skills"
          ></app-nexleader-skills-inventory-skill-chart>
        </div>
      </div>
    </div>
  </div>
</ng-container>
