/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  ElementRef,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-nexleader-skills-inventory-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skills-inventory-bar.component.html',
})
export class NexleaderSkillsInventoryBarComponent implements AfterViewInit, OnDestroy {
  @Input() val!: number;

  // Used by printable view to lock width and height
  @Input() width: number | null = null;
  @Input() height: number | null = null;
  @Input() disableResponsive: boolean = false;

  @ViewChild('chartCanvas') chartCanvas!: ElementRef;
  private chart!: Chart;

  constructor(private elementRef: ElementRef) { }

  drawChart(): void {
    const ctx: HTMLCanvasElement = this.chartCanvas.nativeElement;

    // Set the chart height as 40 px per skill (or preconfigured)
    ctx.height = this.height || 90;
    // Set the custom width or leave it as what it was
    ctx.width = this.width || ctx.width;

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Score'],
        datasets: [
          {
            label: 'Score',
            data: [this.val],
            backgroundColor: 'rgba(76,186,114,0.2)',
            borderColor: 'rgba(76,186,114,1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: this.disableResponsive !== true,
        scales: {
          y: {
            display: false,
          },
          x: {
            position: 'bottom',
            min: 1,
            max: 10,
            ticks: {
              maxRotation: 0,
              stepSize: 1,
              autoSkip: false,
            },
            grid: {
              display: true,
            },
            display: true,
          },
          x2: {
            position: 'top',
            min: 1,
            max: 10,
            ticks: {
              stepSize: 1,
              callback: (value) => {
                switch (value) {
                  case 1:
                    return 'Non-Existent';
                  case 3:
                    return 'Below Average';
                  case 5:
                    return 'Average';
                  case 7:
                    return 'Functionally Competent';
                  case 10:
                    return 'World Class';
                  default:
                    return '';
                }
              },
              maxRotation: 0,
              autoSkip: false,
            },
            grid: {
              drawOnChartArea: false,
            },
            display: true,
          },
        },
        plugins: {
          tooltip: {
            //Manually select the xAlign because Chart.js wasn't doing a good job.
            // left align until it goes off the screen, which is only at a val of 10
            xAlign: this.val > 9 ? 'right' : 'left',
          },
          legend: {
            display: false,
          },
        },
      },
    });
  }

  ngAfterViewInit(): void {
    this.drawChart();
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
