/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderMppImport
 *
 * component
 *
 * "Import from MPP" button
 *
 * this component is a button that launches the MPP export process for a user with the bound user_id
 *
 * queries the backend for a signed exportRequest token to send to mpp and for the exact url to navigate to
 *  the URL this button takes users to is configured by the field 'export.exportUrl' in config.
 *  the endpoint generateExportRequest sends us this value (along with the token it generates)
 */
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { PassionProfileResultService } from '../../../../services/passion-profile-result.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-mpp-import',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mpp-import.component.html',
})
export class NexleaderMppImportComponent {
  @Input() userId: string = '';

  constructor(
    private passionProfileResultService: PassionProfileResultService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  importPassionData(): void {
    this.passionProfileResultService
      .generateExportRequest({
        user_id: this.userId,
        return_url: window.location.href,
      })
      .pipe(
        tap((exportRequest) => {
          window.location.href =
            exportRequest.exportUrl +
            '?exportRequestJwt=' +
            exportRequest.token;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
