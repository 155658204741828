/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { GroupService } from '../../../../services/group.service';
import { SuccessService } from '../../../../services/success.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, of, take, tap } from 'rxjs';
import { NexleaderQuickViewComponent } from '../../../core/components/quick-view/quick-view.component';
import { NexleaderQuickViewNotSelectedComponent } from '../../../core/components/quick-view-not-selected/quick-view-not-selected.component';
import { NexleaderGroupCardComponent } from '../../../core/components/group-card/group-card.component';
import { NexleaderNewGroupModalComponent } from '../../../core/components/modals/new-group-modal/new-group-modal.component';

@Component({
  selector: 'app-nexleader-licensing-partner-groups',
  standalone: true,
  imports: [CommonModule, NexleaderQuickViewComponent, NexleaderQuickViewNotSelectedComponent, NexleaderGroupCardComponent],
  templateUrl: './licensing-partner-groups.component.html'
})
export class NexleaderLicensingPartnerGroupsComponent implements OnInit {
  routeParams: any;
  searchText: string = '';
  groups: any[] = [];
  selectedGroup: any;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private groupService: GroupService,
    private successService: SuccessService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    this.groupService.queryLicensingPartnerIndex(this.routeParams['licensing_partner_id']).pipe(
      tap((data) => {
        this.groups = data;
        this.groups.forEach(group => {
          group._licensesRemaining = `${group.remainingLicenses}/${group.maxUsers}`;
          group._tierName = group.tier.name;
          group._surveyTypeName = group.surveyType.name;
        });
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  open(): void {
    const modalRef = this.modalService.show(NexleaderNewGroupModalComponent);
    modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.load();
      this.successService.handle();
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderLicensingPartnerGroupsComponent',
//   downgradeComponent({
//     component: NexleaderLicensingPartnerGroupsComponent,
//   }) as angular.IDirectiveFactory
// );
