<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="login-container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center m-b-md">
        <div class="text-center m-b-md">
          <img
            src="/assets/images/logo.svg"
            width="125"
            height="100"
            alt="Logo"
          />
        </div>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <p class="text-danger">{{ message }}</p>
          <form id="loginForm" (ngSubmit)="login()">
            <div class="form-group">
              <label class="control-label">Email</label>
              <input
                type="text"
                placeholder="example@gmail.com"
                class="form-control"
                name="email"
                [(ngModel)]="credentials.email"
                [ngModelDebounce]="500"
                (ngModelDebounceChange)="updateLogin()"
              />
            </div>
            <div *ngIf="requiresDisambiguation" class="form-group">
              <label class="control-label">Group</label>
              <select
                class="form-control"
                [(ngModel)]="credentials.group"
                name="group"
              >
                <option value="undefined" disabled selected>
                  -- Select a group --
                </option>
                <option
                  *ngFor="let group of groups; trackBy: trackByGroupId"
                  [value]="group._id"
                >
                  {{ group.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">Password</label>
              <input
                name="password"
                type="password"
                placeholder="******"
                class="form-control"
                [(ngModel)]="credentials.password"
              />
            </div>
            <button type="submit" class="btn btn-success btn-block">
              Login
            </button>
            <br />
            <div class="text-center m-b-md">
              <a [routerLink]="['/passwordReset']">Forgot Password</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <strong>IPSAT</strong> <br />
      2017 Copyright nexleader
    </div>
  </div>
</div>
