/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class PassionProfileResultService {
  constructor(private http: HttpClient) { }

  get(passionProfileResultId: string, userId?: string) {
    let params = new HttpParams();
    if (userId) {
      params = params.append('user_id', userId);
    }
    const url = `${API_BASE}passionProfileResult/${passionProfileResultId}`;
    return this.http.get(url, { withCredentials: true, params });
  }

  generateExportRequest(payload: any): Observable<any> {
    const url = `${API_BASE}passionProfileResults/generateExportRequest`;
    return this.http.post(url, payload, { withCredentials: true });
  }

  queryUserIndex(userId: string): Observable<any[]> {
    const url = `${API_BASE}users/${userId}/passionProfileResults/index`;
    return this.http.get<any[]>(url, { withCredentials: true });
  }

  queryUser(userId: string): Observable<any[]> {
    const url = `${API_BASE}users/${userId}/passionProfileResults`;
    return this.http.get<any[]>(url, { withCredentials: true });
  }
}
