<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="shareable-result">
  <div class="shareable-header">
    <div class="max-width-1200">
      <div class="pull-right quote m-l-md m-b-md">
        <p>
          “Before I can tell my life what I want to do with it, I must listen to
          my life telling me who I am.” <br /><i>-Parker Palmer</i>
        </p>
      </div>
      <br />
      <h1>myIPSAT</h1>
      <h3>Identity Profile Self-Awareness Tool</h3>
      <br />
      <h4>powered by</h4>
      &nbsp;&nbsp;
      <img src="/assets/images/nexleader_logo.svg" alt="nexleader logo" class="full-width-image" />
    </div>
  </div>
  <div class="shareable-about">
    <div class="max-width-1200">
      <div class="row nexleader-onboarding-reverse-row">
        <div class="col-sm-6 shareable-about-info">
          <h3>What is the IPSAT?</h3>
          <p>
            The Identity Profile Self-Awareness Tool (IPSAT) is not a new
            assessment. It is a guided discovery exercise designed to help you
            evaluate and quantify how well you understand and engage with the
            five components of your Identity Profile.
          </p>
        </div>
        <div class="col-sm-6 text-center">
          <img src="/assets/images/logo.svg" alt="logo" class="m-b-sm" />
        </div>
      </div>
    </div>
  </div>
  <div class="shareable-results">
    <h3 class="text-center m-b-md">My Results</h3>
    <div class="max-width-1200">
      <div class="row" *ngIf="surveyResult">
        <div class="col-md-4">
          <div class="shareable-card">
            <div class="text-center m-b-md">
              <img src="/assets/images/icons/personality.svg" alt="personality" width="100px" />
            </div>
            <h3 class="text-center">Personality</h3>
            <p>
              My personality can best be described as
              <b>{{ surveyResult.prerequisites.mbti.name }}</b>.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="shareable-card">
            <div class="text-center m-b-md">
              <img src="/assets/images/icons/strengths.svg" alt="strengths" width="100px" />
            </div>
            <h3 class="text-center">Strengths</h3>
            <p>
              My top five strengths are
              <span *ngFor="
                  let strength of surveyResult.prerequisites.strengths;
                  let last = last
                ">
                <b>{{ strength.name }}</b><span *ngIf="!last">, </span> </span>.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="shareable-card">
            <div class="text-center m-b-md">
              <img src="/assets/images/icons/skills.svg" alt="skills" width="100px" />
            </div>
            <h3 class="text-center">Skills</h3>
            <p>
              My top three skills are
              <span *ngFor="
                  let skill of surveyResult.prerequisites.skills;
                  let last = last
                ">
                <b>{{ skill.name }}</b><span *ngIf="!last">, </span> </span>.
            </p>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-md-4">
          <div class="shareable-card">
            <div class="text-center m-b-md">
              <img src="/assets/images/icons/spiritualgifts.svg" alt="spiritual gifts" width="100px" />
            </div>
            <div *ngIf="surveyTypeIdentifier === 'faith_based'">
              <h3 class="text-center">Spiritual Gifts</h3>
              <p>
                My top three spiritual gifts are
                <span *ngFor="
                    let gift of surveyResult.prerequisites.spiritualGifts;
                    let last = last
                  ">
                  <b>{{ gift.name }}</b><span *ngIf="!last">, </span> </span>.
              </p>
            </div>
            <div *ngIf="surveyTypeIdentifier === 'values_based'">
              <h3 class="text-center">Personal Values</h3>
              <p>
                My top three personal values are
                <span *ngFor="
                    let gift of surveyResult.prerequisites.spiritualGifts;
                    let last = last
                  ">
                  <b>{{ gift.name }}</b><span *ngIf="!last">, </span> </span>.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="shareable-card">
            <div class="text-center m-b-md">
              <img src="/assets/images/icons/passions.svg" alt="passions" width="100px" />
            </div>
            <h3 class="text-center">Passions</h3>
            <p>
              My top three passions are
              <span *ngFor="
                  let passion of surveyResult.prerequisites.passions;
                  let last = last
                ">
                <b>{{ passion.name }}</b><span *ngIf="!last">, </span> </span>.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="shareable-card">
            <h3 class="text-center">Identity Overview Statement</h3>
            <p *ngFor="
                let summaryOverviewQuestion of surveyResult.questions
                  | questionCategory : summaryOverviewQuestionCategory
              ">
              {{ summaryOverviewQuestion.answer.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="text-center m-t-xl m-b-md shareable-footer">
        <h4 class="font-bold">Be who you are. Love what you do.</h4>
      </div>
      <div>
        <div class="pull-left">
          {{ user?.firstName }} {{ user?.lastName }}
          <span *ngIf="coach">
            coached by
            <a *ngIf="coach.coachingUrl" [href]="coach.coachingUrl">{{ coach.firstName }} {{ coach.lastName }}</a>
            <a *ngIf="!coach.coachingUrl" [href]="
                'mailto:' + coach.email + '?subject=I’m interested in the IPSAT'
              ">{{ coach.firstName }} {{ coach.lastName }}</a>.
          </span>
        </div>
        <div class="text-center">
          <a [routerLink]="['/s', surveyResult?._id, 'printable']">Print Result</a>
        </div>
        <div class="pull-right">
          <a href="http://myipsat.com" target="_blank">myipsat.com</a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>