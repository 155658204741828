<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper" style="height: 100%; overflow: hidden">
  <div
    class="content"
    style="
      height: calc(100% - 85px);
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <div style="flex-grow: 1; height: 100%; overflow-y: hidden">
      <app-nexleader-quick-view
        *ngIf="invites"
        [model]="invites"
        [tableHeaders]="['Name', 'Email', 'Coach']"
        [tableColumns]="['_name', 'email', '_coach']"
        [label]="'Invites for ' + group?.name"
        [overrideDataNewElementText]="'Create Invite'"
        (overrideOnNewElement)="createInvite()"
        (onElementSelect)="selectedGroup = $event"
      >
        <ng-container *ngIf="selectedGroup?._id; else notSelected">
          <app-nexleader-invite-card
            [invite]="selectedGroup"
            (onChange)="load()"
          ></app-nexleader-invite-card>
        </ng-container>
        <ng-template #notSelected>
          <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
        </ng-template>
      </app-nexleader-quick-view>
    </div>
  </div>
</div>
