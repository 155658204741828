<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="panel-heading" *ngIf="title">
          <h2>{{ title }}</h2>
        </div>
      </div>
      <div class="col-md-12">
        <div class="hpanel m-n">
          <div class="panel-body">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
