<div class="nexleader-chart-letter-aggregation row">
  <div class="row">
    <div *ngFor="let row of tableData" class="col-3-print col-xs-12">
      <div
        class="nexleader-chart-letter-aggregation-cell col-xs-6 col-12-print m-b-md"
        *ngFor="let mbtiCountObject of row"
      >
        <div class="flex flex-vertical-center m-b-sm">
          <div class="nexleader-chart-letter-wrapper">
            <div class="nexleader-chart-letter-aggregation-letter">
              {{ mbtiCountObject.letter }}
            </div>
          </div>
          <h4
            class="font-normal m-l-sm"
            [ngClass]="{ 'cursor-pointer': mbtiCountObject.value > 0 }"
            (click)="viewUsers(mbtiCountObject)"
          >
            {{ mbtiCountObject.name }} ({{ mbtiCountObject.value }})
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
