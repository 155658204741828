/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentIntro
 *
 * component
 *
 * A component that shows the intro pages that the user goes through before they begin the personal values assessment.
 *  These views show text that puts users in the right mindset so they can go through the assessment in the right way of
 *  thinking.
 */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-intro',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pvi-assessment-intro.component.html',
})
export class NexleaderComponentPviAssessmentIntroComponent {
  /**
   * advanceToAssessment()
   *
   * function
   *
   * The final functionality after the intro assessments have been complete. This allows the user to start the
   *  assessment when they are done with these screens.
   */
  @Output() advanceToAssessment = new EventEmitter();

  // This allows us to control the flow for moving through the intro paragraphs
  selectedStep = 1;

  // The maximum step a user can advance to. This would be the last page
  maxStep = 2;

  // Moves the user to the next step
  nextStep() {
    this.selectedStep++;
  }

  // Moves the user to the previous step
  previousStep() {
    this.selectedStep--;
  }

  // Helper function that determines if the user can move to the next step
  canMoveToNextStep() {
    return this.selectedStep !== this.maxStep;
  }

  // Helper function that determines if the user can move to the previous step
  canMoveToPreviousStep() {
    return this.selectedStep !== 1;
  }

  // Comparator function that determines which step to show
  currentStep(val: number) {
    return this.selectedStep === val;
  }
}
