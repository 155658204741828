/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentResult
 *
 * component
 *
 * This component contains the components and view layout to display the information returned from the
 *  PVIAssessmentResult scoring. It does not do any logic on its own, but instead functions as a render component
 *  where elements are displayed within.
 */
import { Component, Input, OnInit } from '@angular/core';
import { EMPTY, catchError, tap } from 'rxjs';
import { PersonalValuesInventoryResultService } from '../../../../services/personal-values-inventory-result.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { CommonModule } from '@angular/common';
import { NexleaderComponentChartPviBarValuesComponent } from '../chart-pvi-bar-values/chart-pvi-bar-values.component';
import { NexleaderComponentChartPviSpiderComponent } from '../chart-pvi-spider/chart-pvi-spider.component';
import { RouterLink, RouterModule } from '@angular/router';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-result',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NexleaderComponentChartPviBarValuesComponent,
    NexleaderComponentChartPviSpiderComponent,
  ],
  templateUrl: './pvi-assessment-result.component.html',
})
export class NexleaderComponentPviAssessmentResultComponent implements OnInit {
  /**
   * pviResultId
   *
   * string
   *
   * A string binding to the mongoose ID of a PVI result.
   */
  @Input() pviResultId!: string;

  /**
   * userId
   *
   * string
   *
   * A string binding to the mongoose ID of a user.
   */
  @Input() userId!: string;
  allPersonalValueDomains: any[] = [];
  pviAssessmentResultScore: any;
  topTwelveValueDomainScore: number[] = [];
  topSevenValueDomainScore: number[] = [];
  topThreeValueDomainScore: number[] = [];

  constructor(
    private personalValuesInventoryResultService: PersonalValuesInventoryResultService,
    private enumsService: EnumsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    // Fetch enums so we can loop over
    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.allPersonalValueDomains = enums.AllPersonalValueDomains;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();

    // Load the personal values inventory result score
    this.personalValuesInventoryResultService
      .getScore(this.pviResultId)
      .pipe(
        tap((result) => {
          this.pviAssessmentResultScore = result;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe(() => {
        const orderedArrayOfDomains = [
          'Others',
          'Avocation',
          'Self',
          'Vocation',
        ];
        const sortedArray = [
          ...this.pviAssessmentResultScore.personalValueDomainScores,
        ].sort(
          (a, b) =>
            orderedArrayOfDomains.indexOf(a.name) -
            orderedArrayOfDomains.indexOf(b.name)
        );

        // we need these three arrays to determine the spider graphs correctly
        this.topTwelveValueDomainScore = sortedArray.map(
          (v) => v.twelveValuesScore
        );
        this.topSevenValueDomainScore = sortedArray.map(
          (v) => v.sevenValuesScore
        );
        this.topThreeValueDomainScore = sortedArray.map(
          (v) => v.threeValuesScore
        );
      });
  }
}
