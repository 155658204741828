/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-add-unformatted-text',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './add-unformatted-text.component.html',
})
export class NexleaderAddUnformattedTextComponent {
  text: string = '';
  formattedText: string = '';

  constructor(private bsModalService: BsModalService) { }

  formatText() {
    this.formattedText = this.text.replace(/(<([^>]+)>)/gi, '');
  }

  close() {
    this.bsModalService.hide();
  }
}
