/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentQuickView
 *
 * angular component
 *
 * The quick view is characterized by a table and search bar on the left with a preview on the right.
 *
 * See mock ups for more detail.
 *
 * This component can be used similarly to ng-repeat. Calling <nexleader-offer-comparison-quick-view nexleader-offer-comparison-model="customer in customers">
 * will iterate over the customers array providing a search. The right area will contain the transcluded contents with a
 * "customer" property.
 *
 * When rendering columns, the component accesses element[column] where element is the iteration element and column
 *  is a provided column name. If the value is a string, the string is rendered. If it's strictly true, a check is
 *  rendered. If it has a name property, the name property is rendered. Otherwise, it's rendered as blank.
 *
 * This component is used by the TeamProducts view to display a list of products, allow product editing, and allow
 *  creation of new products.
 *
 * @author Jake Billings
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '../../../../pipes/filter.pipe';

@Component({
  selector: 'app-nexleader-quick-view',
  standalone: true,
  imports: [CommonModule, FormsModule, FilterPipe],
  templateUrl: './quick-view.component.html',
})
export class NexleaderQuickViewComponent implements OnInit, OnChanges {
  @Input() set model(value: any) {
    this.elements = value;
    //If the selected element is no longer in the array, deselect all elements.
    if (this.elements && this.elements.indexOf(this.selectedElement) < 0) {
      this.selectElement(null);
    }

    //If we create a new element but then save it, it will have an _id
    // if it has an _id and is saved, it's no longer the new element, so delete the flag
    if (this.elements) {
      this.elements.forEach(function (element) {
        if (element._isQuickViewNewElement && element._id) {
          delete element._isQuickViewNewElement;
        }
      });
    }

    if (this.elements) {
      // Check if there exists a previously selected element in the QuickView
      var preselectedElementId = this.getCookie(
        this.quickViewSelectedKey + this.label.toLowerCase().split(' for ')[0]
      );
      // Ensure that the preselected element exists in the array
      var preselectedElement = this.elements.filter(function (element) {
        return element._id === preselectedElementId;
      })[0];

      if (preselectedElement) {
        this.selectElement(preselectedElement);
      }
    }

    const htmlEl = document.getElementById('nexleader-quick-view-table-active');
    if (htmlEl) {
      const tableElement = document.getElementById('nexleader-quickview-table');
      if (tableElement) {
        tableElement.scrollTop = htmlEl.offsetTop;
      }
    }
  }

  /**
   * Comma delimited list of headers for the preview table from which element will be selected.
   *
   * For instance, a customer tableHeaderString may be "First Name,Last Name,Phone"
   *
   * See component docs for how cells are rendered in rows.
   *
   * {String}
   */
  @Input() tableHeaders: string[] = [];

  /**
   * Comma delimited list of element properties to display in each row for the preview table from which
   * element will be selected.
   *
   * For instance, a customer tableHeaderString may be "firstName,lastName,phone"
   *
   * See component docs for how cells are rendered in rows.
   *
   * {String}
   */
  @Input() tableColumns: string[] = [];

  /**
   * nexleaderDataLabel - The label element that is used in the upper left of the left hpanel.
   *
   * OPTIONAL; if a value is not passed, the default text is 'Quick View'
   *
   * @type {string}
   */
  @Input() label: string = '';

  /**
   * newElementTemplate - The template used to create new quick view elements.
   *
   * OPTIONAL; if a value is not passed, the default is an object with only the prop _isQuickViewNewElement
   *  as true.
   *
   * VALUES MUST have the property _isQuickViewNewElement as true; otherwise clicking the "create new" button
   *  will work multiple times.
   *
   * See $ctrl.createNewElement()
   *
   * @type {{_isQuickViewNewElement: boolean}}
   */
  @Input() newElementTemplate: any;

  /**
   * overrideNewElementText
   *
   * Overrides the default text "create new" in the blue button in the upper right corner
   */
  @Input('overrideDataNewElementText') overrideNewElementText: string = '';

  /**
   * overrideDisplayNewElement
   *
   * Allows us to hide the "create new" button if a certain condition is met
   */
  @Input() overrideDisplayNewElement: boolean = false;

  @Output() onElementSelect = new EventEmitter();
  @Output() overrideOnNewElement = new EventEmitter();

  searchText = '';
  elements: any[] = [];
  selectedElement: any;

  /**
   * quickViewSelectedKey
   *
   * string
   *
   * A string identifier representing the _id to pull from cookies to preselect an element.
   */
  private quickViewSelectedKey = 'nexleaderQuickViewPreselected_';

  /**
   * Initialize the quick view
   */
  ngOnInit() {
    // Init default new element template (see newElementTemplate in bindings)
    if (!this.newElementTemplate) {
      this.newElementTemplate = {
        _isQuickViewNewElement: true,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['overrideDisplayNewElement']) {
      this.overrideDisplayNewElement =
        changes['overrideDisplayNewElement'].currentValue;
    }
  }

  /**
   * Select an element in the quick view.
   *
   * Element must be contained in the $ctrl.elements array. The $ctrl.elements array is the array being
   * repeated over in this quick view. For instance, if using "customer in customers," $ctrl.elements===customers.
   *
   * It is also acceptable to select falsey values. This is interpreted as deselecting all elements.
   *
   * @param element The element to select
   */
  selectElement(element: any) {
    //Throw an error if anybody tries to select an element that isn't in the elements array.
    if (element && this.elements.indexOf(element) < 0) {
      throw new Error(
        'Cannot select an element that is not in the elements array.'
      );
    }

    // Save the element if previously created
    if (element && element._id) {
      this.setCookie(
        this.quickViewSelectedKey + this.label.toLowerCase().split(' for ')[0],
        element._id
      );
    }
    //Store the state of the selected element in the controller
    this.selectedElement = element;

    this.onElementSelect.emit(element);
  }

  /**
   * isNewElementPresent()
   *
   * Returns true if there is an element in $ctrl.elements with the flag _isQuickViewNewElement
   *
   * Used to disable button and throw error if createNewElement() is called
   *
   * See createNewElement() and newElementTemplate
   *
   * @returns {boolean} if the element is present
   */
  isNewElementPresent() {
    return (
      this.elements &&
      this.elements.filter((element) => element._isQuickViewNewElement).length >
      0
    );
  }

  /**
   * createNewElement()
   *
   * If overrideNewElement is defined, that function is called and the below function is ignored.
   *
   * Adds a copy of $ctrl.newElementTemplate to the elements array if there is no element with a truthy
   *  _isQuickViewNewElement property
   *
   *  Used for "Create New" buttons and the like
   *
   * Creation of the element should trigger some sort of update that removes the _isQuickViewNewElement
   *  from the element array.
   */
  createNewElement() {
    //If there's an override, execute it and ignore the rest of the function.
    this.overrideOnNewElement.emit();
    return;

    //Check if a "new" element already exists in the element array
    //   if ($ctrl.isNewElementPresent()) {
    //     throw new Error('Cannot create a new element while a new element is already being created.');
    // }

    // //Clone it, so we don't get weirdness
    // var newElement = angular.copy($ctrl.newElementTemplate);

    // //Push the element to the elements array
    // $ctrl.elements.push(newElement);

    // //Select the element
    // $ctrl.selectElement(newElement);
  }

  /**
   * cancel()
   *
   * Allows a user to cancel out of creating or editing an element in the quick view
   *
   * Useful when the user is creating something, but wants to cancel creating it
   */
  cancel() {
    // We need to filter out elements, so that there are no "new elements" being created
    this.elements = this.elements.filter((e) => !e._isQuickViewNewElement);
    // Set the selectedElement to null so it disappears
    this.selectedElement = null;
  }

  private getCookie(name: string): any {
    const match = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]+)')
    );
    return match ? match[2] : null;
  }

  private setCookie(name: string, value: string): void {
    document.cookie = name + '=' + value + '; path=/';
  }

  isString(value: any) {
    return typeof value === 'string';
  }
}
