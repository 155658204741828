import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PersonalValuesInventoryResultService } from '../../../../services/personal-values-inventory-result.service';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { EMPTY, catchError } from 'rxjs';
import { NexleaderComponentPviAssessmentIntroComponent } from '../../components/pvi-assessment-intro/pvi-assessment-intro.component';
import { NexleaderComponentPviAssessmentStepOneComponent } from '../../components/pvi-assessment-step-one/pvi-assessment-step-one.component';
import { NexleaderComponentPviAssessmentStepTwoComponent } from '../../components/pvi-assessment-step-two/pvi-assessment-step-two.component';
import { NexleaderComponentPviAssessmentStepThreeComponent } from '../../components/pvi-assessment-step-three/pvi-assessment-step-three.component';
import { NexleaderComponentPviAssessmentStepFourComponent } from '../../components/pvi-assessment-step-four/pvi-assessment-step-four.component';
import { NexleaderComponentPviAssessmentStepFiveComponent } from '../../components/pvi-assessment-step-five/pvi-assessment-step-five.component';

@Component({
  selector: 'app-nexleader-view-pvi-assessment',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderComponentPviAssessmentIntroComponent,
    NexleaderComponentPviAssessmentStepOneComponent,
    NexleaderComponentPviAssessmentStepTwoComponent,
    NexleaderComponentPviAssessmentStepThreeComponent,
    NexleaderComponentPviAssessmentStepFourComponent,
    NexleaderComponentPviAssessmentStepFiveComponent,
  ],
  templateUrl: './pvi-assessment.component.html', // Update the path as needed
})
export class NexleaderViewPviAssessmentComponent implements OnInit, OnDestroy {
  routeParams: any;
  user: any;

  // Build a static object that will be filled as the user progresses through the stages
  personalValuesInventoryResult: any = {
    // Pull the user from the route params
    user: '',
    topTwelveValues: [],
  };

  /**
   * selectedStepIndex
   * @type {number} the index of the step of the PVI assessment we're on (see docs for this component)
   */
  selectedStepIndex = 1;

  /**
   * maxStepIndex
   * @type {number} the index of the highest step in the PVI assessment process (so that we can hide next())
   */
  maxStepIndex = 6;

  /**
   * minStepIndex
   * @type {number} the index of the lowest step in the PVI assessment process (so that we can hide previous())
   */
  minStepIndex = 1;

  isPVICompleted = false;

  /**
   * steps
   *
   * array of objects
   *
   * We need some way to reference what step the user is on and if the step has been completed
   *  successfully. This allows us to correctly render checkmarks of what steps have been completed and
   *  what steps have not.
   */
  steps = [
    {
      name: 'Select Values',
      stepHasBeenCompleted: () =>
        // There must be twelve values to advance
        this.personalValuesInventoryResult.topTwelveValues.length === 12,
    },
    {
      name: 'Rate Values',
      // Each value must be assigned a rating which is in the form of a number
      stepHasBeenCompleted: () =>
        this.personalValuesInventoryResult.topTwelveValues.every(
          (value: any) => typeof value.rating === 'number'
        ) && this.personalValuesInventoryResult.topTwelveValues.length > 0,
    },
    {
      name: 'Describe Values',
      stepHasBeenCompleted: () => {
        // Since the description is no longer required, we immediately can return true so long the
        //previous step has been completed where each value has a rating
        return (
          this.personalValuesInventoryResult.topTwelveValues.every(
            (value: any) => {
              return value.valueDescriptionHasBeenViewed;
            }
          ) && this.personalValuesInventoryResult.topTwelveValues.length > 0
        );
      },
    },
    {
      name: 'Top Seven',
      stepHasBeenCompleted: () => {
        // There must be seven values that are marked isInTopSeven
        return (
          this.personalValuesInventoryResult.topTwelveValues.filter(
            (v: any) => {
              return v.isInTopSeven;
            }
          ).length === 7
        );
      },
    },
    {
      name: 'Top Three',
      stepHasBeenCompleted: () => {
        // There must be three values that are marked isInTopThree
        return (
          this.personalValuesInventoryResult.topTwelveValues.filter(
            (v: any) => {
              return v.isInTopThree;
            }
          ).length === 3
        );
      },
    },
  ];

  // private deregisterRouteChangeListener: any; // Replace with the correct type

  constructor(
    private personalValuesInventoryResultSvc: PersonalValuesInventoryResultService,
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    private successService: SuccessService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload($event: any) {
    if (!this.isPVICompleted) {
      $event.returnValue = false;
    }
  }

  canDeactivate() {
    if (!this.isPVICompleted) {
      return confirm('Are you sure you want to leave this page?');
    }
    return true;
  }

  ngOnInit(): void {
    // TODO: show alert when route is changed through angular routing
    // this.deregisterRouteChangeListener = this.route.queryParams.subscribe(
    //   () => {
    //     // Handle route change logic if needed
    //   }
    // );

    this.routeParams = this.route.snapshot.params;

    const userId = this.routeParams['user_id'];
    this.personalValuesInventoryResult = {
      user: userId,
      topTwelveValues: [],
    };
    // We need to get a user so we can properly check if they have already completed the onboarding or not
    this.userService
      .getUser(userId)
      .pipe(
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngOnDestroy(): void {
    this.isPVICompleted = true;
    // this.deregisterRouteChangeListener.unsubscribe();
  }

  /**
   * isSelectedStep()
   *
   * @param n
   * @returns {boolean} true if n === selectedStepIndex
   */
  isSelectedStep(n: number): boolean {
    return this.selectedStepIndex === n;
  }

  /**
   * canAdvanceToNextStep()
   *
   * pure function
   *
   * uses dataModelIsValidToAdvanceStep()
   *
   * @returns {boolean} returns true if there's another step and we should who next button
   */
  canAdvanceToNextStep(): boolean {
    if (this.selectedStepIndex > this.maxStepIndex) {
      return false;
    }
    return this.dataModelIsValidToAdvanceStep();
  }

  /**
   * canRegressToPreviousStep()
   *
   * @returns {boolean} returns false if there's previous step and we're allowed to go back
   */
  canRegressToPreviousStep(): boolean {
    return false; // never allowed to go back due to logical issues w/ data model
  }

  /**
   * nextStep()
   *
   * increments selectedStepIndex and therefore the step
   * throws error if called when selected step is the max step
   */
  nextStep(): void {
    if (!this.canAdvanceToNextStep()) {
      throw new Error('Cannot advance to the next step');
    }
    this.selectedStepIndex++;
  }

  /**
   * previousStep()
   *
   * decrements selectedStepIndex
   * throws error if called when selected step is the min step
   */
  previousStep(): void {
    if (!this.canRegressToPreviousStep()) {
      throw new Error('Cannot regress to the previous step');
    }
    this.selectedStepIndex--;
  }

  /**
   * dataModelIsValidToAdvanceStep()
   *
   * pure function
   *
   * called by canAdvanceToNextStep()
   *
   * @returns boolean true if the data model is complete for a given step (ignores min/max step)
   */
  dataModelIsValidToAdvanceStep(): boolean {
    if (!this.personalValuesInventoryResult) {
      return false;
    }
    if (!this.personalValuesInventoryResult.topTwelveValues) {
      return false;
    }

    // The step's index can not be less than one or greater than six. These are out of the boundaries
    if (this.selectedStepIndex < 1 || this.selectedStepIndex > 6) {
      throw new Error(
        'Invalid call to dataModelIsValidToAdvanceStep() unknown step: ' +
        this.selectedStepIndex
      );
    }

    // If the selectedStepIndex is one, we return immediately because this is the module intro pages
    if (this.selectedStepIndex === 1) {
      return true;
    }

    // Return with the current selectedStepIndex if it has been completed
    return this.steps[this.selectedStepIndex - 2].stepHasBeenCompleted();
  }

  /**
   * completeAssessment()
   *
   * function
   *
   * Creates a save request to save the personalValuesInventoryResult object. This option appears on the
   *  final screen of the assessment and is only valid when all the other steps have been completed.
   */
  completeAssessment(): void {
    this.isPVICompleted = true;
    // this.deregisterRouteChangeListener.unsubscribe();

    if (!this.canAdvanceToNextStep()) {
      return;
    }

    this.personalValuesInventoryResultSvc
      .save('', this.personalValuesInventoryResult)
      .pipe(
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((result: any) => {
        this.successService.handle({
          message: 'Successfully saved the assessment result.',
        });

        // If the user has finished the onboarding, move them to their PVI result
        // Otherwise redirect them back to the onboarding
        if (
          this.user.onboardingStep.identifier === 'done' ||
          this.user.roles.includes('coach') ||
          this.user.roles.includes('groupadmin') ||
          this.user.roles.includes('admin')
        ) {
          this.router.navigate([
            '/users',
            this.routeParams['user_id'],
            'pvi',
            result._id,
          ]);
        } else {
          this.router.navigate(['/onboarding']);
        }
      });
  }

  /**
   * headHome()
   *
   * function
   *
   * Returns a promise creating a modal that determines whether or not we want to redirect the user back.
   *  This is important because we do not save the data the user has inputted during the process.
   */
  headHome(): void {
    this.router.navigate(['/']);
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderViewPviAssessmentComponent',
//   downgradeComponent({
//     component: NexleaderViewPviAssessmentComponent,
//   }) as angular.IDirectiveFactory
// );
