<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div
  [ngClass]="{ 'color-line-danger': !hasEnoughLicenses }"
  class="color-line"
></div>
<div class="modal-header">
  <h3 class="modal-title">Add Independent Coach</h3>
</div>
<div class="modal-body" *ngIf="hasEnoughLicenses">
  <div class="row">
    <div class="col-md-12 m-b-md">
      <p>
        Adding an independent coach sends an email to the coach and provides the
        coach with access to the group.
      </p>
    </div>
    <div class="form-group col-sm-6">
      <label class="control-label">Search by First Name</label>
      <input
        type="text"
        placeholder="John"
        class="form-control"
        [(ngModel)]="search.firstName"
      />
    </div>
    <div class="form-group col-sm-6">
      <label class="control-label">Search by Last Name</label>
      <input
        type="text"
        placeholder="Smith"
        class="form-control"
        [(ngModel)]="search.lastName"
      />
    </div>
  </div>
  <p *ngIf="filterCoachUsers().length > maxCoachUserSelect">
    There are more than {{ maxCoachUserSelect }} results matching your criteria.
    Try narrowing it down by filtering by name.
  </p>
  <div
    class="form-group"
    *ngIf="
      filterCoachUsers().length > 1 &&
      filterCoachUsers().length <= maxCoachUserSelect
    "
  >
    <label class="control-label">Coach</label>
    <select class="form-control" [(ngModel)]="selectedCoachUser">
      <option [ngValue]="null">-- Select a coach --</option>
      <option
        *ngFor="let coach of filterCoachUsers(); trackBy: trackById"
        [ngValue]="coach"
      >
        {{ coach.firstName + " " + coach.lastName }}
      </option>
    </select>
  </div>
  <p *ngIf="filterCoachUsers().length === 1">
    <b>Selected Coach (Single Matching Result):</b>
    {{ filterCoachUsers()[0].firstName }}
    {{ filterCoachUsers()[0].lastName }}
  </p>
  <p *ngIf="filterCoachUsers().length < 1">
    No consolidated coach users found matching search criteria.
  </p>
</div>
<div class="modal-footer" *ngIf="hasEnoughLicenses">
  <button
    class="btn btn-success"
    type="button"
    (click)="addCoachUser(selectedCoachUser)"
    [attr.disabled]="submitDisabled() ? true : null"
  >
    Add Coach
  </button>
</div>
<div class="modal-body" *ngIf="!hasEnoughLicenses">
  <p class="text-center">
    <i class="fa fa-5x fa-shopping-cart text-danger"></i>
    <br />
    <br />
    You've run out of licenses! Contact our support team to purchase more.
    <br /><br />
    <a
      href="mailto:angeline.patrick@nexleader.com?subject=Interested in purchasing more licenses&body=I'm interested in purchasing more licenses, please contact me at:"
      >angeline.patrick&#64;nexleader.com</a
    >
  </p>
</div>
