<!-- Copyright (C) nexleader - All Rights Reserved -->
<!-- Unauthorized copying of this file, via any medium is strictly prohibited -->
<!-- Proprietary and confidential -->
<!-- Written for nexleader <myipsat.com>, 2016-2018 -->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-heading">
            <h3>Coach Evaluation Result</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="hpanel">
          <div class="panel-body">
            <h4>
              {{ evaluationResult?.coach?.firstName }}
              {{ evaluationResult?.coach?.lastName }}
            </h4>
            <small>Coach name</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="hpanel">
          <div class="panel-body">
            <h4>
              {{ evaluationResult?.user?.firstName }}
              {{ evaluationResult?.user?.lastName }}
            </h4>
            <small>Participant name</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-heading">Result Answers</div>
          <div class="panel-body">
            <ng-container *ngFor="let question of evaluationResult?.questions">
              <div class="col-md-6 m-b-md">
                <b>{{ question.name }}</b>
                <br />{{ question.answer?.name }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
