/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderInviteCard
 *
 * component
 *
 * displays a card representing a single invite
 */
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { SuccessService } from './../../../../services/success.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteService } from '../../../../services/invite.service';
import { catchError, of, tap } from 'rxjs';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-invite-card',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './invite-card.component.html',
})
export class NexleaderInviteCardComponent {
  /**
   * invite
   *
   * data object
   *
   * the data object representing the invite
   */
  @Input() invite: any;

  /**
   * onChange
   *
   * expression binding: event handler
   *
   * called after saving a change to the  user
   */
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private inviteService: InviteService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  deleteInvite() {
    this.inviteService
      .delete(this.invite._id)
      .pipe(
        tap(() => {
          this.successService.handle();
          this.onChange.emit();
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe();
  }

  resendInvite() {
    this.inviteService
      .resend(this.invite._id)
      .pipe(
        tap(() => {
          this.successService.handle();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
