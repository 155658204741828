/*global angular*/
import { SuccessService } from './../../../../services/success.service';
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderProfileWizardComponent } from '../../components/profile-wizard/profile-wizard.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-prereqs',
  standalone: true,
  imports: [CommonModule, NexleaderProfileWizardComponent],
  templateUrl: './prereqs.component.html',
})
export class NexleaderPrereqsComponent implements OnInit {
  routeParams: any;
  user: any;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    this.userService
      .getUser(this.routeParams['user_id'])
      .pipe(
        tap((user) => {
          this.user = user;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  save(): void {
    this.userService
      .saveUser(this.user)
      .pipe(
        tap((_) => {
          this.successService.handle();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderPrereqsComponent',
//   downgradeComponent({
//     component: NexleaderPrereqsComponent,
//   }) as angular.IDirectiveFactory
// );
