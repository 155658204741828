<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="hpanel">
      <div class="panel-body">
        <p *ngIf="!smallGroupSessions">
          No small group sessions have been scheduled.
        </p>
        <app-nexleader-calendar
          *ngIf="smallGroupSessions"
          [events]="smallGroupSessions"
          (onCurrentEventChange)="currentEvent = $event"
        >
          <ng-container *ngIf="currentEvent">
            <p class="m-b-sm">
              <b>Start:</b> {{ currentEvent.start | date : "medium" }}
            </p>
            <p class="m-b-sm">
              <b>End:</b> {{ currentEvent.end | date : "medium" }}
            </p>
            <p class="m-b-sm"><b>Location:</b> {{ currentEvent.location }}</p>
            <p>
              <b>Small Group Leader Phone:</b>
              {{ currentEvent.smallGroupLeaderPhone }}
            </p>
          </ng-container>
        </app-nexleader-calendar>
      </div>
    </div>
  </div>
</div>
