/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SurveyResultService } from '../survey-result/survey-result.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { QuestionCategoryPipe } from '../../../../pipes/question-category.pipe';

@Component({
  selector: 'app-nexleader-shareable-survey-result',
  standalone: true,
  imports: [CommonModule, QuestionCategoryPipe, RouterLink],
  templateUrl: './shareable-survey-result.component.html'
})
export class NexleaderShareableSurveyResultComponent implements OnInit {
  routeParams: any;
  surveyResult: any;
  user: any;
  coach: any;
  surveyTypeIdentifier: any;
  categories: any;
  summaryOverviewQuestionCategory: any;

  constructor(
    private route: ActivatedRoute,
    private surveyResultService: SurveyResultService,
    private enumsService: EnumsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;

    forkJoin([
      this.surveyResultService.getShareableVersion(this.routeParams['survey_result_id']),
      this.enumsService.getEnums()
    ]).pipe(
      tap(([surveyResultResponse, enumsResponse]) => {
        this.surveyResult = surveyResultResponse.surveyResult;
        this.user = surveyResultResponse.user;
        this.coach = surveyResultResponse.coach;
        this.surveyTypeIdentifier = surveyResultResponse.user.group.surveyType.identifier;

        this.categories = enumsResponse.AllQuestionCategories;
        this.summaryOverviewQuestionCategory = enumsResponse.QuestionCategories.SUMMARY_OVERVIEW;
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderShareableSurveyResultComponent',
//   downgradeComponent({
//     component: NexleaderShareableSurveyResultComponent,
//   }) as angular.IDirectiveFactory
// );
