<!-- Copyright (C) nexleader - All Rights Reserved -->
<!-- Unauthorized copying of this file, via any medium is strictly prohibited -->
<!-- Proprietary and confidential -->
<!-- Written for nexleader <myipsat.com>, 2016-2018 -->

<div id="nexleader-wrapper">
  <div class="content">
    <app-nexleader-quick-view
      [model]="users"
      [tableHeaders]="['Name', 'Email', 'Group']"
      [tableColumns]="['_name', 'email', '_groupName']"
      [label]="'Users'"
      [overrideDisplayNewElement]="true"
      (onElementSelect)="selectedUser = $event"
    >
      <div *ngIf="selectedUser?._id; else notSelected">
        <app-nexleader-user-card
          [userId]="selectedUser._id"
          [currentViewingRole]="'admin'"
        ></app-nexleader-user-card>
      </div>
      <ng-template #notSelected>
        <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
      </ng-template>
    </app-nexleader-quick-view>
  </div>
</div>
