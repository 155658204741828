/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-take-skills-inventory',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './take-skills-inventory.component.html',
})
export class NexleaderTakeSkillsInventoryComponent implements OnInit {
  routeParams: any;
  userId!: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.userId = this.routeParams['user_id'];

    if (!this.userId) {
      console.error(
        'user_id is required for NexleaderTakeSkillsInventoryComponent',
        this.userId
      );
    }
  }

  goBack(): void {
    this.router.navigate(['/']);
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderTakeSkillsInventoryComponent',
//   downgradeComponent({
//     component: NexleaderTakeSkillsInventoryComponent,
//   }) as angular.IDirectiveFactory
// );
