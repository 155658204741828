<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">About the Spiritual Gifts Venn Diagram</h3>
</div>
<div class="modal-body">
  <p>
    One way to categorize the corporate functions of spiritual gifts in a
    community of believers is to recognize three clusters: power gifts, love
    gifts, and word gifts. Some spiritual gifts function in only one cluster,
    while others can function in more than one, such as both power and love, or
    power and word.
    <br />
    <br />
    Power gifts provide authenticity and credibility. They confirm the reality
    of the unseen God.
    <br />
    <br />
    Love gifts demonstrate the beauty of God's work in our lives and the
    practical expression of our relationship with him. They help the world see
    and experience God's love in action.
    <br />
    <br />
    Word gifts help people know God's character and purposes, what he expects
    from us, and how we can join in his work. Word-gifted leaders communicate
    about and for God.
    <br />
    <br />
    All the spiritual gifts are important. Some are called for more directly,
    based on what is needed in the moment. When functioning together in a body
    of believers, all three gift clusters provide a well-rounded testimony about
    God and his work in our lives.
    <br />
    <br />The corporate functions of spiritual gifts (Word, Love, Power) was
    developed by Dr. J. Robert Clinton, in his book,
    <i>Unlocking Your Giftedness</i>, co-authored with Dr. Richard Clinton.
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
