/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentChartPviBarValues
 *
 * component
 *
 * This component is responsible for handling data input through a binding and then rendering this data in the form of a
 *  chart.
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  PVI_BAR_CHART_COLORS,
  PVI_BAR_CHART_COLORS_TRANSPARENT,
} from '../../../../../../constants';
import { NexleaderComponentBarChartComponent } from '../../../core/components/bar-chart/bar-chart.component';

@Component({
  selector: 'app-nexleader-component-chart-pvi-bar-values',
  standalone: true,
  imports: [CommonModule, NexleaderComponentBarChartComponent],
  templateUrl: './chart-pvi-bar-values.component.html',
})
export class NexleaderComponentChartPviBarValuesComponent implements OnInit {
  /**
   * model
   *
   * The data must be in the correct form for the chart to render properly
   */
  @Input() model: any[] = []; // Assuming the data type for 'model' is an array

  /**
   * chartConfig
   *
   * chart.js config object
   *
   * This defines how we want to configure our bar values for our chart.
   *
   * @type {{maintainAspectRatio: boolean, responsive: boolean, scales: {yAxes: Array, xAxes: *[]}, legend: {display: boolean}}}
   */
  chartConfig: any;
  chartDomains: any[] = [];

  constructor() {}

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit(): void {
    this.setupChartConfig();
    this.setupChartDomains();
  }

  private setupChartConfig(): void {
    this.chartConfig = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          id: 'top_1',
          position: 'top',
          min: 0,
          max: 10,
          ticks: {
            callback: (value: number) => {
              switch (value) {
                case 0:
                  return 'sometimes/should';
                case 5:
                  return 'mostly/ought';
                case 10:
                  return 'always/must';
                default:
                  return '';
              }
            },
            stepSize: 1,
            maxRotation: 0,
            autoSkip: false,
          },
          gridLines: {
            drawOnChartArea: false,
          },
          // display: true,
          // scaleLabel: {
          //   display: true,
          // },
        },
        x2: {
          id: 'bottom_2',
          min: 0,
          max: 10,
          ticks: {
            stepSize: 1,
            maxRotation: 0,
            autoSkip: false,
          },
          gridLines: {
            display: true,
          },
          // display: true,
          // scaleLabel: {
          //   display: true,
          // },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }

  private setupChartDomains(): void {
    if (!Array.isArray(this.model)) {
      throw new Error(
        'Array model is required for nexleaderComponentChartPviBarValues (see component docs; maybe add an ng-if if it has to load)'
      );
    }

    const labels = ['Self', 'Others', 'Vocation', 'Avocation'];

    // Create an array we can enumerate over when rendering the charts
    this.chartDomains = labels.map((label, index) => {
      // Map the domain data to only those that are included within this domain
      const domainData = this.model.filter(
        (data) => data.arrayOfDomains.indexOf(label.toLowerCase()) > -1
      );

      // Labels for the chart
      const chartLabels = domainData.map((data) => data.name);

      // Data for the chart
      const chartData = domainData.map((data) => data.rating);

      return {
        name: label,
        labels: chartLabels,
        datasets: [
          {
            label: label,
            data: chartData,
            backgroundColor: PVI_BAR_CHART_COLORS_TRANSPARENT[index],
            borderColor: PVI_BAR_CHART_COLORS[index],
            borderWidth: 1,
          },
        ],
      };
    });
  }
}
