/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { CoachUserService } from '../../../../services/coach-user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import { NexleaderWrapperPanelComponent } from '../../../core/components/wrapper-panel/wrapper-panel.component';

@Component({
  selector: 'app-nexleader-multigroup-coach-list',
  standalone: true,
  imports: [
    CommonModule,
    FilterPipe,
    FormsModule,
    NgModelDebounceChangeDirective,
    NexleaderWrapperPanelComponent
  ],
  templateUrl: './multigroup-coach-list.component.html',
})
export class NexleaderMultigroupCoachListComponent implements OnInit {
  coachUsers: any[] = [];
  searchText: any;
  constructor(
    private coachUserService: CoachUserService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.loadCoachUsers();
  }

  loadCoachUsers(): void {
    this.coachUserService
      .getAllCoachUser()
      .pipe(
        tap((response) => {
          this.coachUsers = response;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderMultigroupCoachListComponent',
//   downgradeComponent({
//     component: NexleaderMultigroupCoachListComponent,
//   }) as angular.IDirectiveFactory
// );
