<div class="row" *ngIf="!licensingPartnerIsInvited()">
  <div class="col-sm-6">
    <div class="form-group">
      <label for="stripeCountryCode">ISO 3166-1 Country Code for Stripe</label>
      <input
        id="stripeCountryCode"
        class="form-control"
        type="text"
        placeholder="US"
        [(ngModel)]="invite.stripeCountryCode"
      />
    </div>
  </div>
  <div class="col-sm-6">
    <div class="form-group">
      <label for="stripeEmail">Stripe Admin Email</label>
      <input
        id="stripeEmail"
        class="form-control"
        type="text"
        placeholder="admin@licensingpartner.com"
        [(ngModel)]="invite.stripeEmail"
      />
    </div>
  </div>
  <div class="col-sm-12">
    <p class="m-b-md">
      Ensure that the currency represented by the
      <b>ISO 4217 Currency Code</b> is a supported presentment currency for
      Stripe in the country represented by
      <b>ISO 3166-1 Country Code for Stripe</b>.
    </p>

    <button
      class="btn btn-default"
      [attr.disabled]="loading ? true : null"
      (click)="sendStripeConnectAccountInvite()"
    >
      Invite to Stripe Connect
    </button>
  </div>
</div>
<div class="row" *ngIf="licensingPartnerIsInvited()">
  <div class="col-sm-12">
    <p>
      {{ licensingPartner?.name }} is registered with Stripe as
      <b>{{ licensingPartner?.stripe?.stripeConnectAccountId }}</b> with country
      code <b>{{ licensingPartner?.stripe?.stripeCountryCode }}</b
      >.
    </p>
  </div>
</div>
