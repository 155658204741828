<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div>
  <div class="content">
    <div class="row">
      <div class="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3">
        <div class="hpanel">
          <div class="panel-body">
            <div class="text-center">
              <i class="fa fa-lock fa-5x text-success"></i>
              <h3>Account Frozen</h3>
            </div>
            <br />
            <p>
              One of our priorities at nexleader is continuous improvement.
              That’s why we place such a high value on feedback and use the Net
              Promoter Score as our primary metric. Developing a skill requires
              deliberate practice over time. If you don’t use a skill regularly,
              it will affect your level of mastery.
            </p>
            <p>
              We noticed you have not been able to engage in an IPSAT coaching
              session for 90 days. It is possible you have done some coaching,
              but forgot to add the session to the IPSAT calendar. If so, please
              make sure that doesn’t become a habit.
            </p>
            <p>
              It is also possible life has thrown you a curve ball and you are
              giving priority to other important activities and relationships.
              We get that. Can you take a moment to email an update to
              <a href="mailto:Angeline.Patrick@nexleader.com"
                >Angeline.Patrick&#64;nexleader.com</a
              >
              to let us know what’s happening in your world?
            </p>
            <p>
              Thanks for being part of the IPSAT coaching team. We are committed
              to helping you thrive in this role, as you invest in the lives of
              others.
            </p>
            <p>
              To unlock your account, you must complete a practice training
              session with one of our certified trainers. After the session, our
              administrators will unlock your account, and you will be able to
              login again.
            </p>
            <div class="text-center">
              <a (click)="authService.logout()">Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
