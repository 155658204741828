/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
/* global angular */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupService } from '../../../../services/group.service';
import {
  BlobResourceService,
  BlobService,
} from '../../../../services/blob.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { NexleaderVimeoVideoComponent } from '../../../small-groups/components/vimeo-video/vimeo-video.component';
import { NexleaderYoutubeVideoComponent } from '../../../core/components/youtube-video/youtube-video.component';
import { NexleaderWistiaVideoComponent } from '../../../core/components/wistia-video/wistia-video.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-nexleader-custom-onboarding-card',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderVimeoVideoComponent,
    NexleaderYoutubeVideoComponent,
    NexleaderWistiaVideoComponent,
  ],
  templateUrl: './custom-onboarding-card.component.html',
})
export class NexleaderCustomOnboardingCardComponent implements OnInit {
  /**
   * groupId
   *
   * string
   *
   * A group ID to use to fetch any custom onboarding for the group.
   */
  private _groupId: string = '';
  @Input() set groupId(value: string) {
    if (value) {
      this._groupId = value;
      this.load();
    }
  }

  get groupId() {
    return this._groupId;
  }
  /**
   * onboardingExperience
   *
   * object
   *
   * The onboarding experience for the user.
   */
  @Input() onboardingExperience: any;
  customOnboarding: any = null;
  onboardingPhoto: any;

  constructor(
    private groupService: GroupService,
    private blobService: BlobResourceService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void { }

  private load(): void {
    // Once we have the user, query for any additional onboarding details to display
    // When we query for onboarding photos for the group, we have to ensure that we get the first result
    combineLatest([
      this.groupService.queryCustomOnboarding(this.groupId),
      this.blobService.queryOnboardingPhoto(this.groupId),
    ])
      .pipe(
        tap(([customOnboarding, onboardingPhoto]) => {
          this.customOnboarding = customOnboarding;
          if (onboardingPhoto && onboardingPhoto.length > 0) {
            this.onboardingPhoto = onboardingPhoto[0];
          }
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
