/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderEcourseRaliVideoComponent } from '../../../../ecourse/components/ecourse-rali-video/ecourse-rali-video.component';
import { NexleaderOnboardingTakeIpsatComponent } from '../../onboarding-take-ipsat/onboarding-take-ipsat.component';
import { tap, catchError, of } from 'rxjs';
import { UserService } from '../../../../../services/user.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SuccessService } from '../../../../../services/success.service';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-ecourse-module-seven',
  standalone: true,
  imports: [CommonModule, NexleaderEcourseRaliVideoComponent, NexleaderOnboardingTakeIpsatComponent],
  templateUrl: './ecourse-module-seven.component.html'
})
export class NexleaderEcourseModuleSevenComponent implements OnInit {
  /**
   * handleNextStep
   *
   * Allows data that is edited on this ecourse module to be saved.
   */
  @Output() handleNextStep = new EventEmitter();

  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process.
   */
  @Input() user: any;
  routeParams: any;

  /**
   * moduleSteps
   * 
   * Controls the steps the user can move between for the ecourse module.
   */
  moduleSteps: string[] = ['engagement', 'assessment'];

  /**
   * currentStep
   * 
   * Determines the current step the user is on in the ecourse module.
   */
  currentStep: string = this.moduleSteps[0];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    private successHandler: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
  }

  /**
   * nextStep()
   * 
   * Handles moving the user to the next step (WITHIN THE MODULE).
   *  Calls the handleNextStep() function if the user is on the last step.
   *  ie: moving from learning_assessment to engagement
   */
  nextStep(): void {
    const currentStepIndex = this.moduleSteps.indexOf(this.currentStep);
    if (currentStepIndex < this.moduleSteps.length - 1) {
      this.currentStep = this.moduleSteps[currentStepIndex + 1];
    } else {
      this.save();
      this.handleNextStep.emit();
    }
  }

  /**
   * mapContinueButtonTextForStep()
   * 
   * Maps text for the continue buttons given the current step of the module the user
   *  is on.
   */
  mapContinueButtonTextForStep(): string | null {
    switch (this.currentStep) {
      case 'engagement':
        return 'Continue to IPSAT';
      default:
        return null;
    }
  }

  /**
   * save()
   * 
   * Saves a user. To be called when an MBTI option is selected.
   */
  save(): void {
    this.userService
      .saveUser(this.user)
      .pipe(
        tap((_) => {
          this.successHandler.handle();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
