<div class="row">
  <div class="col-md-3">
    <h5 class="text-primary-2 m-b-xs">ECourse Rali Integration</h5>
    <p>
      <span>
        The ECourse integrates with the third-party Rali service to deliver
        videos and conversations. Each video in the ECourse must be mapped to a
        Rali video id in order to work. Each Rali video denotes a unique video
        with a unique conversation. To isolate a group's conversations, create
        new conversations for each module video in Rali and update the ids here.
      </span>
    </p>
  </div>
  <div class="col-md-6">
    <div class="form-group m-b-sm">
      <label class="control-label">Module 1 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleOneRaliId"
      />
    </div>
    <div class="form-group m-b-sm">
      <label class="control-label">Module 2 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleTwoRaliId"
      />
    </div>
    <div class="form-group m-b-sm">
      <label class="control-label">Module 3 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleThreeRaliId"
      />
    </div>
    <div class="form-group m-b-sm">
      <label class="control-label">Module 4 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleFourRaliId"
      />
    </div>
    <div class="form-group m-b-sm">
      <label class="control-label">Module 5 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleFiveRaliId"
      />
    </div>
    <div class="form-group m-b-sm">
      <label class="control-label">Module 6 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleSixRaliId"
      />
    </div>
    <div class="form-group m-b-sm">
      <label class="control-label">Module 7 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleSevenRaliId"
      />
    </div>
    <div class="form-group m-b-sm">
      <label class="control-label">Module 8 Rali Video ID</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="group.ecourse.moduleEightRaliId"
      />
    </div>
  </div>
</div>
<div class="row" *ngIf="group.isDirectPurchase">
  <div class="col-md-3">
    <h5 class="text-primary-2 m-b-xs">Default Direct Purchase Group</h5>
    <p>
      <span
        >This group is automatically assigned to
        {{ group.surveyType.name }} users who do not have an assigned group at
        signup. Users who sign up using these group-specific links will
        automatically be assigned coaches based on the table below.</span
      >
    </p>
  </div>
  <div class="col-md-9">
    <div>
      <span class="label label-default">Direct Purchase</span>
      <p class="m-t-sm">
        Users who do not know which group they'd like to sign up for but do know
        if they're looking for a
        <b>{{ group.surveyType.name }}</b> experience can signup using the
        following links:
      </p>
      <table class="table table-bordered m-t-sm">
        <tbody>
          <tr>
            <td>Standard {{ group.surveyType.name }} IPSAT (no referral)</td>
            <td>
              <a
                [href]="
                  getSignupLink({
                    surveyTypeIdentifier: group.surveyType.identifier,
                    onboardingExperienceIdentifier: 'standard'
                  })
                "
              >
                {{
                  getSignupLink({
                    surveyTypeIdentifier: group.surveyType.identifier,
                    onboardingExperienceIdentifier: "standard"
                  })
                }}
              </a>
            </td>
          </tr>
          <tr *ngIf="group.surveyType.identifier === 'values_based'">
            <td>ECourse {{ group.surveyType.name }} IPSAT (no referral)</td>
            <td>
              <a
                [href]="
                  getSignupLink({
                    surveyTypeIdentifier: group.surveyType.identifier,
                    onboardingExperienceIdentifier: 'ecourse'
                  })
                "
              >
                {{
                  getSignupLink({
                    surveyTypeIdentifier: group.surveyType.identifier,
                    onboardingExperienceIdentifier: "ecourse"
                  })
                }}
              </a>
            </td>
          </tr>
          <tr *ngIf="group.surveyType.identifier === 'values_based'">
            <td>
              ECourse {{ group.surveyType.name }} IPSAT Landing Page (no
              referral)
            </td>
            <td>
              <a [href]="getEcourseMarketingLink({})">
                {{ getEcourseMarketingLink({}) }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="row" *ngIf="!group.isDirectPurchase">
  <div class="col-md-3">
    <h5 class="text-primary-2 m-b-xs">Direct Purchase Referral</h5>
    <p>
      <span
        >Use these special signup links for users to end up in this group when
        they sign up. Users who sign up using these group-specific links will
        automatically be assigned coaches based on the table below.</span
      >
    </p>
  </div>
  <div class="col-md-9">
    <div>
      <p class="m-t-sm">
        Users who know they'd like to sign up for <b>{{ group.name }}</b> can
        signup using the following links:
      </p>
      <table class="table table-bordered m-t-sm">
        <tbody>
          <tr>
            <td>Standard {{ group.surveyType.name }} IPSAT (Group Referral)</td>
            <td>
              <a
                [href]="
                  getSignupLink({
                    groupId: group._id,
                    onboardingExperienceIdentifier: 'standard'
                  })
                "
              >
                {{
                  getSignupLink({
                    groupId: group._id,
                    onboardingExperienceIdentifier: "standard"
                  })
                }}
              </a>
            </td>
          </tr>
          <tr *ngIf="group.surveyType.identifier === 'values_based'">
            <td>ECourse {{ group.surveyType.name }} IPSAT (Group Referral)</td>
            <td>
              <a
                [href]="
                  getSignupLink({
                    groupId: group._id,
                    onboardingExperienceIdentifier: 'ecourse'
                  })
                "
              >
                {{
                  getSignupLink({
                    groupId: group._id,
                    onboardingExperienceIdentifier: "ecourse"
                  })
                }}
              </a>
            </td>
          </tr>
          <tr *ngIf="group.surveyType.identifier === 'values_based'">
            <td>
              ECourse {{ group.surveyType.name }} IPSAT Landing Page (Group
              Referral)
            </td>
            <td>
              <a [href]="getEcourseMarketingLink({ groupId: group._id })">
                {{ getEcourseMarketingLink({ groupId: group._id }) }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <h5 class="text-primary-2 m-b-xs">Coach Assignment</h5>
    <p>
      <span
        >When users sign up for this group, they either use a coach-specific
        link or are randomly assigned a coach.</span
      >
    </p>
  </div>
  <div class="col-md-9">
    <table class="table table-bordered coach-referal-table">
      <thead>
        <th>Coach Name</th>
        <th>Automatic Assignment Enabled?</th>
        <th>Coach Referral Enabled?</th>
      </thead>
      <tbody>
        <tr *ngFor="let coach of coaches">
          <td>{{ coach.firstName }} {{ coach.lastName }}</td>
          <td>
            <span *ngIf="coach.enableAutomaticDirectPurchaseAssignment">
              Enabled (<a (click)="setCoachAssignment(coach, false)">Disable</a
              >)
            </span>
            <span *ngIf="!coach.enableAutomaticDirectPurchaseAssignment">
              Disabled (<a (click)="setCoachAssignment(coach, true)">Enable</a>)
            </span>
          </td>
          <td>
            <span *ngIf="coach.enableDirectPurchaseReferralForThisCoach">
              Enabled (<a (click)="setCoachDirectPurchaseReferral(coach, false)"
                >Disable</a
              >) <br /><br />
              Standard IPSAT Signup (Coach Referral):<br />
              <a
                [href]="
                  getSignupLink({
                    coachId: coach._id,
                    onboardingExperienceIdentifier: 'standard'
                  })
                "
              >
                {{
                  getSignupLink({
                    coachId: coach._id,
                    onboardingExperienceIdentifier: "standard"
                  })
                }}
              </a>
              <div *ngIf="group.surveyType.identifier === 'values_based'">
                <br />
                ECourse IPSAT Signup (Coach Referral):<br />
                <a
                  [href]="
                    getSignupLink({
                      coachId: coach._id,
                      onboardingExperienceIdentifier: 'ecourse'
                    })
                  "
                >
                  {{
                    getSignupLink({
                      coachId: coach._id,
                      onboardingExperienceIdentifier: "ecourse"
                    })
                  }} </a
                ><br /><br />
                ECourse IPSAT Landing Page (Coach Referral):<br />
                <a [href]="getEcourseMarketingLink({ coachId: coach._id })">
                  {{
                    getEcourseMarketingLink({
                      coachId: coach._id
                    })
                  }}
                </a>
              </div>
            </span>
            <span *ngIf="!coach.enableDirectPurchaseReferralForThisCoach">
              Disabled (<a (click)="setCoachDirectPurchaseReferral(coach, true)"
                >Enable</a
              >)
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
