/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewLicensingPartnerDirectDiscounts
 *
 * VIEW
 *
 * Displays a table of all existing DirectDiscounts (See DirectDiscount.model for detailed docs)
 *  Essentially, these are discounts that apply to Direct-to-Consumer purchases of the IPSAT (or IPSAT ECourse)
 *
 * This view is unique to each licensing partner because direct discounts live in each licensing partner
 *  because they must be unique by currency code because there is a flat fee option.
 *
 * Provides the ability to create and delete direct discounts. Creation is provided through a link
 *  that opens the NewDirectDiscountModal.
 *
 * Update ability is not available; these objects are small enough to make the end user (Steve/Angeline) delete
 *  old ones and create new ones.
 */

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, of, take, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { DirectDiscountService } from '../../resources/direct-discount.service';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderNewDirectDiscountModalComponent } from '../../components/modals/new-direct-discount-modal/new-direct-discount-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-direct-discounts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './direct-discounts.component.html',
})
export class NexleaderDirectDiscountsComponent implements OnInit {
  routeParams: any;
  discounts: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private directDiscountService: DirectDiscountService,
    private modalService: BsModalService,
    private successService: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  /**
   * load()
   *
   * load function for the controller
   *
   * Loads the $ctrl.discountDiscounts array that we display in the table view
   */
  load(): void {
    this.directDiscountService
      .queryLicensingPartner(this.routeParams['licensing_partner_id'])
      .pipe(
        tap((data) => {
          this.discounts = data;
          this.discounts.forEach((discount) => {
            if (!discount.discountType) {
              console.warn(
                'malformed directDiscount in DirectDiscounts component; no discountType',
                discount
              );
              return;
            }
            switch (discount.discountType.identifier) {
              case 'flat_fee':
              case 'flat_discount':
                discount._discountAmountString = `$${(
                  discount.discountAmountBps / 10000
                ).toFixed(2)}`;
                break;
              case 'percentage':
                discount._discountAmountString =
                  (discount.discountAmountBps / 100).toFixed(2) + '%';
                break;
              default:
                discount._discountAmountString = 'UNKNOWN TYPE';
                console.warn(
                  'DiscountDiscounts: unknown directDiscount.discountType.identifier; add a line to the switch statement to render it'
                );
            }
          });
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * openCreateDiscount()
   *
   * Presents a modal to the user that allows him/her to create a new team report discount object.
   *  Successfully creating a Discount discount results in a call to innerScope.close() that reloads
   *  this view so that we can see the new object in the table.
   */
  openCreateDiscount(): void {
    const modalRef = this.modalService.show(
      NexleaderNewDirectDiscountModalComponent,
      {
        initialState: {
          licensingPartnerId: this.routeParams['licensing_partner_id'],
        },
      }
    );
    modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.load();
    });
  }

  /**
   * remove()
   *
   * Sends a DELETE request for a given direct discount object (using the _id property)
   *
   * @param discount
   */
  remove(discount: any): void {
    this.directDiscountService
      .remove(discount._id)
      .pipe(
        tap((_) => {
          this.successService.handle({
            message: 'Successfully deleted direct purchase discount code.',
          });
          this.load();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderDirectDiscountsComponent',
//   downgradeComponent({
//     component: NexleaderDirectDiscountsComponent,
//   }) as angular.IDirectiveFactory
// );
