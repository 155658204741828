<div class="form-group">
  <label class="control-label">Group</label>
  <select
    class="form-control"
    [(ngModel)]="model"
    (ngModelChange)="onChange()"
    name="model"
  >
    <option value="undefined">-- Select Group --</option>
    <option
      *ngFor="let option of options; trackBy: trackByGroupId"
      [value]="option._id"
    >
      {{ option.name }}
    </option>
  </select>
</div>
