/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderWrapperPanelComponent } from '../../../core/components/wrapper-panel/wrapper-panel.component';
import { NexleaderSessionsComponent } from '../sessions/sessions.component';

@Component({
  selector: 'app-nexleader-admin-calendar',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderWrapperPanelComponent,
    NexleaderSessionsComponent,
  ],
  templateUrl: './admin-calendar.component.html',
})
export class NexleaderAdminCalendarComponent { }

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderAdminCalendarComponent',
//   downgradeComponent({
//     component: NexleaderAdminCalendarComponent,
//   }) as angular.IDirectiveFactory
// );
