<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-lg-12">
        <div class="hpanel">
          <div class="panel-body">
            <div class="m-b-md">Survey Type: {{ survey.surveyType.name }}</div>
            <div class="form-group">
              <label class="control-label">Name</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="survey.name"
              />
            </div>
            <div class="form-group">
              <label class="control-label">Description</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="survey.description"
              />
            </div>
            <br />
            <button
              class="btn btn-success"
              style="margin-right: 3px"
              (click)="saveSurvey()"
            >
              <i class="fa fa-floppy-o"></i>&nbsp;&nbsp;Save Survey
            </button>
            <button class="btn btn-danger" (click)="deleteSurvey()">
              <i class="fa fa-trash-o"></i>&nbsp;&nbsp;Delete Survey
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="hpanel panel-group">
          <div class="panel-body no-padding">
            <div class="list-group">
              <a
                class="list-group-item"
                *ngFor="
                  let category of enums['QuestionCategories']
                    | keyvalue : onCompare
                "
                (click)="changeCategory(category.value)"
                [class.active]="category.value.name == currentCategory.name"
              >
                <h5
                  *ngIf="category.value.name === 'Spiritual Gifts'"
                  class="list-group-item-heading"
                >
                  <span *ngIf="survey.surveyType.identifier !== 'values_based'"
                    >Spiritual Gifts</span
                  >
                  <span *ngIf="survey.surveyType.identifier === 'values_based'"
                    >Personal Values</span
                  >
                </h5>
                <h5
                  *ngIf="category.value.name !== 'Spiritual Gifts'"
                  class="list-group-item-heading"
                >
                  {{ category.value.name }}
                </h5>
                <p class="list-group-item-text">
                  Category {{ category.value.index + 1 }}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="hpanel">
          <div class="panel-body">
            <div class="pull-right text-muted m-l-lg">
              Category {{ currentCategory.index + 1 }}
            </div>
            <h3 *ngIf="currentCategory.name === 'Spiritual Gifts'">
              <span *ngIf="survey.surveyType.identifier !== 'values_based'"
                >Spiritual Gifts</span
              >
              <span *ngIf="survey.surveyType.identifier === 'values_based'"
                >Personal Values</span
              >
            </h3>
            <h3 *ngIf="currentCategory.name !== 'Spiritual Gifts'">
              {{ currentCategory.name }}
            </h3>
            <hr />
            <app-nexleader-edit-questions
              [questions]="survey.questions"
              [category]="currentCategory"
              [eval]="false"
            ></app-nexleader-edit-questions>
            <br />
            <h4>Summary Tool</h4>
            <br />
            <div
              *ngFor="
                let interpretation of survey.interpretation
                  | filter : 'questionCategory.name' : currentCategory.name
              "
            >
              <div class="form-group">
                <label class="control-label">Interpretation Disclaimer</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="interpretation.disclaimer"
                />
              </div>
              <app-nexleader-edit-ranges
                [ranges]="interpretation.ranges"
              ></app-nexleader-edit-ranges>
            </div>

            <br />
            <div class="btn-group">
              <button class="btn btn-success" (click)="saveSurvey()">
                <i class="fa fa-floppy-o"></i>&nbsp;&nbsp;Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</div>
