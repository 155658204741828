/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */ /* Copyright (C) nexleader - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written for nexleader <myipsat.com>, 2016-2018
*/

/*global angular*/
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-learn-about-custom-video-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './learn-about-custom-video-modal.component.html',
})
export class NexleaderLearnAboutCustomVideoModalComponent {
  constructor(private bsModalService: BsModalService) { }
  close() {
    this.bsModalService.hide();
  }
}
