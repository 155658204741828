<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">
    Identity Profile:
    <span *ngIf="questionCategory.prerequisite !== 'spiritualGifts'">{{
      questionCategory.name
    }}</span>
    <span *ngIf="questionCategory.prerequisite === 'spiritualGifts'">
      <span *ngIf="surveyType === 'values_based'">Personal Values</span>
      <span *ngIf="surveyType === 'faith_based'">Spiritual Gifts</span>
    </span>
  </h3>
</div>
<div class="modal-body">
  <app-nexleader-category-score
    [category]="questionCategory"
    [result]="result"
  ></app-nexleader-category-score>
  <br />
  <div
    class="row"
    *ngIf="result.prerequisites[questionCategory.prerequisite] as value"
  >
    <div class="col-xs-12">
      <small class="small-text">{{ questionCategory.prerequisiteName }}</small>
      <h4 *ngIf="value.name">{{ value.name }}</h4>
      <h4 *ngIf="value[0]">
        <span *ngFor="let v of value; let last = last">
          <span
            >{{ v.name }}<span *ngIf="v.level"> ({{ v.level.name }})</span
            ><span *ngIf="!last">, </span></span
          >
        </span>
      </h4>
    </div>
  </div>

  <div class="m-t-md">
    <p>{{ questionCategory.range.name }}</p>
    <p *ngFor="let question of questions">
      <ng-container *ngIf="question.answer.range">
        {{ question.answer.range.name }}
      </ng-container>
    </p>
    <hr />
    <small
      ><i>{{ questionCategory.disclaimer }}</i></small
    >
  </div>
</div>
