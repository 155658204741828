/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { catchError, of, tap } from 'rxjs';
import { NgModelDebounceChangeDirective } from '../../directives/ng-model-debounce.directive';
@Component({
  selector: 'app-nexleader-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgModelDebounceChangeDirective,
    RouterLink,
  ],
})
export class NexleaderLoginComponent implements OnInit {
  credentials: any = {};
  message: string | undefined;
  groups: any[] | undefined;
  requiresDisambiguation: boolean = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    if (this.errorHandler.error) {
      this.message = this.errorHandler.error.message;
    }

    if (this.auth.getTokenPayload()) {
      this.router.navigate(['/']);
    }
  }

  trackByGroupId(index: number, group: any): number {
    return group._id;
  }

  updateLogin(): void {
    this.auth
      .authDisambiguation(this.credentials)
      .pipe(
        tap((response) => {
          this.groups = response.disambiguation;
          this.requiresDisambiguation = response.requiresDisambiguation;
          delete this.credentials.group;
        }),
        catchError((error: any) => {
          // let it silently fail, no need to show error toast
          // this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  login(): void {
    this.auth
      .auth(this.credentials)
      .pipe(
        tap((_) => {
          this.router.navigate(['/']);
        }),
        catchError((error) => {
          error.error = JSON.parse(error.error);
          this.errorHandler.handleError(error, this.errorHandler.types.TOAST);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderLoginComponent',
//   downgradeComponent({
//     component: NexleaderLoginComponent,
//   }) as angular.IDirectiveFactory
// );
