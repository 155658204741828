/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SkippedVideoService } from '../../../../services/skipped-video.service';
import { SuccessService } from '../../../../services/success.service';
import { UserService } from '../../../../services/user.service';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { NexleaderHelpUtilityComponent } from '../../../core/components/help-utility/help-utility.component';
import { NexleaderOnboardingVideoComponent } from './onboarding-video/onboarding-video.component';
import { NexleaderOnboardingPersonalInfoComponent } from './onboarding-personal-info/onboarding-personal-info.component';
import { NexleaderOnboardingPreassessmentsComponent } from './onboarding-preassessments/onboarding-preassessments.component';
import { NexleaderOnboardingTakeIpsatComponent } from '../onboarding-take-ipsat/onboarding-take-ipsat.component';

@Component({
  selector: 'app-nexleader-onboarding',
  standalone: true,
  imports: [CommonModule, NexleaderHelpUtilityComponent, NexleaderOnboardingVideoComponent,
    NexleaderOnboardingPersonalInfoComponent, NexleaderOnboardingPreassessmentsComponent,
    NexleaderOnboardingTakeIpsatComponent
  ],
  templateUrl: './onboarding.component.html'
})
export class NexleaderOnboardingComponent implements OnInit {
  routeParams: any;
  ONBOARDING_STEPS: any;
  ONBOARDING_EXPERIENCES: any;
  user: any;
  step: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private enumsService: EnumsService,
    private skippedVideoService: SkippedVideoService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    forkJoin([this.enumsService.getEnums(),
    this.userService.getUser(this.routeParams['user_id'])
    ]).pipe(
      tap(([enums, user]) => {
        this.ONBOARDING_STEPS = enums.OnboardingSteps;
        this.ONBOARDING_EXPERIENCES = enums.OnboardingExperiences;
        this.user = user;
        // If the user is an ecourse user, push them to the proper ecourse onboarding
        if (this.user.onboardingExperience.identifier === this.ONBOARDING_EXPERIENCES.ECOURSE.identifier) {
          this.router.navigate(['/ecourse/onboarding']);
        }

        //If the user hasn't started onboarding yet, set their step to the first step and reload.
        if (this.user.onboardingStep.index === this.ONBOARDING_STEPS.NOT_STARTED.index) {
          this.user.onboardingStep = this.ONBOARDING_STEPS.PERSONAL_INFO;
          this.user.onboardingUpdated = Date.now();

          return this.save().subscribe(() => {
            this.step = this.user.onboardingStep;
          })
        }

        // If the user is not on the DONE step, and has not watched the video, redirect them to the video
        // We also need to be sure that they haven't already skipped the video
        if (!this.skippedVideoService.hasSkippedVideo &&
          !this.user.watchedVideo &&
          this.user.onboardingStep.identifier === this.ONBOARDING_STEPS.PREASSESSMENTS.identifier) {
          return this.router.navigate(['/users', this.user._id, 'video']);
        }
        this.step = this.user.onboardingStep;
        return of(null);
      }),
      catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  next(): void {
    this.load();
  }

  save(): Observable<any> {
    return this.userService.saveUser(this.user).pipe(
      tap((_) => {
        this.successService.handle()
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    )
  }

  setOnboardingStep(onboardingStep: any): void {
    this.user.onboardingStep = onboardingStep;
    this.save();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderOnboardingComponent',
//   downgradeComponent({
//     component: NexleaderOnboardingComponent,
//   }) as angular.IDirectiveFactory
// );
