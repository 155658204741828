<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div id="nexleader-wrapper">
  <div class="content">
    <app-nexleader-quick-view
      *ngIf="smallGroups"
      [model]="smallGroups"
      [tableHeaders]="['Name', 'Leader']"
      [tableColumns]="['name', '_smallGroupLeaderName']"
      [label]="'Small Groups for ' + group?.name"
      (overrideOnNewElement)="openNewSmallGroupModal()"
      (onElementSelect)="selectedGroup = $event"
    >
      <ng-container *ngIf="selectedGroup?._id; else notSelected">
        <app-nexleader-small-group-card
          [smallGroupId]="selectedGroup._id"
          (onChange)="load()"
        ></app-nexleader-small-group-card>
      </ng-container>

      <ng-template #notSelected>
        <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
      </ng-template>
    </app-nexleader-quick-view>
  </div>
</div>
