/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/**
 * ConfirmActionSvc
 *
 * angular service
 *
 * Functionality for confirming that an action is done.
 */

/* global angular */
import { Component, Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmActionService {
  constructor(private modalService: BsModalService) { }

  /**
   * handleWarning()
   *
   * function
   *
   * Handles a "warning" type action where a user might be doing something that they want to confirm that they
   *  *really* want to do.
   *
   *  @param {string} title - A title to be used inside of the modal.
   *  @param {string} description - A description to be used inside of the modal.
   */
  handleWarning(title: string, description: string): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      const modalRef = this.modalService.show(WarningModalComponent, {
        initialState: {
          title,
          description,
        },
      });

      modalRef.onHide?.subscribe(() => {
        observer.complete();
      });
    });
  }

  /**
   * handleAlert()
   *
   * function
   *
   * Handles an "alert" type action where a user needs to be alerted about an action that they have just made.
   *
   *  @param {string} title - A title to be used inside of the modal.
   *  @param {string} description - A description to be used inside of the modal.
   */
  handleAlert(title: string, description: string): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      const modalRef = this.modalService.show(AlertModalComponent, {
        initialState: {
          title,
          description,
        },
      });

      modalRef.onHide?.subscribe(() => {
        observer.complete();
      });
    });
  }
}

@Component({
  selector: 'app-warning-modal',
  template: `
    <div class="modal-header hmodal-warning">
      <h3 class="modal-title" id="nexleader-test-warning-modal">
        {{ title }}
      </h3>
    </div>
    <div class="modal-body">
      <p>{{ description }}</p>
    </div>
    <div class="modal-footer">
      <div class="pull-right">
        <button
          id="nexleader-test-warning-modal-cancel"
          class="btn btn-default"
          type="button"
          (click)="cancel()"
        >
          Cancel
        </button>
        <button class="btn btn-primary" type="button" (click)="confirm()">
          Confirm
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  `,
})
export class WarningModalComponent {
  title!: string;
  description!: string;

  constructor(public modal: BsModalRef) { }

  cancel() {
    this.modal.hide();
  }

  confirm() {
    this.modal.hide();
  }
}

@Component({
  selector: 'app-alert-modal',
  template: `
    <div class="modal-header hmodal-warning">
      <h3 class="modal-title" id="nexleader-test-alert-modal">
        {{ title }}
      </h3>
    </div>
    <div class="modal-body">
      <p id="nexleader-test-alert-modal-text">{{ description }}</p>
    </div>
    <div class="modal-footer">
      <div class="pull-right">
        <button
          class="btn btn-default"
          type="button"
          (click)="continue()"
          id="nexleader-test-alert-modal-continue"
        >
          Continue
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  `,
})
export class AlertModalComponent {
  title!: string;
  description!: string;

  constructor(public modal: BsModalRef) { }

  continue() {
    this.modal.hide();
  }
}
