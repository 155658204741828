<div>
  <div class="flex flex-vertical-center m-b-md">
    <h3 class="text-primary-2 font-semi-bold" id="nexleader-test-pvi-result-view">
      Your Personal Values: Summary Overview
    </h3>
    <div class="flex-grow"></div>
    <a class="btn btn-default" [routerLink]="['/users', userId, 'pvi', pviResultId, 'printable']">Print Result</a>
  </div>
  <div class="row m-b-lg equal-6">
    <div class="col-sm-6 col-xs-12 equal-child" *ngFor="let value of pviAssessmentResultScore?.topTwelveValues">
      <div class="hpanel m-b-md">
        <div class="panel-body">
          <div class="pull-right">
            <span class="label label-default" style="margin-right: 4px" *ngIf="value.isInTopSeven">Top Seven</span>
            <span class="label label-success" *ngIf="value.isInTopThree">#{{ value.topThreeIndex }}</span>
          </div>
          <h4 class="text-primary-2 font-semi-bold">{{ value.name }}</h4>
          <div class="flex flex-vertical-center flex-column-md m-t-md m-b-md">
            <p class="m-b-none">
              This value surfaced when I reflected through the life domain of:
            </p>
            <div class="flex-grow"></div>
            <div class="flex m-t-sm">
              <div class="m-l-sm" *ngFor="let domain of allPersonalValueDomains">
                <img *ngIf="value.arrayOfDomains.indexOf(domain.identifier) !== -1" [src]="
                    '/assets/images/pvi_icons/' +
                    domain.identifier +
                    '.svg'
                  " alt="pvi icon" class="img-md" />
                <img *ngIf="value.arrayOfDomains.indexOf(domain.identifier) === -1" [src]="
                    '/assets/images/pvi_icons/' +
                    domain.identifier +
                    '-gray.svg'
                  " alt="pvi icon" class="img-md" />
              </div>
            </div>
          </div>
          <p>
            <b>My Description:</b>
            {{ value.description || "No description provided." }}
          </p>
          <hr />
          <p class="m-b-lg">
            I have rated this value on the sometimes, mostly, always continuum
            as follows:
          </p>
          <div>
            <div class="progress full progress-small">
              <div [style.width]="100 * (value.rating / 10) + '%'" role="progressbar"
                class="progress-bar progress-bar-personality">
                <span class="sr-only">35% Complete (success)</span>
              </div>
            </div>
          </div>
          <div class="flex flex-space-between m-t-lg">
            <p class="font-semi-bold">
              sometimes<span class="hide-mobile-inline">/should</span>
            </p>
            <p class="font-semi-bold">
              mostly<span class="hide-mobile-inline">/ought</span>
            </p>
            <p class="font-semi-bold">
              always<span class="hide-mobile-inline">/must</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3 class="text-primary-2 font-semi-bold m-b-md">
    Your Personal Values: Life Domain Graphs
  </h3>
  <div class="m-b-lg">
    <div class="hpanel">
      <div class="panel-body">
        <h4 class="text-primary-2 font-semi-bold">Your Top Three Values</h4>
        <hr class="m-t-md m-b-md" />
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <h5 class="text-primary-2 m-b-sm">Bar Graph</h5>
            <app-nexleader-component-chart-pvi-bar-values *ngIf="pviAssessmentResultScore"
              [model]="pviAssessmentResultScore.topThreeValues"></app-nexleader-component-chart-pvi-bar-values>
          </div>
          <div class="col-sm-6 col-xs-12">
            <h5 class="text-primary-2 m-b-sm">Spider Graph</h5>
            <app-nexleader-component-chart-pvi-spider *ngIf="pviAssessmentResultScore" [title]="'Top Three'"
              [model]="topThreeValueDomainScore"></app-nexleader-component-chart-pvi-spider>
          </div>
        </div>
      </div>
    </div>
    <div class="hpanel">
      <div class="panel-body">
        <h4 class="text-primary-2 font-semi-bold">Your Top Seven Values</h4>
        <hr class="m-t-md m-b-md" />
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <h5 class="text-primary-2 m-b-sm">Bar Graph</h5>
            <app-nexleader-component-chart-pvi-bar-values *ngIf="pviAssessmentResultScore"
              [model]="pviAssessmentResultScore.topSevenValues"></app-nexleader-component-chart-pvi-bar-values>
          </div>
          <div class="col-sm-6 col-xs-12">
            <h5 class="text-primary-2 m-b-sm">Spider Graph</h5>
            <app-nexleader-component-chart-pvi-spider *ngIf="pviAssessmentResultScore" [title]="'Top Seven'"
              [model]="topSevenValueDomainScore"></app-nexleader-component-chart-pvi-spider>
          </div>
        </div>
      </div>
    </div>
    <div class="hpanel">
      <div class="panel-body">
        <h4 class="text-primary-2 font-semi-bold">Your Top Twelve Values</h4>
        <hr class="m-t-md m-b-md" />
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <h5 class="text-primary-2 m-b-sm">Bar Graph</h5>
            <app-nexleader-component-chart-pvi-bar-values *ngIf="pviAssessmentResultScore"
              [model]="pviAssessmentResultScore.topTwelveValues"></app-nexleader-component-chart-pvi-bar-values>
          </div>
          <div class="col-sm-6 col-xs-12">
            <h5 class="text-primary-2 m-b-sm">Spider Graph</h5>
            <app-nexleader-component-chart-pvi-spider *ngIf="pviAssessmentResultScore" [title]="'Top Twelve'"
              [model]="topTwelveValueDomainScore"></app-nexleader-component-chart-pvi-spider>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hpanel m-b-lg">
    <div class="panel-body">
      <h4 class="text-primary-2 font-bold">Living Your Values</h4>
      <hr class="m-t-md m-b-md" />
      <div class="row flex flex-vertical-center flex-column-md">
        <div class="col-sm-6 col-xs-12 m-b-md">
          <img src="/assets/images/pvi_value_tree.svg" class="img-full" alt="PVI Value Tree" />
        </div>
        <div class="col-sm-6 col-xs-12">
          <h4 class="h4">
            A person is said to be value-driven when they consciously attempt to
            identify their values and consistently operate based on those
            values.
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="hpanel m-b-lg">
    <div class="panel-body">
      <h4 class="text-primary-2 font-bold">
        Observations about the value tree:
      </h4>
      <hr class="m-t-md m-b-md" />
      <ol>
        <li class="m-b-sm">
          Younger people tend to first recognize the seeds of implicit values by
          observing other people and saying or thinking, “I wouldn’t do it that
          way.” This is generally true of everyone, but especially true of
          people with the aptitude for leadership. At this stage, implicit
          values are “reactive and hypothetical.”
        </li>
        <li class="m-b-sm">
          Eventually we tend to coalesce around a set of intuitive and
          functional values that help guide our decisions and actions. We often
          notice them most when we choose to violate a value in a situation.
          This tends to generate negative emotions and can produce inner
          conflict.
        </li>
        <li class="m-b-sm">
          When we engage with a tool like the Personal Values Inventory, we
          bring implicit values to the surface, and make them explicit. When you
          first identify your values, there can be a tentative and reluctant
          feeling as you operationalize them. This is common, like the feeling
          you have when you first put important goals on paper. It forces you to
          interact with them at a deeper level.
        </li>
        <li class="m-b-sm">
          The more explicit and instinctual your values, the more predictable
          your behavior will be for others. This is especially important for
          leaders.
        </li>
        <li class="m-b-sm">
          Only explicit, operational/instinctual values can be tested,
          evaluated, and proactively refined.
        </li>
        <li class="m-b-sm">
          Proactive and operational values are much more likely to be passed on
          to others and embedded into the fabric of a family or culture of an
          organization. If your values are important to you, and by definition
          they are, why would you want others to have to guess them?
        </li>
        <li>
          People who operate with implicit values rob themselves of the benefit
          of accountability when they act in ways that are inconsistent with
          their values and drift toward situational or hypocritical behavior.
        </li>
      </ol>
    </div>
  </div>
  <div class="hpanel">
    <div class="panel-body">
      <h4 class="text-primary-2 font-bold">Questions for Reflection</h4>
      <hr class="m-t-md m-b-md" />
      <ol>
        <li class="m-b-sm">
          Reflect on a time when you found yourself thinking, “If I were in
          charge, I wouldn’t do it that way.” In what life domain did this
          occur? Which of your values were being violated?
        </li>
        <li class="m-b-sm">
          If you are making your values explicit for the first time, do you feel
          tentative about your list? What can you do to evaluate, test and
          refine your values to grow your confidence? Who could you share your
          values with to help you get perspective?
        </li>
        <li class="m-b-sm">
          Are there situations in which you are especially vulnerable to acting
          in ways that are inconsistent with your values? Be prepared to share
          this with your IPSAT coach and explore connections between your values
          and the rest of your identity.
        </li>
        <li class="m-b-sm">
          Who do you want to imprint with your values? Consider sharing your
          Personal Values Inventory Report with your family, friends, and team
          members to get their feedback.
        </li>
        <li>
          How could you intentionally model these values so your life harmonizes
          with your words to produce even greater impact on the people around
          you?
        </li>
      </ol>
    </div>
  </div>
</div>