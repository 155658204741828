/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderIfNoStripe
 *
 * component
 *
 * It is the responsibility of this component to properly render the transclude contents if and only if StripeCheckout
 *  has not successfully loaded.
 *
 * See https://trello.com/c/cC4ipYjo
 */
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-if-no-stripe',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './if-no-stripe.component.html',
})
export class NexleaderIfNoStripeComponent {
  /**
   * stripeIsPresent()
   *
   * function
   *
   * returns true if the StripeCheckout global is present
   */
  stripeIsPresent(): boolean {
    return window.hasOwnProperty('StripeCheckout');
  }
}
