import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';

@Directive({
  selector: '[ngModelDebounceChange]',
  standalone: true,
})
export class NgModelDebounceChangeDirective implements OnDestroy, OnInit {
  @Input() ngModelDebounce = 500;
  @Output() ngModelDebounceChange = new EventEmitter<any>();

  private subscription!: Subscription;

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  constructor(private ngModel: NgModel) {}

  ngOnInit(): void {
    this.subscription = this.ngModel.control.valueChanges
      .pipe(skip(1), debounceTime(this.ngModelDebounce), distinctUntilChanged())
      .subscribe((value) => this.ngModelDebounceChange.emit(value));
  }
}
