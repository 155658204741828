<div class="flex flex-vertical-center flex-horizontal-center">
  <div *ngIf="!customOnboarding || !customOnboarding.enabledForGroup">
    <img src="/assets/images/onboarding-profile.svg" class="nexleader-onboarding-image p-v-50" *ngIf="
        !onboardingExperience || onboardingExperience.identifier === 'standard'
      " />
    <img src="/assets/images/ecourse/banner.svg" class="p-v-50" style="width: 240px" *ngIf="
        onboardingExperience && onboardingExperience.identifier === 'ecourse'
      " />
  </div>
  <div class="hpanel hgreen" style="width: 90%" *ngIf="customOnboarding && customOnboarding.enabledForGroup">
    <div class="panel-body" style="box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05)">
      <div *ngIf="
          customOnboarding.videoUrl &&
          customOnboarding.videoProvider.identifier === 'vimeo'
        " class="nexleader-video-wrapper">
        <app-nexleader-vimeo-video [vimeoId]="customOnboarding.videoUrl" [hideDownloadLink]="true"
          [fullWidth]="true"></app-nexleader-vimeo-video>
        <hr />
      </div>
      <div *ngIf="
          customOnboarding.videoUrl &&
          customOnboarding.videoProvider.identifier === 'youtube'
        " class="nexleader-video-wrapper">
        <app-nexleader-youtube-video [youtubeId]="customOnboarding.videoUrl"></app-nexleader-youtube-video>
        <hr />
      </div>
      <div *ngIf="
          customOnboarding.videoUrl &&
          customOnboarding.videoProvider.identifier === 'wistia'
        " class="nexleader-video-wrapper">
        <app-nexleader-wistia-video [wistiaId]="customOnboarding.videoUrl"></app-nexleader-wistia-video>
        <hr />
      </div>
      <div *ngIf="!customOnboarding.videoUrl" style="text-align: center">
        <img src="/assets/images/onboarding-profile.svg" class="nexleader-onboarding-image p-v-50" *ngIf="
            !onboardingExperience ||
            onboardingExperience.identifier === 'standard'
          " />
        <img src="/assets/images/ecourse/banner.svg" class="p-v-50" style="width: 240px" *ngIf="
            onboardingExperience &&
            onboardingExperience.identifier === 'ecourse'
          " />
        <hr />
      </div>
      <div class="flex flex-vertical-center m-b-sm">
        <!-- TODO test image -->
        <img *ngIf="onboardingPhoto" class="m-r-sm" [src]="onboardingPhoto.path"
          alt="custom onboarding image" width="100px"
          [ngClass]="{ 'img-circle': customOnboarding.shouldRoundGroupPhoto }" />
        <div>
          <h4 class="text-primary-2">
            {{
            customOnboarding.heading ||
            "Welcome to " + customOnboarding.groupName
            }}
          </h4>
        </div>
      </div>
      <p *ngIf="customOnboarding.description">
        {{ customOnboarding.description }}
      </p>
    </div>
  </div>
</div>