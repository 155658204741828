/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartMbtiLetterAggregation
 *
 * Shows the count of how many team members have each letter from the mbti in the TeamReport
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderViewUsersWithAttributeModalComponent } from '../../../core/components/modals/view-users-with-attribute-modal/view-users-with-attribute-modal.component';

@Component({
  selector: 'app-nexleader-chart-mbti-letter-aggregation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-mbti-letter-aggregation.component.html',
})
export class NexleaderChartMbtiLetterAggregationComponent implements OnInit {
  /**
   * model
   *
   * array in the following form:
   * [{
   *    name: 'Extravert', //This is the type
   *    letter: 'E', //This is its letter
   *    value: 1 //this is how many people have it
   *  }, {
   *    name: 'Introvert', //This is the type
   *    letter: 'I',
   *    value: 2
   *  }
   *  ...There are like 8 of these...
   *  ]
   */
  @Input() model: any[] = [];
  tableData: any[][] = [];

  constructor(private modalService: BsModalService) { }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit() {
    // Validate
    if (!Array.isArray(this.model)) {
      throw new Error(
        'Array data model required to use nexleaderChartMbtiLetterAggregation; see docs'
      );
    }

    const lettersArrangedByDisplayLocation = [
      ['E', 'I'],
      ['S', 'N'],
      ['T', 'F'],
      ['J', 'P'],
    ];

    this.tableData = lettersArrangedByDisplayLocation.map((row) => {
      return row.map((letter) => {
        const results = this.model.filter(
          (letterAggregation) => letterAggregation.letter === letter
        );

        if (results.length !== 1) {
          throw new Error(
            'Malformed model property to nexleaderChartMbtiLetterAggregation; there should be exactly one result for each letter'
          );
        }

        return results[0];
      });
    });
  }

  /**
   * viewUsers
   *
   * Allows a user to click on an mbti and view the users who have that mbti
   *
   * @param {Object} mbtiCountObject - AN mbti property to use inside of the modal
   */
  viewUsers(mbtiCountObject: any) {
    // If there are no users with the mbti object, we do not want to open the modal
    if (mbtiCountObject.value < 1) return;
    // Open the modal
    const modalRef = this.modalService.show(
      NexleaderViewUsersWithAttributeModalComponent,
      {
        // Map the title and users from the passed-in parameters
        initialState: {
          title: mbtiCountObject.name + ' - MBTI',
          users: mbtiCountObject.users,
        },
      }
    );
  }
}
