import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderCustomOnboardingCardComponent } from '../../../../onboarding/components/custom-onboarding-card/custom-onboarding-card.component';

@Component({
  selector: 'app-nexleader-custom-onboarding-preview-modal',
  standalone: true,
  imports: [CommonModule, NexleaderCustomOnboardingCardComponent],
  templateUrl: './custom-onboarding-preview-modal.component.html',
})
export class NexleaderCustomOnboardingPreviewModalComponent {
  groupId: string = '';

  constructor(private bsModalService: BsModalService) { }

  close() {
    this.bsModalService.hide();
  }
}
