<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="hpanel">
  <div class="panel-heading">Your Archived Small Groups</div>
  <div class="panel-body">
    <p *ngIf="getArchivedSmallGroups().length < 1">
      You have not yet archived any of your small groups.
    </p>
    <div class="table-responsive" *ngIf="getArchivedSmallGroups().length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th><i class="fa fa-undo"></i>&nbsp;&nbsp;Undo</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let smallGroup of getArchivedSmallGroups()">
            <td>{{ smallGroup.name }}</td>
            <td>
              <a (click)="setArchivedAndSave(smallGroup, false)">
                <i class="fa fa-undo"></i>&nbsp;&nbsp;Unarchive
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="m-t-md">
      Archived groups are hidden from your coaching calendar. Group admins are
      not allowed to assign new participants for you to coach in archived
      groups.
    </p>
  </div>
</div>
