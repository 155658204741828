/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/**
 * Created by jakebillings on 7/31/16.
 */
/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SurveyResultService {
  constructor(private http: HttpClient) {}

  save(payload: any, surveyResultId?: string) {
    let url = `${API_BASE}surveyResults`;
    url += surveyResultId ? `/${surveyResultId}` : '';
    return this.http.post<any>(url, payload, { withCredentials: true });
  }

  queryUser(userId: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}users/${userId}/surveyResults`, {
      withCredentials: true,
    });
  }

  queryUserIndex(userId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}users/${userId}/surveyResults/index`,
      { withCredentials: true }
    );
  }

  getSurveyResult(surveyResultId: string): Observable<any> {
    return this.http.get<any>(`${API_BASE}surveyResults/${surveyResultId}`, {
      withCredentials: true,
    });
  }

  getShareableVersion(surveyResultId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE}surveyResults/${surveyResultId}/shareable`,
      { withCredentials: true }
    );
  }
}
