/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-chart-mbti-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-mbti-table.component.html',
})
export class NexleaderChartMbtiTableComponent implements OnInit {
  /**
   * model
   *
   * array in the following form:
   * [{
   *        name: 'ENTJ', //This is the MBTI type
   *        value: 1 //This is the number of people who have it
   *    }, {
   *       name: 'INFP',
   *       value: 0
   *   }, {
   *       name: 'ASDF',
   *       value: 3
   *   }
   *  ...There are 16 types in the final
   *  ],
   */
  @Input() model: any[] = [];

  /**
   * teamMbti
   *
   * An object of the team's MBTI to use in inversing the colors on the table
   */
  @Input() teamMbti: any;
  tableData: any[][] = [];

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit() {
    // Validate
    if (!Array.isArray(this.model)) {
      throw new Error(
        'Array data model required to use ChartMbtiTable; see docs'
      );
    }

    const mbtiTypesInRows = [
      ['ISTJ', 'ISFJ', 'INFJ', 'INTJ'],
      ['ISTP', 'ISFP', 'INFP', 'INTP'],
      ['ESTP', 'ESFP', 'ENFP', 'ENTP'],
      ['ESTJ', 'ESFJ', 'ENFJ', 'ENTJ'],
    ];

    this.tableData = mbtiTypesInRows.map((row) =>
      row.map((mbtiName) => {
        const resultDataObjects = this.model.filter(
          (mbtiCountObject) => mbtiCountObject.name === mbtiName
        );

        if (resultDataObjects.length !== 1) {
          throw new Error(
            'Invalid data model for ChartMbtiTable; each MBTI must have exactly one count object; see docs for format'
          );
        }
        return resultDataObjects[0];
      })
    );
  }
}
