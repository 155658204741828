<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">{{ moduleTitle }}</h3>
</div>
<div class="modal-body">
  <p *ngIf="ecourseModule !== 'module8'" class="m-b-sm">
    <b class="text-primary-2 m-b-sm">Big Idea in Brief</b>
  </p>
  <p *ngIf="ecourseModule === 'module1'" class="m-b-md">
    Self-Awareness is a critical building block of emotional intelligence.
    Without it, regardless of your talent and training, you will under achieve.
    Self-awareness is not an end. It’s a gateway to effective self-leadership.
    <br /><br />
    There are two dimensions of self-leadership and they hinge on the ability to
    tell yourself yes, and no. When you tell yourself yes, you build
    self-discipline. When you tell yourself no, you build self-control. The
    cumulative results at the heart of self-leadership, both in terms of
    self-discipline and self-control, will determine the quality and trajectory
    of your life. That’s why self-leadership is so important.
  </p>
  <p *ngIf="ecourseModule === 'module2'" class="m-b-md">
    You can’t grow your personality like you can a strength or skill. The more
    you understand your preferences, the easier it will be to anticipate how you
    will respond to situations that take you outside your personality comfort
    zone. You can also get better at explaining your preferences to others. Your
    identity is complex and unique. No assessment can definitively and
    comprehensively explain who you are. Each component of your identity profile
    has a balcony and basement expression. The balcony side of your personality
    is you at your best. The basement side reflects self-defeating attitudes
    that can pull you down.
  </p>
  <p *ngIf="ecourseModule === 'module3'" class="m-b-md">
    The foundational building block of a strength is talent, which is any
    recurring pattern of thought, feeling, or behavior that can be productively
    applied. Talents are unique and enduring, and everyone’s greatest potential
    for growth is in the areas of their greatest strength. A talent can be
    turned into a strength when combined with knowledge and skills. Gallup has
    organized the thirty-four signature themes of talent into four strengths
    domains: executing, influencing, relationship building, and strategic
    thinking. Understanding your strengths will help you add the most value to
    others and make your best contribution to a team.
  </p>
  <p *ngIf="ecourseModule === 'module4'" class="m-b-md">
    The difference between your skills, personality and strengths can be summed
    up in one word: choice. A helpful formula in the skill building process is
    aptitude + deliberate practice + time = progress toward mastery. Skill
    building is driven by gaps we discover in our ability to perform a specific
    task. There is a difference between developing a skill because you need it
    to do your job, and because you need it to fulfill your purpose. You will go
    farther, faster in skill development when you choose to build on the natural
    momentum of the other aspects of your identity.
  </p>
  <p *ngIf="ecourseModule === 'module5'" class="m-b-md">
    Your life is impacted by your values, even if you can’t explain what they
    are. Without clearly identified values we can find ourselves living life on
    autopilot. Values are like a compass because they point us toward what is
    important and meaningful in life. They are like guardrails because they set
    boundaries for how we pursue what is important. To become value-driven you
    must consciously identify the values that undergird your decisions and
    actions and consistently live by them. It is not possible to be truly
    value-driven if you can’t articulate what you believe is important.
  </p>
  <p *ngIf="ecourseModule === 'module6'" class="m-b-md">
    Passion provides the fuel needed for the rest of your identity profile to
    engage the issues you care deeply about. There are two streams of passion
    potential in everyone. The first stream is interest-based passions, things
    you do for fun. The second stream is issue-based passions, activities that
    bring a sense of purpose. Passion is evidenced by self-directed initiative
    to learn more about, engage in, and influence others toward interests and
    issues even when sacrifice is required. There are four domains of passionate
    engagement, Service, Justice, Discovery and Advocacy. Complex issues require
    passionate engagement from people in all four domains.
  </p>
  <p *ngIf="ecourseModule === 'module7'" class="m-b-md">
    Doing an assessment won’t set you apart from others. It’s what you do with
    the information that sets you apart. You don’t understand your identity
    until you can explain it concisely using language that would make sense to
    anyone, even if they’ve never heard of the resources that helped you
    discover your identity. Others observe how you act and react, but they can’t
    understand why. You understand why you act and react the way you do but you
    can’t see how others perceive you. A byproduct of increased self-awareness
    is the self-confidence required to engage in healthy disclosure and invite
    feedback.
  </p>
  <p class="m-b-sm">
    <b class="text-primary-2">Learning Objectives</b> — In
    <span *ngIf="ecourseModule === 'module8'">the Intro Section of </span>this
    video lesson you will learn:
  </p>
  <ul *ngIf="ecourseModule === 'module1'">
    <li>The two triggers and dimensions of self-leadership</li>
    <li>
      The implication of the time variable on the decision/action cycle at the
      heart of self-leadership
    </li>
    <li>
      The relationship between In-the-Moment and On-the-Journey self-leadership
    </li>
    <li>
      The Importance of Identity Self-Awareness and its connection with
      On-the-Journey self-leadership
    </li>
  </ul>
  <ul *ngIf="ecourseModule === 'module2'">
    <li>
      The difference between type theory, trait theory and the impact of the
      Pigeonhole effect
    </li>
    <li>
      The five research-based and biogenic dimensions of personality seen in
      every culture
    </li>
    <li>The relationship between the Big Five and 16Personalities</li>
    <li>
      The Balcony and Basement expression of Identity including personality
    </li>
  </ul>
  <ul *ngIf="ecourseModule === 'module3'">
    <li>The basic brain science research for the strengths revolution</li>
    <li>How talents can become strengths and why that matters</li>
    <li>How the four strengths domains inform your contribution to a team</li>
    <li>The ways your personality can energize your strengths</li>
  </ul>
  <ul *ngIf="ecourseModule === 'module4'">
    <li>
      How personality and strengths can supercharge the skill building process
    </li>
    <li>The dangers of the Silo Effect and how to overcome them</li>
    <li>The link between skill building and career planning</li>
    <li>
      The important distinction between skill building for work and for life
    </li>
  </ul>
  <ul *ngIf="ecourseModule === 'module5'">
    <li>The power of explicit vs implicit values</li>
    <li>
      The connection between personal values, social contagion, and self-control
    </li>
    <li>Four life domains as primers for identifying personal values</li>
    <li>Six principles for identifying, refining and living your values</li>
  </ul>
  <ul *ngIf="ecourseModule === 'module6'">
    <li>The two streams of passion potential – interest and issue-based</li>
    <li>The four levels of the passion pyramid</li>
    <li>
      How passion uniquely connects with the rest of your Identity Profile
    </li>
    <li>The four domains of passionate engagement and why they matter</li>
    <li>The relationship between passion and self-leadership</li>
  </ul>
  <ul *ngIf="ecourseModule === 'module7'">
    <li>
      How to move beyond doing an assessment to doing something with the
      assessment
    </li>
    <li>
      The danger of the Silo Effect with assessments and how to overcome it
    </li>
    <li>The important role of feedback in growing self-awareness</li>
    <li>
      How to enlist feedback on your Identity Profile from four circles of
      relationship
    </li>
  </ul>
  <ul *ngIf="ecourseModule === 'module8'">
    <li>Review the core ingredients of self-leadership</li>
    <li>Explore the relationship between self-leadership and self-awareness</li>
    <li>
      Connect the self authored statements of the IPSAT with On-the-Journey
      Self-Leadership
    </li>
  </ul>
  <div *ngIf="ecourseModule === 'module8'">
    <p>In Part 1 of this video lesson you will:</p>
    <ul>
      <li>Reflect on the three variables of the Life Planning model</li>
      <li>Understand the connection between destiny markers and passion</li>
      <li>
        Explore how your personality, strengths and skills come to life when
        applied to areas of passion
      </li>
      <li>Learn how passion fuels life purpose</li>
    </ul>
    <br />
    <p>In Part 2 of this video lesson you will:</p>
    <ul>
      <li>Put career planning in the context of life planning</li>
      <li>
        Reflect on the two critical questions that help you bring your best to
        work every day
      </li>
      <li>Understand the unique way work contributes to personal growth</li>
      <li>
        Build a plan to engage your boss (and team) as personal growth partners
      </li>
    </ul>
    <br />
    <p>In Part 3 of this video lesson you will:</p>
    <ul>
      <li>Apply self-leadership skills to college life</li>
      <li>
        Connect In-the-Moment and On-the-Journey self-leadership at college
      </li>
      <li>Reflect on the Payoff Paradox as a self-leadership derailer</li>
      <li>Learn how to make self-leadership actions habit forming</li>
    </ul>
    <br />
    <p>In Part 4 of this video lesson you will:</p>
    <ul>
      <li>
        Put the gap between mediocre and extraordinary in daily perspective
      </li>
      <li>Identify the Categories of Wellbeing that matter to you</li>
      <li>Reflect on wellbeing indicators for each category</li>
      <li>
        Build a self-leadership action plan that’s doable daily and
        extraordinary over time
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
