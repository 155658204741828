<div class="row nexleader-onboarding-h100" *ngIf="selectedModuleContext">
  <div class="col-sm-6 col-xs-12 bg-white nexleader-onboarding-h100 p-lg flex flex-column">
    <h4 class="font-bold">IPSAT for Small Groups</h4>
    <div class="flex-grow m-t-n-lg flex flex-horizontal-center flex-vertical-center flex-column">
      <img [src]="'/assets/images/icons/' + selectedModuleContext.icon" alt="personality image" class="m-b-md img-sm" />
      <h1 class="font-bold m-b-lg">
        Module {{ selectedModuleContext.moduleIndex }}:
        {{ selectedModuleContext.name }}
      </h1>
      <img [src]="
          '/assets/images/module_quotes/module' +
          selectedModuleContext.moduleIndex +
          '.svg'
        " alt="graphic" class="img-lg m-b-lg" />
      <br />
      <a [href]="
          '/assets/files/module_handouts/participant/' +
          selectedModuleContext.downloadLink
        " target="_blank" class="btn btn-success btn-outline">
        <i class="fa fa-download"></i>&nbsp;&nbsp; Download the Handout for
        Module
        {{ selectedModuleContext.moduleIndex }}
      </a>
    </div>
  </div>
  <div class="col-sm-6 col-xs-12 bg-light nexleader-onboarding-h100 flex flex-vertical-center flex-vertical-padded">
    <div class="p-lg w100">
      <div class="row m-b-md">
        <div class="col-xs-10 col-xs-offset-2 col-sm-11 col-sm-offset-1">
          <h3 class="font-bold">Your Next Steps:</h3>
        </div>
      </div>
      <div class="m-b-lg row">
        <div class="col-xs-2 col-sm-1">
          <div class="nexleader-onboarding-module-intro-number">1</div>
        </div>
        <div class="col-xs-10 col-sm-11">
          <h4 class="m-t-none">Before your small group meeting:</h4>
          <p>
            <a target="_blank" [href]="
                '/assets/files/module_handouts/participant/' +
                selectedModuleContext.downloadLink
              ">Download the handout for Module
              {{ selectedModuleContext.moduleIndex }}</a>, and check with your small group leader if you have questions
            about the specific date and time of your meeting.
          </p>
        </div>
      </div>
      <div class="m-b-lg row">
        <div class="col-xs-2 col-sm-1">
          <div class="nexleader-onboarding-module-intro-number m-r-sm">2</div>
        </div>
        <div class="col-xs-10 col-sm-11">
          <h4 class="m-t-none">At your small group meeting:</h4>
          <p>
            You will engage video content with discussion questions facilitated
            by your small group leader/coach.
          </p>
        </div>
      </div>
      <div class="m-b-lg row">
        <div class="col-xs-2 col-sm-1">
          <div class="nexleader-onboarding-module-intro-number m-r-sm">3</div>
        </div>
        <div class="col-xs-10 col-sm-11">
          <h4 class="m-t-none">After your small group meeting:</h4>
          <p>
            Login to your IPSAT account and return to this page of the
            onboarding process. Click the button below to continue to
            <i>{{ selectedModuleContext.name.toLowerCase() }}</i>.
          </p>
        </div>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <div class="flex flex-vertical-center">
            <div class="m-r-md">
              <label class="cursor-pointer flex">
                <input type="checkbox" [(ngModel)]="completionBind" />&nbsp;&nbsp;
                <span class="font-normal">I have completed Module
                  {{ selectedModuleContext.moduleIndex }} at my small group
                  meeting and {{ selectedModuleContext.nextStepText }}</span>
              </label>
            </div>
            <div class="flex-grow"></div>
            <button class="btn btn-lg btn-success" [attr.disabled]="!completionBind ? true : null"
              (click)="completionCallback.emit()">
              Continue
            </button>
          </div>
        </div>
      </div>
      <p class="m-t-md" *ngIf="userIsOnIpsatStage">
        After completing the IPSAT you will exit the onboarding process and be
        directed to your dashboard where you can view your report. Please print
        your report and bring a copy with you to Module 7 for the final meeting
        of your small group.
      </p>
    </div>
  </div>
</div>