/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
import { SuccessService } from './../../../../services/success.service';
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BlobResourceService,
  BlobService,
} from '../../../../services/blob.service';
import { GroupService } from '../../../../services/group.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NexleaderFilePickerComponent } from '../file-picker/file-picker.component';

@Component({
  selector: 'app-nexleader-group-photo-upload',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderFilePickerComponent],
  templateUrl: './group-photo-upload.component.html',
})
export class NexleaderGroupPhotoUploadComponent implements OnInit {
  /**
   * groupId
   *
   * string
   *
   * A string representing the groupId that we are uploading the file for.
   */
  @Input() groupId: string = '';
  @Output() handleOnChange = new EventEmitter<any>();
  /**
   * existingPhotos
   *
   * An array of any existing group photos the user has uploaded for their organization.
   *
   * @type {Array}
   */
  existingPhotos: any[] = [];
  /**
   * selectedFile
   *
   * Default a selectedFile to null since we don't have any files yet.
   *
   * @type {{}}
   */
  selectedFile: any = {};
  group: any;
  file: any;
  data: any;
  uploading = false;

  constructor(
    private blobResourceService: BlobResourceService,
    private successService: SuccessService,
    private blobService: BlobService,
    private groupService: GroupService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  // Validate inputs
  ngOnInit(): void {
    if (!this.groupId) {
      throw new Error('nexleaderComponentGroupPhotoUpload requires data group');
    }
    this.load();
  }

  /**
   * load()
   *
   * Handles all loading logic when the component inits.
   */
  load(): void {
    combineLatest([
      this.blobResourceService.queryOnboardingPhoto(this.groupId),
      this.groupService.get(this.groupId),
    ])
      .pipe(
        tap(([photos, group]) => {
          this.existingPhotos = photos;
          this.group = group;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * onSelect()
   *
   * Sets the file once it has been selected.
   *
   * @param file
   */
  onSelect(file: any): void {
    this.file = file;
  }

  /**
   * onLoad()
   *
   * Handles the result once the file has been loaded.
   *
   * @param data
   */
  onLoad(data: any): void {
    this.data = data;
  }

  /**
   * upload()
   *
   * Handles uploading a new file (group profile photo).
   *
   * @returns {Promise}
   */
  upload(): void {
    this.uploading = true;

    this.blobService
      .uploadBase64Url(this.data, {
        group: this.group ? this.group._id : this.group,
        filename: this.file.name,
        imageType: 'group-photo',
      })
      .pipe(
        tap(() => {
          this.successService.handle({
            message: 'Successfully uploaded your group photo',
          });
          this.uploading = false;
          this.file = {};
          this.data = null;
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError({
            message: 'Failed to Upload ' + this.file.name,
            detail: error,
          });
          this.uploading = false;
          this.file = {};
          this.data = null;
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * deletePhoto()
   *
   * Deletes a photo from the group, so the group no longer has a group photo.
   *
   * @param photo - The photo to be deleted.
   */
  deletePhoto(photo: any) {
    this.blobResourceService
      .delete(photo._id)
      .pipe(
        tap(() => {
          this.successService.handle({
            message: 'Successfully deleted onboarding photo.',
          });
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * saveGroup()
   *
   * Saves the group. (We need some callback on the checkbox click.)
   */
  saveGroup(): void {
    this.groupService
      .save(this.group)
      .pipe(
        tap(() => {
          this.successService.handle({
            message: 'Successfully updated the group.',
          });
          this.handleOnChange.emit();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
