/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentRaliFrame
 *
 * angular component
 *
 * For the IPSAT ECourse feature, nexleader is partnering with the Rali online learning tool. As a result,
 *  We will embed Rali "videos" (they're more than just videos) in iFrames in the ECourse Onboarding process
 *  of the IPSAT. To generate the URLs for these iFrame embeds, we must generate and sign JWTs with some
 *  user data. This verifies users have access to view the content they're viewing and enables commenting,
 *  notifications, etc..
 *
 * This component wraps that iFrame and handles generating the URL and calling the backend for that JWT.
 *
 * It should show up looking like a video.
 *
 * See Rali.route
 * See Rali.logic
 * See https://help.rali.io/docs/embedding-onq/embedding-video-player/#iframe-attributes
 */
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RaliService } from '../../../../services/rali.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-rali-frame',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rali-frame.component.html',
})
export class NexleaderRaliFrameComponent implements OnInit {
  /**
   * raliId
   *
   * string
   *
   * A string representing the ID of the Rali video we want to embed. This comes from Steve, who gets it
   *  from creating contexnt in Rali.
   */
  @Input() raliId: string = 'hyz1u6n1adynbmmc1ykmbz0bxskko54i';
  videoUrl!: SafeResourceUrl;

  /**
   * RALI_PLAYER_URL_PREFIX
   *
   * @type {string} the prefix of the URL preprended to the video id before handing it off to the rali embed code
   */
  private readonly RALI_PLAYER_URL_PREFIX = 'https://player.vimeo.com/video/';

  constructor(
    private sanitizer: DomSanitizer,
    private raliService: RaliService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  // makeRaliIFrameUrl(raliId: string, raliToken: string): string {
  //   return `${this.RALI_PLAYER_URL_PREFIX}?id=${encodeURIComponent(
  //     raliId
  //   )}&data=${encodeURIComponent(raliToken)}`;
  // }

  ngOnInit(): void {
    // Ensure we have the Rali ID
    if (!this.raliId) {
      throw new Error(
        'nexleaderDataRaliId is required for nexleaderComponentRaliFrame; See binding documentation.'
      );
    }

    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.RALI_PLAYER_URL_PREFIX + this.raliId);

    // this.raliService
    //   .get(window.location.href)
    //   .pipe(
    //     tap((result) => {
    //       this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    //         this.makeRaliIFrameUrl(this.raliId, result.token)
    //       );
    //     }),
    //     catchError((error) => {
    //       this.errorHandlerService.handleError(error);
    //       return of(null);
    //     })
    //   )
    //   .subscribe();
  }
}
