<!-- Copyright (C) nexleader - All Rights Reserved -->
<!-- Unauthorized copying of this file, via any medium is strictly prohibited -->
<!-- Proprietary and confidential -->
<!-- Written for nexleader <myipsat.com>, 2016-2018 -->
<app-nexleader-help-utility></app-nexleader-help-utility>

<ng-container *ngIf="step">
  <app-nexleader-onboarding-personal-info *ngIf="step.index === ONBOARDING_STEPS.PERSONAL_INFO.index"
    (boundNext)="next()"></app-nexleader-onboarding-personal-info>

  <app-nexleader-onboarding-video *ngIf="step.index === ONBOARDING_STEPS.VIDEO.index"
    (boundNext)="next()"></app-nexleader-onboarding-video>

  <app-nexleader-onboarding-preassessments *ngIf="step.index === ONBOARDING_STEPS.PREASSESSMENTS.index"
    (boundNext)="next()"></app-nexleader-onboarding-preassessments>

  <app-nexleader-onboarding-take-ipsat *ngIf="step.index === ONBOARDING_STEPS.DONE.index" (boundNext)="next()"
    [standalone]="false"></app-nexleader-onboarding-take-ipsat>
</ng-container>