<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div
  class="nexleader-onboarding-vertical-fill nexleader-onboarding-take-ipsat bg-white"
>
  <div class="nexleader-onboarding-half-layout-side">
    <div class="measure m-t-lg m-b-lg">
      <div class="text-center m-b-lg">
        <h1 class="text-success"><i class="fa fa-check fa-2x"></i></h1>
        <h2>Skills Inventory</h2>
      </div>
      <hr />
      <b class="m-t-sm">
        Estimated time to complete the Skills Inventory: 30 minutes
      </b>
      <hr />
      <p class="m-t-sm">
        The <strong>Skills Inventory</strong> is a top-of-mind self-awareness
        exercise designed to help you reflect on your current level of
        competency in a list of general skills.
      </p>
      <p class="m-t-sm">
        Very few of the skills included are unique to leadership, though leaders
        may be drawn to develop competency in a wider skill set than others.
      </p>
      <p class="m-t-sm">
        No leader operates at a world-class skill level in every area. One of
        the important steps in growing your self-awareness is identifying your
        strengths and building on them. But some roles will require at least
        functional competency in specific skills outside your strengths zone.
      </p>
      <p class="m-t-sm">
        If you understand your current skill level and the skills needed to
        succeed in specific roles you can better position yourself for personal
        growth and future effectiveness.
      </p>
      <p class="m-t-sm">
        The Skills Inventory was created specifically for use with the Identity
        Profile Self-Awareness Tool (IPSAT). It will take about 30 minutes; you
        will not be able to save your answers and return later.
      </p>

      <p class="m-b-md">
        <b>Estimated time to complete the Skills Inventory: 30 minutes</b>
      </p>
      <hr />
      <p class="m-b-sm">
        <b>Tips for taking the Skills Inventory</b>
      </p>
      <ul class="m-b-md">
        <li>
          If you exit the Skills Inventory before submitting your answers, they
          will not be saved.
        </li>
      </ul>
      <hr />
      <a
        class="btn btn-block btn-success"
        [routerLink]="['/users', userId, 'skillsInventory']"
      >
        <i class="fa fa-shield"></i>&nbsp;&nbsp;Take the Skills Inventory
      </a>

      <div class="text-center">
        <hr />
        Not ready to continue? <a (click)="goBack()">Go Back</a>
      </div>
    </div>
  </div>
</div>
