/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentValueSelect
 *
 * component
 *
 * This component is responsible for allowing users to select values. It allows users to search through values,
 *  paginate, and do other functions. This component does not send any API requests and calls a callback function,
 *  "selectValue," when a value is selected.
 */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EnumsService } from '../../../../services/enum.service';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '../../../../pipes/filter.pipe';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-value-select',
  standalone: true,
  imports: [CommonModule, FormsModule, FilterPipe],
  templateUrl: './pvi-assessment-value-select.component.html',
})
export class NexleaderComponentPviAssessmentValueSelectComponent
  implements OnInit
{
  /**
   * onSelectValue
   *
   * function
   *
   * A function that binds to the parent and allows the child component (this component) to select a value.
   */
  @Output() onSelectValue = new EventEmitter();
  searchValue = '';
  limit = 20;
  values: any;
  selectedValues: any;
  pageIndex!: number;

  constructor(private enumsService: EnumsService) {}

  ngOnInit(): void {
    if (!this.onSelectValue.observed) {
      throw new Error(
        'selectValue is a required function for component nexleaderComponentPviAssessmentValueSelect'
      );
    }

    this.enumsService.getEnums().subscribe((enums) => {
      this.values = enums.AllPersonalValues;
      this.selectedValues = this.values.slice(0, this.limit);
      this.pageIndex = 0;
    });
  }

  selectValue(value: any) {
    value.selected = true;
    this.onSelectValue.emit({
      value: value,
    });
  }

  /**
   * scrollToTop()
   *
   * function
   *
   * Scrolls the viewport to the top of the window.
   */
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  /**
   * nextSetOfValues()
   *
   * function
   *
   * Moves the user to the next page of values
   */
  nextSetOfValues() {
    this.pageIndex++;
    this.scrollToTop();
  }

  /**
   * previousSetOfValues()
   *
   * function
   *
   * Moves the user to the previous page of values
   */
  previousSetOfValues() {
    this.pageIndex--;
    this.scrollToTop();
  }

  /**
   * getSelectedValues()
   *
   * function
   *
   * @returns an array of values based off the current pageIndex and the limit.
   */
  getSelectedValues() {
    if (!this.values) return [];
    var currentValuesIndex = this.pageIndex * this.limit;
    return this.values.slice(
      currentValuesIndex,
      currentValuesIndex + this.limit
    );
  }

  /**
   * onLimitChange()
   *
   * function
   *
   * Resets the limit to zero, so we can show all the values for the "Show All" function
   */
  onLimitChange() {
    this.pageIndex = 0;
  }

  /**
   * searchAllValues()
   *
   * function
   *
   * Ensures that when the text input changes, we are searching for all values, not just the values that are
   *  on the currently selected screen. Just resets the limit to the max.
   */
  searchAllValues() {
    this.limit = 999999;
  }
}
