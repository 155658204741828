<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper" *ngIf="user">
  <div class="content animate-panel">
    <div class="hpanel">
      <div class="panel-heading">Edit your information</div>
      <div class="panel-body">
        <div class="row m-b-lg">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">User Settings</h5>
            <p>Edit basic user configuration.</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">First Name</label>
                  <input
                    type="text"
                    placeholder="John"
                    class="form-control"
                    [(ngModel)]="user.firstName"
                    [ngModelDebounce]="1000"
                    (ngModelDebounceChange)="saveUser()"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Last Name</label>
                  <input
                    type="text"
                    placeholder="Smith"
                    class="form-control"
                    [(ngModel)]="user.lastName"
                    [ngModelDebounce]="1000"
                    (ngModelDebounceChange)="saveUser()"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label class="control-label">Year of Birth</label>
                <input
                  type="text"
                  placeholder="1991"
                  class="form-control"
                  [(ngModel)]="user.birthYear"
                />
              </div>
              <div class="col-md-6 form-group">
                <label class="control-label">Gender</label>
                <select
                  #gender
                  class="form-control"
                  (change)="genderChanged(gender.value)"
                  [ngModel]="user.gender?.index"
                >
                  <option [ngValue]="null" selected>
                    -- Select a Gender --
                  </option>
                  <option
                    *ngFor="let option of genders; trackBy: trackByIndex"
                    [value]="option.index"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">Email</label>
              <input
                type="email"
                placeholder="john.smith@gmail.com"
                class="form-control"
                [(ngModel)]="user.email"
                [ngModelDebounce]="1000"
                (ngModelDebounceChange)="saveUser()"
              />
            </div>
            <div class="form-group">
              <label class="control-label"
                >I prefer my coach to contact me via</label
              >
              <select
                #contactType
                class="form-control"
                (change)="contactTypeChanged(contactType.value)"
                [ngModel]="user.preferredContactType?.index"
              >
                <option [ngValue]="null" selected>
                  -- Select a Contact Type --
                </option>
                <option
                  *ngFor="let option of contactTypes; trackBy: trackByIndex"
                  [value]="option.index"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="form-group" *ngIf="user.preferredContactType">
              <label class="control-label">{{
                user.preferredContactType.name
              }}</label>
              <input
                type="text"
                placeholder="{{ user.preferredContactType.example }}"
                class="form-control"
                [(ngModel)]="user.preferredContactAddress"
                [ngModelDebounce]="1000"
                (ngModelDebounceChange)="saveUser()"
              />
            </div>
          </div>
        </div>
        <div class="row m-b-lg" *ngIf="isCoach">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Coach Settings</h5>
            <p>Edit configuration pertaining to coaching.</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <div class="form-group">
              <label class="control-label">Description</label>
              <textarea
                class="form-control m-b-xs"
                [(ngModel)]="user.description"
                [ngModelDebounce]="1000"
                (ngModelDebounceChange)="saveUser()"
                rows="4"
              ></textarea>
              <small class="text-muted"
                >This is what your participants see about you.</small
              >
            </div>
            <div class="form-group">
              <label class="control-label">Coaching URL</label>
              <input
                type="url"
                placeholder="http://google.com"
                class="form-control m-b-xs"
                [(ngModel)]="user.coachingUrl"
                [ngModelDebounce]="1000"
                (ngModelDebounceChange)="saveUser()"
              />
              <small class="text-muted"
                >This is a public URL that one can access at the bottom of a
                shared IPSAT result. If empty, the link will default to your
                email.
              </small>
            </div>
          </div>
        </div>
        <div class="row m-b-lg" *ngIf="isCoach">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Phones</h5>
            <p>
              As a coach, you can configure multiple phones to be used in
              creating user sessions.
            </p>
          </div>
          <div class="col-md-9 col-xs-12">
            <div class="table-responsive">
              <form #phoneForm="ngForm">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Phone Type</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let phone of user.phones; let index = index">
                      <td>{{ phone.phoneType.name }}</td>
                      <td>{{ phone.phone | telephone }}</td>
                      <td>
                        <button
                          class="btn btn-danger"
                          (click)="deletePhone(index)"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                    <tr *ngIf="newPhone">
                      <td>
                        <select
                          #phoneType
                          class="form-control"
                          required
                          [ngModel]="newPhone.phoneType?.index"
                          (change)="phoneTypeChanged(phoneType.value)"
                          name="phoneType"
                        >
                          <option
                            *ngFor="
                              let phoneType of phoneTypes;
                              trackBy: trackByIndex
                            "
                            [value]="phoneType.index"
                          >
                            {{ phoneType.name }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="tel"
                          required
                          class="form-control"
                          name="phoneNumber"
                          [pattern]="
                            '^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$'
                          "
                          [(ngModel)]="newPhone.phone"
                          #phoneNumber="ngModel"
                        />
                        <p
                          *ngIf="phoneNumber.invalid && !phoneNumber.pristine"
                          class="text-danger"
                        >
                          Invalid Phone Number
                        </p>
                      </td>
                      <td>
                        <button
                          class="btn btn-primary"
                          (click)="addPhone()"
                          [attr.disabled]="!phoneForm.valid ? true : null"
                        >
                          Add Phone
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
        <div class="row m-b-lg" *ngIf="isCoach">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Address</h5>
            <p>Configure addresses that are shown to your participants.</p>
          </div>
          <div class="col-md-9 col-xs-12">
            <div class="form-group">
              <label class="control-label">Street</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.address.street"
                [ngModelDebounce]="1000"
                (ngModelDebounceChange)="saveUser()"
              />
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">City</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="user.address.city"
                    [ngModelDebounce]="1000"
                    (ngModelDebounceChange)="saveUser()"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="control-label">State</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="user.address.state"
                    [ngModelDebounce]="1000"
                    (ngModelDebounceChange)="saveUser()"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="control-label">Postal</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="user.address.postal"
                    [ngModelDebounce]="1000"
                    (ngModelDebounceChange)="saveUser()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-b-lg">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2">Opt-out</h5>
          </div>
          <div class="col-md-9 col-xs-12">
            <p class="m-b-sm">
              Opting out prevents your IPSAT data from being included in a Team
              Report.
            </p>
            <div class="form-group">
              <label class="font-normal">
                <input
                  type="checkbox"
                  [(ngModel)]="user.hasOptedOutOfTeamReport"
                  (ngModelChange)="saveUser()"
                />
                I would like to opt-out of IPSAT team report
              </label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showCoachConsolidation()">
          <div class="col-md-3 col-xs-12 m-b-md">
            <h5 class="text-primary-2 m-b-xs">Consolidate your Coaching</h5>
          </div>
          <div class="col-md-9 col-xs-12">
            <p *ngIf="user.coachUser">
              Your account has already been consolidated.
              <a
                [routerLink]="['/multigroupCoaching', user.coachUser, 'groups']"
                >View Your Groups</a
              >
            </p>
            <p *ngIf="!user.coachUser">
              If you coach more than one group, you can consolidate your
              coaching into a single account. This is a permanent change and an
              advanced feature.
              <br /><br />
              <a class="btn btn-default" [routerLink]="['/coachUserOnboarding']"
                >Upgrade my Account</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
