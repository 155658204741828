/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentSelectedValues
 *
 * component
 *
 * Responsible for only rendering the selected values that have been selected at the parent level. This component does
 *  not mutate any state, but only renders the values in cards.
 */

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-selected-values',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pvi-assessment-selected-values.component.html',
})
export class NexleaderComponentPviAssessmentSelectedValuesComponent
  implements OnInit
{
  /**
   * selectedValues
   *
   * array
   *
   * An array of values that have been selected through the parent component that should be rendered inside.
   */
  @Input() selectedValues!: any[];

  /**
   * selectedValue
   *
   * object
   *
   * An object of a single value that represents the currently selected value. This is used to highlight
   *  the select value to provide information to the user that it is selected.
   */
  @Input() selectedValue: any;

  /**
   * shouldNotShowDomain
   *
   * boolean
   *
   * A boolean that determines whether or not to show the additional domain text beneath the selected value.
   */
  @Input() shouldNotShowDomain!: boolean;

  /**
   * shouldNotShowRemove
   *
   * boolean
   *
   * A boolean that determines whether or not to show the remove icon to remove a single value.
   */
  @Input() shouldNotShowRemove!: boolean;

  /**
   * removeValue()
   *
   * function
   *
   * A parent function that this component receives to interpret how to remove a value that has been selected.
   */
  @Output() onRemoveValue: EventEmitter<any> = new EventEmitter();

  /**
   * onValueSelect()
   *
   * function
   *
   * A parent function that this component receives that allows an event to be triggered when a value is
   *  selected.
   */
  @Output() onValueSelect: EventEmitter<any> = new EventEmitter();

  constructor() {}

  // Validate input params
  ngOnInit(): void {
    this.validateInputParams();
  }

  validateInputParams(): void {
    if (!this.shouldNotShowRemove && !this.onRemoveValue.observed) {
      throw new Error(
        'onRemoveValue is a required EventEmitter for component app-nexleader-component-pvi-assessment-selected-values'
      );
    }
  }

  removeValue(value: any): void {
    this.onRemoveValue.emit({ value });
  }

  selectValue(value: any): void {
    if (this.selectValueExists()) {
      this.onValueSelect.emit({ value });
    }
  }

  /**
   * selectValueExists()
   *
   * helper function
   *
   * Determines whether or not the $ctrl.selectValue function exists. Useful for applying classes in the HTML.
   *
   * @returns {boolean} If the function exists
   */
  selectValueExists(): boolean {
    return this.onValueSelect.observed;
  }
}
