<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Edit a user</h3>
</div>
<div class="modal-body" *ngIf="user">
  <div class="row">
    <div class="form-group col-md-6">
      <label class="control-label">First Name</label>
      <input
        type="text"
        placeholder="John"
        class="form-control"
        [(ngModel)]="user.firstName"
      />
    </div>
    <div class="form-group col-md-6">
      <label class="control-label">Last Name</label>
      <input
        type="text"
        placeholder="Smith"
        class="form-control"
        [(ngModel)]="user.lastName"
      />
    </div>
  </div>
  <div class="form-group">
    <label class="control-label">Email</label>
    <input
      type="email"
      placeholder="john.smith@gmail.com"
      class="form-control"
      [(ngModel)]="user.email"
    />
  </div>
  <app-nexleader-input-role [model]="user.roles"></app-nexleader-input-role>
  <app-nexleader-input-group
    *ngIf="currentViewingRole === 'admin'"
    [model]="user.group?._id"
    (realOnChange)="user.group = $event"
  ></app-nexleader-input-group>
  <app-nexleader-input-coach
    *ngIf="user.group && user.group._id"
    [model]="user.coach?._id"
    [groupId]="user.group._id"
    (realOnChange)="user.coach = $event"
  ></app-nexleader-input-coach>
  <hr />
  <p class="m-b-sm">
    Mark a user as a team leader. This allows a user to be able to create, view,
    and edit team reports.
  </p>
  <button class="btn btn-default" (click)="markAsTeamLeader()">
    {{ user.isTeamLeader ? "Unmark" : "Mark" }} as Team Leader
  </button>
  <div *ngIf="user.roles.indexOf('coach') >= 0 && editorHasRole('admin')">
    <hr />
    <label for="enable-coach-direct-purchase-referral">
      <input
        type="checkbox"
        id="enable-coach-direct-purchase-referral"
        [(ngModel)]="user.enableDirectPurchaseReferralForThisCoach"
        (ngChange)="saveUser()"
      />
      Enable Direct Purchase Referral for this Coach in this Group
    </label>
    <br />
    <app-nexleader-direct-purchase-referral-link
      *ngIf="user.enableDirectPurchaseReferralForThisCoach"
      [coachId]="user._id"
    ></app-nexleader-direct-purchase-referral-link>
    <i>You can only see this section because you are an admin.</i>
  </div>
  <div
    *ngIf="
      user.group.isDirectPurchase &&
      user.roles.indexOf('coach') >= 0 &&
      (editorHasRole('admin') || editorHasRole('groupadmin'))
    "
  >
    <hr />
    <label for="enable-coach-direct-purchase-auto-assign">
      <input
        type="checkbox"
        id="enable-coach-direct-purchase-auto-assign"
        [(ngModel)]="user.enableAutomaticDirectPurchaseAssignment"
        (ngChange)="saveUser()"
      />
      Enable Direct Purchase Automatic Assignment for this Coach in this Group
    </label>
    <br />
    <i
      >You can only see this section because you are an admin. If the box is
      checked, then this coach will be considered for automatic assignment to
      incoming direct purchase users in this group.</i
    >
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" type="button" (click)="saveUserAndClose()">
    Save User
  </button>
</div>
