<div class="bg-snow">
  <div class="nexleader-ecourse-container">
    <div class="nexleader-ecourse-nav nexleader-ecourse-nav-invert">
      <div class="nexleader-ecourse-nav-logo">
        <img src="/assets/images/ecourse/ecourse-ipsat-black.svg" alt="ipsat" />
      </div>
      <a href="https://myipsat.com" target="_blank" class="nexleader-ecourse-nav-link"
        *ngIf="!hasCoachOrGroupId">Home</a>
      <a href="https://www.growtowardyourdreams.com/" target="_blank" class="nexleader-ecourse-nav-link"
        *ngIf="!hasCoachOrGroupId">Book</a>
      <a href="https://medium.com/myipsat" target="_blank" class="nexleader-ecourse-nav-link"
        *ngIf="!hasCoachOrGroupId">Blog</a>
      <a [routerLink]="'/login'" class="nexleader-ecourse-nav-link" *ngIf="!hasCoachOrGroupId">Login</a>
    </div>
    <div class="p-v-50">
      <h1 class="font-bold text-primary-2 text-center" style="max-width: 15em; margin: 0 auto; line-height: 1.3">
        Included with the 8 Modules of this online course:
      </h1>
      <br />
      <br />
      <div class="row">
        <div class="col-md-3 col-sm-6 text-center m-b-lg">
          <i class="fa fa-3x fa-file-video-o"></i>
          <h4 class="text-primary-2 font-bold" style="height: 57px">
            Interactive Video Lessons
          </h4>
          <br />
          <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('videos')">
            Learn More
          </button>
        </div>
        <div class="col-md-3 col-sm-6 text-center m-b-lg">
          <i class="fa fa-3x fa-files-o"></i>
          <h4 class="text-primary-2 font-bold" style="height: 57px">
            Application Note Guide and Bundle of Assessments
          </h4>
          <br />
          <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('assessments')">
            Learn More
          </button>
        </div>
        <div class="col-md-3 col-sm-6 text-center m-b-lg">
          <i class="fa fa-3x fa-diamond"></i>
          <h4 class="text-primary-2 font-bold" style="height: 57px">
            Identity Profile Self-awareness Tool (IPSAT)
          </h4>
          <br />
          <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('ipsat')">
            Learn More
          </button>
        </div>
        <div class="col-md-3 col-sm-6 text-center">
          <i class="fa fa-3x fa-calendar-o"></i>
          <h4 class="text-primary-2 font-bold" style="height: 57px">
            One-on-One Virtual Coaching
          </h4>
          <br />
          <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('coaching')">
            Learn More
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="nexleader-ecourse-section-gradient">
  <div class="p-v-50">
    <div class="nexleader-ecourse-container">
      <h1 class="font-bold text-white" style="max-width: 15em; margin: 0 auto; line-height: 1.3">
        8 Modules <br />
        5 Assessments <br />
        2 Coaching Sessions <br />
        Lifetime IPSAT Account
      </h1>
      <br />
      <br />
      <p class="text-primary-2" style="font-size: 16px">
        Remember, we offer a money back guarantee. If you follow our process and
        complete the course but don’t believe we delivered the promised value,
        we’ll give you a complete refund.
      </p>
    </div>
  </div>
</div>
<div class="nexleader-ecourse-cta-section">
  <div class="nexleader-ecourse-container">
    <a class="nexleader-ecourse-button" [href]="getDirectSignupLink()">Get Started With Module 1 For Free</a>
  </div>
</div>
<div class="nexleader-ecourse-footer">
  <div class="nexleader-ecourse-container">
    <div class="flex nexleader-ecourse-footer-container">
      <div class="m-r-sm">
        <a href="https://myipsat.com/privacy-policy/">Privacy Policy</a>
      </div>
      <a [routerLink]="'/login'">Login</a>
    </div>
    <br /><br />
    <p>
      myIPSAT is powered by nexleader LLC
      <br />
      4850 Sugarloaf Parkway Suite 209-165, Lawrenceville GA 30044
      <br />
      678-261-7970
    </p>
  </div>
</div>