/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NexleaderMaximumWordsValidationDirective } from '../../../../../directives/max-words.directive';

@Component({
  selector: 'app-nexleader-survey-question',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderMaximumWordsValidationDirective,
  ],
  templateUrl: './question.component.html',
})
export class NexleaderSurveyQuestionComponent implements OnInit {
  @Input() question: any; // Change the type to match your question object
  @Input() index!: number;
  maxWordCount!: number;

  constructor() {}

  ngOnInit(): void {
    this.maxWordCount = this.question?.maxWordCount || 50;
  }

  get wordCount() {
    return (
      this.question?.answer.name?.split(' ').filter((v: any) => !!v).length || 0
    );
  }

  scrollTo(n: number): void {
    const element = document.getElementById(`survey${n}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  selectAnswer(answer: any): void {
    this.question.answer = answer;
    this.scrollTo(this.index + 1);
  }
}
