/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { GroupService } from '../../../../services/group.service';
import { catchError, of, take, tap } from 'rxjs';
import { NexleaderQuickViewComponent } from '../../components/quick-view/quick-view.component';
import { NexleaderQuickViewNotSelectedComponent } from '../../components/quick-view-not-selected/quick-view-not-selected.component';
import { NexleaderGroupCardComponent } from '../../components/group-card/group-card.component';
import { NexleaderNewGroupModalComponent } from '../../components/modals/new-group-modal/new-group-modal.component';

@Component({
  selector: 'app-nexleader-groups',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderQuickViewComponent,
    NexleaderQuickViewNotSelectedComponent,
    NexleaderGroupCardComponent,
  ],
  templateUrl: './groups.component.html',
})
export class NexleaderGroupsComponent implements OnInit {
  searchText = '';
  groups: any[] = [];
  selectedGroup: any;

  constructor(
    private modalService: BsModalService,
    private groupService: GroupService,
    private successService: SuccessService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.groupService
      .queryIndex()
      .pipe(
        tap((data) => {
          this.groups = data;
          this.groups.forEach((group) => {
            group._licensingPartnerName = group.licensingPartner.name;
            group._licensesRemaining = `${group.remainingLicenses}/${group.maxUsers}`;
            group._tierName = group.tier.name;
            group._surveyTypeName = group.surveyType.name;
          });
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  open(): void {
    const modalRef = this.modalService.show(NexleaderNewGroupModalComponent);
    modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.load();
      this.successService.handle();
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupsComponent',
//   downgradeComponent({
//     component: NexleaderGroupsComponent,
//   }) as angular.IDirectiveFactory
// );
