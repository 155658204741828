/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-about-strengths-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './about-strengths-modal.component.html',
})
export class NexleaderAboutStrengthsModalComponent {
  constructor(private bsModalService: BsModalService) { }

  /**
   * close
   *
   * A close callback function to use
   *
   * ex: someFn()
   */
  close() {
    this.bsModalService.hide();
  }
}
