<!-- Copyright (C) nexleader - All Rights Reserved -->
<!-- Unauthorized copying of this file, via any medium is strictly prohibited -->
<!-- Proprietary and confidential -->
<!-- Written for nexleader <myipsat.com>, 2016-2018 -->

<div class="flex flex-vertical-center flex-horizontal-center hideOnPrint w-100 h-100">
  <button class="btn btn-lg btn-primary" (click)="print()">
    <i class="fa fa-print"></i>&nbsp;&nbsp;Print IPSAT Result
  </button>
</div>
<div class="hideNonPrint">
  <h4 class="pull-right">powered by <b>nexleader</b></h4>
  <h3 class="font-bold">myIPSAT</h3>
  <h5>Identity Profile Self-Awareness Tool</h5>
  <div class="flex align-items-center m-b-lg m-t-lg p-t-sm border-top">
    <img src="/assets/images/logo.svg" alt="logo" width="60px" height="60px" class="m-r-md" />
    <div>
      <h4 class="font-semi-bold">What is the IPSAT?</h4>
      <p>
        The Identity Profile Self-Awareness Tool (IPSAT) is not a new
        assessment. It is a guided discovery exercise designed to help you
        evaluate and quantify how well you understand and engage with the five
        components of your Identity Profile.
      </p>
    </div>
  </div>
  <div>
    <h3 class="m-b-md font-semi-bold">My Results</h3>
    <div class="row" *ngIf="surveyResult">
      <div class="col-xs-12 m-b-md">
        <div class="flex align-items-center">
          <img src="/assets/images/icons/personality.svg" alt="personality" width="60px" height="60px" class="m-r-md" />
          <div>
            <h4 class="font-semi-bold">Personality</h4>
            <p>
              My personality can best be described as
              <b>{{ surveyResult.prerequisites.mbti.name }}</b>.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 m-b-md" *ngIf="surveyResult.prerequisites.strengths.length > 0">
        <div class="flex align-items-center">
          <img src="/assets/images/icons/strengths.svg" alt="strengths" width="60px" height="60px" class="m-r-md" />
          <div>
            <h4 class="font-semi-bold">Strengths</h4>
            <p>
              My top five strengths are
              <span *ngFor="
                  let strength of surveyResult.prerequisites.strengths;
                  let last = last
                "><b>{{ strength.name }}</b><span *ngIf="!last">, </span></span>.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 m-b-md" *ngIf="surveyResult.prerequisites.skills.length > 0">
        <div class="flex align-items-center">
          <img src="/assets/images/icons/skills.svg" alt="skills" width="60px" height="60px" class="m-r-md" />
          <div>
            <h4 class="font-semi-bold">Skills</h4>
            <p>
              My top three skills are
              <span *ngFor="
                  let skill of surveyResult.prerequisites.skills;
                  let last = last
                ">
                <b>{{ skill.name }}</b>
                <span *ngIf="!last">, </span> </span>.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 m-b-md">
        <div class="flex align-items-center">
          <img src="/assets/images/icons/spiritualgifts.svg" alt="spiritual gifts" width="60px" height="60px"
            class="m-r-md" />
          <div *ngIf="surveyTypeIdentifier === 'faith_based'">
            <h4 class="font-semi-bold">Spiritual Gifts</h4>
            <p>
              My top three spiritual gifts are
              <span *ngFor="
                  let gift of surveyResult.prerequisites.spiritualGifts;
                  let last = last
                ">
                <b>{{ gift.name }}</b>
                <span *ngIf="!last">, </span> </span>.
            </p>
          </div>
          <div *ngIf="surveyTypeIdentifier === 'values_based'">
            <h4 class="font-semi-bold">Personal Values</h4>
            <p>
              My top three personal values are
              <span *ngFor="
                  let gift of surveyResult.prerequisites.spiritualGifts;
                  let last = last
                ">
                <b>{{ gift.name }}</b>
                <span *ngIf="!last">, </span> </span>.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 m-b-md">
        <div class="flex align-items-center">
          <img src="/assets/images/icons/passions.svg" alt="passions" width="60px" height="60px" class="m-r-md" />
          <div>
            <h4 class="font-semi-bold">Passions</h4>
            <p>
              My top three passions are
              <span *ngFor="
                  let passion of surveyResult.prerequisites.passions;
                  let last = last
                ">
                <b>{{ passion.name }}</b>
                <span *ngIf="!last">, </span> </span>.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <h4 class="font-semi-bold">Identity Overview Statement</h4>
        <p *ngFor="
            let summaryOverviewQuestion of surveyResult.questions
              | questionCategory : summaryOverviewQuestionCategory
          ">
          {{ summaryOverviewQuestion.answer.name }}
        </p>
      </div>
    </div>
    <div class="text-center m-t-lg m-b-md">
      <h4 class="font-bold text-primary-2">
        Be who you are. Love what you do.
      </h4>
    </div>
    <div>
      <div class="pull-left">
        {{ user?.firstName }} {{ user?.lastName }}
        <span *ngIf="coach">
          coached by
          <a *ngIf="coach.coachingUrl" [href]="coach.coachingUrl">{{ coach.firstName }} {{ coach.lastName }}</a>
          <a *ngIf="!coach.coachingUrl" [href]="
              'mailto:' + coach.email + '?subject=I’m interested in the IPSAT'
            ">{{ coach.firstName }} {{ coach.lastName }}</a>.
        </span>
      </div>
      <div class="pull-right">myipsat.com</div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>