<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="hpanel">
  <tabset>
    <tab *ngFor="let item of items" [heading]="item.title">
      <div class="panel-body">
        <form #tabItemForm="ngForm">
          <b>{{ item.name }}</b>
          <br />
          <br />
          <p *ngIf="!editing">{{ item.answer.name }}</p>
          <div *ngIf="editing">
            <textarea
              class="form-control"
              rows="4"
              name="{{ item.title }}"
              [(ngModel)]="item.answer.name"
              nexleaderMaximumWordsValidation
              [maxWords]="50"
            ></textarea>
            <br />
            <button
              class="btn btn-success"
              (click)="saveTab()"
              [attr.disabled]="tabItemForm.invalid ? true : null"
            >
              <i class="fa fa-save"></i>&nbsp;&nbsp;Save
            </button>
            <span style="color: red" *ngIf="tabItemForm.invalid"
              >Invalid - Check that your response is fewer than 50 words.</span
            >
            <br />
          </div>
          <br />
          <a (click)="toggleEditing()"
            ><i class="fa fa-pencil"></i>&nbsp;&nbsp;Edit</a
          >
        </form>
      </div>
    </tab>
  </tabset>
</div>
