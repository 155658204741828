/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../services/enum.service';
import { SmallGroupService } from '../../../resources/small-group.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SuccessService } from '../../../../../services/success.service';
import { FormsModule } from '@angular/forms';
import { NexleaderInputEnumComponent } from '../../../../core/components/input-enum/input-enum.component';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { NexleaderInputDatetimeComponent } from '../../../../core/components/input-datetime/input-datetime.component';

@Component({
  selector: 'app-nexleader-edit-small-group-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderInputEnumComponent,
    NexleaderInputDatetimeComponent,
  ],
  templateUrl: './edit-small-group-modal.component.html',
})
export class NexleaderEditSmallGroupModalComponent implements OnInit {
  /**
   * smallGroupId
   *
   * A small group that will be edited inside of the modal.
   *
   * ex: {SMALL GROUP DATA}
   */
  smallGroupId: string = '';
  smallGroup: any;
  AllSmallGroupMeetingTypes: any[] = [];
  smallGroupMeetings: any[] = [];

  /**
   * save
   *
   * A function to use to save the updated small group.
   *
   * wrapped by save(), which does some processing on data (see $ctrl.save())
   *
   * ex: someFn()
   */
  @Output() onSave = new EventEmitter<any>();

  /**
   * cancel
   *
   * A cancel callback function to use to cancel the editing of a small group.
   *
   * ex: someFn()
   */
  @Output() onCancel = new EventEmitter<any>();

  constructor(
    private enumsService: EnumsService,
    private smallGroupService: SmallGroupService,
    private errorHandler: ErrorHandlerService,
    private successHandler: SuccessService
  ) { }

  /**
   * $onInit()
   *
   * function: angular event handler
   *  called after bindings load
   *
   * validate that the bindings loaded and are the correct type
   */
  ngOnInit() {
    if (!this.smallGroupId) {
      throw new Error(
        'nexleaderEditSmallGroupModal requires string smallGroupId property'
      );
    }

    //Load AllSmallGroupMeetingTypes from enums because we want to populate all event names
    // even if they're not scheduled in the group yet
    forkJoin([
      this.enumsService.getEnums(),
      this.smallGroupService.getSmallGroup(this.smallGroupId),
    ])
      .pipe(
        tap(([enums, smallGroup]) => {
          this.AllSmallGroupMeetingTypes = enums.AllSmallGroupMeetingTypes;
          this.smallGroup = smallGroup;

          // We need to initialize the meeting length enum
          // This is because we use this enum in the input-enum component, and it requires that it be not null
          if (typeof this.smallGroup.moduleMeetingLength === 'undefined') {
            this.smallGroup.moduleMeetingLength = null;
          }

          this.smallGroupMeetings = this.AllSmallGroupMeetingTypes.sort(
            (a, b) => a.index - b.index
          ).map((smallGroupMeetingType) => {
            const match = this.smallGroup.smallGroupMeetings.filter(
              (smallGroupMeeting: any) =>
                smallGroupMeeting.smallGroupMeetingType.identifier ===
                smallGroupMeetingType.identifier
            )[0];

            return {
              smallGroupMeetingType,
              start: match ? match.start : null, //||null is necessary for binding (null vs undefined matters in model prop of input filed)
              timezone: match ? match.timezone : null,
              location: match ? match.location : null,
            };
          });
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * smallGroupMeetingsAreValid()
   *
   * function
   *
   * This is a helper function that allows us to determine whether the small group meetings configured are
   *  invalid. We use this to allow the user to send an email to all participants in a small group if the
   *  meetings have been configured.
   */
  smallGroupMeetingsAreValid(): boolean {
    if (!this.smallGroupMeetings) {
      return false;
    }
    return this.smallGroupMeetings.every((meeting) => {
      return (
        meeting.start &&
        meeting.timezone &&
        meeting.location &&
        meeting.location !== ''
      );
    });
  }

  /**
   * sendSmallGroupMeetingEmails()
   *
   * function
   *
   * Makes a request to send the initial small group scheduling email to all members within the small group.
   *  This email lets all members of the small group the schedule for each module meeting.
   *
   * This function should only be run if all the small group meetings are valid, hence the first line
   *  requiring this.
   */
  sendSmallGroupMeetingEmails() {
    if (!this.smallGroupMeetingsAreValid()) {
      return;
    }
    this.save();
    this.smallGroupService
      .sendInitialMeetingEmail(this.smallGroup._id)
      .pipe(
        tap(() => { }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * save()
   *
   * maps meeting data for saving then calls realSave()
   */
  save() {
    // Filter to make sure we only save meetings with a start and a location
    this.smallGroup.smallGroupMeetings = this.smallGroupMeetings.filter(
      (smallGroupMeeting) => {
        return smallGroupMeeting.start && smallGroupMeeting.location;
      }
    );

    // If any of the meetings are in the past, we need to return an error
    if (
      this.smallGroup.smallGroupMeetings.some(
        (meeting: any) => Date.parse(meeting.start) < Date.now()
      )
    ) {
      this.errorHandler.handleError(
        {
          message: 'All small group meetings must be in the future',
        },
        this.errorHandler.types.TOAST
      );
      return;
    }

    this.smallGroupService
      .save(this.smallGroup)
      .pipe(
        tap(() => {
          this.successHandler.handle();
          this.onSave.emit();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  cancel() {
    this.onCancel.emit();
  }
}
