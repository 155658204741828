/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-file-picker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './file-picker.component.html',
})
export class NexleaderFilePickerComponent {
  @Input() accept: string = '';
  @Output() onLoad: EventEmitter<{ file: File; uri: string }> =
    new EventEmitter();
  @Output() onSelect: EventEmitter<{ file: File }> = new EventEmitter();

  constructor(private el: ElementRef) {}

  clickEvent = () => {
    const input = this.el.nativeElement.querySelector('input');
    input.click();
  };

  onFileUpdate = (event: any) => {
    const target = event.target;
    //If the file changes, load the new file and store it in the appropriate variable.
    const file = target.files[0];
    this.onSelect.emit(file);
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      this.onLoad.emit({ file, uri: evt.target.result });
    };
    reader.readAsDataURL(file);
  };
}
