<div class="row nexleader-onboarding-h100 nexleader-onboarding-reverse-row">
  <div
    class="col-md-6 nexleader-onboarding-padded-container bg-white nexleader-onboarding-half-layout-side"
  >
    <div class="nexleader-onboarding-preassessments-padding">
      <div class="col-md-12">
        <h2>Let's complete your profile...</h2>
        <p class="m-t-md">
          Having the following information helps us curate a path for you when
          using the IPSAT.
        </p>
      </div>

      <div class="m-t-md nexleader-onboarding-form-container" *ngIf="user">
        <div class="form-group col-lg-6">
          <label class="control-label">First Name</label>
          <input
            type="text"
            placeholder="John"
            class="form-control"
            [(ngModel)]="user.firstName"
          />
        </div>

        <div class="form-group col-lg-6">
          <label class="control-label">Last Name</label>
          <input
            type="text"
            placeholder="Smith"
            class="form-control"
            [(ngModel)]="user.lastName"
          />
        </div>
        <div class="form-group col-lg-6">
          <label class="control-label">Year of Birth</label>
          <select class="form-control" [(ngModel)]="user.birthYear">
            <option *ngFor="let year of getBirthYears()" [ngValue]="year">
              {{ year }}
            </option>
          </select>
        </div>

        <div class="form-group col-lg-6">
          <label class="control-label">Gender</label>
          <app-nexleader-input-enum
            *ngIf="user"
            [model]="user.gender"
            [enumName]="'Genders'"
            (realOnChange)="user.gender = $event"
          ></app-nexleader-input-enum>
        </div>

        <div class="form-group col-lg-12">
          <label class="control-label">Email</label>
          <input
            type="text"
            placeholder="john.smith@gmail.com"
            class="form-control"
            [(ngModel)]="user.email"
          />
        </div>

        <!-- groupTiers is not defined in JS file -->
        <!-- <div
          class="form-group col-lg-12"
          *ngIf="user.group.tier.index === groupTiers.ORGANIZATIONAL.index"
        >
          <label class="control-label">Organization</label>
          <input
            type="text"
            placeholder="Organization"
            class="form-control"
            [disabled]="true"
            [(ngModel)]="user.group.name"
          />
        </div> -->

        <div class="form-group col-lg-12">
          <label class="control-label"
            >I prefer my coach to contact me via</label
          >
          <app-nexleader-input-enum
            *ngIf="user"
            [model]="user.preferredContactType"
            [enumName]="'ContactTypes'"
            (realOnChange)="user.preferredContactType = $event"
          ></app-nexleader-input-enum>
        </div>

        <div
          class="form-group col-lg-12"
          *ngIf="
            user.preferredContactType && user.preferredContactType !== 'null'
          "
        >
          <label class="control-label">{{
            user.preferredContactType.name
          }}</label>
          <input
            type="text"
            placeholder="{{ user.preferredContactType.example }}"
            class="form-control"
            [(ngModel)]="user.preferredContactAddress"
          />
        </div>

        <div class="col-lg-12">
          <hr />
        </div>

        <div class="form-group col-lg-12">
          <label class="control-label">Opt Out</label>
          <p class="m-b-sm">
            Opting out prevents your IPSAT data from being included in a Team
            Report.
          </p>
          <div class="form-group">
            <label class="font-normal">
              <input
                type="checkbox"
                [(ngModel)]="user.hasOptedOutOfTeamReport"
              />
              I would like to opt-out of IPSAT team report
            </label>
          </div>
        </div>
      </div>

      <div class="col-lg-12" *ngIf="!isReadyToAdvance()">
        <p class="nexleader-onboarding-text-red">
          Please complete all fields before continuing.
        </p>
      </div>

      <div class="form-group col-lg-12 m-t-lg">
        <button
          class="btn btn-success nexleader-onboarding-full-button"
          [attr.disabled]="!isReadyToAdvance() ? true : null"
          (click)="next()"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <div
    class="col-md-6 nexleader-onboarding-gray h-100 flex flex-vertical-center flex-horizontal-center"
  >
    <app-nexleader-custom-onboarding-card
      *ngIf="groupId"
      [groupId]="groupId"
    ></app-nexleader-custom-onboarding-card>
  </div>
</div>
