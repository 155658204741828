/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartStrengthsRepresented
 *
 * Displays a chart representation of the "represented strengths" of a team in the TeamReport
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgChartsModule } from 'ng2-charts';
import { NexleaderViewUsersWithAttributeModalComponent } from '../../../core/components/modals/view-users-with-attribute-modal/view-users-with-attribute-modal.component';
import { NexleaderAboutStrengthsModalComponent } from '../../../core/components/modals/about-strengths-modal/about-strengths-modal.component';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-nexleader-chart-strengths-represented',
  standalone: true,
  imports: [CommonModule, NgChartsModule],
  templateUrl: './chart-strengths-represented.component.html',
})
export class NexleaderChartStrengthsRepresentedComponent implements OnInit, OnDestroy {
  /**
   * model
   *
   * array in the following form:
   * [{
   *    name: 'Executing',
   *    description: 'making things happen',
   *    strengths: [
   *        {
   *            name: 'Strength name',
   *            value: 2 //how many people have it
   *        },
   *        {
   *            name: 'Strength name',
   *            value: 2 //how many people have it
   *        },
   *        {
   *            name: 'Strength name',
   *            value: 2 //how many people have it
   *        }
   *    ],
   *    value: 3 //how many strengths there are
   *  }
   *  ...more...
   *  ]
   */
  @Input() model: any[] = [];
  private ctx!: HTMLCanvasElement;
  private chart!: Chart;

  constructor(private modalService: BsModalService) { }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit(): void {
    //Validate that there's a model on here
    if (!this.model) {
      throw new Error(
        'model is required for NexleaderChartStrengthsRepresented (see component docs; maybe add an ng-if if it has to load)'
      );
    }

    // Create an array with no values as to serve a placeholder for the strengths labels
    // We do NOT need any labels, yet chart.js requires it, so we must give it an array of empty values of the length of the data
    const labels = this.model.map((item) => (item = ''));
    const data = this.model.map(
      (representedCategory) => representedCategory.value
    );

    // Find the maximum strength value for the array of data
    // We want to be able to alter the height of the chart to only go to the max+1 height
    const maxStrengthValue = data.reduce((a, b) => Math.max(a, b), 0);

    // We need to correctly show the color for each strength category
    // This is provided by StrengthsFinder
    const strengthBorderColors = this.model.map(
      (representedCategory) => representedCategory.borderColor
    );
    const strengthBackgroundColors = this.model.map(
      (representedCategory) => representedCategory.backgroundColor
    );

    // Acquire a reference to the canvas element
    this.ctx = document.getElementById(
      'strength-bar-chart'
    ) as HTMLCanvasElement;

    // Draw the chart using the model data and canvas ref
    if (this.ctx) {
      this.chart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: strengthBackgroundColors, //strengths red
              borderColor: strengthBorderColors, //strengths red
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          scales: {
            y: {
              position: 'left',
              type: 'linear',
              min: 0,
              max: maxStrengthValue + 1,
              suggestedMin: 10,
              suggestedMax: 10,
              ticks: {
                maxRotation: 0,
                minRotation: 0,
              },
              grid: {
                display: true,
              },
              display: true,
            },
            x: {
              display: false,
            },
          },
        },
      });
    }
  }

  /**
   * viewStrengthsInformation
   *
   * Allows a user to learn more about the strengths section through a modal
   */
  viewStrengthsInformation(): void {
    this.modalService.show(NexleaderAboutStrengthsModalComponent);
  }

  /**
   * viewUsers
   *
   * Allows a user to click on a strength and view the users who have that strength
   *
   * @param {Object} strength - A strength property to use inside of the modal
   */
  viewUsers(strength: any): void {
    this.modalService.show(NexleaderViewUsersWithAttributeModalComponent, {
      initialState: {
        // Map the title and users from the passed-in parameters
        title: strength.name + ' - Strengths',
        users: strength.users,
        additionalClarificationCopy:
          'An asterisk denotes this is a top strength for this user.',
      },
    });
  }

  ngOnDestroy() {
    if (this.chart) this.chart.destroy();
  }
}
