/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
/**
 * nexleaderOnboardingPersonalValuesInventory
 *
 * The view component for the onboarding personal values inventory section of the onboarding preassessments.
 * This view contains information and fields that pertain to this part of the onboarding.
 *
 * This view is interesting, as it basically mimics the style of the spiritual gifts and contains similar information.
 *  This assessment is used when the group is a values based group instead of a spirituality based group.
 */
import { PersonalValuesInventoryResultService } from './../../../../../../services/personal-values-inventory-result.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { tap } from 'rxjs';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-onboarding-personal-values-inventory',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './onboarding-personal-values-inventory.component.html'
})
export class NexleaderOnboardingPersonalValuesInventoryComponent implements OnInit {
  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process
   */
  @Input() user: any;

  pviResults: any[] = [];
  pviResultId: string | undefined;
  topThreeValues: any[] = [];

  constructor(
    private personalValuesInventoryResultSvc: PersonalValuesInventoryResultService
  ) { }

  ngOnInit(): void {
    // Validate config
    if (!this.user?._id) {
      throw new Error('Input object user is required with _id for NexleaderOnboardingPersonalValuesInventory');
    }

    // Load PVI results
    this.loadPersonalValuesInventoryResults();
  }

  /**
   * loadPersonalValuesInventoryResults()
   *
   * function
   *
   * Loads all PVI results for an individual user based off of their user_id.
   */
  loadPersonalValuesInventoryResults(): void {
    this.personalValuesInventoryResultSvc.queryUser(this.user._id).pipe(
      tap((pviResults: any[]) => {
        this.pviResults = pviResults;

        // There must be at least one PVI result
        if (this.pviResults.length > 0) {
          // We want to get this off the first result
          this.pviResultId = this.pviResults[0]._id;
          // Filter those in the top three
          this.topThreeValues = this.pviResults[0].topTwelveValues
            .filter((value: any) => value.isInTopThree)
            // Sort by the top three index so we can ensure that the data is in the correct order
            .sort((a: any, b: any) => a.topThreeIndex - b.topThreeIndex);
          // We still assign this to spiritualGifts to ensure consistency in our data model
          this.user.prerequisites.spiritualGifts = this.topThreeValues;
        }
      })
    ).subscribe();

  }
}
