/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../../environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, catchError, of, tap, EMPTY, switchMap, take } from 'rxjs';
import { AuthService } from '../../../../../services/auth.service';
import { EnumsService } from '../../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { UserService } from '../../../../../services/user.service';
import { DirectService } from '../../../../direct/resources/direct.service';
import { NexleaderEcourseRaliVideoComponent } from '../../../../ecourse/components/ecourse-rali-video/ecourse-rali-video.component';
import { NexleaderIfNoStripeComponent } from '../../../../store/components/if-no-stripe/if-no-stripe.component';
import { NexleaderCurrencyComponent } from '../../../../store/components/currency/currency.component';
import { FormsModule } from '@angular/forms';
import { NexleaderStripeElementModalComponent } from '../../../../store/components/stripe-element-modal/stripe-element-modal.component';
import { NexleaderEcourseInformationModalComponent } from '../../../../core/components/modals/ecourse-information-modal/ecourse-information-modal.component';

@Component({
  selector: 'app-nexleader-ecourse-module-one',
  standalone: true,
  imports: [CommonModule, NexleaderEcourseRaliVideoComponent, NexleaderIfNoStripeComponent, NexleaderCurrencyComponent, FormsModule],
  templateUrl: './ecourse-module-one.component.html'
})
export class NexleaderEcourseModuleOneComponent implements OnInit {

  /**
   * handleNextStep
   *
   * Allows data that is edited on this ecourse module to be saved.
   */
  @Output() handleNextStep = new EventEmitter();

  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process.
   */
  @Input() user: any;
  routeParams: any;

  /**
   * transactionIsInProgress
   *
   * this flag is set to true if the user has clicked submit and we have not
   *  yet received a response from the server; we don't want them to be able to
   *  click submit again
   *
   * @type {boolean}
   */
  transactionIsInProgress: boolean = false;

  /**
   * pricingConfig
   *
   * this object contains all data relevant to that price of the IPSAT
   *
   * a deep watch on this object calls the loadPrice() function
   *
   * this keeps the price accurate even when clifton strengths is enabled/disabled
   *
   * @type {null}
   */
  pricingConfig: any = null;
  pricing: any = null;
  loading: boolean = false;

  /**
   * currentStep
   *
   * Determines the current step the user is on in the ecourse module.
   */
  currentStep: string = 'engagement';

  /**
   * moduleSteps
   *
   * Controls the steps the user can move between for the ecourse module.
   */
  moduleSteps: string[] = ['engagement', 'included_items', 'purchase'];
  isModalOpen: boolean = false;
  candidateDiscountCodeName: string = '';

  /**
   * availableModules
   *
   * All available modules that the user can view additional information for.
   */
  availableModules = [
    {
      name: 'Module 1',
      identifier: 'module1',
      description: 'The Art of Self-Leadership and the Power of Self-Awareness',
      icon: 'module1'
    },
    {
      name: 'Module 2',
      identifier: 'module2',
      description: 'Exploring Your Personality (includes assessment based on the Big Five Model)',
      icon: 'personality'
    },
    {
      name: 'Module 3',
      identifier: 'module3',
      description: 'Discovering Your Strengths (includes Clifton Strengths Assessment)',
      icon: 'strengths'
    },
    {
      name: 'Module 4',
      identifier: 'module4',
      description: 'Developing Your Skills (includes Skills Inventory)',
      icon: 'skills'
    },
    {
      name: 'Module 5',
      identifier: 'module5',
      description: 'Refining Your Personal Values (includes Personal Values Inventory)',
      icon: 'spiritualgifts'
    },
    {
      name: 'Module 6',
      identifier: 'module6',
      description: 'Uncovering Your Passions (includes MyPassionProfile)',
      icon: 'passions'
    },
    {
      name: 'Module 7',
      identifier: 'module7',
      description: 'Discovering your Identity Profile with IPSAT (includes one-on-one coaching session)',
      icon: 'module7'
    },
    {
      name: 'Module 8',
      identifier: 'module8',
      description: 'Using Self-Awareness and Self-Leadership to Bring Your Best to School, Work, and Life (includes one-on-one coaching session)',
      icon: 'module8'
    }
  ];
  ECOURSE_ONBOARDING_STEPS: any;
  ONBOARDING_EXPERIENCES: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private enumsService: EnumsService,
    private directService: DirectService,
    private authService: AuthService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    this.load();
    this.pricingConfig = {
      cliftonStrengthsAssessment: false,
      onboardingExperienceIdentifier: 'ecourse',
      userId: this.routeParams['user_id']
    };

    this.handlePricingConfigChange();
  }

  /**
   * load()
   *
   * called when the component binding is complete
   *
   * loads the user we're editing
   * loads any additional information necessary for running the view
   */
  load(): void {
    // Validate the javascript types of our angular $routeParams
    const userId = this.routeParams['user_id'];
    if (!userId) {
      throw new Error('User ID is invalid.');
    }

    this.loading = true;
    forkJoin([
      this.userService.getUser(userId),
      this.enumsService.getEnums()
    ]).pipe(
      tap(([user, enums]) => {
        this.user = user;

        this.ECOURSE_ONBOARDING_STEPS = enums.EcourseOnboardingSteps;
        this.ONBOARDING_EXPERIENCES = enums.OnboardingExperiences;
        this.loading = false;
      }),
      catchError((error) => {
        this.loading = false;
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  loadPrice(): void {
    this.loading = true;

    let config: any = {
      user_id: this.pricingConfig.userId,
      clifton_strengths_assessment: this.pricingConfig.cliftonStrengthsAssessment,
      onboarding_experience_identifier: this.pricingConfig.onboardingExperienceIdentifier
    }

    if (this.pricingConfig.discountCodeName) {
      config.discount_code_name = this.pricingConfig.discountCodeName
    }

    this.directService.getPricing(config).pipe(
      tap((pricing) => {
        this.pricing = pricing;
        this.loading = false;
      }),
      catchError(error => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  /**
   * isReadyToAdvance()
   *
   * perform simple validation on the form data and make sure surveyType has loaded (and is valid)
   *
   * Note: this is no guarantee we won't get a 422 - e.g. the email could be valid but a duplicate
   *
   * @returns {boolean} true if we're ready to submit to the backend
   */
  isReadyToAdvance(): boolean {
    if (this.transactionIsInProgress) return false;
    if (this.isModalOpen) return false;
    if (this.loading) return false;
    if (!this.user) return false;
    if (!this.pricingConfig) return false;
    if (typeof this.pricingConfig.userId !== 'string') return false;
    if (!(this.pricingConfig.cliftonStrengthsAssessment === true
      || this.pricingConfig.cliftonStrengthsAssessment === false)) return false;
    return true;
  }

  handlePricingConfigChange() {
    if (this.pricingConfig) {
      this.loadPrice();
    }
  }

  /**
   * checkout()
   *
   * function
   *
   * this is the main form submission
   *
   * @returns {boolean} false if we cancelled due to frontend validation
   */
  checkout(): void {
    // Abort if the form doesn't meet frontend validation requirements
    //  Those requirements are pretty weak, so there's no point bothering the backend
    //  if they fail
    if (!this.isReadyToAdvance()) return;

    this.isModalOpen = true;

    //Tokenize a payment method via Stripe
    const modalRef = this.modalService.show(
      NexleaderStripeElementModalComponent,
      {
        initialState: {
          title: 'IPSAT Ecourse',
          description: `nexleader ${this.pricing.lineItems
            .map((lineItem: any) => lineItem.name)
            .join(' and ')} for ${this.user.firstName} ${this.user.lastName}`,
          currencyCode: this.pricing.total.currencyCode?.toLowerCase(),
          amount: Math.trunc(this.pricing.total.currencyQuantity * 100),
          email: this.user.email,
          isEmailEditable: false,
        },
      }
    );

    // If there was an error, return with the error appropriately
    modalRef.onHide?.pipe(take(1)).subscribe((_) => {
      this.isModalOpen = false;
    });

    modalRef.content?.tokenGenerated
      .pipe(take(1))
      .pipe(
        tap(() => modalRef.hide()),
        switchMap((token) => {
          // If the price is greater than 0, there must be a token in order to successfully make a payment
          if ((this.pricing.total.currencyQuantity > 0) && !token) {
            return EMPTY;
          }

          this.transactionIsInProgress = this.pricing.total.currencyQuantity !== 0;

          // Build the request body
          const directPurchaseRequestBody = {
            userId: this.user._id,
            cliftonStrengthsAssessment: this.pricingConfig.cliftonStrengthsAssessment,
            token: token, //could be null/undefined
            pricing: this.pricing, //added as a redundancy - we report what we charged to make sure it's correct
            onboardingExperienceIdentifier: this.pricingConfig.onboardingExperienceIdentifier,
            discountCodeName: this.pricingConfig.discountCodeName
          };

          // Send the DirectLanding post request
          //  On Success, perform the next step
          //  On Error, show an error toast
          return this.directService.
            purchase(directPurchaseRequestBody)
            .pipe(
              catchError((err) => {
                this.transactionIsInProgress = false;
                this.errorHandlerService.handleError(err);
                return EMPTY;
              })
            )
        })
      )
      .subscribe(() => {
        //We have successfully purchased the IPSAT!
        // Unfortunately, our authentication token does not reflect our fancy new 'participant'
        //  role, so we must "refresh" the auth token so that we will be routed to the right
        //  onboarding page and have access to the information we need once we get there.
        this.authService.refresh().pipe(
          tap(() => {
            // We have now successfully purchased and upgraded to a participant account. Redirect to /
            //  and the existing logic should take care of the rest.
            this.router.navigate(['/']);
          }), catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null)
          })
        ).subscribe();
      });
  }

  /**
   * nextStep()
   *
   * Handles moving the user to the next step (WITHIN THE MODULE).
   *  Calls the handleNextStep() function if the user is on the last step.
   *  ie: moving from learning_assessment to engagement
   */
  nextStep(): void {
    const currentStepIndex = this.moduleSteps.indexOf(this.currentStep);
    if (currentStepIndex < this.moduleSteps.length - 1) {
      this.currentStep = this.moduleSteps[currentStepIndex + 1];
    } else {
      this.handleNextStep.emit();
    }
  }

  /**
   * previousStep()
   *
   * Handles moving the user to the previous step (WITHIN THE MODULE).
   */
  previousStep(): void {
    const currentStepIndex = this.moduleSteps.indexOf(this.currentStep);
    if (currentStepIndex > 0) {
      this.currentStep = this.moduleSteps[currentStepIndex - 1];
    }
  }

  /**
   * mapContinueButtonTextForStep()
   *
   * Maps text for the continue buttons given the current step of the module the user
   *  is on.
   */
  mapContinueButtonTextForStep(): string | null {
    switch (this.currentStep) {
      case 'engagement':
        return 'Learn More & Access Modules 2-8';
      case 'included_items':
        return 'Purchase';
      default:
        return null;
    }
  }

  /**
   * validateCandidateDiscountCode()
   *
   * validates the coupon code using a backend endpoint then assigns the name
   *  value to teamReportDiscountName (from candidateTeamReportDiscountName)
   *
   * @returns {*}
   */
  validateCandidateDiscountCode(): void {
    const uppercasedDiscountCode = this.candidateDiscountCodeName.toUpperCase();

    this.directService.getPricing({
      user_id: this.pricingConfig.userId,
      clifton_strengths_assessment: this.pricingConfig.cliftonStrengthsAssessment,
      onboarding_experience_identifier: this.pricingConfig.onboardingExperienceIdentifier,
      discount_code_name: uppercasedDiscountCode
    }).pipe(
      tap(() => {
        this.pricingConfig.discountCodeName = uppercasedDiscountCode;
        this.handlePricingConfigChange();
      }), catchError((err) => {
        if (err.status === 422) {
          this.errorHandlerService.handleError({
            message: 'Sorry, that discount code is not valid for this IPSAT configuration. Is Clifton Strengths included with this discount code?',
            status: 422
          });
        } else {
          this.errorHandlerService.handleError(err);
        }
        return of(null);
      })
    ).subscribe();
  }

  clearDiscountName(): void {
    this.pricingConfig.discountCodeName = null;
    this.handlePricingConfigChange();
  }

  /**
   * viewModuleInformation()
   *
   * Opens a modal that displays additional information about each module.
   *
   * @param {string} moduleIdentifier - An identifier for a module that determines which modal copy to render.
   */
  viewModuleInformation(moduleIdentifier: string): void {
    this.modalService.show(NexleaderEcourseInformationModalComponent, {
      initialState: {
        ecourseModule: moduleIdentifier
      }
    });
  }
}
