<div class="hpanel">
  <div class="panel-body">
    <div class="m-b-md">
      <app-nexleader-course-content-file-upload
        *ngIf="!courseContent.blob"
        [courseContentId]="courseContent._id"
        (onUploadFile)="onUploadFile.emit()"
      ></app-nexleader-course-content-file-upload>
    </div>
    <div *ngIf="courseContent.blob">
      <!-- TODO test image -->
      <img
        [src]="courseContent.blob.path"
        alt="course content image"
        class="w-100 m-b-sm"
      />
      <a
        class="font-bold text-muted"
        *ngIf="canEditCourseContent"
        (click)="handleRemoveImage()"
        ><i class="fa fa-trash"></i> Remove Image</a
      >
    </div>
    <div *ngIf="!editingCourseContent">
      <h4 class="text-primary-2 m-b-sm">{{ courseContent.contentName }}</h4>
      <p>{{ courseContent.contentDescription }}</p>
      <hr />
      <h5 class="text-primary-2 m-b-sm">What You Will Learn</h5>
      <p style="white-space: pre-wrap">{{ courseContent.courseObjectives }}</p>
      <div *ngIf="canEditCourseContent">
        <hr />
        <h5 class="text-primary-2 m-b-sm">Tags</h5>
        <span
          class="label label-default m-r-sm"
          *ngFor="let tag of courseContent.tags; let i = index"
          >{{ tag }}</span
        >
      </div>
      <hr />
      <div class="flex flex-vertical-center">
        <a
          [href]="courseContent.contentUrl"
          target="_blank"
          class="btn btn-success"
          >View Course</a
        >
        <div class="flex-grow"></div>
        <h4 class="text-primary-2">
          Cost:
          <span class="text-primary">{{ courseContent.coursePrice }}</span>
        </h4>
        <div *ngIf="canEditCourseContent" class="m-l-md">
          <a class="font-bold text-primary m-r-sm" (click)="editCourseContent()"
            ><i class="fa fa-pencil"></i> Edit</a
          >
          <a
            class="font-bold text-muted"
            (click)="handleRemoveCourseContent.emit()"
            ><i class="fa fa-trash"></i> Remove</a
          >
        </div>
      </div>
    </div>
    <div *ngIf="editingCourseContent">
      <h5 class="text-primary-2 m-t-md">Edit Content</h5>
      <hr />
      <app-nexleader-course-content-input
        [courseContent]="courseContent"
      ></app-nexleader-course-content-input>
      <button
        class="float-right btn btn-success"
        (click)="handleSaveCourseContent.emit()"
        [attr.disabled]="!hasValidFormInput(courseContent) ? true : null"
      >
        Save Content
      </button>
    </div>
  </div>
</div>
