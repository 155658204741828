/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailService } from '../../../../services/email.service';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { NexleaderEditEmailsComponent } from '../edit-emails/edit-emails.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-edit-application-emails',
  standalone: true,
  imports: [CommonModule, NexleaderEditEmailsComponent],
  templateUrl: './edit-application-emails.component.html',
})
export class NexleaderEditApplicationEmailsComponent implements OnInit {
  emails: any[] = [];

  constructor(
    private emailService: EmailService,
    private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    const queryParams = this.route.snapshot.queryParams;
    this.emailService
      .getEmail(queryParams['email'])
      .pipe(
        tap((emails) => {
          this.emails = emails;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEditApplicationEmailsComponent',
//   downgradeComponent({
//     component: NexleaderEditApplicationEmailsComponent,
//   }) as angular.IDirectiveFactory
// );
