<div style="padding: 35px">
  <div class="text-center m-b-md">
    <h3 class="font-bold text-primary-2">{{ title }}</h3>
    <h5>{{ description }}</h5>
  </div>
  <div class="text-center font-bold" *ngIf="!isEmailEditable">
    {{ email }}
  </div>
  <div id="link-authentication-element">
    <!--Stripe.js injects the Email Element-->
  </div>
  <div id="stripe-elements" class="stripe-custom-input m-b-md m-t-md"></div>
  <button class="btn btn-success m-t-sm" style="width: 100%" (click)="pay()">
    PAY
  </button>
</div>
