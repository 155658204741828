<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <app-nexleader-quick-view
          [model]="coachParticipants"
          [tableHeaders]="['Name', 'Email', 'Coach']"
          [tableColumns]="['_name', 'email', '_coach']"
          [label]="'Users'"
          [overrideDisplayNewElement]="true"
          (onElementSelect)="selectedUser = $event"
        >
          <div *ngIf="selectedUser?._id; else notSelected">
            <app-nexleader-user-card
              [userId]="selectedUser._id"
              [currentViewingRole]="'coach'"
            ></app-nexleader-user-card>
          </div>
          <ng-template #notSelected>
            <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
          </ng-template>
        </app-nexleader-quick-view>
      </div>
      <div class="col-md-4 text-center" *ngIf="evaluationResults.length > 0">
        <app-nexleader-net-promoter-box
          *ngIf="!toggleGlobalNPS"
          [score]="netPromoter?.score"
          [class]="netPromoter?.class"
        ></app-nexleader-net-promoter-box>
        <app-nexleader-net-promoter-box
          *ngIf="toggleGlobalNPS"
          [score]="globalNPS?.score"
          [class]="globalNPS?.class"
        ></app-nexleader-net-promoter-box>
        <div class="form-group">
          <div class="checkbox">
            <input
              type="checkbox"
              [(ngModel)]="toggleGlobalNPS"
              id="globalNPSToggle"
            />
            <label for="globalNPSToggle" class="control-label"
              >Toggle Global NPS</label
            >
          </div>
        </div>
        <p *ngIf="toggleGlobalNPS">
          The global NPS is the calculated NPS for all groups that the coach is
          in.
        </p>
      </div>
      <div
        class="col-md-8"
        [ngClass]="{ 'is-hidden': !evaluationResults.length }"
      >
        <div class="hpanel">
          <div class="panel-body">
            <p><b>Net Promoter Score Distribution</b></p>
            <br />
            <canvas id="netPromoterChart"></canvas>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="evaluationResults.length === 0">
        <p>
          <i
            >Coach has not received enough feedback to calculate a Net Promoter
            score.</i
          >
        </p>
      </div>
      <div class="col-md-6">
        <div class="hpanel">
          <div class="panel-body">
            <div class="btn-group pull-right">
              <button
                class="btn"
                [ngClass]="{
                  'btn-primary': evaluationResultsFilter === 'lastName',
                  'btn-default': evaluationResultsFilter !== 'lastName'
                }"
                (click)="filterAlphabetically()"
              >
                <i class="fa fa-sort-alpha-asc"></i>&nbsp;&nbsp;Alphabetical
              </button>
              <button
                class="btn btn-default"
                [ngClass]="{
                  'btn-primary': evaluationResultsFilter === '',
                  'btn-default': evaluationResultsFilter !== ''
                }"
                (click)="filterByCreation()"
              >
                <i class="fa fa-calendar"></i>&nbsp;&nbsp;Creation Date
              </button>
            </div>
            <div class="m-b-md">
              <h5>Your Coach Evaluation Results</h5>
            </div>
            <table class="table" *ngIf="evaluationResults.length > 0">
              <thead>
                <th><i class="fa fa-user"></i>&nbsp;&nbsp;Participant Name</th>
                <th><i class="fa fa-briefcase"></i>&nbsp;&nbsp;Coach Name</th>
                <th><i class="fa fa-eye"></i>&nbsp;&nbsp;View</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let evaluationResult of evaluationResults
                      | orderBy : evaluationResultsFilter
                  "
                >
                  <td>
                    {{ evaluationResult.user?.firstName }}
                    {{ evaluationResult.user?.lastName }}
                  </td>
                  <td>
                    {{ evaluationResult.coach?.firstName }}
                    {{ evaluationResult.coach?.lastName }}
                  </td>
                  <td>
                    <a [routerLink]="['/evaluationResults', evaluationResult._id]"
                      >Link</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <p *ngIf="evaluationResults.length === 0">
              No coach evaluation results yet.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'Total Participants'"
          [value]="stats?.totalParticipants"
          [icon]="'users'"
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'Total Sessions'"
          [value]="stats?.totalSessions"
          [icon]="'calendar'"
        ></app-nexleader-statistic>
      </div>
    </div>
  </div>
</div>
