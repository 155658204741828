/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderWrapperPanelComponent } from '../../../core/components/wrapper-panel/wrapper-panel.component';
import { NexleaderSessionsComponent } from '../sessions/sessions.component';
import { GroupService } from '../../../../services/group.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-group-calendar',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderWrapperPanelComponent,
    NexleaderSessionsComponent,
  ],
  templateUrl: './group-calendar.component.html',
})
export class NexleaderGroupCalendarComponent implements OnInit {
  routeParams: any;
  group: any;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadGroup();
  }

  private loadGroup(): void {
    this.groupService
      .get(this.routeParams['group_id'])
      .pipe(
        tap((group) => {
          this.group = group;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupCalendarComponent',
//   downgradeComponent({
//     component: NexleaderGroupCalendarComponent,
//   }) as angular.IDirectiveFactory
// );
