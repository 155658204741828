/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NexleaderMaximumWordsValidationDirective } from '../../../../directives/max-words.directive';
import { TabsModule } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-nexleader-tabs',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderMaximumWordsValidationDirective,
    FormsModule,
    TabsModule,
  ],
  templateUrl: './tabs.component.html',
})
export class NexleaderTabsComponent implements OnInit {
  @Input() items: any[] = [];
  @Output() save = new EventEmitter();

  selectedItem: any;
  editing = false;

  ngOnInit(): void {
    if (this.items.length) {
      this.selectItem(this.items[0]);
    }
  }

  selectItem(item: any): void {
    this.selectedItem = item;
  }

  toggleEditing(): void {
    this.editing = !this.editing;
  }

  saveTab(): void {
    this.editing = false;
    this.save.emit();
  }
}
