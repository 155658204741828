<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="form-group">
  <label class="control-label">Roles</label>
  <div *ngFor="let role of getAvailableRoles(); let i = index">
    <label for="{{ role._id }}" class="role-label">
      <input
        id="{{ role._id }}"
        type="checkbox"
        [value]="role._id"
        [checked]="model.includes(role._id)"
        (change)="onRoleChange(role._id)"
      />
      &nbsp;&nbsp;{{ role.name }}
    </label>
    <br />
  </div>
</div>
