<div>
  <p class="m-b-sm hideNonPrint">
    <b>Team Discussion Questions - {{ header }}</b>
  </p>
  <h4 class="font-bold m-b-sm text-primary hideOnPrint" *ngIf="!hideHeader">
    Team Discussion Questions
  </h4>
  <ul>
    <li *ngFor="let question of questions" class="m-b-sm m-b-xl-print">
      {{ question }}
    </li>
  </ul>
</div>
