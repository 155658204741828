/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-error',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './error.component.html'
})
export class NexleaderErrorComponent {
  error: any;

  constructor(private errorHandlerService: ErrorHandlerService) {
    this.error = this.errorHandlerService.error;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderErrorComponent',
//   downgradeComponent({
//     component: NexleaderErrorComponent,
//   }) as angular.IDirectiveFactory
// );
