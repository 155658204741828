<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div *ngIf="teamReports?.length < 1">No team reports to display.</div>
    <div *ngIf="teamReports?.length > 0" class="row">
      <div
        *ngFor="let teamReport of teamReports"
        class="col-md-4 col-sm-6 col-xs-12"
      >
        <div class="hpanel">
          <div class="panel-body">
            <h4 class="nexleader-test-team-report-list-view-name">
              {{ teamReport.teamName }}
            </h4>
            <a
              [routerLink]="['/groups', teamReport.group._id, 'teamReports', teamReport._id]"
              id="nexleader-test-team-report-list-view-report-btn-{{
                teamReport._id
              }}"
              >View Team Report</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
