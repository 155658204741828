/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderArchivedSmallGroupsTable
 *
 * component
 *
 * displays a table representing a set of small groups that have been archived by
 *  the current user (CoachUser or coach)
 *
 * may mutate of the state of groups via two-way binding
 *  the unarchive button may unarchive groups
 *
 * NOT: THIS TABLE DOES NOT ASSUME ALL USERS PASSED VIA BINDING ARE ARCHIVED
 *  WE FILTER INSIDE THE CONTROLLER
 *
 * changes are saved automatically via the resource service
 *
 * this must be a component because we're going to re-implement the "Your Small Groups" view for
 *  multigroup coaches
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../services/enum.service';
import { SmallGroupService } from '../../resources/small-group.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-archived-small-groups-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './archived-small-groups-table.component.html',
})
export class NexleaderArchivedSmallGroupsTableComponent implements OnInit {
  @Input() smallGroups: any[] = [];

  constructor(
    private enums: EnumsService,
    private smallGroupService: SmallGroupService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  /**
   * $onInit()
   *
   * function: angular event handler
   *  called after bindings load
   *
   * validate that the bindings loaded and are the correct type
   */
  ngOnInit(): void {
    // Validate the type of smallGroups array
    if (!Array.isArray(this.smallGroups)) {
      throw new Error(
        'nexleaderArchivedSmallGroupsTable smallGroups field must be an array'
      );
    }
  }

  /**
   * getArchivedSmallGroups()
   *
   * Function to return all groups that are archived.
   * Used to display the table of archived groups for this view.
   * This is a function because setArchivedAndSave mutates the state of this.smallGroups when archiving.
   *
   * Returns null if not loaded.
   */
  getArchivedSmallGroups(): any[] {
    if (!this.smallGroups) {
      return [];
    }
    return this.smallGroups.filter((smallGroup) => smallGroup.archived);
  }

  /**
   * setArchivedAndSave()
   *
   * Function to archive or unarchive a small group and call save() via the resource API.
   *
   * @param smallGroup Mutable copy of the smallGroup we are editing.
   * @param archived True if we are archiving a group, false if we are unarchiving a group.
   */
  setArchivedAndSave(smallGroup: any, archived: boolean): void {
    smallGroup.archived = archived;
    this.smallGroupService
      .save(smallGroup)
      .pipe(
        tap((response) => {
          this.successService.handle();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
