/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderVimeoVideoComponent } from '../../../small-groups/components/vimeo-video/vimeo-video.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderEcourseLandingModalComponent } from '../../../core/components/modals/ecourse-landing-modal/ecourse-landing-modal.component';
import { NexleaderEcourseInformationModalComponent } from '../../../core/components/modals/ecourse-information-modal/ecourse-information-modal.component';

@Component({
  selector: 'app-nexleader-ecourse-landing',
  standalone: true,
  imports: [CommonModule, NexleaderVimeoVideoComponent, RouterLink],
  templateUrl: './ecourse-landing.component.html'
})
export class NexleaderEcourseLandingComponent implements OnInit {
  queryParams: any;
  /**
   * availableModules
   * 
   * All available modules that the user can view additional information for.
   */
  public availableModules = [
    {
      name: 'Module 1',
      identifier: 'module1',
      descriptionLine1: 'The Art of Self-Leadership and the Power of Self-Awareness',
      descriptionLine2: 'How self-awareness serves as a primary gateway to self-leadership.',
      icon: 'module1'
    },
    {
      name: 'Module 2',
      identifier: 'module2',
      descriptionLine1: 'Exploring Your Personality (includes assessment based on the Big Five Model)',
      descriptionLine2: 'A look at your balcony and basement, and why you need to understand it.',
      icon: 'personality'
    },
    {
      name: 'Module 3',
      identifier: 'module3',
      descriptionLine1: 'Discovering Your Strengths (includes Clifton Strengths Assessment)',
      descriptionLine2: 'How your preferred neurological pathways position you to excel in specific ways.',
      icon: 'strengths'
    },
    {
      name: 'Module 4',
      identifier: 'module4',
      descriptionLine1: 'Developing Your Skills (includes Skills Inventory)',
      descriptionLine2: 'How personality and strengths work together to supercharge the skill building process.',
      icon: 'skills'
    },
    {
      name: 'Module 5',
      identifier: 'module5',
      descriptionLine1: 'Refining Your Personal Values (includes Personal Values Inventory)',
      descriptionLine2: 'Becoming value-driven and the critical role personal values play in building self-control',
      icon: 'spiritualgifts'
    },
    {
      name: 'Module 6',
      identifier: 'module6',
      descriptionLine1: 'Uncovering Your Passions (includes MyPassionProfile)',
      descriptionLine2: 'The two streams of passion potential in everyone, and how passion shapes the way we engage in causes we care about.',
      icon: 'passions'
    },
    {
      name: 'Module 7',
      identifier: 'module7',
      descriptionLine1: 'Discovering your Identity Profile with IPSAT (includes one-on-one coaching session)',
      descriptionLine2: 'Put the building blocks of your Identity Profile together using the Identity Self-Awareness Tool (IPSAT) in a capstone a self authoring exercise.',
      icon: 'module7'
    },
    {
      name: 'Module 8',
      identifier: 'module8',
      descriptionLine1: 'Using Self-Awareness and Self-Leadership to Bring Your Best to School, Work, and Life (includes one-on-one coaching session)',
      descriptionLine2: 'How this journey of self-awareness and self- leadership can enable you to bring your best to school, work and life through a Self leadership Action Plan.',
      icon: 'module8'
    }
  ];

  public faqs: any = [
    {
      question: 'What is the Identity Profile Self-Awareness Tool (IPSAT)?',
      answer: 'The IPSAT is not a new assessment. It is a process designed to help you gain the maximum value from existing assessments/ resources with a specific focus on your Identity Profile, the unique combination of personality, strengths, skills, personal values and passions. The IPSAT process is included in this online course.'
    },
    {
      question: 'What if I’ve already taken some of the assessments included in this process?',
      answer: 'You don’t have to retake assessments you have already completed as long as you still have the results.'
    },
    {
      question: 'Are all the assessments and coaching sessions included in the price?',
      answer: 'Yes, there are no hidden fees for the assessments, the IPSAT or the coaching.'
    },
    {
      question: 'What is included with each of the eight Modules?',
      answer: 'Each Module has an interactive video lesson and Note Guide. Modules 2-6 include a specific assessment, Module 7 includes the IPSAT and a coaching session, Module 8 includes a final coaching session.'
    },
    {
      question: 'What do you mean by interactive video lesson?',
      answer: 'The videos utilize a proprietary technology that enables you to engage the content on your schedule but interact with others as if you were watching at the same time.'
    },
    {
      question: 'Who will be doing my coaching session?',
      answer: 'You will meet virtually with an experienced and certified IPSAT coach whose performance is monitored closely to ensure a high quality experience.'
    },
    {
      question: 'How long does it take to complete this course?',
      answer: 'We suggest you complete one module per week and complete the process in a few weeks. But you can proceed at your own pace and schedule. Just logout and login and you’ll come right back to where you left off.'
    },
    {
      question: 'How much does this cost?',
      answer: 'The all-inclusive cost is $795 but there may be a special offer happening now. Check the full price info button below for all the details.'
    }
  ];

  coachId!: string;
  groupId!: string;
  hasCoachOrGroupId: boolean = false;

  constructor(private route: ActivatedRoute, private modalService: BsModalService) { }

  /**
   * $onInit()
   *
   * angular event handler function
   *
   * called when the component binding is complete
   */
  ngOnInit(): void {
    this.queryParams = this.route.snapshot.queryParams;

    this.coachId = this.queryParams['coach_id'];
    this.groupId = this.queryParams['group_id'];
    this.hasCoachOrGroupId = !!this.coachId || !!this.groupId;
    if (this.coachId && this.groupId) {
      throw new Error('Cannot have coach and group_id. group_id will take precedent');
    }
  }

  viewAdditionalInformationModal(identifier: string): void {
    const modalRef = this.modalService.show(
      NexleaderEcourseLandingModalComponent,
      {
        initialState: { identifier: identifier },
      }
    );
  }

  /**
   * viewQuestion()
   * 
   * Toggles the visibility of an individual question.
   * 
   * @param {number} index - An number to change the faq of. 
   */
  viewQuestion(index: number): void {
    this.faqs[index].isVisible = !this.faqs[index].isVisible;
  }

  /**
   * viewModuleInformation()
   * 
   * Opens a modal that displays additional information about each module.
   * 
   * @param {string} moduleIdentifier - An identifier for a module that determines which modal copy to render.
   */
  viewModuleInformation(moduleIdentifier: string): void {
    const modalRef = this.modalService.show(
      NexleaderEcourseInformationModalComponent,
      {
        initialState: { ecourseModule: moduleIdentifier },
      }
    );
  }

  getDirectSignupLink(): string {
    if (this.groupId) {
      return `/#/direct/signup?onboarding_experience_identifier=ecourse&group_id=${this.groupId}`;
    } else if (this.coachId) {
      return `/#/direct/signup?onboarding_experience_identifier=ecourse&coach_id=${this.coachId}`;
    } else {
      return '/#/direct/signup?onboarding_experience_identifier=ecourse&survey_type_identifier=values_based';
    }
  }

  getPricingLink(): string {
    if (this.groupId) {
      return `/#/direct/ecourse/pricing?group_id=${this.groupId}`;
    } else if (this.coachId) {
      return `/#/direct/ecourse/pricing?coach_id=${this.coachId}`;
    } else {
      return '/#/direct/ecourse/pricing';
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEcourseLandingComponent',
//   downgradeComponent({
//     component: NexleaderEcourseLandingComponent,
//   }) as angular.IDirectiveFactory
// );
