<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Share Your Team Report</h3>
</div>
<div class="modal-body">
  <p>
    The IPSAT Team Report can be shared with anyone who has an IPSAT account and
    is part of your group. <br /><br />
    Please use the print your report link to create a PDF that can be shared
    with people who do not have an IPSAT account or are not part of your IPSAT
    group.
  </p>
  <br />
  <div class="form-group">
    <label class="control-label">Share this link with someone</label>
    <app-nexleader-copy-link
      [relativeLink]="shareableLink"
    ></app-nexleader-copy-link>
  </div>
</div>
<div class="modal-footer">
  <div class="pull-right">
    <button class="btn btn-default" (click)="close()">Close</button>
  </div>
</div>
