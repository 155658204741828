/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CourseContentService {
  constructor(private http: HttpClient) {}

  getCourseContent(courseContentId?: string): Observable<any> {
    return this.http.get(
      courseContentId
        ? `${API_BASE}courseContent/${courseContentId}`
        : `${API_BASE}courseContent`,
      {
        withCredentials: true,
      }
    );
  }

  saveCourseContent(course: any, courseContentId?: string): Observable<any> {
    return this.http.post(
      courseContentId
        ? `${API_BASE}courseContent/${courseContentId}`
        : `${API_BASE}courseContent`,
      course,
      {
        withCredentials: true,
      }
    );
  }

  removeCourseContent(courseContentId: string): Observable<any> {
    return this.http.delete(`${API_BASE}courseContent/${courseContentId}`, {
      withCredentials: true,
    });
  }

  getThumbnails(): Observable<any> {
    return this.http.get(`${API_BASE}blobs/courseContent/blobs`, {
      withCredentials: true,
    });
  }
}
