<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="nexleader-onboarding-vertical-fill nexleader-onboarding-take-ipsat bg-white"
  id="nexleader-test-pvi-take-pvi">
  <div class="nexleader-onboarding-half-layout-side">
    <div class="measure m-t-lg m-b-lg">
      <div class="text-center m-b-lg">
        <img src="/assets/images/icons/spiritualgifts.svg" alt="Personal Values Inventory icon" class="img-sm" />
        <h2>Personal Values Inventory</h2>
      </div>
      <hr />
      <b class="m-t-sm">
        Estimated time to complete the Personal Values Inventory: 30 minutes
      </b>
      <hr />
      <p>
        Your life is impacted by your values, even if you can’t explain what
        they are. Personal values reveal what you believe is important in life.
        Since your intelligence and strengths are value neutral, the only way to
        ensure you contribute to the common good is to define clear boundaries
        in the form of personal values.
        <br /><br />
        Values form the river banks for our decisions and actions. Sometimes we
        choose and act in ways that are inconsistent with our values, producing
        negative emotions that erode the river banks of our lives. You can only
        evaluate your values, to determine if changes are in order, after you
        have made them explicit.
        <br /><br />
        Sometimes when identifying personal values, we discover a gap between
        aspirational values (ideal) and operational values (actual).
        Articulating personal values enables us to live more intentionally and
        increase the alignment between ideal and actual.
      </p>
      <hr />
      <p class="m-b-sm">
        <b>Tips for taking the Personal Values Inventory</b>
      </p>
      <ul class="m-b-md">
        <li>
          If you exit the Personal Values Inventory before submitting your
          answers, they will not be saved.
        </li>
      </ul>
      <hr />
      <a class="btn btn-block btn-success" id="nexleader-test-pvi-take-pvi-btn" (click)="go()">
        <i class="fa fa-diamond"></i>
        &nbsp;&nbsp;Take the Personal Values Inventory
      </a>

      <div class="text-center">
        <hr />
        Not ready to continue? <a (click)="goBack()">Go Back</a>
      </div>
    </div>
  </div>
</div>