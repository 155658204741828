/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import {
  AngularEditorConfig,
  AngularEditorModule,
} from '@kolkov/angular-editor';
import { EnumsService } from '../../../../services/enum.service';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { EmailService } from '../../../../services/email.service';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderAddUnformattedTextComponent } from '../../components/modals/add-unformatted-text/add-unformatted-text.component';

@Component({
  selector: 'app-nexleader-edit-emails',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FilterPipe,
    NgModelDebounceChangeDirective,
    AngularEditorModule,
  ],
  templateUrl: './edit-emails.component.html',
})
export class NexleaderEditEmailsComponent implements OnInit {
  @Input() emails: any[] = [];
  @Input() emailScope: string = '';
  Enums: any;
  emailTypes: any[] = [];
  selectedEmailType: any;
  config: AngularEditorConfig = {
    editable: true,
    minHeight: '300px',
    sanitize: false,
    toolbarHiddenButtons: [
      ['subscript', 'superscript', 'textColor', 'backgroundColor'],
    ],
  };
  constructor(
    private modalService: BsModalService,
    private errorHandlerService: ErrorHandlerService,
    private emailService: EmailService,
    private successService: SuccessService,
    private enumsService: EnumsService
  ) { }

  ngOnInit() {
    if (!this.emailScope) {
      this.emailScope = 'group';
    }
    this.load();
  }

  load() {
    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.Enums = enums;
          this.emailTypes = enums.AllEmailTypes.filter(
            (emailType: any) => emailType.scope === this.emailScope
          );
          this.selectEmailType(this.emailTypes[0]);
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  selectEmailType(emailType: any) {
    this.selectedEmailType = emailType;
  }

  saveEmail(email: any) {
    this.emailService
      .save(email)
      .pipe(
        tap((res) => {
          this.successService.handle();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  emailTextUpdated(email: any) {
    let html = email.body;
    //Replace all escaped '}' and '{' characters with the real deal whenever somebody pastes
    //Poor man's replace all
    html = html.split('%7B').join('{');
    html = html.split('%7D').join('}');

    //When links containing {{{baseUrl}}} get copied, the browser appends the site url in front
    //Even though {{{baseUrl}}} will resolve to the same thing. This removes the extra bit
    //added by the browser
    if (html.indexOf('{{{baseUrl}}}')) {
      html = html.replace(
        /href="[^"]*(?:\{\{\{baseUrl\}\}\})/,
        'href="{{{baseUrl}}}'
      );
    }

    if (email.body !== html) {
      email.body = html;
    }
  }

  addWordText() {
    this.modalService.show(NexleaderAddUnformattedTextComponent, {
      animated: true,
    });
  }

  deleteEmail(email: any) {
    this.emailService
      .remove(email._id)
      .pipe(
        tap(() => {
          this.load();
          this.successService.handle();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
