/*global angular */

/**
 * nexleaderCoachSmallGroups
 *
 * component
 *
 * "Your Small Groups" view for coaches
 *
 * displays a set of cards for each small group this coach is a part of that is not archived
 *  archived groups are displayed in a table at the bottom of the page
 *
 * links are provided to open modals to edit small groups
 * buttons are provided to archive/unarchive small groups
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { SmallGroupService } from '../../resources/small-group.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderSmallGroupCardComponent } from '../../components/small-group-card/small-group-card.component';
import { NexleaderArchivedSmallGroupsTableComponent } from '../../components/archived-small-groups-table/archived-small-groups-table.component';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';

@Component({
  selector: 'app-nexleader-coach-small-groups',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NexleaderSmallGroupCardComponent,
    NexleaderArchivedSmallGroupsTableComponent,
  ],
  templateUrl: './coach-small-groups.component.html',
})
export class NexleaderCoachSmallGroupsComponent implements OnInit {
  routeParams: any;
  smallGroups: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private smallGroupService: SmallGroupService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  /**
   * load()
   *
   * function: mutates internal state
   *
   * called by ngOnInit and by onSmallGroupChange
   */
  load(): void {
    // Query all small groups that belong to this user
    this.userService
      .querySmallGroupsIndex(this.routeParams['user_id'])
      .pipe(
        tap((smallGroups) => {
          this.smallGroups = smallGroups;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * getActiveSmallGroups()
   *
   * function
   *
   * returns all groups that are not archived; used to display the small group cards for this view
   * this is a function because the setArchivedAndSave mutates the state of this.smallGroups when archiving
   *
   * returns null if not loaded
   */
  getActiveSmallGroups(): any[] {
    if (!this.smallGroups) return [];
    this.smallGroups.sort();
    return this.smallGroups.filter((smallGroup) => !smallGroup.archived);
  }

  /**
   * onSmallGroupChange()
   *
   * function: mutates component state; event handler
   *
   * called when a small group card reports a state change to us
   */
  onSmallGroupChange(): void {
    this.load();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCoachSmallGroupsComponent',
//   downgradeComponent({
//     component: NexleaderCoachSmallGroupsComponent,
//   }) as angular.IDirectiveFactory
// );
