<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="login-container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center m-b-md">
        <div class="text-center m-b-md">
          <img src="/assets/images/logo.svg" width="125" height="100" />
        </div>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <h3 class="text-center">Reset your password</h3>
          <br />
          <p class="text-danger">{{ message }}</p>
          <br />
          <form (ngSubmit)="resetPassword()" #resetForm="ngForm">
            <div class="form-group">
              <label class="control-label">Password</label>
              <input type="password" placeholder="******" class="form-control" [(ngModel)]="password" name="password"
                required />
            </div>
            <div class="form-group">
              <label class="control-label">Confirm your password</label>
              <input type="password" placeholder="******" class="form-control" [(ngModel)]="confirmPassword"
                name="confirmPassword" required />
            </div>
            <button type="submit" class="btn btn-success btn-block" [attr.disabled]="!resetForm.valid ? true : null">
              Reset your password
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <strong>IPSAT</strong> <br />
      2017 Copyright nexleader
    </div>
  </div>
</div>