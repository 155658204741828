<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <ng-container *ngIf="myPassionProfileResults.length < 1">
      <p>No My Passion Profile results yet.</p>
    </ng-container>
    <div class="row equal-6" *ngIf="myPassionProfileResults.length > 0">
      <div
        class="col-md-6 col-xs-12 equal-child"
        *ngFor="let mppResult of myPassionProfileResults"
      >
        <div class="hpanel">
          <div class="panel-body">
            <h4 class="text-primary-2 m-b-sm">My Passion Profile Result</h4>
            <b>Top Three Passions:</b>
            <span
              *ngFor="
                let passion of mppResult.assessmentResult.passions;
                let last = last
              "
            >
              {{ passion.name }}<span *ngIf="!last">, </span>
            </span>
          </div>
          <div class="panel-footer flex flex-vertical-center">
            <span class="text-secondary font-semi-bold">
              Imported on {{ mppResult.createdAt | date }}
            </span>
            <div class="flex-grow"></div>
            <a
              class="btn btn-default"
              [routerLink]="['/users', mppResult.user, 'mppResults', mppResult._id]"
            >
              View result
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
