/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-nexleader-ecourse-landing-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ecourse-landing-modal.component.html'
})
export class NexleaderEcourseLandingModalComponent implements OnInit {
  modalSection: any;

  /**
   * identifier
   * 
   * A string representing the identifier for which modal to render.
   */
  identifier!: string;

  constructor(private bsModalService: BsModalService) { }

  modalSections: any = {
    videos: {
      title: 'Interactive Video Lessons',
      description: 'Course videos use the Rali platform to include prompts for reflection questions you add to the video feed. You can also comment on the video or the responses of others. This unique platform allows you to engage on your schedule and interact with the feedback of others regardless of when they watch.'
    },
    assessments: {
      title: 'Bundle of Assessments',
      description: 'Self-awareness is the gateway to self-leadership. You have to know yourself in order to lead yourself. We focus on your Identity Profile, the unique combination of personality, strengths, skills, personal values and passions. You will engage with five pre-IPSAT assessments as part of this journey (personality based on the Big Five, Clifton Strengths, Skills Inventory, Personal Values Inventory, and MyPassionProfile).'
    },
    ipsat: {
      title: 'Identity Profile Self-Awareness Tool (IPSAT)',
      description: 'The IPSAT is not a new assessment. It is a process designed to help you gain the maximum value from a bundle of assessments. We use a powerful self authoring process to help you write five short identity statements that explain how your personality, strengths, skills, values and passions work together in real life.'
    },
    coaching: {
      title: 'One-on-One Virtual Coaching Sessions',
      description: 'When you purchase the course you will be contacted by a certified IPSAT coach to schedule two virtual coaching sessions. The first session is 75 minutes and comes after Module 7, with a focus on processing the results of the Identity Profile Self-Awareness Tool (IPSAT). The second session is 60 min and comes after Module 8, with a focus on creating your self-leadership action plan.'
    }
  };

  ngOnInit(): void {
    this.modalSection = this.modalSections[this.identifier];
  }

  /**
   * close
   *
   * A close callback function to use
   *
   * ex: someFn()
   */
  close() {
    this.bsModalService.hide();
  }
}
