<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div
  *ngFor="let range of ranges; let last = last"
  [ngClass]="{ 'm-b-lg': !last }"
  class="nexleaderSummaryInputGroup"
>
  <label class="control-label">
    Summary text for a category score of {{ range.min || "N/A" }} to
    {{ range.max || "N/A" }}
  </label>
  <div class="nexleaderSummaryInputGroupInputs">
    <textarea
      type="text"
      class="form-control"
      [(ngModel)]="range.name"
      rows="5"
    ></textarea>
    <br />
    <div class="row">
      <div class="col-md-4">
        <div class="input-group">
          <div class="input-group-addon">Min</div>
          <input class="form-control" type="number" [(ngModel)]="range.min" />
        </div>
      </div>
      <div class="col-md-4 col-md-offset-4">
        <div class="input-group">
          <div class="input-group-addon">Max</div>
          <input class="form-control" type="number" [(ngModel)]="range.max" />
        </div>
      </div>
    </div>
  </div>
  <br />
  <a (click)="removeRange(ranges, range)" class="text-danger"
    ><i class="fa fa-minus"></i>&nbsp;&nbsp;Remove Range</a
  >
</div>
<br />
<a (click)="addRange(ranges)" class="text-success"
  ><i class="fa fa-plus"></i>&nbsp;&nbsp;New Range</a
>
