<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="content animate-panel">
    <h1>Oops, there was an error.</h1>
    <pre *ngIf="error">{{ error | json }}</pre>
    <br>
    <a [routerLink]="['/']">Head back home</a>
</div>