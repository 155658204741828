<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div *ngIf="!manualEntry">
  <h5 class="m-b-sm">Your Passions</h5>
  <table class="table passion-table" *ngIf="passions.length >= 1">
    <thead>
      <th>Passion</th>
      <th>Level</th>
    </thead>
    <tr *ngFor="let passion of passions">
      <td>{{ passion.name }}</td>
      <td>{{ passion.level?.name }}</td>
    </tr>
  </table>
  <p *ngIf="passions.length < 1">
    You have not selected any passions. Try importing from MyPassionProfile or
    entering them manually.
  </p>
  <h5 class="m-b-sm">Your Imported MyPassionProfile Assessments</h5>
  <table class="table" *ngIf="passionProfileResults.length >= 1">
    <thead>
      <th>Assessment Date</th>
      <th>Passions</th>
    </thead>
    <tr *ngFor="let assessment of passionProfileResults">
      <td>{{ assessment.createdAt | date }}</td>
      <td>
        <span
          *ngFor="
            let passion of assessment.scoredAssessmentResult.passions;
            let last = last
          "
          >{{ passion.name }}<span *ngIf="!last">, </span></span
        >
      </td>
    </tr>
  </table>
  <p *ngIf="passionProfileResults.length < 3" class="m-b-md">
    You have not imported <b>three</b> passions from MyPassionProfile.com. To
    continue on to the IPSAT, you must enter three passions. Importing the full
    assessment allows the IPSAT team report to include your domains of
    passionate engagement.
  </p>
  <app-nexleader-mpp-import [userId]="userId"></app-nexleader-mpp-import>
  <a
    style="margin-left: 3px"
    id="nexleader-test-edit-passions-manually"
    (click)="manualEntry = true"
    >Edit Passions Manually</a
  >
</div>
<div *ngIf="manualEntry">
  <h5 class="m-b-sm">Edit Passions Manually</h5>
  <div class="row" *ngFor="let i of [0, 1, 2]">
    <div class="form-group col-xs-6">
      <input
        type="text"
        class="form-control"
        [ngModel]="passions[i]?.name"
        placeholder="Passion"
        required
        [ngModelDebounce]="1000"
        (ngModelDebounceChange)="onNameChange($event, i)"
      />
    </div>
    <div class="form-group col-xs-6">
      <select
        class="form-control"
        name="passion{{ i }}"
        [ngModel]="passions[i]?.level || undefined"
        required
        (ngModelChange)="onLevelChange($event, i)"
        [compareWith]="compareName"
      >
        <option value="undefined">-- Select a level --</option>
        <option *ngFor="let option of levels" [ngValue]="option">
          {{ option.name }}
        </option>
      </select>
    </div>
  </div>
  <a (click)="manualEntry = false">Back</a>
</div>
