<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">About the Four Domains of Strength</h3>
</div>
<div class="modal-body">
  <p>
    Gallup has identified four domains of strength as follows:
    <br /><br />
    <b>Executing:</b> People with dominant strength in the Executing domain know
    how to make things happen. When you need someone to implement a solution,
    these people will work tirelessly to get it done. <br /><br />
    <b>Influencing:</b> People with dominant strength in the Influencing domain
    help their team reach a much broader audience. They want to take charge,
    speak up and make sure that the group is heard. <br /><br />
    <b>Relationship Building:</b> People with dominant strength in the
    Relationship Building domain are the essential glue that holds a team
    together. They can create groups that are much greater than the sum of their
    parts. <br /><br />
    <b>Strategic Thinking:</b> People with dominant strength in the Strategic
    Thinking domain keep everyone focused on what could be. They are constantly
    absorbing and analyzing information and helping the team make better
    decisions.
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-default" type="button" (click)="close()">Close</button>
</div>
