/* Copyright (C) nexleader - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
* Written for nexleader <myipsat.com>, 2016-2018
    */

/*global angular*/

/**
 * nexleaderSmallGroupVideoModal
 *
 * Displays a small group video in a modal.
 *
 * This component is responsible only for wrapping the SmallGroupVideo component in a modal.
 *  SmallGroupVideo handles mapping the internal identifier to a video id and passing it to the vimeo component.
 *  VimeoVideo handles displaying a video using vimeo embedding.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderSmallGroupVideoComponent } from '../../small-group-video/small-group-video.component';

@Component({
  selector: 'app-nexleader-small-group-video-modal',
  standalone: true,
  imports: [CommonModule, NexleaderSmallGroupVideoComponent],
  templateUrl: './small-group-video-modal.component.html'
})
export class NexleaderSmallGroupVideoModalComponent implements OnInit {
  /**
   * nexleaderDataVideoIdentifier
   *
   * the internal identifier of the small group video to display
   *
   * ex: 'module_7_max_value'
   */
  @Input() videoIdentifier!: string;

  constructor(private bsModalService: BsModalService) { }

  /**
   * $onInit()
   *
   * function: angular event handler
   *  called after bindings load
   *
   * validate that the bindings loaded and are the correct type
   */
  ngOnInit() {
    if (typeof this.videoIdentifier !== 'string') {
      throw new Error('nexleaderSmallGroupVideoModal requires string videoIdentifier property');
    }
  }

  /**
   * close
   *
   * A close callback function to use to cancel the editing of a small group.
   *
   * ex: someFn()
   */
  close() {
    this.bsModalService.hide();
  }

  /**
   * getTitleForVideoIdentifier()
   *
   * function: depends on internal controller state
   *
   * @returns {string} the human readable string title of that corresponds with the currently selected internal video identifier
   */
  getModalTitle(): string {
    return this.getTitleForVideoIdentifier(this.videoIdentifier);
  }

  /**
   * getTitleForVideoIdentifier()
   *
   * pure function
   *
   * @param identifier {string} the internal video identifier
   *
   * @returns string the title of a video that will be displayed in the video modal title
   */
  private getTitleForVideoIdentifier(identifier: string): string {
    switch (identifier) {
      case 'ipsat_promo_video':
        return 'IPSAT Promo Video';
      case 'ipsat_launch_video_1':
        return 'Launch Video One';
      case 'ipsat_launch_video_2':
        return 'Launch Video Two';
      case 'ipsat_launch_video_3':
        return 'Launch Video Three';
      case 'module_1_intro':
        return 'Module 1: Intro';
      case 'module_1_teaching':
        return 'Module 1: Teaching';
      case 'module_2_intro':
        return 'Module 2: Intro';
      case 'module_2_teaching':
        return 'Module 2: Teaching';
      case 'module_3_intro':
        return 'Module 3: Intro';
      case 'module_3_teaching':
        return 'Module 3: Teaching';
      case 'module_4_intro':
        return 'Module 4: Intro';
      case 'module_4_teaching':
        return 'Module 4: Teaching';
      case 'module_5_intro':
        return 'Module 5: Intro';
      case 'module_5_teaching':
        return 'Module 5: Teaching';
      case 'module_6_intro':
        return 'Module 6: Intro';
      case 'module_6_teaching':
        return 'Module 6: Teaching';
      case 'module_7_intro':
        return 'Module 7: Intro';
      case 'module_7_teaching':
        return 'Module 7: Teaching';
      default:
        throw new Error(`no smallGroup video id found for the internal video identifier: ${identifier}`);
    }
  }
}
