/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderInputPassions
 *
 * component to input passions to a user's prerequisites object
 *
 * binds to nexleader-data-passions array and mutates it with each user update
 *
 * ALLOWS USERS TO IMPORT FROM MPP
 *
 * also allows users to edit passions directly
 *
 * If a user imports from MPP, we will exit the IPSAT webapp, enter the MPP webapp, and return to the IPSAT webapp
 *  this could result in state loss, so watch out. This will directly update the user's prereqs in the database as
 *  a side-effect of the MPP import (this is done for UX and convenience)
 *
 * If the user only edits passions manually, we directly mutate the passions array and call nexleaderOnChange() so that
 *  a parent controller can save the changes.
 */
import { PassionProfileResultService } from './../../../../services/passion-profile-result.service';
import { EnumsService } from './../../../../services/enum.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NexleaderMppImportComponent } from '../mpp-import/mpp-import.component';

@Component({
  selector: 'app-nexleader-input-passions',
  standalone: true,
  imports: [
    CommonModule,
    NgModelDebounceChangeDirective,
    FormsModule,
    NexleaderMppImportComponent,
  ],
  templateUrl: './input-passions.component.html',
})
export class NexleaderInputPassionsComponent implements OnInit {
  /**
   * user_id
   *
   * string
   *
   * BIND BY REF
   *
   * the string user_id we're inputting passions for
   *  required because we need to load the passion results that we've already imported and
   *  possibly sign JWTs to import new ones
   */
  @Input() userId: string = '';

  /**
   * passions
   *
   * array
   *
   * BIND BY REF
   *
   * MUTATE
   *
   * InputPassions displays the contents of this array as "Your Passions". InputPassions also mutates this array
   *  if the user chooses to edit passions manually. If we mutate this array, we call nexleaderOnChange()
   */
  @Input() passions: any[] = [];

  /**
   * nexleaderOnChange
   *
   * angular expression
   *
   * called when we mutate passions, so a parent controller can save the user object that the passions array
   *  came from
   */
  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();

  /**
   * manualEntry
   *
   * toggles the view between "manual entry" (which has text fields and drop downs) and "MPP IMPORT" (which
   *  has an import from MPP button and a list of imported MPP assessments
   *
   * @type {boolean} true if manual entry should be displayed
   */
  manualEntry = false;
  levels: any[] = [];
  passionProfileResults: any[] = [];

  constructor(
    private enumsService: EnumsService,
    private passionProfileResultService: PassionProfileResultService
  ) { }

  /**
   * $onInit()
   *
   * function: angular event handler
   *
   * called after binding
   *
   * loads the Levels enum to populate dropdowns
   * loads the PassionProfileResults the user has already imported
   */
  ngOnInit(): void {
    this.enumsService
      .getEnums()
      .pipe(
        tap((a) => {
          this.levels = a.AllLevels;
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();

    this.passionProfileResultService
      .queryUserIndex(this.userId)
      .pipe(
        tap((a) => {
          this.passionProfileResults = a;
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }

  compareName(o1: any, o2: any) {
    if (o1?.name === o2?.name) return true;
    else return false;
  }

  onNameChange(value: string, index: number) {
    if (!this.passions[index]) {
      this.passions[index] = {};
    }
    this.passions[index].name = value;
    this.onChange.emit();
  }

  onLevelChange(value: any, index: number) {
    if (!this.passions[index]) {
      this.passions[index] = {};
    }
    this.passions[index].level = value;
    this.onChange.emit();
  }
}
