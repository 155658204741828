<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<app-nexleader-help-utility></app-nexleader-help-utility>

<div class="row nexleader-onboarding-h100 nexleader-onboarding-reverse-row">
  <div
    class="col-md-6 nexleader-onboarding-padded-container bg-white nexleader-onboarding-half-layout-side"
  >
    <div class="nexleader-onboarding-preassessments-padding">
      <form (ngSubmit)="signup()">
        <div class="col-md-12">
          <h2>
            Start your
            <span *ngIf="onboardingExperience?.identifier === 'ecourse'">
              journey to increased self-awareness and better self-leadership
            </span>
            <span *ngIf="onboardingExperience?.identifier === 'standard'">
              IPSAT journey
            </span>
            ...
          </h2>
          <div *ngIf="onboardingExperience?.identifier === 'standard'">
            <p class="m-t-md" *ngIf="referringCoach && !isEcourse">
              Follow these steps to start the {{ surveyType?.name }} IPSAT
              {{
                onboardingExperience?.identifier === "ecourse" ? "Ecourse " : ""
              }}
              with {{ referringCoach?.firstName }}
              {{ referringCoach?.lastName }}
            </p>
            <p class="m-t-md" *ngIf="!referringCoach && !isEcourse">
              Follow these steps to start the {{ surveyType?.name }} IPSAT
              {{
                onboardingExperience?.identifier === "ecourse" ? "Ecourse " : ""
              }}
            </p>
          </div>
        </div>

        <div class="m-t-md nexleader-onboarding-form-container">
          <div class="form-group col-lg-6">
            <label class="control-label">First Name</label>
            <input
              type="text"
              placeholder="John"
              class="form-control"
              [(ngModel)]="form.firstName"
              name="firstName"
            />
          </div>

          <div class="form-group col-lg-6">
            <label class="control-label">Last Name</label>
            <input
              type="text"
              placeholder="Smith"
              class="form-control"
              [(ngModel)]="form.lastName"
              name="lastName"
            />
          </div>

          <div class="form-group col-lg-12">
            <label class="control-label">Email</label>
            <input
              type="text"
              placeholder="john.smith@gmail.com"
              class="form-control"
              [(ngModel)]="form.email"
              name="email"
            />
          </div>

          <div class="form-group col-lg-12">
            <label class="control-label">Password</label>
            <input
              type="password"
              placeholder="********"
              class="form-control"
              [(ngModel)]="form.password"
              name="password"
            />
          </div>
        </div>

        <div class="form-group col-lg-12 m-t-lg">
          <input
            type="submit"
            class="btn btn-success nexleader-onboarding-full-button"
            [attr.disabled]="!isReadyToAdvance() ? true : null"
            value="Create my Account"
            id="nexleader-e2e-target-direct-signup-button"
          />
        </div>
      </form>
    </div>
  </div>
  <div
    class="col-md-6 h-100 align-items-center flex justify-content-center"
    [ngClass]="{
      'nexleader-ecourse-banner-wrapper':
        onboardingExperience?.identifier === 'ecourse'
    }"
  >
    <div class="p-v-50">
      <app-nexleader-custom-onboarding-card
        *ngIf="onboardingExperience"
        [groupId]="groupId"
        [onboardingExperience]="onboardingExperience"
      ></app-nexleader-custom-onboarding-card>
    </div>
  </div>
</div>
