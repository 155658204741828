/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { EMPTY, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../../../../services/user.service';
import { SkillsInventoryResultService } from '../../../../services/skills-inventory-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-skills-inventory-results',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './skills-inventory-results.component.html',
})
export class NexleaderSkillsInventoryResultsComponent implements OnInit {
  routeParams: any;
  user: any;
  skillsInventoryResults!: any[];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private skillsInventoryResultService: SkillsInventoryResultService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    const userId = this.routeParams['user_id'];

    forkJoin({
      user: this.userService.getUser(userId),
      skillsInventoryResults:
        this.skillsInventoryResultService.queryUserScores(userId),
    })
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((result) => {
        this.user = result.user;
        this.skillsInventoryResults = result.skillsInventoryResults;
      });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSkillsInventoryResultsComponent',
//   downgradeComponent({
//     component: NexleaderSkillsInventoryResultsComponent,
//   }) as angular.IDirectiveFactory
// );
