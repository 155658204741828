/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderNewUserModal
 *
 * angular directive: modal
 *
 * opened by the participant table component when clicking "Invite a new User"
 *  creates an invite object and sends a transactional email to the invited user
 *  the invited user then accepts the email and starts the onboarding process
 *
 * the invite object stores some user information to make signing up easier/possible
 *  for instance, the invite contains the group/smallGroup the user will be a part of and his/her email
 *
 * this component populates that information
 *
 * this component also displays an error message if the group does not have enough licenses to support the
 *  addition of this user
 */
import { SmallGroupService } from './../../../../small-groups/resources/small-group.service';
import { UserService } from './../../../../../services/user.service';
import { GroupService } from './../../../../../services/group.service';
import { ErrorHandlerService } from './../../../../../services/error-handler.service';
import { AuthService } from './../../../../../services/auth.service';
import { InviteService } from './../../../../../services/invite.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NexleaderInputRoleComponent } from '../../input-role/input-role.component';

@Component({
  selector: 'app-nexleader-new-user-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderInputRoleComponent],
  templateUrl: './new-user-modal.component.html',
})
export class NexleaderNewUserModalComponent implements OnInit {
  @Input() groupId: any;
  @Output() onSave = new EventEmitter<any>();

  routeParams: any;
  hasEnoughLicenses: boolean = true; //Assume we have enough licenses until the group loads and we know for sure
  invite: any = { roles: [], smallGroup: null, coach: null }; //Invite scaffolding
  placeUserInSmallGroup = false; //---Toggle for Assigning Coach Directly vs. Assigning Small Group---
  group: any;
  coaches: any[] = [];
  smallGroups: any[] = [];

  constructor(
    private inviteService: InviteService,
    private authService: AuthService,
    private groupService: GroupService,
    private userService: UserService,
    private smallGroupService: SmallGroupService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    //---Load Data---
    this.loadGroup();
    this.loadCoaches();
    this.loadSmallGroups();
  }

  loadGroup() {
    //Load the group so we know how many licenses are left
    this.groupService
      .get(this.groupId || this.routeParams['group_id'])
      .pipe(
        tap((group) => {
          this.group = group;
          //Once goup has loaded, find out if we have enough licenses left
          // display an error if we don't
          this.hasEnoughLicenses = this.group.remainingLicenses > 0;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  loadCoaches() {
    //Load the coaches for the coach dropdown
    this.userService
      .queryGroupRoleIndex(
        this.groupId || this.routeParams['group_id'],
        'coach'
      )
      .pipe(
        tap((coach) => {
          this.coaches = coach;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  loadSmallGroups() {
    //Load the small groups for the small group dropdown
    this.smallGroupService
      .queryGroupIndex(this.groupId || this.routeParams['group_id'])
      .pipe(
        tap((a) => {
          this.smallGroups = a;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  trackById(index: number, item: any) {
    return item._id;
  }

  enableSmallGroup(): void {
    this.placeUserInSmallGroup = true;
    this.invite.coach = null; //nullify the coach selection because small group will override it
  }

  disableSmallGroup(): void {
    this.placeUserInSmallGroup = false;
    this.invite.smallGroup = null; //nullify the samll group selection because we're assigning to a coach
    this.invite.coach = null;
  }

  /**
   * saveInvite()
   *
   * function
   *
   * performs last-minute processing before sending the $scope.invite object as a POST to the
   *  /invite endpoint
   *
   * @param inv
   */
  saveInvite(inv: any): void {
    //Copy the object in case there's an error
    // we don't wanna ruin internal state yet
    const req = { ...inv };

    //Copy the group id over
    req.group = this.group._id;
    req.licensingPartner = this.group.licensingPartner._id;

    //Reduce small group and coach to _ids before POSTing if a small group was selected
    if (this.invite.smallGroup) {
      req.coach = this.invite.smallGroup.smallGroupLeader._id;
      req.smallGroup = this.invite.smallGroup._id;
    }

    //send the POST to the backend
    // if it succeeds, close the modal
    // if there's an error, show a toast
    this.inviteService
      .save(req)
      .pipe(
        tap(() => {
          this.onSave.emit();
        }),
        catchError((error) => {
          // error.error = JSON.parse(error.error);
          this.errorHandlerService.handleError(
            error.error
          );
          return of(null);
        })
      )
      .subscribe();
  }

  shouldShowGroupInformation(): boolean {
    const payload = this.authService.getTokenPayload();
    if (payload && payload.roles) {
      return (
        payload.roles.indexOf('admin') >= 0 ||
        payload.roles.indexOf('partneradmin') >= 0
      );
    }
    return false;
  }
}
