/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewEcourseContent
 *
 * angular view
 *
 * After the Ecourse onboarding ends, ecourse participants need lifetime access to the course materials since this is
 *  one of the guarantees we make. This view provides that by implementing a tab on the lefthand side of the participant
 *  view. It links to all ECourse videos and materials.
 *
 * In January 2021, Steve updated the spec. In addition to providing access to the ECourse users who have complete
 *  their onboardings, this view must also provide non-Ecourse users who have completed their IPSATs the option to
 *  purchase access to the Ecourse materials.
 */
import { SessionService } from './../../../sessions/views/session/session.service';
import { UserService } from './../../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderVimeoVideoComponent } from '../../../small-groups/components/vimeo-video/vimeo-video.component';
import { RaliService } from '../../../../services/rali.service';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { environment } from '../../../../../environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderPurchaseEcourseContentUpgradeComponent } from '../../../ecourse/components/purchase-ecourse-content-upgrade/purchase-ecourse-content-upgrade.component';
import { NexleaderViewEcourseVideoModalComponent } from '../../../ecourse/components/view-ecourse-video-modal/view-ecourse-video-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-ecourse-content',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderVimeoVideoComponent,
    NexleaderPurchaseEcourseContentUpgradeComponent,
  ],
  templateUrl: './ecourse-content.component.html',
})
export class NexleaderEcourseContentComponent implements OnInit {
  routeParams: any;
  userId: string = '';
  sessions: any[] = [];
  hasCompletedCoachingSession: boolean = false;
  raliAccess: any;
  user: any;

  /**
   * ecourseModules
   *
   * All available ecourse modules that the user can view additional information for.
   */
  ecourseModules = [
    {
      name: 'Module 1',
      index: 1,
      identifier: 'module1',
      description: 'The Art of Self-Leadership and the Power of Self-Awareness',
      icon: 'module1',
      vimeoId: '449799085',
    },
    {
      name: 'Module 2',
      index: 2,
      identifier: 'module2',
      description: 'Exploring Your Personality',
      icon: 'personality',
      vimeoId: '442197435',
    },
    {
      name: 'Module 3',
      index: 3,
      identifier: 'module3',
      description: 'Discovering Your Strengths',
      icon: 'strengths',
      vimeoId: '442202704',
    },
    {
      name: 'Module 4',
      index: 4,
      identifier: 'module4',
      description: 'Developing Your Skills',
      icon: 'skills',
      vimeoId: '442207974',
    },
    {
      name: 'Module 5',
      index: 5,
      identifier: 'module5',
      description: 'Refining Your Personal Values',
      icon: 'spiritualgifts',
      vimeoId: '442220028',
    },
    {
      name: 'Module 6',
      index: 6,
      identifier: 'module6',
      description: 'Uncovering Your Passions',
      icon: 'passions',
      vimeoId: '442226479',
    },
    {
      name: 'Module 7',
      index: 7,
      identifier: 'module7',
      description: 'Discovering your Identity Profile with IPSAT',
      icon: 'module7',
      vimeoId: '442227733',
    },
  ];

  moduleEight = {
    name: 'Module 8',
    index: 8,
    identifier: 'module8',
    description: 'Discovering your Identity Profile with IPSAT',
    icon: 'module8',
    vimeoId: '442227733',
  };

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private sessionService: SessionService,
    private raliService: RaliService,
    private modalService: BsModalService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.userId = this.routeParams['user_id'];
    this.load();
  }

  load(): void {
    this.sessionService
      .queryParticipant(this.userId)
      .pipe(
        tap((sessionData) => {
          this.sessions = sessionData;
          this.hasCompletedCoachingSession =
            this.sessions.filter((session) => session.completed).length > 0;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.raliService
      .checkAccess()
      .pipe(
        tap((raliAccessData) => {
          this.raliAccess = raliAccessData;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.userService
      .getUser(this.userId)
      .pipe(
        tap((userData) => {
          this.user = userData;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * hasRaliAccess()
   *
   * returns true if the current user has access to the ECourse Rali content; false otherwise
   *
   * Note: this result loads when the controller does; we will need to reload data if a user purchases
   *  access to the videos.
   *
   * See Rali.logic (specifically userHasAccessToRali)
   */
  hasRaliAccess(): boolean {
    // Default to true so that we hide the components until we know for sure they don't have Rali
    if (!this.raliAccess) return true;
    return this.raliAccess.hasRaliAccess;
  }

  shouldShowPurchaseOption(): boolean {
    if (!this.user) return false;
    return (
      this.user.onboardingExperience.identifier !== 'ecourse' &&
      !this.user.hasUpgradedFromNonEcourseToEcourseMaterialsAccess
    );
  }

  /**
   * viewEcourseModuleVideo()
   *
   * Opens a vimeo video modal that allows the user to rewatch the video.
   *
   * @param {Object} module - The module the user wants to watch a video for.
   */
  viewEcourseModuleVideo(module: any): void {
    const modalRef = this.modalService.show(
      NexleaderViewEcourseVideoModalComponent,
      {
        initialState: {
          title: module.name,
          index: module.index,
        },
      }
    );

    modalRef.content?.onClose.subscribe(() => {
      modalRef.hide();
    });
  }

  /**
   * shouldEnableModule()
   *
   * @param module        an ecourse module object from this controller
   * @returns {boolean}   true if the module can be accessed by the current user; false if it should be greyed out
   */
  shouldEnableModule(module: any): boolean {
    if (!this.user) return false;

    // Module 1 is always enabled - even for unpaid users
    //  It's a sample (see https://trello.com/c/2Tygkm7q)
    if (module.index === 1) {
      return true;
    }

    // Users need Rali access for videos other than Module 1
    if (!this.hasRaliAccess()) return false;

    // Module 8 is available to users who have access to the ECourse content by
    // OR (are Ecourse AND have completed at least one coaching session)
    if (
      module.index === 8 &&
      this.user.onboardingExperience.identifier === 'ecourse'
    ) {
      return this.hasCompletedCoachingSession;
    }

    // The rest of the modules are available if a user has access to ECourse content at all
    //  (kind of what you would expect)
    return (
      this.user.onboardingExperience.identifier === 'ecourse' ||
      this.user.hasUpgradedFromNonEcourseToEcourseMaterialsAccess
    );
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEcourseContentComponent',
//   downgradeComponent({
//     component: NexleaderEcourseContentComponent,
//   }) as angular.IDirectiveFactory
// );
