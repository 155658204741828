/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import { EnumsService } from '../../../../services/enum.service';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import { TelephonePipe } from '../../../../pipes/telephone.pipe';
import { Subscription, catchError, of, tap } from 'rxjs';

const defaultEmptyUser = {
  firstName: '',
  lastName: '',
  birthYear: '',
  gender: {},
  email: '',
  preferredContactType: {},
  preferredContactAddress: '',
  description: '',
  coachingUrl: '',
  phones: [],
  address: {},
  hasOptedOutOfTeamReport: false,
};

@Component({
  selector: 'app-nexleader-account',
  templateUrl: './account.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgModelDebounceChangeDirective,
    TelephonePipe,
    RouterLink
  ],
})
export class NexleaderAccountComponent implements OnInit {
  routeParams: any;
  user: any;
  phoneTypes!: any[];
  contactTypes!: any[];
  genders!: any[];
  isCoach: boolean = false;
  newPhone: any;
  saveUserSubscription!: Subscription;

  constructor(
    private userService: UserService,
    private enumsService: EnumsService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    this.loadUser();
    this.enumsService
      .getEnums()
      .pipe(
        tap((enumsResponse) => {
          this.phoneTypes = enumsResponse.AllPhoneTypes;
          this.contactTypes = enumsResponse.AllContactTypes;
          this.genders = enumsResponse.AllGenders;
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  trackByIndex(index: number, option: any): number {
    return option.index;
  }

  genderChanged(indexValue: string) {
    if (indexValue && indexValue !== 'null') {
      this.user.gender = this.genders.find(
        (value) => value.index === indexValue
      );
    } else {
      this.user.gender = null;
    }
    this.saveUser();
  }

  contactTypeChanged(indexValue: string) {
    if (indexValue && indexValue !== 'null') {
      this.user.preferredContactType = this.contactTypes.find(
        (value) => value.index === indexValue
      );
    } else {
      this.user.preferredContactType = null;
    }
    this.saveUser();
  }

  phoneTypeChanged(indexValue: string) {
    if (indexValue !== null) {
      this.newPhone.phoneType = this.phoneTypes.find(
        (value) => value.index.toString() === indexValue
      );
    } else {
      this.newPhone.phoneType = null;
    }
  }

  loadUser(): void {
    this.newPhone = null;
    this.userService
      .getUser(this.routeParams['user_id'])
      .pipe(
        tap((userResponse) => {
          this.user = {
            ...defaultEmptyUser,
            ...userResponse,
          };
          this.isCoach = this.user.roles.indexOf('coach') >= 0;
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe(() => {
        this.newPhone = {};
      });
  }

  saveUser(): void {
    this.saveUserSubscription?.unsubscribe();
    this.saveUserSubscription = this.userService
      .saveUser(this.user)
      .subscribe(() => {
        this.successService.handle();
        this.loadUser();
      });
  }

  showCoachConsolidation(): boolean {
    if (!this.user) return false;
    if (!this.user.roles) {
      console.warn('Malformed user in account component', this.user);
      return false;
    }
    return this.user.roles.indexOf('coach') >= 0;
  }

  addPhone(): void {
    this.user.phones.push(this.newPhone);
    this.newPhone = {};
    this.saveUser();
  }

  deletePhone(index: number): void {
    this.user.phones.splice(index, 1);
    this.saveUser();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderAccountComponent',
//   downgradeComponent({
//     component: NexleaderAccountComponent,
//   }) as angular.IDirectiveFactory
// );
