/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderDirectLandingView
 *
 * angular component: view
 *
 * Added May 2020
 *
 * This view lets new users purchase an IPSAT license without talking to a human being.
 *  This view can also be used to purchase a premium IPSAT Ecourse license.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, catchError, tap, of, take, switchMap, EMPTY } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SkippedVideoService } from '../../../../services/skipped-video.service';
import { SuccessService } from '../../../../services/success.service';
import { UserService } from '../../../../services/user.service';
import { NexleaderIfNoStripeComponent } from '../../../store/components/if-no-stripe/if-no-stripe.component';
import { NexleaderCurrencyComponent } from '../../../store/components/currency/currency.component';
import { DirectService } from '../../resources/direct.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderStripeElementModalComponent } from '../../../store/components/stripe-element-modal/stripe-element-modal.component';
import { FormsModule } from '@angular/forms';
import { NexleaderHelpUtilityComponent } from '../../../core/components/help-utility/help-utility.component';
import { NexleaderOnboardingVideoComponent } from '../../../onboarding/views/onboarding/onboarding-video/onboarding-video.component';

@Component({
  selector: 'app-nexleader-direct-landing',
  standalone: true,
  imports: [CommonModule, NexleaderIfNoStripeComponent, NexleaderCurrencyComponent, FormsModule, NexleaderHelpUtilityComponent, NexleaderOnboardingVideoComponent],
  templateUrl: './direct-landing.component.html'
})
export class NexleaderDirectLandingComponent implements OnInit {
  routeParams: any;

  /**
   * transactionIsInProgress
   *
   * this flag is set to true if the user has clicked submit and we have not
   *  yet received a response from the server; we don't want them to be able to
   *  click submit again
   *
   * @type {boolean}
   */
  transactionIsInProgress = false;

  /**
   * pricingConfig
   *
   * this object contains all data relevant to that price of the IPSAT
   *
   * a deep watch on this object calls the loadPrice() function
   *
   * this keeps the price accurate even when clifton strengths is enabled/disabled
   *
   * @type {null}
   */
  pricingConfig: any = null;

  /**
   * candidateDiscountCodeName
   *
   * this field is the contents of the discount code textbox. The validate button adds it to
   *  the pricingConfig if it is compatible with this configuration.
   *
   * @type {null}
   */
  candidateDiscountCodeName: string | null = null;
  loading = false;
  isModalOpen = false;
  pricing: any = null;
  user: any = null;
  ECOURSE_ONBOARDING_STEPS: any;
  ONBOARDING_EXPERIENCES: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private enumsService: EnumsService,
    private directService: DirectService,
    private authService: AuthService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService,
    private skippedVideoService: SkippedVideoService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  /**
   * load()
   *
   * called when the component binding is complete
   *
   * loads the user we're editing
   * loads any additional information necessary for running the view
   */
  load(): void {
    // Validate the javascript types of our angular $routeParams
    if (!this.routeParams['user_id']) {
      throw new Error('IPSATCheckoutComponent requires user_id route param');
    }

    this.loading = true;

    forkJoin([
      this.userService.getUser(this.routeParams['user_id']),
      this.enumsService.getEnums()
    ]).pipe(
      tap(([user, enums]) => {
        this.user = user;
        this.ECOURSE_ONBOARDING_STEPS = enums.EcourseOnboardingSteps;
        this.ONBOARDING_EXPERIENCES = enums.OnboardingExperiences;
        this.loading = false;

        // If the user is an ecourse user and not currently on MODULE_TWO (the step
        //  that we require them to pay at), then push them to the ecourse onboarding
        if (this.user.onboardingExperience.identifier === this.ONBOARDING_EXPERIENCES.ECOURSE.identifier) {
          this.router.navigate(['/ecourse/onboarding']);
        }

        // This form is meant only for direct sign up users that have not paid yet;
        //  if anyone else finds their way in, redirect to /
        if (!(this.user.roles.length === 1 && this.user.roles[0] === 'directsignupunpaid')) {
          this.router.navigate(['/']);
        }

        this.pricingConfig = {
          cliftonStrengthsAssessment: true,
          userId: this.routeParams['user_id'],
          onboardingExperienceIdentifier: this.user.onboardingExperience.identifier
        };

        this.handlePricingConfigChange();

      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  handlePricingConfigChange() {
    if (this.pricingConfig) {
      if (this.pricingConfig.onboardingExperienceIdentifier === 'ecourse') {
        this.pricingConfig.cliftonStrengthsAssessment = false;
      }
      this.loadPrice();
    }
  }

  loadPrice(): void {
    this.loading = true;

    let config: any = {
      user_id: this.pricingConfig.userId,
      clifton_strengths_assessment: this.pricingConfig.cliftonStrengthsAssessment,
      onboarding_experience_identifier: this.pricingConfig.onboardingExperienceIdentifier
    }

    if (this.pricingConfig.discountCodeName) {
      config.discount_code_name = this.pricingConfig.discountCodeName;
    }

    this.directService.getPricing(config).pipe(
      tap((pricing) => {
        this.pricing = pricing;
        this.loading = false;
      }),
      catchError(err => {
        this.errorHandlerService.handleError(err);
        this.loading = false;
        return of(null);
      })
    ).subscribe();
  }

  shouldShowVideo(): boolean {
    if (this.skippedVideoService.hasSkippedVideo) return false;
    return this.user && !this.user.watchedVideo;
  }

  getSurveyTypeName(): string {
    return this.user ? this.user.group.surveyType.name : '';
  }

  /**
   * isReadyToAdvance()
   *
   * perform simple validation on the form data and make sure surveyType has loaded (and is valid)
   *
   * Note: this is no guarantee we won't get a 422 - e.g. the email could be valid but a duplicate
   *
   * @returns {boolean} true if we're ready to submit to the backend
   */
  isReadyToAdvance(): boolean {
    if (this.transactionIsInProgress) return false;
    if (this.isModalOpen) return false;
    if (this.loading) return false;
    if (!this.user) return false;
    if (!this.pricingConfig) return false;
    if (!this.pricingConfig.userId) return false;
    if (!(this.pricingConfig.cliftonStrengthsAssessment === true
      || this.pricingConfig.cliftonStrengthsAssessment === false)) return false;
    return true;
  }

  /**
   * validateCandidateDiscountCode()
   *
   * validates the coupon code using a backend endpoint then assigns the name
   *  value to teamReportDiscountName (from candidateTeamReportDiscountName)
   *
   * @returns {*}
   */
  validateCandidateDiscountCode(): void {
    const uppercasedDiscountCode = this.candidateDiscountCodeName!.toUpperCase();
    const config = {
      user_id: this.pricingConfig.userId,
      clifton_strengths_assessment: this.pricingConfig.cliftonStrengthsAssessment,
      onboarding_experience_identifier: this.pricingConfig.onboardingExperienceIdentifier,
      discount_code_name: uppercasedDiscountCode
    };

    this.directService.getPricing(config).pipe(
      tap((_) => {
        this.pricingConfig.discountCodeName = uppercasedDiscountCode;
        this.handlePricingConfigChange();
      }), catchError((error) => {
        if (error.status === 422) {
          this.errorHandlerService.handleError({
            message: 'Sorry, that discount code is not valid for this IPSAT configuration. Is Clifton Strengths included with this discount code?',
            status: 422
          });
        } else {
          this.errorHandlerService.handleError(error);
        }
        return of(null);
      })
    ).subscribe();
  }

  clearDiscountName(): void {
    this.pricingConfig.discountCodeName = null;
    this.handlePricingConfigChange();
  }

  /**
   * checkout()
   *
   * function
   *
   * this is the main form submission
   *
   * @returns {boolean} false if we cancelled due to frontend validation
   */
  checkout(): void {
    // Abort if the form doesn't meet frontend validation requirements
    //  Those requirements are pretty weak, so there's no point bothering the backend
    //  if they fail
    if (!this.isReadyToAdvance()) return;

    this.isModalOpen = true;

    //Tokenize a payment method via Stripe
    const modalRef = this.modalService.show(
      NexleaderStripeElementModalComponent,
      {
        initialState: {
          title: 'IPSAT Direct Purchase',
          description: `nexleader ${this.pricing.lineItems
            .map((lineItem: any) => lineItem.name)
            .join(' and ')} for ${this.user.firstName} ${this.user.lastName}`,
          currencyCode: this.pricing.total.currencyCode?.toLowerCase(),
          amount: Math.trunc(this.pricing.total.currencyQuantity * 100),
          email: this.user.email,
          isEmailEditable: false,
        },
      }
    );

    // If there was an error, return with the error appropriately
    modalRef.onHide?.pipe(take(1)).subscribe((_) => {
      this.isModalOpen = false;
    });

    modalRef.content?.tokenGenerated
      .pipe(take(1))
      .pipe(
        tap(() => modalRef.hide()),
        switchMap((token) => {
          // If the price is greater than 0, there must be a token in order to successfully make a payment
          if ((this.pricing.total.currencyQuantity > 0) && !token) {
            return EMPTY;
          }

          // We only need an $apply if we're coming back from a Stripe charge
          //  this is a bit of a janky hack until we refactor StripePayment to be a sensible
          //  piece of code
          this.transactionIsInProgress = this.pricing.total.currencyQuantity !== 0;

          // Build the request body
          const directPurchaseRequestBody = {
            userId: this.user._id,
            cliftonStrengthsAssessment: this.pricingConfig.cliftonStrengthsAssessment,
            token: token, //could be null/undefined
            pricing: this.pricing, //added as a redundancy - we report what we charged to make sure it's correct
            onboardingExperienceIdentifier: this.pricingConfig.onboardingExperienceIdentifier,
            discountCodeName: this.pricingConfig.discountCodeName
          };

          // Send the DirectLanding post request
          //  On Success, perform the next step
          //  On Error, show an error toast
          return this.directService.
            purchase(directPurchaseRequestBody)
            .pipe(
              catchError((err) => {
                this.transactionIsInProgress = false;
                this.errorHandlerService.handleError(err);
                return EMPTY;
              })
            );
        })
      )
      .subscribe(() => {
        // Display a success toast
        this.successService.handle({ message: `Successfully purchased ${this.getSurveyTypeName()} IPSAT.` });

        //We have successfully purchased the IPSAT!
        // Unfortunately, our authentication token does not reflect our fancy new 'participant'
        //  role, so we must "refresh" the auth token so that we will be routed to the right
        //  onboarding page and have access to the information we need once we get there.
        this.authService.refresh().pipe(
          tap(() => {
            // We have now successfully purchased and upgraded to a participant account. Redirect to /
            //  and the existing logic should take care of the rest.
            this.router.navigate(['/']);
          }), catchError((error) => {
            this.errorHandlerService.handleError(error);
            return of(null);
          })
        ).subscribe();
      });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderDirectLandingComponent',
//   downgradeComponent({
//     component: NexleaderDirectLandingComponent,
//   }) as angular.IDirectiveFactory
// );
