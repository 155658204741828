/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/**
 * nexleaderComponentHelpUtility
 *
 * component
 *
 * Displays a small help box in the bottom right corner of the screen that allows a user to get help. This is only
 *  displayed on certain screens in the application where immediate access to help is not available.
 */

/* global angular */
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderHelpModalComponent } from '../modals/help-modal/help-modal.component';

@Component({
  selector: 'app-nexleader-help-utility',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './help-utility.component.html'
})
export class NexleaderHelpUtilityComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  /**
   * openHelpModal()
   *
   * function
   *
   * Opens a modal that displays content intended to help a person.
   */
  openHelpModal(): void {
    const modalRef = this.modalService.show(NexleaderHelpModalComponent);
  }

  /**
   * isLoggedIn()
   *
   * function
   *
   * @returns {boolean} true if the user is logged in according to the auth service
   */
  isLoggedIn(): boolean {
    return !!this.authService.getTokenPayload();
  }

  /**
   * logout()
   *
   * function
   *
   * Logs a user out of the application.
   */
  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}


