<div class="m-b-md">
  <div class="chart-spiritual-gifts-represented-canvas-container">
    <div id="venn" style="width: 100%; min-height: 400px"></div>
  </div>
  <br />
  <a (click)="viewSpiritualGiftsInformation()" class="hideOnPrint">
    Learn more about Word gifts, Love gifts, and Power gifts.
  </a>
</div>
<div class="row">
  <div
    *ngFor="let category of categoriesWithoutIntersects"
    class="col-sm-4 col-xs-12 col-4-print m-b-md text-center"
  >
    <h5
      class="text-primary m-b-sm font-bold nexleader-spiritual-gifts-{{
        category.identifier
      }}"
    >
      {{ category.name }}
    </h5>
    <div *ngFor="let spiritualGift of category.spiritualGifts" class="m-b-xs">
      <p class="cursor-pointer" (click)="viewUsers(spiritualGift)">
        {{ spiritualGift.name }} ({{ spiritualGift.value }})
      </p>
    </div>
    <div *ngIf="category.spiritualGifts.length < 1">
      <p class="text-muted small">
        0 {{ category.name }} are represented on this team
      </p>
    </div>
  </div>
</div>
