
/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderStoreView
 *
 * angular view component
 *
 * It is the responsibility of this view to render the IPSAT Store view.
 *  This should contain a list of all products that the user from $routeParams can purchase from the
 *  group provided in $routeParams.
 *
 * The Product component handles Stripe purchase transactions for a particular product.
 */
import { ProductService } from './../../resources/product.service';
import { UserService } from './../../../../services/user.service';
import { GroupService } from './../../../../services/group.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NexleaderIfNoStripeComponent } from '../../components/if-no-stripe/if-no-stripe.component';
import { NexleaderProductComponent } from '../../components/product/product.component';

@Component({
  selector: 'app-nexleader-store-view',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderIfNoStripeComponent,
    NexleaderProductComponent,
  ],
  templateUrl: './store-view.component.html',
})
export class NexleaderStoreViewComponent implements OnInit {
  routeParams: any;
  group: any;
  products: any;
  user: any;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private productService: ProductService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    this.groupService
      .get(this.routeParams['group_id'])
      .pipe(
        tap((group) => {
          this.group = group;
        }),
        catchError((error) => {
          this.router.navigate(['/']);
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.productService
      .query(this.routeParams['group_id'])
      .pipe(
        tap((products) => {
          this.products = products;
        }),
        catchError((error) => {
          this.router.navigate(['/']);
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.userService
      .getUser(this.routeParams['user_id'])
      .pipe(
        tap((user) => {
          this.user = user;
        }),
        catchError((error) => {
          this.router.navigate(['/']);
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderStoreViewComponent',
//   downgradeComponent({
//     component: NexleaderStoreViewComponent,
//   }) as angular.IDirectiveFactory
// );
