/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderDirectPurchaseReferralLink
 *
 * angular component
 *
 * This component is responsible for providing UI for copying and pasting coach direct purchase referral links when
 *  they are present.
 */
import { tap, catchError, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-direct-purchase-referral-link',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './direct-purchase-referral-link.component.html',
})
export class NexleaderDirectPurchaseReferralLinkComponent implements OnInit {
  @Input() coachId: string = '';
  loading = true;
  user: any;

  constructor(
    private userService: UserService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    if (this.coachId === undefined) {
      throw new Error(
        'nexleaderDirectPurchaseReferralLink requires a coach id'
      );
    }

    this.userService
      .getUser(this.coachId)
      .pipe(
        tap((user) => {
          this.user = user;
          this.loading = false;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          this.loading = false;
          return of(null);
        })
      )
      .subscribe();
  }

  canShowLink(): boolean {
    return (
      !this.loading &&
      this.user &&
      this.user.roles.indexOf('coach') >= 0 &&
      this.user.enableDirectPurchaseReferralForThisCoach
    );
  }

  getStandardLink(): string | undefined {
    if (!this.canShowLink()) {
      return;
    }
    return `${window.location.origin}/#/direct/signup?coach_id=${this.user._id}&onboarding_experience_identifier=standard`;
  }

  getECourseLink(): string | undefined {
    if (!this.canShowLink()) {
      return;
    }
    return `${window.location.origin}/#/direct/signup?coach_id=${this.user._id}&onboarding_experience_identifier=ecourse`;
  }
}
