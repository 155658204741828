/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderSurvey
 *
 * angular component: view
 *
 * This is the IPSAT assessment component. It is responsible for loading the questions from the backend survey object.
 *  Then populating a SurveyResult object from that structure then sending that information to the backend.
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, catchError, forkJoin } from 'rxjs';
import { EnumsService } from '../../../../services/enum.service';
import { SurveyService } from '../../../../services/survey.service';
import { UserService } from '../../../../services/user.service';
import { SurveyResultService } from '../survey-result/survey-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { CommonModule } from '@angular/common';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';
import { FormsModule } from '@angular/forms';
import { NexleaderSurveyQuestionComponent } from './question/question.component';

@Component({
  selector: 'app-nexleader-survey',
  standalone: true,
  imports: [
    CommonModule,
    OrderByPipe,
    FormsModule,
    NexleaderSurveyQuestionComponent,
  ],
  templateUrl: './survey.component.html',
})
export class NexleaderSurveyComponent implements OnInit {
  routeParams: any;
  // Enums: any;
  survey: any;
  user: any;
  disableSubmit = false;

  constructor(
    private enumsService: EnumsService,
    private surveyService: SurveyService,
    private userService: UserService,
    private surveyResultService: SurveyResultService,
    private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    forkJoin({
      // enums: this.enumsService.getEnums(),
      survey: this.surveyService.getSurvey(this.routeParams['survey_id']),
      user: this.userService.getUser(this.routeParams['user_id']),
    })
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe(({ survey, user }) => {
        // this.Enums = enums;
        this.survey = survey;
        this.survey.questions.forEach((question: any) => {
          question.answer = {};
        });
        this.user = user;
      });
  }

  isFormInvalid() {
    return this.survey?.questions.some((q: any) =>
      this.isObjectEmpty(q.answer)
    );
  }

  isObjectEmpty(object: any) {
    return Object.keys(object).length === 0;
  }

  submit(): void {
    this.disableSubmit = true;

    delete this.survey._id;
    this.survey.user = this.routeParams['user_id'];
    this.survey.prerequisites = this.user.prerequisites;

    this.surveyResultService
      .save(this.survey)
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((result) => {
        this.router.navigate(['/users', this.user._id, 'surveyResults', result._id]);
      });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSurveyComponent',
//   downgradeComponent({
//     component: NexleaderSurveyComponent,
//   }) as angular.IDirectiveFactory
// );
