/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlobResourceService } from '../../../../services/blob.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-files',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './files.component.html',
})
export class NexleaderFilesComponent {
  //This is an array of blob objects
  @Input() files: any[] = [];
  @Input() fileOwner: any;
  @Output() onFileDelete = new EventEmitter<any>();
  @Input() columnClass: string = 'col-md-6';
  @Input() editable: boolean = false;

  constructor(
    private blobService: BlobResourceService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  deleteFile(file: any): void {
    let user_id = this.fileOwner;
    if (user_id && user_id._id) user_id = user_id._id;

    this.blobService
      .deleteUser(user_id, file._id)
      .pipe(
        tap((_) => {
          this.successService.handle({ message: 'File deleted.' });
          this.onFileDelete.emit({ file });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  iconForContentType(contentType: string): string {
    if (contentType.indexOf('pdf') > -1) return 'fa-file-pdf-o text-danger';
    if (contentType.indexOf('image') > -1) return 'fa-file-image-o';
    if (contentType.indexOf('video') > -1) return 'fa-file-video-o';
    if (
      contentType.indexOf('excel') > -1 ||
      contentType.indexOf('spreadsheet') > -1 ||
      contentType.indexOf('csv') > -1
    )
      return 'fa-file-excel-o';
    if (
      contentType.indexOf('msword') > -1 ||
      contentType.indexOf('wordprocessingml') > -1
    )
      return 'fa-file-word-o';
    if (
      contentType.indexOf('zip') > -1 ||
      contentType.indexOf('compressed') > -1
    )
      return 'fa-file-archive-o';
    return 'fa-file-o';
  }

  panelClassForFilePrerequisite(prereq: any): string {
    if (!(prereq && prereq.name)) return '';
    if (prereq.name === 'Personality') return 'hgreen';
    if (prereq.name === 'Strengths') return 'hreddeep';
    if (prereq.name === 'Skills') return 'hdarkblue';
    if (prereq.name === 'Spiritual Gifts') return 'hblue';
    if (prereq.name === 'Passions') return 'hyellow';
    return '';
  }
}
