<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="login-container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center m-b-md">
        <div class="text-center m-b-md">
          <img src="/assets/images/logo.svg" width="125" height="100" />
        </div>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <h4 class="text-center">Reset your password</h4>
          <form id="loginForm" (ngSubmit)="sendPasswordReset()">
            <div class="form-group">
              <label class="control-label">Email</label>
              <input type="text" placeholder="example@gmail.com" title="Please enter your email" class="form-control"
                name="email" [(ngModel)]="passwordReset.email" [ngModelDebounce]="500"
                (ngModelDebounceChange)="update()" />
            </div>

            <div *ngIf="groups" class="form-group">
              <label class="control-label">Group</label>
              <select class="form-control" [(ngModel)]="passwordReset.group" name="group">
                <option value="undefined" disabled selected>
                  -- Select a group --
                </option>
                <option *ngFor="let group of groups; trackBy: trackByGroupId" [value]="group._id">
                  {{ group.name }}
                </option>
              </select>
            </div>

            <button type="submit" class="btn btn-success btn-block">
              Send Link
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <strong>IPSAT</strong> <br />
      2017 Copyright nexleader
    </div>
  </div>
</div>