/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderEcourseRaliVideoComponent } from '../ecourse-rali-video/ecourse-rali-video.component';

@Component({
  selector: 'app-nexleader-view-ecourse-video-modal',
  standalone: true,
  imports: [CommonModule, NexleaderEcourseRaliVideoComponent],
  templateUrl: './view-ecourse-video-modal.component.html',
})
export class NexleaderViewEcourseVideoModalComponent {
  /**
   * title
   *
   * A title to use for the modal.
   *
   * ex: Module 1
   */
  title: string = '';

  /**
   * index
   *
   * Which module is this video for?
   *
   * ex: 1
   */
  index!: number;

  /**
   * close
   *
   * A close callback function to use
   *
   * ex: someFn()
   */
  @Output() onClose = new EventEmitter<any>();
}
