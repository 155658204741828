/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  query(groupId: string): Observable<any> {
    return this.http.get(`${API_BASE}groups/${groupId}/products`, {
      withCredentials: true,
    });
  }

  buyProduct(groupId: string, payload: any): Observable<any> {
    const url = `${API_BASE}groups/${groupId}/products/buy`;
    return this.http.post(url, payload, { withCredentials: true });
  }
}
