/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderCategoryScore
 *
 * component
 *
 * A template component that shows data pertaining to an IPSAT survey result.
 */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nexleader-category-score',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './category-score.component.html',
})
export class NexleaderCategoryScoreComponent {
  /**
   * category
   *
   * object
   *
   * the category of data we are displaying
   */
  @Input() category: any;

  /**
   * result
   *
   * object
   *
   * an actual IPSAT survey result
   */
  @Input() result: any;

  /**
   * surveyType
   *
   * string
   *
   * a string that determines what type of survey result we are showing
   */
  @Input() surveyType!: string;
}
