/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderInputEnum
 *
 * angular component
 *
 * dynamic selector dropdown for enums
 *
 * as a developer, you shouldn't have to worry about loading enums every single time you want to make a dropdown, and
 *  dropdowns usually select one of our enums. this component renders a dropdown for an enum with a given name
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-input-enum',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-enum.component.html',
})
export class NexleaderInputEnumComponent implements OnInit {
  /**
   * model
   *
   * two-way object binding
   *
   * basically ng-model
   *
   * selecting an option in the dropdown should mutate this state to the selected option
   * mutating this state from an outside controller should update the dropdown
   * model should not be null in $onLoad (after binding)
   */
  @Input() model: any;

  /**
   * enumName
   *
   * one-way string binding
   *
   * the name of the enum you're trying to bind to (excluding "All"; including the s at the end if it's present)
   *
   * Ex: 'TeamReportDiscountTypes'
   */
  @Input() enumName: string | undefined;

  /**
   * onChange
   *
   * event callback expression
   *
   * called after a $timeout after an ng-change callback from the option dropdown
   *
   * we wrap this in a $timeout so that there's time for the data to propagate upwards through
   *  the model binding
   */
  @Output() realOnChange: EventEmitter<any> = new EventEmitter<any>();
  options: any[] = [];

  constructor(
    private enumsService: EnumsService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  /**
   * $onInit()
   *
   * function: angular event handler
   *
   * called after binding
   *
   * validates bindings then
   * loads the enum options
   */
  ngOnInit(): void {
    if (this.model === undefined) {
      throw new Error(
        `Cannot initialize an InputEnum (${this.enumName}) component with an undefined (null is actually fine) model; you wouldn't actually be binding to anything; try adding an ngIf with the same model property (or adding a model property if you didn't).`
      );
    }

    if (typeof this.enumName !== 'string') {
      throw new Error(
        `Cannot initialize an InputEnum (${this.enumName}) component without a string enumName binding (see docs).`
      );
    }

    if (this.enumName.indexOf('All') === 0) {
      throw new Error(
        `You don't put 'All' in front for InputEnum (${this.enumName}). Just put the normal name. The component does the rest.`
      );
    }

    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.options = enums['All' + this.enumName];

          if (!Array.isArray(this.options)) {
            console.error(
              `Invalid enumName in InputEnum; we successfully loaded the enum object, but the enum you wanted wasn't there. Make sure the name is right.`
            );
          }
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  compareEnums(o1: any, o2: any) {
    if (o1?.name == o2?.name) return true;
    else return false;
  }

  trackByName(index: number, option: any): string {
    return option.name;
  }

  /**
   * onChange()
   *
   * wraps realOnChange() in a $timeout
   *
   * see docs for realOnChange in bindings
   */
  onChange(): void {
    this.realOnChange.emit(this.model);
  }
}
