import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[nexleaderMaximumWordsValidation]',
  standalone: true,
})
export class NexleaderMaximumWordsValidationDirective {
  @Input() maxWords: number = Infinity;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    const value = event.target.value;
    const words = value.trim().split(/\s+/);
    const exceeded = words.length > this.maxWords;

    if (exceeded) {
      // Truncate the input to the maximum number of words
      const truncatedValue = words.slice(0, this.maxWords).join(' ');
      this.el.nativeElement.value = truncatedValue;
      event.stopPropagation();
    }
  }
}
