import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  constructor(private http: HttpClient) { }

  get(inviteId: string): Observable<any> {
    return this.http.get(`${API_BASE}invites/${inviteId}`, {
      withCredentials: true,
    });
  }

  save(invite: any): Observable<any> {
    return this.http.post(`${API_BASE}invites`, invite, {
      withCredentials: true,
    });
  }

  delete(inviteId: string): Observable<any> {
    return this.http.delete(`${API_BASE}invites/${inviteId}`, {
      withCredentials: true,
    });
  }

  queryGroup(groupId: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}groups/${groupId}/invites`);
  }

  queryGroupIndex(groupId: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}groups/${groupId}/invites/index`, {
      withCredentials: true,
    });
  }

  signup(inviteId: string, invite: any): Observable<any> {
    return this.http.post(`${API_BASE}invites/${inviteId}/signup`, invite);
  }

  resend(inviteId: string): Observable<any> {
    return this.http.post(
      `${API_BASE}invites/${inviteId}/resend`,
      {},
      {
        withCredentials: true,
      }
    );
  }
}
