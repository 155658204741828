<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="row">
  <div class="col-xs-6">
    <button
      class="btn m-t-sm"
      style="width: 100%"
      (click)="setModel(false)"
      [ngClass]="{ 'btn-success': noActive, 'btn-default': !noActive }"
    >
      No
    </button>
  </div>
  <div class="col-xs-6">
    <button
      class="btn m-t-sm"
      style="width: 100%"
      (click)="setModel(true)"
      [ngClass]="{ 'btn-success': yesActive, 'btn-default': !yesActive }"
    >
      Yes
    </button>
  </div>
</div>
