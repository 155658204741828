<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel m-n">
          <div class="panel-heading">
            <div class="panel-tools">
              <a (click)="openCreateTeamReportDiscount()"
                ><i class="fa fa-plus"></i>&nbsp;&nbsp;Create new Discount
                Code</a
              >
            </div>
            <span>Team Report Discount Codes</span>
          </div>
          <div class="panel-body">
            <div
              class="team-report-discount-table table-responsive"
              *ngIf="teamReportDiscounts.length > 0"
            >
              <table class="table">
                <thead>
                  <th>Name</th>
                  <th>Discount Amount (%/$)</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  <tr *ngFor="let teamReportDiscount of teamReportDiscounts">
                    <td>{{ teamReportDiscount.name }}</td>
                    <td>{{ teamReportDiscount._discountAmountString }}</td>
                    <td><a (click)="remove(teamReportDiscount)">Delete</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p *ngIf="teamReportDiscounts.length < 1">
              There are no discounts to show.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
