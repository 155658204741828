/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPviAssessmentStepThree
 *
 * component
 *
 * This component is responsible for completing the third step of the PVI. This step asks each user to go through each
 *  value they selected on the first step and add a short description about this value and how they would explain it to
 *  someone else.
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NexleaderComponentPviAssessmentSelectedValuesComponent } from '../pvi-assessment-selected-values/pvi-assessment-selected-values.component';
import { FormsModule } from '@angular/forms';
import { NexleaderMaximumWordsValidationDirective } from '../../../../directives/max-words.directive';

@Component({
  selector: 'app-nexleader-component-pvi-assessment-step-three',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderMaximumWordsValidationDirective,
    NexleaderComponentPviAssessmentSelectedValuesComponent,
  ],
  templateUrl: './pvi-assessment-step-three.component.html',
})
export class NexleaderComponentPviAssessmentStepThreeComponent
  implements OnInit
{
  /**
   * topTwelveValues
   *
   * array
   *
   * An array of the top twelve values that the user has selected. This array is filled within this component
   *  and utilized in the parent component.
   */
  @Input() topTwelveValues!: any[];
  selectedValue: any;

  ngOnInit(): void {
    if (!Array.isArray(this.topTwelveValues)) {
      throw new Error(
        'Input "topTwelveValues" must be an array for NexleaderComponentPviAssessmentStepThreeComponent.'
      );
    }

    // Select the first element in the topTwelveValues array
    this.selectValue(this.topTwelveValues[0]);
  }

  /**
   * moveToPreviousValue()
   *
   * function
   *
   * Moves the user to the previous value than the one they selected
   */
  moveToPreviousValue(): void {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );
    if (currentValueIndex === 0) {
      return; // If they are currently on the first value, they can't move backwards
    }
    this.selectValue(this.topTwelveValues[currentValueIndex - 1]);
  }

  /**
   * moveToNextValue()
   *
   * function
   *
   * Moves the user to the next value than the one they selected
   */
  moveToNextValue(): void {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );
    if (currentValueIndex === this.topTwelveValues.length - 1) {
      return; // If they are currently on the last value, they can't move forwards
    }
    this.selectValue(this.topTwelveValues[currentValueIndex + 1]);
  }

  /**
   * canMoveToPreviousValue()
   *
   * helper function
   *
   * Determines whether or not the user can move to the previous value.
   *
   * @returns {boolean}
   */
  canMoveToPreviousValue(): boolean {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );
    return currentValueIndex !== 0;
  }

  /**
   * canMoveToNextValue()
   *
   * helper function
   *
   * Determines whether or not the user can move to the next value.
   *
   * @returns {boolean}
   */
  canMoveToNextValue(): boolean {
    const currentValueIndex = this.topTwelveValues.findIndex(
      (value) => value.name === this.selectedValue.name
    );
    return currentValueIndex !== this.topTwelveValues.length - 1;
  }

  /**
   * selectValue()
   *
   * helper function
   *
   * Changes the selected value to a new value defined by an input param.
   *
   * @param {Object} value - A new value to select.
   */
  selectValue(value: any): void {
    // Important for us to be able to validate if the user has seen all of the values to continue
    value.valueDescriptionHasBeenViewed = true;
    this.selectedValue = value;
  }
}
