/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NexleaderVimeoVideoComponent } from '../../../../small-groups/components/vimeo-video/vimeo-video.component';
import { NexleaderInputEnumComponent } from '../../../../core/components/input-enum/input-enum.component';
import { EnumsService } from '../../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { UserService } from '../../../../../services/user.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-ecourse-personal-info',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderVimeoVideoComponent, NexleaderInputEnumComponent],
  templateUrl: './ecourse-personal-info.component.html'
})
export class NexleaderEcoursePersonalInfoComponent implements OnInit {
  /**
   * handleNextStep
   *
   * Allows data that is edited on this ecourse module to be saved.
   */
  @Output() handleNextStep = new EventEmitter();

  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process.
   */
  @Input() user: any;

  /**
   * hasViewedIntroductionVideo
   * 
   * boolean
   * 
   * Determines if the user has already watched the introduction video.
   */
  hasViewedIntroductionVideo = false;
  currentPrerequisite: any;

  constructor(
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    private enumsService: EnumsService
  ) { }

  ngOnInit(): void {
    this.load();

    // If enum fields are undefined, set them to null so that input-enum can bind
    if (!this.user.gender) this.user.gender = null;
    if (!this.user.preferredContactType) this.user.preferredContactType = null;
    if (!this.user.hasOptedOutOfTeamReport) this.user.hasOptedOutOfTeamReport = false;
  }

  /**
   * load()
   * 
   * Loads all required enums for the module.
   */
  load(): void {
    this.enumsService.getEnums().pipe(
      tap((enums) => {
        this.currentPrerequisite = enums.Prerequisites.MBTI;
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  /**
   * next()
   * 
   * Wrapper to save the user and then move them to the next step.
   */
  next(): void {
    this.userService.saveUser(this.user).pipe(
      tap((_) => {
        this.handleNextStep.emit();
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  /**
   * isReadyToAdvance()
   * 
   * Determines if the user is ready to move to the next step.
   */
  isReadyToAdvance(): boolean {
    if (!this.user) return false;
    if (typeof this.user.firstName !== 'string') return false;
    if (!this.user.lastName) return false;
    if (!this.user.birthYear) return false;
    if (!this.user.gender) return false;
    if (typeof this.user.gender.name !== 'string') return false;
    if (typeof this.user.email !== 'string') return false;
    if (!this.user.group) return false;
    if (typeof this.user.group.name !== 'string') return false;
    if (!this.user.preferredContactType || this.user.preferredContactType === 'null') return false;
    if (typeof this.user.preferredContactAddress !== 'string') return false;

    // COPA Compliance: We don't want users signing up if they aren't 13
    if (this.user.birthYear > new Date().getFullYear() - 13) return false;

    return true;
  }

  getBirthYears(): number[] {
    const years = [];
    const currentYear = new Date().getFullYear();
    // The oldest person was 122 years old
    // The youngest allowed person is 13 years old
    for (let i = 13; i <= 122; i++) {
      years.push(currentYear - i);
    }

    // Start with the current year instead of the last year
    return years;
  }
}
