/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderViewPviAssessmentResultPrintable
 *
 * view
 *
 * This view contains the components and view layout to display the information returned from the PVIAssessmentResult
 *  scoring for the printable layout. It does not do any logic on its own, but instead functions as a render component
 *  where elements are displayed within.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalValuesInventoryResultService } from '../../../../services/personal-values-inventory-result.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { EMPTY, catchError, tap } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-view-pvi-assessment-result-printable',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pvi-assessment-result-printable.component.html',
})
export class NexleaderViewPviAssessmentResultPrintableComponent
  implements OnInit {

  routeParams: any;
  allPersonalValueDomains!: any[];
  pviAssessmentResultScore: any;
  topTwelveValueDomainScore!: number[];
  topSevenValueDomainScore!: number[];
  topThreeValueDomainScore!: number[];

  constructor(
    private pviResultService: PersonalValuesInventoryResultService,
    private enumsService: EnumsService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    // Fetch enums so we can loop over
    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.allPersonalValueDomains = enums.AllPersonalValueDomains;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();

    const { user_id, pvi_result_id } = this.routeParams;

    // Load the personal values inventory result score
    this.pviResultService
      .getScore(pvi_result_id, user_id)
      .pipe(
        tap((result) => {
          this.pviAssessmentResultScore = result;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((_) => {
        const orderedArrayOfDomains = [
          'Others',
          'Avocation',
          'Self',
          'Vocation',
        ];
        const sortedArray = [
          ...this.pviAssessmentResultScore.personalValueDomainScores,
        ].sort(
          (a, b) =>
            orderedArrayOfDomains.indexOf(a.name) -
            orderedArrayOfDomains.indexOf(b.name)
        );

        // we need these three arrays to determine the spider graphs correctly
        this.topTwelveValueDomainScore = sortedArray.map(
          (v) => v.twelveValuesScore
        );
        this.topSevenValueDomainScore = sortedArray.map(
          (v) => v.sevenValuesScore
        );
        this.topThreeValueDomainScore = sortedArray.map(
          (v) => v.threeValuesScore
        );
      });
  }

  printResult(): void {
    window.print();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderViewPviAssessmentResultPrintableComponent',
//   downgradeComponent({
//     component: NexleaderViewPviAssessmentResultPrintableComponent,
//   }) as angular.IDirectiveFactory
// );
