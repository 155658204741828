/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../services/user.service';
import { SessionService } from '../../../sessions/views/session/session.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';
import { TelephonePipe } from '../../../../pipes/telephone.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-my-coach',
  standalone: true,
  imports: [CommonModule, OrderByPipe, TelephonePipe],
  templateUrl: './my-coach.component.html',
})
export class NexleaderMyCoachComponent implements OnInit {
  routeParams: any;
  user: any;
  coach: any;
  sessions: any[] = [];
  apiUrl = environment.apiUrl;

  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.userService
      .getUser(this.routeParams['user_id'])
      .pipe(
        tap((userResult) => {
          this.user = userResult;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.userService
      .queryCoach(this.routeParams['user_id'])
      .pipe(
        tap((coachResult) => {
          // If the coach exists, assign it to the val
          if (coachResult.firstName) {
            this.coach = coachResult;
          }
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.sessionService
      .queryParticipant(this.routeParams['user_id'])
      .pipe(
        tap((sessionsResult) => {
          this.sessions = sessionsResult;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderMyCoachComponent',
//   downgradeComponent({
//     component: NexleaderMyCoachComponent,
//   }) as angular.IDirectiveFactory
// );
