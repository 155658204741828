/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewGroupInvites
 *
 * angular component
 *
 * Shows all invites that are associated with the current group that the user is viewing.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GroupService } from '../../../../services/group.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { InviteService } from '../../../../services/invite.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { NexleaderQuickViewComponent } from '../../components/quick-view/quick-view.component';
import { NexleaderQuickViewNotSelectedComponent } from '../../components/quick-view-not-selected/quick-view-not-selected.component';
import { NexleaderInviteCardComponent } from '../../components/invite-card/invite-card.component';
import { NexleaderNewUserModalComponent } from '../../components/modals/new-user-modal/new-user-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-group-invites',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderQuickViewComponent,
    NexleaderQuickViewNotSelectedComponent,
    NexleaderInviteCardComponent,
  ],
  templateUrl: './group-invites.component.html',
})
export class NexleaderGroupInvitesComponent implements OnInit {
  routeParams: any;
  group: any;
  enums: any;
  invites: any[] = [];
  selectedGroup: any;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private groupService: GroupService,
    private enumsService: EnumsService,
    private inviteService: InviteService,
    private errorHandler: ErrorHandlerService,
    private successService: SuccessService
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load() {
    combineLatest([
      this.groupService.get(this.routeParams['group_id']),
      this.enumsService.getEnums(),
      this.inviteService.queryGroupIndex(this.routeParams['group_id']),
    ])
      .pipe(
        tap(([group, enums, invites]) => {
          this.group = group;
          this.enums = enums;
          this.handleInvites(invites);
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  handleInvites(invites: any[]) {
    this.invites = invites
      .filter((invite) => !invite.completed)
      .map((invite) => ({
        ...invite,
        _name: `${invite.firstName} ${invite.lastName}`,
        _coach: invite.coach
          ? `${invite.coach.firstName} ${invite.coach.lastName}`
          : 'N/A',
      }));
  }

  createInvite() {
    const modalRef = this.modalService.show(NexleaderNewUserModalComponent, {
      initialState: { routeParams: this.routeParams },
    });

    modalRef.content?.onSave.subscribe(() => {
      modalRef.hide();
      this.successService.handle();
      this.load();
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupInvitesComponent',
//   downgradeComponent({
//     component: NexleaderGroupInvitesComponent,
//   }) as angular.IDirectiveFactory
// );
