/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartStrengthsUnrepresented
 *
 * Displays a chart representation of the "unrepresented strengths" of a team in the TeamReport
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-chart-strengths-unrepresented',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-strengths-unrepresented.component.html',
})
export class NexleaderChartStrengthsUnrepresentedComponent implements OnInit {
  /**
   * model
   *
   * array in the following form:
   * [{
   *    name: 'Executing',
   *    description: 'making things happen',
   *    strengths: [
   *        {
   *            name: 'Strength name',
   *            value: 2 //how many people have it
   *        },
   *        {
   *            name: 'Strength name',
   *            value: 2 //how many people have it
   *        },
   *        {
   *            name: 'Strength name',
   *            value: 2 //how many people have it
   *        }
   *    ],
   *    value: 3 //how many strengths there are
   *  }
   *  ...more...
   *  ]
   */
  @Input() model: any[] = [];

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit(): void {
    // Validate that there's a model
    if (!this.model) {
      throw new Error(
        'model is required for NexleaderChartStrengthsUnrepresentedComponent (see component docs; maybe add an *ngIf if it has to load)'
      );
    }
  }
}
