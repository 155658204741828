/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderViewPviAssessmentResults
 *
 * view
 *
 * This view contains a basic list that displays all of the personal values inventory results.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { PersonalValuesInventoryResultService } from '../../../../services/personal-values-inventory-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { EMPTY, catchError, tap } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-view-pvi-assessment-results',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './pvi-assessment-results.component.html',
})
export class NexleaderViewPviAssessmentResultsComponent implements OnInit {
  routeParams: any;
  personalValuesInventoryResults: any[] = [];

  constructor(
    private pviResultService: PersonalValuesInventoryResultService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadPersonalValuesInventoryResults();
  }

  private loadPersonalValuesInventoryResults(): void {
    const userId = this.routeParams.user_id;

    // Load the personal values inventory results
    this.pviResultService
      .queryUser(userId)
      .pipe(
        tap((results) => {
          this.personalValuesInventoryResults = results;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderViewPviAssessmentResultsComponent',
//   downgradeComponent({
//     component: NexleaderViewPviAssessmentResultsComponent,
//   }) as angular.IDirectiveFactory
// );
