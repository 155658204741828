<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div *ngIf="noCoachesForGroup">
  No coaches have been created for this group yet. Create one before creating a
  session.
</div>

<div *ngIf="!noCoachesForGroup">
  <form #sessionForm="ngForm" *ngIf="session">
    <div>
      <span class="label label-success" *ngIf="session.completed"
        >Completed</span
      >
      <span class="label label-info" *ngIf="session.mustBeRecorded"
        >Must Record</span
      >
    </div>
    <p class="m-t-sm"><b>Group:</b> {{ groupName }}</p>
    <div class="form-group" *ngIf="coaches">
      <label class="control-label">Coach</label>
      <select
        name="sessionCoach"
        class="form-control"
        [(ngModel)]="session.coach"
        required
        (ngModelChange)="loadParticipants(session.coach)"
      >
        <option *ngFor="let coach of coaches" [value]="coach._id">
          {{ coach._fullName }}
        </option>
      </select>
    </div>
    <p *ngIf="!coaches">
      <b>Coach:</b> {{ session.coach?.firstName }} {{ session.coach?.lastName }}
    </p>
    <p *ngIf="!participants">
      <b>Participant:</b> {{ session.participant?.firstName }}
      {{ session.participant?.lastName }}
      <span *ngIf="!session.coach">Select a Coach to View Participants</span>
    </p>
    <p *ngIf="session.participant && !participants">
      <b>Contact:</b>
      <span *ngIf="session.participant.preferredContactAddress">
        {{ session.participant.preferredContactType?.name }} -
        <span
          *ngIf="session.participant.preferredContactType?.index === 'phone'"
          >{{ session.participant.preferredContactAddress | telephone }}</span
        >
        <span
          *ngIf="session.participant.preferredContactType?.index !== 'phone'"
          >{{ session.participant.preferredContactAddress }}</span
        >
      </span>
      <span *ngIf="!session.participant.preferredContactAddress">
        Not Provided. Try emailing
        <a [href]="'mailto:' + session.participant.email">{{
          session.participant.email
        }}</a
        >.
      </span>
    </p>
    <div class="form-group" *ngIf="participants">
      <label class="control-label">Participant</label>
      <select
        class="form-control"
        name="sessionParticipant"
        [(ngModel)]="session.participant"
        required
      >
        <option
          *ngFor="let participant of participants"
          [value]="participant._id"
        >
          {{ participant.firstName + " " + participant.lastName }}
        </option>
      </select>
    </div>
    <div class="row m-t-sm">
      <div class="col-md-6">
        <label class="control-label">Start</label>
        <div class="input-group" style="margin-top: 11px">
          <input
            type="text"
            class="form-control"
            required
            name="start"
            #startDate="bsDatepicker"
            [bsConfig]="{
              showTodayButton: true,
              showClearButton: true,
              dateInputFormat: 'MM/DD/YYYY',
              clearPosition: 'right',
              todayPosition: 'left',
              adaptivePosition: true
            }"
            bsDatepicker
            [(ngModel)]="start"
            (ngModelChange)="updateStartTime()"
          />
          <span class="input-group-btn">
            <button
              class="btn btn-default"
              type="button"
              (click)="startDate.toggle()"
            >
              <i class="fa fa-calendar"></i>
            </button>
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <timepicker
          [(ngModel)]="start"
          [hourStep]="1"
          [minuteStep]="15"
          (ngModelChange)="updateStartTime()"
          name="startTime"
        ></timepicker>
      </div>
      <div class="col-md-6">
        <label class="control-label">End</label>
        <div class="input-group" style="margin-top: 11px">
          <input
            type="text"
            class="form-control"
            required
            name="end"
            #endDate="bsDatepicker"
            [bsConfig]="{
              showTodayButton: true,
              showClearButton: true,
              dateInputFormat: 'MM/DD/YYYY',
              clearPosition: 'right',
              todayPosition: 'left',
              adaptivePosition: true,
            }"
            bsDatepicker
            [minDate]="start"
            [(ngModel)]="end"
            (ngModelChange)="updateEndTime()"
            [disabled]="timelocked"
          />
          <span class="input-group-btn">
            <button
              class="btn btn-default"
              type="button"
              (click)="endDate.toggle()"
            >
              <i class="fa fa-calendar"></i>
            </button>
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <timepicker
          [(ngModel)]="end"
          [hourStep]="1"
          [minuteStep]="15"
          (ngModelChange)="updateEndTime()"
          [min]="start"
          [disabled]="timelocked"
          name="endTime"
        ></timepicker>
      </div>
      <div class="col-md-12" *ngIf="session.end < session.start">
        <span style="color: red">A session cannot end before it begins.</span>
      </div>
      <div class="col-md-6">
        <label class="control-label">Timezone</label>
        <app-nexleader-timezone-select
          *ngIf="session.timezone !== undefined"
          [model]="session.timezone"
          (realOnChange)="timezoneChanged($event)"
        ></app-nexleader-timezone-select>
      </div>
      <div class="col-md-6">
        <label class="control-label">Lock End Time</label>
        <div class="form-group">
          <div class="checkbox">
            <input
              type="checkbox"
              name="timelock"
              [(ngModel)]="timelocked"
              id="timelock"
              (ngModelChange)="updateEndTime()"
            />
            <label for="timelock" class="control-label"
              >This is a 75 minute coaching session.</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label">Best Number to Call</label>
      <input
        type="tel"
        class="form-control"
        [pattern]="
          '^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$'
        "
        name="sessionCallNumber"
        [(ngModel)]="session.bestNumberToCall"
      />
    </div>
    <div class="form-group">
      <label class="control-label">Notes</label>
      <textarea
        class="form-control"
        name="notes"
        [(ngModel)]="session.notes"
        rows="4"
      ></textarea>
    </div>
    <div class="form-group">
      <label class="control-label">Recording</label>
      <div class="checkbox">
        <input
          type="checkbox"
          name="mustBeRecorded"
          [(ngModel)]="session.mustBeRecorded"
          id="mustBeRecorded"
        />
        <label for="mustBeRecorded" class="control-label"
          >This session must be recorded.</label
        >
      </div>
    </div>
    <div class="form-group">
      <label class="control-label">ECourse</label>
      <div class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="session.isSecondECourseSession"
          (ngModelChange)="onIsSecondECourseSessionUpdated()"
          id="secondSessionForECourse"
          name="secondSessionForECourse"
        />
        <label for="secondSessionForECourse" class="control-label"
          >This session is the second session for an ECourse user.</label
        >
      </div>
    </div>
    <button
      style="margin-right: 3px"
      class="btn btn-default"
      (click)="complete()"
      *ngIf="session._id && !session.completed"
    >
      Mark Session as Complete
    </button>
    <a class="btn btn-default" [href]="calendarDownloadUrl" *ngIf="session._id"
      >Download as Calendar File (.ics)</a
    >
    <hr />
    <div class="flex align-items-center">
      <p *ngIf="session.updatedAt" class="m-b-none font-semi-bold">
        {{ session.updatedAt | date }}
      </p>
      <div class="flex-grow"></div>
      <button class="btn btn-default" (click)="remove()" *ngIf="session._id">
        Delete
      </button>
      &nbsp;
      <button
        class="btn btn-success"
        (click)="save()"
        [attr.disabled]="
          sessionForm.invalid || session.end < session.start ? true : null
        "
      >
        {{ session._id ? "Save" : "Create" }} Session
      </button>
    </div>
  </form>
</div>
