<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="flex flex-vertical-center flex-horizontal-center hideOnPrint w-100 h-100vh">
  <button class="btn btn-lg btn-primary" (click)="print()">
    <i class="fa fa-print"></i>&nbsp;&nbsp;Print your IPSAT
  </button>
</div>
<div class="content animate-panel" *ngIf="result">
  <div class="centeredFirstPage">
    <div class="centeredFirstPageInside">
      <img src="/assets/images/logo.svg" alt="ipsat logo" width="200px" />
      <br />
      <br />
      <h1>Identity Profile <br />Self-Awareness Tool (IPSAT)</h1>
      <br />
      <h3><b>discover your identity</b></h3>
      <br />
      <br />
      <p>
        <b>Prepared for:</b>
        {{ user?.firstName }} {{ user?.lastName }}
      </p>
      <p><b>Completed:</b> {{ result.createdAt | date }}</p>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="row">
    <div class="col-sm-12">
      <div class="hpanel">
        <div class="panel-heading">
          <h3><b>introduction</b></h3>
        </div>
        <div class="panel-body">
          <p>
            Your identity profile is the combination of your personality,
            strengths, skills,
            <span *ngIf="surveyType === 'faith_based'">spiritual gifts</span><span
              *ngIf="surveyType === 'values_based'">personal values</span>,
            and passions. The Identity Profile Self-Awareness Tool (IPSAT) is a
            self-assessment exercise designed to help you evaluate and quantify
            how well you understand and engage with these five components.
          </p>
          <div>
            <br />
            <div class="col-xs-3 border-right">
              <ol>
                <li><b>Personality</b></li>
                <li><b>Strengths</b></li>
                <li><b>Skills</b></li>
                <li *ngIf="surveyType === 'faith_based'">
                  <b>Spiritual Gifts</b>
                </li>
                <li *ngIf="surveyType === 'values_based'">
                  <b>Personal Values</b>
                </li>
                <li><b>Passions</b></li>
              </ol>
            </div>
            <div class="col-xs-9">
              There are many fine assessment tools available to help unlock your
              potential in these categories of identity. For simplicity and
              uniformity, we have chosen to utilize the following for the IPSAT:
              personality based on the Myers Briggs Type Indicator&reg;
              (MBTI&reg;), strengths from the perspective of Clifton Strengths,
              skills based on the Skills Inventory,<span *ngIf="surveyType === 'values_based'">personal values based on
                the Personal Values Inventory</span>and passion using MyPassionProfile.com.<span
                *ngIf="surveyType === 'faith_based'">There is no prescribed spiritual gifts assessment.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <div class="row m-b-xl">
    <div class="col-xs-3">
      <div class="text-center m-b-xs">
        <img src="/assets/images/icons/ruler.svg" width="125" heigth="125" />
      </div>
    </div>
    <div class="col-xs-9">
      <div class="hpanel">
        <h4><b>application, not assessment is what sets you apart</b></h4>
        <p>
          Doing an assessment will not set you apart from others. Millions of
          people take assessments every year. It is what you do with the
          information that will set you apart.
          <span *ngIf="surveyType === 'faith_based'">The reason you have this combination of personality, strengths,
            skills, spiritual gifts, and passions is because God knew you would
            need them along with the power of the Holy Spirit to accomplish the
            good works prepared in advance for you.</span>
          <span *ngIf="surveyType === 'values_based'">You have a unique combination of personality, strengths, skills,
            personal values and passions. Living a purposeful life that adds
            value to others will require you to discover, optimize and unleash
            these resources for the common good. That’s what the IPSAT is
            designed to do.</span>
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-3">
      <div class="text-center m-b-xs">
        <img src="/assets/images/icons/giftedness.svg" width="125" heigth="125" />
      </div>
    </div>
    <div class="col-xs-9">
      <div class="hpanel">
        <div class="m-b-xs">
          <h4><b>character is the foundation for giftedness</b></h4>
          <p *ngIf="surveyType === 'faith_based'">
            You will need hard and soft skills, a great team, and a well-crafted
            strategy. But you will only bear lasting fruit when you live and
            lead in alignment with God’s principles. Your character matters, and
            it serves as a foundation supporting all the kingdom resources God
            has given you. Life is not about you. Leadership is not an end in
            itself. Great leaders leverage their influence for the glory of God
            in the service of others.
          </p>
          <p *ngIf="surveyType === 'values_based'">
            You will need hard and soft skills, a great team, and a well-crafted
            strategy. But you will only leave a noble legacy if your life is
            build on a solid foundation. Your character matters. Life is about
            more than you. Leadership is not an end in itself. Great leaders
            leverage their influence for the common good in the service of
            others and make the world a better place.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="page-break"></div>
  <div class="row">
    <div class="col-xs-12 m-b-lg">
      <div class="text-center">
        <h3><b>4 Stages of Your Identity Profile</b></h3>
      </div>
    </div>
    <div class="col-xs-12 row" style="margin-right: 0; margin-left: 0">
      <div class="col-xs-6" style="padding-left: 0">
        <div class="hpanel">
          <div class="panel-body">
            <h4>1. Exploration Stage:</h4>
            <p>
              You are just beginning to explore the components of your identity
              profile but not yet developing them intentionally or using them
              effectively.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-6" style="padding-right: 0">
        <div class="hpanel">
          <div class="panel-body">
            <h4>2. Identification Stage:</h4>
            <p>
              You have enough clarity about the components of your identity
              profile to develop them intentionally and begin to use them
              consistently.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 row" style="margin-right: 0; margin-left: 0">
      <div class="col-xs-6" style="padding-left: 0">
        <div class="hpanel">
          <div class="panel-body">
            <h4>3. Application Stage:</h4>
            <p>
              Your self-awareness is high enough to engage the categories of
              your identity profile with predictable results and focus
              intentionally on what you do best.
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-6" style="padding-right: 0">
        <div class="hpanel">
          <div class="panel-body">
            <h4>4. Maturation Stage:</h4>
            <p>
              You are cultivating an experiential understanding of the
              categories in your identity profile and how they interact with
              each other to maximize your effectiveness.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 m-b-xl">
      <div class="text-center">
        <p>
          The maximum score for the Identity Profile Self-Assessment Tool is
          100. There are five categories based on the components of your
          identity profile: personality, strengths, skills,
          <span *ngIf="surveyType === 'faith_based'">spiritual gifts</span><span
            *ngIf="surveyType === 'values_based'">personal values</span>,
          and passions. Each sub category has a maximum score of 20.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 text-center">
      <h3>
        You are at the <b>{{ ipsatStage.name }}</b>
      </h3>
    </div>
    <div class="col-lg-12">
      <div class="hpanel">
        <div class="panel-body">
          <div class="col-xs-6 text-center">
            <small>{{ possessivePrefix }} Score Breakdown</small>
            <br />
            <br />
            <div style="width: 200px; margin-left: auto; margin-right: auto">
              <canvas id="doughnut-chart"></canvas>
            </div>
            <div class="col-md-12 text-center">
              <h1 class="font-extra-bold">
                {{ total }}
              </h1>
              <small>IPSAT score out of 100</small>
            </div>
          </div>
          <div class="col-xs-6">
            <p>{{ ipsatStage.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-break"></div>
  <div *ngFor="
      let category of categories | filter : 'disclaimer' : '!!';
      let last = last
    ">
    <div class="row">
      <div class="col-sm-12">
        <div class="hpanel" *ngIf="
            category.name !== 'Open-Ended Questions' &&
            category.name !== 'Summary Overview'
          ">
          <div class="panel-body">
            <div class="text-center">
              <img src="/assets/images/icons/{{ category.className }}.svg" alt="icon" class="img-sm" />
              <h4 class="m-b-md">{{ category.name }}</h4>
              <h3>{{ category.value }}/{{ category.maxValue }}</h3>
            </div>
            <div>
              <div class="progress full progress-small">
                <div [ngStyle]="{
                    width: (100 * category.value) / category.maxValue + '%'
                  }" aria-valuemax="100" aria-valuemin="0" aria-valuenow="55" role="progressbar"
                  class="progress-bar progress-bar-{{ category.className }}">
                  <span class="sr-only">35% Complete (success)</span>
                </div>
              </div>
              <br />
              <div class="row" *ngIf="getCategoryValue(category.className) as item">
                <div class="col-xs-12">
                  <small class="stats-label" *ngIf="item.key !== 'spiritualGifts'">{{ item.key.toUpperCase() }}
                  </small>
                  <small class="stats-label" *ngIf="item.key === 'spiritualGifts'">
                    <span *ngIf="surveyType === 'faith_based'">SPIRITUAL GIFTS</span>
                    <span *ngIf="surveyType === 'values_based'">PERSONAL VALUES</span>
                  </small>
                  <h4 *ngIf="item.value.name">{{ item.value.name }}</h4>
                  <h4 *ngIf="item.value.length > 1">
                    <span *ngFor="let v of item.value; let index = index">
                      <span *ngIf="index !== item.value.length - 1">{{ v.name }},
                      </span>
                      <span *ngIf="index === item.value.length - 1">{{
                        v.name
                        }}</span>
                    </span>
                  </h4>
                </div>
              </div>
              <br />
              <p>{{ category.range.name }}</p>
              <p *ngFor="
                  let question of result.questions | questionCategory : category
                ">
                <span *ngIf="question.answer.summary">{{
                  question.answer.summary
                  }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!last" class="page-break"></div>
  </div>
  <div class="page-break"></div>
  <div class="row">
    <div class="col-md-12">
      <div class="hpanel">
        <div class="panel-heading">
          <h3><b>communicating your identity with others</b></h3>
        </div>
      </div>
    </div>
    <div class="col-md-12" *ngFor="
        let summaryOverviewQuestion of result.questions
          | questionCategory : summaryOverviewQuestionCategory
      ">
      <p>
        <b>identity overview statement:</b> Here is my best explanation of the
        unique combination of my personality, strengths, skills,
        <span *ngIf="surveyType === 'faith_based'">spiritual gifts</span><span
          *ngIf="surveyType === 'values_based'">personal values</span>, and
        passions… (this is who you are, based on your identity profile)
      </p>
      <p>{{ summaryOverviewQuestion.answer.name }}</p>
    </div>
    <ng-container *ngFor="let question of openEndedQuestions">
      <div class="col-md-12" *ngIf="openEndedQuestions[0]">
        <br />
        <p>
          <b style="text-transform: lowercase">{{ question.title }}:</b>
          {{ question.name }}
        </p>
        <p>{{ question.answer.name }}</p>
      </div>
    </ng-container>
  </div>
  <div class="page-break"></div>
  <div class="row equal-6">
    <ng-container *ngFor="let category of categories">
      <div class="col-xs-6 equal-child" *ngIf="
          category.name !== 'Open-Ended Questions' &&
          category.name !== 'Summary Overview'
        ">
        <div style="padding: 5px" class="panel-body">
          <div class="text-center">
            <h4 class="m-b-md">{{ category.name }}</h4>
            <h5>{{ category.value }}/{{ category.maxValue }}</h5>
          </div>
          <div>
            <div class="progress full progress-small">
              <div [ngStyle]="{
                  width: (100 * category.value) / category.maxValue + '%'
                }" aria-valuemax="100" aria-valuemin="0" aria-valuenow="55" role="progressbar"
                class=" progress-bar progress-bar-{{ category.className }}">
                <span class="sr-only">35% Complete (success)</span>
              </div>
            </div>
            <br />

            <div class="row" *ngIf="getCategoryValue(category.className) as item">
              <div class="col-xs-12">
                <small class="stats-label"><b>{{ category.prerequisiteName }}</b></small>
                <p *ngIf="item.value.name" class="small">
                  {{ item.value.name }}
                </p>
                <p *ngIf="item.value.length > 1">
                  <span *ngFor="let v of item.value; let index = index">
                    <span *ngIf="index !== item.value.length - 1" class="small">{{ v.name
                      }}<span *ngIf="v.level"> ({{ v.level.name }})</span>,
                    </span>
                    <span *ngIf="index === item.value.length - 1" class="small">{{ v.name
                      }}<span *ngIf="v.level"> ({{ v.level.name }})</span></span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-xs-6" *ngFor="
        let summaryOverviewQuestion of result.questions
          | questionCategory : summaryOverviewQuestionCategory
      ">
      <div class="text-center">
        <h4>identity overview statement:</h4>
      </div>
      <p class="small">{{ summaryOverviewQuestion.answer.name }}</p>
    </div>
    <div class="col-xs-12">
      <hr />
      <div class="hpanel">
        <div class="col-xs-6 text-center">
          <small>{{ possessivePrefix }} Score Breakdown</small>
          <br />
          <br />
          <div style="width: 200px; margin-left: auto; margin-right: auto">
            <canvas id="doughnut-chart2"></canvas>
          </div>
          <h1 class="font-extra-bold">
            {{ total }}
          </h1>
          <small>IPSAT score out of 100</small>
        </div>
        <div class="col-xs-6">
          <p>{{ ipsatStage.description }}</p>
        </div>
      </div>
    </div>
  </div>
</div>