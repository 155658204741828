<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="flex flex-wrap">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-body">
            <div class="pull-right">
              <img [src]="'/assets/images/icons/general_resources.svg'" alt="resource icon" class="img-md" />
            </div>
            <h4 class="text-primary-2">General Resources</h4>
            <br />
            <div class="row">
              <div class="col-sm-6 col-xs-12 m-b-md">
                <a class="font-semi-bold flex flex-vertical-center"
                  href="/assets/files/IPSAT_Small_Groups_Field_Guide.pdf" target="_blank">
                  <i class="fa fa-2x fa-file-pdf-o text-success"></i>
                  <span class="m-l-sm">IPSAT for Small Groups Field Guide (PDF)</span>
                </a>
                <br />
                <a class="font-semi-bold flex flex-vertical-center" href="/assets/files/IPSAT_Small_Groups_Launch.pdf"
                  target="_blank">
                  <i class="fa fa-2x fa-file-pdf-o text-success"></i>
                  <span class="m-l-sm">IPSAT for Small Groups Launch Meeting Handout (PDF)</span>
                </a>
                <br />
                <a class="font-semi-bold flex flex-vertical-center" href="/assets/images/general_resources_slide.jpg"
                  target="_blank">
                  <i class="fa fa-2x fa-file-image-o text-success"></i>
                  <span class="m-l-sm">IPSAT for Small Groups Launch Meeting Welcome Slide
                    (JPG)</span>
                </a>
                <br />
                <a class="font-semi-bold flex flex-vertical-center" (click)="viewSmallGroupVideo('ipsat_promo_video')">
                  <i class="fa fa-2x fa-file-video-o text-success"></i>
                  <span class="m-l-sm">IPSAT Promo Video</span>
                </a>
              </div>
              <div class="col-sm-6 col-xs-12">
                <a class="font-semi-bold flex flex-vertical-center"
                  (click)="viewSmallGroupVideo('ipsat_launch_video_1')">
                  <i class="fa fa-2x fa-file-video-o text-success"></i>
                  <span class="m-l-sm">IPSAT for Small Groups Launch Meeting (Video 1)</span>
                </a>
                <br />
                <a class="font-semi-bold flex flex-vertical-center"
                  (click)="viewSmallGroupVideo('ipsat_launch_video_2')">
                  <i class="fa fa-2x fa-file-video-o text-success"></i>
                  <span class="m-l-sm">IPSAT for Small Groups Launch Meeting (Video 2)</span>
                </a>
                <br />
                <a class="font-semi-bold flex flex-vertical-center"
                  (click)="viewSmallGroupVideo('ipsat_launch_video_3')">
                  <i class="fa fa-2x fa-file-video-o text-success"></i>
                  <span class="m-l-sm">IPSAT for Small Groups Launch Meeting (Video 3)</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let resource of smallGroupResources">
        <div class="hpanel">
          <div class="panel-body">
            <div class="pull-right">
              <img [src]="'/assets/images/icons/' + resource.icon" alt="resource icon" class="img-md" />
            </div>
            <h4 class="text-primary-2">
              {{ resource.name
              }}<span *ngIf="resource.subname">: {{ resource.subname }}</span>
            </h4>
            <p>Resources for {{ resource.name }}</p>
            <br />
            <a class="font-semi-bold flex flex-vertical-center" [href]="
                '/assets/files/module_handouts/' +
                resource.participantGuideLink
              " target="_blank">
              <i class="fa fa-2x fa-file-pdf-o text-success"></i>
              <span class="m-l-sm">Participant Guide (PDF)</span>
            </a>
            <br />
            <a class="font-semi-bold flex flex-vertical-center" [href]="
                '/assets/files/module_handouts/' + resource.leaderGuideLink
              " target="_blank">
              <i class="fa fa-2x fa-file-pdf-o text-success"></i>
              <span class="m-l-sm">Leader Guide (PDF)</span>
            </a>
            <br />
            <a class="font-semi-bold flex flex-vertical-center"
              (click)="viewSmallGroupVideo(resource.introVideoIdentifier)">
              <i class="fa fa-2x fa-file-video-o text-success"></i>
              <span class="m-l-sm">Intro Video (3 min)</span>
            </a>
            <br />
            <a class="font-semi-bold flex flex-vertical-center"
              (click)="viewSmallGroupVideo(resource.teachingVideoIdentifier)">
              <i class="fa fa-2x fa-file-video-o text-success"></i>
              <span class="m-l-sm">Teaching Video (10 min)</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>