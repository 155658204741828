<div class="row nexleader-onboarding-h100">
  <div
    class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center bg-white"
  >
    <div class="nexleader-ecourse-content-wrapper nexleader-onboarding-h100">
      <h2>Let's complete your profile...</h2>
      <p class="m-t-md m-b-md">
        Having the following information helps us curate a path for you when
        using the IPSAT.
      </p>
      <div class="row">
        <div class="form-group col-lg-6">
          <label class="control-label">First Name</label>
          <input
            type="text"
            placeholder="John"
            class="form-control"
            [(ngModel)]="user.firstName"
          />
        </div>

        <div class="form-group col-lg-6">
          <label class="control-label">Last Name</label>
          <input
            type="text"
            placeholder="Smith"
            class="form-control"
            [(ngModel)]="user.lastName"
          />
        </div>
        <div class="form-group col-lg-6">
          <label class="control-label">Year of Birth</label>
          <select class="form-control" [(ngModel)]="user.birthYear">
            <option *ngFor="let year of getBirthYears()" [ngValue]="year">
              {{ year }}
            </option>
          </select>
        </div>

        <div class="form-group col-lg-6">
          <label class="control-label">Gender</label>
          <app-nexleader-input-enum
            *ngIf="user"
            [model]="user.gender"
            [enumName]="'Genders'"
            (realOnChange)="user.gender = $event"
          ></app-nexleader-input-enum>
        </div>

        <div class="form-group col-lg-12">
          <label class="control-label">Email</label>
          <input
            type="text"
            placeholder="john.smith@gmail.com"
            class="form-control"
            [(ngModel)]="user.email"
          />
        </div>

        <div class="form-group col-lg-12">
          <label class="control-label"
            >I prefer my coach to contact me via</label
          >
          <app-nexleader-input-enum
            *ngIf="user"
            [model]="user.preferredContactType"
            [enumName]="'ContactTypes'"
            (realOnChange)="user.preferredContactType = $event"
          ></app-nexleader-input-enum>
        </div>

        <div
          class="form-group col-lg-12"
          *ngIf="
            user.preferredContactType && user.preferredContactType !== 'null'
          "
        >
          <label class="control-label">{{
            user.preferredContactType.name
          }}</label>
          <input
            type="text"
            placeholder="{{ user.preferredContactType.example }}"
            class="form-control"
            [(ngModel)]="user.preferredContactAddress"
          />
        </div>
        <div class="col-lg-12">
          <hr />
        </div>

        <div class="col-lg-12" *ngIf="!isReadyToAdvance()">
          <p class="nexleader-onboarding-text-red">
            Please complete all fields before continuing.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center bg-snow"
  >
    <div class="nexleader-ecourse-content-wrapper">
      <h3
        class="font-bold text-primary-2 text-center"
        style="line-height: 36px"
      >
        How to Grow Your Self-Awareness and Lead Yourself Better
      </h3>

      <div style="text-align: center">
        <app-nexleader-vimeo-video
          [vimeoId]="'360905830'"
          [hideDownloadLink]="true"
        ></app-nexleader-vimeo-video>
      </div>
      <br />
      <p class="text-center" style="font-size: 16px">
        This 10-minute video will help you understand what to expect from this
        process. Our research shows that individuals who watch this video save
        time and get more benefits out of the experience.
      </p>
      <hr class="m-t-lg m-b-lg" />
      <div class="flex align-items-center">
        <div class="checkbox">
          <input
            type="checkbox"
            [(ngModel)]="hasViewedIntroductionVideo"
            id="hasViewedIntroductionVideo"
          />
          <label for="hasViewedIntroductionVideo" class="control-label">
            I've watched the above video outlining the IPSAT process
          </label>
        </div>
        <div class="flex-grow"></div>
        <button
          class="btn btn-lg btn-success"
          [attr.disabled]="
            !isReadyToAdvance() || !hasViewedIntroductionVideo ? true : null
          "
          (click)="next()"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</div>
