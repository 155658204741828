/* global angular */
/**
 * nexleaderViewAdminSessionsList
 *
 * angular component
 *
 * A view used to display ALL sessions for the entire application within a table (through a component)
 */
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderSessionsTableComponent } from '../../components/sessions-table/sessions-table.component';

@Component({
  selector: 'app-nexleader-admin-session-list',
  standalone: true,
  imports: [CommonModule, NexleaderSessionsTableComponent],
  templateUrl: './admin-session-list.component.html',
})
export class NexleaderAdminSessionListComponent { }

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderAdminSessionListComponent',
//   downgradeComponent({
//     component: NexleaderAdminSessionListComponent,
//   }) as angular.IDirectiveFactory
// );
