<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel m-n">
          <div class="panel-heading">
            <div class="panel-tools">
              <a (click)="openCreateDiscount()"
                ><i class="fa fa-plus"></i>&nbsp;&nbsp;Create new Direct
                Purchase Discount Code</a
              >
            </div>
            <span>Direct Purchase Discount Codes</span>
          </div>
          <div class="panel-body">
            <div
              class="direct-discount-table table-responsive"
              *ngIf="discounts.length > 0"
            >
              <table class="table">
                <thead>
                  <th>Name</th>
                  <th>Product</th>
                  <th>Onboarding Experience</th>
                  <th>Survey Type</th>
                  <th>Includes Clifton Strengths</th>
                  <th>Discount Amount (%/$)</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  <tr *ngFor="let discount of discounts">
                    <td>{{ discount.name }}</td>
                    <td>{{ discount.directDiscountProduct.name }}</td>
                    <td>{{ discount.onboardingExperience?.name || 'Any' }}</td>
                    <td>{{ discount.surveyType?.name || 'Any' }}</td>
                    <td *ngIf="discount.discountType.identifier === 'flat_fee'">
                      {{ discount.cliftonStrengthsAssessment ? 'Yes' : 'No' }}
                    </td>
                    <td *ngIf="discount.discountType.identifier !== 'flat_fee'">
                      Either
                    </td>
                    <td>{{ discount._discountAmountString }}</td>
                    <td><a (click)="remove(discount)">Delete</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p *ngIf="discounts.length < 1">There are no discounts to show.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
