/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewGroupUsers
 *
 * angular component
 *
 * Shows all users that are associated with the current group that the user is viewing.
 */
import { AuthService } from './../../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderQuickViewNotSelectedComponent } from '../../components/quick-view-not-selected/quick-view-not-selected.component';
import { NexleaderQuickViewComponent } from '../../components/quick-view/quick-view.component';
import { NexleaderUserCardComponent } from '../../components/user-card/user-card.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GroupService } from '../../../../services/group.service';
import { UserService } from '../../../../services/user.service';
import { InviteService } from '../../../../services/invite.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderAddCoachUserModalComponent } from '../../../multigroup-coaching/components/add-coach-user-modal/add-coach-user-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nexleader-group-users',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderQuickViewNotSelectedComponent,
    NexleaderQuickViewComponent,
    NexleaderUserCardComponent,
  ],
  templateUrl: './group-users.component.html',
})
export class NexleaderGroupUsersComponent implements OnInit {
  routeParams: any;
  selectedUser: any;
  enums: any;
  group: any;
  users: any[] = [];
  coaches: any[] = [];
  invites: any[] = [];
  licensingPartnerId: string = '';
  isLicensingPartnerOrSuperAdmin: boolean = false;
  currentUser: any;
  relativeUrl: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private groupService: GroupService,
    private userService: UserService,
    private inviteService: InviteService,
    private enumsService: EnumsService,
    private errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;
    this.relativeUrl = `/groups/${this.routeParams['group_id']}/users`;
    const payload = this.authService.getTokenPayload();

    this.currentUser = payload.user;
    //Payload may not of roles if you're a multigroup coach
    if (payload.roles) {
      this.isLicensingPartnerOrSuperAdmin =
        payload.roles.indexOf('admin') >= 0 ||
        payload.roles.indexOf('partneradmin') >= 0;
    }
    this.load();
  }

  load() {
    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.enums = enums;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.groupService
      .get(this.routeParams['group_id'])
      .pipe(
        tap((group) => {
          this.group = group;
          this.licensingPartnerId = this.group.licensingPartner._id;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.userService
      .queryGroup(this.routeParams['group_id'])
      .pipe(
        tap((users) => {
          this.users = users.map((user) => {
            user._name = user.firstName + ' ' + user.lastName;
            user._coach = user.coach
              ? user.coach.firstName + ' ' + user.coach.lastName
              : 'N/A';
            return user;
          });

          this.coaches = this.users.filter(
            (user) => user.roles.indexOf('coach') >= 0
          );
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.inviteService
      .queryGroupIndex(this.routeParams['group_id'])
      .pipe(
        tap((invites) => {
          this.invites = invites;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  onUserChange() {
    this.load();
  }

  /**
   * openAddCoachUserModal()
   *
   * function
   *
   * Opens a modal allowing a super admin to create a coach user (multi-group coach).
   */
  openAddCoachUserModal() {
    const modalRef = this.modalService.show(
      NexleaderAddCoachUserModalComponent,
      {
        initialState: {
          // Map the licensing partner data to the child
          licensingPartnerId: this.licensingPartnerId,
          routeParams: this.routeParams,
        },
      }
    );

    modalRef.content?.close.subscribe(() => {
      modalRef.hide();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([this.relativeUrl]);
      });
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupUsersComponent',
//   downgradeComponent({
//     component: NexleaderGroupUsersComponent,
//   }) as angular.IDirectiveFactory
// );
