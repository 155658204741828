<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="container p-v-50">
  <app-nexleader-if-no-stripe>
    <div class="row">
      <div class="col-md-10 col-md-offset-1 col-xs-12">
        <div class="hpanel warning">
          <div class="color-line color-line-warning"></div>
          <div class="panel-body">
            <h3 class="mt-">Unable to Contact Stripe</h3>
            <p>
              Your computer is not able to talk to our payment provider, Stripe.
              Please try disabling ad blockers or other browser extensions that
              interfere with network traffic. Stripe allows us to securely
              process your order, and you cannot complete your purchase without
              Stripe.
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-nexleader-if-no-stripe>
  <div class="row">
    <div class="col-sm-8 col-sm-offset-2 col-xs-12">
      <div class="text-center m-b-lg">
        <h1>Create a Team Report</h1>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <div>
            <div class="pull-right">
              Step {{ currentStep.index + 1 }}/{{
                createTeamReportSteps.length
              }}
            </div>
            <h4 class="m-b-md">{{ currentStep.name }}</h4>
            <hr class="m-t-md m-b-md" />
          </div>
          <div *ngIf="currentStep.identifier === 'info'">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="control-label">Team Name</label>
                  <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="teamName"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-xs-12">
                <p>&nbsp;</p>
                <p>
                  A team name is a short, memorable name used to identify the
                  group of the users on this team report.
                </p>
              </div>
              <div class="clearfix"></div>
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="control-label">Team Leader</label>
                  <select [(ngModel)]="selectedTeamLeader" class="form-control">
                    <option
                      *ngFor="let groupAdmin of teamLeaders; trackBy: trackById"
                      [ngValue]="groupAdmin"
                    >
                      {{ groupAdmin.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 col-xs-12">
                <p>&nbsp;</p>
                <p>
                  A team leader is a user that will be in charge of this team
                  report.
                </p>
              </div>
            </div>
          </div>

          <div *ngIf="currentStep.identifier === 'members'">
            <p class="m-b-md">
              Your IPSAT Team Report may include anyone in your group that has
              completed the IPSAT and a coaching session. If you don't see a
              member of your team in the pull-down menu, it suggests he/she has
              not completed the IPSAT or the IPSAT coaching session. In some
              cases, users may have completed both the IPSAT and follow-up
              coaching session, but the coaching session was not marked
              completed by the coach. Please contact
              <a href="mailto:Angeline.Patrick@nexleader.com"
                >Angeline.Patrick&#64;nexleader.com</a
              >
              if you have a question. <br /><br />Select users to create a team
              report for.
            </p>
            <div class="form-group">
              <input
                type="text"
                class="form-control m-b-sm"
                placeholder="Search for a user"
                [(ngModel)]="userSearchText"
              />
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Selected</th>
                    <th>Name</th>
                    <th>IPSAT</th>
                    <th>Skills Inventory</th>
                    <th>Coaching Session</th>
                    <th>Not Opted-out</th>
                    <th>Eligible</th>
                    <th>
                      Can view team report
                      <i
                        class="fa fa-info-circle"
                        tooltip="Checking this box allows these users to be able to view the team report you are creating."
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let user of selectableUsers
                        | filter : undefined : userSearchText
                    "
                    [ngClass]="{ 'bg-success': user.selected }"
                    (click)="selectUser(user)"
                  >
                    <td width="50">
                      <input
                        type="checkbox"
                        [id]="
                          'nexleader-test-team-report-select-checkbox-' +
                          user._id
                        "
                        (click)="selectUser(user)"
                        [(ngModel)]="user.selected"
                        [disabled]="!user.eligibility.isEligibleForTeamReport"
                        nexleaderNoClick
                      />
                    </td>
                    <td
                      width="200"
                      class="nexleader-test-team-report-eligibility-name"
                    >
                      {{ user.firstName }} {{ user.lastName }}
                    </td>
                    <td>
                      <span *ngIf="user.eligibility.hasSurveyResult"
                        ><i class="fa fa-check text-success"></i
                      ></span>
                    </td>
                    <td>
                      <span *ngIf="user.eligibility.hasSkillsInventory"
                        ><i class="fa fa-check text-success"></i
                      ></span>
                    </td>
                    <td>
                      <span *ngIf="user.eligibility.hasSession"
                        ><i class="fa fa-check text-success"></i
                      ></span>
                    </td>
                    <td>
                      <span *ngIf="user.eligibility.hasNotOptedOut"
                        ><i class="fa fa-check text-success"></i
                      ></span>
                    </td>
                    <td>
                      <span *ngIf="user.eligibility.isEligibleForTeamReport">
                        <i
                          class="fa fa-check text-success"
                          [id]="
                            'nexleader-test-team-report-eligible-check-' +
                            user._id
                          "
                        ></i>
                      </span>
                    </td>
                    <td width="100">
                      <input
                        type="checkbox"
                        [id]="
                          'nexleader-test-team-report-allowed-to-view-checkbox-' +
                          user._id
                        "
                        [(ngModel)]="user.isAllowedToViewTeamReport"
                        [disabled]="!user.selected"
                        nexleaderNoClick
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="currentStep.identifier === 'skills'">
            <p class="m-b-md">
              Your team report will contrast the self-assessed skills
              represented on your team, and the level of mastery for each skill,
              with an "Ideal Skill Set Profile." Follow the instructions below
              to complete the Ideal Skill Set Profile for your team.
              <br /><br />Click on the "cards" below to identify up to ten
              skills you believe are most essential for your team to thrive. It
              is understandable to desire ALL the skills for every team. But
              focus on the top ten mission-critical skills for your team. If you
              change your mind, click on a "card" to deselect it. On the next
              step, you will be asked to rate the level for each of the Ideal
              Skills you have selected on a mastery continuum.
            </p>
            <div class="row">
              <div
                class="col-sm-3 col-xs-6"
                *ngFor="let skill of defaultSkills; let index = index"
              >
                <div
                  class="panel-body panel-select panel-sm line-wrap"
                  [id]="'nexleader-test-team-report-skill-panel-' + index"
                  [ngClass]="{
                    'panel-select-active': skill.selected,
                    'cursor-no':
                      selectedSkills().length >= 10 && !skill.selected
                  }"
                  (click)="selectSkill(skill)"
                >
                  <div class="font-bold">{{ skill.name }}</div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="currentStep.identifier === 'selected_skills'">
            <div *ngIf="selectedSkills().length > 0">
              <p class="m-b-md">
                Your team report will contrast the self-assessed skills
                represented on your team, and the level of mastery for each
                skill, with an "Ideal Skill Set Profile." Follow the
                instructions below to complete the Ideal Skill Set Profile for
                your team.
                <br /><br />Select the level of mastery required for your team
                with each of the skills identified in the Ideal Skill Set
                Profile. When you have identified the level of mastery for each
                skill, click "Next Step" to continue.
              </p>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Skill Name</th>
                      <th>Level of Mastery</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let skill of selectedSkills(); let index = index"
                    >
                      <td>{{ skill.name }}</td>
                      <td>
                        <div style="margin: 1px">
                          <select
                            [id]="
                              'nexleader-test-team-report-skill-level-selector-' +
                              index
                            "
                            class="form-control nexleader-test-team-report-skill-level-selector"
                            [(ngModel)]="skill.goalLevel"
                          >
                            <option
                              *ngFor="
                                let skillLevel of skillLevels;
                                trackBy: trackByValue
                              "
                              [ngValue]="skillLevel.value"
                            >
                              {{ skillLevel.name }}
                            </option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p *ngIf="selectedSkills().length < 1">
              You must select skills before editing the goal level.
            </p>
          </div>

          <div *ngIf="currentStep.identifier === 'checkout'">
            <p class="m-b-sm">
              <span class="font-bold">Team Leader:</span>
              {{ selectedTeamLeader.firstName }}
              {{ selectedTeamLeader.lastName }}
            </p>
            <p class="font-bold m-b-xs">Team Members</p>
            <ul class="m-b-sm">
              <li *ngFor="let user of selectedUsers()">
                {{ user.firstName }} {{ user.lastName }}
              </li>
            </ul>
            <hr />
            <!-- Discount code interface -->
            <div *ngIf="!teamReportDiscountName">
              <div class="form-group">
                <label class="control-label">Discount Code</label>
                <input
                  class="form-control"
                  type="text"
                  [(ngModel)]="candidateTeamReportDiscountName"
                />
              </div>
              <button
                class="btn btn-default"
                (click)="validateCandidateTeamReportDiscountCode()"
              >
                Validate
              </button>
            </div>
            <div *ngIf="teamReportDiscountName">
              <p>
                Discount code
                <i>{{ teamReportDiscountName }}</i> successfully applied.
                <a (click)="clearTeamReportDiscountName()">Undo</a>.
              </p>
            </div>
            <hr />
            <div class="flex flex-column-md">
              <div>
                <p>Your total is...</p>
                <p class="text-muted small">
                  The cost of a team report is $15 per team member, with a
                  minimum of $75 and a maximum of $225.
                </p>
              </div>
              <div class="flex-grow"></div>
              <h4
                class="font-bold m-t-none m-b-none"
                id="nexleader-test-team-report-price"
              >
                <app-nexleader-currency
                  *ngIf="price"
                  [currencyQuantity]="price.currencyQuantity"
                  [currencyCode]="price.currencyCode"
                ></app-nexleader-currency>
                <span *ngIf="!price">Loading Price...</span>
              </h4>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>

        <div
          class="panel-footer text-center flex flex-column-md flex-vertical-center"
        >
          <div class="pull-left">
            <button
              class="btn btn-default"
              (click)="previous()"
              [attr.disabled]="currentStep.index < 1 ? true : null"
            >
              Previous Step
            </button>
          </div>
          <div class="flex-grow"></div>
          <span *ngIf="currentError()">
            {{ currentError() }}
          </span>
          <div class="flex-grow"></div>
          <div class="pull-right">
            <button
              class="btn btn-default"
              (click)="next()"
              [attr.disabled]="currentError() ? true : null"
              *ngIf="!(currentStep.index >= createTeamReportSteps.length - 1)"
            >
              Next Step
            </button>
            <button
              class="btn btn-success"
              (click)="createTeamReport()"
              [attr.disabled]="transactionIsInProgress ? true : null"
              *ngIf="currentStep.index === createTeamReportSteps.length - 1"
            >
              Purchase Team Report
            </button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="text-center">
        <a [routerLink]="['/groups', group_id, 'teamReports']"
          >Go Back to Team Reports</a
        >
      </div>
    </div>
  </div>
</div>
