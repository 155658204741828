/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderInputDatetime
 *
 * angular component
 *
 * input option for javascript date object
 *
 * presents a date picker and a time picker
 *  timezone-aware
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderTimezoneSelectComponent } from '../timezone-select/timezone-select.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-nexleader-input-datetime',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderTimezoneSelectComponent,
    TimepickerModule,
    BsDatepickerModule,
    FormsModule,
  ],
  templateUrl: './input-datetime.component.html',
})
export class NexleaderInputDatetimeComponent implements OnInit, OnDestroy {
  /**
   * modelDate
   *
   * two-way string binding
   *
   * basically ng-model
   *
   * state changes/mutations should propagate properly (like ng-model)
   *
   * ISO string two-way binding for date model
   */
  @Input() modelDate: any;

  /**
   * modelTimezone
   *
   * two-way string binding
   *
   * basically ng-model
   *
   * state changes/mutations should propagate properly (like ng-model)
   *
   * timezone string
   */
  @Input() modelTimezone: string = '';

  /**
   * onChange
   *
   * event callback expression
   *
   * called after a $timeout after an ng-change callback from the option dropdown
   *
   * we wrap this in a $timeout so that there's time for the data to propagate upwards through
   *  the model binding
   */
  @Output() realOnChange = new EventEmitter<any>();

  /**
   * openState
   *
   * boolean
   *
   * uib-datepicker forces us to store the open/close state of the picker window in our
   *  scope via a two-way data binding; this is that
   *
   * see open()
   */
  openState = false;

  /**
   * internalDateModel
   *
   * date (javascript date object0
   *
   * we output a timezone and a ISO string; however, we need a JS date object to bind with
   *  the datepicker; this is the binding with the datepicker, and we convert it using a $watch
   */
  internalDateModel: Date | null = null;
  // moment: any;

  private _destroy$ = new Subject();

  constructor() { }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  /**
   * $onInit()
   *
   * function: angular event handler
   *
   * called after binding
   *
   * validates bindings then
   */
  ngOnInit(): void {
    if (this.modelDate === undefined) {
      throw new Error(
        'Cannot initialize NexleaderInputDatetimeComponent with an undefined modelDate.'
      );
    }

    if (this.modelTimezone === undefined) {
      throw new Error(
        'Cannot initialize NexleaderInputDatetimeComponent with an undefined modelTimezone.'
      );
    }

    if (this.modelDate && this.modelTimezone) {
      this.internalDateModel = this.fromUTC(
        this.modelDate,
        this.modelTimezone
      );
    }
  }

  /**
   * open()
   *
   * function
   *
   * opens the datepicker window by setting the two-way state binding to true
   */
  open(): void {
    this.openState = true;
  }

  /**
   * toISO()
   *
   * private function
   *
   * converts a date object and a timezone to an ISO string
   *
   * @param a JS date object from bootstrap date picker
   * @param timezone the timezone we actually want that date in
   * @returns {string} the ISO string for $ctrl.modelDate
   */
  toISO(a: Date, timezone: string): string {
    return moment
      .tz(
        [
          a.getFullYear(),
          a.getMonth(),
          a.getDate(),
          a.getHours(),
          a.getMinutes(),
          a.getSeconds(),
        ],
        timezone
      )
      .toISOString();
  }

  /**
   * fromUTC()
   *
   * converts from the existing model on load (used to update our internal model)
   *
   * converts from a UTC/ISO string and a timezone to a date object for bootstrap datepicker
   *
   * @param a the date string to convert
   * @param timezone the timezone string to convert
   * @returns {Date} a date object for the date picker
   */
  fromUTC(a: string, timezone: string): Date {
    const mstart = moment(a).tz(timezone);

    return new Date(
      mstart.year(),
      mstart.month(),
      mstart.date(),
      mstart.hour(),
      mstart.minute(),
      mstart.second()
    );
  }

  //After an update from the date picker or timezone selector,
  // convert to an ISO string/timezone pair for the external model
  update(): void {
    if (!this.internalDateModel || !this.modelTimezone) {
      this.modelDate = null;
      this.onChange();
      return;
    }

    this.modelDate = this.toISO(this.internalDateModel, this.modelTimezone);
    this.onChange();
  }

  timezoneChanged(updatedTimezone: string) {
    this.modelTimezone = updatedTimezone;
    this.update();
  }

  /**
   * onChange()
   *
   * wraps realOnChange() in a $timeout
   *
   * see docs for realOnChange in bindings
   */
  onChange(): void {
    this.realOnChange.emit({
      date: this.modelDate,
      timezone: this.modelTimezone,
    });
  }
}
