import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionCategory',
  standalone: true,
})
export class QuestionCategoryPipe implements PipeTransform {
  transform(questions: any[], category: any): any {
    const result: any[] = [];

    questions.forEach((question, key) => {
      if (
        question &&
        question.questionCategory &&
        category &&
        question.questionCategory.index === category.index
      ) {
        result.push(question);
      }
    });

    return result;
  }
}
