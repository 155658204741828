<!-- Copyright (C) nexleader - All Rights Reserved -->
<!-- Unauthorized copying of this file, via any medium is strictly prohibited -->
<!-- Proprietary and confidential -->
<!-- Written for nexleader <myipsat.com>, 2016-2018 -->

<div class="login-container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center m-b-md">
        <div class="text-center m-b-md">
          <img src="/assets/images/logo.svg" width="125" height="100" />
        </div>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <h3 class="text-center">Evaluate Your Coach</h3>
          <br />
          <form (ngSubmit)="submitEvaluation(evaluation)">
            <div *ngFor="let question of evaluation?.questions" class="m-b-xl">
              <div *ngIf="question.questionType.name === 'Evaluation Range'">
                <h5>{{ question.name }}</h5>
                <br />
                <div class="evalBox">
                  <div *ngFor="let answer of question.answers; let index = index" class="nexleaderEvalOuter" [ngClass]="{
                      nexleaderEvalOuterActive: question.answer.value === index
                    }" (click)="setAnswer(question, answer.value)">
                    {{ answer.value }}
                    <!--<input type="radio" name="answer{{$parent.$index}}" [ngValue]="(answer.value+1)"
                                 [(ngModel)]="question.answer.value"> {{ answer.name }}-->
                  </div>
                </div>
              </div>
              <div *ngIf="question.questionType.name === 'Input Field'">
                <div class="form-group">
                  <h5 class="m-b-sm">{{ question.name }}</h5>
                  <textarea class="form-control" name="name" [(ngModel)]="question.answer.name" rows="3"></textarea>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-success btn-block" [attr.disabled]="invalid() ? true : null">
              Submit
            </button>
            <div class="text-center m-t-sm">
              <span *ngIf="invalid()" class="text-red">{{ invalid() }}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <strong>IPSAT</strong> <br />
      2017 Copyright nexleader
    </div>
  </div>
  <br />
</div>