<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="container p-v-50-sm">
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <h4 class="text-primary-2 font-semi-bold m-b-sm">Top Twelve Values</h4>
      <p class="m-b-md">
        Your top 12 values are listed below, in order of priority based on the
        force ranking exercise, with the values you positioned closest to the
        mostly/always side of the scale at the top. Your next step is to select
        your top 7 values out of the list of your top 12. You may choose the
        first 7, based on the priority order of the force ranking exercise, or
        you may choose a different list of 7 values. Simply click on the + for a
        value in the top 12 to move it to your list of 7. If you change your
        mind, you may click the - to remove a value from your list of 7.
      </p>
      <div class="hpanel">
        <div class="panel-body">
          <div
            *ngFor="
              let value of topTwelveValues | orderBy : 'rating' : true;
              let index = index;
              let last = last
            "
          >
            <div class="flex flex-vertical-center">
              <h4 class="font-semi-bold text-success m-r-sm">
                {{ index + 1 }}
              </h4>
              <div>
                <h5 class="text-primary-2">{{ value.name }}</h5>
                <p>
                  <small
                    class="text-secondary font-bold m-r-sm"
                    *ngFor="let domain of value.personalValueDomains"
                    >{{ domain.name }}
                  </small>
                </p>
              </div>
              <div class="flex-grow"></div>
              <button
                class="btn btn-sm btn-success"
                id="nexleader-test-pvi-step-four-select-{{ index }}"
                [attr.disabled]="shouldDisableSelectionFor(value) ? true : null"
                (click)="toggleValueSelection(value.name)"
              >
                {{ value.isInTopSeven ? "Unselect" : "Select" }}
              </button>
            </div>
            <hr *ngIf="!last" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <h4 class="text-primary-2 font-semi-bold m-b-md">Top Seven Values</h4>
      <p *ngIf="getValuesInTopSeven().length < 1">
        No values have been selected yet. Select one to the left.
      </p>
      <div class="row">
        <div
          class="col-sm-6 col-xs-12"
          *ngFor="let value of getValuesInTopSeven(); let index = index"
        >
          <div class="hpanel">
            <div class="panel-body">
              <div class="flex flex-vertical-center">
                <div>
                  <h5 class="text-primary-2 font-semi-bold">
                    {{ value.name }}
                  </h5>
                  <small
                    class="text-secondary font-bold m-r-sm"
                    *ngFor="let domain of value.personalValueDomains"
                    >{{ domain.name }}
                  </small>
                </div>
                <div class="flex-grow"></div>
                <i
                  class="fa fa-minus text-muted cursor-pointer"
                  id="nexleader-test-pvi-step-four-deselect-{{ index }}"
                  (click)="toggleValueSelection(value.name)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
