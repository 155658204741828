<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<input
  type="file"
  name="file"
  class="fileInput ng-hide"
  accept="{{ accept }}"
  (change)="onFileUpdate($event)"
  style="display: none;"
/>

<button
  style="margin-right: 4px"
  class="dropdown-toggle btn btn-default"
  (click)="clickEvent()"
>
  Select File
</button>
