/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderQuickViewComponent } from '../../../core/components/quick-view/quick-view.component';
import { NexleaderUserCardComponent } from '../../../core/components/user-card/user-card.component';
import { NexleaderQuickViewNotSelectedComponent } from '../../../core/components/quick-view-not-selected/quick-view-not-selected.component';
import { ActivatedRoute } from '@angular/router';
import { SuccessService } from '../../../../services/success.service';
import { UserService } from '../../../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderNewUserModalComponent } from '../../../core/components/modals/new-user-modal/new-user-modal.component';

@Component({
  selector: 'app-nexleader-licensing-partner-users',
  standalone: true,
  imports: [CommonModule, NexleaderQuickViewComponent, NexleaderUserCardComponent, NexleaderQuickViewNotSelectedComponent],
  templateUrl: './licensing-partner-users.component.html'
})
export class NexleaderLicensingPartnerUsersComponent implements OnInit {
  routeParams: any;
  searchText: string = '';
  users: any[] = [];
  selectedUser: any;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private userService: UserService,
    private successService: SuccessService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    this.userService.queryLicensingPartnerIndex(this.routeParams['licensing_partner_id']).pipe(
      tap((data) => {
        this.users = data;
        this.users.forEach(user => {
          user._name = user.firstName + ' ' + user.lastName;
          if (user.group) user._groupName = user.group.name;
          if (user.licensingPartner) user._licensingPartnerName = user.licensingPartner.name;
        });
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  open(): void {
    const modalRef = this.modalService.show(NexleaderNewUserModalComponent);

    modalRef.content?.onSave.subscribe(() => {
      modalRef.hide();
      this.successService.handle();
      this.load();
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderLicensingPartnerUsersComponent',
//   downgradeComponent({
//     component: NexleaderLicensingPartnerUsersComponent,
//   }) as angular.IDirectiveFactory
// );
