/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-wrapper-panel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wrapper-panel.component.html',
})
export class NexleaderWrapperPanelComponent {
  @Input() title: string = '';
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderWrapperPanelComponent',
//   downgradeComponent({
//     component: NexleaderWrapperPanelComponent,
//   }) as angular.IDirectiveFactory
// );
