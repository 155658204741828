<div class="row">
  <div
    class="col-md-3 col-sm-6 col-xs-12 m-b-md col-3-print text-center"
    *ngFor="let category of model"
  >
    <h5
      class="m-b-sm text-strengths-{{
        category.identifier
      }} show-mobile hideOnPrint"
    >
      {{ category.name }}
    </h5>
    <div *ngFor="let strength of category.strengths" class="m-b-xs">
      <p>{{ strength.name }}</p>
    </div>
  </div>
</div>
