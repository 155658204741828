/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderOnboardingPreassessments
 *
 * angular component: onboarding step
 *
 * This component follows the onboarding step component pattern. This means it has a few common function names for
 *  validation of next buttons and a "nexleader-next" function binding for the nexleaderOnboarding component to call.
 *
 * This component is responsible for collecting and validating the "prerequisites" section of the
 *  user object.
 *
 * This component appears a horizontally split view with preassessment entry on the left and preassessment selection on
 *  right.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { EnumsService } from '../../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SkillsInventoryResultService } from '../../../../../services/skills-inventory-result.service';
import { UserService } from '../../../../../services/user.service';
import { SuccessService } from '../../../../../services/success.service';
import { NexleaderOnboardingPersonalityComponent } from './onboarding-personality/onboarding-personality.component';
import { NexleaderOnboardingStrengthsComponent } from './onboarding-strengths/onboarding-strengths.component';
import { NexleaderOnboardingSkillsComponent } from './onboarding-skills/onboarding-skills.component';
import { NexleaderOnboardingSpiritualGiftsComponent } from './onboarding-spiritual-gifts/onboarding-spiritual-gifts.component';
import { NexleaderOnboardingPersonalValuesInventoryComponent } from './onboarding-personal-values-inventory/onboarding-personal-values-inventory.component';
import { NexleaderOnboardingPassionsComponent } from './onboarding-passions/onboarding-passions.component';
import { NexleaderPreIpsatChecklistComponent } from './pre-ipsat-checklist/pre-ipsat-checklist.component';
import { NexleaderOnboardingModuleIntroComponent } from '../onboarding-module-intro/onboarding-module-intro.component';

@Component({
  selector: 'app-nexleader-onboarding-preassessments',
  standalone: true,
  imports: [CommonModule, NexleaderOnboardingPersonalityComponent, NexleaderOnboardingStrengthsComponent,
    NexleaderOnboardingSkillsComponent, NexleaderOnboardingSpiritualGiftsComponent,
    NexleaderOnboardingPersonalValuesInventoryComponent, NexleaderOnboardingPassionsComponent,
    NexleaderPreIpsatChecklistComponent, NexleaderOnboardingModuleIntroComponent
  ],
  templateUrl: './onboarding-preassessments.component.html'
})
export class NexleaderOnboardingPreassessmentsComponent implements OnInit {
  @Output() boundNext = new EventEmitter();
  routeParams: any;
  personalInfoOnboardingStep: any;
  previousOnboardingStep: any;
  onboardingStep: any;
  nextOnboardingStep: any;
  AllPrerequisites: any;
  AllModuleContexts: any;
  Prerequisites: { [key: string]: any } = {};
  user: any;
  group: any;
  scoredSkillsInventory: any;
  selectedPrerequisite: any;

  /**
   * showSmallGroupModuleIfRelevant
   *
   * an additional piece of internal state is required to show/hide
   *  small group modules. this flag is used to show/hide the module when
   *  the previous prereq is complete but the user has not yet clicked next
   *
   * it is set to true by onNextPrereqButtonClicked() when the user clicks next
   * it is set to false in completeModuleAndAdvance() when the user marks the module complete
   *
   * it is combined with the small group status and module index to determine whether or not to
   *  show the small group module
   *
   * if the controller is loading fresh, this value should match the complete state of the current
   *  small group module
   *
   * this is updated in the $onInit() function
   *
   * @type {boolean} see above
   */
  showSmallGroupModuleIfRelevant: boolean = false;

  /**
   * overrideSmallGroupModuleIndex
   *
   * boolean
   *
   * set to a truthy value if we need to override the system's guess at
   *  the current small group module index
   *
   * the index usually corresponds to the selected prereq
   *
   * however, the ipsat module does not correspond to a prereq, so when we get there
   *  we have to override the rule and set it to 6
   *
   * this is set to six by onNextPrereqButtonClicked() if we have reached the IPSAT module
   *
   * @type {boolean|int}
   */
  overrideSmallGroupModuleIndex: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private enumsService: EnumsService,
    private userService: UserService,
    private skillsInventoryResultService: SkillsInventoryResultService,
    private errorHandler: ErrorHandlerService,
    private successHandler: SuccessService
  ) { }

  /**
   * $onInit()
   *
   * angular event handler function
   *
   * called when the component binding is complete
   *
   * loads the user we're editing
   * loads any additional information necessary for running the view
   */
  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    forkJoin([
      this.enumsService.getEnums(),
      this.userService.getUser(this.routeParams['user_id']),
      this.skillsInventoryResultService.queryUserScores(this.routeParams['user_id'])
    ]).pipe(
      tap(([enumsData, userData, skillsData]) => {
        this.personalInfoOnboardingStep = enumsData.OnboardingSteps.PERSONAL_INFO;
        this.previousOnboardingStep = enumsData.OnboardingSteps.VIDEO;
        this.onboardingStep = enumsData.OnboardingSteps.PREASSESSMENTS;
        this.nextOnboardingStep = enumsData.OnboardingSteps.DONE;

        this.AllPrerequisites = enumsData.AllPrerequisites;
        this.AllModuleContexts = enumsData.AllModuleContexts;

        this.Prerequisites = enumsData.Prerequisites;

        this.user = userData;
        this.group = userData.group;

        //This is the on-boarding, so we know they won't have more than one.
        // (we don't show them the button if they've taken it).
        this.scoredSkillsInventory = skillsData[0];

        this.ensurePrerequisitesDataStructureExists();
        this.selectFirstIncompletePrereq();
        this.showSmallGroupModuleIfRelevant = !this.hasCompletedCurrentSmallGroupModule();
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null)
      })
    ).subscribe();
  }

  /**
   * isUserInSmallGroup()
   *
   * pure function
   *
   * called by UI logic and canSelectPrereq logic to determine if the user is in a small gorup
   *
   * @returns boolean true if the user is in a small group; false if not
   */
  isUserInSmallGroup(): boolean {
    return this.user && !!this.user.smallGroup;
  }

  /**
   * ensurePrerequisitesDataStructureExists()
   *
   * function: mutates controller state
   *
   * ensures that a pull prereq data structure exists and is properly formatted
   *  at $ctrl.user.prerequisites
   *
   * this is necessary because the backend doesn't populate a default for us
   *  and subcomponents require a data structure to bind to
   *
   * doesn't overwrite any existing data
   *
   * called on load
   */
  ensurePrerequisitesDataStructureExists() {
    // If the user doesn't exist, you should load it
    // This function doesn't do that
    if (!this.user) throw new Error('can\'t call ensurePrerequisitesDataStructureExists() without a user object on the controller');

    // If there's no object, add it
    if (!this.user.prerequisites) {
      this.user.prerequisites = {};
    }

    // If the arrays for each prereq don't exist, add them
    if (!this.user.prerequisites.passions) {
      this.user.prerequisites.passions = [];
    }
    if (!this.user.prerequisites.spiritualGifts) {
      this.user.prerequisites.spiritualGifts = [];
    }
    if (!this.user.prerequisites.skills) {
      this.user.prerequisites.skills = [];
    }
    if (!this.user.prerequisites.strengths) {
      this.user.prerequisites.strengths = [];
    }

    // At this point, we know they exist; if they're not arrays, we assume that a rare bug just
    // occurred; it was years ago and we couldn't quite track it down, but this is a mitigation
    // sometimes the prereqs end up as objects with numeric keys instead of actual arrays
    // this code fixes that if it sees it
    if (!Array.isArray(this.user.prerequisites.passions)) {
      this.user.prerequisites.passions = Object.keys(this.user.prerequisites.passions).map(key => this.user.prerequisites.passions[key]);
    }
    if (!Array.isArray(this.user.prerequisites.spiritualGifts)) {
      this.user.prerequisites.spiritualGifts = Object.keys(this.user.prerequisites.spiritualGifts).map(key => this.user.prerequisites.spiritualGifts[key]);
    }
    if (!Array.isArray(this.user.prerequisites.skills)) {
      this.user.prerequisites.skills = Object.keys(this.user.prerequisites.skills).map(key => this.user.prerequisites.skills[key]);
    }
    if (!Array.isArray(this.user.prerequisites.strengths)) {
      this.user.prerequisites.strengths = Object.keys(this.user.prerequisites.strengths).map(key => this.user.prerequisites.strengths[key]);
    }

    // Assign null values to undefined boolean properties in user.prerequisites so that
    // yesNoButton components can properly bind to the field

    // If everything is complete, select the last step
    if (this.user.prerequisites.completedPassions === undefined) {
      this.user.prerequisites.completedPassions = null;
    }
    // We only set this to true if the user is taking a values-based version because no yes/no selector exists for this
    if (this.user.prerequisites.completedSpiritualGifts === undefined) {
      if (this.user.group && this.user.group.surveyType && this.user.group.surveyType.identifier === 'values_based') {
        this.user.prerequisites.completedSpiritualGifts = true;
      } else {
        this.user.prerequisites.completedSpiritualGifts = null;
      }
    }
    // We need to set this value to true because there is no yes/no selector for this
    if (this.user.prerequisites.completedSkills === undefined) {
      this.user.prerequisites.completedSkills = true;
    }
    if (this.user.prerequisites.completedStrengths === undefined) {
      this.user.prerequisites.completedStrengths = null;
    }
    if (this.user.prerequisites.completedMbti === undefined) {
      this.user.prerequisites.completedMbti = null;
    }
  }

  /**
   * isPrereqComplete()
   *
   * pure function
   *
   * used by arePrerequisitesValid() and html view
   *
   * @returns boolean true if a prerequisite is complete and should show a checkmark
   */
  isPrereqComplete(prereq: any): boolean {
    //If it's falsy, it can't be done
    //Can't select anything until the user loads
    if (!prereq || !this.user) return false;

    const nonemptyStringObj = (strO: any) => strO && typeof strO.name === 'string' && strO.name.trim() !== '';

    switch (prereq.identifier) {
      case 'mbti':
        return this.user.prerequisites.completedMbti && this.user.prerequisites.mbti
          && this.user.prerequisites.mbti.name;
      case 'strengths':
        return this.user.prerequisites.completedStrengths &&
          this.user.prerequisites.strengths.length === 5 &&
          this.user.prerequisites.strengths.every(nonemptyStringObj);
      case 'skills':
        return this.user.prerequisites.skills.length === 3 &&
          this.user.prerequisites.skills.every(nonemptyStringObj);
      case 'spiritual_gifts':
        if (this.user.group?.surveyType?.identifier === 'values_based') {
          return this.user.prerequisites.spiritualGifts.length === 3 &&
            this.user.prerequisites.spiritualGifts.every(nonemptyStringObj);
        }
        return this.user.prerequisites.completedSpiritualGifts &&
          this.user.prerequisites.spiritualGifts.length === 3 &&
          this.user.prerequisites.spiritualGifts.every(nonemptyStringObj);
      case 'passions':
        return this.user.prerequisites.completedPassions &&
          this.user.prerequisites.passions.length === 3 &&
          this.user.prerequisites.passions.every(nonemptyStringObj) &&
          this.user.prerequisites.passions.every((passion: any) => passion.level && passion.level.name);
      default:
        throw new Error(`unknown prereq identifier; cannot determine completeness; '${prereq.identifier}'`);
    }
  }

  /**
   * selectFirstIncompletePrereq()
   *
   * function: mutates controller state
   *
   * sets the selected prereq to the first incomplete prereq
   * this is the prereq that we want selected when the page first loads according to UX
   */
  selectFirstIncompletePrereq(): void {
    if (!this.Prerequisites) throw new Error('cannot call selectFirstIncompletePreqreq() before prereqs load');

    //Set the selected prerequisite to the earliest non-complete prerequisite
    // This is accomplished because this sections of ifs is in reverse order.
    this.selectedPrerequisite = this.Prerequisites['PASSIONS'];
    if (!this.isPrereqComplete(this.Prerequisites['SPIRITUAL_GIFTS'])) {
      this.selectedPrerequisite = this.Prerequisites['SPIRITUAL_GIFTS'];
    }
    if (!this.isPrereqComplete(this.Prerequisites['SKILLS'])) {
      this.selectedPrerequisite = this.Prerequisites['SKILLS'];
    }
    if (!this.isPrereqComplete(this.Prerequisites['STRENGTHS'])) {
      this.selectedPrerequisite = this.Prerequisites['STRENGTHS'];
    }
    if (!this.isPrereqComplete(this.Prerequisites['MBTI'])) {
      this.selectedPrerequisite = this.Prerequisites['MBTI'];
    }
  }

  /**
   * arePrerequisitesValid()
   *
   * pure function
   *
   * preassessment validation right here
   *
   * used by canProceed()
   *
   * @returns {boolean} true if all prerequisites are complete and valid
   */
  arePrerequisitesValid(): boolean {
    let valid = true;

    this.AllPrerequisites.forEach((prereq: any) => {
      if (!this.isPrereqComplete(prereq)) {
        valid = false;
      }
    });

    return valid;
  }

  /**
   * hasUserCompletedAllRelevantSmallGroupModules()
   *
   * pure function
   *
   * used by canProceed()
   *
   * small group users are required to complete modules 1-6 during the onboarding
   *  it should be noted that there are seven modules whose completion statuses are stored
   *  on the smallGroupStep user object
   *
   * @returns {boolean} true if the user has completed every small group module relevant to onboarding
   */
  hasUserCompletedAllRelevantSmallGroupModules(): boolean {
    if (!this.user) return false;
    return this.user.smallGroupStep.hasCompletedModule1 &&
      this.user.smallGroupStep.hasCompletedModule2 &&
      this.user.smallGroupStep.hasCompletedModule4 &&
      this.user.smallGroupStep.hasCompletedModule5 &&
      this.user.smallGroupStep.hasCompletedModule6;
  }

  /**
   * canProceed()
   *
   * pure function
   *
   * returns false if the prerequisites aren't valid to be submitted
   * returns false if the user is part of a small group and not all modules have been completed
   *
   * @returns {boolean} true if all prerequisites are complete and valid
   */
  canProceed(): boolean {
    //If the prereqs aren't valid, the we definetely can't proceed
    if (!this.arePrerequisitesValid()) return false;

    // If the user is in a small group, we want to move them to the next step
    // We do not want to move them to the IPSAT immediately, but instead move to the next module
    if (this.isUserInSmallGroup() && !this.hasUserCompletedAllRelevantSmallGroupModules()) return false;
    return true;
  }

  /**
   * next()
   *
   * function: mutates backend and internal state
   *
   * navigates to the next onboarding step
   *
   * if prereqs, small group module status, and everything else canProceed cares about is valid,
   *  we update the onboardingStep and advance to the next onboarding step
   *
   *  @returns {Boolean} false if the internal state was not valid to advance according to canProceed()
   */
  next(): void {
    //If we can't proceed, return false and don't go to next screen
    if (!this.canProceed()) return;
    this.user.onboardingStep = this.nextOnboardingStep;
    this.save(true);
  }

  /**
   * previous()
   *
   * navigates to the previous onboarding step
   *
   * function: mutates backend and internal state
   */
  previous(): void {
    if (!this.user) throw new Error('cannot call previous() before user loads');
    this.user.onboardingStep = this.previousOnboardingStep;
    this.save(true);
  }

  /**
   * viewPersonalInfoStep()
   *
   * function: mutates backend and internal state
   *
   * navigates to the personal info onboarding step
   */
  viewPersonalInfoStep(): void {
    if (!this.user) throw new Error('cannot call viewPersonalInfoStep() before user loads');
    this.user.onboardingStep = this.personalInfoOnboardingStep;
    this.save(true);
  }

  /**
   * getPrerequisiteByIndex()
   *
   * pure function
   *
   * @param index number int the index of the prereq to find
   * @returns {*} the prerequisite object that has an index that matches index
   */
  getPrerequisiteByIndex(index: number): any {
    if (!this.AllPrerequisites) throw new Error('cannot call getPrerequisiteByIndex() before AllPrerequisites loads');
    return this.AllPrerequisites.find((prereq: any) => prereq.index === index);
  }

  /**
   * selectPreviousPrereq()
   *
   * function: mutates internal state
   *
   * decrements the selected prerequisite in the side bar and on the left panel
   *  (does not change the onboarding step)
   */
  selectPreviousPrereq(): void {
    if (!this.selectedPrerequisite) throw new Error('cannot navigate to previous prereq if a prereq is not selected');
    if (this.selectedPrerequisite.index < 1) throw new Error('cannot navigate to a negative indexed prereq');
    this.setSelectedPrereq(this.getPrerequisiteByIndex(this.selectedPrerequisite.index - 1));
  }

  /**
   * selectNextPrereq()
   *
   * function: mutates internal state
   *
   * increments the selected prerequisite in the side bar and on the left panel
   *  (does not change the onboarding step)
   *
   * should NOT be called directly by the UI; this button directly modifies prereq selection state
   *  use onNextPrereqButtonClicked() to handle UI events as it also handles small group module
   *  screens and advancing to the IPSAT
   */
  selectNextPrereq(): void {
    if (!this.selectedPrerequisite) throw new Error('cannot navigate to next prereq if a prereq is not selected');
    this.setSelectedPrereq(this.getPrerequisiteByIndex(this.selectedPrerequisite.index + 1));
  }

  /**
   * shouldShowNextPrereqButton()
   *
   * pure function
   *
   * this function may display as "take ipsat" if all prereqs are valid
   *
   * @returns {boolean} true if the next prereq button should be displayed
   */
  shouldShowNextPrereqButton(): boolean {
    if (!this.selectedPrerequisite) return false; //fixes the race-condition-based bug where prereqs disappear https://trello.com/c/MAae4BZK
    //If we're on the last prereq, only display this button if it's the "take ipsat" button
    // the click handling logic will ensure that it advances correctly to the next step
    // instead of throwing an error
    if (this.selectedPrerequisite.index >= this.AllPrerequisites.length - 1) return this.arePrerequisitesValid();
    if (!this.isPrereqComplete(this.selectedPrerequisite)) return false;
    return true;
  }

  /**
   * setSelectedPrereq()
   *
   * function: mutates internal state
   *
   * @param val the prereq object to select
   */
  setSelectedPrereq(val: any): void {
    if (!val) throw new Error('cannot select a falsy prereq');
    this.selectedPrerequisite = val;
    this.save();
  }

  /**
   * getCurrentSmallGroupModuleIndex()
   *
   * pure function
   *
   * small group modules are 1 indexed instead of 0 indexed and correspond
   *  to prerequisites assessments
   *
   * @returns {number} the index of the current small group module
   */
  getCurrentSmallGroupModuleIndex(): number | boolean {
    if (this.overrideSmallGroupModuleIndex) return this.overrideSmallGroupModuleIndex;
    if (!this.selectedPrerequisite) throw new Error('cannot getCurrentSmallGroupModuleIndex if a prereq is not selected');
    return this.selectedPrerequisite.index + 1;
  }

  /**
   * getCurrentSmallGroupModule()
   *
   * pure function
   *
   * @returns {*} the current small group module object that should be displayed if the user clicks next to select the next prereq
   */
  getCurrentSmallGroupModule(): any {
    // The current module is equal to the index of the firstOccurrence (plus one)
    // This is because we start the moduleIndex at 1, not 0
    return this.AllModuleContexts.find((context: any) => context.moduleIndex === this.getCurrentSmallGroupModuleIndex());
  }

  /**
   * markCurrentSmallGroupModuleComplete()
   *
   * function: mutates internal
   *
   * Mark a module as complete and allows the user to proceed to the preassessment that corresponds
   *  to that module
   */
  markCurrentSmallGroupModuleComplete(): void {
    if (!this.user) throw new Error('cannot markCurrentSmallGroupModuleComplete if a user has not loaded');
    this.user.smallGroupStep[`hasCompletedModule${this.getCurrentSmallGroupModuleIndex()}`] = true;
  }

  /**
   * hasCompletedCurrentSmallGroupModule()
   *
   * pure function
   *
   * @returns {boolean} true if the current module is marked as complete in the data model
   */
  hasCompletedCurrentSmallGroupModule(): boolean {
    if (!this.user) throw new Error('cannot hasCompletedCurrentSmallGroupModule if a user has not loaded');
    return this.user.smallGroupStep[`hasCompletedModule${this.getCurrentSmallGroupModuleIndex()}`];
  }

  /**
   * shouldShowSmallGroupModuleScreen()
   *
   * returns true if we should display the module intro component using ng-if
   *
   * the module intro component is displayed between preassessment steps when we are in a small group onboarding
   *
   * the screen is hidden once a user acknowledges that they have received proper instructions and taken proper
   *  assessments in order to continue (see nexleaderComponentOnboardingModuleIntro for more details)
   *
   * @returns {boolean}
   */
  shouldShowSmallGroupModuleScreen(): boolean {
    return this.isUserInSmallGroup() && this.showSmallGroupModuleIfRelevant;
  }

  /**
   * completeModuleAndAdvance()
   *
   * function: mutates internal and backend state
   *
   * marks the current small group module complete
   * if it's the last module and the user is now done with preassessments,
   *  advances the onboarding to the next step
   * saves updates state to the backend
   */
  completeModuleAndAdvance(): void {
    //Mark the current module complete
    this.markCurrentSmallGroupModuleComplete();

    //see docs for this flag
    // this hides the small group module now that the user has completed it
    this.showSmallGroupModuleIfRelevant = false;

    //If all the modules are done, advance to next prereq
    // next() will take care of a call to save()
    if (this.hasUserCompletedAllRelevantSmallGroupModules()) {
      this.next();
    } else {
      //save the updated step
      this.save();
    }
  }

  /**
   * onNextPrereqButtonClicked()
   *
   * function: mutates controller state
   *
   * handles the next prereq or "take ipsat" button
   *  being clicked in the UI
   *
   * if we're on the last prereq, we just want to show the ipsat module
   * there isn't a prereq page for it
   *
   * this button may say "take ipsat" and should call next()
   *  if not small group and prereqs are good
   */
  onNextPrereqButtonClicked(): void {
    this.showSmallGroupModuleIfRelevant = true;

    if (this.arePrerequisitesValid()) {
      if (this.isUserInSmallGroup()) {
        this.overrideSmallGroupModuleIndex = this.AllPrerequisites.length + 1;
      } else {
        this.next();
      }
    } else {
      if (this.selectedPrerequisite.index < this.AllPrerequisites.length - 1) {
        this.selectNextPrereq();
      } else {
        throw new Error('cannot advance past the end of the prereq screen');
      }
    }
  }

  /**
   * getIconPathForPrereq()
   *
   * pure function
   *
   * @returns {string|null} the path of the svg icon for a specific prerequisite
   */
  getIconPathForPrereq(prerequisite: any): string | null {
    return prerequisite ? `/assets/images/icons/${prerequisite.name.toLowerCase().replace(' ', '')}.svg` : null;
  }

  /**
   * shouldDisplayTakeIpsatInsteadOfNext()
   *
   * If the prerequisites are valid, we display "Take IPSAT"
   *  instead of "Next" in the next button.
   *
   * @returns {boolean} true if we should display the words "take ipsat" instead of "next"
   */
  shouldDisplayTakeIpsatInsteadOfNext(): boolean {
    return this.arePrerequisitesValid();
  }

  /**
   * save()
   *
   * function: mutates backend state
   *
   * saves the internal state of this component to the backend
   *
   * saves the user object
   *
   * @returns {*}
   */
  save(emitEvent: boolean = false): void {
    this.userService.saveUser(this.user).pipe(
      tap((_) => {
        this.successHandler.handle()
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null)
      })
    ).subscribe(() => {
      if (emitEvent) {
        this.boundNext.emit();
      }
    });
  }

  /**
   * getSelectedPrerequisiteDescription()
   *
   * helper function
   *
   * work around
   *
   * Trello card: https://trello.com/c/S8TYNrAQ asks to change "Leadership Skills Inventory" to
   *  "Skills Inventory". Since that's such an old crusty enum, I didn't think it was a good idea to
   *  change it. So this function returns $ctrl.selectedPrerequisite.description unless that description
   *  is "Leadership Skills Inventory". In that case, it returns "Skills Inventory"
   */
  getSelectedPrerequisiteDescription(): string | null {
    if (!this.selectedPrerequisite) return null;
    const desc = this.selectedPrerequisite.description;
    return desc === 'Leadership Skills Inventory' ? 'Skills Inventory' : desc;
  }

}
