<div class="row" style="height: 100%" *ngIf="currentStep === 'engagement'">
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center bg-white">
    <div class="nexleader-ecourse-content-wrapper">
      <h3 class="font-bold text-primary-2 text-center" style="line-height: 36px">
        How to Grow Your Self-Awareness and Lead Yourself Better
      </h3>
      <br />
      <div>
        <app-nexleader-ecourse-rali-video [index]="7"></app-nexleader-ecourse-rali-video>
      </div>
      <br />
      <div class="text-center">
        <a class="btn btn-success btn-lg" href="/assets/files/ecourse/module7.pdf" target="_blank">Download
          Module 7 Application Note Guide</a>
      </div>
    </div>
  </div>
  <div class="col-md-6 nexleader-onboarding-h100 flex flex-vertical-center">
    <div class="nexleader-ecourse-content-wrapper">
      <div class="text-center">
        <img [src]="'/assets/images/icons/ipsat.svg'" alt="IPSAT" class="m-b-lg" width="80" />
      </div>
      <div class="nexleader-ecourse-heading flex">
        <div class="font-bold nexleader-ecourse-heading-left">
          Module<span>7</span>
        </div>
        <h3 class="font-bold text-primary-2 m-l-md">
          Taking the Identity Profile Self-Awareness Tool (IPSAT)
        </h3>
      </div>
      <br />
      <div class="p-v-50">
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-download text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">Download the Application Note Guide</h4>
          </div>
        </div>
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-play text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">Engage with the video segments</h4>
          </div>
        </div>
        <div class="m-b-lg">
          <div class="flex flex-vertical-center">
            <span class="nexleader-number-highlight nexleader-number-highlight-active">
              <i class="fa fa-undo text-success"></i>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <h4 class="font-semi-bold">
              Reflect & Respond to the Action Steps
            </h4>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-success btn-lg" (click)="nextStep()">
            {{ mapContinueButtonTextForStep() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="height: 100%" *ngIf="currentStep === 'assessment'">
  <app-nexleader-onboarding-take-ipsat [standalone]="false">
  </app-nexleader-onboarding-take-ipsat>
</div>