<div class="embed-container">
  <iframe
    [src]="videoUrl"
    allowtransparency="true"
    frameborder="0"
    scrolling="no"
    class="wistia_embed"
    name="wistia_embed"
    allowfullscreen
    mozallowfullscreen
    webkitallowfullscreen
    oallowfullscreen
    msallowfullscreen
    width="540"
    height="303"
  ></iframe>
  <script src="//fast.wistia.net/assets/external/E-v1.js" async></script>
</div>
