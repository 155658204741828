/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-no-coach',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './no-coach.component.html'
})
export class NexleaderNoCoachComponent {

}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderNoCoachComponent',
//   downgradeComponent({
//     component: NexleaderNoCoachComponent,
//   }) as angular.IDirectiveFactory
// );