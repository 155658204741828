/* global angular */
/**
 * nexleaderViewLicensingPartnerSessionList
 *
 * angular component
 *
 * A view used to display all licensing partner sessions for the entire application within a table (through a component)
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderSessionsTableComponent } from '../../components/sessions-table/sessions-table.component';
import { LicensingPartnerService } from '../../../licensing-partners/resources/licensing-partner.service';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-licensing-partner-session-list',
  standalone: true,
  imports: [CommonModule, NexleaderSessionsTableComponent],
  templateUrl: './licensing-partner-session-list.component.html'
})
export class NexleaderLicensingPartnerSessionListComponent implements OnInit {
  routeParams: any;
  licensingPartnerId!: string;
  licensingPartner: any;

  constructor(
    private route: ActivatedRoute,
    private licensingPartnerService: LicensingPartnerService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    // Used to correctly configure the licensing partner's session list
    this.licensingPartnerId = this.routeParams['licensing_partner_id'];
    this.load();
  }

  load(): void {
    this.licensingPartnerService.getLite(this.routeParams['licensing_partner_id']).pipe(
      tap((data) => {
        this.licensingPartner = data;
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderLicensingPartnerSessionListComponent',
//   downgradeComponent({
//     component: NexleaderLicensingPartnerSessionListComponent,
//   }) as angular.IDirectiveFactory
// );
