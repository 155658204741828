<div class="nexleader-chart-personal-values-spider">
  <canvas id="personal-value-spider-graph"></canvas>
</div>
<div class="flex m-t-md m-b-md" *ngIf="!hideValueSetSelect">
  Current Value Set
  <div class="flex-grow"></div>
  <label class="control-label m-r-sm">
    <input
      type="radio"
      [(ngModel)]="selectedDataSetName"
      value="topTwelveValues"
      (click)="selectDataSet('topTwelveValues')"
    />&nbsp;&nbsp;Top Twelve
  </label>
  <label class="control-label m-r-sm">
    <input
      type="radio"
      [(ngModel)]="selectedDataSetName"
      value="topSevenValues"
      (click)="selectDataSet('topSevenValues')"
    />&nbsp;&nbsp;Top Seven
  </label>
  <label class="control-label">
    <input
      type="radio"
      [(ngModel)]="selectedDataSetName"
      value="topThreeValues"
      (click)="selectDataSet('topThreeValues')"
    />&nbsp;&nbsp;Top Three
  </label>
</div>
