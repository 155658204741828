/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-nexleader-component-bar-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bar-chart.component.html',
})
export class NexleaderComponentBarChartComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /**
   * chartData
   *
   * object
   *
   * An object that contains all data required to render a bar chart correctly.
   */
  @Input() chartData: any;

  /**
   * chartConfig
   *
   * object
   *
   * An object that contains all of the configuration needed to setup a bar chart correctly.
   */
  @Input() chartConfig: any;
  @ViewChild('chartCanvas') chartCanvas!: ElementRef;
  private chartInstance: Chart | undefined;

  ngAfterViewInit(): void {
    // Get the canvas element that we draw the chart in
    const chartElement: HTMLCanvasElement = this.chartCanvas.nativeElement;

    // We need the wrapper so we can set the canvas height
    const wrapperElement: HTMLElement | null = chartElement.closest(
      '.nexleader-bar-chart-wrapper'
    );

    // We want to set a fixed height per line, so we assign this and then calculated it
    const HEIGHT_PER_LINE = 40;
    const calculatedHeight =
      HEIGHT_PER_LINE * this.chartData.datasets[0].data.length + 50;

    if (wrapperElement) {
      wrapperElement.style.height = calculatedHeight + 'px';
    }

    // Create the chart with the provided configuration
    this.chartInstance = new Chart(chartElement, {
      type: 'bar',
      data: this.chartData,
      options: {
        indexAxis: 'y',
        ...this.chartConfig,
      },
    });
  }

  // Validate inputs
  ngOnInit(): void {
    this.validateInputs();

    window.addEventListener('beforeprint', this.beforePrint);
  }

  private validateInputs(): void {
    if (
      !this.chartData ||
      !this.chartData.datasets ||
      !this.chartData.datasets[0].data
    ) {
      throw new Error(
        'nexleaderComponentBarChart requires chartData to be an object'
      );
    }

    if (!this.chartConfig) {
      throw new Error(
        'nexleaderComponentBarChart requires chartConfig to be an object'
      );
    }
  }

  // Print handlers
  // This ensurse that the charts print properly
  private beforePrint(): void {
    for (const id in Chart.instances) {
      if (Chart.instances.hasOwnProperty(id)) {
        Chart.instances[id].resize();
      }
    }
  }

  ngOnDestroy(): void {
    // Destroy the chart instance to clean up resources
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
    window.removeEventListener('beforeprint', this.beforePrint);
  }
}
