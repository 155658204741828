/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, KeyValue } from '@angular/common';
import { EnumsService } from '../../../../services/enum.service';
import { SurveyService } from '../../../../services/survey.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { NexleaderEditQuestionsComponent } from '../edit-questions/edit-questions.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NexleaderEditRangesComponent } from '../edit-ranges/edit-ranges.component';

@Component({
  selector: 'app-nexleader-edit-survey',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FilterPipe,
    NexleaderEditQuestionsComponent,
    NexleaderEditRangesComponent,
  ],
  templateUrl: './edit-survey.component.html',
})
export class NexleaderEditSurveyComponent implements OnInit {
  routeParams: any;
  enums: { [key: string]: any[] } = {};
  survey: {
    description: String;
    disclaimer: String;
    interpretation: any[];
    name: string;
    questions: any[];
    surveyType: { identifier: string; name: string };
    updatedAt: string;
    _id: string;
  } = {
      description: '',
      disclaimer: '',
      interpretation: [],
      name: '',
      questions: [],
      surveyType: { identifier: '', name: '' },
      updatedAt: '',
      _id: '',
    };
  currentCategory: {
    index: number;
    name: string;
    maxValue: number;
    prerequisiteName: string;
    prerequisite: string;
  } = {
      index: NaN,
      name: '',
      maxValue: NaN,
      prerequisiteName: '',
      prerequisite: '',
    };

  constructor(
    private enumsService: EnumsService,
    private surveyService: SurveyService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  public onCompare(
    _left: KeyValue<any, any>,
    _right: KeyValue<any, any>
  ): number {
    return 1;
  }

  load(): void {
    forkJoin([
      this.enumsService.getEnums(),
      this.surveyService.getSurvey(this.routeParams['survey_id']),
    ])
      .pipe(
        tap(([enums, survey]) => {
          this.enums = enums;
          this.survey = survey;
          this.survey.questions.forEach((question: any) => {
            question.answer = {};
            question.answers.forEach((answer: any) => {
              if (!answer.ranges) {
                answer.ranges = [];
              }
            });
          });

          /* @ts-ignore */
          this.currentCategory = this.enums['QuestionCategories'].PERSONALITY;

          this.enums['AllQuestionCategories'].forEach((category: any) => {
            const found = this.survey.interpretation.some(
              (interpretation: any) =>
                interpretation.questionCategory.index === category.index
            );
            if (!found) {
              this.survey.interpretation.push({
                questionCategory: category,
                ranges: [],
              });
            }
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  saveSurvey(): void {
    this.surveyService
      .save(this.survey)
      .pipe(
        tap(() => {
          this.load();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  deleteSurvey(): void {
    this.surveyService
      .delete(this.survey._id)
      .pipe(
        tap(() => {
          this.router.navigate(['/edits']);
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  changeCategory(category: any): void {
    this.currentCategory = category;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEditSurveyComponent',
//   downgradeComponent({
//     component: NexleaderEditSurveyComponent,
//   }) as angular.IDirectiveFactory
// );
