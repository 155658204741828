import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { EMPTY, catchError } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-user-team-reports',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './user-team-reports.component.html',
})
export class NexleaderUserTeamReportsComponent implements OnInit {
  routeParams: any;
  teamReports: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    const userId = this.routeParams['user_id'];
    this.userService
      .queryTeamReport(userId)
      .pipe(
        catchError((error) => {
          this.errorHandler.handleError(error);
          return EMPTY;
        })
      )
      .subscribe((teamReports) => {
        this.teamReports = teamReports;
      });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderUserTeamReportsComponent',
//   downgradeComponent({
//     component: NexleaderUserTeamReportsComponent,
//   }) as angular.IDirectiveFactory
// );
