<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div id="nexleader-wrapper">
  <div class="content animate-panel" *ngIf="result">
    <div class="row">
      <div class="col-lg-12">
        <div class="hpanel">
          <div class="hpanel">
            <div class="panel-heading">
              <div class="panel-tools">
                <a [routerLink]="[
                    '/users',
                    user._id,
                    'surveyResults',
                    result._id,
                    'printable'
                  ]">
                  <i class="fa fa-print"></i>&nbsp;&nbsp;Print
                  {{ possessivePrefix }} Result
                </a>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="panel-body">
              <div class="col-lg-4 col-md-6">
                <div class="col-md-12 text-center m-b-md" [ngStyle]="doughnutStyle">
                  <small>{{ possessivePrefix }} Score Breakdown</small>
                  <br />
                  <br />
                  <canvas id="doughnut-chart"></canvas>
                </div>
                <div class="col-md-12 text-center">
                  <h1 class="font-extra-bold">
                    {{ ipsatTotal }}
                  </h1>
                  <small>IPSAT score out of 100</small>
                </div>
              </div>
              <div class="col-lg-8 col-md-6">
                <h3 class="font-semi-bold text-primary-2">
                  {{ ipsatStage.name }}
                </h3>
                <p>{{ ipsatStage.description }}</p>
              </div>
            </div>
            <div class="panel-footer">
              <div class="pull-right">
                <a class="btn btn-default" *ngIf="userIsInSmallGroup" href="/assets/files/module_handouts/participant/module7.pdf" target="_blank">
                  <i class="fa fa-download"></i>&nbsp;&nbsp;Download Handout for
                  Module 7</a>
                <a *ngIf="surveyType === 'values_based'" class="btn btn-default" href="/assets/files/IPSAT_Values_Coaching_Prep_Worksheet.pdf" target="_blank">
                  <i class="fa fa-download"></i>&nbsp;&nbsp;Download IPSAT
                  Coaching Prep Worksheet</a>
                <a *ngIf="surveyType === 'faith_based'" class="btn btn-default" href="/assets/files/IPSAT_Faith_Coaching_Prep_Worksheet.pdf" target="_blank">
                  <i class="fa fa-download"></i>&nbsp;&nbsp;Download IPSAT
                  Coaching Prep Worksheet</a>
                <a style="margin-left: 4px" class="btn btn-info" (click)="shareIPSAT()">
                  <i class="fa fa-share"></i>&nbsp;&nbsp;Share
                  {{ possessivePrefix }} result
                </a>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" *ngFor="let category of ipsatQuestionCategories">
        <div class="hpanel stats" *ngIf="
            category.name !== 'Open-Ended Questions' &&
            category.name !== 'Summary Overview'
          ">
          <div class="panel-body">
            <app-nexleader-category-score [category]="category" [result]="result"
              [surveyType]="surveyType"></app-nexleader-category-score>
          </div>
          <div class="panel-footer">
            <a (click)="learnMoreAboutResult(category)"><i class="fa fa-external-link"></i>&nbsp;&nbsp;Learn more about
              {{ possessivePrefix }} result</a>
          </div>
        </div>
      </div>

      <div class="col-md-4" *ngFor="
          let summaryOverviewQuestion of result.questions
            | questionCategory : summaryOverviewQuestionCategory
        ">
        <form #summaryOverviewForm="ngForm">
          <div class="text-center">
            <h4 class="m-b-xs text-primary-2 font-semi-bold">
              Identity Overview Statement
            </h4>
            <br />
            <p class="small" *ngIf="!editingOpenEndedStatements">
              {{ summaryOverviewQuestion.answer.name }}
            </p>
            <div *ngIf="editingOpenEndedStatements">
              <textarea class="form-control" rows="4" [(ngModel)]="summaryOverviewQuestion.answer.name"
                name="identity-overview-statement" nexleaderMaximumWordsValidation [maxWords]="100"></textarea>
              <br />
              <button class="btn btn-success" (click)="saveIdentityStatements()"
                [attr.disabled]="summaryOverviewForm.invalid ? true : null">
                <i class="fa fa-save"></i>&nbsp;&nbsp;Save
              </button>
              <span style="color: red" *ngIf="summaryOverviewForm.invalid">Invalid - Check that your response is fewer
                than 100
                words.</span>
              <br />
            </div>
            <br />
            <a (click)="toggleEditableOpenEndedStatements()" *ngIf="!editingOpenEndedStatements"><i
                class="fa fa-pencil"></i>&nbsp;&nbsp;Edit</a>
          </div>
        </form>
      </div>
      <div class="col-md-12" *ngIf="openEndedQuestions[0]">
        <app-nexleader-tabs [items]="openEndedQuestions" (save)="saveIdentityStatements()"></app-nexleader-tabs>
      </div>
    </div>
  </div>

  <!-- <nexleader-footer></nexleader-footer> -->
</div>