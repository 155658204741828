<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Add Team Member</h3>
</div>
<div class="modal-body">
  <app-nexleader-if-no-stripe>
    <div class="row">
      <div class="col-md-10 col-md-offset-1 col-xs-12">
        <div class="hpanel warning">
          <div class="color-line color-line-warning"></div>
          <div class="panel-body">
            <h3 class="mt-">Unable to Contact Stripe</h3>
            <p>
              Your computer is not able to talk to our payment provider, Stripe.
              Please try disabling ad blockers or other browser extensions that
              interfere with network traffic. Stripe allows us to securely
              process your order, and you cannot complete your purchase without
              Stripe.
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-nexleader-if-no-stripe>

  <div *ngIf="selectableUsers.length > 0">
    <p class="m-b-sm">
      You can select new users to add to each team report using the table below.
    </p>
    <div class="form-group">
      <input
        type="text"
        class="form-control m-b-sm"
        placeholder="Search for a user"
        [(ngModel)]="userSearchText"
      />
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Selected</th>
            <th>Name</th>
            <th>Eligible</th>
            <th>
              Can view team report
              <i
                class="fa fa-info-circle"
                tooltip="Checking this box allows these users to be able to view the team report you are creating."
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let user of selectableUsers | filter : undefined : userSearchText
            "
            [ngClass]="{ 'bg-success': user.selected }"
            (click)="selectUser(user)"
          >
            <td width="50">
              <input
                type="checkbox"
                id="{{
                  'nexleader-test-team-report-add-member-select-check-' +
                    user._id
                }}"
                (click)="selectUser(user)"
                [(ngModel)]="user.selected"
                [disabled]="!user.eligibility.isEligibleForTeamReport"
                nexleaderNoClick
              />
            </td>
            <td width="200">{{ user.firstName }} {{ user.lastName }}</td>
            <td>
              <span *ngIf="user.eligibility.isEligibleForTeamReport"
                ><i class="fa fa-check text-success"></i
              ></span>
            </td>
            <td width="100">
              <input
                type="checkbox"
                [(ngModel)]="user.isAllowedToViewTeamReport"
                [disabled]="!user.selected"
                nexleaderNoClick
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p
    *ngIf="selectableUsers.length < 1"
    id="nexleader-test-team-report-no-users-to-add"
  >
    No users are currently able to be added to this team report.
  </p>
  <br />
  <div
    class="flex flex-column-md flex-vertical-center"
    *ngIf="selectedUsers().length > 0"
  >
    <div>
      <p class="font-bold">Your total is...</p>
      <p class="text-muted small">
        The cost of a team report is $15 per team member.
      </p>
    </div>
    <div class="flex-grow"></div>
    <h4
      class="font-bold m-t-none m-b-none"
      id="nexleader-test-team-report-add-member-price"
    >
      <app-nexleader-currency
        *ngIf="price"
        [currencyQuantity]="price.currencyQuantity || 0"
        [currencyCode]="price.currencyCode"
      >
      </app-nexleader-currency>
    </h4>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-default" (click)="close.emit()">Cancel</button>
  <button
    class="btn btn-success"
    (click)="addTeamMembers()"
    [attr.disabled]="
      transactionIsInProgress || selectedUsers().length < 1 ? true : null
    "
  >
    Add Team Members
  </button>
</div>
