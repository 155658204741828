<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div
  class="row m-b-md"
  *ngIf="
    showUserSelectionDialogWithName || showGroupSelectionDialog || !isSuperAdmin
  "
>
  <div class="col-xs-6 col-md-4" *ngIf="showUserSelectionDialogWithName">
    <div>
      <select
        class="form-control"
        (ngModelChange)="onSelectedUserChange()"
        [(ngModel)]="selectedUser"
      >
        <option [ngValue]="null" selected>
          All {{ showUserSelectionDialogWithName }}s
        </option>
        <option
          *ngFor="let user of selectableUsers; trackBy: trackById"
          [ngValue]="user"
        >
          {{ user.display_name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-xs-6 col-md-4" *ngIf="showGroupSelectionDialog">
    <div>
      <select
        class="form-control"
        [(ngModel)]="selectedGroup"
        (ngModelChange)="onSelectedGroupChange()"
      >
        <option [ngValue]="null" selected>All Groups</option>
        <option
          *ngFor="let group of selectableGroups; trackBy: trackById"
          [ngValue]="group"
        >
          {{ group.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-xs-2 col-md-4">
    <button
      class="btn btn-default m-l-sm"
      (click)="newSession()"
      [attr.disabled]="disableNewSession() ? true : null"
    >
      New Session
    </button>
  </div>
</div>

<app-nexleader-calendar
  *ngIf="events"
  [events]="filteredEvents"
  (onCurrentEventChange)="currentEvent = $event"
>
  <ng-container *ngIf="currentEvent">
    <app-nexleader-session
      [session_id]="currentEvent._id"
      [coach_id]="currentEvent.coach._id"
      (onSave)="closeModal()"
    >
    </app-nexleader-session>
  </ng-container>
</app-nexleader-calendar>
