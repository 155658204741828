/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/**
 * nexleaderChartIpsatDoughnut
 *
 * This is not the ipsat doughnut that is used in the SurveyResult (as of the time the component was written).
 *  We didn't build components the same way back then. This component is used in the team report and anywhere
 *  else where an ipsat doughnut chart is necessary.
 *
 * This component does NOT perform SurveyResult calculations. I properly formatted object must be passed in via
 *  bindings.
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { CHART_COLORS } from '../../../../../../constants';

@Component({
  selector: 'app-nexleader-chart-ipsat-doughnut',
  standalone: true,
  imports: [CommonModule, NgChartsModule],
  templateUrl: './chart-ipsat-doughnut.component.html',
})
export class NexleaderChartIpsatDoughnutComponent implements OnInit, OnDestroy {
  /**
   * model
   *
   * This is the data used to generate the doughnut chart. It should be an object of the following form:
   * {
   *   score: 80,
   *   personality: 15,
   *   strengths: 18,
   *   skills: 4,
   *   spiritualGifts: 12,
   *   passions: 9
   * }
   *
   * The TeamReport.logic generates objects like this for scored team report objects.
   */
  @Input() model: any;

  /**
   * isPrinting
   *
   * This defines whether or not to show the chart in a wrapped state so that it renders correctly
   */
  @Input() isPrinting: boolean = false;

  public doughnutStyle: { [key: string]: string } = {};
  private ctx!: HTMLCanvasElement;
  charts: Chart[] = [];

  constructor() { }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit(): void {
    //Validate that there's a model on here
    if (!this.model) {
      throw new Error(
        'model is required for nexleaderChartIpsatDoughnut (see component docs; maybe add an ng-if if it has to load)'
      );
    }

    // Declare the style to show elements as when printing
    this.doughnutStyle = {
      'max-width': '250px',
      'margin-left': 'auto',
      'margin-right': 'auto',
    };

    // If they are printing, make sure to set the style to handle
    if (this.isPrinting) {
      this.doughnutStyle = {
        width: '100px',
        'margin-left': 'auto',
        'margin-right': 'auto',
      };
    }

    this.ctx = document.getElementById('doughnut-chart') as HTMLCanvasElement;

    if (this.ctx) {
      //Draw the chart using the model data and canvas ref
      const chart = new Chart(this.ctx, {
        type: 'doughnut',
        data: {
          labels: [
            'Personality',
            'Strengths',
            'Skills',
            'Spiritual Gifts',
            'Passions',
            'Room to Grow',
          ],
          datasets: [
            {
              label: 'Your IPSAT Score breakdown',
              data: [
                this.model.personality,
                this.model.strengths,
                this.model.skills,
                this.model.spiritualGifts,
                this.model.passions,
                this.model.roomToGrow,
              ],
              backgroundColor: CHART_COLORS,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        },
      });

      this.charts.push(chart as Chart);
    }
  }

  ngOnDestroy() {
    // Destroy all chart instances to clean up resources
    if (this.charts) {
      this.charts.forEach(chart => chart.destroy());
    }
  }
}
