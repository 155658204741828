<select
  class="form-control"
  [(ngModel)]="model"
  (ngModelChange)="onChange()"
  [compareWith]="compareEnums"
>
  <option [ngValue]="null" selected>-- Select --</option>
  <option
    *ngFor="let option of options; trackBy: trackByName"
    [ngValue]="option"
  >
    {{ option.name }}
  </option>
</select>
