<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-body">
            <h2 class="mt-">IPSAT Store for {{ group?.name }}</h2>
            <p>
              Welcome to the IPSAT Store. Here, you can purchase additional
              licenses for your group. Buying a license will automatically
              charge your card and credit licenses to your group.
            </p>
          </div>
        </div>
      </div>
    </div>
    <app-nexleader-if-no-stripe>
      <div class="row">
        <div class="col-md-8">
          <div class="hpanel warning">
            <div class="color-line color-line-warning"></div>
            <div class="panel-body">
              <h3 class="mt-">Unable to Contact Stripe</h3>
              <p>
                Your computer is not able to talk to our payment provider,
                Stripe. Please try disabling ad blockers or other browser
                extensions that interfere with network traffic. Stripe allows us
                to securely process your order, and you cannot complete your
                purchase without Stripe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </app-nexleader-if-no-stripe>
    <div class="row">
      <div class="col-md-3" *ngFor="let product of products | keyvalue">
        <app-nexleader-product [key]="product.key" [val]="product.value"></app-nexleader-product>
      </div>
    </div>
  </div>
</div>