/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
/**
 * nexleaderOnboardingPersonality
 *
 * The view component for the onboarding personality section of the onboarding preassessments.
 * This view contains information and fields that pertain to this part of the onboarding.
 */
import { FormsModule } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../../services/enum.service';
import { BlobResourceService } from '../../../../../../services/blob.service';
import { tap } from 'rxjs';
import { NexleaderYesNoButtonsComponent } from '../../../../../core/components/yes-no-buttons/yes-no-buttons.component';
import { NexleaderProfileWizardUploadFileComponent } from '../../../../../core/components/profile-wizard-upload-file/profile-wizard-upload-file.component';

@Component({
  selector: 'app-nexleader-onboarding-personality',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderYesNoButtonsComponent, NexleaderProfileWizardUploadFileComponent],
  templateUrl: './onboarding-personality.component.html'
})
export class NexleaderOnboardingPersonalityComponent implements OnInit {
  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process
   */
  @Input() user: any;

  /**
   * save
   *
   * Allows data that is edited on this onboarding personality view to be saved.
   */
  @Output() save = new EventEmitter();

  prerequisiteLink!: string;
  files: any[] = [];
  mbtis: any[] = [];
  prerequisite: any;

  constructor(private enumsService: EnumsService, private blobService: BlobResourceService) { }

  ngOnInit(): void {
    // We need an MBTI assessment link
    if (!this.user.group) throw new Error('user.group is required for component nexleaderOnboardingPersonality.');
    if (!this.user.group.preferredPreassessments) throw new Error('user.group.preferredPreassessments is required for component nexleaderOnboardingPersonality.');
    if (!this.user.group.preferredPreassessments.mbti) throw new Error('user.group.preferredPreassessments.mbti is required for component nexleaderOnboardingPersonality.');

    // set the link to the MBTI's assessment link
    this.prerequisiteLink = this.user.group.preferredPreassessments.mbti;

    // Load all the files for the user
    this.loadFiles();

    this.enumsService.getEnums().pipe(
      tap((data) => {
        this.mbtis = data.AllMBTI;
        this.prerequisite = data.Prerequisites.MBTI;
      })
    ).subscribe();
  }

  trackByOptionIndex(index: number, option: any): number {
    return option.index;
  }

  compareMBTI(o1: any, o2: any) {
    if (o1?.name == o2?.name) return true;
    else return false;
  }

  /**
   * loadFiles
   *
   * Allows us to query for files for a specific user based off of data passed into the component
   */
  loadFiles(): void {
    this.blobService.queryUser(this.user._id).pipe(
      tap((files) => {
        this.files = files;
      })
    ).subscribe();
  }
}
