/* global angular */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoachUserService } from '../../../../services/coach-user.service';
import { FormsModule } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { AuthService } from '../../../../services/auth.service';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-coach-user-signup',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink],
  templateUrl: './coach-user-signup.component.html',
})
export class NexleaderCoachUserSignupComponent implements OnInit {
  myCoachAccounts: any[] = [];
  myGroupAdminAccounts: any[] = [];
  coachUser: any = {};

  constructor(
    private coachUserService: CoachUserService,
    private authService: AuthService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.coachUserService
      .queryMyCoachAccounts()
      .pipe(
        tap((myCoachAccounts) => {
          this.myCoachAccounts = myCoachAccounts;
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.coachUserService
      .queryMyGroupAdminAccounts()
      .pipe(
        tap((myGroupAdminAccounts) => {
          this.myGroupAdminAccounts = myGroupAdminAccounts;
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  // This function basically validates everything except the password.
  hidePasswordForm(): boolean {
    if (!this.myGroupAdminAccounts) return true; //Invalid if we haven't loaded validation data
    return this.myGroupAdminAccounts.length !== 1;
  }

  //This function ANDs the showPasswordForm validation and the password valdiation
  invalid(): boolean {
    if (this.hidePasswordForm()) return false;
    if (!this.coachUser.password) return true;
    if (this.coachUser.password === '') return true;
    return this.coachUser.password !== this.coachUser.confirmPassword;
  }

  signup(): void {
    this.coachUserService
      .upgradeMyCoachAccount(this.coachUser)
      .pipe(
        tap(() => {
          this.authService.logout();
          this.router.navigate(['/']);
        }),
        catchError((error: any) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCoachUserSignupComponent',
//   downgradeComponent({
//     component: NexleaderCoachUserSignupComponent,
//   }) as angular.IDirectiveFactory
// );
