<div class="nexleader-ecourse-landing-bg">
  <div class="nexleader-ecourse-container">
    <div class="nexleader-ecourse-nav">
      <div class="nexleader-ecourse-nav-logo">
        <img src="/assets/images/ecourse/ecourse-ipsat-white.svg" alt="ipsat" />
      </div>
      <a href="https://myipsat.com" target="_blank" class="nexleader-ecourse-nav-link"
        *ngIf="!hasCoachOrGroupId">Home</a>
      <a href="https://www.growtowardyourdreams.com/" target="_blank" class="nexleader-ecourse-nav-link"
        *ngIf="!hasCoachOrGroupId">Book</a>
      <a href="https://medium.com/myipsat" target="_blank" class="nexleader-ecourse-nav-link"
        *ngIf="!hasCoachOrGroupId">Blog</a>
      <a [routerLink]="['/login']" class="nexleader-ecourse-nav-link" *ngIf="!hasCoachOrGroupId">Login</a>
    </div>
    <div class="nexleader-ecourse-banner text-center">
      <h1>How to Grow Your Self-Awareness <br />and Lead Yourself Better</h1>
      <br />
      <h3>
        Let us help you become world class at leading yourself.
        <br />The quality and trajectory of your life depends on it.
      </h3>
      <br />
      <a class="nexleader-ecourse-button" [href]="getDirectSignupLink()">Get Started With Module 1 For Free</a>
    </div>
    <br />
    <br />
  </div>
</div>
<div class="nexleader-ecourse-section-one">
  <div class="nexleader-ecourse-container">
    <div class="row flex align-items-center">
      <div class="col-md-6">
        <ul class="nexleader-section-one-list">
          <li>
            Gain confidence and clarity about how you can add value to others
            and make your best contribution to a team
          </li>
          <li>
            Prioritize where you need to grow and develop the self-leadership
            skills to follow through
          </li>
          <li>
            Reveal self-defeating behaviors that could sabotage relationships or
            derail your influence
          </li>
          <li>
            Take charge of your life and close the gap between where you are now
            and who you aspire to become
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="nexleader-ecourse-video-wrapper">
          <app-nexleader-vimeo-video [vimeoId]="'442266854'" [hideDownloadLink]="true"
            [fullWidth]="true"></app-nexleader-vimeo-video>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="nexleader-ecourse-section-two">
  <div class="nexleader-ecourse-container">
    <h1 class="font-bold text-primary-2 text-center">
      Included with the 8 Modules of this online course:
    </h1>
    <br /><br />
    <div class="row">
      <div class="col-md-3 col-sm-6 text-center m-b-lg">
        <i class="fa fa-3x fa-file-video-o"></i>
        <h4 class="text-primary-2 font-bold" style="height: 57px">
          Interactive Video Lessons
        </h4>
        <br />
        <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('videos')">
          Learn More
        </button>
      </div>
      <div class="col-md-3 col-sm-6 text-center m-b-lg">
        <i class="fa fa-3x fa-files-o"></i>
        <h4 class="text-primary-2 font-bold" style="height: 57px">
          Application Note Guide and Bundle of Assessments
        </h4>
        <br />
        <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('assessments')">
          Learn More
        </button>
      </div>
      <div class="col-md-3 col-sm-6 text-center m-b-lg">
        <i class="fa fa-3x fa-diamond"></i>
        <h4 class="text-primary-2 font-bold" style="height: 57px">
          Identity Profile Self-awareness Tool (IPSAT)
        </h4>
        <br />
        <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('ipsat')">
          Learn More
        </button>
      </div>
      <div class="col-md-3 col-sm-6 text-center">
        <i class="fa fa-3x fa-calendar-o"></i>
        <h4 class="text-primary-2 font-bold" style="height: 57px">
          One-on-One Virtual Coaching
        </h4>
        <br />
        <button class="nexleader-ecourse-button" (click)="viewAdditionalInformationModal('coaching')">
          Learn More
        </button>
      </div>
    </div>
  </div>
</div>
<div class="nexleader-ecourse-section-three">
  <div class="nexleader-ecourse-container">
    <div class="row flex flex-wrap" style="padding: 80px 0">
      <div class="col-md-6 col-xs-12" *ngFor="let module of availableModules" style="min-height: 267px">
        <div class="nexleader-ecourse-card" style="min-height: 267px">
          <img [src]="'/assets/images/icons/' + module.icon + '.svg'" [alt]="module.icon" />
          <div class="flex flex-column">
            <h4 class="text-primary-2">{{ module.name }}</h4>
            <p class="font-bold">{{ module.descriptionLine1 }}</p>
            <br />
            <p>{{ module.descriptionLine2 }}</p>
            <div class="flex-grow"></div>
            <button class="nexleader-ecourse-button" (click)="viewModuleInformation(module.identifier)"
              style="width: 120px">
              More&nbsp;&nbsp;<i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="nexleader-ecourse-section-four">
  <div class="nexleader-ecourse-container">
    <h4 class="text-center">
      “I’m such a believer in this resource all of our staff and interns engage
      with the IPSAT. It’s a core aspect of our commitment to help every team
      member bring their best to work and life.”
    </h4>
    <br />
    <p class="text-center font-bold">
      ~Tim Elmore, Founder & CEO, Growing Leaders
    </p>
    <br />
    <br />
    <div class="nexleader-ecourse-author-section">
      <div class="flex align-items-center flex-column-reverse-md">
        <div class="col-md-8">
          <p>
            Steve Moore is the creator of the Identity Profile Self- Awareness
            Tool (IPSAT). He serves as the president of <b>nexleader</b> and
            Growing Leaders. He has helped thousands of people grow their
            Identity Self- Awareness through his writing, speaking and network
            of IPSAT coaches. Steve is the author of six books, including
            <a [href]="
                'https://www.amazon.com/dp/B07RH9PPM7/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1'
              " target="_blank">Grow Toward Your Dreams</a>.
          </p>
        </div>
        <div class="col-md-4">
          <img [src]="'/assets/images/steve-moore.jpg'" alt="steve moore" style="width: 100%" />
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="text-center">
      <a class="nexleader-ecourse-button" [href]="getDirectSignupLink()">Get Started With Module 1 For Free</a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a class="nexleader-ecourse-button" [href]="getPricingLink()">Get Full Course Pricing Info</a>
      <br /><br /><br /><br />
      <h1 class="font-bold">Money Back Guarantee!</h1>
      <h4>
        If you follow our process and complete the course but don’t believe we
        delivered the promised value, we’ll give you a complete refund.
      </h4>
    </div>
  </div>
</div>
<div class="nexleader-ecourse-faq">
  <div class="nexleader-ecourse-container">
    <h3 class="font-bold text-primary-2">Frequently Asked Questions</h3>
    <hr class="m-t-md m-b-md nexleader-ecourse-faq-divider" />
    <div class="nexleader-ecourse-question" *ngFor="let question of faqs; let index = index"
      (click)="viewQuestion(index)">
      <div class="flex align-items-center text-primary-2">
        <b>{{ question.question }}</b>
        <div class="flex-grow"></div>
        <i class="fa fa-chevron-down" *ngIf="!question.isVisible"></i>
        <i class="fa fa-chevron-up" *ngIf="question.isVisible"></i>
      </div>
      <div *ngIf="question.isVisible" class="m-t-sm">
        <p>{{ question.answer }}</p>
      </div>
      <hr class="m-t-md m-b-md nexleader-ecourse-faq-divider" />
    </div>
  </div>
</div>
<div class="nexleader-ecourse-cta-section">
  <div class="nexleader-ecourse-container">
    <h1 class="font-bold text-center m-b-md">
      We want to help you become world class at leading yourself.
    </h1>
    <h4 class="font-bold text-center m-b-lg">
      The quality and trajectory of your life depends on it.
    </h4>
    <a class="nexleader-ecourse-button" [href]="getDirectSignupLink()">Get Started With Module 1 For Free</a>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <a class="nexleader-ecourse-button" [href]="getPricingLink()">Get Full Course Pricing Info</a>
  </div>
</div>
<div class="nexleader-ecourse-footer">
  <div class="nexleader-ecourse-container">
    <div class="flex nexleader-ecourse-footer-container">
      <div class="m-r-sm">
        <a href="https://myipsat.com/privacy-policy/">Privacy Policy</a>
      </div>
      <a [routerLink]="['/login']">Login</a>
    </div>
    <br /><br />
    <p>
      myIPSAT is powered by nexleader LLC
      <br />
      4850 Sugarloaf Parkway Suite 209-165, Lawrenceville GA 30044
      <br />
      678-261-7970
    </p>
  </div>
</div>