<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <br />
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="text-center m-b-md">
          <img src="/assets/images/logo.svg" width="125" height="100" />
          <h2>
            Your identity profile is the combination of your
            <br />personality, strengths, skills,
            <span *ngIf="surveyTypeIdentifier === 'faith_based'">spiritual gifts</span><span
              *ngIf="surveyTypeIdentifier === 'values_based'">personal values</span>, and passions.
          </h2>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel hgreen">
          <div class="panel-heading">Introduction</div>
          <div class="panel-body">
            <p>
              The Identity Profile Self-Awareness Tool (IPSAT) is a
              self-assessment exercise designed to help you evaluate and
              quantify how well you understand and engage with these five
              components.
            </p>
            <div>
              <br />
              <div class="col-md-2 border-right">
                <ol>
                  <li><b>Personality</b></li>
                  <li><b>Strengths</b></li>
                  <li><b>Skills</b></li>
                  <li>
                    <b *ngIf="surveyTypeIdentifier === 'faith_based'">Spiritual Gifts</b><b
                      *ngIf="surveyTypeIdentifier === 'values_based'">Personal Values</b>
                  </li>
                  <li><b>Passions</b></li>
                </ol>
              </div>
              <div class="col-md-10">
                There are many fine assessment tools available to help unlock
                your potential in these categories of identity. For simplicity
                and uniformity, we have chosen to utilize the following for the
                IPSAT: personality based on the Myers Briggs Type Indicator&reg;
                (MBTI&reg;), strengths from the perspective of Clifton
                Strengths, skills based on the Skills Inventory,
                <span *ngIf="surveyTypeIdentifier === 'values_based'">personal values based on the Personal Values
                  Inventory</span>
                and passion using MyPassionProfile.com.
                <span *ngIf="surveyTypeIdentifier === 'faith_based'">There is no prescribed spiritual gifts
                  assessment.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="text-center m-b-md">
          <img src="/assets/images/icons/ruler.svg" width="125" height="125" />
        </div>
      </div>
      <div class="col-md-9">
        <div class="hpanel">
          <h3>application, not assessment is what sets you apart</h3>
          <p>
            Doing an assessment will not set you apart from others. Millions of
            people take assessments every year. It is what you do with the
            information that will set you apart.
            <span *ngIf="surveyTypeIdentifier === 'faith_based'">The reason you have this combination of personality,
              strengths,
              skills, spiritual gifts, and passions is because God knew you
              would need them along with the power of the Holy Spirit to
              accomplish the good works prepared in advance for you.</span>
            <span *ngIf="surveyTypeIdentifier === 'values_based'">You have a unique combination of personality,
              strengths, skills,
              personal values and passions. Living a purposeful life that adds
              value to others will require you to discover, optimize and unleash
              these resources for the common good. That’s what the IPSAT is
              designed to do.</span>
          </p>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="text-center m-b-md">
          <img src="/assets/images/icons/giftedness.svg" width="125" height="125" />
        </div>
      </div>
      <div class="col-md-9">
        <div class="hpanel">
          <div class="m-b-md">
            <h3>character is the foundation for giftedness</h3>
            <p *ngIf="surveyTypeIdentifier === 'faith_based'">
              You will need hard and soft skills, a great team, and a
              well-crafted strategy. But you will only bear lasting fruit when
              you live and lead in alignment with God’s principles. Your
              character matters, and it serves as a foundation supporting all
              the kingdom resources God has given you. Life is not about you.
              Leadership is not an end in itself. Great leaders leverage their
              influence for the glory of God in the service of others.
            </p>
            <p *ngIf="surveyTypeIdentifier === 'values_based'">
              You will need hard and soft skills, a great team, and a
              well-crafted strategy. But you will only leave a noble legacy if
              your life is build on a solid foundation. Your character matters.
              Life is about more than you. Leadership is not an end in itself.
              Great leaders leverage their influence for the common good in the
              service of others and make the world a better place.
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div class="row">
      <div class="col-md-12 m-b-lg">
        <div class="text-center">
          <h3><b>4 Stages of Your Identity Profile</b></h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="hpanel hgreen">
          <div class="panel-body">
            <h4>1. Exploration Stage:</h4>
            <p>
              You are just beginning to explore the components of your identity
              profile but not yet developing them intentionally or using them
              effectively.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="hpanel hgreen">
          <div class="panel-body">
            <h4>2. Identification Stage:</h4>
            <p>
              You have enough clarity about the components of your identity
              profile to develop them intentionally and begin to use them
              consistently.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="hpanel hgreen">
          <div class="panel-body">
            <h4>3. Application Stage:</h4>
            <p>
              Your self-awareness is high enough to engage the categories of
              your identity profile with predictable results and focus
              intentionally on what you do best.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="hpanel hgreen">
          <div class="panel-body">
            <h4>4. Maturation Stage:</h4>
            <p>
              You are cultivating an experiential understanding of the
              categories in your identity profile and how they interact with
              each other to maximize your effectiveness.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 m-b-xl">
        <div class="text-center">
          <p>
            The maximum score for the Identity Profile Self-Assessment Tool is
            100. There are five categories based on the components of your
            identity profile: personality, strengths, skills,
            <span *ngIf="surveyTypeIdentifier === 'faith_based'">spiritual gifts</span><span
              *ngIf="surveyTypeIdentifier === 'values_based'">personal values</span>, and passions. Each sub category
            has a maximum score of 20.
          </p>
        </div>
      </div>
      <div class="col-md-12 text-center">
        <a [routerLink]="'/takeIpsat'" style="margin-right: 4px" class="btn btn-success"><i
            class="fa fa-diamond"></i>&nbsp;&nbsp;Take the IPSAT</a>
        <a [routerLink]="'/surveyResults'" class="btn btn-default"><i class="fa fa-files-o"></i>&nbsp;&nbsp;My
          Results</a>
      </div>
    </div>
  </div>
</div>