/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SkillsInventoryResultService {
  constructor(private http: HttpClient) {}

  save(payload: any, skillsInventoryResultId?: string) {
    let url = `${API_BASE}skillsInventoryResults`;
    url += skillsInventoryResultId ? `/${skillsInventoryResultId}` : '';
    return this.http.post(url, payload, { withCredentials: true });
  }

  queryUser(userId: string): Observable<any[]> {
    const url = `${API_BASE}users/${userId}/skillsInventoryResults`;
    return this.http.get<any[]>(url, { withCredentials: true });
  }

  getScore(skillsInventoryResultId: string): Observable<any> {
    const url = `${API_BASE}skillsInventoryResults/${skillsInventoryResultId}/score`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  queryUserScores(userId: string): Observable<any[]> {
    const url = `${API_BASE}users/${userId}/skillsInventoryResults/scores`;
    return this.http.get<any[]>(url, { withCredentials: true });
  }
}
