<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <app-nexleader-skills-inventory-result
      *ngIf="userId && skillsInventoryResultId"
      [userId]="userId"
      [skillsInventoryResultId]="skillsInventoryResultId"
    ></app-nexleader-skills-inventory-result>
  </div>
</div>
