<ng-container *ngIf="chartDomains">
  <div *ngFor="let chartDomain of chartDomains" class="m-b-md">
    <h5 class="text-primary-2 m-b-sm">{{ chartDomain.name }}</h5>
    <p *ngIf="chartDomain.datasets[0].data.length < 1">
      This domain does not have any values associated with it.
    </p>
    <app-nexleader-component-bar-chart
      *ngIf="chartDomain.datasets[0].data.length > 0"
      [chartData]="chartDomain"
      [chartConfig]="chartConfig"
    ></app-nexleader-component-bar-chart>
  </div>
</ng-container>
