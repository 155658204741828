/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { BsModalService } from 'ngx-bootstrap/modal';
import { tap, catchError, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GroupService } from '../../../../../services/group.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { EnumsService } from '../../../../../services/enum.service';
import { NexleaderInputLicensingPartnerComponent } from '../../input-licensing-partner/input-licensing-partner.component';

@Component({
  selector: 'app-nexleader-new-group-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, NexleaderInputLicensingPartnerComponent],
  templateUrl: './new-group-modal.component.html',
})
export class NexleaderNewGroupModalComponent implements OnInit {
  groupTiers: any[] = [];
  surveyTypes: any[] = [];
  group: any = { licensingPartner: null };

  constructor(
    private groupService: GroupService,
    private errorHandler: ErrorHandlerService,
    private enumsService: EnumsService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.groupTiers = enums.GroupTiers;
          this.surveyTypes = enums.SurveyTypes;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  trackByName(index: number, group: any): string {
    return group.key;
  }

  saveGroup(group: any) {
    this.groupService
      .save(group)
      .pipe(
        tap(() => {
          this.bsModalService.hide();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
