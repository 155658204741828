<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="login-container">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center m-b-md">
        <div class="text-center m-b-md">
          <img src="/assets/images/logo.svg" width="125" height="100" alt="Logo" />
        </div>
      </div>
      <div class="hpanel">
        <div class="panel-body">
          <div class="text-center">
            <h3>Multi-group Coaching</h3>
          </div>

          <p class="m-b-md m-t-md">
            Welcome to the new multi-group coaching system. This process allows
            you to link your coaching activities across multiple groups.
            However, it is permanent and cannot be undone. You will see the new
            multi-group coaching interface whenever you log in, and you will no
            longer be able to log into individual groups separately.
          </p>

          <hr />

          <ng-container *ngIf="myGroupAdminAccounts.length > 0">
            <p class="m-b-sm m-t-md">
              Your {{ myGroupAdminAccounts.length }} Group Admin Accounts
            </p>
            <ul *ngFor="let account of myGroupAdminAccounts">
              <li>
                {{ account.firstName }} {{ account.lastName }} |
                {{ account.group.name }}
              </li>
            </ul>
            <p class="m-t-sm text-red" *ngIf="myGroupAdminAccounts.length > 1">
              We currently don't support consolidation for users with multiple
              group admin accounts. Contact
              <a href="mailto:angeline.patrick@nexleader.com">angeline.patrick&#64;nexleader.com</a>
              for help working with this complicated coaching situation.
            </p>
          </ng-container>

          <ng-container *ngIf="myGroupAdminAccounts.length < 1">
            <p class="m-b-sm m-t-md">You have no group admin accounts.</p>
            <p class="m-t-sm text-red">
              We currently don't support consolidation for users with no group
              admin accounts. Contact
              <a href="mailto:angeline.patrick@nexleader.com">angeline.patrick&#64;nexleader.com</a>
              for help working with this complicated coaching situation.
            </p>
          </ng-container>

          <hr />

          <ng-container *ngIf="myCoachAccounts.length > 0">
            <p class="m-b-sm m-t-md">
              Your {{ myCoachAccounts.length }} Coaching Accounts
            </p>
            <ul *ngFor="let coachAccount of myCoachAccounts">
              <li>
                {{ coachAccount.firstName }} {{ coachAccount.lastName }} |
                {{ coachAccount.group.name }}
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="myCoachAccounts.length < 1">
            <p class="text-red">
              You do not have any accounts under your email address eligible for
              this upgrade.
            </p>
            <p class="m-t-sm">
              Super admin, group admin, and participant accounts are not
              eligible.
            </p>
          </ng-container>

          <hr />

          <div *ngIf="!hidePasswordForm()">
            <p class="m-b-sm m-t-md">
              <b>Your new password will be used to access your new account. You
                will not be able to use any of your individual group coaching
                accounts. Everything will be managed through your new
                multi-group account.</b>
            </p>

            <hr />

            <form id="coachUserForm" (ngSubmit)="signup()">
              <div class="form-group">
                <label class="control-label">New Password</label>
                <input type="password" placeholder="******" class="form-control" name="password"
                  [(ngModel)]="coachUser.password" />
              </div>
              <div class="form-group">
                <label class="control-label">Confirm New Password</label>
                <input type="password" placeholder="******" name="confirmPassword" class="form-control"
                  [(ngModel)]="coachUser.confirmPassword" />
              </div>

              <input type="submit" [disabled]="invalid()" class="btn btn-success btn-block" value="Upgrade" />
            </form>
          </div>

          <div *ngIf="hidePasswordForm()" class="text-center">
            <a [routerLink]="['/']">Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <strong>IPSAT</strong> <br />
      2017 Copyright nexleader
    </div>
  </div>
</div>