/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderViewUsersWithAttributeModal
 *
 * A modal that is used to show users who have a certain attribute (MBTI, strength, etc)
 */
import { TeamReportService } from './../../../views/team-reports/team-report.service';
import { ErrorHandlerService } from './../../../../../services/error-handler.service';
import { UserService } from './../../../../../services/user.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NexleaderIfNoStripeComponent } from '../../../../store/components/if-no-stripe/if-no-stripe.component';
import { NexleaderCurrencyComponent } from '../../../../store/components/currency/currency.component';
import { FilterPipe } from '../../../../../pipes/filter.pipe';
import { NexleaderNoClickDirective } from '../../../../../directives/no-click.directive';
import { EMPTY, catchError, of, switchMap, take, tap } from 'rxjs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderStripeElementModalComponent } from '../../../../store/components/stripe-element-modal/stripe-element-modal.component';
import { SuccessService } from '../../../../../services/success.service';

@Component({
  selector: 'app-nexleader-add-team-member-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderIfNoStripeComponent,
    NexleaderCurrencyComponent,
    FilterPipe,
    NexleaderNoClickDirective,
    TooltipModule,
  ],
  templateUrl: './add-team-member-modal.component.html',
})
export class NexleaderAddTeamMemberModalComponent implements OnInit {
  /**
   * groupId
   *
   * A group ID used to query for users who are eligible for a team report
   *
   * ex: GROUP_ID
   */
  groupId!: string;

  /**
   * teamReportId
   *
   * A team report ID to use when making a query to the API
   *
   * ex: TEAM_REPORT_ID
   */
  teamReportId!: string;

  /**
   * currentUsers
   *
   * An array of current users on the team report.
   * We don't want to add the same user to the team report twice.
   * This an array of strings, as it makes it easier to filter through it.
   *
   * ex: []
   */
  currentUsers: string[] = [];

  /**
   * close
   *
   * A close callback function to use after the user successfully saves
   *
   * ex: someFn()
   */
  @Output() close = new EventEmitter<any>();

  selectableUsers: any[] = [];
  userSearchText: string = '';
  price: any;
  transactionIsInProgress: boolean = false;

  constructor(
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: BsModalService,
    private successService: SuccessService,
    private teamReportService: TeamReportService
  ) { }

  ngOnInit(): void {
    // Query all users who are eligible for team reports

    this.userService
      .queryGroupEligibleForTeamReport(this.groupId)
      .pipe(
        tap((users) => {
          // Only show the users that are eligible for team reports
          // Only show the users that are not currently on the team report
          this.selectableUsers = users
            .filter((u) => u.eligibility.isEligibleForTeamReport)
            .filter((u) => !this.currentUsers.includes(u._id));
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  // Select/unselect users
  selectUser(user: any): void {
    // The user must be eligible to be on the team report for he/she to be selected
    if (user.eligibility.isEligibleForTeamReport) {
      if (user.selected) {
        user.isAllowedToViewTeamReport = false;
      } else {
        user.isAllowedToViewTeamReport = true;
      }
      user.selected = !user.selected;
    }
    this.loadPrice().subscribe();
  }

  // Fetch all users that have been selected
  selectedUsers(): any[] {
    if (!this.selectableUsers) {
      return [];
    }
    return this.selectableUsers.filter((u) => u.selected);
  }

  /**
   * loadPrice()
   *
   * loads the price for this team report from the backend
   *  this should be called anytime price-related information changes
   *  it's called when discount code changes
   *  it's called when the page changes (and they're an array of users to check)
   */
  loadPrice() {
    return this.teamReportService
      .calculateAddUserPrice({
        userCount: this.selectedUsers().length,
        group: this.groupId,
      })
      .pipe(
        tap((data) => {
          this.price = data.price;
        })
      );
  }

  /**
   * Quick way to map an array of objects to an array of string IDs
   *
   * @param {Array} model: an input model to map the array of
   */
  toId(model: any[]): string[] {
    return model.map((m) => m._id);
  }

  /**
   * addTeamMembers()
   *
   * Adds a team member to an already-existent team report.
   */
  addTeamMembers(): void {
    // Disable the submit button so we don't get another submit while processing
    this.transactionIsInProgress = true;

    // Ensure we have the latest price data from the API
    this.loadPrice().subscribe(() => {
      // Create the StripeCheckout modal that displays in order to acquire credit card info
      const modalRef = this.modalService.show(
        NexleaderStripeElementModalComponent,
        {
          initialState: {
            title: 'IPSAT Team Report',
            description: `nexleader IPSAT TeamReport for ${this.selectedUsers().length
              } users`,
            currencyCode: this.price.currencyCode?.toLowerCase(),
            amount: Math.trunc(this.price.currencyQuantity * 100),
          },
        }
      );

      // If there was an error, return with the error appropriately
      modalRef.onHide?.pipe(take(1)).subscribe((_) => {
        this.transactionIsInProgress = false;
      });

      modalRef.content?.tokenGenerated
        .pipe(take(1))
        .pipe(
          tap(() => modalRef.hide()),
          switchMap((token) => {
            // If the price is greater than 0, there must be a token in order to successfully make a payment
            if (this.price.currencyQuantity > 0 && !token) {
              // This is an odd error that should never happen
              this.errorHandlerService.handleError({
                message:
                  'Error communicating with Stripe. Stripe did not send a token. Please contact support.',
                token: token, //undefined or null
              });
              this.transactionIsInProgress = false;
              return EMPTY;
            }

            const requestBody = {
              // Stripe information
              stripe: {
                // The token generated from the StripeCheckout form
                token: token,
                // The price calculated based off of the number of users being used in the team report
                price: this.price,
              },
              // The constructor object that declares information to be added to the team report model
              addUserData: {
                // Users to generate the report for
                teamUsers: this.toId(this.selectedUsers()),
                // A numerical value for the number of users
                userCount: this.selectedUsers().length,
                // The group that the team report will be created for
                group: this.groupId,
              },
            };

            return this.teamReportService
              .addUsersToTeamReport(this.teamReportId, requestBody)
              .pipe(
                catchError((err) => {
                  this.errorHandlerService.handleError(err);
                  this.transactionIsInProgress = false;
                  this.close.emit();
                  return of(null);
                })
              );
          })
        )
        .subscribe((teamReport) => {
          // Save the teamReportData
          this.successService.handle({
            message: 'Successfully purchased and generated team report.',
          });
          this.transactionIsInProgress = false;
          this.close.emit();
        });
    });
  }
}
