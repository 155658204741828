<h4
  [id]="'nexleader-test-team-report-progress-bar-' + category"
  class="font-bold pull-right text-muted"
>
  {{ roundedValue }}/{{ max }}
</h4>
<div class="clearfix"></div>
<div class="progress m-t-xs full progress-small">
  <div
    [ngStyle]="{ width: percentage + '%' }"
    aria-valuemax="100"
    aria-valuemin="0"
    [attr.aria-valuenow]="percentage"
    role="progressbar"
    [ngClass]="'progress-bar progress-bar-' + category"
  ></div>
</div>
