<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-heading">
            <h3>Report an Issue</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <p>
                  <br />
                  We're currently working on creating an incredible new version
                  of the IPSAT, and your feedback during our beta is greatly
                  appreciated.
                  <br /><br />
                  If you happen to come across an issue, let us know how it
                  happened so we can recreate it and fix the bug as fast as
                  possible.
                </p>
              </div>
              <div class="col-md-6">
                <form
                  method="POST"
                  action="https://formspree.io/henry@mothershipstudio.io"
                >
                  <div class="form-group">
                    <label class="control-label">Your Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="John Smith"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Your Email</label>
                    <input
                      type="text"
                      class="form-control"
                      name="_replyto"
                      placeholder="john.smith@gmail.com"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Your Issue</label>
                    <textarea
                      type="text"
                      class="form-control"
                      name="description"
                      placeholder="Yeah, so this is really funky..."
                    ></textarea>
                  </div>
                  <br />
                  <button class="btn btn-default" type="submit">
                    <i class="fa fa-paper-plane"></i>&nbsp;&nbsp;Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
