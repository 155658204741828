<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div *ngIf="!prerequisiteAlreadySelected">
  <select class="form-control pull-left" [(ngModel)]="prerequisite">
    <option value="undefined" selected>--Select a Prerequisite--</option>
    <option
      *ngFor="let prereq of prerequisites; trackBy: trackByIndex"
      [ngValue]="prereq"
    >
      {{ prereq.description }}
    </option>
  </select>
  <br /><br /><br />
</div>
<app-nexleader-file-picker
  (onSelect)="onSelect($event)"
  (onLoad)="onLoad($event.uri)"
></app-nexleader-file-picker>
<span *ngIf="file?.name"
  >Selected File: <i>{{ file?.name }}</i></span
>
<span *ngIf="!file?.name">No file selected.</span>
<button
  class="btn btn-primary pull-right"
  [attr.disabled]="!(data && file?.name && prerequisite) ? true : null"
  (click)="upload()"
>
  <i class="fa fa-upload"></i>&nbsp;&nbsp; Upload
</button>
