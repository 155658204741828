<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="form-group">
  <input
    class="form-control"
    type="text"
    value="{{ link }}"
    chba-select-on-click
    readonly="true"
  />
</div>
