<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-4 text-center" *ngIf="evaluationResults.length > 0">
        <app-nexleader-net-promoter-box *ngIf="!toggleGlobalNPS" [score]="netPromoter?.score"
          [class]="netPromoter?.class"></app-nexleader-net-promoter-box>
        <app-nexleader-net-promoter-box *ngIf="toggleGlobalNPS" [score]="globalNPS?.score"
          [class]="globalNPS?.class"></app-nexleader-net-promoter-box>
        <div class="form-group">
          <div class="checkbox">
            <input type="checkbox" [(ngModel)]="toggleGlobalNPS" id="globalNPSToggle" />
            <label for="globalNPSToggle" class="control-label">Toggle Global NPS</label>
          </div>
        </div>
        <p *ngIf="toggleGlobalNPS">
          The global NPS is the calculated NPS for all groups that the coach is
          in.
        </p>
      </div>
      <div class="col-md-8" *ngIf="evaluationResults.length > 0">
        <div class="hpanel">
          <div class="panel-body">
            <p><b>Net Promoter Score Distribution</b></p>
            <br />
            <canvas id="netPromoterChart"></canvas>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="evaluationResults.length === 0">
        <p>
          <i>Coach has not received enough feedback to calculate a Net Promoter
            score.</i>
        </p>
      </div>
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-heading">Participants</div>
          <div class="panel-body">
            <app-nexleader-participants-table [isCoach]="true"></app-nexleader-participants-table>
            <div *ngIf="participants && participants?.length < 1">
              <i>No participants yet.</i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="hpanel">
          <div class="panel-heading">Your Coach Evaluation Results</div>
          <div class="panel-body">
            <table class="table coach-evaluation-result-table" *ngIf="evaluationResults.length > 0">
              <thead>
                <th><i class="fa fa-user"></i>&nbsp;&nbsp;Participant Name</th>
                <th><i class="fa fa-briefcase"></i>&nbsp;&nbsp;Coach Name</th>
                <th><i class="fa fa-eye"></i>&nbsp;&nbsp;View</th>
              </thead>
              <tbody>
                <tr *ngFor="let evaluationResult of evaluationResults">
                  <td>
                    {{ evaluationResult.user.firstName }}
                    {{ evaluationResult.user.lastName }}
                  </td>
                  <td>
                    {{ evaluationResult.coach.firstName }}
                    {{ evaluationResult.coach.lastName }}
                  </td>
                  <td>
                    <a [routerLink]="['/evaluationResults', evaluationResult._id]">Link</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p *ngIf="evaluationResults.length === 0">
              No coach evaluation results yet.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic [name]="'Total Participants'" [value]="stats?.totalParticipants"
          [icon]="'users'"></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic [name]="'Total Sessions'" [value]="stats?.totalSessions"
          [icon]="'calendar'"></app-nexleader-statistic>
      </div>
    </div>
  </div>
</div>