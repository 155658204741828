/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationResultService } from '../../../../services/evaluation-result.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-evaluation-result',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './evaluation-result.component.html'
})
export class NexleaderEvaluationResultComponent implements OnInit {
  routeParams: any;
  evaluationResult: any;

  constructor(
    private evaluationResultService: EvaluationResultService,
    private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.fetchEvaluationResult();
  }

  fetchEvaluationResult(): void {
    this.evaluationResultService.getEvaluationResult(this.routeParams['evaluation_result_id']).pipe(
      tap((result) => {
        this.evaluationResult = result;
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderEvaluationResultComponent',
//   downgradeComponent({
//     component: NexleaderEvaluationResultComponent,
//   }) as angular.IDirectiveFactory
// );
