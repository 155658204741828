<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-heading">
            <h3>Edit Evaluation</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label class="control-label">Name</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="evaluation.name"
              />
            </div>
            <div class="form-group">
              <label class="control-label">Description</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="evaluation.description"
              />
            </div>
            <br />
            <button
              class="btn btn-success"
              style="margin-right: 3px"
              (click)="saveEvaluation()"
            >
              <i class="fa fa-floppy-o"></i>&nbsp;&nbsp;Save Evaluation
            </button>
            <button class="btn btn-danger" (click)="deleteEvaluation()">
              <i class="fa fa-trash-o"></i>&nbsp;&nbsp;Delete Evaluation
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-body">
            <div class="tab-content">
              <div id="note1" class="tab-pane active">
                <h3>Questions</h3>
                <hr />
                <app-nexleader-edit-questions
                  [questions]="evaluation.questions"
                  [eval]="true"
                ></app-nexleader-edit-questions>
                <br />
                <div>
                  <button
                    class="btn btn-default"
                    style="margin-right: 4px"
                    (click)="newQuestion()"
                  >
                    <i class="fa fa-plus"></i>&nbsp;&nbsp;New Question
                  </button>
                  <button class="btn btn-success" (click)="saveEvaluation()">
                    <i class="fa fa-floppy-o"></i>&nbsp;&nbsp;Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</div>
