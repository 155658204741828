<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="flex flex-vertical-center">
  <h3 class="pull-left font-semi-bold m-r-sm text-primary-2" id="dateLabel">
    {{ viewDate | calendarDate : view + "ViewTitle" : "en" }}
  </h3>
  <small class="font-bold"
    >{{ timezone || "Local Time" }} ({{ timezoneAbbr || "?" }})</small
  >
</div>

<div class="pull-right form-inline">
  <div class="btn-group">
    <button
      class="btn btn-default"
      *ngIf="view === CalendarView.Day"
      (click)="toggleExtended()"
    >
      {{ extended ? "Show Less" : "Show More" }}
    </button>
  </div>

  <div class="btn-group" style="margin-right: 4px">
    <button
      class="btn btn-primary"
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()"
    >
      &lt;&lt;Prev
    </button>
    <button class="btn btn-default" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
      Today
    </button>
    <button
      class="btn btn-primary"
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()"
    >
      Next &gt;&gt;
    </button>
  </div>

  <div class="btn-group">
    <div
      class="btn btn-warning"
      (click)="setView(CalendarView.Month)"
      [class.active]="view === CalendarView.Month"
    >
      Month
    </div>
    <div
      class="btn btn-warning"
      (click)="setView(CalendarView.Week)"
      [class.active]="view === CalendarView.Week"
    >
      Week
    </div>
    <div
      class="btn btn-warning"
      (click)="setView(CalendarView.Day)"
      [class.active]="view === CalendarView.Day"
    >
      Day
    </div>
  </div>
</div>

<div class="clearfix"></div>
<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [refresh]="refresh"
    [events]="events"
    [activeDay]="activeDay"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClickedForMonthCalendar($event)"
    (eventClicked)="onEventClick($event, eventModalTemplate)"
  ></mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [refresh]="refresh"
    [events]="events"
    (dayHeaderClicked)="dayClickedForWeekCalendar($event)"
    (eventClicked)="onEventClick($event, eventModalTemplate)"
  ></mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [refresh]="refresh"
    [events]="events"
    [dayStartHour]="dayStartHour"
    [dayEndHour]="dayEndHour"
    (eventClicked)="onEventClick($event, eventModalTemplate)"
  ></mwl-calendar-day-view>
</div>

<ng-template #eventModalTemplate>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      (click)="closeModal()"
      aria-hidden="true"
      style="float: right"
    >
      &times;
    </button>
    <h3 class="modal-title" style="float: left">
      {{ currentEvent?.title }}
    </h3>
    <br />
  </div>
  <div class="modal-body" style="display: none"></div>
  <div *ngIf="currentEvent" class="modal-body">
    <ng-content></ng-content>
  </div>
</ng-template>
