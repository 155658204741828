<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="nexleader-video-wrapper">
                  <app-nexleader-vimeo-video [vimeoId]="'329438506'"
                    [hideDownloadLink]="true"></app-nexleader-vimeo-video>
                </div>
              </div>
              <div class="col-sm-6">
                <h3 class="font-bold m-b-sm text-primary-2">
                  Enhance Your IPSAT Journey With This New Resource
                </h3>
                <p class="m-b-sm" style="font-size: 16px">
                  <i>"If you're serious about being an agent of change, read
                    this book and apply it to your life and organization."</i>
                  <br />
                  — Hans Finzel, leadership expert and best-selling author
                </p>
                <p class="m-b-md" style="font-size: 16px">
                  <i>"Regardless of what your dreams are, this book will help
                    bring them to life."</i>
                  <br />
                  — Linda J. Miller, MCC, Global Liason for Coaching, The Ken
                  Blanchard Companies
                </p>
                <a href="https://www.amazon.com/dp/1632573296/ref=sr_1_fkmrnull_2?keywords=grow+toward+your+dreams+steve+moore&qid=1555374090&s=gateway&sr=8-2-fkmrnull"
                  target="_blank" style="margin-right: 3px" class="btn btn-success btn-lg">Order on Amazon</a>
                <a href="https://www.growtowardyourdreams.com/purchase-the-book" target="_blank"
                  class="btn btn-default btn-lg">Quantity Order</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="user['prerequisites']">
        <div class="hpanel hgreen">
          <div class="panel-heading">
            {{ possessivePrefix }} 5 Pre-Assessments
          </div>
          <div class="panel-body">
            <div class="col-md-15" *ngFor="let category of categories">
              <div *ngFor="
                  let _prereq of user['prerequisites'] | keyvalue : onCompare
                ">
                <div *ngIf="_prereq.key === category.prerequisite">
                  <h5 *ngIf="category.prerequisiteName !== 'Top 3'">
                    {{ category.prerequisiteName }}
                  </h5>
                  <h5 *ngIf="category.name === 'Skills'">
                    Skills Inventory (Top 3)
                  </h5>
                  <h5 *ngIf="category.name === 'Passions'">
                    MyPassionsProfile.com (Top 3)
                  </h5>
                  <h5 *ngIf="
                      category.name === 'Spiritual Gifts' &&
                      surveyType === 'values_based'
                    ">
                    Personal Values (Top 3)
                  </h5>
                  <h5 *ngIf="
                      category.name === 'Spiritual Gifts' &&
                      surveyType === 'faith_based'
                    ">
                    Spiritual Gifts (Top 3)
                  </h5>
                  <p *ngIf="_prereq.value.name">{{ _prereq.value.name }}</p>
                  <p *ngIf="_prereq.value[0]">
                    <span *ngFor="let v of _prereq.value; let last = last">
                      <span class="word-wrap">{{ v.name
                        }}<span *ngIf="v.level"> ({{ v.level.name }})</span><span *ngIf="!last">, </span></span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="!user['prerequisites']">
        <div class="hpanel">
          <h4>No prerequisites yet.</h4>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 m-b-lg" *ngIf="ipsatResults.length < 1">
        <h4>No IPSAT assessment results yet.</h4>
      </div>
      <div class="col-lg-4 col-md-6" *ngFor="let result of ipsatResults">
        <div class="hpanel">
          <div class="panel-body">
            <div class="row flex flex-column">
              <div class="col-md-12 text-center m-b-md" [ngStyle]="doughnutStyle">
                <small>{{ possessivePrefix }} Score Breakdown</small>
                <br />
                <br />
                <canvas #canvas id="doughnut{{ result._id }}"></canvas>
              </div>
              <div class="col-md-12 text-center">
                <h1 class="font-extra-bold">
                  {{ result.total }}
                </h1>
                <small>IPSAT score out of 100</small>
              </div>
            </div>
          </div>
          <div class="panel-footer flex flex-vertical-center">
            <i class="fa fa-calendar"></i>&nbsp;&nbsp;Completed on
            {{ result.updatedAt | date }}
            <div class="flex-grow"></div>
            <div class="pull-right">
              <a class="btn btn-info btn-sm" [routerLink]="['/users', user['_id'], 'surveyResults', result._id]">
                <i class="fa fa-eye"></i>&nbsp;&nbsp;View result
              </a>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="col-md-8" *ngIf="surveyType === 'faith_based'">
        <div class="hpanel">
          <div class="panel-body">
            <h4 class="text-primary-2 m-b-sm">
              Watch this video to gain the maximum value from your IPSAT
              coaching session.
            </h4>
            <div class="nexleader-video-wrapper">
              <app-nexleader-small-group-video [hideDownloadLink]="true"
                [videoIdentifier]="'module_7_teaching'"></app-nexleader-small-group-video>
            </div>
            <br />
            <p>
              This video is re-purposed from the IPSAT for small groups
              curriculum. The previous module referenced is the video made
              available prior to taking the IPSAT in the onboarding process.
            </p>
            <br />
            <a class="btn btn-default" [href]="
                '/assets/files/IPSAT_Faith_Coaching_Prep_Worksheet.pdf'
              " target="_blank">
              <i class="fa fa-download"></i>&nbsp;&nbsp;Download IPSAT Coaching
              Prep Worksheet</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>