<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="row">
  <div class="col-xs-6">
    <select
      class="form-control"
      [(ngModel)]="region"
      (ngModelChange)="model = undefined;"
    >
      <option *ngFor="let region of regions" [value]="region">
        {{ region }}
      </option>
    </select>
  </div>
  <div class="col-xs-6">
    <select
      class="form-control"
      [(ngModel)]="model"
      (ngModelChange)="onChange()"
    >
      <option [ngValue]="undefined"></option>
      <option
        *ngFor="let timezone of timezones | filter : 'region' : region"
        [value]="timezone.timezone"
      >
        {{ timezone.name }}
      </option>
    </select>
  </div>
</div>
