<div class="embed-container">
  <iframe
    *ngIf="videoUrl"
    [src]="videoUrl"
    frameborder="0"
    allowfullscreen
    allowtransparency="true"
    scrolling="no"
    class="rally_embed"
    name="rally_embed"
    allowfullscreen
    mozallowfullscreen="true"
    webkitallowfullscreen="true"
    oallowfullscreen="true"
    msallowfullscreen="true"
    allow="fullscreen; microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  ></iframe>
</div>
