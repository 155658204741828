import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { SurveyResultService } from '../features/core/views/survey-result/survey-result.service';

@Injectable({
    providedIn: 'root'
})
export class DefaultRedirectResolver implements Resolve<string> {
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private surveyResultService: SurveyResultService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        const payload = this.authService.getTokenPayload();

        if (!(payload && (payload.user || payload.coach_user))) {
            return of('/login');
        }

        if (payload.coach_user) {
            return of('/multigroupCoaching');
        }

        const roles = payload.roles;
        const user_id = payload.user;

        if (roles.indexOf('admin') >= 0) {
            return of('/groups');
        }
        if (roles.indexOf('partneradmin') >= 0) {
            return of('/licensingPartners/groups');
        }
        if (roles.indexOf('groupadmin') >= 0) {
            return of(`/groups/${payload.group}`);
        }
        if (roles.indexOf('coach') >= 0) {
            return of(`/coaches/${user_id}/admin`);
        }

        return combineLatest([
            this.surveyResultService.queryUserIndex(user_id),
            this.userService.getUser(user_id)
        ]).pipe(
            map(([surveyResults, user]) => {
                if (roles.indexOf('directsignupunpaid') >= 0 || surveyResults.length < 1) {
                    if (user.onboardingExperience.identifier === 'ecourse') {
                        return '/ecourse/onboarding';
                    }
                    if (roles.indexOf('participant') < 0) {
                        return '/direct/landing';
                    }
                    return '/onboarding';
                }

                surveyResults.sort((a, b) => (new Date(a.updatedAt).getTime()) - (new Date(b.updatedAt).getTime()));
                return `/users/${user_id}/surveyResults/${surveyResults[0]._id}`;
            }),
            catchError(() => of('/login'))
        );
    }
}
