/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * InputGroup
 *
 * angular component
 *
 * dynamic selector dropdown for group
 *
 * This component is responsible for loading a list of groups, displaying a dropdown, and
 *  letting the user select one.
 *
 * If a user is logged in as a partner admin (not a super admin), they are restricted to selecting
 *  from only their licensing partner. This component is responsible for the frontend side of this
 *  behavior. We check the auth token and query for only groups we will be allowed to see.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { GroupService } from '../../../../services/group.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-input-group',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-group.component.html',
})
export class NexleaderInputGroupComponent implements OnInit {
  /**
   * model
   *
   * two-way object binding
   *
   * basically ng-model
   *
   * selecting an option in the dropdown should mutate this state to the selected option
   * mutating this state from an outside controller should update the dropdown
   * model should not be null in ngOnInit (after binding)
   */
  @Input() model: any;
  @Output() realOnChange: EventEmitter<any> = new EventEmitter<any>();
  options: any[] = [];

  constructor(
    private authService: AuthService,
    private groupService: GroupService,
    private errorHandler: ErrorHandlerService
  ) {}

  /**
   * ngOnInit()
   *
   * function: Angular lifecycle hook
   *
   * called after binding
   *
   * validates bindings then
   * loads the enum options
   */
  ngOnInit(): void {
    if (this.model === undefined) {
      throw new Error(
        "cannot initialize an InputLicensingPartner component with an undefined (null is actually fine) $ctrl.model; you wouldn't actually be binding to anything; try adding an ng-if with the same model property (or adding a model property if you didn't)."
      );
    }

    const payload = this.authService.getTokenPayload();
    if (!payload) {
      throw new Error(
        'cannot use licensing partner input if you are not authenticated; we have logic that depends on auth and your write query would 403 anyway'
      );
    }

    if (!payload.roles) {
      throw new Error(
        'cannot use licensing partner input as a consolidated coach user (roles required in payload) you are not authenticated; we have logic that depends on auth and your write query would 403 anyway'
      );
    }

    if (typeof payload.licensing_partner !== 'string') {
      throw new Error(
        'malformed auth payload licensing_partner is required; we have logic that depends on auth and your write query would 403 anyway'
      );
    }

    const isSuperAdmin = payload.roles.indexOf('admin') >= 0;

    if (isSuperAdmin) {
      this.groupService
        .queryIndex()
        .pipe(
          tap((response) => {
            this.options = response;
          }),
          catchError((error) => {
            this.errorHandler.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    } else {
      this.groupService
        .queryLicensingPartnerIndex(payload.licensing_partner)
        .pipe(
          tap((response) => {
            this.options = response;
          }),
          catchError((error) => {
            this.errorHandler.handleError(error);
            return of(null);
          })
        )
        .subscribe();
    }
  }

  trackByGroupId(index: number, group: any): number {
    return group._id;
  }

  onChange(): void {
    this.realOnChange.emit(this.model);
  }
}
