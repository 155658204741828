/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderChartPassionsRepresented
 *
 * Displays a chart representation of the "represented passions" of a team in the TeamReport
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderViewUsersWithAttributeModalComponent } from '../../../core/components/modals/view-users-with-attribute-modal/view-users-with-attribute-modal.component';

@Component({
  selector: 'app-nexleader-chart-passions-represented',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart-passions-represented.component.html',
})
export class NexleaderChartPassionsRepresentedComponent implements OnInit {
  /**
   * model - representedPassionLevels
   *
   * array in the following form:
   * [{
   *    name: 'Level 4',
   *    level: 4,
   *    value: 3,
   *    passions: [
   *        {
   *            name: 'Passion name'
   *            //other passion data
   *        }, {
   *            name: 'Passion name'
   *            //other passion data
   *        }, {
   *            name: 'Passion name'
   *            //other passion data
   *        }
   *       //other passions
   *    ]
   *}]
   */
  @Input() model: any[] = [];
  renderedModel: any[] = [];

  constructor(private modalService: BsModalService) { }

  //Wait for $onInit so the model has time to load, then validate and draw the chart.
  ngOnInit(): void {
    //Validate that there's a model on here
    if (!Array.isArray(this.model)) {
      throw new Error(
        'Array model is required for nexleaderChartPassionsRepresented (see component docs; maybe add an ng-if if it has to load)'
      );
    }

    // Reverse model because we want to start with level 4 passions
    // Do not want to mutate the array, so reassign it
    this.renderedModel = this.model.slice().reverse();
  }

  /**
   * viewUsers
   *
   * Allows a user to click on a passion and view the users who have that passion
   *
   * @param {Object} passion - A passion property to use inside of the modal
   */
  viewUsers(passion: any): void {
    this.modalService.show(NexleaderViewUsersWithAttributeModalComponent, {
      initialState: {
        title: passion.name + ' - Passions',
        users: passion.users,
        additionalClarificationCopy:
          'An asterisk denotes this is a top passion for this user.',
      },
    });
  }
}
