/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
/**
 * nexleaderOnboardingPassions
 *
 * The view component for the onboarding passions section of the onboarding preassessments.
 * This view contains information and fields that pertain to this part of the onboarding.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderInputPassionsComponent } from '../../../../../core/components/input-passions/input-passions.component';
import { NexleaderProfileWizardUploadFileComponent } from '../../../../../core/components/profile-wizard-upload-file/profile-wizard-upload-file.component';
import { NexleaderYesNoButtonsComponent } from '../../../../../core/components/yes-no-buttons/yes-no-buttons.component';
import { EnumsService } from '../../../../../../services/enum.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs';
import { BlobResourceService } from '../../../../../../services/blob.service';
import { NexleaderMyPassionProfileVerificationModalComponent } from '../../../../../core/components/modals/my-passion-profile-verification-modal/my-passion-profile-verification-modal.component';

@Component({
  selector: 'app-nexleader-onboarding-passions',
  standalone: true,
  imports: [CommonModule, NexleaderInputPassionsComponent, NexleaderProfileWizardUploadFileComponent, NexleaderYesNoButtonsComponent],
  templateUrl: './onboarding-passions.component.html',
})
export class NexleaderOnboardingPassionsComponent implements OnInit {
  /**
   * save
   *
   * Allows data that is edited on this onboarding strengths view to be saved.
   */
  @Output() save = new EventEmitter();

  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process
   */
  @Input() user: any;
  files: any;
  prerequisite: any;
  prerequisiteLink!: string;

  constructor(private enums: EnumsService, private blobService: BlobResourceService, private modalService: BsModalService) { }

  ngOnInit() {
    this.enums.getEnums().subscribe((a) => {
      this.prerequisite = a.Prerequisites.PASSIONS;
    })

    // We need a passions assessment link
    if (!this.user.group) throw new Error('user.group is required for component nexleaderOnboardingPassions.');
    if (!this.user.group.preferredPreassessments) throw new Error('user.group.preferredPreassessments is required for component nexleaderOnboardingPassions.');
    if (!this.user.group.preferredPreassessments.passions) throw new Error('user.group.preferredPreassessments.passions is required for component nexleaderOnboardingPassions.');

    if (!this.user.prerequisites.passions) this.user.prerequisites.passions = [];

    // Set the link to the passion's assessment link
    this.prerequisiteLink = this.user.group.preferredPreassessments.spiritualGifts;

    // Load all the files for the user
    this.loadFiles();
  }

  /**
   * loadFiles
   *
   * Allows us to query for files for a specific user based off of data passed into the component
   */
  loadFiles(): void {
    this.blobService.queryUser(this.user._id).pipe(
      tap((files) => {
        this.files = files;
      })
    ).subscribe();
  }

  /**
   * toggleMyPassionProfileConfirmationModal()
   * 
   * function
   * 
   * Toggles the confirmation modal that users see before they take the MyPassionProfile
   * assessment.
   */
  toggleMyPassionProfileConfirmationModal() {
    const modalRef = this.modalService.show(NexleaderMyPassionProfileVerificationModalComponent);
  }
}
