<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="hpanel">
          <div class="panel-heading">
            <h3>Not Assigned Yet</h3>
          </div>
          <div class="panel-body">
            <span
              >Looks like you don't have a coach assigned yet. That's okay. You
              can still take the IPSAT.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
