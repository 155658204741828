/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentPurchaseEcourseContentUpgrade
 *
 * angular component
 *
 * In January 2021, Steve updated the Ecourse Content spec. In addition to providing access to the ECourse users who
 *  have completed their onboardings, the EcourseContent view must also provide non-Ecourse users who have completed
 *  their IPSATs the option to purchase access to the Ecourse Content.
 *
 * That component handles showing/hiding links. The backend handles access control.
 *
 * This component handles the frontend purchase operation that flips the bit that grants access.
 *
 * See EcourseContent.view
 * See User.model (specifically, hasUpgradedFromNonEcourseToEcourseContentAccess)
 * See Rali.logic (specifically, userHasRaliAccess())
 */
import { UserService } from './../../../../services/user.service';
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderCurrencyComponent } from '../../../store/components/currency/currency.component';
import { StripePaymentService } from '../../../../services/stripe-payment.service';
import { EcourseContentUpgradeService } from '../../resources/ecourse-content-upgrade.service';
import {
  EMPTY,
  catchError,
  combineLatest,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { FormsModule } from '@angular/forms';
import { SuccessService } from '../../../../services/success.service';
import { Router, RouterModule } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderStripeElementModalComponent } from '../../../store/components/stripe-element-modal/stripe-element-modal.component';

@Component({
  selector: 'app-nexleader-purchase-ecourse-content-upgrade',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderCurrencyComponent,
    FormsModule,
    RouterModule,
    NexleaderStripeElementModalComponent,
  ],
  templateUrl: './purchase-ecourse-content-upgrade.component.html',
})
export class NexleaderPurchaseEcourseContentUpgradeComponent implements OnInit {
  /**
   * userId
   *
   * string; Mongoose _id; the user we might purchase an upgrade for
   *
   * An integer representing which module video we're trying to load
   */
  @Input() userId: string = '';

  /**
   * loading
   *
   * @type {boolean} true if the component is loading (e.g. pricing information)
   */
  loading = true;

  /**
   * transactionIsInProgress
   *
   * this flag is set to true if the user has clicked submit and we have not
   *  yet received a response from the server; we don't want them to be able to
   *  click submit again
   *
   * @type {boolean}
   */
  transactionIsInProgress = false;

  /**
   * pricingConfig
   *
   * this object contains all data relevant to that price of the upgrade
   *
   * a deep watch on this object calls the loadPrice() function
   *
   * this keeps the price accurate even when clifton strengths is enabled/disabled
   *
   * @type {null}
   */
  pricingConfig: any = null;

  /**
   * This field is bound to the text field where a user types a discount code
   *  If they click "validate" and it's valid, it becomes the discount code.
   * @type {null}
   */
  candidateDiscountCodeName: string = '';
  user: any;
  pricing: any;
  isModalOpen: boolean = false;
  // A relative URL we can use to redirect the user to in case they are not logged in
  relativeUrl: string = '';

  constructor(
    private ecourseContentUpgradeService: EcourseContentUpgradeService,
    private stripePaymentService: StripePaymentService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.relativeUrl = `/users/${this.userId}/ecourseContent`;
    this.initializeComponent();
  }

  private initializeComponent(): void {
    // Validate bindings
    if (!this.userId) {
      throw new Error(
        'string nexleaderDataUserId is required for nexleaderComponentPurchaseEcourseContentUpgrade; See binding documentation.'
      );
    }

    this.pricingConfig = {
      user_id: this.userId,
    };

    this.loadPricing();
  }

  private loadPricing(): void {
    this.loading = true;

    combineLatest([
      this.ecourseContentUpgradeService.getPricing(this.pricingConfig),
      this.userService.getUser(this.userId),
    ])
      .pipe(
        tap(([pricing, user]) => {
          this.pricing = pricing;
          this.user = user;
        }),
        catchError((error) => {
          this.loading = false;
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe(() => {
        this.loading = false;
      });
  }

  /**
   * validateCandidateDiscountCode()
   *
   * validates the coupon code using a backend endpoint then assigns the name
   *  value to teamReportDiscountName (from candidateTeamReportDiscountName)
   *
   * @returns {*}
   */
  validateCandidateDiscountCode(): void {
    const uppercasedDiscountCode = this.candidateDiscountCodeName.toUpperCase();

    this.ecourseContentUpgradeService
      .getPricing({
        user_id: this.pricingConfig.user_id,
        discount_code_name: uppercasedDiscountCode,
      })
      .pipe(
        tap(() => {
          this.pricingConfig.discount_code_name = uppercasedDiscountCode;
          this.loadPricing();
        }),
        catchError((error) => {
          if (error.status === 422) {
            this.errorHandlerService.handleError({
              message:
                'Sorry, that discount code is not valid for this IPSAT configuration. Is Clifton Strengths included with this discount code?',
              status: 422,
            });
          } else {
            this.errorHandlerService.handleError(error);
          }
          return of(null);
        })
      )
      .subscribe();
  }

  clearDiscountName(): void {
    this.pricingConfig.discount_code_name = null;
    this.loadPricing();
  }

  /**
   * isReadyToAdvance()
   *
   * perform simple validation on the form data and make sure surveyType has loaded (and is valid)
   *
   * Note: this is no guarantee we won't get a 422 - e.g. the email could be valid but a duplicate
   *
   * @returns {boolean} true if we're ready to submit to the backend
   */
  isReadyToAdvance(): boolean {
    if (this.transactionIsInProgress) return false;
    if (this.isModalOpen) return false;
    if (this.loading) return false;
    if (!this.user) return false;
    if (!this.pricingConfig) return false;
    if (!this.pricingConfig.user_id) return false;
    return true;
  }

  /**
   * checkout()
   *
   * function
   *
   * this is the main form submission
   *
   * @returns {boolean} false if we cancelled due to frontend validation
   */
  checkout(): void {
    // Abort if the form doesn't meet frontend validation requirements
    //  Those requirements are pretty weak, so there's no point bothering the backend
    //  if they fail
    if (!this.isReadyToAdvance()) {
      return;
    }

    this.isModalOpen = true;
    const modalRef = this.modalService.show(
      NexleaderStripeElementModalComponent,
      {
        initialState: {
          title: 'ECourse Content Upgrade',
          description: `nexleader ${this.pricing.lineItems
            .map((lineItem: any) => lineItem.name)
            .join(' and ')} for ${this.user.firstName} ${this.user.lastName}`,
          currencyCode: this.pricing.total.currencyCode?.toLowerCase(),
          amount: Math.trunc(this.pricing.total.currencyQuantity * 100),
          email: this.user.email,
          isEmailEditable: false,
        },
      }
    );

    modalRef.onHide?.pipe(take(1)).subscribe((_) => {
      this.isModalOpen = false;
    });

    modalRef.content?.tokenGenerated
      .pipe(take(1))
      .pipe(
        tap(() => modalRef.hide()),
        switchMap((token) => {
          // If the price is greater than 0, there must be a token in order to successfully make a payment
          if (this.pricing.total.currencyQuantity > 0 && !token) {
            return EMPTY;
          }
          this.transactionIsInProgress = true;
          const purchaseRequestBody = {
            userId: this.pricingConfig.user_id,
            token,
            pricing: this.pricing,
            discountCodeName: this.pricingConfig.discount_code_name,
          };
          return this.ecourseContentUpgradeService
            .purchase(purchaseRequestBody)
            .pipe(
              catchError((err) => {
                this.transactionIsInProgress = false;
                this.errorHandlerService.handleError(err);
                return EMPTY;
              })
            );
        })
      )
      .subscribe(() => {
        this.successService.handle({
          message: 'Successfully purchased ECourse Content Upgrade.',
        });
        this.transactionIsInProgress = false;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([this.relativeUrl]);
        });
      });
  }
}
