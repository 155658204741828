/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { EMPTY, Observable, catchError, forkJoin, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../services/enum.service';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../../services/auth.service';
import { SurveyService } from '../../../../services/survey.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { NexleaderVimeoVideoComponent } from '../../../small-groups/components/vimeo-video/vimeo-video.component';
import { NexleaderPrerequisitesReviewModalComponent } from '../../../core/components/modals/prerequisites-review-modal/prerequisites-review-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-nexleader-onboarding-take-ipsat',
  standalone: true,
  imports: [CommonModule, RouterLink, NexleaderVimeoVideoComponent],
  templateUrl: './onboarding-take-ipsat.component.html',
})
export class NexleaderOnboardingTakeIpsatComponent implements OnInit {
  @Output() boundNext = new EventEmitter();
  @Input() standalone: boolean = true;
  routeParams: any;
  survey: any;
  user: any;
  prerequisites: any;
  previousOnboardingStep: any;
  valid!: boolean;
  showGoBack!: boolean;
  surveyTypeIdentifier!: string;
  userIsInSmallGroup!: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private enumsService: EnumsService,
    private userService: UserService,
    private authService: AuthService,
    private surveyService: SurveyService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    // Get the token payload so we can get information about the group and then request the right survey
    const payload = this.authService.getTokenPayload();

    if (!payload) {
      throw new Error(
        'payload required for component nexleaderOnboardingTakeIpsat (must be logged in)'
      );
    }

    if (!(payload.group || payload.primary_group)) {
      throw new Error(
        '(payload.group || payload.primary_group) required for component nexleaderOnboardingTakeIpsat'
      );
    }

    this.load();
  }

  load(): void {
    const payload = this.authService.getTokenPayload();
    forkJoin({
      survey: this.surveyService.queryGroup(
        payload.group || payload.primary_group
      ),
      user: this.userService.getUser(this.routeParams['user_id']),
      enums: this.enumsService.getEnums(),
    })
      .pipe(
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        })
      )
      .subscribe(({ survey, user, enums }) => {
        this.survey = survey;

        this.user = user;

        if (!this.user.prerequisites) {
          this.user.prerequisites = {};
        }

        if (!this.user.prerequisites.disclaimerAcknowledged) {
          this.user.prerequisites.disclaimerAcknowledged = false;
        }

        // We need a way to identify if a user is in a small group so we can alter the onboarding
        this.userIsInSmallGroup = this.user.smallGroup;
        // We need a way to know what type of group a user is in
        this.surveyTypeIdentifier = this.user.group.surveyType.identifier;

        //Validate the prerequisites before letting users take the IPSAT
        this.prerequisites = enums.Prerequisites;
        this.previousOnboardingStep = enums.OnboardingSteps.PREASSESSMENTS;

        this.user.prerequisites.passions =
          this.user.prerequisites.passions || [];
        this.user.prerequisites.spiritualGifts =
          this.user.prerequisites.spiritualGifts || [];
        this.user.prerequisites.skills = this.user.prerequisites.skills || [];
        this.user.prerequisites.strengths =
          this.user.prerequisites.strengths || [];

        if (!Array.isArray(this.user.prerequisites.passions)) {
          this.user.prerequisites.passions = Object.keys(
            this.user.prerequisites.passions
          ).map((a) => this.user.prerequisites.passions[a]);
        }

        if (!Array.isArray(this.user.prerequisites.spiritualGifts)) {
          this.user.prerequisites.spiritualGifts = Object.keys(
            this.user.prerequisites.spiritualGifts
          ).map((a) => this.user.prerequisites.spiritualGifts[a]);
        }

        if (!Array.isArray(this.user.prerequisites.skills)) {
          this.user.prerequisites.skills = Object.keys(
            this.user.prerequisites.skills
          ).map((a) => this.user.prerequisites.skills[a]);
        }

        if (!Array.isArray(this.user.prerequisites.strengths)) {
          this.user.prerequisites.strengths = Object.keys(
            this.user.prerequisites.strengths
          ).map((a) => this.user.prerequisites.strengths[a]);
        }

        const nonemptyStringObj = (strO: any) =>
          strO && typeof strO.name === 'string' && strO.name !== '';

        this.prerequisites.PASSIONS._completed =
          this.user.prerequisites.completedPassions &&
          this.user.prerequisites.passions.length === 3 &&
          this.user.prerequisites.passions.every(nonemptyStringObj);

        this.prerequisites.SPIRITUAL_GIFTS._completed =
          this.user.prerequisites.completedSpiritualGifts &&
          this.user.prerequisites.spiritualGifts.length === 3 &&
          this.user.prerequisites.spiritualGifts.every(nonemptyStringObj);

        this.prerequisites.SKILLS._completed =
          this.user.prerequisites.completedSkills &&
          this.user.prerequisites.skills.length === 3 &&
          this.user.prerequisites.skills.every(nonemptyStringObj);

        this.prerequisites.STRENGTHS._completed =
          this.user.prerequisites.completedStrengths &&
          this.user.prerequisites.strengths.length === 5 &&
          this.user.prerequisites.strengths.every(nonemptyStringObj);

        this.prerequisites.MBTI._completed =
          this.user.prerequisites.completedMbti && this.user.prerequisites.mbti;

        let valid = true;

        Object.keys(this.prerequisites).forEach((key) => {
          if (!this.prerequisites[key]._completed) {
            valid = false;
          }
        });

        this.valid = valid;

        this.showGoBack =
          (this.user.roles.length === 1 &&
            this.user.roles[0]._id === 'participant') ||
          this.standalone;
      });
  }

  save(): Observable<any> {
    return this.userService.saveUser(this.user).pipe(
      tap(() => {
        this.successService.handle();
      }),
      catchError((error) => {
        this.errorHandlerService.handleError(error);
        return EMPTY;
      })
    );
  }

  canProceed(): boolean {
    return (
      this.user &&
      this.user.prerequisites &&
      this.user.prerequisites.disclaimerAcknowledged &&
      this.valid
    );
  }

  setDisclaimerAcknowledged(val: boolean): void {
    this.user.prerequisites.disclaimerAcknowledged = val;
    this.save().subscribe();
  }

  goBack(): void {
    if (this.standalone) {
      this.router.navigate(['/']);
    } else {
      this.user.onboardingStep = this.previousOnboardingStep;
      this.save().subscribe(() => this.boundNext.emit());
    }
  }

  /**
   * canViewFaithBasedTakeIpsatVideo()
   *
   * helper function
   *
   * Determines whether the user can view the additional video on the take IPSAT screen.
   * This occurs if the user is not in the standalone view and is a values_based user.
   *
   * @returns {boolean}
   */
  canViewFaithBasedTakeIpsatVideo(): boolean {
    return !this.standalone && this.surveyTypeIdentifier === 'faith_based';
  }

  /**
   * canViewValuesBasedTakeIpsatVideo()
   *
   * helper function
   *
   * Determines whether the user can view the additional video on the take IPSAT screen.
   * This occurs if the user is not in the standalone view and is a values_based user.
   *
   * @returns {boolean}
   */
  canViewValuesBasedTakeIpsatVideo(): boolean {
    return !this.standalone && this.surveyTypeIdentifier === 'values_based';
  }

  /**
   * openReviewPrerequisitesModal()
   *
   * function
   */
  openReviewPrerequisitesModal(): void {
    this.modalService.show(NexleaderPrerequisitesReviewModalComponent, {
      initialState: {
        prerequisites: this.user.prerequisites,
        surveyType: this.surveyTypeIdentifier
      }
    });
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderOnboardingTakeIpsatComponent',
//   downgradeComponent({
//     component: NexleaderOnboardingTakeIpsatComponent,
//   }) as angular.IDirectiveFactory
// );
