<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div id="nexleader-wrapper">
  <div class="content">
    <app-nexleader-quick-view
      [model]="groups"
      [tableHeaders]="['Name', 'Experience', 'Tier', 'Licensing Partner']"
      [tableColumns]="[
        'name',
        '_surveyTypeName',
        '_tierName',
        '_licensingPartnerName'
      ]"
      [label]="'Groups'"
      (overrideOnNewElement)="open()"
      (onElementSelect)="selectedGroup = $event"
    >
      <ng-container *ngIf="selectedGroup?._id; else notSelected">
        <app-nexleader-group-card
          [groupId]="selectedGroup._id"
        ></app-nexleader-group-card>
      </ng-container>
      <ng-template #notSelected>
        <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
      </ng-template>
    </app-nexleader-quick-view>
  </div>
</div>
