<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper" *ngIf="group">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-body">
            <h2 class="mt-0">Manage {{ group.name }}</h2>
            <p>
              Welcome the IPSAT Management View. Here, you can manage everything
              that belongs to the group {{ group.name }}. You can also analyze
              how your group is doing using your Net Promoter Score.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'Licenses'"
          [value]="group.remainingLicenses + '/' + group.maxUsers"
          [text]="'Purchase more licenses'"
          [icon]="'cart-plus'"
          [link]="'#/groups/' + group._id + '/store'"
          [description]="
            'Licenses are used to invite new coaches and users to use the IPSAT'
          "
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'Coaches'"
          [value]="group.coachCount"
          [text]="'View your coaches'"
          [icon]="'briefcase'"
          [link]="'#/groups/' + group._id + '/coaches'"
          [description]="
            'Coaches serve to help participants understand their IPSAT scores'
          "
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'All Users'"
          [value]="group.userCount"
          [text]="'View your users'"
          [icon]="'users'"
          [link]="'#/groups/' + group._id + '/users'"
          [description]="
            'Users are able to take the IPSAT, have roles, and view past scores'
          "
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'All Invites'"
          [value]="group.inviteCount"
          [text]="'View your invites'"
          [icon]="'users'"
          [link]="'#/groups/' + group._id + '/invites'"
          [description]="
            'Invites are users that have been invited to the IPSAT platform but have not signed up'
          "
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'All Sessions'"
          [value]="group.sessionCount"
          [text]="'View all sessions'"
          [icon]="'calendar'"
          [link]="'#/groups/' + group._id + '/calendar'"
          [description]="
            'The total number of sessions since the creation of your group'
          "
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'All Team Reports'"
          [value]="group.teamReportCount"
          [text]="'View all reports'"
          [icon]="'flag'"
          [link]="'#/groups/' + group._id + '/teamReports'"
          [description]="
            'The total number of team reports since the creation of your group'
          "
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3">
        <app-nexleader-statistic
          [name]="'All Small Groups'"
          [value]="group.smallGroupCount"
          [text]="'View all small groups'"
          [icon]="'flag'"
          [link]="'#/groups/' + group._id + '/smallGroups'"
          [description]="
            'The total number of small groups since the creation of your group'
          "
        ></app-nexleader-statistic>
      </div>
      <div class="col-md-3" *ngIf="evaluationResults.length > 0">
        <app-nexleader-net-promoter-box
          [score]="netPromoter.score"
          [class]="netPromoter.class"
        ></app-nexleader-net-promoter-box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="hpanel">
          <div class="panel-body">
            <div class="stats-title pull-left">
              <h4>Coach Evaluation Results</h4>
            </div>
            <div
              class="btn-group pull-right m-b-sm"
              *ngIf="evaluationResults.length > 0"
            >
              <button
                class="btn"
                [ngClass]="{
                  'btn-primary': evaluationResultsFilter === 'user.lastName',
                  'btn-default': evaluationResultsFilter !== 'user.lastName'
                }"
                (click)="filterAlphabetically()"
              >
                <i class="fa fa-sort-alpha-asc"></i>&nbsp;&nbsp;Alphabetical
              </button>
              <button
                class="btn btn-default"
                [ngClass]="{
                  'btn-primary': evaluationResultsFilter === '',
                  'btn-default': evaluationResultsFilter !== ''
                }"
                (click)="filterByCreation()"
              >
                <i class="fa fa-calendar"></i>&nbsp;&nbsp;Creation Date
              </button>
            </div>
            <table class="table" *ngIf="evaluationResults.length > 0">
              <thead>
                <tr>
                  <th>
                    <i class="fa fa-user"></i>&nbsp;&nbsp;Participant Name
                  </th>
                  <th><i class="fa fa-briefcase"></i>&nbsp;&nbsp;Coach Name</th>
                  <th><i class="fa fa-eye"></i>&nbsp;&nbsp;View</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let evaluationResult of evaluationResults
                      | orderBy : evaluationResultsFilter
                  "
                >
                  <td>
                    {{ evaluationResult.user?.firstName }}
                    {{ evaluationResult.user?.lastName }}
                  </td>
                  <td>
                    {{ evaluationResult.coach?.firstName }}
                    {{ evaluationResult.coach?.lastName }}
                  </td>
                  <td>
                    <a [routerLink]="'/evaluationResults/' + evaluationResult._id"
                      >Link</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <p *ngIf="evaluationResults.length === 0">
              No coach evaluation results yet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
