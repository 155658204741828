/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewParticipantCourseContent
 *
 * angular component
 */
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseContentService } from '../../resources/course-content.service';
import { BlobResourceService } from '../../../../services/blob.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { NexleaderCourseContentCardComponent } from '../../components/course-content-card/course-content-card.component';

@Component({
  selector: 'app-nexleader-course-content-participant-view',
  standalone: true,
  imports: [CommonModule, NexleaderCourseContentCardComponent],
  templateUrl: './course-content-participant-view.component.html',
})
export class NexleaderCourseContentParticipantViewComponent implements OnInit {
  allCourseContent: any[] = [];
  blobs: any[] = [];
  mappedCourseContent: any[] = [];

  constructor(
    private courseContentService: CourseContentService,
    private blobResourceService: BlobResourceService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  /**
   * $ctrl.load()
   *
   * Loads all IPSAT U associated content.
   *
   * Called when the initial page loads.
   */
  load(): void {
    forkJoin([
      this.courseContentService.getCourseContent(),
      this.blobResourceService.queryCourseContent(),
    ])
      .pipe(
        tap(([courseContent, blobs]) => {
          this.allCourseContent = courseContent;
          this.blobs = blobs;

          this.mappedCourseContent = this.allCourseContent.map((content) => {
            const blob = this.blobs?.find(
              (b) =>
                b.file &&
                b.file.metadata &&
                b.file.metadata.courseContentId === content._id
            );
            return { ...content, blob: blob };
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCourseContentParticipantViewComponent',
//   downgradeComponent({
//     component: NexleaderCourseContentParticipantViewComponent,
//   }) as angular.IDirectiveFactory
// );
