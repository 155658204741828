/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/*global $*/
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule, CalendarView } from 'angular-calendar';
import { Subject, take } from 'rxjs';
import moment from 'moment-timezone';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nexleader-calendar',
  standalone: true,
  imports: [CommonModule, CalendarModule],
  templateUrl: './calendar.component.html',
})
export class NexleaderCalendarComponent implements OnInit, AfterViewInit {
  private _events: any[] = [];
  @Input() set events(value: any[]) {
    // const search = this.routerHelper.fetchQueryParams();
    const search = this.route.snapshot.queryParams;

    this.extended = search['extended'] === 'true';
    this.dayStartHour = this.extended ? 0 : 9;
    this.dayEndHour = this.extended ? 23 : 18;

    this._events = value
      .filter((event: any) => {
        if (!(event.start && event.end)) {
          console.warn('Malformed calendar event', event);
          return false;
        }
        if (this.extended) return true;
        if (search['view'] !== 'day') return true;
        return (
          new Date(event.start).getHours() >= 9 &&
          new Date(event.end).getHours() <= 18
        );
      })
      .map((event) => {
        event.start = new Date(event.start);
        event.end = new Date(event.end);
        event.id = event._id;
        return event;
      });
    this.refresh.next(true);
  }
  get events() {
    return this._events;
  }

  @Output() onCurrentEventChange = new EventEmitter();

  currentEvent: any;
  extended = false;
  timezone = '';
  timezoneAbbr = '';

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView; // to use in template
  viewDate = new Date();
  activeDay = new Date();
  activeDayIsOpen: boolean = false;
  refresh = new Subject<boolean>();
  referenceTime = Date.now();
  dayStartHour: number = 0; // start hour for day view
  dayEndHour: number = 23; // end hour for day view

  modalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    this.router.navigate([], {
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: { view: this.view, day: moment(this.viewDate).format('YYYY-MM-DD') }
    });
    // this.routerHelper.addMultipleQueryParams({
    //   view: this.view,
    //   day: moment(this.viewDate).format('YYYY-MM-DD'),
    // });
  }

  ngOnInit(): void {
    this.initCalendar();
    this.setupTimezone();
  }

  setView(view: CalendarView) {
    this.view = view;
    this.router.navigate([], {
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: { view }
    });
    // this.routerHelper.addQueryParams('view', this.view);
  }

  closeOpenMonthViewDay() {
    this.router.navigate([], {
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: { day: moment(this.viewDate).format('YYYY-MM-DD') }
    });
    // this.routerHelper.addQueryParams(
    //   'day',
    //   moment(this.viewDate).format('YYYY-MM-DD')
    // );
  }

  onEventClick(event: any, template: TemplateRef<void>): void {
    this.currentEvent = event.event;
    this.onCurrentEventChange.emit(this.currentEvent);
    this.modalRef = this.modalService.show(template);
    this.modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.closeModal();
      this.activeDayIsOpen = false;
    });
  }

  dayClickedForMonthCalendar(event: any) {
    const currentTime = Date.now();
    const onDayClick =
      event.sourceEvent.target.classList.contains('cal-day-number');
    if (onDayClick || currentTime - this.referenceTime <= 400) {
      // on day count click OR double clicked so redirect to day view
      this.openDayView(event.day.date);
      return;
    }

    this.referenceTime = currentTime;
    if (event.day.events?.length) {
      const clickedOnDifferentDate = this.activeDay !== event.day.date;
      this.activeDay = event.day.date;
      this.activeDayIsOpen = clickedOnDifferentDate
        ? true
        : !this.activeDayIsOpen;
    }
  }

  dayClickedForWeekCalendar(event: any) {
    this.openDayView(event.day.date);
  }

  openDayView(date: Date) {
    this.router.navigate([], {
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: {
        view: CalendarView.Day,
        day: moment(date).format('YYYY-MM-DD')
      }
    });
    // this.routerHelper.addMultipleQueryParams({
    //   view: CalendarView.Day,
    //   day: moment(date).format('YYYY-MM-DD'),
    // });
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
    }
    this.currentEvent = null;
    this.onCurrentEventChange.emit(null);
  }

  private initCalendar(): void {
    const search = this.route.snapshot.queryParams;
    // const search = this.routerHelper.fetchQueryParams();

    if (search['day']) {
      this.viewDate = new Date(search['day']);
    }

    switch (search['view']) {
      case 'week':
        this.view = CalendarView.Week;
        break;
      case 'day':
        this.view = CalendarView.Day;
        break;
      default:
        this.view = CalendarView.Month;
        break;
    }
  }

  private setupTimezone(): void {
    this.timezone = moment.tz.guess();
    this.timezoneAbbr = moment().tz(this.timezone).format('z');
  }

  toggleExtended(): void {
    this.extended = !this.extended;
    this.router.navigate([], {
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: {
        extended: this.extended.toString()
      }
    });
    // this.routerHelper.addQueryParams('extended', this.extended.toString());
  }
}
