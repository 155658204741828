/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderViewSmallGroupVideo
 *
 * Component
 *
 * This component allows users to view videos from Vimeo, specifically designed for the small group users.
 *
 * requires external binding to specify which video the user is trying to view
 *
 * This component is responsible for converting an internal video identifier to a vimeo id then passing it
 *  to the VimeoVideo component, which displays the video.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderVimeoVideoComponent } from '../vimeo-video/vimeo-video.component';

@Component({
  selector: 'app-nexleader-small-group-video',
  standalone: true,
  imports: [CommonModule, NexleaderVimeoVideoComponent],
  templateUrl: './small-group-video.component.html',
})
export class NexleaderSmallGroupVideoComponent implements OnInit {
  /**
   * videoIdentifier
   *
   * string
   *
   * A string that connects the requested video url with the URL of the vimeo video
   */
  @Input() videoIdentifier: string = '';

  /**
   * hideDownloadLink
   *
   * boolean
   *
   * A boolean determining if we allow or disallow a user from downloading the video
   */
  @Input() hideDownloadLink: boolean = false;

  vimeoId: string = '';
  vimeoDownloadId: string = '';

  ngOnInit(): void {
    // We need a video identifier to correct assign a video url.
    if (!this.videoIdentifier)
      throw new Error('param videoIdentifier of type string is required');

    this.vimeoId = this.getVimeoIdForVideoIdentifier(this.videoIdentifier);
    this.vimeoDownloadId = this.getVimeoDownloadIdForVideoIdentifier(
      this.videoIdentifier
    );
  }

  /**
   * getVimeoIdForVideoIdentifier()
   *
   * pure function
   *
   * Returns a vimeo video id based off a param identifier
   *
   * @param {string} identifier - A string representing an identifier used to identify a vimeo video
   *
   * @return {string} the vimeo id of that video
   */
  private getVimeoIdForVideoIdentifier(identifier: string): string {
    switch (identifier) {
      case 'ipsat_promo_video':
        return '276237651';
      case 'ipsat_launch_video_1':
        return '273137006';
      case 'ipsat_launch_video_2':
        return '272845227';
      case 'ipsat_launch_video_3':
        return '273186794';
      case 'module_1_intro':
        return '424994558';
      case 'module_1_teaching':
        return '424994807';
      case 'module_2_intro':
        return '424995531';
      case 'module_2_teaching':
        return '424995706';
      case 'module_3_intro':
        return '425173033';
      case 'module_3_teaching':
        return '425174688';
      case 'module_4_intro':
        return '425176921';
      case 'module_4_teaching':
        return '425177741';
      case 'module_5_intro':
        return '425179589';
      case 'module_5_teaching':
        return '425180449';
      case 'module_6_intro':
        return '425186284';
      case 'module_6_teaching':
        return '425183551';
      case 'module_7_intro':
        return '425186996';
      case 'module_7_teaching':
        return '425187908';
      default:
        throw new Error(
          'no smallGroup vimeo id found for the internal video identifier: ' +
          identifier
        );
    }
  }

  /**
   * getVimeoDownloadIdForVideoIdentifier()
   *
   * pure function
   *
   * Returns a vimeo ID with the correct additional ID (the part after the slash) for a user to download a
   *  video from vimeo.
   *
   * @param {string} identifier - A string representing an identifier used to identify a vimeo video
   *
   * @return {string} the vimeo download id of that video
   */
  private getVimeoDownloadIdForVideoIdentifier(identifier: string): string {
    switch (identifier) {
      case 'ipsat_launch_video_1':
        return '273137006/453dda2878';
      case 'ipsat_launch_video_2':
        return '272845227/688f6ed129';
      case 'ipsat_launch_video_3':
        return '273186794/bb3b9d09dc';
      case 'module_1_intro':
        return '424994558/e35cc5916b';
      case 'module_1_teaching':
        return '424994807/81a0fabf96';
      case 'module_2_intro':
        return '424995531/00a72f9dd0';
      case 'module_2_teaching':
        return '424995706/75245de184';
      case 'module_3_intro':
        return '425173033/3337803ad5';
      case 'module_3_teaching':
        return '425174688/c287cc3d9c';
      case 'module_4_intro':
        return '425176921/d93efb0d32';
      case 'module_4_teaching':
        return '425177741/2673819137';
      case 'module_5_intro':
        return '425179589/c5cc62b35c';
      case 'module_5_teaching':
        return '425180449/ca60857c7a';
      case 'module_6_intro':
        return '425186284/b5662ec427';
      case 'module_6_teaching':
        return '425183551/b4dfe9d63d';
      case 'module_7_intro':
        return '425186996/5be89c2784';
      case 'module_7_teaching':
        return '425187908/65cb6c76a8';
      default:
        throw new Error(
          'no smallGroup vimeo id found for the internal video identifier: ' +
          identifier
        );
    }
  }
}
