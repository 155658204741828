<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="row">
  <div class="col-xs-12">
    <div class="form-group" *ngIf="users.length > 0 || searchText !== ''">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="searchText"
          placeholder="Search for a user"
          [ngModelDebounce]="250"
          (ngModelDebounceChange)="reloadForSearch()"
        />
        <div class="input-group-btn">
          <button
            class="btn"
            [ngClass]="{
              'btn-primary': filter === 'lastName',
              'btn-default': filter !== 'lastName'
            }"
            (click)="filterAlphabetically()"
          >
            <i class="fa fa-sort-alpha-asc"></i>&nbsp;&nbsp;Alphabetical
          </button>
          <button
            class="btn btn-default"
            [ngClass]="{
              'btn-primary': filter === '',
              'btn-default': filter !== ''
            }"
            (click)="filterByCreation()"
          >
            <i class="fa fa-calendar"></i>&nbsp;&nbsp;Creation Date
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<p *ngIf="users.length < 1" style="margin-top: 10px">No users.</p>
<div class="table-responsive" *ngIf="users.length > 0">
  <table class="table" style="margin-bottom: 0">
    <thead>
      <th>Name</th>
      <th><i class="fa fa-envelope-o"></i>&nbsp;&nbsp;Email</th>
      <th>
        <i class="fa fa-envelope-o"></i>&nbsp;&nbsp;Preferred Contact Info
      </th>
      <th *ngIf="!isCoach"><i class="fa fa-clone"></i>&nbsp;&nbsp;Roles</th>
      <th *ngIf="isMultipleGroup">
        <i class="fa fa-users"></i>&nbsp;&nbsp;Group
      </th>
      <th *ngIf="!isCoach && !disableCoachEdit">
        <i class="fa fa-briefcase"></i>&nbsp;&nbsp;Coach
      </th>
      <th><i class="fa fa-paper-plane-o"></i>&nbsp;&nbsp;Coach Evaluation</th>
      <th><i class="fa fa-eye"></i>&nbsp;&nbsp;Survey Results</th>
      <th><i class="fa fa-shield"></i>&nbsp;&nbsp;SkillsInventory Results</th>
      <th><i class="fa fa-file-archive-o"></i>&nbsp;&nbsp;Files</th>
      <th *ngIf="!isCoach">
        <i class="fa fa-pencil"></i>&nbsp;&nbsp;Edit User
      </th>
      <th *ngIf="!isCoach"><i class="fa fa-trash-o"></i>&nbsp;&nbsp;Delete</th>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let user of users | filter : undefined : searchText | orderBy : filter
        "
      >
        <td>{{ user.firstName }} {{ user.lastName }}</td>
        <td>
          <a [href]="'mailto:' + user.email">{{ user.email }}</a>
        </td>
        <td>
          <span *ngIf="user.preferredContactAddress"
            >{{ user.preferredContactType.name }} -
            <span *ngIf="user.preferredContactType.index === 'phone'">{{
              user.preferredContactAddress | telephone
            }}</span
            ><span *ngIf="user.preferredContactType.index !== 'phone'">{{
              user.preferredContactAddress
            }}</span>
          </span>
          <span *ngIf="!user.preferredContactAddress"> Not Provided </span>
        </td>
        <td *ngIf="!isCoach" style="text-transform: capitalize">
          <ul>
            <li *ngFor="let role of user.roles">
              {{ role.name || role | capitalize }}
            </li>
          </ul>
        </td>
        <td *ngIf="isMultipleGroup">
          {{ user.group.name }}
        </td>
        <td *ngIf="!isCoach && !disableCoachEdit">
          <select
            class="form-control"
            [(ngModel)]="user.coach"
            (ngModelChange)="saveUser(user)"
          >
            <option value="">-- Select a coach --</option>
            <option
              *ngFor="let coach of coaches; trackBy: trackById"
              [value]="coach._id"
            >
              {{ coach.firstName }} {{ coach.lastName }}
            </option>
          </select>
        </td>
        <td>
          <a
            (click)="sendEvaluationEmail(user)"
            *ngIf="user.coach && user.evaluationResults.length === 0"
          >
            <i class="fa fa-paper-plane-o"></i>&nbsp;&nbsp;
            {{ user.coachEvalSent ? "Resend" : "Send" }}
            Email</a
          >
          <span *ngIf="!user.coach">No Coach</span>
          <a
            [routerLink]="['/evaluationResults', user.evaluationResults[0]._id]"
            *ngIf="user.evaluationResults.length > 0"
            >View Results</a
          >
        </td>
        <td>
          <a [routerLink]="['/users', user._id, 'surveyResults']"
            ><i class="fa fa-eye"></i>&nbsp;&nbsp;View Results</a
          >
        </td>
        <td>
          <a [routerLink]="['/users', user._id, 'skillsInventoryResults']"
            ><i class="fa fa-shield"></i>&nbsp;&nbsp;View Results</a
          >
        </td>
        <td>
          <a [routerLink]="['/users', user._id, 'files']"
            ><i class="fa fa-file-archive-o"></i>&nbsp;&nbsp;View Files</a
          >
        </td>
        <td *ngIf="!isCoach">
          <a (click)="open(user._id)">Edit User</a>
        </td>
        <td *ngIf="!isCoach">
          <button
            [attr.disabled]="
              user._id === currentUser._id ||
              (indexOfPropertyValue(user.roles, 'name', 'Coach') > -1 &&
                user.completedIpsat)
                ? true
                : null
            "
            class="btn"
            [ngClass]="{ 'btn-danger': user._id !== currentUser }"
            (click)="deleteUser(user)"
          >
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div>
  <hr />
  <div class="flex flex-vertical-center">
    <div *ngIf="users.length < limit">Showing all results.</div>
    <div *ngIf="users.length >= limit">
      Showing the first {{ limit }} results.
    </div>
    <div class="flex-grow"></div>
    <span class="input-group">
      <select
        class="form-control"
        [(ngModel)]="limit"
        (ngModelChange)="reloadForSearch()"
      >
        <option [ngValue]="10">Show 10</option>
        <option [ngValue]="20">Show 20</option>
        <option [ngValue]="30">Show 30</option>
        <option [ngValue]="999999">Show All</option>
      </select>
    </span>
  </div>
</div>
