/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/**
 * Created by jakebillings on 7/26/16.
 */
/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class LicensingPartnerService {
  constructor(private http: HttpClient) { }

  get(licensingPartnerId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE}licensingPartners/${licensingPartnerId}`,
      {
        withCredentials: true,
      }
    );
  }

  save(licensingPartner: any, licensingPartnerId?: string): Observable<any> {
    const url =
      `${API_BASE}licensingPartners` +
      (licensingPartnerId ? `/${licensingPartnerId}` : '');
    return this.http.post(url, licensingPartner, {
      withCredentials: true,
    });
  }

  getGroup(groupId: string): Observable<any> {
    return this.http.get<any>(`${API_BASE}groups/${groupId}/licensingPartner`, {
      withCredentials: true,
    });
  }

  queryIndex(): Observable<any[]> {
    return this.http.get<any[]>(`${API_BASE}licensingPartners/index`, {
      withCredentials: true,
    });
  }

  getLite(licensingPartnerId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE}licensingPartners/${licensingPartnerId}/lite`,
      {
        withCredentials: true,
      }
    );
  }

  sendStripeConnectAccountInvite(
    licensingPartnerId: string,
    invite: any
  ): Observable<any> {
    return this.http.post(
      `${API_BASE}licensingPartners/${licensingPartnerId}/sendStripeConnectAccountInvite`,
      invite,
      { withCredentials: true }
    );
  }

  getCliftonStrengthsAssessmentCode(
    licensingPartnerId: string
  ): Observable<any> {
    return this.http.get(
      `${API_BASE}licensingPartners/${licensingPartnerId}/cliftonStrengthsAssessmentCode`, {
      withCredentials: true
    }
    );
  }
}
