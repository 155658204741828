<div class="form-group">
  <label class="control-label">Coach</label>
  <select
    class="form-control"
    [(ngModel)]="model"
    (ngModelChange)="onChange($event)"
  >
    <option value="undefined">-- Select Coach --</option>
    <option
      *ngFor="let coach of coaches; trackBy: trackByCoachId"
      [value]="coach._id"
    >
      {{ coach._name }}
    </option>
  </select>
</div>
