/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderGroupDirectPurchaseSettings
 *
 * angular component
 *
 * This component is responsible for managing a group's relationship with the Direct Purchase and ECourse
 *  features. This is where these features are enabled/disabled as well as configured.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { SuccessService } from '../../../../services/success.service';
import { catchError, of, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-group-direct-purchase-settings',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './group-direct-purchase-settings.component.html',
})
export class NexleaderGroupDirectPurchaseSettingsComponent implements OnInit {
  @Input() group: any;
  coaches: any[] = [];
  loading: boolean = true;

  constructor(
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private successService: SuccessService
  ) { }

  ngOnInit(): void {
    if (!this.group) {
      throw new Error(
        'nexleaderGroupDirectPurchaseSettings requires a group object'
      );
    }

    this.userService
      .queryGroupRole(this.group._id, 'coach')
      .pipe(
        tap((coaches) => {
          this.coaches = coaches;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe(() => {
        this.loading = false;
      });
  }

  getSignupLink(params: any): string {
    if (
      params.surveyTypeIdentifier &&
      params.onboardingExperienceIdentifier &&
      !params.groupId &&
      !params.coachId
    ) {
      return (
        window.location.origin +
        '/#/direct/signup?survey_type_identifier=' +
        params.surveyTypeIdentifier +
        '&onboarding_experience_identifier=' +
        params.onboardingExperienceIdentifier
      );
    }

    if (
      params.groupId &&
      params.onboardingExperienceIdentifier &&
      !params.surveyTypeIdentifier &&
      !params.coachId
    ) {
      return (
        window.location.origin +
        '/#/direct/signup?group_id=' +
        params.groupId +
        '&onboarding_experience_identifier=' +
        params.onboardingExperienceIdentifier
      );
    }

    if (
      params.coachId &&
      params.onboardingExperienceIdentifier &&
      !params.surveyTypeIdentifier &&
      !params.groupId
    ) {
      return (
        window.location.origin +
        '/#/direct/signup?coach_id=' +
        params.coachId +
        '&onboarding_experience_identifier=' +
        params.onboardingExperienceIdentifier
      );
    }

    return '';
  }

  getEcourseMarketingLink(params: any): string {
    if (!params.groupId && !params.coachId) {
      return window.location.origin + '/#/direct/ecourse';
    }

    if (params.groupId && !params.coachId) {
      return (
        window.location.origin + '/#/direct/ecourse?group_id=' + params.groupId
      );
    }

    if (!params.groupId && params.coachId) {
      return (
        window.location.origin + '/#/direct/ecourse?coach_id=' + params.coachId
      );
    }
    return '';
  }

  setCoachAssignment(coach: any, coachShouldBeEnabled: boolean): void {
    const modifiedCoach = {
      ...coach,
      enableAutomaticDirectPurchaseAssignment: coachShouldBeEnabled,
    };

    this.userService
      .saveUser(modifiedCoach)
      .pipe(
        tap(() => {
          coach.enableAutomaticDirectPurchaseAssignment = coachShouldBeEnabled;
          this.successService.handle({
            message: 'Successfully saved coach assignment setting.',
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  setCoachDirectPurchaseReferral(
    coach: any,
    coachShouldBeEnabled: boolean
  ): void {
    const modifiedCoach = {
      ...coach,
      enableDirectPurchaseReferralForThisCoach: coachShouldBeEnabled,
    };

    this.userService
      .saveUser(modifiedCoach)
      .pipe(
        tap(() => {
          coach.enableDirectPurchaseReferralForThisCoach = coachShouldBeEnabled;
          this.successService.handle({
            message: 'Successfully saved coach referral setting.',
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
