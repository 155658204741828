<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Share your result</h3>
</div>
<div class="modal-body">
  <div class="btn-group">
    <a
      class="btn btn-default btn-sm"
      href="https://facebook.com/sharer/sharer.php?u={{ url }}"
      target="_blank"
      aria-label="Share on Facebook"
    >
      Share on Facebook
    </a>
    <a
      class="btn btn-default btn-sm"
      href="https://twitter.com/intent/tweet/?text={{ combined }}"
      target="_blank"
    >
      Share on Twitter
    </a>
    <a
      class="btn btn-default btn-sm"
      href="mailto:?subject={{ text }}&amp;body={{ url }}"
      target="_self"
    >
      Share by E-Mail
    </a>
    <a
      class="btn btn-default btn-sm"
      href="https://www.linkedin.com/shareArticle?mini=true&amp;url={{
        url
      }}&amp;title={{ text }}&amp;summary=&amp;source="
      target="_blank"
    >
      Share on LinkedIn
    </a>
  </div>
  <br /><br />
  <div class="form-group">
    <label class="control-label">Share this link with someone</label>
    <app-nexleader-copy-link
      [relativeLink]="'#/s/' + shareableID"
    ></app-nexleader-copy-link>
  </div>
</div>
<div class="modal-footer">
  <div class="pull-right">
    <button class="btn btn-default" (click)="close.emit()">Close</button>
  </div>
</div>
