<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="row">
  <div class="col-xs-8">
    <div class="form-group" *ngIf="!!sessions">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="searchText"
        placeholder="Search for a session"
        [ngModelDebounce]="250"
        (ngModelDebounceChange)="load()"
      />
    </div>
  </div>
  <div class="col-xs-4">
    <select class="form-control" [(ngModel)]="sort" (ngModelChange)="load()">
      <option *ngFor="let sorter of sortOptions" [ngValue]="sorter">
        {{ sorter.name }}
      </option>
    </select>
  </div>
</div>
<div class="session-table table-responsive">
  <table class="table">
    <thead>
      <th><i class="fa fa-user"></i>&nbsp;&nbsp;Participant</th>
      <th><i class="fa fa-user"></i>&nbsp;&nbsp;Coach</th>
      <th><i class="fa fa-users"></i>&nbsp;&nbsp;Group</th>
      <th><i class="fa fa-check-circle"></i>&nbsp;&nbsp;Complete</th>
      <th><i class="fa fa-check-circle"></i>&nbsp;&nbsp;Must Record</th>
      <th><i class="fa fa-calendar"></i>&nbsp;&nbsp;Start</th>
      <th><i class="fa fa-calendar"></i>&nbsp;&nbsp;End</th>
      <th><i class="fa fa-calendar"></i>&nbsp;&nbsp;Timezone</th>
      <th><i class="fa fa-pencil"></i>&nbsp;&nbsp;Edit Session</th>
      <th><i class="fa fa-eye"></i>&nbsp;&nbsp;Calendar</th>
    </thead>
    <tbody>
      <tr *ngFor="let session of sessions">
        <td>
          {{ session.participant?.firstName }}
          {{ session.participant?.lastName }}
        </td>
        <td>{{ session.coach.firstName }} {{ session.coach.lastName }}</td>
        <td>{{ session.coach.group.name }}</td>
        <td>
          <span class="label label-success" *ngIf="session.completed"
            >Completed</span
          >
          <span class="label label-info" *ngIf="!session.completed"
            >Not Completed Yet</span
          >
        </td>
        <td>
          <span class="label label-default" *ngIf="!session.mustBeRecorded"
            >Not required</span
          >
          <span class="label label-info m-t-sm" *ngIf="session.mustBeRecorded"
            >Must Record</span
          >
        </td>
        <td>
          {{ session.start | date : "MMM d y" }}<br />{{
            session.start | date : "h:mm a"
          }}
        </td>
        <td>
          {{ session.end | date : "MMM d y" }}<br />{{
            session.end | date : "h:mm a"
          }}
        </td>
        <td>{{ session.timezone }}</td>
        <td><a (click)="editSession(session)">Edit this Session</a></td>
        <td>
          <a (click)="viewSessionInCalendar(session)">View in Calendar</a>
        </td>
      </tr>
    </tbody>
  </table>

  <div>
    <hr />
    <div class="flex flex-vertical-center">
      <div *ngIf="sessions.length < limit">
        Showing all {{ sessions.length }} results.
      </div>
      <div *ngIf="sessions.length >= limit">
        Showing the first {{ limit }} results.
      </div>
      <span> <a (click)="showHelp()">Help</a></span>
      <div class="flex-grow"></div>
      <div class="input-group">
        <select
          class="form-control"
          [(ngModel)]="limit"
          (ngModelChange)="load()"
        >
          <option value="10">Show 10</option>
          <option value="20">Show 20</option>
          <option value="30">Show 30</option>
          <option value="999999">Show All</option>
        </select>
      </div>
    </div>
  </div>
</div>
