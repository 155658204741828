/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/**
 * nexleaderSessionsComponent
 *
 * angular component
 *
 * A component used to display a list of sessions and allow users to interact with those sessions. Used both as an admin
 *  and as a group admin.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionService } from '../../views/session/session.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import { Observable, catchError, of, take, tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NexleaderSessionTableHelpModalComponent } from '../modals/session-table-help-modal/session-table-help-modal.component';
import { NexleaderNewSessionModalComponent } from '../modals/new-session-modal/new-session-modal.component';
import { NexleaderEditSessionModalComponent } from '../modals/edit-session-modal/edit-session-modal.component';
import moment from 'moment';

@Component({
  selector: 'app-nexleader-sessions-table',
  standalone: true,
  imports: [CommonModule, FormsModule, NgModelDebounceChangeDirective],
  templateUrl: './sessions-table.component.html',
})
export class NexleaderSessionsTableComponent implements OnInit {
  /**
   * groupId
   *
   * string
   *
   * A string representing a group's ID (not required) to query sessions for an individual group.
   */
  @Input() groupId: string | undefined;

  /**
   * licensingPartnerId
   *
   * string
   *
   * A string representing a licensing partner's ID (not required) to query sessions for an individual
   *  licensing partner.
   */
  @Input() licensingPartnerId: string | undefined;

  // Configure some of the requirements used to render the table properly
  limit: number = 10;
  searchText: string = '';

  // Configuration used for sorting the table
  sortOptions = [
    { name: 'Start Date (+)', value: 'start' },
    { name: 'Start Date (-)', value: '-start' },
    { name: 'End Date (+)', value: 'end' },
    { name: 'End Date (-)', value: '-end' },
    { name: 'Timezone (+)', value: 'timezone' },
    { name: 'Timezone (-)', value: '-timezone' },
    { name: 'Completed (+)', value: '-completed' },
    { name: 'Completed (-)', value: 'completed' },
  ];
  sort = this.sortOptions[1];
  sessions: any[] = [];

  constructor(
    private sessionService: SessionService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.load();
  }

  // Load all sessions that pertain to the selected type
  load(): void {
    let observable: Observable<any>;

    // If we are just querying sessions for an individual group, query by the group or query for all
    if (this.groupId) {
      observable = this.sessionService.queryGroup(this.groupId, {
        limit: this.limit,
        search: this.searchText,
        sort: this.sort.value,
      });
    } else if (this.licensingPartnerId) {
      observable = this.sessionService.queryLicensingPartner(
        this.licensingPartnerId,
        {
          limit: this.limit,
          search: this.searchText,
          sort: this.sort.value,
        }
      );
    } else {
      observable = this.sessionService.queryIndex({
        limit: this.limit,
        search: this.searchText,
        sort: this.sort.value,
      });
    }

    observable
      .pipe(
        tap((response) => {
          this.sessions = response;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * newSession()
   *
   * function
   *
   * Opens a modal allowing the user to create a new session
   */
  newSession(): void {
    const modalRef = this.modalService.show(NexleaderNewSessionModalComponent);
    modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.load();
    });
  }

  /**
   * editSession()
   *
   * function
   *
   * Opens a modal allowing the user to edit an individual session
   */
  editSession(session: any): void {
    const modalRef = this.modalService.show(
      NexleaderEditSessionModalComponent,
      {
        initialState: { session },
      }
    );
    modalRef.onHidden?.pipe(take(1)).subscribe(() => {
      this.load();
    });
  }

  /**
   * showHelp()
   *
   * function
   *
   * Opens a modal providing help instructions for the user
   */
  showHelp(): void {
    this.modalService.show(NexleaderSessionTableHelpModalComponent);
  }

  /**
   * viewSessionInCalendar()
   *
   * function
   *
   * Redirects a user to the calendar with the selected session
   *
   * @param session - a session to use as the redirect
   */
  viewSessionInCalendar(session: any): void {
    const start = new Date(session.start);
    this.router.navigate(['/sessions'], {
      queryParams: {
        view: 'month',
        day: moment(start).format('YYYY-MM-DD')
      }
    });
  }
}
