/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewPviAssessmentResult
 *
 * view
 *
 * Renders a child component that shows information about the PVI assessment. This view wraps the assessment result
 *  container.
 */
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NexleaderComponentPviAssessmentResultComponent } from '../../components/pvi-assessment-result/pvi-assessment-result.component';

@Component({
  selector: 'app-nexleader-view-pvi-assessment-result',
  standalone: true,
  imports: [CommonModule, NexleaderComponentPviAssessmentResultComponent],
  templateUrl: './pvi-assessment-result.component.html', // Update the path
})
export class NexleaderViewPviAssessmentResultComponent implements OnInit {
  routeParams: any;
  userId: string | undefined;
  pviResultId: string | undefined;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.userId = this.routeParams.user_id;
    this.pviResultId = this.routeParams.pvi_result_id;

    if (!this.userId) {
      console.error(
        'user_id required to render PVIResult; needed in route params'
      );
    }
    if (!this.pviResultId) {
      console.error(
        'pvi_result_id required to render PVIResult; needed in route params'
      );
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderViewPviAssessmentResultComponent',
//   downgradeComponent({
//     component: NexleaderViewPviAssessmentResultComponent,
//   }) as angular.IDirectiveFactory
// );
