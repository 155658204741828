import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  transform(input: string): string {
    return input
      ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase()
      : '';
  }
}
