import { Injectable, HostListener } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    constructor() {
        ['resize', 'click'].forEach(event => {
            window.addEventListener(event, () => {
                this.setBodySmall();
            })
        });
    }

    checkWindowSize(): boolean {
        return window.innerWidth < 769;
    }

    setBodySmall(): void {
        if (window.innerWidth < 769) {
            document.body.classList.add('page-small');
        } else {
            document.body.classList.remove('page-small');
            document.body.classList.remove('show-sidebar');
        }
    }
}
