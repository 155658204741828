/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationsService } from '../../../../services/integrations.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-integrations',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './integrations.component.html',
})
export class NexleaderIntegrationsComponent implements OnInit {
  integrations: {
    mailchimp: {
      enabled: boolean;
      listArray: any;
      stats: any;
    };
  } = {
      mailchimp: {
        enabled: false,
        listArray: [],
        stats: [],
      },
    };

  constructor(
    private integrationsService: IntegrationsService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.integrationsService
      .get()
      .pipe(
        tap((data) => {
          this.integrations = data;
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderIntegrationsComponent',
//   downgradeComponent({
//     component: NexleaderIntegrationsComponent,
//   }) as angular.IDirectiveFactory
// );
