/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */ /*global angular*/
/**
 * CreateTeamReport allows group admins to be able to create a "team report" for their group and visualize strengths and weaknesses for their team.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderIfNoStripeComponent } from '../../../store/components/if-no-stripe/if-no-stripe.component';
import { NexleaderNoClickDirective } from '../../../../directives/no-click.directive';
import { NexleaderCurrencyComponent } from '../../../store/components/currency/currency.component';
import { AuthService } from '../../../../services/auth.service';
import { EnumsService } from '../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { GroupService } from '../../../../services/group.service';
import { StripePaymentService } from '../../../../services/stripe-payment.service';
import { SuccessService } from '../../../../services/success.service';
import { UserService } from '../../../../services/user.service';
import { TeamReportDiscountService } from '../../resources/team-report-discount.service';
import { TeamReportService } from '../team-reports/team-report.service';
import {
  EMPTY,
  catchError,
  combineLatest,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '../../../../pipes/filter.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderStripeElementModalComponent } from '../../../store/components/stripe-element-modal/stripe-element-modal.component';

@Component({
  selector: 'app-nexleader-create-team-report',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NexleaderIfNoStripeComponent,
    NexleaderNoClickDirective,
    NexleaderCurrencyComponent,
    FormsModule,
    TooltipModule,
    FilterPipe,
  ],
  templateUrl: './create-team-report.component.html',
})
export class NexleaderCreateTeamReportComponent implements OnInit {
  routeParams: any;

  /**
   * transactionIsInProgress
   *
   * this flag is set to true if the user has clicked submit and we have not
   *  yet received a response from the server; we don't want them to be able to
   *  click submit again
   *
   * @type {boolean}
   */
  transactionIsInProgress = false;

  /**
   * group_id
   *
   * this is a group _id string that is used in the CreateTeamReport view to redirect a user to the group's team reports
   *
   * @type {string}
   */
  group_id: string = '';

  /**
   * currentUser
   *
   * The current user who is signed in and purchasing this team report
   *
   * @type {string}
   */
  currentUser: any;
  group: any;
  selectableUsers: any[] = [];
  teamLeaders: any[] = [];
  defaultSkills: any[] = [];

  // The potential skill levels that can be chosen when the user picks skills for their team report
  skillLevels: any[] = [
    {
      name: '1 - Non Existent',
      value: 1,
    },
    {
      name: '2',
      value: 2,
    },
    {
      name: '3 - Below Average',
      value: 3,
    },
    {
      name: '4',
      value: 4,
    },
    {
      name: '5 - Average',
      value: 5,
    },
    {
      name: '6',
      value: 6,
    },
    {
      name: '7 - Functionally Competent',
      value: 7,
    },
    {
      name: '8',
      value: 8,
    },
    {
      name: '9',
      value: 9,
    },
    {
      name: '10 - World Class',
      value: 10,
    },
  ];
  teamName: string = '';
  selectedTeamLeader: any;
  candidateTeamReportDiscountName: string = '';
  teamReportDiscountName: any;
  price: any;
  userSearchText: any;

  // The CreateTeamReport is broken into four steps as shown below
  // This object holds our state for the different steps that we need to render
  // Each step has a function getError(), which returns the errors in the form that must
  //  be fixed before the page can advance. The UI will show a warning and disable the
  //  next button if getError() doesn't return a falsey value.
  createTeamReportSteps = [
    {
      index: 0,
      name: 'Team Report Information',
      identifier: 'info',
      getError: () => {
        if (!this.teamName) return 'A team name is required to continue.';
        if (this.teamName === '') return 'A team name is required to continue.';
        if (!this.selectedTeamLeader)
          return 'A team leader is required to continue.';
        return false;
      },
    },
    {
      index: 1,
      name: 'Select Group Members',
      identifier: 'members',
      getError: () => {
        if (!Array.isArray(this.selectedUsers()))
          return 'At least one user must be selected to continue.';
        if (this.selectedUsers().length < 1)
          return 'At least one user must be selected to continue.';
        return false;
      },
    },
    {
      index: 2,
      name: 'Select up to 10 Mission-Critical Skills for Your Team',
      identifier: 'skills',
      getError: () => {
        if (!Array.isArray(this.selectedSkills()))
          return 'At least one target skill must be selected to continue.';
        if (this.selectedSkills().length < 1)
          return 'At least one target skill must be selected to continue.';
        return false;
      },
    },
    {
      index: 3,
      name: 'Select the Level of Mastery for Your Team',
      identifier: 'selected_skills',
      getError: () => {
        if (!Array.isArray(this.selectedSkills()))
          return 'At least one target skill must be selected to continue.';
        if (this.selectedSkills().length < 1)
          return 'At least one target skill must be selected to continue.';
        let skillError: string | undefined;
        this.selectedSkills().forEach((selectedSkill) => {
          if (typeof selectedSkill.goalLevel !== 'number') {
            skillError =
              'All skills must have a target goal level selected to continue.';
          }
        });
        return skillError || false;
      },
    },
    {
      index: 4,
      name: 'Review & Checkout',
      identifier: 'checkout',
      getError: () => false,
    },
  ];
  currentStep: any;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private groupService: GroupService,
    private enumsService: EnumsService,
    private teamReportService: TeamReportService,
    private teamReportDiscountService: TeamReportDiscountService,
    private stripePaymentService: StripePaymentService,
    private successService: SuccessService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    this.currentUser = this.authService.getTokenPayload();
    this.group_id = this.routeParams['group_id'];

    // Select the first step on the load of the component
    this.currentStep = this.createTeamReportSteps[0];

    this.load();
  }

  load() {
    combineLatest([
      this.groupService.get(this.group_id),
      this.userService.queryGroupEligibleForTeamReport(this.group_id),
      this.userService.queryGroupCanLeadTeamReportIndex(this.group_id),
      this.enumsService.getEnums(),
    ])
      .pipe(
        tap(([group, selectableUsers, teamLeaders, enums]) => {
          this.group = group;
          this.selectableUsers = selectableUsers;
          // We need users that either are group admins or have the isTeamLeader property
          this.teamLeaders = teamLeaders;
          // We need to be able to view all the default skills when rendering
          this.defaultSkills = enums.AllSkills;

          // If there is only a single group admin, make sure to select this group admin by default as the team leader
          if (this.teamLeaders.length === 1) {
            this.selectedTeamLeader = this.teamLeaders[0];
          }
          // Quick way to join together the first name and last name so we don't have to do it in the template
          this.teamLeaders.forEach(function (teamLeader) {
            teamLeader.name = teamLeader.firstName + ' ' + teamLeader.lastName;
          });
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * validateCandidateTeamReportDiscountCode()
   *
   * validates the coupon code using a backend endpoint then assigns the name
   *  value to teamReportDiscountName (from candidateTeamReportDiscountName)
   *
   * @returns {*}
   */
  validateCandidateTeamReportDiscountCode(): void {
    if (!this.candidateTeamReportDiscountName) return;
    this.teamReportDiscountService
      .getByName(this.candidateTeamReportDiscountName.toUpperCase()) //convert to uppercase since that's how the db stores it
      .pipe(
        tap((teamReportDiscount) => {
          if (teamReportDiscount?.name) {
            this.teamReportDiscountName = teamReportDiscount.name;
            this.successService.handle({
              message: 'Successfully applied discount code.',
            });
            this.loadPrice().subscribe();
          } else {
            this.errorHandlerService.handleError({
              message: 'Sorry. That is not a valid discount code',
              status: 422,
            });
          }
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * clearTeamReportDiscountName()
   *
   * sets $ctrl.teamReportDiscountName to null when a user clicks "undo"
   */
  clearTeamReportDiscountName(): void {
    this.teamReportDiscountName = null;
    this.loadPrice().subscribe();
  }

  /**
   * loadPrice()
   *
   * loads the price for this team report from the backend
   *  this should be called anytime price-related information changes
   *  it's called when discount code changes
   *  it's called when the page changes (and they're an array of users to check)
   */
  loadPrice() {
    return this.teamReportService
      .calculatePrice({
        userCount: this.selectedUsers().length,
        teamReportDiscountName: this.teamReportDiscountName,
        group: this.group_id,
      })
      .pipe(
        tap((data) => {
          this.price = data.price;
        })
      );
  }

  // Function to move to the next step
  next(): void {
    this.currentStep = this.createTeamReportSteps[this.currentStep.index + 1];
    if (this.selectedUsers().length > 0) {
      this.loadPrice().subscribe();
    }
  }

  // Function to move to the previous step
  previous(): void {
    this.currentStep = this.createTeamReportSteps[this.currentStep.index - 1];
  }

  //Returns the relevant error if it exists
  // this is used to disable next and to show error messages
  // it callas getError() on $ctrl.currentStep
  currentError(): any {
    return this.currentStep.getError();
  }

  // Select/unselect skills
  selectSkill(skill: any): void {
    // Check if there are already the maximum number of skills
    if (this.selectedSkills().length >= 10) {
      // If there are, the user can only deselect skills
      if (skill.selected) {
        skill.selected = false;
      }
    } else {
      // If there are not, allow the user to select the skills
      skill.selected = !skill.selected;
    }
  }

  // Select/unselect users
  selectUser(user: any): void {
    // The user must be eligible to be on the team report for he/she to be selected
    if (user.eligibility.isEligibleForTeamReport) {
      if (user.selected) {
        user.isAllowedToViewTeamReport = false;
      } else {
        user.isAllowedToViewTeamReport = true;
      }
      user.selected = !user.selected;
    }
  }

  // Fetch all users that have been selected
  selectedUsers(): any[] {
    if (!this.selectableUsers) return [];
    return this.selectableUsers.filter(function (u) {
      return u.selected;
    });
  }

  // Fetch all skills that have been selected
  selectedSkills(): any[] {
    if (!this.defaultSkills) return [];
    return this.defaultSkills.filter(function (s) {
      return s.selected;
    });
  }

  // Parses the data passed in through the wizard and creates a new team report model
  createTeamReport(): void {
    // Disable the submit button so we don't get
    // another submit while processing
    this.transactionIsInProgress = true;

    // Ensure we have the latest price data from the API

    this.loadPrice().subscribe(() => {
      const modalRef = this.modalService.show(
        NexleaderStripeElementModalComponent,
        {
          initialState: {
            title: 'IPSAT Team Report',
            description: `nexleader IPSAT TeamReport for ${this.selectedUsers().length
              } users`,
            currencyCode: this.price.currencyCode?.toLowerCase(),
            amount: Math.trunc(this.price.currencyQuantity * 100),
          },
        }
      );

      // If there was an error, return with the error appropriately
      modalRef.onHide?.pipe(take(1)).subscribe((_) => {
        this.transactionIsInProgress = false;
      });

      modalRef.content?.tokenGenerated
        .pipe(take(1))
        .pipe(
          tap(() => modalRef.hide()),
          switchMap((token) => {
            // If the price is greater than 0, there must be a token in order to successfully make a payment
            if (this.price.currencyQuantity > 0 && !token) {
              // Reset since the transition is not in process
              this.transactionIsInProgress = false;
              return EMPTY;
            }

            const teamReportData = {
              // Stripe information
              stripe: {
                // The token generated from the StripeCheckout form
                token: token,
                // The price calculated based off of the number of users being used in the team report
                price: this.price,
              },
              // The constructor object that declares information to be added to the team report model
              teamReportConstructor: {
                // The group that the team report will be created for
                group: this.routeParams['group_id'],
                // A name for the team that will be created
                teamName: this.teamName,
                // Users to generate the report for
                teamUsers: this.selectedUsers().map((teamUser) => ({
                  _id: teamUser._id,
                  isAllowedToViewTeamReport: teamUser.isAllowedToViewTeamReport,
                })),
                // A numerical value for the number of users
                userCount: this.selectedUsers().length,
                // Skills that the group admin will use to base off target levels
                teamTargetSkills: this.selectedSkills(),
                // The group admin user that is selected during team creation
                // Ensure that this is just the ID of the user
                teamLeader: this.selectedTeamLeader._id,
                //True if the validation method has been called and has set the flag
                // undefined if not; undefined is fine; empty stirng is not
                teamReportDiscountName: this.teamReportDiscountName,
                // The current user who is logged in and purchasing the team report
                // This field is necessary in sending a team report purchased email correctly
                userPurchasingTeamReport:
                  this.currentUser.user || this.currentUser.primary_user,
              },
            };
            // Save the teamReportData
            return this.teamReportService.purchase(teamReportData).pipe(
              catchError((err) => {
                this.errorHandlerService.handleError(err);
                this.transactionIsInProgress = false;
                return of(null);
              })
            );
          })
        )
        .subscribe((teamReport) => {
          //teamReport is an object with only the _id field of the actual teamReport
          this.successService.handle({
            message: 'Successfully purchased and generated team report.',
          });
          this.transactionIsInProgress = false;
          this.router.navigate([
            'groups',
            this.group._id,
            'teamReports',
            teamReport._id,
            'confirm',
          ]);
        });
    });
  }

  trackById(index: number, item: any) {
    return item._id;
  }

  trackByValue(index: number, item: any) {
    return item.value;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderCreateTeamReportComponent',
//   downgradeComponent({
//     component: NexleaderCreateTeamReportComponent,
//   }) as angular.IDirectiveFactory
// );
