<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="container p-v-50">
  <!-- Title and copy at the top of the assessment -->
  <div class="text-center m-b-lg">
    <h1>nexleader Skills Inventory</h1>
    <p>
      For each of the skills included in this inventory, rate yourself by
      clicking on the circle that best represents your capability now.
      <br />
      Don’t over think or over analyze this process. Just mark the number from 1
      – 10 for each skill based on where you are now.
    </p>
  </div>

  <!-- The main assessment form -->
  <ng-container *ngIf="skillsInventory">
    <form name="skillsInventoryForm" novalidate>
      <div>
        <!-- Progress Bar -->
        <div class="m-b-md">
          <p class="m-b-sm font-bold">Your Progress</p>
          <div class="full progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-success"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
              [ngStyle]="{
                width:
                  ((selectedQuestion.questionIndex + 1) /
                    skillsInventory.questions.length) *
                    100 +
                  '%'
              }"
            >
              <span class="sr-only">70% Complete</span>
            </div>
          </div>
        </div>

        <!-- Question Panel -->
        <div class="hpanel hgreen">
          <!-- If it's a one-through-ten question, render it using the component-->
          <app-nexleader-skills-inventory-question
            *ngIf="
              selectedQuestion &&
              selectedQuestion.questionType.identifier === 'one_through_ten'
            "
            [question]="selectedQuestion"
          ></app-nexleader-skills-inventory-question>

          <!-- If it's the future-role question, give it a custom screen -->
          <div
            class="panel-body"
            *ngIf="selectedQuestion.questionType.identifier === 'future_role'"
          >
            <div class="m-b-sm">
              <div class="pull-right">
                <p class="text-success font-bold">
                  {{ selectedQuestion.questionIndex }}
                </p>
              </div>
              <p class="font-bold">Future Role</p>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12 m-b-md">
                <p>
                  Think about the role in which you aspire to serve in the
                  future. Write your future ideal role in the text box. What
                  skills will you need to be effective for the role you have
                  identified. In what skills will you need to distinguish
                  yourself from a person who does a good job in this role from
                  someone who does a great job?
                </p>
              </div>
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="control-label">Future Ideal Role</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="future_role"
                    [(ngModel)]="skillsInventory.textResponses.futureIdealRole"
                  />
                </div>
                <div class="form-group">
                  <label class="control-label">Skills Needed</label>
                  <textarea
                    required
                    rows="3"
                    class="form-control"
                    name="skills_needed"
                    [(ngModel)]="skillsInventory.textResponses.skillsNeeded"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <!-- If it's the underdeveloped skills question, give it a custom screen -->
          <div
            class="panel-body"
            *ngIf="
              selectedQuestion.questionType.identifier ===
              'underdeveloped_skills'
            "
          >
            <div class="m-b-sm">
              <div class="pull-right">
                <p class="text-success font-bold">
                  {{ selectedQuestion.questionIndex }}
                </p>
              </div>
              <p class="font-bold">Underdeveloped Skills</p>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12 m-b-md">
                <p>
                  Which of the skills that you think you will need for your
                  future ideal role are currently under-developed? How does this
                  exercise/inform your personal learning agenda going forward?
                </p>
                <hr />
                <p class="font-bold m-b-sm">
                  Potentially Underdeveloped Skills
                </p>
                <ul>
                  <li *ngFor="let skill of suggestedWeakSkills">
                    {{ skill.name }} ({{ skill.value }})
                  </li>
                </ul>
              </div>
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="control-label">Under-Developed Skills</label>
                  <textarea
                    required
                    rows="3"
                    class="form-control"
                    name="under_developed_skills"
                    [(ngModel)]="
                      skillsInventory.textResponses.underdevelopedSkills
                    "
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="control-label">Explanation</label>
                  <textarea
                    required
                    rows="3"
                    class="form-control"
                    name="explanation"
                    [(ngModel)]="
                      skillsInventory.textResponses
                        .underdevelopedSkillsExplanation
                    "
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer and next/previous buttons -->
          <div class="panel-footer text-center">
            <div class="pull-left">
              <button
                class="btn btn-default"
                [attr.disabled]="
                  selectedQuestion.questionIndex <= 0 ? true : null
                "
                (click)="previousQuestion()"
              >
                Previous
              </button>
            </div>
            <span style="top: 8px; position: relative" *ngIf="invalid()">{{
              invalid()
            }}</span>
            <div class="pull-right">
              <button
                class="btn btn-default"
                [attr.disabled]="
                  selectedQuestion.questionIndex + 1 >=
                    skillsInventory.questions.length ||
                  invalid(selectedQuestion.questionIndex)
                    ? true
                    : null
                "
                (click)="nextQuestion()"
              >
                Next
              </button>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>

      <!-- Bottom submit button -->
      <div class="text-center">
        <button
          class="btn btn-success btn-lg"
          type="submit"
          (click)="submit()"
          [attr.disabled]="invalid() ? true : null"
        >
          Submit
        </button>
      </div>
    </form>
  </ng-container>
</div>
