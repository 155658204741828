import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamReportService } from '../team-reports/team-report.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { NexleaderCurrencyComponent } from '../../../store/components/currency/currency.component';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';

@Component({
  selector: 'app-nexleader-team-report-creation-confirmation',
  standalone: true,
  imports: [CommonModule, RouterLink, NexleaderCurrencyComponent],
  templateUrl: './team-report-creation-confirmation.component.html',
})
export class NexleaderTeamReportCreationConfirmationComponent
  implements OnInit {
  routeParams: any;
  teamReport: any;

  constructor(
    private teamReportService: TeamReportService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadTeamReport();
  }

  loadTeamReport(): void {
    this.teamReportService
      .get(this.routeParams['team_report_id'], this.routeParams['group_id'])
      .pipe(
        tap((teamReport) => {
          this.teamReport = teamReport;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderTeamReportCreationConfirmationComponent',
//   downgradeComponent({
//     component: NexleaderTeamReportCreationConfirmationComponent,
//   }) as angular.IDirectiveFactory
// );
