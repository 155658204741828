import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  getTasks(): Observable<any> {
    return this.http.get<any>(`${API_BASE}tasks`, {
      withCredentials: true
    });
  }

  runTask(task: any): Observable<any> {
    return this.http.post(`${API_BASE}tasks/${task._id}/run`, task, {
      withCredentials: true,
      responseType: 'text'
    });
  }
}
