<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div>
  <div class="flex flex-vertical-center m-b-md">
    <h4 class="text-primary-2 font-semi-bold">Selected Values</h4>
    <div class="flex-grow"></div>
    <h5 class="text-secondary font-semi-bold">
      {{ selectedValues.length }}/12
    </h5>
  </div>
  <div
    *ngFor="let value of selectedValues; let index = index"
    class="hpanel m-b-md"
    [class.hgreen]="value.name === selectedValue?.name"
    [class.cursor-pointer]="selectValueExists()"
    (click)="selectValue(value)"
  >
    <div class="panel-body">
      <div class="pull-right">
        <i
          class="fa fa-minus text-muted cursor-pointer"
          *ngIf="!shouldNotShowRemove"
          id="nexleader-test-pvi-deselect-{{ index }}"
          (click)="removeValue(value)"
        ></i>
      </div>
      <h5 class="text-primary-2 font-semi-bold">{{ value.name }}</h5>
      <ng-container *ngFor="let domain of value.personalValueDomains">
        <small
          class="text-secondary font-bold m-r-sm"
          *ngIf="!shouldNotShowDomain"
          >{{ domain.name }}
        </small>
      </ng-container>
    </div>
  </div>
  <div *ngIf="selectedValues.length < 1">
    No values have been selected yet. Select values on the right.
  </div>
</div>
