<div class="row">
  <div class="col-md-12 m-b-md">
    <p>
      Licensing partners own the rights to the IPSAT with their region and can
      sell their own licenses.
    </p>
  </div>
  <div class="form-group col-md-6">
    <label class="control-label">Name</label>
    <input
      type="text"
      placeholder="Nexleader, LLC."
      class="form-control"
      [(ngModel)]="model.name"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label">Region</label>
    <input
      type="text"
      placeholder="North America"
      class="form-control"
      [(ngModel)]="model.region"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label">ISO 4217 Currency Code for Presentment</label>
    <input
      type="text"
      placeholder="North America"
      class="form-control"
      [(ngModel)]="model.currencyCode"
    />
  </div>
</div>
<div class="row">
  <div class="form-group col-md-6">
    <label class="control-label"
      >Organizational Faith-based IPSAT Price<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.ipsatLicense.organizationalFaithBased"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label"
      >Organizational Values-based IPSAT Price<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.ipsatLicense.organizationalValuesBased"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label"
      >Independent Contractor Faith-based IPSAT Price<span
        *ngIf="model.currencyCode"
      >
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.ipsatLicense.independentContractorFaithBased"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label"
      >Independent Contractor Values-based IPSAT Price<span
        *ngIf="model.currencyCode"
      >
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.ipsatLicense.independentContractorValuesBased"
    />
  </div>
</div>
<div class="row">
  <div class="form-group col-md-6">
    <label class="control-label"
      >Faith-based Team Report Price Per User<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.teamReport.faithBased.perUser"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label"
      >Faith-based Team Report Price Minimum<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.teamReport.faithBased.min"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label"
      >Faith-based Team Report Price Maximum<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.teamReport.faithBased.max"
    />
  </div>
</div>
<div class="row">
  <div class="form-group col-md-6">
    <label class="control-label"
      >Values-based Team Report Price Per User<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.teamReport.valuesBased.perUser"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label"
      >Values-based Team Report Price Minimum<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.teamReport.valuesBased.min"
    />
  </div>
  <div class="form-group col-md-6">
    <label class="control-label"
      >Values-based Team Report Price Maximum<span *ngIf="model.currencyCode">
        in ({{ model.currencyCode }})</span
      ></label
    >
    <input
      type="number"
      placeholder="25"
      class="form-control"
      [(ngModel)]="model.pricing.teamReport.valuesBased.max"
    />
  </div>
</div>
<div>
  <div class="row">
    <div class="form-group col-md-6">
      <label class="control-label"
        >Faith-based Direct Purchase IPSAT with Coaching Session Price Per
        User<span *ngIf="model.currencyCode">
          in ({{ model.currencyCode }})</span
        ></label
      >
      <input
        type="number"
        placeholder="30"
        class="form-control"
        [(ngModel)]="
          model.pricing.ipsatDirectPurchase.faithBasedPlusCoachingSession
        "
      />
    </div>
    <div class="form-group col-md-6">
      <label class="control-label"
        >Values-based Direct Purchase IPSAT with Coaching Session Price Per
        User<span *ngIf="model.currencyCode">
          in ({{ model.currencyCode }})</span
        ></label
      >
      <input
        type="number"
        placeholder="20"
        class="form-control"
        [(ngModel)]="
          model.pricing.ipsatDirectPurchase.valuesBasedPlusCoachingSession
        "
      />
    </div>
    <div class="form-group col-md-6">
      <label class="control-label"
        >Clifton Strengths Assessment Price Per User<span
          *ngIf="model.currencyCode"
        >
          in ({{ model.currencyCode }})</span
        ></label
      >
      <input
        type="number"
        placeholder="20"
        class="form-control"
        [(ngModel)]="
          model.pricing.ipsatDirectPurchase.cliftonStrengthsAssessment
        "
      />
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label class="control-label"
        >IPSAT Ecourse Bundle<span *ngIf="model.currencyCode">
          in ({{ model.currencyCode }})</span
        ></label
      >
      <input
        type="number"
        placeholder="30"
        class="form-control"
        [(ngModel)]="model.pricing.ipsatEcourseDirectPurchase.valuesBasedBundle"
      />
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label class="control-label">Clifton Strengths Assessment Code</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="model.cliftonStrengthsCode"
      />
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label class="control-label"
        >ECourse Content Upgrade for Non-Ecourse User<span
          *ngIf="model.currencyCode"
        >
          in ({{ model.currencyCode }})</span
        ></label
      >
      <input
        type="number"
        placeholder="279"
        class="form-control"
        [(ngModel)]="model.pricing.ecourseContentUpgrade"
      />
    </div>
  </div>
</div>
