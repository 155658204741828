/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
import { tap, catchError, of } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlobService } from '../../../../services/blob.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { NexleaderFilePickerComponent } from '../../../core/components/file-picker/file-picker.component';

@Component({
  selector: 'app-nexleader-course-content-file-upload',
  standalone: true,
  imports: [CommonModule, NexleaderFilePickerComponent],
  templateUrl: './course-content-file-upload.component.html',
})
export class NexleaderCourseContentFileUploadComponent implements OnInit {
  @Input() courseContentId: string = '';
  @Output() onUploadFile: EventEmitter<any> = new EventEmitter<any>();

  file: any = {};
  data: any;
  uploading: boolean = false;

  constructor(
    private blobService: BlobService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    if (!this.courseContentId) {
      throw new Error(
        'nexleaderComponentCourseContentFileUpload requires courseContentId'
      );
    }
  }

  onUploadComplete(): void {
    this.successService.handle({
      message: 'Finished Uploading ' + this.file.name,
    });
    this.onUploadFile.emit({ file: this.file });
  }

  onUploadFail(e: any): void {
    this.errorHandlerService.handleError({
      message: 'Failed to Upload ' + this.file.name,
      detail: e,
    });
  }

  onSelect(file: any): void {
    this.file = file;
  }

  onLoad(data: any): void {
    this.data = data;
  }

  upload(): void {
    this.uploading = true;

    this.blobService
      .uploadBase64Url(this.data, {
        filename: this.file.name,
        imageType: 'course-content-photo',
        courseContentId: this.courseContentId,
      })
      .pipe(
        tap(() => {
          this.onUploadComplete();
          this.uploading = false;
          this.file = {};
          this.data = null;
        }),
        catchError((error) => {
          this.uploading = false;
          this.file = {};
          this.data = null;
          this.onUploadFail(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
