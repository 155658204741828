<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="hpanel" [ngClass]="class">
  <div class="panel-body">
    <div class="text-center" style="padding-top: 16px; padding-bottom: 16px">
      <p *ngIf="score < -100">
        Not enough data to calculate a Net Promoter Score.
      </p>
      <div *ngIf="score >= -100">
        <h3>Net Promoter Score</h3>
        <p class="text-big">{{ score }}</p>
      </div>
    </div>
  </div>
</div>
