<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="hpanel">
      <div class="panel-heading">Edit Application Emails</div>
      <div class="panel-body">
        <app-nexleader-edit-emails
          [emails]="emails"
          [emailScope]="'application'"
        ></app-nexleader-edit-emails>
      </div>
    </div>
  </div>
</div>
