/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-edit-ranges',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-ranges.component.html',
})
export class NexleaderEditRangesComponent {
  @Input() ranges: any[] = [];

  addRange(ranges: any[]) {
    ranges.push({});
  }

  removeRange(ranges: any[], range: any) {
    const index = ranges.indexOf(range);
    if (index !== -1) {
      ranges.splice(index, 1);
    }
  }
}
