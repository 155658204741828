import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class EvaluationResultService {
  constructor(private httpClient: HttpClient) { }

  getEvaluationResult(evaluationResultId: string): Observable<any> {
    return this.httpClient.get(
      `${API_BASE}evaluationResults/${evaluationResultId}`,
      {
        withCredentials: true,
      }
    );
  }

  save(evaluation: any): Observable<any> {
    return this.httpClient.post(
      `${API_BASE}evaluationResults`,
      evaluation,
      {
        withCredentials: true,
      }
    );
  }

  queryGroup(groupId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${API_BASE}groups/${groupId}/evaluationResults`,
      {
        withCredentials: true,
      }
    );
  }

  coachQuery(userId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${API_BASE}users/${userId}/evaluationResults`,
      {
        withCredentials: true,
      }
    );
  }

  queryCoachUser(coachUserId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${API_BASE}coachUsers/${coachUserId}/evaluationResults`,
      {
        withCredentials: true,
      }
    );
  }
}
