<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<form name="prereqs" novalidate *ngIf="user">
  <div class="row">
    <div class="col-md-12">
      <div class="hpanel hgreen">
        <div class="panel-heading">Myers Briggs Type Indicator &reg;</div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <img src="/assets/images/icons/personality.svg" alt="personality" height="75px;" class="m-b-md" />
              <p class="m-b-md">
                The first component of your identity profile is your
                personality. One of the most widely utilized assessments for
                understanding your personality is the Myers Briggs Type
                Indicator&reg; (MBTI&reg;).
                <br /><br />
                If you have already taken the MBTI&reg; and have the results,
                you do not need to retake it.
              </p>
            </div>
            <div class="col-md-6">
              <h5 class="m-b-sm">Your Personality</h5>
              <div class="form-group">
                <select class="form-control" [(ngModel)]="user.prerequisites.mbti" name="mbti" required
                  (ngModelChange)="saveUser()" [compareWith]="compareName">
                  <option value="undefined">-- Select a Myers Briggs --</option>
                  <option *ngFor="let option of mbtis; trackBy: trackByIndex" [ngValue]="option">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <hr />
              <app-nexleader-profile-wizard-upload-file *ngIf="user && files && prerequisites" [user]="user"
                [prerequisite]="prerequisites.MBTI" [allFiles]="files" (load)="load()">
              </app-nexleader-profile-wizard-upload-file>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="hpanel hreddeep">
        <div class="panel-heading">StrengthsFinder</div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <img src="/assets/images/icons/strengths.svg" alt="personality" height="75px;" class="m-b-md" />
              <p class="m-b-md">
                The second component of your identity profile is your strengths.
                The gold standard for understanding your strengths is
                StrengthsFinder 2.0, developed by Donald Clifton, for Gallup.
                <br /><br />
                If you have already taken StrengthsFinder and have the results,
                you do not need to retake it.
              </p>
            </div>
            <div class="col-md-6">
              <h5 class="m-b-sm">Your Strengths</h5>
              <div class="form-group" *ngFor="let i of [0, 1, 2, 3, 4]">
                <b *ngIf="!showEdit">{{
                  user.prerequisites.strengths[i].name
                  }}</b>

                <select class="form-control" required placeholder="Strength" *ngIf="showEdit"
                  [(ngModel)]="user.prerequisites.strengths[i]" name="strength{{ i }}" [compareWith]="compareName"
                  (ngModelChange)="saveUser()">
                  <option *ngFor="let strength of allStrengths; trackBy: trackByIndex" [ngValue]="strength">
                    {{ strength.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <hr />
              <app-nexleader-profile-wizard-upload-file *ngIf="user && files && prerequisites" [user]="user"
                [prerequisite]="prerequisites.STRENGTHS" [allFiles]="files" (load)="load()">
              </app-nexleader-profile-wizard-upload-file>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="hpanel hdarkblue">
        <div class="panel-heading">Skills Inventory</div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <img src="/assets/images/icons/skills.svg" alt="personality" height="75px;" class="m-b-md" />
              <p class="m-b-md" *ngIf="surveyTypeIdentifier === 'faith_based'">
                The third component of your identity profile is your skills.
                Unlike personality, strengths, or spiritual gifts, you don’t
                need an assessment to tell you if you have a given skill, such
                as public speaking, or working with finances. But you can
                benefit from reflecting on which skills you have developed,
                where you have gaps, and where you need to develop new skills.
              </p>
              <p class="m-b-md" *ngIf="surveyTypeIdentifier === 'values_based'">
                The third component of your identity profile is your skills.
                Unlike personality or strengths, you don’t need an assessment to
                tell you if you have a given skill, such as public speaking, or
                working with finances. But you can benefit from reflecting on
                which skills you have developed, where you have gaps, and where
                you need to develop new skills.
              </p>
            </div>
            <div class="col-md-6">
              <h5 class="m-b-sm">Your Skills</h5>
              <div class="form-group" *ngFor="let i of [0, 1, 2]">
                <input type="text" class="form-control" [(ngModel)]="user.prerequisites.skills[i].name"
                  name="skill{{ i }}" required placeholder="Skill" [ngModelDebounce]="1000"
                  (ngModelDebounceChange)="saveUser()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="hpanel hblue">
        <div class="panel-heading">
          <span *ngIf="shouldShowSpiritualityBasedCopy()">Spiritual Gifts Assessment</span>
          <span *ngIf="shouldShowValuesBasedCopy()">Personal Values Assessment</span>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <img src="/assets/images/icons/spiritualgifts.svg" alt="personality" height="75px;" class="m-b-md" />
              <p class="m-b-md" *ngIf="surveyTypeIdentifier === 'faith_based'">
                The forth component of your identity profile is your spiritual
                gifts. Unlike personality and strengths, there are many
                different assessments available for spiritual gifts. The
                different assessments reflect the diversity of beliefs about
                spiritual gifts and their use in the church today.
                <br /><br />
                If you have already taken a spiritual gifts assessment, and have
                your results, you do not need to retake it.
              </p>
              <p class="m-b-md" *ngIf="surveyTypeIdentifier === 'values_based'">
                The forth component of your identity profile is your personal
                values. To create a uniform IPSAT experience we have created a
                Personal Values Inventory (PVI). The PVI will guide you through
                a process of selecting 12 values by reflecting on four domains
                (self, others, vocation, avocation). You will then rank your
                values to identify a list of your top 7, and finally your top 3.
              </p>
            </div>
            <div class="col-md-6">
              <h5 class="m-b-sm">
                Your
                <span *ngIf="shouldShowSpiritualityBasedCopy()">Spiritual Gifts</span>
                <span *ngIf="shouldShowValuesBasedCopy()">Personal Values</span>
              </h5>
              <div *ngIf="shouldShowValuesBasedCopy()">
                <a class="btn btn-default" [routerLink]="['/takePVI']">Take the Personal Values Inventory</a>
                <div *ngIf="pviResults.length > 0">
                  <br />
                  <div class="pull-right">
                    <a [routerLink]="['/users', user._id, 'pvi', pviResultId]" target="_blank">View Your Personal Values
                      Inventory Result</a>
                  </div>
                  <p class="m-b-xs">
                    <b>Your Top Three Personal Values</b>
                  </p>
                  <ul>
                    <li *ngFor="let value of topThreeValues">
                      {{ value.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <ng-container *ngIf="shouldShowSpiritualityBasedCopy()">
                <div class="form-group" *ngFor="let i of [0, 1, 2]">
                  <select class="form-control" name="spiritualGift{{ i }}" required *ngIf="showEdit"
                    [(ngModel)]="user.prerequisites.spiritualGifts[i]" (ngModelChange)="saveUser()"
                    [compareWith]="compareName">
                    <option *ngFor="
                        let gift of allSpiritualGifts;
                        trackBy: trackByName
                      " [ngValue]="gift">
                      {{ gift.name }}
                    </option>
                  </select>
                </div>
              </ng-container>
            </div>
            <div class="col-md-12" *ngIf="shouldShowSpiritualityBasedCopy()">
              <hr />
              <app-nexleader-profile-wizard-upload-file *ngIf="user && files && prerequisites" [user]="user"
                [prerequisite]="prerequisites.SPIRITUAL_GIFTS" [allFiles]="files" (load)="load()">
              </app-nexleader-profile-wizard-upload-file>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="hpanel hyellow">
        <div class="panel-heading">MyPassionProfile.com</div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <img src="/assets/images/icons/passions.svg" alt="personality" height="75px;" class="m-b-md" />
              <p class="m-b-md" *ngIf="surveyTypeIdentifier === 'faith_based'">
                The fifth component of your identity profile is your passions.
                Unlike personality, strengths, or spiritual gifts, you don’t
                need an assessment to tell you if you are passionate about an
                issue or cause. But you can benefit from the opportunity to
                explore the level of passion you have for an issue and how to
                deepen your engagement.
              </p>
              <p class="m-b-md" *ngIf="surveyTypeIdentifier === 'values_based'">
                The fifth component of your identity profile is your passions.
                Unlike personality or strengths, you don’t need an assessment to
                tell you if you are passionate about an issue or cause. But you
                can benefit from the opportunity to explore the level of passion
                you have for an issue and how to deepen your engagement.
              </p>
            </div>
            <div class="col-md-6">
              <app-nexleader-input-passions *ngIf="user._id" [userId]="user._id"
                [passions]="user.prerequisites.passions" (onChange)="saveUser()">
              </app-nexleader-input-passions>
            </div>
            <div class="col-md-12">
              <hr />
              <app-nexleader-profile-wizard-upload-file *ngIf="user && files && prerequisites" [user]="user"
                [prerequisite]="prerequisites.PASSIONS" [allFiles]="files" (load)="load()">
              </app-nexleader-profile-wizard-upload-file>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>