<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="w-100">
  <div class="nexleader-skills-inventory-view-full-screen-inner">
    <div class="content animate-panel">
      <app-nexleader-component-pvi-assessment-result
        *ngIf="userId && pviResultId"
        [userId]="userId"
        [pviResultId]="pviResultId"
      ></app-nexleader-component-pvi-assessment-result>
    </div>
  </div>
</div>
