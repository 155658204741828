/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderViewPviAssessmentResultFullScreen
 *
 * view
 *
 * Renders a child component that shows information about the PVI assessment. This view wraps the assessment result
 *  container.
 */
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderComponentPviAssessmentResultComponent } from '../../components/pvi-assessment-result/pvi-assessment-result.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nexleader-pvi-assessment-result-full-screen',
  standalone: true,
  imports: [CommonModule, NexleaderComponentPviAssessmentResultComponent],
  templateUrl: './pvi-assessment-result-full-screen.component.html'
})
export class NexleaderPviAssessmentResultFullScreenComponent implements OnInit {
  routeParams: any;
  userId!: string;
  pviResultId!: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;

    if (!this.routeParams['user_id']) {
      console.error('user_id required to render PVI Assessment Result; needed in route parameters');
      return;
    }

    if (!this.routeParams['pvi_result_id']) {
      console.error('pvi_result_id required to render PVI Assessment Result; needed in route parameters');
      return;
    }

    this.userId = this.routeParams['user_id'];
    this.pviResultId = this.routeParams['pvi_result_id'];
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderPviAssessmentResultFullScreenComponent',
//   downgradeComponent({
//     component: NexleaderPviAssessmentResultFullScreenComponent,
//   }) as angular.IDirectiveFactory
// );

