<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Session List</h3>
</div>
<div class="modal-body">
  <h3>Searching</h3>
  <p>
    To search for a session, type whatever you can remember into the search box.
  </p>
  <h3>Sorting</h3>
  <p>
    To sort the table, use the dropdown in the upper right. + indicates an
    ascending sort. - indicates a descending sort.
  </p>
  <h3>Advanced Searching (Filtering)</h3>
  <p>
    Use quotes to search for multi-word phrases. For instance, if you would like
    to filter be the group Test Group, type "Test Group" (with quotes) into the
    search.
  </p>
</div>
<div class="modal-footer">
  <button class="btn btn-default" (click)="close()">Close</button>
</div>
