<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->
<div class="h-bg-blue">
  <div class="container p-t-sm p-b-sm">
    <div class="flex flex-vertical-center">
      <img src="/assets/images/icons/pvi_inverted.svg" alt="PVI logo" height="24px" class="m-r-sm" />
      <h4 class="font-semi-bold text-white" id="nexleader-test-pvi-header">
        Personal Values Inventory
      </h4>
      <div class="flex-grow"></div>
      <a class="text-white font-semi-bold text-right" (click)="headHome()" id="nexleader-test-pvi-head-home">
        Head Home
      </a>
    </div>
  </div>
</div>
<div class="bg-white border-bottom" *ngIf="!isSelectedStep(1)">
  <div class="container p-t-sm p-b-sm">
    <div class="flex flex-vertical-center flex-wrap">
      <button class="btn btn-default pull-left" *ngIf="canRegressToPreviousStep()">
        Previous
      </button>
      <div *ngFor="let step of steps; let last = last" class="flex flex-vertical-center m-b-sm-sm"
        [class.m-r-md]="!last">
        <i class="fa fa-2x fa-circle text-muted" *ngIf="!step.stepHasBeenCompleted()"></i>
        <i class="fa fa-2x fa-check-circle text-success" *ngIf="step.stepHasBeenCompleted()"></i>
        &nbsp;&nbsp;
        <span class="font-semi-bold">{{ step.name }}</span>
      </div>
      <div class="flex-grow"></div>
      <button class="btn btn-success" id="nexleader-test-pvi-next"
        [class.is-hidden]="selectedStepIndex === maxStepIndex" [attr.disabled]="!canAdvanceToNextStep() ? true : null"
        (click)="nextStep()">
        Next Step
      </button>
      <button class="btn btn-success" id="nexleader-test-pvi-complete-assessment"
        *ngIf="selectedStepIndex === maxStepIndex" [attr.disabled]="!canAdvanceToNextStep() ? true : null"
        (click)="completeAssessment()">
        Complete Assessment
      </button>
    </div>
  </div>
</div>
<app-nexleader-component-pvi-assessment-intro *ngIf="isSelectedStep(1)"
  (advanceToAssessment)="nextStep()"></app-nexleader-component-pvi-assessment-intro>
<app-nexleader-component-pvi-assessment-step-one *ngIf="isSelectedStep(2)"
  [topTwelveValues]="personalValuesInventoryResult.topTwelveValues"></app-nexleader-component-pvi-assessment-step-one>
<app-nexleader-component-pvi-assessment-step-two *ngIf="isSelectedStep(3)"
  [topTwelveValues]="personalValuesInventoryResult.topTwelveValues"></app-nexleader-component-pvi-assessment-step-two>
<app-nexleader-component-pvi-assessment-step-three *ngIf="isSelectedStep(4)"
  [topTwelveValues]="personalValuesInventoryResult.topTwelveValues"></app-nexleader-component-pvi-assessment-step-three>
<app-nexleader-component-pvi-assessment-step-four *ngIf="isSelectedStep(5)"
  [topTwelveValues]="personalValuesInventoryResult.topTwelveValues"></app-nexleader-component-pvi-assessment-step-four>
<app-nexleader-component-pvi-assessment-step-five *ngIf="isSelectedStep(6)"
  [topTwelveValues]="personalValuesInventoryResult.topTwelveValues"></app-nexleader-component-pvi-assessment-step-five>

<div class="container p-v-50 border-top" *ngIf="!isSelectedStep(1)">
  <div class="flex flex-vertical-center flex-wrap">
    <button class="btn btn-default pull-left" *ngIf="canRegressToPreviousStep()">
      Previous
    </button>
    <p>
      You can<span *ngIf="!canAdvanceToNextStep()">not</span> proceed to the
      next step.
    </p>
    <div class="flex-grow"></div>
    <button class="btn btn-success" [class.is-hidden]="selectedStepIndex === maxStepIndex"
      [attr.disabled]="!canAdvanceToNextStep() ? true : null" (click)="nextStep()">
      Next Step
    </button>
    <button class="btn btn-success" *ngIf="selectedStepIndex === maxStepIndex"
      [attr.disabled]="!canAdvanceToNextStep() ? true : null" (click)="completeAssessment()">
      Complete Assessment
    </button>
  </div>
</div>