/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderEditUserModal
 *
 * Allows a group admin to edit a user inside of a modal
 */

import { SuccessService } from './../../../../../services/success.service';
import { combineLatest, tap, catchError, of } from 'rxjs';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../../../../services/user.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { RoleService } from '../../../../../services/role.service';
import { AuthService } from '../../../../../services/auth.service';
import { NexleaderInputRoleComponent } from '../../input-role/input-role.component';
import { NexleaderInputGroupComponent } from '../../input-group/input-group.component';
import { NexleaderInputCoachComponent } from '../../input-coach/input-coach.component';
import { NexleaderDirectPurchaseReferralLinkComponent } from '../../../../direct/components/direct-purchase-referral-link/direct-purchase-referral-link.component';

@Component({
  selector: 'app-nexleader-edit-user-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NexleaderInputRoleComponent,
    NexleaderInputGroupComponent,
    NexleaderInputCoachComponent,
    NexleaderDirectPurchaseReferralLinkComponent,
  ],
  templateUrl: './edit-user-modal.component.html',
})
export class NexleaderEditUserModalComponent implements OnInit {
  /**
   * _id
   *
   * A user's _id to use for querying
   *
   * ex: 596ab0a30b3d4e60fac5d7b1
   */
  id: string = '';

  /**
   * currentViewingRole
   *
   * string binding
   *
   * The current role of the user that is viewing this user card.
   */
  currentViewingRole!: string;

  /**
   * close
   *
   * A close callback function to use after the user successfully saves
   *
   * ex: someFn()
   */
  @Output() close = new EventEmitter<any>();

  user: any;
  roles: any[] = [];
  editableGroup: boolean = false;
  showFreezing: boolean = false;

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private successService: SuccessService,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit() {
    combineLatest([this.userService.getUser(this.id), this.roleService.query()])
      .pipe(
        tap(([user, roles]) => {
          this.user = user;
          this.roles = roles.filter(
            (role: any) => role._id !== 'public' && role._id !== 'admin'
          );
          const token = this.auth.getTokenPayload();
          if (token.roles?.indexOf('admin') > -1) {
            this.editableGroup = true;
          }
          this.showFreezing = this.user.roles.indexOf('admin') < 0;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  saveUser() {
    this.userService
      .saveUser(this.user)
      .pipe(
        tap((_) => {
          this.successService.handle({ message: 'Saved Changes to User.' });
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  saveUserAndClose() {
    this.userService
      .saveUser(this.user)
      .pipe(
        tap((_) => {
          this.close.emit();
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  // setFrozen(frozen: boolean) {
  //   this.user.frozen = frozen;
  //   this.userService.save(this.user, Success.handle, ErrorHandler.handle);
  // }

  /**
   * Updates a user and marks them as a team leader to be used on the team report
   */
  markAsTeamLeader() {
    this.userService
      .markAsTeamLeader(this.id)
      .pipe(
        tap((response) => {
          this.successService.handle();
          // Ensure that we have the latest data
          this.user = response;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  editorHasRole(role: string): boolean {
    return this.auth.getTokenPayload().roles?.indexOf(role) >= 0;
  }
}
