/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderComponentCourseContentInput
 *
 * angular component
 *
 * The course content input is a component that super admins can use to edit information about a course content piece.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-course-content-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './course-content-input.component.html',
})
export class NexleaderCourseContentInputComponent implements OnInit {
  /**
   * courseContent
   *
   * Object
   *
   * An object representing course content to be rendered on the individual card.
   */
  @Input() courseContent: any;

  ngOnInit(): void {
    // validate inputs
    if (!this.courseContent || typeof this.courseContent !== 'object') {
      throw new Error(
        'Input courseContent of type object is required for NexleaderCourseContentInputComponent'
      );
    }

    // If there are tags, convert the tags to a string joined by commas
    if (this.courseContent.tags) {
      this.courseContent.internalTags = this.courseContent.tags.join(',');
    }
  }
}
