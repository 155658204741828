<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div *ngIf="skillsInventoryResults && skillsInventoryResults.length < 1">
      <p>No Skills Inventory results yet.</p>
    </div>
    <div class="row" *ngIf="skillsInventoryResults">
      <div class="col-lg-4 col-md-6 col-xs-12" *ngFor="let result of skillsInventoryResults">
        <div class="hpanel">
          <div class="panel-heading">Your Result</div>
          <div class="panel-body">
            <div class="flex flex-vertical-center">
              <img src="/assets/images/icons/skills.svg" alt="skills" class="img-md m-r-md" />
              <h4 class="text-primary">Your Skills Inventory Result</h4>
            </div>
            <div class="m-t-md m-b-md">
              <hr />
            </div>
            <div class="row">
              <div class="col-xs-12">
                <p class="font-bold">Your skill breakdown</p>
                <div class="row equal-child">
                  <div class="col-xs-6 m-b-sm equal-child" *ngFor="let skillGroup of result.skillGroups">
                    <h3 class="font-normal">{{ skillGroup.skills.length }}</h3>
                    <small class="font-muted font-bold">Total at {{ skillGroup.name }}</small>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <hr />
              </div>
              <div class="col-xs-12">
                <p class="font-bold">Top 4 skills</p>
                <div class="row equal-6">
                  <div class="col-xs-6 m-b-sm equal-child" *ngFor="let skill of result.topSkills">
                    <h3 class="font-normal">{{ skill.value }}</h3>
                    <small class="font-muted font-bold">{{ skill.name }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer flex flex-vertical-center">
            <i class="fa fa-calendar"></i>&nbsp;&nbsp;Completed on
            {{ result.createdAt | date }}
            <div class="flex-grow"></div>
            <div class="pull-right">
              <a class="btn btn-info btn-sm" [routerLink]="['/users', user._id, 'skillsInventoryResults', result._id]">
                <i class="fa fa-eye"></i>&nbsp;&nbsp;View result
              </a>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>