<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="m-b-md">
      <div class="flex flex-vertical-center">
        <h1 class="font-bold text-primary-2">
          The Learning Community for IPSAT Users
        </h1>
        <div class="flex-grow"></div>
        <img src="/assets/images/ecourse/ecourse-content-view.png" alt="ecourse content view"
          style="max-width: 320px; width: 30%" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" *ngFor="let courseContent of mappedCourseContent">
        <app-nexleader-course-content-card [courseContent]="courseContent"></app-nexleader-course-content-card>
      </div>
    </div>
  </div>
</div>