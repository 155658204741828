/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */
/**
 * nexleaderOnboardingSkills
 *
 * The view component for the onboarding skills section of the onboarding preassessments.
 * This view contains information and fields that pertain to this part of the onboarding.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../../services/enum.service';
import { tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-onboarding-skills',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterLink],
  templateUrl: './onboarding-skills.component.html'
})
export class NexleaderOnboardingSkillsComponent implements OnInit {
  /**
   * save
   *
   * Allows data that is edited on this onboarding personality view to be saved.
   */
  @Output() save = new EventEmitter();

  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process
   */
  @Input() user: any;

  /**
   * scoredSkillsInventory
   *
   * A skills inventory result object.
   */
  @Input() scoredSkillsInventory: any;

  /**
   * showAllSkills
   *
   * Allows us to toggle whether or not we're showing the data from the skills inventory
   *
   * @type {boolean}
   */
  showAllSkills = false;
  prerequisite: any;

  constructor(private enumsService: EnumsService) { }

  ngOnInit() {
    this.enumsService.getEnums().pipe(
      tap((a) => {
        this.prerequisite = a.Prerequisites.SKILLS;
      })
    ).subscribe();

    if (!this.user.prerequisites) {
      this.user.prerequisites = { skills: [] };
    } else if (!this.user.prerequisites.skills) {
      this.user.prerequisites.skills = [];
    }

    if (!this.user.prerequisites.skills.every((skill: any) => skill.name) && this.scoredSkillsInventory?.topSkills) {
      this.user.prerequisites.skills = this.scoredSkillsInventory?.topSkills.slice(0, 3).map((x: any) => Object.assign({}, x));
    } else {
      for (let i = this.user.prerequisites.skills.length; i < 3; i++) {
        this.user.prerequisites.skills.push({ name: '' });
      }
    }
  }
}
