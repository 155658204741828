/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumsService } from '../../../../../services/enum.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { SkillsInventoryResultService } from '../../../../../services/skills-inventory-result.service';
import { SuccessService } from '../../../../../services/success.service';
import { UserService } from '../../../../../services/user.service';
import { NexleaderEcourseRaliVideoComponent } from '../../../../ecourse/components/ecourse-rali-video/ecourse-rali-video.component';
import { NexleaderOnboardingSkillsComponent } from '../../onboarding/onboarding-preassessments/onboarding-skills/onboarding-skills.component';
import { NexleaderPreIpsatChecklistComponent } from '../../onboarding/onboarding-preassessments/pre-ipsat-checklist/pre-ipsat-checklist.component';
import { tap, catchError, of, forkJoin } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-nexleader-ecourse-module-four',
  standalone: true,
  imports: [CommonModule, NexleaderEcourseRaliVideoComponent, NexleaderOnboardingSkillsComponent, NexleaderPreIpsatChecklistComponent],
  templateUrl: './ecourse-module-four.component.html'
})
export class NexleaderEcourseModuleFourComponent implements OnInit {
  /**
   * handleNextStep
   *
   * Allows data that is edited on this ecourse module to be saved.
   */
  @Output() handleNextStep = new EventEmitter();

  /**
   * user
   *
   * A user object we can reference to and update as the onboarding user goes throughout the process.
   */
  @Input() user: any;

  currentPrerequisite: any;
  scoredSkillsInventory: any;

  /**
   * moduleSteps
   * 
   * Controls the steps the user can move between for the ecourse module.
   */
  moduleSteps = ['engagement', 'assessment'];

  /**
   * currentStep
   * 
   * Determines the current step the user is on in the ecourse module.
   */
  currentStep = this.moduleSteps[0];

  constructor(
    private userService: UserService,
    private skillsInventoryResultService: SkillsInventoryResultService,
    private successService: SuccessService,
    private errorHandlerService: ErrorHandlerService,
    private enumsService: EnumsService
  ) { }

  ngOnInit(): void {
    this.load();

    // Automatically move the user to the engagement step if they've already completed the Skills Inventory
    if (this.user && this.user.prerequisites && this.user.prerequisites.completedSkills) {
      this.nextStep();
    }
  }

  /**
   * load()
   * 
   * Loads all required enums for the module.
   */
  load(): void {
    forkJoin([
      this.enumsService.getEnums(),
      this.skillsInventoryResultService.queryUserScores(this.user._id)
    ]).pipe(
      tap(([enums, skillsInventoryScores]) => {
        this.currentPrerequisite = enums.Prerequisites.SKILLS;
        this.scoredSkillsInventory = skillsInventoryScores.length > 0 ? skillsInventoryScores[0] : null;
      }), catchError((error) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  /**
   * nextStep()
   * 
   * Handles moving the user to the next step (WITHIN THE MODULE).
   *  Calls the handleNextStep() function if the user is on the last step.
   *  ie: moving from learning_assessment to engagement
   */
  nextStep(): void {
    this.save();
    const currentStepIndex = this.moduleSteps.indexOf(this.currentStep);
    if (currentStepIndex < this.moduleSteps.length - 1) {
      this.currentStep = this.moduleSteps[currentStepIndex + 1];
    } else {
      this.handleNextStep.emit();
    }
  }

  /**
   * mapContinueButtonTextForStep()
   * 
   * Maps text for the continue buttons given the current step of the module the user
   *  is on.
   */
  mapContinueButtonTextForStep(): string | null {
    switch (this.currentStep) {
      case 'engagement':
        return 'Continue to Skills Inventory';
      default:
        return null;
    }
  }

  /**
   * save()
   * 
   * Saves a user. To be called when an MBTI option is selected.
   */
  save(): void {
    this.user.prerequisites.completedSkills = true;
    this.userService
      .saveUser(this.user)
      .pipe(
        tap((_) => {
          this.successService.handle();
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * canProceedToNextStep()
   * 
   * Determines if the user is able to continue to the next module of the ecourse.
   */
  canProceedToNextStep(): boolean {
    return this.user
      && this.user.prerequisites
      && this.user.prerequisites.skills
      && this.user.prerequisites.skills.length === 3
      && this.user.prerequisites.skills.every(this.nonemptyStringObj);
  }

  /**
   * nonemptyStringObj()
   * 
   * helper function
   * 
   * Determines if the object contains a key of name that has a string value.
   * 
   * @param {Object} strO - An object to check.
   */
  private nonemptyStringObj(strO: any): boolean {
    if (!strO) return false;
    return typeof strO.name === 'string' && strO.name.trim() !== '';
  }
}
