<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div id="nexleader-wrapper" style="height: 100%; overflow: hidden">
  <div
    class="content"
    style="
      height: calc(100% - 85px);
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <div style="flex-grow: 1; height: 100%; overflow-y: hidden">
      <app-nexleader-quick-view
        [model]="users"
        [tableHeaders]="[
          'Name',
          'Email',
          'Coach',
          'Group',
          'Licensing Partner'
        ]"
        [tableColumns]="[
          '_name',
          'email',
          '_coach',
          '_group',
          '_licensingPartner'
        ]"
        [label]="'Users'"
        [overrideDisplayNewElement]="true"
        (onElementSelect)="selectedUser = $event"
      >
        <div *ngIf="selectedUser?._id; else notSelected">
          <app-nexleader-user-card
            [userId]="selectedUser._id"
            [currentViewingRole]="'admin'"
            (onChange)="load()"
          ></app-nexleader-user-card>
        </div>
        <ng-template #notSelected>
          <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
        </ng-template>
      </app-nexleader-quick-view>
    </div>
    <!-- TODO test export -->
    <a
      class="font-semi-bold text-muted"
      [href]="'/api/users/exportToCSV'"
      style="display: block; flex-shrink: 1; margin: 20px 0"
    >
      <i class="fa fa-share-square-o"></i>&nbsp;&nbsp;Export to CSV
    </a>
  </div>
</div>
