/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */
/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class PersonalValuesInventoryResultService {
  constructor(private http: HttpClient) {}

  save(pviResultId: string, payload: any) {
    return this.http.post(
      `${API_BASE}personalValuesInventoryResults/${pviResultId}`,
      payload,
      { withCredentials: true }
    );
  }

  queryUser(userId: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${API_BASE}users/${userId}/personalValuesInventoryResults`,
      { withCredentials: true }
    );
  }

  getScore(pviResultId: string, userId?: string): Observable<any> {
    let url = `${API_BASE}personalValuesInventoryResults/${pviResultId}/score`;
    if (userId) {
      url += `?user_id=${userId}`;
    }
    return this.http.get<any>(url, { withCredentials: true });
  }
}
