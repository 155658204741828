/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderCurrency
 *
 * component
 *
 * It is the responsibility of this component to properly render a currency as text based on a quantity and currency code.
 *  This component relies on the backend /currency API to load formatting data for the currency unit
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { CurrencyService } from '../../resources/currency.service';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-currency',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './currency.component.html',
})
export class NexleaderCurrencyComponent implements OnInit {
  /**
   * nexleaderDataCurrencyQuantity
   *
   * two-way integer binding
   *
   * This should be an integer binding representing the number of currency units to display.
   * For example, if you are displaying $100.00, this value is 100.
   */
  @Input() currencyQuantity!: number;

  private _currencyCode: string = '';

  /**
   * nexleaderDataCurrencyCode
   *
   * two-way string binding
   *
   * This should be the ISO4217 currency code of the currency amount to be displayed.
   * For example, if you are displaying $100.00, this value should be USD.
   * For example, if you are displaying $100.00 CAD, this value should be CAD.
   */
  @Input() set currencyCode(value: string) {
    if (typeof value !== 'string') {
      throw new Error(
        'input nexleaderDataCurrencyCode of type string is required for nexleaderCurrency'
      );
    }
    this._currencyCode = value;
    this.loadCurrency();
  }

  get currencyCode() {
    return this._currencyCode;
  }

  /**
   * nexleaderDataOverrideFractionSize
   *
   * optional two-way string binding
   *
   * This should be an integer. Currencies have a standard fraction size for display. E.g. dollars are
   *  displayed with a fraction size of 2, so two decimals are shown in prices. E.g. $100.00.  This property
   *  allows overrides of a currency's default fraction size. This would typically be used for aestetic reasons
   *  and should not be used in final checkout totals.
   */
  @Input() overrideFractionSize?: number;
  currency: any;

  constructor(
    private currencyService: CurrencyService,
    private errorHandler: ErrorHandlerService
  ) {}

  /**
   * $onInit()
   *
   * function: angular event handler
   *
   * called after binding
   *
   * validates the bindings
   */
  ngOnInit() {
    // validate inputs
    if (!this.currencyQuantity) {
      throw new Error(
        'input nexleaderDataCurrencyQuantity of type number is required for nexleaderCurrency'
      );
    }

    this.loadCurrency();
  }

  private loadCurrency() {
    this.currencyService
      .get(this.currencyCode)
      .pipe(
        tap((currency) => {
          this.currency = currency;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * getFractionSize()
   *
   * function
   *
   * Since we allow the override of default currency fraction size, this funciton exists to return the
   *  overrideFractionSize if it is present and the default fraction size if not.
   *
   * Can return null if there's no overrideFractionSize and currency has not yet loaded
   */
  getFractionSize(): string {
    if (typeof this.overrideFractionSize == 'number') {
      return `1.0-${this.overrideFractionSize}`;
    }
    if (!this.currency) {
      return '';
    }
    return `1.2-${this.currency.fractionSize}`;
  }
}
