<div class="chart-spiritual-gifts-represented-container row">
  <div
    *ngFor="let category of categoriesWithoutIntersects"
    class="col-sm-4 col-xs-12 col-4-print text-center m-b-md"
  >
    <h5 class="text-primary m-b-sm show-mobile hideOnPrint">
      {{ category.name }}
    </h5>
    <div *ngFor="let spiritualGift of category.spiritualGifts" class="m-b-xs">
      <p>{{ spiritualGift.name }}</p>
    </div>
  </div>
</div>
