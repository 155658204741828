<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Create a new Session</h3>
</div>
<div class="modal-body">
  <app-nexleader-session (onSave)="close()" [group_id]="group?._id" [coach_id]="coach?._id"></app-nexleader-session>
</div>