/*global angular*/
/**
 * nexleaderTeamReportDiscussionQuestions
 *
 * This component renders team discussion questions in each category of the team report.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-team-report-discussion-questions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './team-report-discussion-questions.component.html',
})
export class NexleaderTeamReportDiscussionQuestionsComponent implements OnInit {
  /**
   * questions
   *
   * Array of strings, which are the questions to be rendered
   */
  @Input() questions: string[] = [];

  /**
   * header
   *
   * A header to be used on the printable team report page, as it might be confusing
   * when the content falls onto a new page.
   */
  @Input() header: string = '';

  /**
   * hideHeader
   *
   * Allows the header to be hidden on a section. We need this to hide when they view the "Next Steps" questions
   */
  @Input() hideHeader: boolean = false;

  ngOnInit(): void {
    if (!Array.isArray(this.questions)) {
      throw new Error(
        "Array type binding 'questions' required in NexleaderTeamReportDiscussionQuestionsComponent"
      );
    }
  }
}
