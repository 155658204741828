/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/

/**
 * nexleaderInputRange
 *
 * component
 *
 * Provides a range input for developers to use when building user interfaces. A range input is basically a slider that
 *  allows the user to select a number on a continuum by sliding an element (circle) on the range (line).
 */
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-nexleader-input-range',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './input-range.component.html',
})
export class NexleaderInputRangeComponent implements OnInit {
  /**
   * model
   *
   * two-way object binding
   *
   * basically ng-model
   *
   * We need some value that we can bind the range input to and this serves as that connection.
   */
  @Input() model: number = 0;

  /**
   * rangeMin
   *
   * number
   *
   * A number that defines the minimum value that can be selected on the range input.
   */
  @Input() rangeMin: number = 0;

  /**
   * rangeMax
   *
   * number
   *
   * A number that defines that maximum value that can be selected on the range input.
   */
  @Input() rangeMax: number = 0;

  /**
   * rangeStep
   *
   * number
   *
   * A number that defines the "step" or the minimum distance between two numbers on the range input.
   */
  @Input() rangeStep: number = 1;

  /**
   * disabled
   *
   * boolean
   *
   * Determines whether or not to disable the input range. Useful if we're showing it as a static range, but
   *  don't want the user to be able to edit the fields
   */
  @Input() disabled: boolean = false;

  /**
   * onChange
   *
   * event callback expression
   *
   * called after a $timeout after an ng-change callback from the option dropdown
   *
   * we wrap this in a $timeout so that there's time for the data to propagate upwards through
   *  the model binding
   */
  @Output() realOnChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * $onInit()
   *
   * function: angular event handler
   *
   * called after binding
   *
   * validates the bindings
   */
  ngOnInit(): void {
    // Validate inputs
    if (typeof this.model !== 'number') {
      throw new Error(
        'Input "model" of type number is required for NexleaderInputRangeComponent.'
      );
    }
    if (typeof this.rangeMin !== 'number') {
      throw new Error(
        'Input "rangeMin" of type number is required for NexleaderInputRangeComponent.'
      );
    }
    if (typeof this.rangeMax !== 'number') {
      throw new Error(
        'Input "rangeMax" of type number is required for NexleaderInputRangeComponent.'
      );
    }

    // By default, the step for the input is equal to 1
    if (!this.rangeStep) {
      this.rangeStep = 1;
    }
  }

  /**
   * onChange()
   *
   * wraps realOnChange() in a $timeout
   *
   * see docs for realOnChange in bindings
   */
  onChange(): void {
    this.realOnChange.emit(this.model);
  }
}
