/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { ErrorHandlerService } from './../../../../services/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexleaderNetPromoterBoxComponent } from '../../components/net-promoter-box/net-promoter-box.component';
import { GroupService } from '../../../../services/group.service';
import { NexleaderStatisticComponent } from '../../components/statistic/statistic.component';
import { EvaluationResultService } from '../../../../services/evaluation-result.service';
import { NetPromoterService } from '../../../../services/net-promoter.service';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { OrderByPipe } from '../../../../pipes/orderby.pipe';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-group',
  standalone: true,
  imports: [
    CommonModule,
    NexleaderNetPromoterBoxComponent,
    NexleaderStatisticComponent,
    OrderByPipe,
    RouterLink
  ],
  templateUrl: './group.component.html',
})
export class NexleaderGroupComponent implements OnInit {
  routeParams: any;
  group: any;
  evaluationResults: any[] = [];
  netPromoter: any;
  // Default configuration for the evaluation result
  evaluationResultsFilter: string = 'user.lastName';

  constructor(
    private groupService: GroupService,
    private evaluationResultService: EvaluationResultService,
    private netPromoterService: NetPromoterService,
    private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.load();
  }

  load(): void {
    combineLatest([
      this.groupService.get(this.routeParams['group_id']),
      this.evaluationResultService.queryGroup(this.routeParams['group_id']),
    ])
      .pipe(
        tap(([group, evaluationResults]) => {
          this.group = group;
          this.evaluationResults = evaluationResults;
          if (this.evaluationResults.length > 0) {
            this.netPromoterService
              .calculateNetPromoterScore(this.evaluationResults)
              .then((netPromoter) => {
                this.netPromoter = netPromoter;
              });
          }
        }),
        catchError((error) => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  filterAlphabetically(): void {
    this.evaluationResultsFilter = 'user.lastName';
  }

  filterByCreation(): void {
    this.evaluationResultsFilter = '';
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupComponent',
//   downgradeComponent({
//     component: NexleaderGroupComponent,
//   }) as angular.IDirectiveFactory
// );
