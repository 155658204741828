<div class="row">
  <div class="col-md-12">
    <app-nexleader-yes-no-buttons
      [(model)]="user.prerequisites.completedPassions"
      (onChange)="save.emit()"
    ></app-nexleader-yes-no-buttons>
  </div>

  <div class="col-md-12" *ngIf="!user.prerequisites.completedPassions">
    <hr />
    <b>Estimated time to complete: 20-30 minutes</b>
    <p class="m-t-md m-b-md">
      Click the "Take MyPassionProfile" button below and MyPassionProfile will
      open in a new tab. After you create your account, you will be asked to
      select three passions—issues/causes you care deeply about. You will then
      respond to a series of questions about each passion, which will generate a
      report showing the level of passion you have for each of the three
      issues/causes.
      <br /><br />
      Once you have completed the assessment, click "I Completed the
      MyPassionProfile Assessment," and save your results.
    </p>
    <div class="hpanel">
      <div class="panel-body">
        <p>
          You can take MyPassionProfile for free by using the discount code:
          <b>IPSAT</b>
        </p>
      </div>
    </div>
    <button
      class="btn btn-default"
      (click)="toggleMyPassionProfileConfirmationModal()"
    >
      Take MyPassionProfile
    </button>
    <br />
    <a
      class="btn btn-success btn-block m-t-md"
      (click)="user.prerequisites.completedPassions = true"
    >
      I Completed the MyPassionProfile Assessment
    </a>
  </div>

  <div class="col-md-12" *ngIf="user.prerequisites.completedPassions">
    <p class="m-t-md m-b-lg">
      <b>You are almost ready to take the IPSAT.</b>
      <br /><br />
      Enter your top three passions and the corresponding level from your
      report. You can upload the PDF reports using the file uploader tool to
      save your results within the IPSAT. If you have problems uploading the
      files, just move on to the next step.
    </p>
    <hr />
    <div class="col-xl-6">
      <app-nexleader-input-passions
        *ngIf="user"
        [userId]="user._id"
        [passions]="user.prerequisites.passions"
        (onChange)="save.emit()"
      ></app-nexleader-input-passions>
    </div>
    <hr />
    <div class="col-xl-6">
      <app-nexleader-profile-wizard-upload-file
        *ngIf="user && files && prerequisite"
        [user]="user"
        [prerequisite]="prerequisite"
        [allFiles]="files"
        (load)="loadFiles()"
        [isOptional]="true"
      ></app-nexleader-profile-wizard-upload-file>
    </div>
  </div>
</div>
