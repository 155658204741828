/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class DirectService {

    constructor(private http: HttpClient) { }

    signup(data: any): Observable<any> {
        return this.http.post(`${API_BASE}direct/signup`, data);
    }

    purchase(data: any): Observable<any> {
        return this.http.post(`${API_BASE}direct/purchase`, data, { withCredentials: true, responseType: 'text', });
    }

    getPricing(params: any): Observable<any> {
        return this.http.get(`${API_BASE}direct/pricing`, { params, withCredentials: true });
    }

    getCoach(coachId: string): Observable<any> {
        return this.http.get(`${API_BASE}direct/coach/${coachId}`);
    }

    getGroup(groupId: string): Observable<any> {
        return this.http.get(`${API_BASE}direct/group/${groupId}`);
    }
}
