/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderViewMppResult
 *
 * view
 *
 * This view contains a basic list that displays a single MPP Result.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { PassionProfileResultService } from '../../../../services/passion-profile-result.service';
import { UserService } from '../../../../services/user.service';
import { catchError, forkJoin, of, tap } from 'rxjs';

@Component({
  selector: 'app-nexleader-mpp-result',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './mpp-result.component.html'
})
export class NexleaderMppResultComponent implements OnInit {
  routeParams: any;

  myPassionProfileResult: any;
  assessmentResult: any;
  user: any;

  private SCROLL_DURATION = 500;

  constructor(
    private route: ActivatedRoute,
    private passionProfileResultService: PassionProfileResultService,
    private userService: UserService,
    private errorHandler: ErrorHandlerService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.routeParams = this.route.snapshot.params;
    this.loadPassionProfileResult();
  }

  private loadPassionProfileResult() {
    // Load the my passion profile result
    forkJoin([
      this.passionProfileResultService.get(this.routeParams['passion_profile_result_id'], this.routeParams['user_id']),
      this.userService.getUser(this.routeParams['user_id'])
    ]).pipe(
      tap(([profileResult, user]) => {
        this.myPassionProfileResult = profileResult;
        this.assessmentResult = this.myPassionProfileResult.scoredAssessmentResult;
        this.user = user;
        this.processAssessmentResult();
      }), catchError((error) => {
        this.errorHandler.handleError(error);
        return of(null);
      })
    ).subscribe();
  }

  private processAssessmentResult() {
    this.assessmentResult.passions.forEach((passion: any) => {
      passion.noWinningDomainOfPassion = false;
      let highestDomainOfPassionScore = 0;
      passion.domainsOfPassionateEngagement.forEach((domain: any) => {
        if (domain.score > highestDomainOfPassionScore) {
          highestDomainOfPassionScore = domain.score;
        } else if (domain.score > 0 && domain.score === highestDomainOfPassionScore) {
          passion.noWinningDomainOfPassion = true;
        }
      });
      if (highestDomainOfPassionScore <= 0) {
        passion.noWinningDomainOfPassion = true;
      }

      //Aggregate narrative overview by title
      // High algorithmic complexity but good readability (i hope)
      // Won't scale well, but we won't have over 100 items.
      //Filter to the narrative overview questions
      const rawNarrativeOverviewParagraphs = passion.applicableCopy.filter((paragraph: any) => !paragraph.style);

      passion.narrativeOverviewAggregatedByTitle = this.aggregateNarrativeOverview(rawNarrativeOverviewParagraphs);
      passion.warnings = passion.applicableCopy.filter((paragraph: any) => paragraph.style === 'warning');
      passion.levelCopy = passion.applicableCopy.filter((paragraph: any) => paragraph.style === 'passion_level');
      passion.domainCopy = passion.applicableCopy.filter((paragraph: any) => paragraph.style === 'four_domains');
      passion.leftovers = passion.applicableCopy.filter((paragraph: any) =>
        paragraph.style && paragraph.style !== 'passion_level' && paragraph.style !== 'warning' && paragraph.style !== 'four_domains'
      );

      if (passion.leftovers.length > 0) {
        console.warn('Leftover passions with unknown styles', passion.leftovers, 'for passion', passion.name, passion);
      }
    });
  }

  private aggregateNarrativeOverview(paragraphs: any[]) {
    //Map to the titles
    const titles = paragraphs.map(paragraph => paragraph.title)
      //Reduce to the unique titles
      .reduce((uniqueTitles, title) => {
        if (!uniqueTitles) uniqueTitles = [];
        if (!Array.isArray(uniqueTitles)) uniqueTitles = [uniqueTitles];
        if (uniqueTitles.indexOf(title) >= 0) return uniqueTitles;
        uniqueTitles.push(title);
        return uniqueTitles;
      }, []);
    return titles.map((title: any) => paragraphs.filter(paragraph => paragraph.title === title));
  }

  scrollTo(id: string) {
    const element = this.document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderMppResultComponent',
//   downgradeComponent({
//     component: NexleaderMppResultComponent,
//   }) as angular.IDirectiveFactory
// );

