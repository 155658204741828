<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="modal-header">
  <h3 class="modal-title">Need help?</h3>
</div>
<div class="modal-body">
  <p>
    You can contact Angeline Patrick via email at
    <a href="mailto:angeline.patrick@nexleader.com"
      >angeline.patrick&#64;nexleader.com</a
    >
    or phone at <a href="tel:678-261-7970">678-261-7970</a>.
  </p>
</div>
<div class="modal-footer">
  <div class="pull-right">
    <button class="btn btn-default" type="button" (click)="close()">
      Close
    </button>
  </div>
  <div class="clearfix"></div>
</div>
