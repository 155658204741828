/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { GroupService } from '../../../../services/group.service';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-nexleader-about',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './about.component.html',
})
export class NexleaderAboutComponent implements OnInit {
  routeParams: any;
  surveyTypeIdentifier: string = '';

  constructor(
    private groupService: GroupService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    this.loadSurveyTypeIdentifier();
  }

  private loadSurveyTypeIdentifier(): void {
    this.groupService
      .getLite(this.routeParams['group_id'], this.routeParams['user_id'])
      .pipe(
        tap((result) => {
          this.surveyTypeIdentifier = result.surveyType.identifier;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderAboutComponent',
//   downgradeComponent({
//     component: NexleaderAboutComponent,
//   }) as angular.IDirectiveFactory
// );
