<div class="hpanel" *ngIf="smallGroup">
  <div class="panel-body">
    <h4 class="text-primary-2">{{ smallGroup.name }}</h4>
    <hr />
    <p><b>Group: </b> {{ smallGroup.group.name }}</p>
    <br />
    <h5 class="m-b-sm text-primary-2">Users</h5>
    <div class="table-responsive" *ngIf="usersAndInvites.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>User Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of usersAndInvites">
            <td>{{ user.firstName }} {{ user.lastName }}</td>
            <td>{{ user.email }}</td>
            <td>
              <span *ngIf="user.isUser">Invitation Accepted</span>
              <span *ngIf="user.isInvite"
                >Invitation Pending. This user has not signed up yet.</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p *ngIf="usersAndInvites.length < 1" class="m-b-md">
      This small group does not yet contain any users. Group admins can add
      users to this group by issuing invites or by editing users directly.
    </p>

    <h5 class="m-b-sm text-primary-2">Meetings</h5>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Start Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let meeting of smallGroupMeetings
                | orderBy : 'smallGroupMeetingType.name'
            "
          >
            <td>{{ meeting.smallGroupMeetingType.name }}</td>
            <td>{{ meeting.location || "Not Scheduled Yet" }}</td>
            <td>
              {{
                (meeting.start | date : "MMM d, y h:mm a") ||
                  "Not Scheduled Yet"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="panel-footer flex flex-vertical-center">
    <div class="font-semi-bold">
      Created On {{ smallGroup.createdAt | date }}
    </div>
    <div class="flex-grow"></div>
    <div>
      <button
        class="btn btn-default"
        *ngIf="!smallGroup.archived"
        (click)="setArchivedAndSave(smallGroup, true)"
      >
        Archive
      </button>
      <button
        class="btn btn-default"
        *ngIf="smallGroup.archived"
        (click)="setArchivedAndSave(smallGroup, false)"
      >
        Unarchive
      </button>
      <button
        style="margin-left: 3px"
        class="btn btn-success"
        (click)="editSmallGroup()"
      >
        Edit
      </button>
    </div>
  </div>
</div>
