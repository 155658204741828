<div class="row">
  <div class="col-xs-6">
    <div class="input-group" style="margin-top: 33px">
      <input
        type="text"
        class="form-control"
        required
        name="start"
        #datePicker="bsDatepicker"
        [bsConfig]="{
          showTodayButton: true,
          showClearButton: true,
          dateInputFormat: 'MM/DD/YYYY',
          clearPosition: 'right',
          todayPosition: 'left',
          adaptivePosition: true
        }"
        bsDatepicker
        [(ngModel)]="internalDateModel"
        (ngModelChange)="update()"
      />
      <span class="input-group-btn">
        <button
          class="btn btn-default"
          type="button"
          (click)="datePicker.toggle()"
        >
          <i class="fa fa-calendar"></i>
        </button>
      </span>
    </div>
  </div>
  <div class="col-xs-6">
    <timepicker
      [(ngModel)]="internalDateModel"
      [hourStep]="1"
      [minuteStep]="15"
      name="internalTime"
      (ngModelChange)="update()"
    ></timepicker>
  </div>
  <div class="col-xs-12">
    <app-nexleader-timezone-select
      [model]="modelTimezone"
      (realOnChange)="timezoneChanged($event)"
    ></app-nexleader-timezone-select>
  </div>
</div>
