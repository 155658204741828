import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private httpClient: HttpClient) {}

  query(): Observable<any> {
    return this.httpClient.get(`${API_BASE}surveys`, {
      withCredentials: true,
    });
  }

  getSurvey(surveyId: string): Observable<any> {
    return this.httpClient.get(`${API_BASE}surveys/${surveyId}`, {
      withCredentials: true,
    });
  }

  save(survey: any): Observable<any> {
    return this.httpClient.post(`${API_BASE}surveys/${survey._id}`, survey, {
      withCredentials: true,
    });
  }

  delete(surveyId: string): Observable<any> {
    return this.httpClient.delete(`${API_BASE}surveys/${surveyId}`, {
      withCredentials: true,
    });
  }

  queryIndex(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${API_BASE}surveys/index`, {
      withCredentials: true,
    });
  }

  queryGroup(groupId: string): Observable<any> {
    return this.httpClient.get(`${API_BASE}groups/${groupId}/survey`, {
      withCredentials: true,
    });
  }
}
