<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="nexleaderSurveyBox">
  <form (ngSubmit)="submit()">
    <ng-container *ngIf="survey">
      <div *ngFor="let question of survey.questions; let i = index">
        <app-nexleader-survey-question
          [question]="question"
          [index]="i"
        ></app-nexleader-survey-question>
      </div>
    </ng-container>
    <div
      class="nexleaderSurveyQuestionBox nexleaderSurveyFinish nexleaderSurveyText"
      id="survey30"
    >
      <input
        class="nexleaderSurveySubmit"
        type="submit"
        [disabled]="isFormInvalid() || disableSubmit ? true : null"
      />
      <br />
      <span *ngIf="isFormInvalid()"
        >Your IPSAT is not complete, please make sure you've answered all the
        questions.</span
      >
    </div>
  </form>
</div>
