/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-yes-no-buttons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './yes-no-buttons.component.html'
})
export class NexleaderYesNoButtonsComponent {
  @Input() model: boolean | null | undefined;
  @Output() modelChange = new EventEmitter<boolean | null | undefined>();
  @Output() onChange = new EventEmitter<void>();

  yesActive: boolean = false;
  noActive: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      this.updateButtonState(this.model);
    }
  }

  updateButtonState(model: boolean | null | undefined): void {
    //Handle a null or undefined model
    if (model === null || model === undefined) {
      this.yesActive = false;
      this.noActive = false;
    } else if (model) {
      //Handle a truthy model
      this.yesActive = true;
      this.noActive = false;
    } else {
      //Handle the case where model===false
      // We know model is flase because it is not null, undefined, or falsey
      this.noActive = true;
      this.yesActive = false;
    }
  }

  setModel(value: boolean): void {
    this.model = value;
    this.modelChange.emit(this.model);
    this.updateButtonState(value);
    setTimeout(() => this.onChange.emit(), 0);
  }
}
