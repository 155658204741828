<div class="row">
  <div class="col-md-12">
    <app-nexleader-yes-no-buttons
      [(model)]="user.prerequisites.completedSpiritualGifts"
      (onChange)="save.emit()"
    ></app-nexleader-yes-no-buttons>
  </div>

  <div
    class="col-md-12"
    *ngIf="user.prerequisites.completedSpiritualGifts === false"
  >
    <hr />
    <b>Estimated time to complete: 20 minutes</b>
    <p class="m-t-md m-b-md">
      Clicking the "Take your Spiritual Gifts Assessment" button below will open
      a new tab in your browser with the spiritual gifts test selected by your
      group administrator.
      <br /><br />
      Once you have completed the assessment, click "I Completed the Spiritual
      Gifts Assessment," and save your results.
    </p>
    <a class="btn btn-default" [href]="prerequisiteLink" target="_blank"
      >Take the Spiritual Gifts Assessment</a
    >
    <br />
    <a
      class="btn btn-success btn-block m-t-md"
      (click)="user.prerequisites.completedSpiritualGifts = true"
    >
      I Completed the Spiritual Gifts Assessment
    </a>
  </div>

  <div class="col-md-12" *ngIf="user.prerequisites.completedSpiritualGifts">
    <p class="m-t-md m-b-lg">
      Enter your top three spiritual gifts and use the file uploader tool to
      save your results within the IPSAT. If you have trouble uploading the file
      or don't have files, just move on to the next step.
    </p>
    <div class="col-xl-6">
      <b>Your Spiritual Gifts</b>
      <div class="row" *ngFor="let i of [0, 1, 2]; let index = index">
        <div class="col-xs-9">
          <div class="form-group">
            <select
              class="form-control m-t-sm"
              *ngIf="!editingSpiritualGiftsManually[index]"
              [(ngModel)]="user.prerequisites.spiritualGifts[index]"
              placeholder="Spiritual Gift"
              [compareWith]="compareGifts"
            >
              <option
                *ngFor="let gift of allSpiritualGifts; trackBy: trackByName"
                [ngValue]="gift"
              >
                {{ gift.name }}
              </option>
            </select>
            <input
              type="text"
              class="form-control m-t-sm"
              *ngIf="editingSpiritualGiftsManually[index]"
              [(ngModel)]="user.prerequisites.spiritualGifts[index].name"
              placeholder="Manual Spiritual Gift"
            />
          </div>
        </div>
        <div class="col-xs-3">
          <br />
          <a (click)="editSpiritualGiftManually(index)">Edit Manually</a>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <app-nexleader-profile-wizard-upload-file
        *ngIf="user && files && prerequisite"
        [user]="user"
        [prerequisite]="prerequisite"
        [allFiles]="files"
        (load)="loadFiles()"
        [isOptional]="true"
      ></app-nexleader-profile-wizard-upload-file>
    </div>
  </div>
</div>
