/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/* global angular */

/**
 * nexleaderComponentQuickViewNotSelected
 *
 * angular component
 *
 * The quick view is characterized by a table and search bar on the left with a preview on the right.
 *
 * This component is not the quick view. It is just a panel that says "You haven't selected anything" that
 *  makes it cleaner to use the quick view.
 *
 * example:
 *
 * <nexleader-component-quick-view
 * ...
 * >
 *
 * <nexleader-small-group-card
 *  ng-if="smallGroup._id"
 *  nexleader-data-small-group-id="smallGroup._id"
 * ></nexleader-small-group-card>
 *
 * <nexleader-quick-view-not-selected
 *  ng-if="!smallGroup._id">
 * </nexleader-quick-view-not-selected>
 *
 * </nexleader-component-quick-view>
 *
 * @author Jake Billings
 */
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nexleader-quick-view-not-selected',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quick-view-not-selected.component.html',
})
export class NexleaderQuickViewNotSelectedComponent {}
