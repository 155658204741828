/*global angular*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const API_BASE = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  queryAdmin(): Observable<any> {
    return this.http.get(`${API_BASE}dashboard/admin`, {
      withCredentials: true,
    });
  }

  queryCoach(coachId: string): Observable<any> {
    return this.http.get(`${API_BASE}dashboard/coach/${coachId}`, {
      withCredentials: true,
    });
  }

  queryCoachUser(coachUserId: string): Observable<any> {
    return this.http.get(`${API_BASE}coachUsers/${coachUserId}/dashboard`, {
      withCredentials: true,
    });
  }

  getDocumentsCreatedByTime(payload: any): Observable<any> {
    let params = new HttpParams({ fromObject: payload });
    return this.http.get(`${API_BASE}dashboard/documentsCreatedByTime`, {
      withCredentials: true,
      params,
    });
  }

  queryAllowedModels(): Observable<any> {
    return this.http.get(
      `${API_BASE}dashboard/documentsCreatedByTime/allowedModels`,
      { withCredentials: true }
    );
  }
}
