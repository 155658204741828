<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper">
  <div class="content animate-panel">
    <div class="row">
      <div class="col-md-6">
        <div class="hpanel">
          <div class="panel-heading">Mailchimp</div>
          <div class="panel-body" *ngIf="!integrations.mailchimp.enabled">
            Mailchimp is currently disabled.
          </div>
          <div class="panel-body" *ngIf="integrations.mailchimp.enabled">
            <b>Lists</b>
            <table class="table">
              <tr *ngFor="let list of integrations.mailchimp.listArray">
                <td>{{ list.name }}</td>
                <td>{{ list.id }}</td>
              </tr>
            </table>
            <b>Stats</b>
            <table class="table">
              <tr *ngFor="let stat of integrations.mailchimp.stats">
                <td>{{ stat.name }}</td>
                <td>{{ stat.value | date: 'medium' }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
