/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NexleaderCategoryScoreComponent } from '../../category-score/category-score.component';

@Component({
  selector: 'app-nexleader-result-more-modal',
  standalone: true,
  imports: [CommonModule, NexleaderCategoryScoreComponent],
  templateUrl: './result-more-modal.component.html',
})
export class NexleaderResultMoreModalComponent {
  /**
   * questionCategory
   *
   * object
   *
   * the category of data we are displaying
   */
  @Input() questionCategory: any;

  /**
   * questions
   *
   * object
   *
   * IPSAT questions
   */
  @Input() questions: any;

  /**
   * result
   *
   * object
   *
   * an actual IPSAT survey result
   */
  @Input() result: any;

  /**
   * surveyType
   *
   * string
   *
   * a string that determines what type of survey result we are showing
   */
  @Input() surveyType!: string;
}
