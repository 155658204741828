<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<!-- Main nexleader-wrapper -->
<div id="nexleader-wrapper" style="height: 100%; overflow: hidden">
  <div
    class="content"
    style="
      height: calc(100% - 85px);
      display: flex;
      flex-direction: column;
      overflow: hidden;
    "
  >
    <div style="flex-grow: 1; height: 100%; overflow-y: hidden">
      <app-nexleader-quick-view
        *ngIf="users"
        [model]="users"
        [tableHeaders]="['Name', 'Email', 'Coach']"
        [tableColumns]="['_name', 'email', '_coach']"
        [label]="'Users for ' + group?.name"
        [overrideDisplayNewElement]="true"
        (onElementSelect)="selectedUser = $event"
      >
        <div *ngIf="selectedUser?._id; else notSelected">
          <app-nexleader-user-card
            [userId]="selectedUser._id"
            [currentViewingRole]="'groupadmin'"
            (onChange)="onUserChange()"
          ></app-nexleader-user-card>
        </div>
        <ng-template #notSelected>
          <app-nexleader-quick-view-not-selected></app-nexleader-quick-view-not-selected>
        </ng-template>
      </app-nexleader-quick-view>
    </div>
    <div *ngIf="isLicensingPartnerOrSuperAdmin">
      <a
        class="font-semi-bold text-muted"
        (click)="openAddCoachUserModal()"
        style="display: block; flex-shrink: 1; margin: 20px 0"
      >
        <i class="fa fa-plus"></i>&nbsp;&nbsp;Add Independent Coach
      </a>
    </div>
  </div>
</div>
