/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
/**
 * SurveyResultCalculations
 *
 * This is an angular factory that handles calculations pertaining to how we show the data of the returned IPSAT survey
 *  result.
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SurveyResultCalculationsService {
  constructor() {}

  /**
   * calculateDoughnutChart()
   *
   * Function
   *
   * Calculates the data used within the doughnut chart based off of data returned from an API request made to the
   *  survey result endpoint. This returns an array of numbers to show inside of the SurveyResult component for
   *  the pie chart.
   *
   * @param {Array} categories - An array of objects containing information about individual ipsat score sections.
   * @param {Number} total - A total value of all
   */
  calculateDoughnutChart(categories: any[], total: number): number[] {
    const doughnutData = categories
      // Filter through data that does not have a name of "Open-Ended Questions" or "Summary Overview"
      .filter(
        (a) =>
          a.name !== 'Open-Ended Questions' && a.name !== 'Summary Overview'
      )
      // We just need the value
      .map((a) => a.value);

    // Somewhat of a hack. We just need the last portion of the doughnutData array to be the remainder
    // To calculate this we do 100 - the total.
    doughnutData.push(100 - total);
    return doughnutData;
  }

  /**
   * doughnutLabels
   *
   * An array of labels we use in the SurveyResult doughnut chart. These labels show when a user hovers over an
   *  individual section of the chart.
   *
   * @param {String} surveyTypeIdentifier - The type of survey result that the user has.
   *
   * @type {string[]}
   */
  doughnutLabels(surveyTypeIdentifier: string): string[] {
    if (surveyTypeIdentifier === 'values_based') {
      return [
        'Personality',
        'Strengths',
        'Skills',
        'Personal Values',
        'Passions',
        'Room to Grow',
      ];
    } else {
      return [
        'Personality',
        'Strengths',
        'Skills',
        'Spiritual Gifts',
        'Passions',
        'Room to Grow',
      ];
    }
  }

  /**
   * calculateTotalIpsatScore()
   *
   * Helper Function
   *
   * Calculates the total score for an IPSAT result (just adding).
   *
   * @param {Object} ipsatResult - An IPSAT result we use to calculate a total.
   * @returns {number} - The total value of each section added together.
   */
  calculateTotalIpsatScore(ipsatResult: any): number {
    let total = 0;
    ipsatResult.questions.forEach((r: any) => {
      total += r.answer.value;
    });
    return total;
  }

  /**
   * interpretCategories()
   *
   * Function
   *
   * no idea what this does, but it's important for the survey result view
   *
   * @param categories
   * @param surveyResult
   */
  interpretCategories(categories: any[], surveyResult: any): void {
    categories.forEach((category) => {
      category.value = 0;
      category.className = category.name.toLowerCase().split(' ').join('');
      surveyResult.questions.forEach((question: any) => {
        if (
          question.questionCategory.index === category.index ||
          category.index === 6
        ) {
          category.value += question.answer.value;
        }
      });
      surveyResult.questions.forEach((question: any) => {
        if (
          question.questionCategory.index === category.index &&
          question.answer.ranges
        ) {
          question.answer.ranges.forEach((range: any) => {
            if (category.value <= range.max && category.value >= range.min) {
              // interpretation.range = range;
              question.answer.range = range;
            }
          });
        }
      });

      surveyResult.interpretation.forEach((interpretation: any) => {
        if (interpretation.questionCategory.index === category.index) {
          category.disclaimer = interpretation.disclaimer;
          interpretation.ranges.forEach((range: any) => {
            if (category.value <= range.max && category.value >= range.min) {
              // interpretation.range = range;
              category.range = range;
            }
          });
        }
      });
    });
  }

  /**
   * calculateCurrentIpsatStage()
   *
   * Function
   *
   * Calculates the current stage the user falls into based off of the passed-in total score. This returns an
   *  object that contains a name of the stage the user is in and a description of that stage.
   *
   * @param {Number} ipsatTotal - The total IPSAT score for an individual score report. Used to calculate stage
   */
  calculateCurrentIpsatStage(ipsatTotal: number): {
    name: string;
    description: string;
  } {
    if (ipsatTotal <= 25) {
      return {
        name: 'Exploration Stage',
        description:
          'You are at the Exploration Stage of your self-awareness journey with regard to the components of your identity profile. Do not feel the pressure to increase your self-awareness in every category at the same time. Pick one area of focus (personality, strengths, skills, spiritual gifts, passions) and get started. Mentoring and coaching will be a great help.',
      };
    } else if (ipsatTotal > 25 && ipsatTotal <= 50) {
      return {
        name: 'Identification Stage',
        description:
          'You are at the Identification Stage of your self-awareness journey with regard to the components of your identity profile. You know enough about who you are who God made you to be to engage in focused personal development. Review the scores and feedback for the components of your identity profile (personality, strengths, skills, spiritual gifts, and passions) and identify where you could benefit most from intentional development now. Lean in to coaching and mentoring opportunities to accelerate your growth.',
      };
    } else if (ipsatTotal > 50 && ipsatTotal <= 75) {
      return {
        name: 'Application Stage',
        description:
          'You are at the Application Stage of your self-awareness journey with regard to the components of your identity profile. This level of self-awareness cannot be cultivated in a classroom; it comes from using (applying) your identity profile categories in real life. You may have developed higher self-awareness in some areas (personality, strengths, skills, spiritual gifts, passions) than others. Now it is time to apply the same developmental approach to the categories where you score is lower.',
      };
    } else if (ipsatTotal > 75 && ipsatTotal <= 100) {
      return {
        name: 'Maturation Stage',
        description:
          'You are at the Maturation Stage of your self-awareness journey with regard to the components of your identity profile. This suggests you know how God has gifted you, where and how you can make the best contribution, and how your personality, strengths, skills, spiritual gifts and passions interact with each other to maximize your effectiveness. Now it is about sustaining this momentum and remaining faithful over time. That will include the intentional development of other people.',
      };
    } else {
      throw new Error(
        'param total did not fall into any of the boundary categories set in the if statement'
      );
    }
  }
}
