import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NexleaderSkillsInventoryResultComponent } from '../../components/skills-inventory-result/skills-inventory-result.component';

@Component({
  selector: 'app-nexleader-skills-inventory-result-view',
  standalone: true,
  imports: [CommonModule, NexleaderSkillsInventoryResultComponent],
  templateUrl: './skills-inventory-result-view.component.html',
})
export class NexleaderSkillsInventoryResultViewComponent implements OnInit {
  routeParams: any;
  userId: string | null = null;
  skillsInventoryResultId: string | null = null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;
    const userId = this.routeParams['user_id'];

    const skillsInventoryResultId =
      this.routeParams['skills_inventory_result_id'];
    if (!userId || !skillsInventoryResultId) {
      console.error(
        'user_id and skills_inventory_result_id required to render SkillsInventoryResult'
      );
      return;
    }
    this.userId = userId;
    this.skillsInventoryResultId = skillsInventoryResultId;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderSkillsInventoryResultViewComponent',
//   downgradeComponent({
//     component: NexleaderSkillsInventoryResultViewComponent,
//   }) as angular.IDirectiveFactory
// );
