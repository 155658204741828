<!--Copyright (C) nexleader - All Rights Reserved-->
<!--Unauthorized copying of this file, via any medium is strictly prohibited-->
<!--Proprietary and confidential-->
<!--Written for nexleader <myipsat.com>, 2016-2018-->

<div class="hpanel stats">
  <div class="panel-body">
    <div class="stats-title pull-left">
      <h4>{{ val.name }}</h4>
    </div>
    <div class="stats-icon pull-right">
      <i class="fa fa-{{ icon }} fa-2x"></i>
    </div>
    <div class="m-t-xl">
      <h1 class="text-success">
        <app-nexleader-currency
          *ngIf="val"
          [currencyQuantity]="val.price.currencyQuantity"
          [currencyCode]="val.price.currencyCode"
        >
        </app-nexleader-currency>
      </h1>
      <p>{{ val.description }}</p>
    </div>
    <div class="input-group">
      <span class="input-group-btn">
        <button class="btn btn-default" (click)="changeVal(-1)">
          <i class="fa fa-minus"></i>
        </button>
      </span>
      <input
        type="number"
        class="form-control text-center"
        [(ngModel)]="amount"
        disabled
      />
      <div class="input-group-btn">
        <button class="btn btn-default" (click)="changeVal(1)">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="panel-footer">
    <a (click)="purchaseLicenses(key, val)">
      <i class="fa fa-cart-plus"></i>&nbsp;&nbsp;Buy more -
      <app-nexleader-currency
        *ngIf="val.price"
        [currencyQuantity]="val.price.currencyQuantity * amount"
        [currencyCode]="val.price.currencyCode"
      >
      </app-nexleader-currency>
    </a>
  </div>
</div>
